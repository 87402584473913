import Quill from "quill";
import { linkToRoute } from "services/Helpers/RoutingHelper";
import { Emoji, getEmoji } from "./Emojis";

const Inline = Quill.import("blots/inline");

export const PPElink = 'ppelink';
export class PPELink extends Inline {
    static blotName = PPElink;
    static tagName = 'A';
    static className = 'ppe-link';
    static SANITIZED_URL = 'about:blank';
    static PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];

    static create(value: any) {
        let node = super.create(value);
        //const lnkToRoute = linkToRoute('ppe') + '/article/' + value;
        const lnkToRoute = '/#/ppe/article/' + value;
        //console.log('lnkToRoute', lnkToRoute);

        node.setAttribute('href', lnkToRoute);
        node.setAttribute('rel', 'noopener noreferrer');
        node.setAttribute("data-id", value)
        //node.setAttribute('target', '_blank');
        return node;
    }

    static formats(domNode: any) {
        return domNode.getAttribute('data-id');
    }

    format(name: string, value: any) {
        if (name !== this.statics.blotName || !value)
            return super.format(name, value);
        this.domNode.setAttribute('data-id', value);
        //const lnkToRoute = linkToRoute('ppe') + '/article/' + value;
        const lnkToRoute = '/#/ppe/article/' + value;
        this.domNode.setAttribute('href', lnkToRoute);
    }
}
export const Httpsmailto = 'httpsmailto';
export class HttpsMailto extends Inline {
    static blotName = Httpsmailto;
    static tagName = 'A';
    static className = "https-mailto"
    static SANITIZED_URL = 'about:blank';
    static PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];

    static create(value: any) {
        let node = super.create(value);
        node.setAttribute('href', value);
        node.setAttribute('rel', 'noopener noreferrer');
        node.setAttribute("data-id", value)
        node.setAttribute('target', '_blank');
        return node;
    }

    static formats(domNode: any) {
        return domNode.getAttribute('data-id');
    }

    format(name: string, value: any) {
        if (name !== this.statics.blotName || !value)
            return super.format(name, value);
        this.domNode.setAttribute('data-id', value);
        this.domNode.setAttribute('href', value);
    }
}
export const UserLink = 'userlink';
export class UserProfileLink extends Inline {
    static blotName = UserLink;
    static className = 'user-link';
    static tagName = 'A';
    static SANITIZED_URL = 'about:blank';
    static PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];
    static create(value: any) {
        let node = super.create(value);
        const lnkToRoute = linkToRoute('user') + '/' + value;
        node.setAttribute('href', lnkToRoute);
        node.setAttribute('rel', 'noopener noreferrer');
        node.setAttribute("data-id", value);
        //node.setAttribute('target', '_blank');
        return node;
    }
    static formats(domNode: any) {
        return domNode.getAttribute('data-id');
    }
    format(name: string, value: any) {
        if (name !== this.statics.blotName || !value)
            return super.format(name, value);
        this.domNode.setAttribute('data-id', value);
        const lnkToRoute = linkToRoute('user') + '/' + value;
        this.domNode.setAttribute('href', lnkToRoute);
    }
}

export class EmojiBlot extends Inline {
    static blotName = Emoji;
    static className = 'emoji-blot';
    static tagName = 'span';

    static create(value: string) {
        let node = super.create();
        node.innerText = getEmoji(value);
        return node;
    }
    static parseUnicode(string: string) {
        return string.split('-').map(str => parseInt(str, 16));
    }
}