import loadImage from 'blueimp-load-image';
import { MarkAreaInPictureQuestionDownloadDto, MarkAreaInPictureUserAnswer, MarkInPictureUserAnswer, MediaDownloadDto } from 'collaboration-service';
import { getUriFromLinkByName, Media, URLMedia } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { QuizSC as T } from '../QuizSC';
import { QuestionImplementationProps } from './Question';

const elementResizeDetectorMaker = require("element-resize-detector");
const erdUltraFast = elementResizeDetectorMaker({
    strategy: "scroll" //<- For ultra performance.
});

interface CanvasAndContext {
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D | null;
    data: ImageData;
    width: number;
    height: number;
    name?: string;
    size?: number;
}

export interface ImageWithAreasProps extends QuestionImplementationProps {
    answer?: (answer: MarkInPictureUserAnswer) => void;
}
export interface ImageWithAreasState {
    width: number;
    height: number;
    userAnswer: MarkAreaInPictureUserAnswer;
}

export default class ImageWithAreas extends React.Component<ImageWithAreasProps, ImageWithAreasState> {
    private ref: any;
    private canvases: Array<CanvasAndContext | undefined>;
    private loading: boolean;
    constructor(props: ImageWithAreasProps) {
        super(props);
        const data = this.props.question as MarkAreaInPictureQuestionDownloadDto;
        this.canvases = data.possibleAnswers ? data.possibleAnswers.map(m => undefined) : [];
        this.loading = true;
        this.state = {
            width: 0,
            height: 0,
            userAnswer: {
                id: "",
                selectedAnswers: [],
            },
        }
    }

    async componentDidMount() {
        await this.reset();
    }

    async componentDidUpdate(prevProps: ImageWithAreasProps) {
        if (this.props.question.id !== prevProps.question.id) {
            await this.reset();
            this.setMediaContainer(this.ref);
        }
    }

    public render() {
        const { media, possibleAnswers, areaOpacity } = this.props.question as MarkAreaInPictureQuestionDownloadDto;
        const { displayCorrect, loaded } = this.props;
        const { userAnswer } = this.state;

        const urlMedia: URLMedia | undefined = (loaded && loaded.length > 0) ?
            {
                url: loaded[0]?.url ?? "",
                type: loaded[0]?.media?.mediaType ?? ""
            }
            : undefined;

        // if (this.loading && width !== 0 && height !== 0) {
        //     this.loading = false;
        //     _.forEach(files, (f, i) => {
        //         //console.log('f', f);

        //         loadImage(f, (overlay: any) => {
        //             const canvas = document.createElement("canvas");
        //             canvas.width = overlay.width;
        //             canvas.height = overlay.height;
        //             canvas.style.position = "absolute";
        //             canvas.style.top = "0px";
        //             canvas.style.left = "0px";
        //             canvas.style.width = "100%";
        //             canvas.style.height = "100%";
        //             canvas.style.opacity = "" + 0;
        //             const ctx = canvas.getContext("2d");
        //             if (ctx) {
        //                 ctx.drawImage(overlay, 0, 0, overlay.width, overlay.height);
        //                 const data = ctx.getImageData(0, 0, overlay.width, overlay.height);
        //                 (this.canvases as any)[i] = { canvas, ctx, data, width: overlay.width, height: overlay.height, name: f.name, size: f.size };
        //             }
        //         }, {});
        //     });
        // }


        return (
            <T.IWAOverlay
                imageWidth={media.width ?? 16}
                imageHeight={media.height ?? 9}
                ref={this.setMediaContainer}
                onClick={this.onClick}
            >
                {urlMedia ?
                    <Media
                        media={urlMedia}
                        width="100%"
                    />
                    :
                    <T.IWAImg
                        src={getUriFromLinkByName(media, "self")}
                        width="100%"
                    />
                }
                {_.map(possibleAnswers, (a, idx) => {
                    //console.log("possible answers ", idx, " ", a.media);

                    const uri = getUriFromLinkByName(a.media ?? {} as MediaDownloadDto, "self");
                    const found = _.find(userAnswer?.selectedAnswers, s => s === idx) !== undefined;
                    const isCorrect = displayCorrect ? a.isCorrect : false;

                    return (
                        <T.IWAArea
                            key={a.id}
                            opacity={found ? 1 : isCorrect ? 1 : areaOpacity}
                        >
                            <T.IWAImg
                                src={uri}
                                width="100%"
                                isCorrect={isCorrect}
                            />
                        </T.IWAArea>
                    );
                })}
            </T.IWAOverlay>
        );
    }
    private blobToFile = (theBlob: Blob, fileName: string): File => {
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    };

    private onClick = (ev: React.MouseEvent<HTMLDivElement>) => {
        if (this.props.timeIsOver || this.props.displayCorrect)
            return;

        const { width, height } = this.state;

        if (width === 0 || height === 0)
            return;

        const data = this.props.question as MarkAreaInPictureQuestionDownloadDto;

        const div = ev.target as HTMLDivElement;
        const rect = div.getBoundingClientRect();
        const x = ev.clientX - rect.left;
        const y = ev.clientY - rect.top;
        const ax = (x / width);
        const ay = (y / height);
        //console.log('ay =>' + ay + ", ax =>" + ax);
        //console.log("onClick3", this.canvases, data.possibleAnswers);
        for (let i = 0; i < this.canvases.length; i++) {
            const c = this.canvases[i];
            //console.log('canvas ', i, " ", c?.name);
            if (c && c.data) {
                const pos = (Math.floor(c.width * ax) + Math.floor(c.height * ay) * c.width) * 4;
                if (c.data.data[pos + 3] > 32) {
                    //console.log('found at index ', i, c.name, c.size);

                    const userAnswer = _.clone(this.state.userAnswer);
                    const selectedAnswers = _.clone(userAnswer.selectedAnswers) ?? [];
                    //console.log('selected', selected);

                    const index = _.findIndex(data.possibleAnswers, a => a.media?.reference === c.name);
                    const idx = selectedAnswers.indexOf(index);
                    if (idx >= 0)
                        selectedAnswers.splice(idx, 1);
                    else
                        selectedAnswers.push(index);
                    userAnswer.selectedAnswers = selectedAnswers;
                    if (this.props.answer)
                        this.props.answer(userAnswer);
                    this.setState({ userAnswer });
                    break;
                }
            }
        }

    };

    private setDim = _.debounce((width: number, height: number) => this.setState({ width, height }), 500);
    private setMediaContainer = (ele?: HTMLDivElement | null) => {
        if (ele) {
            if (this.ref === undefined)
                this.ref = ele;
            erdUltraFast.listenTo({}, ele, (e: HTMLDivElement) => {
                //console.log('e', e);
                // console.log('w', e?.offsetWidth ?? 0);
                // console.log('h', e?.offsetHeight ?? 0);
                this.setDim(e?.offsetWidth ?? 0, e?.offsetHeight ?? 0);
            });
        }
    };

    private reset = async () => {
        const data = this.props.question as MarkAreaInPictureQuestionDownloadDto;
        this.canvases = data.possibleAnswers ? data.possibleAnswers.map(m => undefined) : [];
        this.loading = true;

        const { possibleAnswers } = this.props.question as MarkAreaInPictureQuestionDownloadDto;
        const { answers, questionNumber } = this.props;
        const ua = ((answers ?? [])[questionNumber ?? 0] as any)?.userAnswer as MarkAreaInPictureUserAnswer;

        //const files: File[] = [];
        _.forEach(possibleAnswers, async (a, idx) => {
            //console.log('a', a);

            if (a.media) {
                const uri = getUriFromLinkByName(a.media, "self") ?? "";
                const blob = await fetch(uri).then(r => r.blob());

                const file = this.blobToFile(blob, (a.media?.reference ?? "dummy"));
                //files.push(file);
                loadImage(file, (overlay: any) => {
                    const canvas = document.createElement("canvas");
                    canvas.width = overlay.width;
                    canvas.height = overlay.height;
                    canvas.style.position = "absolute";
                    canvas.style.top = "0px";
                    canvas.style.left = "0px";
                    canvas.style.width = "100%";
                    canvas.style.height = "100%";
                    canvas.style.opacity = "" + 0;
                    const ctx = canvas.getContext("2d");
                    if (ctx) {
                        ctx.drawImage(overlay, 0, 0, overlay.width, overlay.height);
                        const data = ctx.getImageData(0, 0, overlay.width, overlay.height);
                        (this.canvases as any)[idx] = { canvas, ctx, data, width: overlay.width, height: overlay.height, name: file.name, size: file.size };
                    }
                }, {});
            }
        });
        this.setState({
            width: 0,
            height: 0,
            userAnswer: {
                id: "",
                selectedAnswers: ua?.selectedAnswers ?? [],
            } as MarkAreaInPictureUserAnswer,
        });
    };

}
