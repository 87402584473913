import { ContentController, ContentPostDownloadDto, ControllerHelper, EventController, EventUploadDto, PostController, PostDownloadDto, PostInteraction } from 'collaboration-service';
import CommentsRatingsView from 'components/General/CommentsRatingsView';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Button, Dimmer, Icon, RatingView, getColor, styled, useBelowBreakpoint } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { bannerTop } from 'services/Config';
import { getTranslatedLinkForContent, isChangedDateGreaterThanCreatedDateWithoutTime, shallowCompare, updateCategoriesAndCurrentStack } from 'services/Helpers';
import { windowOpen } from 'services/Helpers/LinkHelpers';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import ScrollHelper from 'services/Helpers/ScrollHelper';
import { getTranslated, getTranslatedStandardDate } from 'services/Helpers/TranslationHelpers';
import History from 'services/History';
import { useImgI18N } from 'services/ImgI18N';
import { useAppTheme } from 'services/useAppTheme';
import usePostHub from 'services/usePostHub/usePostHub';
import { ContentSC as C } from 'views/ContentPage/ContentSC';
import { PlaylistDetailSC } from 'views/Playlist/PlaylistDetailSC';
import PreviewTubePodcast from 'views/Playlist/PlaylistItems/Dimmer/Previews/PreviewTubePodcast';
import { PlaylistSC } from 'views/Playlist/PlaylistSC';
import { ContentTheme } from './ContentMain';

const CPMainContainer = styled.div`
    width:100%;
    text-align: left;
    margin: 0;
    user-select: none;
    padding-bottom: 80px;
`;
const CPGrid = styled.div<{ mobileView: boolean }>`
    width: 100%;
    max-width: 900px;
    transition: 0.2s all ease-out;
    margin: auto;
`;
const CPGridLeftHeaderBar = styled.div`
    line-height: 40px;
    font-size: 1rem;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;  
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 40px 1fr max-content;
    margin: 20px 0;
    button>div{
        margin: 0;
    }
`;
const DetailContainerImage = styled.div<{ image: string, mobileView: boolean }>`
    width: 100%;
    grid-column-start: ${p => p.mobileView ? 2 : 1};
    grid-column-end: ${p => p.mobileView ? 4 : 2};
    grid-row-start: ${p => p.mobileView ? 2 : 1};
    grid-row-end:  ${p => p.mobileView ? 2 : 8};
    background-image: url(${p => p.image});
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    align-self: start;
    cursor: pointer;
    transition: all 0.2s ease-out;
    border: 1px solid transparent;
    box-shadow: none;
    height: calc(100% - 40px);
    position: relative;
    &:hover{
        box-shadow: ${p => "0px 0px 60px -20px " + getColor(p, "@middleLightGrey") + ", 0px 6px 8px -10px " + getColor(p, "@mainBackground")};
    }
`;
const ContentGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 20px;
`;
const CPGridRead = styled.div`
    background: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)};
    height: 40px;
    grid-row-start: 8;
    grid-row-end: 9;
    grid-column-start: 1;
    grid-column-end:  2;
    transform: translateY(-39px);
    width: calc(100% - 1px);
`;
const CPGridLeftComments = styled.div<{ mobileView: boolean }>`
    grid-row: 8;
    grid-column: 2;
    padding-left: 15px;
`;
const mapper = (state: ApplicationState) => ({
    params: state.params,
    contentLanguage: state.contentLanguage,
    post: state.contentState.currentPost,
    categories: state.contentState.categories,
    categoryStack: state.contentState.currentCategoryStack,
    search: state.contentState.search
});

interface ContentFeedDetailPlaylistViewProps {
}

const ContentFeedDetailPlaylistView = (p: ContentFeedDetailPlaylistViewProps) => {
    const { post, params, contentLanguage, categoryStack, search } = useSelector(mapper, shallowCompare);
    const dispatch = useAppDispatch();
    const [scrollHelper] = React.useState(new ScrollHelper());
    const [showDimmer, setShowDimmer] = React.useState<boolean>(false);
    const { t } = useImgI18N("content");

    React.useEffect(() => {
        if (categoryStack === undefined) {
            updateCategoriesAndCurrentStack(dispatch, "LANDING PAGE SMART MOBILITY", "smw", Actions.setContentCategories, Actions.setContentCurrentCategoryStack, params);
        }
    }, [dispatch, categoryStack, params]);

    const onUpdate = React.useMemo(() => (post: PostDownloadDto) => {
        dispatch(Actions.setContentCurrentPost(post as ContentPostDownloadDto));
    }, [dispatch]);

    usePostHub({
        onUpdate,
        ids: post?.id ?? [],
    })
    const categories = post?.categories;
    React.useEffect(() => {
        const load = async () => {
            if (params?.id !== post?.id && params?.id) {
                const context = params?.context as string;
                dispatch(Actions.setContentCurrentPost(undefined));
                const id = params.id as string;
                var pp = context ? await ControllerHelper.singleCall({ id, context }, PostController.GetSinglePostWithContext) as ContentPostDownloadDto : await ControllerHelper.singleCall({ id }, PostController.GetSinglePost) as ContentPostDownloadDto;
                dispatch(Actions.setContentCurrentPost(pp));
            }
        }
        load();
    }, [params, dispatch, post]);
    React.useEffect(() => {
        const load = async () => {
            if (!categories) {
                const c = await ControllerHelper.singleCall({ grouptype: "LANDING PAGE SMART MOBILITY" }, ContentController.GetCategoriesForAllGroupsByGroupType);
                dispatch(Actions.setContentCategories(c));
            }
        };
        load();
    }, [categories, dispatch]);

    const scrollTo = React.useMemo(() => (id: string) => {
        scrollHelper.scrollTo(id);
    }, [scrollHelper]);

    React.useEffect(() => {
        dispatch(Actions.setScrollTo(scrollTo))
    }, [scrollTo, dispatch]);

    const media = getMediaLink(post, p => p?.media);

    const title = getTranslated(post?.headlines, contentLanguage)?.text;
    const dateCreated = post ? getTranslatedStandardDate(post?.created ?? "") : "";
    const dateChanged = post ? getTranslatedStandardDate(post?.changed ?? "") : "";

    const createLinkVisitedEvent = (post: ContentPostDownloadDto) => {
        const searchedFor = search ? _.orderBy(_.split(search, " ")) : undefined;
        let linkSource: string = "category";
        if (searchedFor)
            linkSource = "search";
        const event: EventUploadDto = {
            group_id: post.group_id,
            eventType: "LinkVisited",
            payload: {
                "PostId": post.id,
                "linkSource": linkSource,
                "searchedFor": searchedFor
            }
        };
        EventController.PostEvent({
            ev: event
        });
        const event1: EventUploadDto = {
            group_id: post.group_id,
            eventType: "Finished",
            payload: {
                "PostId": post.id,
                "linkSource": linkSource,
                "searchedFor": searchedFor
            }
        };
        EventController.PostEvent({
            ev: event1
        });
    }

    const openLink = async () => {
        if (post) {
            createLinkVisitedEvent(post);
            windowOpen(getTranslatedLinkForContent(post.content, contentLanguage), "_blank");
        }
    }

    const openDimmer = async () => {
        if (post) {
            createLinkVisitedEvent(post);
            setShowDimmer(true)
        }
    }

    const mobileView = useBelowBreakpoint("tablet");
    const contentTheme = useAppTheme<ContentTheme>("contentTheme");
    const checkDates = post ? isChangedDateGreaterThanCreatedDateWithoutTime(post.created, post.changed) : false;
    const banner = bannerTop.playlist;
    const hasContent = post && post?.content.length > 0;
    const hasVideo = hasContent ? post.content.every(item => item.content.mediaType === "video/mp4") : false;
    const clickHandler = hasContent ? (hasVideo ? openDimmer : openLink) : undefined;
    const linkForContent = post ? getTranslatedLinkForContent(post.content, contentLanguage) : undefined;
    const linkIncludesCdnImaginarity = linkForContent?.includes("cdn.imaginarity.com");
    console.log('linkIncludesCdnImaginarity', linkIncludesCdnImaginarity);

    return (
        <div ref={scrollHelper.getRef("top")}>
            <CPMainContainer>
                <PlaylistSC.BannerContainer imageUrl={banner} />
                <CPGrid mobileView={mobileView}>
                    <CPGridLeftHeaderBar id="videoWidth">
                        <Button kind="fullTransparentButton" icon='feed' disabled iconColor='color' />
                        <SafeHTML html={title} allowedTags={[]} allowedAttributes={{}} />
                        <Button
                            kind="transparentButton"
                            icon="chevron left"
                            floated="right"
                            onClick={() => History.goBack()}
                            content={t("back")}
                        />
                    </CPGridLeftHeaderBar>
                    <ContentGrid>
                        <DetailContainerImage image={media ?? ""} mobileView={mobileView} onClick={clickHandler} style={{ pointerEvents: hasContent ? "inherit" : "none" }} />
                        <CPGridRead>
                            <Button
                                content={t("mark content post as read")}
                                kind={!contentTheme.darkMode ? "transparentButtonDark" : "transparentButton"}
                                icon="eye open"
                                floated="left"
                                fluid
                            />
                        </CPGridRead>
                        <C.DetailContainerTitle mobileView={mobileView}>
                            {getTranslated(post?.contentType?.names, contentLanguage)?.text}
                        </C.DetailContainerTitle>
                        {!mobileView &&
                            <C.DetailContainerType mobileView={mobileView}>
                                <RatingView rating={post?.rating ?? 0} maxStars={5} />
                            </C.DetailContainerType>
                        }
                        <C.DetailContainerDescription mobileView={mobileView}>
                            <C.DetailContainerDescriptionDate>
                                {checkDates ?
                                    t("created {{datecreated}}, changed {{datechanged}}", { datecreated: dateCreated, datechanged: dateChanged })
                                    :
                                    t("created {{datecreated}}", { datecreated: dateCreated })
                                }
                                {mobileView &&
                                    <div style={{ float: "right" }}>
                                        <RatingView rating={post?.rating ?? 0} maxStars={5} />
                                    </div>
                                }
                            </C.DetailContainerDescriptionDate>
                            <SafeHTML html={getTranslated(post?.descriptions, contentLanguage)?.text ?? ""} />
                        </C.DetailContainerDescription>

                        <C.DetailContainerFooterMediatype mobileView={mobileView} onClick={clickHandler} style={{ pointerEvents: hasContent ? "inherit" : "none" }} >
                            <Icon name="file" style={{ float: "left", marginRight: 10 }} color={contentTheme.contentAccent} marginTop={1} size="20px" />
                            <C.DetailContainerFooterItem>
                                {getTranslated(post?.mediaType?.names, contentLanguage)?.text ?? ""}
                            </C.DetailContainerFooterItem>
                        </C.DetailContainerFooterMediatype>

                        <C.DetailContainerFooterCategories mobileView={mobileView} style={{ textOverflow: "unset", whiteSpace: "unset", overflow: "unset" }}>
                            <Icon name="content aggregation" style={{ float: "left", marginRight: 10 }} color={contentTheme.contentAccent} marginTop={1} size="20px" />
                            {categories && _.map(categories, (c, j) => <C.DetailContainerFooterItem key={j}>{getTranslated(c.names)?.text}</C.DetailContainerFooterItem>)}
                            {categories && _.map(categories, (c, j) => <C.DetailContainerFooterItem key={j}>{getTranslated(c.names)?.text}</C.DetailContainerFooterItem>)}
                            {categories && _.map(categories, (c, j) => <C.DetailContainerFooterItem key={j}>{getTranslated(c.names)?.text}</C.DetailContainerFooterItem>)}
                        </C.DetailContainerFooterCategories>
                        <C.DetailContainerFooterSystem mobileView={mobileView}>
                            <Icon name="location" style={{ float: "left", marginRight: 10 }} color={contentTheme.contentAccent} marginTop={1} size="20px" />
                            <C.DetailContainerFooterItem>{getTranslated(post?.location?.names, contentLanguage)?.text ?? ""}</C.DetailContainerFooterItem>
                        </C.DetailContainerFooterSystem>
                        <C.DetailContainerLink style={{ height: 40 }}>
                            {hasContent &&
                                <Button
                                    kind={!contentTheme.darkMode ? "transparentButtonDark" : "transparentButton"}
                                    icon="file"
                                    floated="left"
                                    onClick={clickHandler}
                                    tooltip={{ tooltipText: t("download file") }}
                                />
                            }
                        </C.DetailContainerLink>
                        <CPGridLeftComments mobileView={mobileView}>
                            <div style={{ height: 0, marginTop: -10 }} ref={scrollHelper.getRef("ratings")} />
                            {post &&
                                <CommentsRatingsView
                                    post={post}
                                    noLeftBorder
                                    asRating
                                    getIncoming={PostInteraction.GetPostRatings}
                                    addCommentRating={PostInteraction.AddPostRating}
                                    darkMode={contentTheme.darkMode}
                                    accent={contentTheme.contentAccent}
                                    inline={!mobileView}
                                    noPosting
                                />
                            }
                        </CPGridLeftComments>
                    </ContentGrid>
                </CPGrid>
            </CPMainContainer>
            {showDimmer && post &&
                <Dimmer active >
                    <PlaylistDetailSC.DimmerContainer style={{
                        display: "unset",
                        gridTemplateColumns: "unset",
                        gridTemplateRows: "unset",
                        paddingBottom: 0
                    }}>
                        <PreviewTubePodcast
                            id={post.id}
                            isPodcast={false}
                        />
                        <PlaylistDetailSC.DimmerInfoContainerClose onClick={() => setShowDimmer(false)} >
                            <Icon name="times" size={27} marginTop={3} />
                        </PlaylistDetailSC.DimmerInfoContainerClose>
                    </PlaylistDetailSC.DimmerContainer>

                </Dimmer>
            }
        </div>
    );
}
export default ContentFeedDetailPlaylistView;