import React, { useCallback, useState } from 'react';
import { ImageSliderImgSC as T } from './ImageSliderImgSC';
import { Icon } from 'imaginarity-react-ui';

interface ImageCarouselProps {
    loadedImages: string[];
    closeFullscreen?: () => void;
    curIdx: number;
}

const ImageCarousel = (p: ImageCarouselProps) => {
    const { loadedImages, closeFullscreen, curIdx } = p;
    const [currentIndex, setCurrentIndex] = useState(curIdx);

    React.useEffect(() => {
        setCurrentIndex(curIdx);
    }, [curIdx]);

    const width = window.innerWidth;
    const height = window.innerHeight;

    const [naturalWidth, setNaturalWidth] = useState(0);
    const [naturalHeight, setNaturalHeight] = useState(0);

    const [initialScale, setInitialScale] = useState(1);
    const [scale, setScale] = useState(1);
    const [translateX, setTranslateX] = useState(0);
    const [translateY, setTranslateY] = useState(0);

    const [initialTouches, setInitialTouches] = useState<React.Touch[] | null>(null);
    const [initialDistance, setInitialDistance] = useState<number | null>(null);

    const [startTouchX, setStartTouchX] = useState<number | null>(null);
    const [startTouchY, setStartTouchY] = useState<number | null>(null);

    const [lastTap, setLastTap] = useState<number>(0);
    const [isInteracting, setIsInteracting] = useState(false);

    const [previewOffset, setPreviewOffset] = useState(0);

    const currentImage = loadedImages[currentIndex];
    const imageCount = loadedImages?.length || 0;

    const getDistance = (t1: React.Touch, t2: React.Touch) => {
        const dx = t1.clientX - t2.clientX;
        const dy = t1.clientY - t2.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    };

    const handleImageLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
        const img = e.currentTarget as HTMLImageElement;
        const iw = img.naturalWidth;
        const ih = img.naturalHeight;

        setNaturalWidth(iw);
        setNaturalHeight(ih);

        const scaleToFitWidth = width / iw;
        const scaleToFitHeight = height / ih;
        const fitScale = Math.min(scaleToFitWidth, scaleToFitHeight);

        setInitialScale(fitScale);
        setScale(fitScale);

        setTranslateX(0);
        setTranslateY(0);
        setPreviewOffset(0); // Beim neuen Bild resetten
    }, [width, height]);

    const clampPosition = useCallback((x: number, y: number, s: number) => {
        const scaledWidth = naturalWidth * s;
        const scaledHeight = naturalHeight * s;

        let maxX = 0;
        let maxY = 0;

        if (scaledWidth > width) {
            maxX = (scaledWidth - width) / 2;
        } else {
            x = 0;
        }

        if (scaledHeight > height) {
            maxY = (scaledHeight - height) / 2;
        } else {
            y = 0;
        }

        const clampedX = Math.min(maxX, Math.max(-maxX, x));
        const clampedY = Math.min(maxY, Math.max(-maxY, y));

        return { clampedX, clampedY };
    }, [width, height, naturalWidth, naturalHeight]);

    const handleTouchStart = useCallback((e: React.TouchEvent) => {
        setIsInteracting(true);

        if (e.touches.length === 1) {
            setStartTouchX(e.touches[0].clientX);
            setStartTouchY(e.touches[0].clientY);

            const now = Date.now();
            if (now - lastTap < 300 && scale > initialScale) {
                setScale(initialScale);
                setTranslateX(0);
                setTranslateY(0);
            }
            setLastTap(now);

        } else if (e.touches.length === 2) {
            const t1 = e.touches.item(0);
            const t2 = e.touches.item(1);
            if (t1 && t2) {
                setInitialTouches([t1, t2]);
                const dist = getDistance(t1, t2);
                setInitialDistance(dist);
            }
        }
    }, [lastTap, scale, initialScale]);

    const handleTouchMove = useCallback((e: React.TouchEvent) => {
        e.preventDefault();

        if (e.touches.length === 2 && initialTouches && initialTouches.length === 2 && initialDistance && naturalWidth > 0 && naturalHeight > 0) {
            const t1 = e.touches.item(0);
            const t2 = e.touches.item(1);
            if (t1 && t2) {
                const newDist = getDistance(t1, t2);

                const slowFactor = 0.15;
                const pinchRatio = newDist / initialDistance;
                const calculatedScale = scale * (1 + (pinchRatio - 1) * slowFactor);
                const clampedScale = Math.min(4, Math.max(initialScale, calculatedScale));

                const midX = (t1.clientX + t2.clientX) / 2;
                const midY = (t1.clientY + t2.clientY) / 2;

                const deltaX = (midX - width / 2) * (clampedScale - scale) / clampedScale;
                const deltaY = (midY - height / 2) * (clampedScale - scale) / clampedScale;

                const newX = translateX - deltaX;
                const newY = translateY - deltaY;

                const { clampedX, clampedY } = clampPosition(newX, newY, clampedScale);

                setScale(clampedScale);
                setTranslateX(clampedX);
                setTranslateY(clampedY);
                setPreviewOffset(0);
            }

        } else if (e.touches.length === 1 && scale > initialScale) {
            // Panning
            const touch = e.touches[0];
            if (startTouchX !== null && startTouchY !== null) {
                const dx = touch.clientX - startTouchX;
                const dy = touch.clientY - startTouchY;

                const newX = translateX + dx;
                const newY = translateY + dy;

                const { clampedX, clampedY } = clampPosition(newX, newY, scale);

                setTranslateX(clampedX);
                setTranslateY(clampedY);
                setPreviewOffset(0);

                setStartTouchX(touch.clientX);
                setStartTouchY(touch.clientY);
            }

        } else if (e.touches.length === 1 && scale === initialScale && startTouchX !== null) {
            const touch = e.touches[0];
            const diffX = touch.clientX - startTouchX;
            setPreviewOffset(diffX * 0.2);
        }

    }, [initialTouches, initialDistance, scale, startTouchX, startTouchY, translateX, translateY, width, height, clampPosition, initialScale, naturalWidth, naturalHeight]);

    const handleTouchEnd = useCallback((e: React.TouchEvent) => {
        if (e.changedTouches.length === 1 && scale === initialScale && startTouchX !== null && startTouchY !== null) {
            const endX = e.changedTouches[0].clientX;
            const diffX = endX - startTouchX;

            if (Math.abs(diffX) > 50) {
                if (diffX < 0 && currentIndex < loadedImages.length - 1) {
                    setCurrentIndex(i => i + 1);
                } else if (diffX > 0 && currentIndex > 0) {
                    setCurrentIndex(i => i - 1);
                }
            }
        }

        setIsInteracting(false);
        setInitialTouches(null);
        setInitialDistance(null);
        setStartTouchX(null);
        setStartTouchY(null);
        setPreviewOffset(0);
    }, [currentIndex, loadedImages, scale, startTouchX, startTouchY, initialScale]);

    return (
        <div
            style={{
                position: 'relative',
                width: `${width}px`,
                height: `${height}px`,
                overflow: 'hidden',
                margin: '0 auto',
                touchAction: 'none',
                background: "rgba(0,0,0,0.5)"
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <img
                src={currentImage}
                alt={`slide ${currentIndex}`}
                onLoad={handleImageLoad}
                style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transition: isInteracting ? 'none' : 'transform 0.2s ease-in-out',
                    transformOrigin: 'center center',
                    transform: `translate(-50%, -50%) translate(${translateX + previewOffset}px, ${translateY}px) scale(${scale})`,
                }}
            />
            {currentIndex > 0 && (
                <T.NavButtonAreaDimmerLeftMobile
                    style={{ touchAction: "none" }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setCurrentIndex(currentIndex - 1);
                        setScale(initialScale);
                        setPreviewOffset(0);
                    }}
                >
                    <Icon name="chevron left" size="50px" color="background" />
                </T.NavButtonAreaDimmerLeftMobile>
            )}
            {currentIndex < imageCount - 1 && (
                <T.NavButtonAreaDimmerRightMobile
                    style={{ touchAction: "none" }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setCurrentIndex(currentIndex + 1);
                        setScale(initialScale);
                        setPreviewOffset(0);
                    }}
                >
                    <Icon name="chevron right" size="50px" color="background" />
                </T.NavButtonAreaDimmerRightMobile>
            )}
            <T.NavButtonAreaDimmerCenter onClick={scale > initialScale ? () => {
                setScale(initialScale);
                setTranslateX(0);
                setTranslateY(0);
                setPreviewOffset(0);
            } : closeFullscreen} style={{ touchAction: "none" }}>
                <Icon name={scale > initialScale ? "zoom out" : "times"} size={scale > 1 ? "50px" : "50px"} color={scale > initialScale ? "background" : "@accent"} />
            </T.NavButtonAreaDimmerCenter>
        </div>
    );
};

export default ImageCarousel;
