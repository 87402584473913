import { BestPracticeController, BestPracticePostDownloadDto, BookmarkController, ControllerHelper, PostController } from 'collaboration-service';
import { PostRibbon } from 'components/Posts/PostClasses';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { formatDistanceToNow, formatRelative } from 'date-fns';
import { Button, Flag, Icon, Image, Media, RatingView } from 'imaginarity-react-ui';
import useOnScreen from 'imaginarity-react-ui/dist/helpers/useOnScreen';
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { gotoNamedRoute } from 'services/Helpers/RoutingHelper';
import { sanitizedAllowedAttributesHighSafety, sanitizedAllowedTagsPreview } from 'services/Helpers/SanitizedHelper';
import { getOrTranslatePostLanguageStrings, getTranslated, getTranslatedUserName } from 'services/Helpers/TranslationHelpers';
import { ImgI18N, useImgI18N } from 'services/ImgI18N';
import { useBelowBreakpoint, useBelowBreakpointOrEqual, useThemePart } from 'services/useAppTheme';
import { BestPracticeSC as T } from './BestPracticeSC';

const mapper = (state: ApplicationState) => ({
    route: state.route,
    user: state.user,
    bestpracticeState: state.bestpracticeState,
    contentLanguage: state.contentLanguage,
});

export interface BestPracticePostProps {
    post: BestPracticePostDownloadDto;
    onTranslated?: (post: BestPracticePostDownloadDto) => void;
}

const BestPracticePost = (p: BestPracticePostProps) => {
    const { onTranslated, post } = p;

    const [dateRelative, setDateRelative] = React.useState<boolean>(false);
    const [implementations, setImplementations] = React.useState<number>(0);

    const { contentLanguage, route, bestpracticeState } = useSelector(mapper, shallowCompare);
    const dispatch = useAppDispatch();

    const { t } = useImgI18N("best practice");

    const { emptyAvatarImageUrl, emptyImageUrl, width } = useThemePart(t => ({ emptyAvatarImageUrl: t.emptyAvatarImageUrl, emptyImageUrl: t.emptyImageUrl, width: t.content.width }));

    const { visible: isOnScreen, setRef } = useOnScreen();

    React.useEffect(() => {
        const load = async () => {
            if (onTranslated) {
                const translated = await getOrTranslatePostLanguageStrings(post, contentLanguage);
                if (translated)
                    onTranslated(translated);
            }

        }
        load();
    }, [onTranslated, post, contentLanguage]);

    const { openDetails, onBookmark, changeVisibility, demote, promote } = React.useMemo(() => {
        const openDetails = () => {
            dispatch(Actions.setBestPost(post));
            gotoNamedRoute("best_detail", { id: post.id });
        }

        const onBookmark = () => {
            if (onTranslated)
                onTranslated({ ...post, isBookmarked: !post.isBookmarked });
            if (post.isBookmarked)
                BookmarkController.RemoveBookmark(
                    { postid: post.id },
                    post => {
                        if (onTranslated)
                            onTranslated(post as BestPracticePostDownloadDto);
                    },
                    reason => {
                        if (onTranslated)
                            onTranslated(post);
                    }
                );
            else
                BookmarkController.PostBookmark(
                    { postid: post.id },
                    post => {
                        if (onTranslated)
                            onTranslated(post as BestPracticePostDownloadDto);
                    },
                    reason => {
                        if (onTranslated)
                            onTranslated(post);
                    }
                );
        }

        const changeVisibility = () => {
            if (onTranslated)
                onTranslated({ ...post, hidden: !post.hidden });
            PostController.SetPostHidden(
                { id: post.id, hidden: !post.hidden },
                post => {
                    if (onTranslated)
                        onTranslated(post as BestPracticePostDownloadDto);
                    console.log("set post hidden state: " + post.hidden);
                },
                reason => {
                    if (onTranslated)
                        onTranslated(post);
                    console.log("set post hidden state failed ", reason);
                }
            );
        };

        const promote = () => {
            if (onTranslated)
                onTranslated({ ...post, links: _.filter(post.links, l => l.ref !== "promote") });

            PostController.PromotePost(
                { postid: post.id },
                post => {
                    if (onTranslated)
                        onTranslated(post as BestPracticePostDownloadDto);
                    console.log("post was promoted!")
                },
                reason => {
                    if (onTranslated)
                        onTranslated(post);
                    console.log("post promotion failed: ", reason);
                }
            );
        };

        const demote = () => {
            if (onTranslated)
                onTranslated({ ...post, links: _.filter(post.links, l => l.ref !== "demote") });

            PostController.DemotePost(
                { postid: post.id },
                post => {
                    if (onTranslated)
                        onTranslated(post as BestPracticePostDownloadDto);
                    console.log("post was demote!")
                },
                reason => {
                    if (onTranslated)
                        onTranslated(post);
                    console.log("post demotion failed: ", reason);
                }
            );
        };

        return {
            openDetails,
            onBookmark,
            changeVisibility,
            demote,
            promote,
        }
    }, [dispatch, post, onTranslated]);


    const id = post.id;
    React.useEffect(() => {
        const load = async () => {
            const implementations = await ControllerHelper.singleCall({ id }, BestPracticeController.GetBestPracticeImplementation, true);
            setImplementations(implementations ? implementations.length : 0);
        }
        load();
    }, [id])

    let descLng = contentLanguage ?? "";

    if (post.descriptions && post.descriptions.length !== 0) {
        descLng = post.descriptions[0].lng;
    }
    let flag = descLng.indexOf("-") > -1 ? descLng.split("-")[1] as any : descLng.toUpperCase() as any;
    if (flag === "EN") {
        flag = "GB";
    }

    const getDateFnsLng = ImgI18N.getInstance().languageLocal();
    const restImg = post.medias.length - 1;

    const mayChangeVisibility = _.find(post.links, l => l.ref === "changeVisibility") !== undefined;
    const mayPromote = _.find(post.links, l => l.ref === "promote") !== undefined;
    const mayDemote = _.find(post.links, l => l.ref === "demote") !== undefined;
    const medias = _.map(post.medias, m => ({ media: m?.thumbnails[0] ?? m, refName: "self" }));
    const postDate = dateRelative ? formatRelative(new Date(post.created as Date), new Date(), { locale: getDateFnsLng }) : formatDistanceToNow(new Date(post.created as Date), { addSuffix: true, locale: getDateFnsLng });
    const postDate2 = dateRelative ? formatRelative(new Date(post.changed as Date), new Date(), { locale: getDateFnsLng }) : formatDistanceToNow(new Date(post.changed as Date), { addSuffix: true, locale: getDateFnsLng });
    const isHidden = post.hidden;
    const isBookmarked = post.isBookmarked;

    const isMobile = useBelowBreakpointOrEqual("mobile");
    const isDektop = useBelowBreakpoint("desktop");
    const avatarUri = post.creator?.avatar?.links[0]?.uri ?? emptyAvatarImageUrl;

    return (
        <T.PostContainer hidden={isHidden ?? false} ref={setRef}>
            {isOnScreen &&
                <>
                    {route.includes("user") &&
                        <PostRibbon gradientColor1="#D88711" gradientColor2="#F2A100"><span>{t("PRI")}</span> </PostRibbon>
                    }
                    <T.PCImage onClick={openDetails}>
                        {post.medias.length > 0 ?
                            <Media
                                media={medias}
                                videoShift={1}
                                controls={false}
                                minChangeDelay={4500}
                                maxChangeDelay={5500}
                                videoIcon
                            />
                            :
                            <Image src={emptyImageUrl} />
                        }

                    </T.PCImage>

                    <T.PCTitle hidden={isHidden ?? false} onClick={openDetails}>
                        {getTranslated(post.headlines, contentLanguage)?.text ?? ""}
                    </T.PCTitle>
                    <T.PCButton>
                        <Button
                            kind="secondary"
                            onClick={openDetails}
                            icon="info"
                            content={t("details")}
                            floated="right"
                            active={isMobile}
                        />
                        <Button
                            kind="secondary"
                            active={isBookmarked || isMobile}
                            icon={isBookmarked ? "bookmark" : "bookmark outline"}
                            floated="right"
                            onClick={onBookmark}
                        />
                        {mayChangeVisibility && !isMobile &&
                            <Button
                                kind={isHidden ? "primary" : "secondary"}
                                icon={isHidden ? "eye close" : "eye open"}
                                floated="right"
                                onClick={changeVisibility}
                                active={isMobile}
                                tooltip={{
                                    tooltipText: isHidden ? t("show post") : t("hide post"),
                                    position: "bottom",
                                    noMargin: true,
                                    notInline: true,
                                    offsetY: 5,
                                }}
                            />
                        }
                        {mayPromote && !isMobile &&
                            <Button
                                kind="secondary"
                                icon="globe"
                                floated="right"
                                onClick={promote}
                                tooltip={{
                                    tooltipText: t("promote post"),
                                    position: "bottom",
                                    offsetY: 5,
                                    noMargin: true,
                                    notInline: true,
                                }}
                            //active={belowBreakpointOrEqual({ theme }, "mobile")}
                            />
                        }
                        {mayDemote && !isMobile &&
                            <Button
                                kind="primary"
                                active
                                icon="globe"
                                floated="right"
                                onClick={demote}
                                tooltip={{
                                    tooltipText: t("demote post"),
                                    position: "bottom",
                                    noMargin: true,
                                    notInline: true,
                                    offsetY: 5,
                                }}
                            />
                        }
                    </T.PCButton>

                    <T.PCSpace>
                        <T.PCFlag>
                            <Flag name={flag} size={'20px'} />
                            {mayPromote && isMobile &&
                                <div style={{ float: "right", marginLeft: (mayPromote || mayDemote) ? 0 : 10 }} >
                                    <Button
                                        kind="secondary"
                                        icon="globe"
                                        floated="right"
                                        onClick={promote}
                                        //active={isMobile}
                                        size="small"
                                    />
                                </div>
                            }
                            {mayDemote && isMobile &&
                                <div style={{ float: "right", marginLeft: (mayPromote || mayDemote) ? 0 : 10 }} >
                                    <Button
                                        kind="primary"
                                        icon="globe"
                                        floated="right"
                                        onClick={demote}
                                        active
                                        size="small"
                                    />
                                </div>
                            }
                            {mayChangeVisibility && isMobile &&
                                <div style={{ float: "right", marginLeft: 10 }} >
                                    <Button
                                        kind={isHidden ? "primary" : "secondary"}
                                        icon={isHidden ? "eye close" : "eye open"}
                                        floated="right"
                                        onClick={changeVisibility}
                                        active={isMobile}
                                        size="small"
                                    />
                                </div>
                            }
                        </T.PCFlag>
                    </T.PCSpace>

                    <T.PCUser>
                        {post.creator &&
                            <T.PCAvatar>
                                <Image
                                    src={avatarUri}
                                    rounded
                                    squared
                                    width="20px"
                                />
                            </T.PCAvatar>
                        }
                        {post.creator &&
                            <span style={{ textTransform: "lowercase", paddingRight: 5 }}>
                                {t("by")} {getTranslatedUserName(post.creator)} {post.creator?.organisation && <>({post.creator?.organisation})</>}
                            </span>
                        }
                        <T.PCName>
                            <span onClick={width > 768 ? () => setDateRelative(!dateRelative) : undefined}>
                                {postDate}
                                {(bestpracticeState.ordering === 'invisible') &&
                                    <span style={{ paddingLeft: 5 }} onClick={width > 768 ? () => setDateRelative(!dateRelative) : undefined}>{t("(last change: {{date}})", { date: postDate2 })}</span>
                                }

                            </span>

                        </T.PCName>
                    </T.PCUser>


                    <T.PCmoreImage>
                        {restImg > 0 &&
                            <>
                                {"+" + restImg}
                                <Icon
                                    name="images"
                                    marginTop={0}
                                    size="20px"
                                    style={{
                                        float: isDektop ? "right" : "left",
                                        marginTop: isDektop ? 2 : 3
                                    }}
                                />
                            </>
                        }
                    </T.PCmoreImage>
                    <T.PCContent>
                        <T.BPRSanitizeContainer>
                            <SafeHTML
                                allowedTags={sanitizedAllowedTagsPreview}
                                allowedAttributes={sanitizedAllowedAttributesHighSafety}
                                html={getTranslated(post.descriptions, contentLanguage)?.text ?? ""}
                            />
                        </T.BPRSanitizeContainer>
                    </T.PCContent>
                    <T.PCRating>
                        <RatingView
                            rating={post.rating}
                            maxStars={5}
                            showTotal={post.ratingCount > 0}
                            size={isDektop ? "15px" : "20px"}
                            filledOut={true}
                        // filledColor='@accentBlue'
                        />
                    </T.PCRating>
                    <T.PCInfo>
                        <Icon
                            name="comment"
                            size="20px"
                            marginTop={1}
                            style={{ float: "left", opacity: post.commentCount > 0 ? 1 : 0.3, marginRight: 3 }}
                        />
                        <T.CommentCount show={post.commentCount === 0}>
                            {post.commentCount}
                        </T.CommentCount>

                        <Icon
                            name="thumb up"
                            size="20px"
                            marginTop={1}
                            style={{ float: "left", opacity: implementations > 0 ? 1 : 0.3, marginRight: 3 }}
                        />
                        <T.CommentCount show={implementations === 0}>
                            {implementations}
                        </T.CommentCount>
                        {isHidden &&
                            <T.BPPostContainerHidden >
                                <Icon
                                    name="eye close"
                                    size="20px"
                                    marginTop={1}
                                    style={{ float: "right", marginRight: 10 }}
                                />
                            </T.BPPostContainerHidden>
                        }
                    </T.PCInfo>
                </>
            }
        </T.PostContainer>
    );
}

export default BestPracticePost;
