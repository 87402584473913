import { UserShortInfoDownloadDto } from 'collaboration-service';
import { getColor, Icon, Image, lightenColor, styled } from 'imaginarity-react-ui';
import * as React from 'react';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import { gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { getTranslatedUserName } from 'services/Helpers/TranslationHelpers';
import { useThemePart } from 'services/useAppTheme';

export interface UserTileProps {
  user?: UserShortInfoDownloadDto;
  showAvatar?: boolean;
  showEmail?: boolean;
  isMyself?: boolean;
  isMyselfNoCheckIcon?: boolean;
  linkToProfile?: boolean;
}

const C = styled.div<{ isMyself?: boolean, linkToProfile?: boolean }>`
  cursor: ${p => p.linkToProfile ? "pointer" : "normal"};
  display: grid;
  //grid-template-columns: max-content 30% max-content 1fr max-content;
  grid-template-columns: max-content 1fr max-content max-content max-content;
  width:100%;
  line-height:28px;
  text-align: left;
  font-weight: ${p => p.isMyself ? "bolder" : "normal"};
  
  a{
    color: ${p => p.linkToProfile ? getColor(p, p.theme.color) : undefined};
  }
  a:hover{
    color: ${p => p.linkToProfile ? lightenColor(p, p.theme.color, 10) : undefined};
  }
  img{
    margin-top:4px !important;
  }
`;
const N = styled.div<{ linkToProfile?: boolean }>`
  &:hover{
    color: ${p => p.linkToProfile ? lightenColor(p, p.theme.color, 10) : undefined};
  }
`;

export interface UserTileState {
}

const UserTile = (p: UserTileProps) => {
  const { user, ...rest } = p;
  const avatar = user ? getMediaLink(user, u => u.avatar, "self") : undefined;
  const emptyAvatarImageUrl = useThemePart(t => t.emptyAvatarImageUrl);
  return (
    <C {...rest}>

      {p.showAvatar ?
        <div onClick={user && p.linkToProfile ? gotoNamedRouteMethod("user", { id: user.id }) : undefined} >
          <Image src={avatar ?? emptyAvatarImageUrl} rounded width="20px" floated="left" />
        </div>
        : <div />
      }

      <N onClick={user && p.linkToProfile ? gotoNamedRouteMethod("user", { id: user.id }) : undefined} linkToProfile={p.linkToProfile ?? false}>
        {user ? getTranslatedUserName(user) : undefined}
      </N>


      {p.showEmail && user ?
        <>
          <div>
            <Icon name="mail" style={{ float: "left", marginRight: 5 }} />
          </div>
          <div>
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </div>
        </>
        :
        <>
          <div />
          <div />
        </>
      }

      {(p.isMyself && !p.isMyselfNoCheckIcon) ?
        <div>
          <Icon name="check" marginTop={4} style={{ float: "right" }} />
        </div>
        : <div />
      }


    </C>
  );
}

export default UserTile;