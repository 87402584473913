import { getColor, styled } from 'imaginarity-react-ui';
import * as React from 'react';

export const DonutOuterContainer = styled.div`
    .gridContainer {
  display: grid;
  grid-template-columns: 100px 150px 100px;
  grid-template-rows: repeat(7, 20px);
  gap: 0px 0px;
  grid-template-areas:
    "TextTopLeft TextTopLeft chartTopRight"
    "TextTopLeft TextTopLeft chartTopRight"
    "ChartBottomLeft center chartTopRight"
    "ChartBottomLeft center chartTopRight"
    "ChartBottomLeft center chartTopRight"
    "ChartBottomLeft TextBottomRight TextBottomRight"
    "ChartBottomLeft TextBottomRight TextBottomRight";
}

.chartTopRight { 
    grid-area: chartTopRight; 
    text-align: right;
    svg{
        margin-top: -5px;
    }
    }

.ChartBottomLeft { 
    grid-area: ChartBottomLeft; 
    text-align: left;
    svg{
        margin-left: -10px;
        margin-top: 5px;
    }
    }

.TextTopLeft { 
    grid-area: TextTopLeft; 
    text-align: left;
    }

.TextBottomRight { 
    grid-area: TextBottomRight; 
    text-align: right;
    }

.center { grid-area: center; }

@media all and (-ms-high-contrast:none) {
  .gridContainer {
    display: -ms-grid;
    -ms-grid-columns: 100px 150px 100px;
    -ms-grid-rows: repeat(7, 20px);
  }

  .chartTopRight {
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-row-span: 5;
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-column-span: 1;
    text-align: right;
  }

  .ChartBottomLeft {
    -ms-grid-row: 3;
    grid-row: 3;
    -ms-grid-row-span: 5;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-column-span: 1;
    text-align: left;
  }

  .TextTopLeft {
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-column-span: 2;
    text-align: left;
  }

  .TextBottomRight {
    -ms-grid-row: 6;
    grid-row: 6;
    -ms-grid-row-span: 2;
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-column-span: 2;
    text-align: right;
  }

  .center {
    -ms-grid-row: 3;
    grid-row: 3;
    -ms-grid-row-span: 3;
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-column-span: 1;
  }
}
`;
export const DonutContainer = styled.div`
`;
export const TextContainer = styled.div`
    text-transform: uppercase;
    color: ${props => getColor(props, props.theme.colors.lightGrey)};
    font-size: 1.0rem;
    line-height: 20px;
`;
export const TextPercentage = styled.span<{ color: string }>`
    .colored{
      text-transform: uppercase;
      color: ${props => getColor(props, props.color)};
      font-weight: bolder;
    }
`;

export default class DonutChartClasses extends React.Component<any> { }
