
import { ControllerHelper, PowerBiController, PowerBiObjectDownloadDto, PowerBiPermissionListDownloadDto } from 'collaboration-service';
import InfoBox from 'components/InfoBox/InfoBox';
import TabsBar from 'components/TabsBar/TabsBar';
import { Button, getColor, Loader, styled } from 'imaginarity-react-ui';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import * as React from 'react';
import { gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { useIsInGroup, useIsInSpecificGroup } from 'services/StoreDependantHelpers';

const OuterContainer = styled.div`
    height: calc(100vh - 110px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 30px;
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-top: none;
`;

export type MyHomeReportTab = "GLP" | "CMS" | "GLPAdmin" | "GLPOverview";
export interface MyHomeStartGLPRProps {
}

const MyHomeStartGLPR = (p: MyHomeStartGLPRProps) => {
    console.debug("loading started");
    const [selectedTab, setSelectedTab] = React.useState<MyHomeReportTab>("GLP");
    const [loading, setLoading] = React.useState<boolean>(false);

    const [report, setReport] = React.useState<PowerBiObjectDownloadDto>();
    const [permissions, setPermissions] = React.useState<PowerBiPermissionListDownloadDto>();


    const hasGLP = useIsInGroup("REPORTING");
    const hasGLPAdmin = useIsInSpecificGroup(["fd442634-9741-479d-b21c-919c25395087", "9de2c204-b0d6-436a-9f8a-09dee1817e5d"]);
    //const hasCMS = useIsInGroup("REPORTING");

    const tabsSelect = React.useMemo(() => (idx: number) => {
        if (idx === 0)
            setSelectedTab("GLP");
        if (idx === 1)
            setSelectedTab("GLPAdmin");
        if (idx === 2)
            setSelectedTab("GLPOverview");
        else if (idx === 3)
            setSelectedTab("CMS");
    }, []);

    React.useEffect(() => {
        setLoading(true);
        const l = async () => {
            console.debug("call API");
            console.debug(selectedTab);
            const poBiReport: PowerBiObjectDownloadDto = await ControllerHelper.singleCall({ reportName: selectedTab }, PowerBiController.GetReport, true);

            if (poBiReport.permissionsList !== undefined) {
                setPermissions(poBiReport.permissionsList);
            }
            setReport(poBiReport);


            setLoading(false);
        };
        l();
    }, [selectedTab]);


    const advancedFilter = [{
        $schema: "https://powerbi.com/product/schema#advanced",
        target: {
            table: "GV_PPX_EXPORT_MODUL",
            column: "FilterString"
        },
        logicalOperator: "Or",
        conditions: [{
            operator: "DoesNotStartWith",
            value: "1"
        }],
        filterType: models.FilterType.Advanced
    }]
    const adminFilter = [{
        $schema: "https://powerbi.com/product/schema#advanced",
        target: {
            table: "GV_PPX_EXPORT_MODUL",
            column: "Curriculum_Title"
        },
        logicalOperator: "Or",
        conditions: [{
            operator: "Contains",
            value: "["
        }],
        filterType: models.FilterType.Advanced
    }];

    permissions?.marketList.forEach(element => {
        console.debug("M " + element);
        let con = {
            operator: "Contains",
            value: "1$" + element + "2$"
        }
        advancedFilter[0].conditions.push(con);

    });
    permissions?.countryList.forEach(element => {
        console.debug("C " + element);
        let con = {
            operator: "Contains",
            value: "2$" + element + "3$"
        }
        advancedFilter[0].conditions.push(con);
    });
    permissions?.retailerList.forEach(element => {
        console.debug("R " + element);
        let con = {
            operator: "Contains",
            value: "3$" + element + "4$"
        }
        advancedFilter[0].conditions.push(con);
    });

    const noFilter = advancedFilter[0].conditions.length === 1;

    return (
        <div>
            <Loader active={loading} infoText='loading report data' size='small' light />
            <TabsBar
                input={[
                    {
                        title: "GLP Reporting",
                        icon: "file",
                        active: selectedTab === "GLP",
                        hidden: !hasGLP
                    },
                    {
                        title: "GLP ADMIN Reporting",
                        icon: "file",
                        active: selectedTab === "GLPAdmin",
                        hidden: !hasGLPAdmin
                    },
                    {
                        title: "GLP Overview",
                        icon: "file",
                        active: selectedTab === "GLPOverview",
                        hidden: !hasGLPAdmin
                    },
                    {
                        title: "CMS Reporting",
                        icon: "cms admin",
                        active: selectedTab === "CMS",
                        hidden: true // hasCMS
                    }
                ]}
                onChange={tabsSelect}
                additionalContent={<Button kind="cancelButton" onClick={gotoNamedRouteMethod("myhome")} floated='right' icon='chevron left' />}
                verticalSlider
                activeBold
            // shadowed
            />
            {report ?
                <OuterContainer >
                    <PowerBIEmbed
                        embedConfig={{
                            type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                            id: report.objectId,
                            embedUrl: report.embedUrl,
                            accessToken: report.token,
                            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                            filters: hasGLPAdmin ? adminFilter : !noFilter ? advancedFilter : adminFilter,

                            settings: {
                                filterPaneEnabled: hasGLPAdmin,
                                navContentPaneEnabled: false,
                            }
                        }}

                        cssClassName={
                            "report-style-class"
                        }

                        eventHandlers={
                            new Map([
                                ['loaded', function () { console.log('Report loaded'); }],
                                ['rendered', function () { console.log('Report rendered'); }],
                                ['error', function (event: any) { console.log(event.detail); }],
                                ['visualClicked', () => console.log('visual clicked')],
                                ['pageChanged', (event) => console.log(event)],
                            ])
                        }

                        getEmbeddedComponent={(embeddedReport) => {
                            //this.report = embeddedReport as Report;
                        }}
                    />
                </OuterContainer>
                :
                <div style={{ marginTop: 20 }}>
                    <InfoBox type='alert' content='no data found' icon='exclamation circle' />
                </div>
            }
        </div>
    );

}
export default MyHomeStartGLPR;
