import { DashboardDownloadDto, DashboardElementDownloadDto, DashboardElementTemplateDownloadDto, DashboardLayoutDownloadDto, DashboardPackingMode } from "collaboration-service";
import * as _ from "lodash";
import memoizee from "memoizee";
import * as React from "react";
import { Layout, Layouts, Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import { generateUUID } from "services/Helpers";
import { DashboardSC as T } from "./DashboardSC";

const elementResizeDetectorMaker = require("element-resize-detector");
const erdUltraFast = elementResizeDetectorMaker({
    strategy: "scroll" //<- For ultra performance.
});

export type DashboardLayoutMode = "none" | "horizontal" | "vertical";

export class DashboardHelpers {
    // adds an template element to an existing layout (this is used while dragging some new element into the grid)
    public static AddToLayouts = (template: DashboardElementTemplateDownloadDto, mouseEvent: { clientX: number, clientY: number }, layouts: Layouts | undefined, dashboard: DashboardDownloadDto | undefined) => {
        const el = _.cloneDeep(layouts);
        if (el && template && dashboard) {
            const id = generateUUID();
            _.forEach(el, (v, key) => {
                const maxX = v.reduce((val, item) => (item.x + item.h > val ? item.x + item.h : val), 0);
                const maxY = v.reduce((val, item) => (item.y + item.w > val ? item.y + item.w : val), 0);
                const toAdd = {
                    x: maxX + 1,
                    y: maxY + 1,
                    h: template.standardHeight ?? 1,
                    w: template.standardWidth ?? 1,
                    temp: true,
                    mouseEvent: mouseEvent,
                    i: id,
                    lx: key,
                    content: template,
                    isResizable: template.staticSize === true ? false : true,
                }
                v.push(toAdd);
            });
            return el;
        }
        return undefined;
    };

    // remove all template elements from layout 
    public static ClearTempFromLayouts = (layouts: Layouts | undefined) => {
        const el = _.cloneDeep(layouts);
        const ids: string[] = [];
        if (el) {
            _.forEach(el, v => {
                let idx = _.findIndex(v, vv => (vv as any).temp);
                while (idx >= 0) {
                    ids.push(v[idx].i);
                    v.splice(idx, 1);
                    idx = _.findIndex(v, vv => (vv as any).temp);
                }
            })
            _.forEach(el, v => {
                let idx = _.findIndex(v, vv => ids.indexOf(vv.i) >= 0);
                while (idx >= 0) {
                    v.splice(idx, 1);
                    idx = _.findIndex(v, vv => ids.indexOf(vv.i) >= 0);
                }
            });
            return el;
        }
        return undefined;
    }

    // save dragged element to dashboard and grid
    public static AddToDashboard = (template: DashboardElementTemplateDownloadDto, layouts: Layouts | undefined, dashboard: DashboardDownloadDto | undefined) => {
        const el = _.cloneDeep(layouts);
        const db = _.cloneDeep(dashboard);
        if (el && db) {
            const ls: {
                [key: string]: DashboardLayoutDownloadDto;
            } = {};
            _.forEach(el, (v, k) => {
                let idx = _.findIndex(v, vv => (vv as any).temp);
                while (idx >= 0) {
                    delete (v[idx] as any)["temp"];
                    delete (v[idx] as any)["mouseEvent"];
                    ls[k] = v[idx];
                    idx = _.findIndex(v, vv => (vv as any).temp);
                }
            })
            const dbt: DashboardElementTemplateDownloadDto = template;
            db.elements.push({ ...dbt, layouts: ls, templateReference: dbt.id, diagramId: ls[Object.keys(ls)[0]].i });
            return { layouts: el, dashboard: db };
        }
        return undefined;
    }
    public static getLayoutId = (ele: DashboardElementDownloadDto) => {
        const keys = Object.keys(ele.layouts);
        return keys && keys.length > 0 ? ele.layouts[keys[0]].i : ele.layouts?.i;
    }

}

interface DashboardProps {
    editMode?: boolean;
    editLayouts?: Layouts;
    dashboard?: DashboardDownloadDto;
    templates?: DashboardElementTemplateDownloadDto[];
    editDashboard?: DashboardDownloadDto;
    tileWidth?: number;
    tileHeight?: number;
    children: (key: string, element: DashboardElementDownloadDto, template: DashboardElementTemplateDownloadDto) => JSX.Element;
    tempRenderer: (key: string, layout: Layout, template: DashboardElementTemplateDownloadDto) => JSX.Element;
    onLayoutChange: (allLayouts: Layouts) => void;
}

const calcBpsAndCols = memoizee((width: number, tilefact: number) => {
    const bps: { [P: string]: number } = {};
    const cols: { [P: string]: number } = {};
    for (let i = 1; i <= (1200 / width); i++) {
        const n = `t${i}`;
        bps[n] = i === 1 ? 0 : (i * width) - 1;
        cols[n] = i * tilefact;
    }
    return { bps, cols };
});

export const getLayoutId = (ele: DashboardElementDownloadDto) => {
    const keys = Object.keys(ele.layouts);
    return (keys && keys.length > 0 ? ele.layouts[keys[0]].i : ele.layout?.i) ?? "0";
}

const calcPackingMode = (packingMode?: DashboardPackingMode): 'vertical' | 'horizontal' | null => {
    if (!packingMode || packingMode === "None")
        return null;
    if (packingMode === "Vertical")
        return "vertical";
    return "horizontal";
}

const Dashboard = (p: DashboardProps) => {
    const [width, setWidth] = React.useState(0);
    const [bp, setBp] = React.useState<string>("");

    const olc = p.onLayoutChange;
    const onLayoutChange = React.useMemo(() => {
        return (currentLayout: ReactGridLayout.Layout[], allLayouts: Layouts) => {
            olc(allLayouts);
        }
    }, [olc]);

    const tileWidth = p.tileWidth ?? 100;
    const tileHeight = p.tileHeight ?? 50;
    const { bps, cols } = calcBpsAndCols(tileWidth, 2);


    const setWidthRef = React.useMemo(() => (ref?: any) => {
        const div = ref as HTMLDivElement;
        if (div) {
            erdUltraFast.listenTo({}, div, (ele: HTMLDivElement) => {
                setWidth(Math.floor(ele?.offsetWidth ?? 0));
            })
        }
    }, [setWidth]);


    const dashboard = p.editMode && p.editDashboard ? p.editDashboard : p.dashboard;
    const { editLayouts } = p;
    const layouts = React.useMemo(() => {
        const layouts: Layouts = {};
        _.forEach(dashboard?.elements, e => {
            _.forEach(e.layouts, (l, name) => {
                if (!layouts[name])
                    layouts[name] = [];
                layouts[name].push(l);
            });
        });

        const keys = Object.keys(layouts);
        _.forEach(dashboard?.elements, e => {
            if (e.layout) {
                const layout = e.layout;
                _.forEach(keys, k => {
                    const idx = _.findIndex(layouts[k], l => l.i === layout?.i);
                    if (idx < 0)
                        layouts[k].push(layout);
                })
            }
        });

        const toRet: Layouts = {};
        _.forEach(bps, (b, name) => {
            toRet[name] = layouts[name] ?? [];
        })
        if (Object.keys(layouts).length === 0 && editLayouts === undefined)
            olc(toRet);
        return toRet;
    }, [bps, dashboard, olc, editLayouts]
    );


    //const layouts = getLayouts(dashboard);
    if (bp === "") {
        let curBp = "";
        _.forEach(bps, (w, n) => {
            if (w > width && curBp === "")
                curBp = n;
        });
        if (curBp !== "")
            setBp(curBp);
    }

    let tempsBp = bp;
    let tempsIdx = _.findIndex((p.editLayouts ?? {})[bp], e => (e as any).temp === true);
    let tempsId = ((p.editLayouts ?? {})[bp] ?? [])[tempsIdx]?.i;
    if (!tempsId && p.editMode && p.editLayouts) {
        const el = p.editLayouts;
        _.forEach(Object.keys(el), key => {
            const idx = _.findIndex(el[key], l => (l as any).temp === true);
            if (idx >= 0) {
                tempsIdx = idx;
                tempsBp = key;
                tempsId = el[key][idx].i;
                return false;
            }
        })
    }

    const temps = tempsId && p.editMode && p.editLayouts ? _.filter(p.editLayouts[bp], e => e.i === tempsId) : [];
    const dbt = tempsId && p.editMode && p.editLayouts && tempsBp && tempsIdx >= 0 ? (p.editLayouts[tempsBp][tempsIdx] as any).content as DashboardElementTemplateDownloadDto : undefined;
    //    const toRender = React.useMemo(() => {
    const toRender = _.filter(_.map(dashboard?.elements, e => {
        if (e.templateReference) {
            const t = _.find(p.templates, tt => tt.id === e.templateReference);
            if (t) {
                const skip = ["id", "layout", "layouts"]
                if (!t.usesGlobalConfiguration)
                    skip.push("elementConfiguration");
                const ele = _.cloneDeep(e);
                const keys = Object.keys(ele);
                _.forEach(keys, k => {
                    if (skip.indexOf(k) < 0 && t.hasOwnProperty(k)) {
                        (ele as any)[k] = (t as any)[k];
                    }
                });
                // more fields!
                return p.children(getLayoutId(e), ele, t);
            }
            return null;
        }
    }), ff => ff !== null && ff !== undefined);
    //}, [dashboard?.elements, p.children, p.templates]);
    const { tempRenderer } = p;
    const tt = JSON.stringify(temps);
    const toAdd = React.useMemo(() => {
        const _temps = JSON.parse(tt);
        return _.filter(_.map(_temps, t => {
            const lay = _.cloneDeep(t);
            const me = (lay as any).mouseEvent;
            if (me) {
                me.clientX -= lay.w * tileWidth * 0.25;
                me.clientY -= lay.h * tileHeight * 0.5;
            }
            if (dbt)
                return tempRenderer(t.i, lay, dbt);
            return null;
        }), ff => ff !== null && ff !== undefined)
    }, [tt, tempRenderer, dbt, tileHeight, tileWidth]);

    toRender.push(...toAdd);
    //    const render = memoizee(() =>
    return (
        <T.Container tileWidth={tileWidth} ref={setWidthRef}>
            <ResponsiveGridLayout
                preventCollision={true}
                margin={[0, 0]}
                onLayoutChange={onLayoutChange}
                className="layout"
                layouts={p.editMode ? (p.editLayouts ?? layouts) : layouts}
                breakpoints={bps}
                onBreakpointChange={(newBp) => setBp(newBp)}
                cols={cols}
                width={width}
                rowHeight={tileHeight}
                compactType={calcPackingMode(dashboard?.packingMode)}
                isDraggable={p.editMode}
                isResizable={p.editMode}
            //onDrag={printIt}
            >
                {toRender}
            </ResponsiveGridLayout>
        </T.Container>
    );
}

//(Dashboard as any).whyDidYouRender = true;

export default Dashboard;