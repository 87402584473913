
import { fadeColor, fadeIn, getColor, styled, TextArea } from 'imaginarity-react-ui';

export class CommentsViewSC {
    public static NewCommentButtonContainer = styled.div<{ color: string }>`
    display: block;
    margin-top:1em;
    padding-bottom:1rem;
    padding-left:1rem;
    width: 100%;
    border-left:3px solid ${props => props.color};
`;
    public static NewCommentButtonHeadline = styled.div`
    font-size:0.9rem;
    font-weight: bolder;
    line-height: 40px;
    animation: ${fadeIn} 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
`;
    public static NewCommentBox = styled.div<{ bgColor: string }>`
    display: block;
    width: 100%;
    height:80px;
    background: ${props => fadeColor(props, getColor(props, props.bgColor), 3)};
    border: 1px solid ${props => getColor(props, props.bgColor)};
    position: relative;
    animation: ${fadeIn} 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
`;
    public static NewCommentBoxLeft = styled.div`
    position: absolute;
    top:0;
    left: 0;
    width: calc(100% - 40px);
    height:80px;
    padding:10px;
`;
    public static CommentTextArea = styled(TextArea)`
    height:60px;
    font-size:0.8rem;
    color: ${props => getColor(props, props.theme.colors.mainForeground)};
    font-weight: 400;
    padding:0;
`;
    public static NewCommentBoxRight = styled.div<{ bgColor: string }>`
    position: absolute;
    top:0;
    right: 0;
    width:40px;
    background: ${props => fadeColor(props, getColor(props, props.bgColor), 5)};
    height:80px;
`;
    public static RatingSelectContainer = styled.div<{ color: string }>`
    display: block;
    svg{
        stroke: ${props => getColor(props, props.color)};
        fill: ${props => fadeColor(props, getColor(props, props.color), 50)};
        &:hover{
            stroke: ${props => getColor(props, props.color)};
            fill: ${props => fadeColor(props, getColor(props, props.color), 80)};
        }
    }
`;
}