import { CorrelatePairsQuestionDownloadDto, FourPicturesOneSolutionQuestionDownloadDto, GameController, MediaDownloadDto, MultipleChoiceQuestionDownloadDto, QuestionDownloadDto, SortQuestionDownloadDto, UserAnswer, UserShortInfoDownloadDto } from "collaboration-service";
import InfoBox from "components/InfoBox/InfoBox";
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { aboveBreakpoint, belowBreakpointOrEqual, Button, ButtonGroup, Dimmer, fadeColor, Icon, Image, ImgThemeConsumer, Loader, Media, Modal, TooltippedElement, URLMedia } from "imaginarity-react-ui";
import _ from "lodash";
import Carousel from "nuka-carousel";
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { downloadMedia, getMediaLink } from "services/Helpers/MediaHelpers";
import { gotoNamedRoute } from "services/Helpers/RoutingHelper";
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { ExtendedAnswerQuestionResultDto } from "../GameEngine";
import QuizButton from "../QuizButton";
import QuizFooterInfo from "../QuizFooterInfo";
import { QuizSC as T } from '../QuizSC';
import CorrelatePairsQuestion from './CorrelatePairsQuestion';
import EstimateQuestion from './EstimateQuestion';
import FourPicturesOneSolutionQuestion from "./FourPicturesOneSolutionQuestion";
import MarkAreaInPictureQuestion from './MarkAreaInPictureQuestion';
import MarkInPictureQuestion from './MarkInPictureQuestion';
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion';
import QuestionIndicator from "./QuestionIndicator";
import QuestionInfo from "./QuestionInfo";
import SortQuestion from './SortQuestion';
import TimeIndicator from "./TimeIndicator";


export interface LoadState {
    media: MediaDownloadDto;
    done: boolean;
    url?: string;
}
export const getMediaFromLoadState = (states: LoadState[] | undefined, idx: number): URLMedia | undefined => {
    if (idx < 0)
        return undefined;
    if (!states || states.length <= idx)
        return undefined;
    const state = states[idx];
    if (!state.media || !state.url || !state.done)
        return undefined;
    return ({ url: state.url, type: state.media.mediaType });
};

export enum GameState {
    leaveUnfinished,
    cancelGame,
    continue,
    showModal
}
const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage });

export interface QuestionProps extends ComponentWithMappedApplicationState<typeof mapper>, ImgI18NTranslatedComponentProps {
    gameid?: string;
    question: QuestionDownloadDto;
    displayCorrect?: boolean;
    children?: JSX.Element | JSX.Element[] | string;
    answers?: Array<ExtendedAnswerQuestionResultDto | undefined>;
    questionNumber?: number;
    numberOfQuestions?: number;
    preLoaded?: LoadState[];
    players?: UserShortInfoDownloadDto[];
    playersAnswered?: UserShortInfoDownloadDto[];
    isPpeQuiz?: boolean;
    goOn: (answer: UserAnswer) => void;
    getLoaded?: (loaded: LoadState[]) => void;
    closeModal?: () => void;
}
export interface QuestionImplementationProps extends QuestionProps {
    answer?: (answer: UserAnswer) => void;
    loaded?: LoadState[];
    timeIsOver: boolean;
}

export interface QuizType {
    type: string;
    title: string;
    iconUri: string;
    instruction: string;
    hideEnlargeHint?: boolean;
}
const ActualQuizType: QuizType[] = [
    {
        type: "MultipleChoiceQuestion",
        title: "Multiple Choice",
        iconUri: "./images/Quiz/iconMultipleChoice.svg",
        instruction: "Mark the <b>correct answers</b> by clicking on the corresponding box. If more than one answer is correct, click on them as well. To <b>deselect</b>, click on the box again."
    },
    {
        type: "SingleChoiceQuestion",
        title: "Single Choice",
        iconUri: "./images/Quiz/iconSingleChoice.svg",
        instruction: "Mark the <b>correct answers</b> by clicking on the corresponding box. If more than one answer is correct, click on them as well. To <b>deselect</b>, click on the box again."
    },
    {
        type: "FourPicturesOneSolutionQuestion",
        title: "4 Pictures 1 Solution",
        iconUri: "./images/Quiz/iconFourPictures.svg",
        instruction: "All four pictures have <b>one thing</b> in common. Click on the <b>selectable letters</b> to find the solution word. To delete a letter, click on it again."
    },
    {
        type: "CorrelatePairsQuestion",
        title: "Correlate Pairs",
        iconUri: "./images/Quiz/iconCorrelatePairs.svg",
        instruction: "Arrange the boxes in the <b>right column</b> in the correct order by <b>clicking and holding</b> them, then drag them to the correct place and <b>release</b> it."
    },
    {
        type: "SortQuestion",
        title: "Sort Items",
        iconUri: "./images/Quiz/iconSortQuestion.svg",
        instruction: "Arrange the boxes in the in the correct order by <b>clicking and holding</b> them, then drag them to the correct place and <b>release</b> it."
    },
    {
        hideEnlargeHint: true,
        type: "MarkAreaInPictureQuestion",
        title: "Mark Area in Picture",
        iconUri: "./images/Quiz/iconMarkInPic.svg",
        instruction: "Mark the correct <b>area(s)</b> by clicking on the image in the <b>appropriate area</b>. To deselect, click on this area again."
    },
    {
        hideEnlargeHint: true,
        type: "MarkInPictureQuestion",
        title: "Mark in Picture",
        iconUri: "./images/Quiz/iconMarkInPic.svg",
        instruction: "Above the picture you will see a <b>grid</b>, mark the correct <b>box(es)</b> by clicking on them. To deactivate, simply click on it again. A frame will appear around your selection to represent your choice."
    },
    {
        type: "EstimateQuestion",
        title: "Estimate Solution",
        iconUri: "./images/Quiz/iconEstimateQuestion.svg",
        instruction: "Move the <b>slider</b> to the estimated figure. Your selected answer with units is displayed above the slider."
    }
];
const Question = (props: QuestionProps) => {
    const [showTimeIsUpInfo, setShowTimeIsUpInfo] = React.useState(false);
    const [showInstructions, setInstructions] = React.useState(false);
    const [answer, setAnswer] = React.useState<UserAnswer>({ id: "" });
    const [decreaseView, setDecreaseView] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [modal, setModal] = React.useState<boolean>(false);
    const [loaded, setLoaded] = React.useState<LoadState[]>([]);
    const [timer, setTimer] = React.useState<boolean>(false);
    const { questionNumber, question, preLoaded } = props;
    const rightAnswer = (props.answers ?? [])[props.questionNumber ?? 0]?.rightAnswer;
    const { getLoaded } = props;

    React.useEffect(() => {
        setAnswer({ id: "" });
    }, [questionNumber]);

    const initLoaded = (toLoad: MediaDownloadDto[]) => {
        return toLoad.map(v => (
            {
                done: false,
                link: v ? _.find(v.links, l => l.ref === "self") : undefined,
                media: v,
            }));
    };
    const loadMedia = React.useMemo(() => (loaded: LoadState[]) => {
        const copy = _.clone(loaded);
        const p: Array<Promise<{ url: string, userData: number, ok: boolean }>> = loaded.map((l, idx) =>
            downloadMedia<number>(l.media, idx));
        Promise.all(p).then(res => {
            res.forEach(r => {
                if (r.ok) {
                    copy[r.userData].done = true;
                    copy[r.userData].url = r.url;
                }
                else {
                    copy[r.userData].done = false;
                    copy[r.userData].url = undefined;
                }
            });
            setLoaded(copy);
            setTimer(true);
            if (getLoaded)
                getLoaded(copy);
        });
    }, [setLoaded, setTimer, getLoaded]);

    React.useEffect(() => {
        if (preLoaded && preLoaded.length !== 0) {
            setLoaded(preLoaded);
            return; // case GameStates.ShowAnswer, all medias are already loaded 
        }
        setTimer(false);
        const type = question?.type ?? "";
        let toLoad: MediaDownloadDto[] = [question.media];
        if (type === 'MultipleChoiceQuestion') {
            const data = question as MultipleChoiceQuestionDownloadDto;
            _.forEach(data.possibleAnswers, a => { if (a.media) toLoad.push(a.media); });
        }
        else if (type === 'CorrelatePairsQuestion') {
            const data = question as CorrelatePairsQuestionDownloadDto;
            _.forEach(data.possibleAnswers, a => { if (a.media) toLoad.push(a.media); });
        }
        else if (type === 'FourPicturesOneSolutionQuestion') {
            const data = question as FourPicturesOneSolutionQuestionDownloadDto;
            toLoad.push(data.media1);
            toLoad.push(data.media2);
            toLoad.push(data.media3);
        }
        else if (type === 'SortQuestion') {
            const data = question as SortQuestionDownloadDto;
            _.forEach(data.possibleAnswers, a => { if (a.media) toLoad.push(a.media); });
        }
        loadMedia(initLoaded(toLoad));

    }, [question, preLoaded, loadMedia]);

    const qProps = React.useMemo(() => ({ ...props, answer: setAnswer, loaded, timeIsOver: showTimeIsUpInfo }), [props, loaded, setAnswer, showTimeIsUpInfo]);
    const type = props.question?.type ?? "";
    const renderImageAndAnswers = React.useMemo(() => () => {
        switch (type) {
            case 'MultipleChoiceQuestion':
                return <MultipleChoiceQuestion {...qProps} />;
            case 'FourPicturesOneSolutionQuestion':
                return <FourPicturesOneSolutionQuestion {...qProps} />;
            case "CorrelatePairsQuestion":
                return <CorrelatePairsQuestion {...qProps} />;
            case "SortQuestion":
                return <SortQuestion {...qProps} />;
            case "MarkAreaInPictureQuestion":
                return <MarkAreaInPictureQuestion {...qProps} />;
            case "MarkInPictureQuestion":
                return <MarkInPictureQuestion {...qProps} />;
            case "EstimateQuestion":
                return <EstimateQuestion {...qProps} />;
            default:
                return <div>this type of question is not implemented yet</div>;
        }
    }, [type, qProps]);

    const getQuizTypeModal = React.useMemo(() => () => {
        return <QuestionInfo
            active={showInstructions}
            quizType={_.find(ActualQuizType, t => t.type === type)}
            onClick={() => setInstructions(false)}
        />;
    }, [type, showInstructions]);

    const goOn = () => {
        if (showTimeIsUpInfo) {
            setShowTimeIsUpInfo(false); // reset timer for next
        }
        setDecreaseView(false);
        props.goOn(answer);
    }
    const cancelQuiz = React.useCallback((value: GameState) => {
        if (value === GameState.continue)
            setModal(false);
        else if (value === GameState.showModal)
            setModal(true);
        else if (value === GameState.cancelGame) {
            if (props.gameid) {
                GameController.DeleteGame(
                    { gameId: props.gameid },
                    (result) => { },
                    (error) => {
                        console.log("error", error);
                    }
                );
                gotoNamedRoute("quiz");
            }
        }
        else if (value === GameState.leaveUnfinished) {
            gotoNamedRoute("quiz");
        }
        else {
            console.log("undefined state, take a look");
        }
    }, [props.gameid]);
    const closeModal = props.closeModal;

    const cancelQuizFromWiki = React.useCallback((value: GameState) => {
        if (value === GameState.cancelGame) {
            if (props.gameid) {
                GameController.DeleteGame(
                    { gameId: props.gameid },
                    (result) => { },
                    (error) => {
                        console.log("error", error);
                    }
                );
                if (closeModal)
                    closeModal();
            }
        }
        else if (value === GameState.leaveUnfinished) {
            gotoNamedRoute("quiz");
        }
        else {
            console.log("undefined state, take a look");
        }
    }, [props.gameid, closeModal]);

    const keyHit = React.useCallback((event: KeyboardEvent) => {
        if (event.code === 'Escape') {
            if (closeModal)
                cancelQuizFromWiki(GameState.cancelGame);

            else
                cancelQuiz(GameState.cancelGame);
        }
        else if (event.code === 'F8') {
            cancelQuiz(GameState.continue);
        }
        else if (event.code === 'F9') {
            cancelQuiz(GameState.leaveUnfinished);
        }
    }, [cancelQuiz, closeModal, cancelQuizFromWiki]);


    React.useEffect(() => {
        document.addEventListener("keydown", keyHit, false);
        return () => {
            document.removeEventListener("keydown", keyHit, false);
        };
    }, [keyHit]);

    if (props.question === undefined) {
        return (
            <InfoBox content={props.t("played and finished")} icon="info" type="warning" />
        );
    }
    const timeIsOver = () => {
        setShowTimeIsUpInfo(true);
    }
    //console.log('props.displayCorrect', props.displayCorrect);

    return (
        <ImgThemeConsumer>
            {(theme) => {
                const isTablet = belowBreakpointOrEqual({ theme }, "tablet") && aboveBreakpoint({ theme }, "mobile");
                const isMobile = belowBreakpointOrEqual({ theme }, "mobile");
                const timeDiameter = isMobile ? 35 : isTablet ? 45 : 50;
                const imageAvailable = props.question.media ? props.question.media.links.length > 0 : false;
                const imageHeight = isMobile ? 80 : 150;
                const footer = <>
                    {props.players &&
                        <T.FooterUsers>
                            {_.map(props.players, (p) => {
                                const avatar = getMediaLink(p, y => y.avatar) ?? "";
                                const didAnswer = _.find(props.playersAnswered, pa => pa.id === p.id) ? true : false;
                                return (
                                    <T.FooterUsersImage didAnswer={didAnswer}>
                                        <Image
                                            src={avatar}
                                            rounded
                                            width="30px"
                                            squared
                                            shadowed={didAnswer}
                                        />
                                    </T.FooterUsersImage>
                                );
                            })}
                        </T.FooterUsers>
                    }
                    <div style={{ height: (!isTablet && !isMobile) ? 5 : 2 }} />
                    <div style={{ position: "absolute", top: "50%", left: 0, transform: "translateY(-50%)" }}>
                        {isTablet || isMobile ?
                            <Button
                                kind="fullTransparentButton"
                                icon={decreaseView ? "decrease" : "expand"}
                                floated="left"
                                size="large"
                                onClick={() => setDecreaseView(decreaseView ? false : true)}
                            />
                            :
                            <div style={{ marginLeft: -17, float: "left", width: 50 }}>
                                <TooltippedElement
                                    element={Button}
                                    position="top-start"
                                    scheme="secondary"
                                    tooltipContent={props.t("{{view}} view", { view: decreaseView ? "decrease" : "expand" })}
                                    icon={decreaseView ? "decrease" : "expand"}
                                    kind="fullTransparentButton"
                                    size="large"
                                    onClick={() => setDecreaseView(decreaseView ? false : true)}
                                />
                            </div>
                        }
                    </div>
                    <QuizButton text1={props.t("next")} onClick={goOn} diameter={isMobile ? 50 : 90} />
                </>;
                const header = <T.Header>
                    <div style={{ marginLeft: -17, float: "left", width: 100 }}>
                        <TooltippedElement
                            element={Button}
                            position="bottom-start"
                            scheme="secondary"
                            tooltipContent={props.t("cancel game")}
                            icon="times"
                            kind="fullTransparentButton"
                            size="large"
                            onClick={props.isPpeQuiz ? () => cancelQuizFromWiki(GameState.cancelGame) : () => cancelQuiz(GameState.showModal)}
                        />
                        <TooltippedElement
                            element={Button}
                            position="bottom-start"
                            scheme="secondary"
                            tooltipContent={props.t("show instructions")}
                            icon="questionark"
                            kind="fullTransparentButton"
                            size="large"
                            onClick={() => setInstructions(true)}
                        />
                    </div>
                    {props.answers &&
                        <QuestionIndicator
                            current={props.questionNumber ?? 0}
                            count={props.numberOfQuestions ?? 0}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            paddingRight={timeDiameter}
                            answers={props.answers}
                        />
                    }
                    {timer &&
                        <TimeIndicator
                            maxTime={props.displayCorrect ? 0 : props.question.time ?? 999}
                            onTimeOver={timeIsOver}
                            diameter={timeDiameter}
                        />
                    }
                    {props.displayCorrect &&
                        <T.ResultInfo>
                            <Icon name="bulb" size={24} color={rightAnswer ? "@accentGreen" : "@accentRed"} />
                        </T.ResultInfo>
                    }
                </T.Header>;
                return (
                    <>
                        {isMobile ?
                            <T.QuizBackgroundMobileAlternative>
                                {/* <T.QuizBackgroundMobileGrid> */}
                                <T.HeaderMobileAlternative>
                                    <div style={{ float: "left", width: 100 }}>
                                        <TooltippedElement
                                            element={Button}
                                            position="bottom-start"
                                            scheme="secondary"
                                            tooltipContent={props.t("cancel game")}
                                            icon="times"
                                            kind="fullTransparentButton"
                                            size="large"
                                            onClick={props.isPpeQuiz ? () => cancelQuizFromWiki(GameState.cancelGame) : () => cancelQuiz(GameState.showModal)}
                                        />
                                        <TooltippedElement
                                            element={Button}
                                            position="bottom-start"
                                            scheme="secondary"
                                            tooltipContent={props.t("show instructions")}
                                            icon="questionark"
                                            kind="fullTransparentButton"
                                            size="large"
                                            onClick={() => setInstructions(true)}
                                        />
                                    </div>
                                    {props.answers &&
                                        <QuestionIndicator
                                            current={props.questionNumber ?? 0}
                                            count={props.numberOfQuestions ?? 0}
                                            isMobile={isMobile}
                                            isTablet={isTablet}
                                            paddingRight={timeDiameter + 5}
                                            answers={props.answers}
                                        />
                                    }
                                    {timer &&
                                        <TimeIndicator
                                            maxTime={props.displayCorrect ? 0 : props.question.time ?? 999}
                                            onTimeOver={timeIsOver}
                                            diameter={timeDiameter}
                                            style={{ right: 5 }}
                                        />
                                    }
                                    {props.displayCorrect &&
                                        <T.ResultInfo style={{ right: 11 }}>
                                            <Icon name="bulb" size={24} color={rightAnswer ? "@accentGreen" : "@accentRed"} />
                                        </T.ResultInfo>
                                    }
                                </T.HeaderMobileAlternative>

                                <T.FunctionsMobileAlternative>

                                    {!decreaseView &&
                                        <T.TitleMobile>
                                            {getTranslated(props.question.headlines, props.contentLanguage)?.text ?? ""}
                                        </T.TitleMobile>
                                    }
                                    {!decreaseView &&
                                        <T.DescriptionMobile>
                                            {getTranslated(props.question.descriptions, props.contentLanguage)?.text ?? ""}
                                        </T.DescriptionMobile>
                                    }
                                    {!decreaseView && props.question.type !== "MarkInPictureQuestion" && props.question.type !== "MarkAreaInPictureQuestion" && props.question.type !== "FourPicturesOneSolutionQuestion" &&
                                        <T.ImageMobile >
                                            {(timer || props.displayCorrect) ?
                                                <Media
                                                    media={getMediaFromLoadState(loaded, 0) ?? { url: "", type: "" }}
                                                    controls
                                                    width="100%"
                                                    imageModal
                                                    withImageBackground
                                                    imageBackgroundHeight={imageHeight}
                                                />
                                                :
                                                <Loader active size="small" light infoText={props.t("loading media(s)")} />
                                            }
                                        </T.ImageMobile>
                                    }
                                    {!decreaseView && props.question.type === "FourPicturesOneSolutionQuestion" &&
                                        <>
                                            {timer ?
                                                <T.Image style={{ padding: "10px 0" }}>
                                                    {/* <Gallery lightbox layout={3} fillImage smallGap button >
                                                            <Image src={getMediaFromLoadState(loaded, 0)?.url ?? ""} />
                                                            <Image src={getMediaFromLoadState(loaded, 1)?.url ?? ""} />
                                                            <Image src={getMediaFromLoadState(loaded, 2)?.url ?? ""} />
                                                            <Image src={getMediaFromLoadState(loaded, 3)?.url ?? ""} />
                                                        </Gallery> */}
                                                    <Carousel
                                                        style={{
                                                            position: "absolute",
                                                            top: 0,
                                                            left: 0,
                                                            right: 0,
                                                            padding: 0,
                                                        }}
                                                        slidesToShow={2}
                                                        height="95px"
                                                        cellAlign="left"
                                                        cellSpacing={10}
                                                        autoGenerateStyleTag
                                                        autoplay
                                                        dragging={false}
                                                        swiping={false}
                                                        wrapAround
                                                        renderCenterLeftControls={({ previousSlide }) => (
                                                            <div onClick={previousSlide} >
                                                                <Icon name="chevron left" size="5em" />
                                                            </div>
                                                        )}
                                                        renderCenterRightControls={({ nextSlide }) => (
                                                            <div onClick={nextSlide}>
                                                                <Icon name="chevron right" size="5em" />
                                                            </div>
                                                        )}
                                                        renderBottomCenterControls={() =>
                                                            <div className="bullets" />
                                                        }
                                                    >
                                                        <Image src={getMediaFromLoadState(loaded, 0)?.url ?? ""} />
                                                        <Image src={getMediaFromLoadState(loaded, 1)?.url ?? ""} />
                                                        <Image src={getMediaFromLoadState(loaded, 2)?.url ?? ""} />
                                                        <Image src={getMediaFromLoadState(loaded, 3)?.url ?? ""} />
                                                    </Carousel>
                                                </T.Image>
                                                :
                                                <Loader active size="small" light infoText={props.t("loading media(s)")} />
                                            }
                                        </>
                                    }

                                    <div style={{ display: "grid", gridTemplateRows: "0 0 0 0 1fr", width: "100%", height: "auto" }}>
                                        {renderImageAndAnswers()}
                                    </div>

                                    {showTimeIsUpInfo &&
                                        <QuizFooterInfo
                                            text={props.t("time is up")}
                                            warning={true}
                                            delay={0.5}
                                            duration={4}
                                        />
                                    }
                                </T.FunctionsMobileAlternative>
                                <T.FooterMobileAlternative className="Footer">
                                    {footer}
                                </T.FooterMobileAlternative>
                                {/* </T.QuizBackgroundMobileGrid> */}
                            </T.QuizBackgroundMobileAlternative>

                            :
                            <T.QuizBackground style={{
                                backgroundColor: props.isPpeQuiz ? "transparent" : theme.colors.darkGrey,
                                backgroundImage: props.isPpeQuiz ? "none" : "",
                                textAlign: "left",
                                position: "fixed",
                                inset: 0,
                                zIndex: 2147483637

                            }}>
                                <T.ContainerBackgroundBlur style={{
                                    background: props.isPpeQuiz ? theme.colors.mainBackground : fadeColor({ theme }, theme.colors.mainBackground, 50)
                                }}
                                    isPpeQuiz={props.isPpeQuiz}
                                >
                                    <T.ContainerGrid imageAvailable={imageAvailable} decreaseView={decreaseView}>

                                        {header}
                                        {!decreaseView &&
                                            <T.Title rightAnswer={rightAnswer}  >
                                                {getTranslated(props.question.headlines, props.contentLanguage)?.text ?? ""}
                                            </T.Title>
                                        }
                                        {!decreaseView &&
                                            <T.Description>
                                                {getTranslated(props.question.descriptions, props.contentLanguage)?.text ?? ""}
                                            </T.Description>
                                        }
                                        {!decreaseView && props.question.type !== "MarkInPictureQuestion" && props.question.type !== "MarkAreaInPictureQuestion" && props.question.type !== "FourPicturesOneSolutionQuestion" &&
                                            <T.Image >
                                                {(timer || props.displayCorrect) ?
                                                    <Media
                                                        media={getMediaFromLoadState(loaded, 0) ?? { url: "", type: "" }}
                                                        controls
                                                        width="100%"
                                                        imageModal
                                                        withImageBackground
                                                        imageBackgroundHeight={imageHeight}
                                                        height="150px"
                                                    />
                                                    :
                                                    <Loader active size="small" light infoText={props.t("loading media(s)")} />
                                                }
                                            </T.Image>
                                        }
                                        {!decreaseView && props.question.type === "FourPicturesOneSolutionQuestion" &&
                                            <>
                                                {(timer || props.answers !== undefined) ?
                                                    <T.Image style={{ padding: "10px 0" }} >
                                                        {/* <Gallery lightbox layout={3} fillImage smallGap button >
                                                            <Image src={getMediaFromLoadState(loaded, 0)?.url ?? ""} />
                                                            <Image src={getMediaFromLoadState(loaded, 1)?.url ?? ""} />
                                                            <Image src={getMediaFromLoadState(loaded, 2)?.url ?? ""} />
                                                            <Image src={getMediaFromLoadState(loaded, 3)?.url ?? ""} />
                                                        </Gallery> */}
                                                        <Carousel
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                right: 0,
                                                                height: "100%",
                                                                padding: 0,
                                                            }}
                                                            slidesToShow={2}
                                                            height="137px"
                                                            cellAlign="left"
                                                            cellSpacing={10}
                                                            autoplay
                                                            autoGenerateStyleTag
                                                            wrapAround
                                                            renderCenterLeftControls={({ previousSlide }) => (
                                                                <div onClick={previousSlide}>
                                                                    <Icon name="chevron left" size="5em" />
                                                                </div>
                                                            )}
                                                            renderCenterRightControls={({ nextSlide }) => (
                                                                <div onClick={nextSlide}>
                                                                    <Icon name="chevron right" size="5em" />
                                                                </div>
                                                            )}
                                                            renderBottomCenterControls={({ slideCount, currentSlide, goToSlide }) =>
                                                                <div className="bullets" />
                                                            }
                                                        >
                                                            <Image src={getMediaFromLoadState(loaded, 0)?.url ?? ""} />
                                                            <Image src={getMediaFromLoadState(loaded, 1)?.url ?? ""} />
                                                            <Image src={getMediaFromLoadState(loaded, 2)?.url ?? ""} />
                                                            <Image src={getMediaFromLoadState(loaded, 3)?.url ?? ""} />
                                                        </Carousel>
                                                    </T.Image>
                                                    :
                                                    <Loader active size="small" light infoText={props.t("loading media(s)")} />
                                                }
                                            </>
                                        }
                                        {renderImageAndAnswers()}
                                        <T.Footer className="Footer">
                                            {footer}
                                        </T.Footer>
                                        {showTimeIsUpInfo &&
                                            <T.FooterHint>
                                                <QuizFooterInfo
                                                    text={props.t("time is up")}
                                                    warning={true}
                                                    delay={0.5}
                                                    duration={4}
                                                />
                                            </T.FooterHint>
                                        }
                                    </T.ContainerGrid>
                                </T.ContainerBackgroundBlur>
                            </T.QuizBackground>


                        }












                        <Dimmer active={showModal} onClick={() => setShowModal(false)}  >
                            <Media
                                media={getMediaFromLoadState(loaded, 0) ?? { url: "", type: "" }}
                                width="100%"
                            />
                        </Dimmer>




                        {getQuizTypeModal()}
                        <Modal
                            active={modal}
                            header={props.t("attention")}
                            footer={
                                <Button kind="secondary" floated="right" icon="times" onClick={() => cancelQuiz(GameState.continue)} />
                            }
                            width="small"
                            height="large"
                            light
                            shadow
                            icon="exclamation circle"
                        >
                            <p>{props.t("you can continue, leave unfinished (and play later) or ultimately cancel this game.")}</p>
                            <ButtonGroup vertical fluid spacing={0} >
                                <Button kind="cancelButton" content={props.t("continue this game") + ' [F8]'} onClick={() => cancelQuiz(GameState.continue)} />
                                <Button kind="cancelButton" content={props.t("leave and play later") + ' [F9]'} onClick={() => cancelQuiz(GameState.leaveUnfinished)} />
                                <Button kind="cancelButton" content={props.t("cancel this game permanently") + ' [Esc]'} onClick={() => cancelQuiz(GameState.cancelGame)} />
                            </ButtonGroup>
                        </Modal>
                    </>
                );
            }
            }
        </ImgThemeConsumer>
    );
}
export default translate("question")(connect(mapper)(Question));
