import { PostDownloadDto } from "collaboration-service";
import HubConnection from "./HubConnection";
import * as _ from "lodash";
// import { config } from "./Config";
// import { store } from "./Reducer";

export type postUpdateCallback = (post: PostDownloadDto, changeType: PostHubEventType) => void;
export type PostHubEventType = "update" | "add" | "delete" | "lock" | "unlock" | "notification";

export class PostHubRegistration {
    private _signedIds: string[];
    public constructor(private _callback: postUpdateCallback, private _instance: PostHubConnection, private _types: Array<PostHubEventType | undefined>) {
        this._signedIds = [];
    }

    public signForIds = async (ids: string[] | undefined) => {
        const toSign = _.difference(ids, this._signedIds);
        const toUnsign = _.difference(this._signedIds, ids ?? []);
        await this._instance.unsignForId(toUnsign);
        this._signedIds = _.difference(this._signedIds, toUnsign);
        let newSign: string[] = [];
        if (ids && ids.length > 0)
            newSign = await this._instance.signForId(ids, toSign);
        this._signedIds.push(...newSign);
    }

    public unsubscribe = async () => {
        this._instance.unsignForId(this._signedIds);
        _.forEach(this._types, t => this._instance.unsubscribe(this._callback, t));
    }


}
class PostHubConnection {
    public currentIds: { [id: string]: number } = {};
    private hubConnection: HubConnection;
    private subscribers: { [changeType: string]: postUpdateCallback[] };
    private static _instance: PostHubConnection = new PostHubConnection();
    public static getInstance = () => {
        return PostHubConnection._instance;
    }
    private constructor() {
        this.hubConnection = new HubConnection();
        this.setupRegistrations();
        this.subscribers = {
            update: [],
            add: [],
            delete: [],
            lock: [],
            unlock: [],
            notification: [],
        };
    }

    public subscribe = (f: postUpdateCallback, changeType?: PostHubEventType): PostHubRegistration => {
        if (_.findIndex(this.subscribers[changeType ?? "update"], s => s === f) < 0)
            this.subscribers[changeType ?? "update"].push(f);
        return new PostHubRegistration(f, this, [changeType]);
    }

    public subscribeMultiple = (f: postUpdateCallback, changeTypes: PostHubEventType[]): PostHubRegistration => {
        _.forEach(changeTypes, changeType => this.subscribe(f, changeType));
        return new PostHubRegistration(f, this, changeTypes);
    }

    public unsubscribe = (f: postUpdateCallback, changeType?: PostHubEventType) => {
        const pos = _.findIndex(this.subscribers[changeType ?? "update"], s => s === f);
        if (pos >= 0) {
            this.subscribers[changeType ?? "update"].splice(pos, 1);
        }
    }

    public unsubscribeMultiple = (f: postUpdateCallback, changeTypes: PostHubEventType[]) => {
        _.forEach(changeTypes, changeType => this.unsubscribe(f, changeType));
    }

    public signForId = async (ids: string[], unsignIds?: string[]) => {
        //console.log("ids => ", ids);
        //console.log("this.currentIds => ", _.clone(this.currentIds));
        await this.unsignForId(unsignIds);

        const newIds = _.difference(ids, _.filter(_.keys(this.currentIds), k => this.currentIds[k] > 0));
        _.forEach(ids, i => {
            if (!this.currentIds[i])
                this.currentIds[i] = 0;
            this.currentIds[i]++;
        });
        //console.log("newIds => ", newIds);
        await this.hubConnection.invoke("postHub", "register", newIds);
        return newIds;
    }

    public unsignForId(ids: string[] | undefined): Promise<void> {
        if (!ids || ids.length === 0)
            return new Promise<void>(resolve => resolve());
        _.forEach(ids, i => {
            if (this.currentIds[i])
                this.currentIds[i] = Math.max(0, this.currentIds[i] - 1);
        });
        return new Promise<void>((resolve, reject) => {
            this.hubConnection.invoke("postHub", "deregister", ids).then(resolve, reject);
        });
    }

    public lock = (id: string) => {
        // if (config.adminFeatures.postLocking) {
        //     const state = store.getState();
        //     this.hubConnection.invoke("postHub", "lock", state.client.token, id);
        // }
    }

    public unlock = (id: string) => {
        // if (config.adminFeatures.postLocking) {
        //     const state = store.getState();
        //     this.hubConnection.invoke("postHub", "unlock", state.client.token, id);
        // }
    }

    private onPostChanged = (changeType: PostHubEventType) => (post: PostDownloadDto) => {
        _.forEach(this.subscribers[changeType], s => s(post, changeType));
    }

    private reconnect = async () => {
        console.log("reconnecting");
        this.setupRegistrations();
        if (this.currentIds)
            await this.signForId(_.filter(_.keys(this.currentIds), k => this.currentIds[k] > 0));
    }

    private setupRegistrations = () => {
        this.hubConnection.registerForConnectionEvent("reconnected", () => this.reconnect);
        this.hubConnection.register<PostDownloadDto>("postHub", "UpdatePost", this.onPostChanged("update"));
        this.hubConnection.register<PostDownloadDto>("postHub", "AddPost", this.onPostChanged("add"));
        this.hubConnection.register<PostDownloadDto>("postHub", "DeletePost", this.onPostChanged("delete"));
        this.hubConnection.register<PostDownloadDto>("postHub", "NewNotification", this.onPostChanged("notification"));
        // if (config.adminFeatures.postLocking) {
        //     this.hubConnection.register<PostDownloadDto>("postHub", "LockPost", this.onPostChanged("lock"));
        //     this.hubConnection.register<PostDownloadDto>("postHub", "UnlockPost", this.onPostChanged("unlock"));
        // }
    }
}

export default PostHubConnection;