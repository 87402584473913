// import "expose-loader?jQuery!jquery";
// import "expose-loader?$!jquery";
import * as $ from "jquery";
import * as _ from "lodash";
import Config from "../Config";
(window as any).jQuery = (window as any).$ = $;
require("signalr");

interface IHubMap {
    [key: string]: SignalR.Hub.Proxy;
}

interface EventMap {
    [eventName: string]: Array<(param: any) => void>;
}

type eventTypes = "reconnected" | "reconnecting" | "connectionSlow" | "disconnected";

const config = Config.getInstance();

export default class HubConnection {
    private signalRConnection: SignalR.Hub.Connection;
    private hubs: IHubMap = {};
    private events: EventMap = {};

    public constructor() {
        if (config.debug)
            console.log("constructing..");
        this.signalRConnection = $.hubConnection(config.baseURL + "signalr");
        // this._connection.start();
        this.signalRConnection.disconnected(this.disconnected);
        this.signalRConnection.reconnected(this.reconnected);
        this.signalRConnection.reconnecting(this.reconnecting);
        this.signalRConnection.connectionSlow(this.connectionSlow);
    }

    public registerForConnectionEvent = (event: eventTypes, callback: (params: any) => void) => {
        if (!this.events[event])
            this.events[event] = [];
        const idx = _.findIndex(this.events[event], e => e === callback);
        if (idx >= 0)
            this.events[event][idx] = callback;
        else
            this.events[event].push(callback);
    }

    public deregisterFromConnectionEvent = (event: eventTypes, callback: (params: any) => void) => {
        const idx = _.findIndex(this.events[event], e => e === callback);
        if (idx >= 0)
            this.events[event].splice(idx, 1);
    }


    public register<T1 = undefined, T2 = undefined, T3 = undefined, T4 = undefined>(hubName: string, methodName: string, callBack: (p1: T1, p2: T2, p3: T3, p4: T4) => void) {
        if (config.debug)
            console.log("register to hub! hub: ", hubName, ", method: ", methodName);
        this.getHub(hubName).on(methodName, callBack);
        this.signalRConnection.start();
    }

    public deregister<T1 = undefined, T2 = undefined, T3 = undefined, T4 = undefined>(hubName: string, methodName: string, callBack: (p1: T1, p2: T2, p3: T3, p4: T4) => void) {
        if (config.debug)
            console.log("deregister from hub! hub: ", hubName, ", method: ", methodName);
        this.getHub(hubName).off(methodName, callBack);
    }

    public invoke(hubName: string, methodName: string, ...args: any[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.signalRConnection.start()
                .done(() => this.getHub(hubName).invoke(methodName, ...args)
                    .done(() => resolve())
                    .fail((data: any) => reject(data)))
                .fail((data: any) => reject(data));
        });

    }

    private getHub(hubName: string): SignalR.Hub.Proxy {
        if (config.debug)
            console.log("getting hub for ", hubName);
        if (!this.hubs[hubName]) {
            if (config.debug)
                console.log("creating new hub ", hubName);
            this.hubs[hubName] = this.signalRConnection.createHubProxy(hubName);
        }
        return this.hubs[hubName];
    }

    private disconnected = () => {
        const callbacks = this.events["disconnected" as eventTypes];
        if (callbacks) {
            callbacks.forEach((cb) => cb(undefined));
        }
    }

    private reconnected = () => {
        const callbacks = this.events["reconnected" as eventTypes];
        if (callbacks) {
            callbacks.forEach((cb) => cb(undefined));
        }
    }

    private reconnecting = () => {
        const callbacks = this.events["reconnecting" as eventTypes];
        if (callbacks) {
            callbacks.forEach((cb) => cb(undefined));
        }
    }

    private connectionSlow = () => {
        const callbacks = this.events["connectionSlow" as eventTypes];
        if (callbacks) {
            callbacks.forEach((cb) => cb(undefined));
        }
    }
}
