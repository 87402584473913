import { ImgIcons } from "imaginarity-react-ui";

const icon: ImgIcons = "info";

export const cmsConfig = {
    showActionInfo: false,
    showFileSidebarNavigation: true,
    allowDiscussionChangeInWorkflow: false,
    activateDownloadDialog: true,
    headerDisplaySticky: false,
    fileCommentMaxLength: 80,
    showExtraFilterOnSearchResult: false,
    showAssignedTasksOnSidebar: true,
    showWorkflowStepIcon: false,
    showAdditionalSidebarButtons: true,
    showOrderButtonsForTemplates: false,

    showPushInformSettingsOnSidebar: false, //false
    showEmailInformSettingsOnSidebar: true,
    showTaskInformSettingsOnSidebar: true,
    showAddProjectFilenameStructure: false, //false

    trainingMinDuration: 0,
    // trainingMaxDuration: 240 * 4,
    // trainingMaxDuration: 60 * 7,
    trainingMaxDuration: 0,
    trainingDurationStep: 15,

    //ANPASSUNGEN 14.11.2022_Ergebnisse_IMG.xlsx
    //=>   (content\.width)|(\.content[ \.\),])
    //=>   (content\.width)|(\.content.width[ \.\),])
    // rangeBarColor: "@darkerGrey",
    // sliderBackground: "@darkerGrey",
    // trackColor: "fade('@mainBackground', 20%)",
    rangeBarColor: undefined,
    sliderBackground: undefined,
    trackColor: undefined,
    showFilledIconInTitle: true,



    //ANPASSUNGEN Features_2022122(002).xlsx
    pGTranslationSettings: true,
    showBatches: true,
    userFileDownloadShowReason: true,
    userFileDownloadReasonIsMandatory: false,

    disabledMoreButtonIcon: icon,
    disabledMoreButtonIconColor: "@accentRed",
    disabledMoreButtonIconColorAlpha: 100,
    multiSelectMoreButtonIconColor: "@accentGreen",

    //Sprint 3
    showTrainingDurationInputs: true,
    trainingDurationHoursperDay: 8,
    showChildren: true,
    // maxSearchCount: 15,


    //Sprint 7
    loaderIcon: "images/lottiIcons/spinner.json",

    showPreviewBackgroundImage: true,


    // NEW
    showSaveMethodInTable: true,
    showSaveMethodBesideSaveButton: false,
    showUpdatePublishedDataButtonInDirectoryView: true,

    showAdditionalCmsSearchToggleFilesProjects: {
        show: true,
        showInfoText: true
    },
    rightSidebarAccent: {
        color: "@accentLightBlue",
        lightenLevel: 110,
    },
    rightSiedbarWarning:
    {
        color: "@accentYellow",
        lightenLevel: 130
    },
    rightSiedbarTaskAccentColor:
    {
        color: "@accentLightBlue",
        lightenLevel: 100,
        textColor: "@accentBlack"
    },
};
export type CMSConfig = typeof cmsConfig;