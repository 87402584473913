import { GameController, GameDownloadDto } from 'collaboration-service';
import { formatDistanceToNow } from 'date-fns';
import { Button, LoaderInline, TooltippedElement } from 'imaginarity-react-ui';
import { FloatedRight } from 'layout/masterLayouts/MasterLayoutsSC';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { gotoNamedRoute } from 'services/Helpers/RoutingHelper';
import { ImgI18N, ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { quizTheme } from 'views/Quiz/QuizMain';
import { Item } from '../PostClasses';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage, user: state.user });

export interface QuizIncompleteProps extends ImgI18NTranslatedComponentProps, ComponentWithMappedApplicationState<typeof mapper> {
    id: string;
    total?: (total: number) => void;
    commentsContainerHeight?: number;
    top?: number;
}

export interface QuizIncompleteState {
    incompleted?: GameDownloadDto[];
}


class QuizIncomplete extends React.Component<QuizIncompleteProps, QuizIncompleteState> {
    constructor(props: QuizIncompleteProps) {
        super(props);

        this.state = {
        }
    }

    public componentDidMount() {
        GameController.SearchGames({
            filter: {
                postId: this.props.id,
                state: "Unfinished",
                findReplayGames: false,
                findVersusGames: false,
                userId: this.props.user?.id,
                top: this.props.top,
            }
        },
            (incompleted) => {
                this.setState({ incompleted });
                if (this.props.total)
                    this.props.total(incompleted.length);
            },
            (err) => console.log(err)
        );
    }

    public render() {
        const { incompleted } = this.state;
        const { t, contentLanguage } = this.props;
        const commentsContainerHeight = this.props.commentsContainerHeight ? this.props.commentsContainerHeight - 41 : 300;
        return (
            <div style={{
                minHeight: 40,
                height: commentsContainerHeight,
                overflowY: "auto",
                overflowX: "hidden",
                padding: "10px 0 5px 0"
            }}>
                {incompleted === undefined &&
                    <LoaderInline
                        infoText="loading"
                        animationColor={"@accent"}
                        backgroundColor={"@veryLightGrey"}
                        textColor={"@mainForeground"}
                        active
                    />
                }
                {incompleted && incompleted.map((g, idx) => {
                    const link = () => {
                        gotoNamedRoute("quiz_singleplayer", { quizId: g.id, mode: "continue" });
                    }
                    const lastQuestion = g.lastQuestionAnswered ? g.lastQuestionAnswered + 1 : 0;
                    const created = formatDistanceToNow(
                        new Date(g.created),
                        { addSuffix: true, locale: ImgI18N.getInstance().languageLocal(contentLanguage) }
                    )
                    return (
                        <Item key={g.id} onClick={link} style={{ paddingLeft: 10 }}>
                            {lastQuestion + t(" answered {{created}}", { created: created })}
                            <FloatedRight>
                                <TooltippedElement
                                    element={Button}
                                    position="left"
                                    scheme="secondary"
                                    tooltipContent={t("resume game")}
                                    icon="game"
                                    kind={!quizTheme.darkMode ? "transparentButton" : "transparentButtonDark"}
                                    onClick={link}
                                />
                            </FloatedRight>
                        </Item>
                    );
                })}
            </div>
        );
    }
}

export default translate("feed")(connect(mapper)(QuizIncomplete));

