import { BestPracticeController, BestPracticePostDownloadDto, ControllerHelper, EventController, EventUploadDto, ImplementationDownloadDto, PlaylistEdgeTransitionFunction, PlaylistItemStateDownloadDto } from 'collaboration-service';
import DateTimeFns from 'components/DateTime/DateTimeFns';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { formatRelative } from 'date-fns';
import { Button, Dimmer, Icon, Image, Media, RatingView, useThemePart } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { ApplicationState, useAppSelector } from "services/ApplicationState/ApplicationState";
import { DueInfo, shallowCompare } from 'services/Helpers';
import { sanitizedNothingAllowedAttributes } from 'services/Helpers/SanitizedHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { ImgI18N, useImgI18N } from 'services/ImgI18N';
import { BestPracticeSC } from 'views/BestPractice/BestPracticeSC';
import { PlaylistDetailSC as T } from "../../PlaylistDetailSC";
import MarkAsReadButton from './MarkAsReadButton';
import { LoadPlaylistStates } from './PlaylistTubePodcastDimmer';

const mapper = (s: ApplicationState) => ({
    contentLanguage: s.contentLanguage,
    currentGroup: s.currentGroup,
    currentArticle: s.wikiState.currentArticle,
});

interface PlaylistBestPracticeDimmerProps extends LoadPlaylistStates {
    id: string;
    item: BestPracticePostDownloadDto;
    link: string;
    result: DueInfo;
    playlistId: string;
    state: PlaylistItemStateDownloadDto;
    isOptional: boolean;
    transition: PlaylistEdgeTransitionFunction | undefined;
    elementIsReference: boolean;
    nodeIsDone: boolean;
    warning: boolean;
    closeDimmer: () => void;
}

const PlaylistBestPracticeDimmer = (p: PlaylistBestPracticeDimmerProps) => {
    const { contentLanguage, currentGroup } = useAppSelector(mapper, shallowCompare);
    const { id, item, link, result, playlistId, state, isOptional, transition, elementIsReference, nodeIsDone, warning, closeDimmer, loadPlaylistStates } = p;
    const { t } = useImgI18N('playlist');
    const [implementations, setImplementations] = React.useState<ImplementationDownloadDto[]>([]);

    React.useEffect(() => {
        const fetchImplementations = async () => {
            try {
                const [result] = await ControllerHelper
                    .addCall({ id }, BestPracticeController.GetBestPracticeImplementation)
                    .execute<ImplementationDownloadDto[]>();
                setImplementations(result);
            } catch (error) {
                console.error('Error fetching implementations:', error);
            }
        };

        fetchImplementations();
    }, [id]);

    const clickOnItem = React.useMemo(() => () => {
        if (currentGroup && playlistId) {
            const ev: EventUploadDto = {
                eventType: "PostViewed",
                group_id: currentGroup.id,
                payload: {
                    "PostId": item.id,
                    "From": "playlist",
                    "PlaylistId": playlistId,
                }
            };
            ControllerHelper.singleCall({ ev }, EventController.PostEvent);
        }
    }, [currentGroup, playlistId, item.id]);
    // const medias = _.map(item.medias, m => ({ media: m?.thumbnails[0] ?? m, refName: "self" }));
    const imageCount = item.medias.length;
    const desc = item.descriptions ? getTranslated(item.descriptions, contentLanguage) : undefined;
    const outputs = item.outputs ? getTranslated(item.outputs, contentLanguage) : undefined;
    const effects = _.filter(item.categories, c => c.categoryType === undefined || c.categoryType === "");
    const bussinessAreas = _.filter(item.locations, c => c.categoryType === undefined || c.categoryType === "");
    const goals = _.filter(item.categories, c => c.categoryType === "goal");
    const kindOfActivities = _.filter(item.categories, c => c.categoryType === "kindOfActivity");
    const channels = _.filter(item.categories, c => c.categoryType === "channel");
    const emptyAvatarImageUrl = useThemePart(t => t.emptyAvatarImageUrl);

    return (
        <Dimmer active >
            <T.DimmerContainer>
                <BestPracticeSC.GalleryContainer imageCount={imageCount}>
                    {item.medias[0] &&
                        <BestPracticeSC.GCImage1
                            backgroundCover={false}
                            noScale={imageCount === 1}
                            key={item.medias[0].id}
                        >
                            <Media
                                media={{ media: item.medias[0], refName: "self" }}
                                width="100%"
                                height="auto"
                                controls
                                hoverable
                                audioheadertext={item.medias[0].mediaType.startsWith("audio") ? t("audio file") : undefined}
                                hoverIcon={item.medias[0].mediaType.startsWith("video") ? "video player" : "search"}
                            />
                        </BestPracticeSC.GCImage1>
                    }
                    {item.medias[1] &&
                        <BestPracticeSC.GCImage2
                            noScale={imageCount === 2}
                            key={item.medias[1].id}
                            backgroundCover={false}
                        >
                            <Media
                                media={{ media: item.medias[1], refName: "self" }}
                                width="100%"
                                height="auto"
                                controls
                                hoverable
                                audioheadertext={item.medias[1].mediaType.startsWith("audio") ? t("audio file") : undefined}
                                hoverIcon={item.medias[1].mediaType.startsWith("video") ? "video player" : "search"}
                            />
                        </BestPracticeSC.GCImage2>
                    }
                    {item.medias[2] &&
                        <BestPracticeSC.GCImage3
                            key={item.medias[2].id}
                            backgroundCover={false}
                        >
                            <Media
                                media={{ media: item.medias[2], refName: "self" }}
                                width="100%"
                                height="auto"
                                controls
                                hoverable
                                audioheadertext={item.medias[2].mediaType.startsWith("audio") ? t("audio file") : undefined}
                                hoverIcon={item.medias[2].mediaType.startsWith("video") ? "video player" : "search"}
                            />
                        </BestPracticeSC.GCImage3>
                    }
                    {item.medias[3] &&
                        <BestPracticeSC.GCImage4
                            key={item.medias[3].id}
                            backgroundCover={false}
                        >
                            <Media
                                media={{ media: item.medias[3], refName: "self" }}
                                width="100%"
                                height="auto"
                                controls
                                hoverable
                                audioheadertext={item.medias[3].mediaType.startsWith("audio") ? t("audio file") : undefined}
                                hoverIcon={item.medias[3].mediaType.startsWith("video") ? "video player" : "search"}
                            />
                        </BestPracticeSC.GCImage4>
                    }
                    {item.medias[4] &&
                        <BestPracticeSC.GCImage5
                            key={item.medias[4].id}
                            backgroundCover={false}
                        >
                            <Media
                                media={{ media: item.medias[4], refName: "self" }}
                                width="100%"
                                height="auto"
                                controls
                                hoverable
                                audioheadertext={item.medias[4].mediaType.startsWith("audio") ? t("audio file") : undefined}
                                hoverIcon={item.medias[4].mediaType.startsWith("video") ? "video player" : "search"}
                            />
                        </BestPracticeSC.GCImage5>
                    }
                </BestPracticeSC.GalleryContainer>













                <T.DimmerInfoContainer>
                    <T.DimmerInfoContainerTitle>
                        <SafeHTML html={getTranslated(item.headlines, contentLanguage)?.text ?? ""} allowedTags={[]} allowedAttributes={{}} />
                    </T.DimmerInfoContainerTitle>
                    <T.DimmerInfoContainerButton>
                        <div>
                            {item.rating > 0 &&
                                <div style={{ float: "left", marginTop: 6, marginRight: 15 }}>
                                    <RatingView
                                        rating={item.rating}
                                        maxStars={5}
                                        size={24}
                                        filledOut={true}
                                    />
                                </div>
                            }
                            <Button
                                icon="link external"
                                content={t("open in pri")}
                                bordered
                                kind="secondary"
                                floated="right"
                                asHtmlLinkToRoute={link}
                                onClick={clickOnItem}
                            />
                        </div>
                        <MarkAsReadButton
                            item={item}
                            playlistId={playlistId}
                            playlistItemState={state}
                            lastAction={loadPlaylistStates}
                        />
                    </T.DimmerInfoContainerButton>
                    <T.DimmerInfoContainerDate>
                        {result.dueToDate &&
                            <div style={{ float: "left" }}>
                                <DateTimeFns
                                    date={result.dueToDate}
                                    noTime
                                    hideIcons
                                    textColor="grey"
                                />
                            </div>
                        }
                        {isOptional ?
                            <T.ColoredSpan color={state.state === "Finished" ? "@accentGreen" : "inherit"}>
                                {state.state === "Finished" ? t("100%") : ""}
                            </T.ColoredSpan>
                            :
                            (transition === "ReferenceDone" || transition === "ReferenceDoneWithValue") ?
                                elementIsReference ?
                                    <T.ColoredSpan color={(result.text === "100%" || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color}>
                                        {nodeIsDone ? "100%" : warning ? "warning" : result.text}
                                    </T.ColoredSpan>
                                    :
                                    <T.ColoredSpan color={result.text === "100%" ? "@accentGreen" : "@middleLightGrey"}>
                                        {result.text === "100%" ? "100%" : t("optional")}
                                    </T.ColoredSpan>
                                :
                                <T.ColoredSpan color={result.text === "100%" ? "@accentGreen" : warning ? "@warning" : result.color}>
                                    {result.text}
                                </T.ColoredSpan>
                        }
                    </T.DimmerInfoContainerDate>
                </T.DimmerInfoContainer>

                <T.DimmerInfoContainerDescription style={{ wordBreak: "break-word" }}>
                    {desc && desc.text &&
                        <SafeHTML
                            html={desc.text}
                            allowedTags={["strong", "b", "i", "u"]}
                            allowedAttributes={sanitizedNothingAllowedAttributes}
                        />
                    }
                    <T.DimmerInfoContainerDescriptionGrid>
                        {outputs?.text !== "" && outputs?.text !== undefined &&
                            <>
                                <T.DimmerInfoContainerDescriptionGridTitle>{t("output")}</T.DimmerInfoContainerDescriptionGridTitle>
                                <div>
                                    <SafeHTML
                                        allowedTags={["strong", "b", "i", "u"]}
                                        allowedAttributes={sanitizedNothingAllowedAttributes}
                                        html={outputs?.text ?? ""}
                                    />
                                </div>
                            </>
                        }
                        {item?.responsibleUsers.length > 0 &&
                            <>
                                <T.DimmerInfoContainerDescriptionGridTitle>
                                    {item.responsibleUsers.length === 1 ? t("responsible") : t("responsibles")}
                                </T.DimmerInfoContainerDescriptionGridTitle>
                                <div>
                                    {_.map(item.responsibleUsers, (resp, i) =>
                                        <div key={i}>
                                            <Image
                                                src={resp.avatar?.links[0].uri ?? emptyAvatarImageUrl}
                                                rounded
                                                squared
                                                floated="left"
                                                width="20px"
                                            />
                                            {resp.firstName + " " + resp.secondName}
                                        </div>
                                    )}
                                </div>
                            </>
                        }
                        {item?.contributorsUsers.length > 0 &&
                            <>
                                <T.DimmerInfoContainerDescriptionGridTitle>
                                    {item.responsibleUsers.length === 1 ? t("contributor") : t("contributors")}
                                </T.DimmerInfoContainerDescriptionGridTitle>
                                <div>
                                    {_.map(item.contributorsUsers, (contr, i) =>
                                        <div key={i}>
                                            <Image
                                                src={contr.avatar?.links[0].uri ?? emptyAvatarImageUrl}
                                                rounded
                                                squared
                                                floated="left"
                                                width="20px"
                                            />
                                            {contr.firstName + " " + contr.secondName}
                                        </div>
                                    )}
                                </div>
                            </>
                        }
                        {effects.length > 0 &&
                            <>
                                <T.DimmerInfoContainerDescriptionGridTitle>
                                    {effects.length === 1 ? t("effect") : t("effects")}
                                </T.DimmerInfoContainerDescriptionGridTitle>
                                <div >
                                    {_.map(effects, (e, i) =>
                                        <T.DimmerInfoContainerDescriptionGridTListItem key={i}>
                                            <Icon name="check square" style={{ marginRight: 11, marginLeft: 1, float: "left" }} marginTop={0} />
                                            {e.names[0].text}
                                        </T.DimmerInfoContainerDescriptionGridTListItem>
                                    )}
                                </div>
                            </>
                        }
                        {bussinessAreas.length > 0 &&
                            <>
                                <T.DimmerInfoContainerDescriptionGridTitle>
                                    {bussinessAreas.length === 1 ? t("location") : t("locations")}
                                </T.DimmerInfoContainerDescriptionGridTitle>
                                <div >
                                    {_.map(bussinessAreas, (b, i) =>
                                        <T.DimmerInfoContainerDescriptionGridTListItem key={i}>
                                            <Icon name="check square" style={{ marginRight: 11, marginLeft: 1, float: "left" }} marginTop={0} />
                                            {b.names[0].text}
                                        </T.DimmerInfoContainerDescriptionGridTListItem>
                                    )}
                                </div>
                            </>
                        }
                        {goals.length > 0 &&
                            <>
                                <T.DimmerInfoContainerDescriptionGridTitle>
                                    {goals.length === 1 ? t("goal") : t("goals")}
                                </T.DimmerInfoContainerDescriptionGridTitle>
                                <div >
                                    {_.map(goals, (g, i) =>
                                        <T.DimmerInfoContainerDescriptionGridTListItem key={i}>
                                            <Icon name="check square" style={{ marginRight: 11, marginLeft: 1, float: "left" }} marginTop={0} />
                                            {g.names[0].text}
                                        </T.DimmerInfoContainerDescriptionGridTListItem>
                                    )}
                                </div>
                            </>
                        }
                        {kindOfActivities.length > 0 &&
                            <>
                                <T.DimmerInfoContainerDescriptionGridTitle>
                                    {kindOfActivities.length === 1 ? t("kind of activity") : t("kind of activities")}
                                </T.DimmerInfoContainerDescriptionGridTitle>
                                <div >
                                    {_.map(kindOfActivities, (k, i) =>
                                        <T.DimmerInfoContainerDescriptionGridTListItem key={i}>
                                            <Icon name="check square" style={{ marginRight: 11, marginLeft: 1, float: "left" }} marginTop={0} />
                                            {k.names[0].text}
                                        </T.DimmerInfoContainerDescriptionGridTListItem>
                                    )}
                                </div>
                            </>
                        }
                        {channels.length > 0 &&
                            <>
                                <T.DimmerInfoContainerDescriptionGridTitle>
                                    {channels.length === 1 ? t("channel") : t("channels")}
                                </T.DimmerInfoContainerDescriptionGridTitle>
                                <div >
                                    {_.map(channels, (c, i) =>
                                        <T.DimmerInfoContainerDescriptionGridTListItem key={i}>
                                            <Icon name="check square" style={{ marginRight: 11, marginLeft: 1, float: "left" }} marginTop={0} />
                                            {c.names[0].text}
                                        </T.DimmerInfoContainerDescriptionGridTListItem>
                                    )}
                                </div>
                            </>
                        }
                        <T.DimmerInfoContainerDescriptionGridTitle>
                            {implementations.length === 1 ? t("implementation") : t("implementations")}
                        </T.DimmerInfoContainerDescriptionGridTitle>
                        {implementations.length > 0 ?
                            <div style={{ display: "grid", gridTemplateColumns: "max-content max-content 1fr max-content", columnGap: 10, rowGap: 5, width: "100%" }}>
                                {_.map(implementations, (impl, i) => {
                                    const uri = impl.responsible.avatar ? impl.responsible.avatar?.links[0].uri : emptyAvatarImageUrl;
                                    const getDateFnsLng = ImgI18N.getInstance().languageLocal();
                                    const postDate = formatRelative(new Date(impl.implemented as Date), new Date(), { locale: getDateFnsLng });

                                    return (<React.Fragment key={i}>
                                        <div><Image src={uri} rounded squared width="20px" /></div>
                                        <div>{impl.responsible.firstName} {impl.responsible.secondName}</div>
                                        <div>({impl.organisation})</div>
                                        <div>{postDate}</div>
                                        <div style={{ gridColumnStart: 2, gridColumnEnd: 5 }}>{getTranslated(impl.comments).text}</div>
                                    </React.Fragment>);
                                }
                                )}
                            </div> :
                            <div>
                                {t("no implementations reported to date ")}
                            </div>
                        }
                    </T.DimmerInfoContainerDescriptionGrid>
                </T.DimmerInfoContainerDescription>

                <T.DimmerInfoContainerClose onClick={closeDimmer} >
                    <Icon name="times" size={27} marginTop={3} />
                </T.DimmerInfoContainerClose>
            </T.DimmerContainer>
        </Dimmer>
    );
}
export default PlaylistBestPracticeDimmer;