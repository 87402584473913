import * as React from 'react';
import { svgDescribeArc } from 'services/Helpers';
import { QuizSC as T } from '../QuizSC';

interface TimeIndicatorProps {
    style?: React.CSSProperties;
    maxTime: number;
    onTimeOver?: () => void;
    diameter?: number;
}

interface TimeIndicatorState {
    timeLeft?: number;
}

export default class TimeIndicator extends React.Component<TimeIndicatorProps, TimeIndicatorState> {
    private static sTimeLeft: number;
    private static sOver: boolean;
    private static run: boolean;
    private static ellapsedTime: number;
    private start?: number;
    private finish?: boolean;

    public static stop() {
        this.run = false;
    }

    public static start() {
        this.run = true;
    }

    constructor(props: TimeIndicatorProps) {
        super(props);
        this.state = {};
    }

    public static get timeLeft(): number {
        return this.sTimeLeft;
    }

    public static get isOver(): boolean {
        return this.sOver;
    }

    public static get ellapsed(): number {
        return this.ellapsedTime;
    }

    public componentDidMount() {
        this.setState({ timeLeft: this.props.maxTime });
        TimeIndicator.sTimeLeft = this.props.maxTime;
        TimeIndicator.sOver = false;
        TimeIndicator.run = true;
        window.requestAnimationFrame(this.updateTime);
    }

    public componentWillUnmount() {
        this.finish = true;
    }

    public componentWillReceiveProps(nextProps: TimeIndicatorProps) {
        if (nextProps.maxTime !== this.props.maxTime) {
            TimeIndicator.run = true;
            this.start = 0;
        }
    }

    public render() {
        const tl = this.state.timeLeft ? this.state.timeLeft : 0;
        const p = 360.0 * (tl / this.props.maxTime);
        const countdown = 7;
        const size = this.props.diameter ?? 50;
        const fontSize = size * 16 / 50;
        function calcSize(i: number) { return size * i }
        return (
            <T.TISvg
                viewBox={"0 0 " + size + " " + size}
                height={size}
                width={size}
                style={this.props.style}
            >
                {this.props.maxTime === 0 ?
                    <>
                        <T.TICircleInfiniteTime
                            cx={calcSize(0.5)}
                            cy={calcSize(0.5)}
                            r={calcSize(0.4)}
                            strokeWidth={calcSize(0.12)}
                        />
                        <T.TITextInfiniteTime
                            x={calcSize(0.5)}
                            y={calcSize(0.5)}
                            textAnchor="middle"
                            alignmentBaseline="central"
                            fontSize={fontSize}
                        />
                    </>
                    :
                    <>
                        {(p > 0) &&
                            <>

                                <T.TIPath
                                    d={svgDescribeArc(calcSize(0.5), calcSize(0.5), calcSize(0.4), 0, p)}
                                    className='outer'
                                    strokeWidth={calcSize(0.2)}
                                    warning={tl < countdown && tl > 0}
                                />
                                <T.TIPath
                                    d={svgDescribeArc(calcSize(0.5), calcSize(0.5), calcSize(0.4), p, 360)}
                                    className='inner'
                                    strokeWidth={calcSize(0.1)}
                                    warning={tl < countdown && tl > 0}
                                />
                            </>
                        }
                        {(p <= 0 || isNaN(p)) &&
                            <T.TICircle
                                cx={calcSize(0.5)}
                                cy={calcSize(0.5)}
                                r={calcSize(0.4)}
                                strokeWidth={calcSize(0.12)}
                            />
                        }
                        <T.TIText
                            x={calcSize(0.5)}
                            y={calcSize(0.5)}
                            textAnchor="middle"
                            // alignmentBaseline="middle"
                            light={(p <= 0 || isNaN(p)) ?? false}
                            fontSize={fontSize}
                            dy="0.3em"
                        >
                            {Math.floor(tl)}
                        </T.TIText>
                    </>
                }
            </T.TISvg>
        );
    }

    private updateTime = (timeStamp: number) => {
        if (TimeIndicator.run && !this.finish) {
            if (!this.start)
                this.start = timeStamp;
            TimeIndicator.ellapsedTime = timeStamp - this.start;
            const timeLeft = Math.max(this.props.maxTime - TimeIndicator.ellapsedTime / 1000, 0.0);
            this.setState({ timeLeft });
            TimeIndicator.sTimeLeft = timeLeft;
            if (timeLeft <= 0 && this.props.maxTime > 0 && !TimeIndicator.sOver) {
                if (this.props.onTimeOver)
                    this.props.onTimeOver();
                TimeIndicator.sOver = true;
            }
        }
        if (!this.finish)
            window.requestAnimationFrame(this.updateTime);
    }




}
