import { fadeColor, getColor, styled } from "imaginarity-react-ui";

export class ImageSliderImgSC {

    public static SliderContainer = styled.div<{ width: number | string; height: number | string }>`
  position: relative;
  overflow: hidden;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
  max-height: 55vh;
`;

    public static ImageWrapper = styled.div<{ translateX: number }>`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: ${({ translateX }) => `translateX(${translateX}%)`};
`;

    public static Image = styled.img<{ contained?: boolean }>`
  width: ${({ contained }) => (contained ? '100vw' : '100%')};
  height: ${({ contained }) => (contained ? '100vh' : '100%')};
  object-fit: ${({ contained }) => (contained ? 'contain' : 'cover')};
  cursor: pointer;
  flex-shrink: 0;
`;

    public static NavButtonArea = styled.div<{ position: 'left' | 'right', color?: string }>`
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: ${p => fadeColor(p, p.color ?? "@darkGrey", 15)};
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  ${({ position }) => position}: 10px;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: ${p => fadeColor(p, "@darkGrey", 35)};
  }
`;
    public static DotNavigation = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
`;
    public static DotNavigationSpan = styled.span<{ active: boolean }>`
    cursor: ${p => p.active ? "default" : "pointer"};
    svg{
        &:hover{
            stroke: ${p => getColor(p, p.active ? "@mainBackground" : "@accent")};
        }
    }
`;
    public static NavButtonAreaDimmerLeft = styled.div`
    z-index: 2147483638;
    top: unset;
    transform: translateX(calc(-100% - 80px));
    bottom: 20px;
    position: fixed;
    left: 50%;
    background: ${p => fadeColor(p, "@accentBlack", 35)};
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: ${p => fadeColor(p, "@accentBlack", 65)};
    }
`;
    public static NavButtonAreaDimmerRight = styled(this.NavButtonAreaDimmerLeft)`
    transform: translateX(calc(100% + 80px));    
    right: 50%;
    left: unset;
`;
    public static NavButtonAreaDimmerCenter = styled(this.NavButtonAreaDimmerLeft)`
    transform: translateX(-50%);    
`;
    public static NavButtonAreaDimmerLeftMobile = styled(this.NavButtonAreaDimmerLeft)`
    transform: unset;    
    left: 20px;
`;
    public static NavButtonAreaDimmerRightMobile = styled(this.NavButtonAreaDimmerRight)`
    transform: unset;    
    right: 20px;
`;
    public static ZoomFactor = styled.div`
    position: fixed;
    bottom: 45px;
    left: calc(50% + 3px);
    font-size: 11px;
    transform: translate(-50%, 0px);
    color: ${p => getColor(p, "@mainBackground")};
    z-index: 2147483638;
`;
    public static ImageMapCoantainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 55vh;
    min-height: 120px;
`;
    public static LottieSpinner = styled.div`
    position: absolute;
    z-index: 12;
    top: calc(50% + 25px);
    left: 50%;
    transform: translate(-50%,-50%);
`;
    public static MobileDimmer = styled.div`
    position: fixed;
    inset: 0;
    z-index: 100;
    background: ${p => getColor(p, "@darkGrey")};
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh + 10px);
    display: flex;
    align-items: center;
    
*{
    scrollbar-width: none !important; 
    -ms-overflow-style: none !important; 

    &::-webkit-scrollbar {
        display: none !important; 
    }
}
  
`;
    public static DimmerBottom = styled.div`
    z-index: 102;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 110px;
    background-image: linear-gradient(to top, ${p => getColor(p, "@darkGrey")} 5%, transparent 100%);
`;
}