import { GroupDownloadDto } from "collaboration-service";
import { GroupBase, Icon, ImgIcons, ImgOnChangeValue, ImgSelect, ImgSelectProps } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { getGroupsWithAnyRole } from "services/Helpers";
import { adminGroupsOfType, changeLastAdminGroupToType, changeLastGroup, changeLastGroupToType, groupsOfType } from "services/StoreDependantHelpers";
import { FilterContainerHeader } from "views/Feed/FeedFilterAll";
import { GeneralSC as T } from "./GeneralSC";

interface GroupSelectionProps<IsClearable extends boolean> extends Omit<ImgSelectProps<GroupDownloadDto, false, GroupBase<GroupDownloadDto>, IsClearable>, "onChange" | "isMulti" | "isClearable"> {
    groupTypes?: string[];
    darkMode?: boolean;
    title?: string;
    titleComponent?: JSX.Element;
    onChange?: (group: ImgOnChangeValue<GroupDownloadDto, false, IsClearable>) => void;
    inAdminGroups?: boolean;
    rolesInGroups?: string[];
    icon?: ImgIcons;
    noRoot?: boolean;
    isClearable?: IsClearable;
    value?: GroupDownloadDto | null;
    styles?: any;
    check?: (g: GroupDownloadDto) => boolean;
    selectOnly?: boolean;
}

const GroupSelection = <IsClearable extends boolean = false>(p: GroupSelectionProps<IsClearable>) => {
    const { groupTypes, inAdminGroups, onChange, rolesInGroups, icon, noRoot, value, isClearable, styles, check, selectOnly } = p;
    const cg = useSelector((s: ApplicationState) => s.currentGroup);
    const currentUser = useSelector((s: ApplicationState) => s.user);
    const currentGroup = value !== undefined ? value : cg;
    if (value !== undefined && onChange === undefined)
        throw new Error("GroupSelection.tsx::31 : if value is set onChange must be set too!");

    React.useEffect(() => {
        if (!onChange) {
            if (inAdminGroups)
                changeLastAdminGroupToType(groupTypes);
            else
                changeLastGroupToType(groupTypes);
        }
    }, [groupTypes, inAdminGroups, onChange]);

    const root = React.useMemo(() => document.getElementById("root"), []);

    const groups = React.useMemo(() => {
        const toRet = inAdminGroups ? adminGroupsOfType(groupTypes) : groupsOfType(groupTypes);
        if (rolesInGroups)
            return getGroupsWithAnyRole(currentUser, toRet, rolesInGroups);
        return check ? _.filter(toRet, check) : toRet;
    }, [groupTypes, inAdminGroups, currentUser, rolesInGroups, check]);

    const chgGroup = React.useMemo(() => (data: ImgOnChangeValue<GroupDownloadDto, false, IsClearable>) => {
        if (data)
            changeLastGroup(data);
    }, []);

    if (!groups || groups.length <= 1)
        return <div style={{ display: "none" }} />;
    return (
        <>
            {p.titleComponent && !selectOnly && p.titleComponent}
            {p.title && !selectOnly &&
                <>
                    {p.darkMode ?
                        <FilterContainerHeader>{p.title}</FilterContainerHeader>
                        :
                        <T.SidebarContainerTitle>
                            {icon !== undefined &&
                                <Icon name={icon} style={{ float: "left", marginRight: 5 }} />
                            }
                            {p.title}
                        </T.SidebarContainerTitle>
                    }
                </>
            }
            <ImgSelect
                options={groups}
                getOptionLabel={(item: GroupDownloadDto) => item.name}
                value={currentGroup === null ? currentGroup : _.find(groups, g => g.id === currentGroup?.id)}
                onChange={(onChange ?? chgGroup) as any}
                menuPortalTarget={noRoot ? undefined : root}
                isClearable={isClearable}
                styles={styles ?? undefined}
            />

        </>
    );
}

export default GroupSelection;