import { FileEntryDownloadDto } from "collaboration-service";
import * as React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "services/ApplicationState/ApplicationState";
import CMSAppIcon from "./CMSAppIcon";
import * as _ from "lodash";

const CMSFileEntryAppIcon = (p: { fileEntry?: FileEntryDownloadDto, margin?: string }) => {
    const { fileEntry, margin } = p;
    const filePosts = useSelector((s: ApplicationState) => s.cmsState.filePosts);
    const Icon = React.useMemo(() => () => {
        if (!fileEntry)
            return <CMSAppIcon margin={margin} />;
        const first = Object.values(fileEntry?.contents)[0];
        if (!first)
            return <CMSAppIcon margin={margin} />;
        if (first.referenceId) {
            const p = _.find(filePosts[fileEntry.id], p => p.id === first.referenceId);
            return <CMSAppIcon type={p?.type} margin={margin} />;
        }
        else {
            const mediaType = (first.contents ?? [])[0]?.mediaType;
            return <CMSAppIcon type={mediaType} margin={margin} />;
        }
    }, [fileEntry, filePosts, margin]);
    return <Icon />;
}

export default CMSFileEntryAppIcon;