import { FacetResult } from "collaboration-service";
import { SelectCount, SelectElementContainerWithCount } from "components/SelectHelpers/SelectHelpers";
import { GroupBase, ImgSelect, OptionProps, Theme, belowBreakpoint, components, styled } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from "react";
import { ThemeContext } from "styled-components";

type FacetType = {
    value: string;
    label: string;
    icon?: JSX.Element;
    count: number;
}

export const OptionsOuterContainer = styled.div<{ icon?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${p => p.icon ? "30px" : ""} 1fr max-content;
`;


const FacetOption = (
    p: OptionProps<FacetType, false, GroupBase<FacetType>>) => {
    const cur = p.data;
    return (
        <components.Option {...p}>
            <OptionsOuterContainer icon={cur.icon !== undefined}>
                {cur.icon}
                {cur.count > 0 ?
                    <SelectElementContainerWithCount style={{ paddingTop: 5 }}>
                        <div>{cur.label}</div>
                        <SelectCount style={{ paddingRight: 6 }}>{cur.count}</SelectCount>
                    </SelectElementContainerWithCount>
                    :
                    <div style={{ paddingTop: 3 }}>{cur.label}</div>
                }
            </OptionsOuterContainer>
        </components.Option>
    );
};

const FacetOptionNoNumber = (
    p: OptionProps<FacetType, false, GroupBase<FacetType>>) => {
    const cur = p.data;
    return (
        <components.Option {...p}>
            <OptionsOuterContainer icon={cur.icon !== undefined}>
                {cur.icon}
                <div style={{ paddingTop: 3 }}>{cur.label}</div>
            </OptionsOuterContainer>
        </components.Option>
    );
};

interface FacetSelectionProps {
    facets?: { [key: string]: FacetResult[] };
    facetName: string;
    noCount?: boolean;
    onChange?: (value: string | null) => void;
    value: string | null | undefined;
    header?: JSX.Element;
    translateLabels?: (val: string) => string;
    iconRendererForLabels?: (val: string) => JSX.Element;
}

const FacetSelection = (p: FacetSelectionProps) => {
    const { facetName, facets, noCount, onChange, value, header, translateLabels, iconRendererForLabels } = p;
    const theme = React.useContext<Theme>(ThemeContext);

    const data = React.useMemo(() => {
        if (noCount)
            return _.orderBy(_.map((facets ?? {})[facetName] ?? [], x => ({
                label: translateLabels ? translateLabels(x.data) : x.data,
                count: x.count,
                value: x.data,
                icon: iconRendererForLabels ? iconRendererForLabels(x.data) : undefined
            })), o => o.label);
        return _.orderBy(_.map((facets ?? {})[facetName] ?? [], x => ({
            label: translateLabels ? translateLabels(x.data) : x.data,
            count: x.count,
            value: x.data,
            icon: iconRendererForLabels ? iconRendererForLabels(x.data) : undefined
        })), o => o.count, "desc");
    }, [facets, facetName, translateLabels, iconRendererForLabels, noCount]);


    const onChangeMthd = (newValue: FacetType | null) => {
        if (onChange)
            onChange(newValue === null ? null : newValue.value);
    }

    const val = _.find(data, d => d.value === value) ?? null;
    const isMobileDevice = belowBreakpoint({ theme }, "tablet");

    return (<>
        {data.length !== 0 && (
            <>
                {header}
                <ImgSelect
                    options={data as any}
                    onChange={onChangeMthd}
                    value={val}
                    isClearable
                    // components={{ Option: noCount ? FacetOptionNoNumber : FacetOption }}
                    components={{ Option: (noCount ? FacetOptionNoNumber : FacetOption) as any }}
                    menuShouldBlockScroll={true}
                    menuShouldScrollIntoView={true}
                    menuPlacement="bottom"
                    maxMenuHeight={isMobileDevice ? 120 : undefined}
                />
            </>
        )}
    </>
    )
}

export default FacetSelection;