import { WikiTableCellDownloadDto, WikiTableDownloadDto, WikiTableRowDownloadDto } from 'collaboration-service';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import Editor from "Dummies/TestImpl/TestEditor";
import { Button, ButtonGroup, Icon, ImgThemeConsumer } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { Table as TableResponsive, Tbody, Td, Tr } from 'react-super-responsive-table';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { WikiSC as T } from './WikiSC';
import CancelWithConfirmation from 'components/CancelWithConfirmation';

interface MergePoint {
    row: number;
    column: number;
}
interface Cell {
    state: boolean;
    error?: boolean;
    active?: boolean;
    select?: boolean;
}
interface MergedCellCoordinates {
    toLeft?: number;
    toRight?: number;
    toBottom?: number;
    toTop?: number;
}
enum ActivateDirection { "top", "right", "bottom", "left" }
interface EditorState {
    active: boolean;
    content: string;
    row: number;
    column: number;
}

export interface WikiEditTableProps extends ImgI18NTranslatedComponentProps {
    table: WikiTableDownloadDto;
    onChange: (table: WikiTableDownloadDto) => void;
    noEdit?: boolean;
}

export interface WikiEditTableState {
    rows: Cell[][];
    headerCounter: number;
    mergePoints: Array<MergePoint>;
    editMode: boolean;
    activateDirection?: ActivateDirection;
    editor: EditorState;
    showReallyDestroy: boolean;
}

class WikiEditTable extends React.Component<WikiEditTableProps, WikiEditTableState> {
    private tableContent: WikiTableDownloadDto;
    //private editor: Editor | undefined;

    constructor(props: WikiEditTableProps) {
        super(props);
        this.tableContent = this.props.table;
        this.state = {
            rows: [[]],
            headerCounter: 0,
            mergePoints: new Array<MergePoint>(),
            //editMode: !this.props.noEdit ?? false,
            editMode: false,
            editor: { active: true, content: "", row: 0, column: 0 },
            showReallyDestroy: false
        }
    }

    componentDidMount() {
        this.reset(false);
    }
    componentDidUpdate(prevProps: WikiEditTableProps, prevState: WikiEditTableState) {
        if (!_.isEqual(this.props.table, prevProps.table)) {
            this.reset(true);
        }
    }

    public render() {
        const { rows, editMode, editor } = this.state;
        const tableContent = this.tableContent;

        if (rows.length === 1 && rows[0].length === 0) {
            return (<></>);
        }

        console.log('rows', rows);

        return (
            <ImgThemeConsumer>
                {(theme) => {
                    return (
                        <div style={{ padding: this.props.noEdit ? 0 : "16px 0" }}>
                            {/* {!this.props.noEdit && editMode &&
                                <SaveInfo>
                                    {this.props.t('you have to click the "save table" button to apply your changes!')}
                                </SaveInfo>
                            } */}
                            <T.TSS editMode={editMode}>
                                {/*editor.active */ editMode &&
                                    <T.WEDEditorStyled>
                                        <Editor
                                            value={editor.content}
                                            onChange={this.editorOnChangeFinished}
                                        // height={50}
                                        // noFocus={true}
                                        // onInit={this.setEditor}
                                        />
                                        {/* <Button
                                            icon="check"
                                            kind="primary"
                                            onClick={this.editFinished}
                                            floated="right"
                                        /> */}
                                    </T.WEDEditorStyled>
                                }
                                <ContextMenuTrigger id="same_unique_identifier">
                                    <TableResponsive>
                                        <Tbody>
                                            {_.map(tableContent.rows, (r, row) =>
                                                <Tr key={row}>
                                                    {_.map(r.cells, (cell, column) => {
                                                        const lastColumn = r.cells.length - 1;
                                                        const lastRow = tableContent.rows.length - 1;

                                                        const stateCell = rows[row][column];
                                                        console.log('row-cell-column', row, cell, column);
                                                        console.log('stateCell', stateCell);

                                                        if (cell.merged) {
                                                            return false;
                                                        }

                                                        const customClassName = row === 0 ? "header" :
                                                            stateCell?.select ? "select" :
                                                                stateCell?.error ? "error check" :
                                                                    stateCell?.state ? "marked check" : undefined;
                                                        const cellUnderEdit = editor.row === row && editor.column === column && !this.props.noEdit;
                                                        return (
                                                            <Td
                                                                className={editMode ? customClassName + (cellUnderEdit ? " underEdit" : "") : customClassName}
                                                                colSpan={cell.colSpan}
                                                                rowSpan={cell.rowSpan}
                                                                key={column}
                                                                data-row={row}
                                                                data-column={column}
                                                            >
                                                                {column === 0 && stateCell !== undefined &&
                                                                    <>
                                                                        {editMode &&
                                                                            <T.EditRowIconLeft onClick={this.activateRow(row, column, ActivateDirection.left)} active={stateCell.active ?? false}>
                                                                                <Icon name="edit table left" />
                                                                            </T.EditRowIconLeft>
                                                                        }
                                                                        {(stateCell?.select && this.state.activateDirection === ActivateDirection.left) &&
                                                                            <T.RowSettingsContainer>
                                                                                <ButtonGroup vertical fluid spacing={3} width="100%">
                                                                                    <Button fluid icon="add above" content="add row above" kind="transparentButton" onClick={this.addRow(row, true)} />
                                                                                    <Button fluid icon="add below" content="add row below" kind="transparentButton" onClick={this.addRow(row, false)} />
                                                                                    <Button fluid icon="delete row" content="delete row" kind="transparentButton" onClick={this.delRow(row)} />
                                                                                </ButtonGroup>
                                                                            </T.RowSettingsContainer>
                                                                        }
                                                                    </>
                                                                }
                                                                {column === lastColumn && stateCell !== undefined &&
                                                                    <>
                                                                        {editMode &&
                                                                            <T.EditRowIconRight onClick={this.activateRow(row, column, ActivateDirection.right)} active={stateCell.active ?? false}>
                                                                                <Icon name="edit table right" />
                                                                            </T.EditRowIconRight>
                                                                        }
                                                                        {(stateCell?.select && this.state.activateDirection === ActivateDirection.right) &&
                                                                            <T.RowSettingsContainerRight>
                                                                                <ButtonGroup vertical fluid spacing={3} width="100%">
                                                                                    <Button fluid icon="add above" content={this.props.t("add row above")} kind="transparentButton" onClick={this.addRow(row, true)} />
                                                                                    <Button fluid icon="add below" content={this.props.t("add row below")} kind="transparentButton" onClick={this.addRow(row, false)} />
                                                                                    <Button fluid icon="delete row" content={this.props.t("delete row")} kind="transparentButton" onClick={this.delRow(row)} />
                                                                                </ButtonGroup>
                                                                            </T.RowSettingsContainerRight>
                                                                        }
                                                                    </>
                                                                }
                                                                {row === 0 && stateCell !== undefined &&
                                                                    <>
                                                                        {editMode &&
                                                                            <T.EditColumnIconTop onClick={this.activateColumn(column, ActivateDirection.top)} active={stateCell.active ?? false}>
                                                                                <Icon name="edit table top" />
                                                                            </T.EditColumnIconTop>
                                                                        }
                                                                        {(stateCell?.select && this.state.activateDirection === ActivateDirection.top) &&
                                                                            <T.ColumnSettingsContainer>
                                                                                <ButtonGroup vertical fluid spacing={3} width="100%">
                                                                                    <Button fluid icon="add left" content={this.props.t("add column before")} kind="transparentButton" onClick={this.addColumn(column, false)} />
                                                                                    <Button fluid icon="add right" content={this.props.t("add column after")} kind="transparentButton" onClick={this.addColumn(column, true)} />
                                                                                    <Button fluid icon="delete column" content={this.props.t("delete column")} kind="transparentButton" onClick={this.delColumn(column)} />
                                                                                </ButtonGroup>
                                                                            </T.ColumnSettingsContainer>
                                                                        }
                                                                    </>
                                                                }
                                                                {row === lastRow && stateCell !== undefined &&
                                                                    <>
                                                                        {editMode &&
                                                                            <T.EditColumnIconBottom onClick={this.activateColumn(column, ActivateDirection.bottom)} active={stateCell.active ?? false}>
                                                                                <Icon name="edit table bottom" />
                                                                            </T.EditColumnIconBottom>
                                                                        }
                                                                        {(stateCell?.select && this.state.activateDirection === ActivateDirection.bottom) &&
                                                                            <T.ColumnSettingsContainerBottom>
                                                                                <ButtonGroup vertical fluid spacing={3} width="100%">
                                                                                    <Button fluid icon="add left" content="add column before" kind="transparentButton" onClick={this.addColumn(column, false)} />
                                                                                    <Button fluid icon="add right" content="add column after" kind="transparentButton" onClick={this.addColumn(column, true)} />
                                                                                    <Button fluid icon="delete column" content="delete column" kind="transparentButton" onClick={this.delColumn(column)} />
                                                                                </ButtonGroup>
                                                                            </T.ColumnSettingsContainerBottom>
                                                                        }
                                                                    </>
                                                                }
                                                                {editMode &&
                                                                    <T.EditIcon className="EditIcon">
                                                                        {stateCell !== undefined && !stateCell?.select &&
                                                                            <>
                                                                                {!stateCell.state ?
                                                                                    <>
                                                                                        <Button icon="circle" kind="transparentButton" size="mini" floated="right" onClick={this.setCoordinates(row, column)} />
                                                                                        <Button icon="edit" kind="transparentButton" size="mini" floated="right" onClick={this.editCell(row, column)} />
                                                                                    </>
                                                                                    :
                                                                                    <Button icon="circle dot" kind="transparentButton" size="mini" floated="right" onClick={this.setCoordinates(row, column)} />
                                                                                }
                                                                            </>
                                                                        }
                                                                    </T.EditIcon>
                                                                }
                                                                {/* 
                                                                {editMode &&
                                                                    "(" + row + "," + column + ")"} */}

                                                                <SafeHTML html={cell.content + ''} />
                                                            </Td>
                                                        );
                                                    })}
                                                </Tr>
                                            )}
                                        </Tbody>

                                    </TableResponsive>
                                </ContextMenuTrigger>
                            </T.TSS>

                            {editMode && this.state.mergePoints.length === 0 &&
                                <CancelWithConfirmation
                                    cancel={this.destroyTable}
                                    icon="bomb"
                                    cancelText={this.props.t("destroy table and start over")}
                                    confirmText={this.props.t('yes, destroy table')}
                                    kind="secondary"
                                    tooltipInitialButton={this.props.t("destroy table and start over")}
                                    iconOnly
                                />
                            }
                            {this.state.mergePoints.length === 2 &&
                                <Button
                                    icon="merge"
                                    content={this.props.t("merge selected cell")}
                                    kind="sendButton"
                                    floated="left"
                                    onClick={this.merge}
                                //disabled={this.state.mergePoints.length !== 2} 
                                />
                            }
                            {this.state.mergePoints.length === 1 &&
                                <Button
                                    icon="unmerge"
                                    content={this.props.t("unmerge selected cell")}
                                    kind="primary"
                                    onClick={this.unmerge}
                                    floated="left"
                                // disabled={this.state.mergePoints.length !== 1} 
                                />
                            }
                            {!this.props.noEdit && !this.state.showReallyDestroy &&
                                <>
                                    <Button
                                        icon={editMode ? "edit close" : "edit"}
                                        floated="right"
                                        kind={editMode ? "primary" : "secondary"}
                                        content={editMode ? this.props.t("close edit mode") : this.props.t("edit table")}
                                        onClick={this.toggleEditMode}
                                    />
                                </>
                            }
                            {/* {editMode &&
                                <Button
                                    floated="right"
                                    icon="undo"
                                    content="reset all"
                                    kind="cancelButton"
                                    onClick={this.reset}
                                />

                            } */}
                            {this.state.mergePoints.length > 0 &&
                                <Button
                                    icon="check times"
                                    content={"unmark selected cells"}
                                    kind="cancelButton"
                                    onClick={this.unmark}
                                    floated="right"
                                />
                            }
                            {editMode &&
                                <ContextMenu id="same_unique_identifier" style={{ backgroundColor: "white", border: "1px solid black" }}>
                                    <MenuItem data={{ action: 'info' }} onClick={this.handleClick}>
                                        show info
                                    </MenuItem>
                                    <MenuItem data={{ action: 'addrowabove' }} onClick={this.handleClick}>
                                        add row above
                                    </MenuItem>
                                    <MenuItem data={{ action: 'addrowbelow' }} onClick={this.handleClick}>
                                        add row below
                                    </MenuItem>
                                    <MenuItem data={{ action: 'delrow' }} onClick={this.handleClick}>
                                        delete row
                                    </MenuItem>
                                    <MenuItem data={{ action: 'addcolbefore' }} onClick={this.handleClick}>
                                        add column before
                                    </MenuItem>
                                    <MenuItem data={{ action: 'addcolafter' }} onClick={this.handleClick}>
                                        add column after
                                    </MenuItem>
                                    <MenuItem data={{ action: 'delcol' }} onClick={this.handleClick}>
                                        delete column
                                    </MenuItem>
                                </ContextMenu>
                            }
                        </div>
                    );
                }}
            </ImgThemeConsumer>
        );
    }
    // private setEditor = (editor: Editor) => {
    //     this.editor = editor;
    // };

    private editorOnChangeFinished = (html: string) => {
        let { editor } = this.state;

        if (editor.row === -1) {
            return;
        }
        editor.content = html;
        this.tableContent.rows[editor.row].cells[editor.column].content = html;
        this.setState({ editor });
        this.prepareTableForUpload();   // immediately update wiki article
    };

    private editCell = (row: number, column: number) => () => {
        let { editor } = this.state;
        if (editor.row === row && editor.column === column) {
            // this is second click, deselect
            editor = { active: true, content: " ", row: -1, column: -1 };
            this.setState({ editor });
            return;
        }

        const content = this.tableContent.rows[row].cells[column].content;

        editor = { active: true, content: content.length === 0 ? " " : content, row: row, column: column };

        this.setState({ editor });
        // if (this.editor) {
        //     this.editor.focus();
        // }
    };
    private delColumn = (column: number) => () => {
        this.selectColumn(undefined);
        const { rows, headerCounter } = this.state;
        const tableContent = this.tableContent;

        let mergedCellsToGo: MergedCellCoordinates = {};
        let isMergedStarted = true;
        _.forEach(tableContent.rows, (r, row) => {
            const rowSpan = r.cells[column].rowSpan ?? 1; // 1 || >1  || 0
            const colSpan = r.cells[column].colSpan ?? 1; // 1 || >1  || 0

            if (rowSpan === 1 && colSpan === 1) {
                // normal case,
                // nothing to do
            }
            else if (rowSpan === 1 && colSpan === 0) {
                // does not exist
                // nothing to do
            }
            else if (rowSpan > 1 && colSpan === 0) {
                // does not exist
                // nothing to do
            }
            else if (rowSpan === 1 && colSpan > 1) {
                // !!! top left corner, just row
                this.state.mergePoints.push({ row: row, column: column });
                this.unmerge();
            }
            else if (rowSpan > 1 && colSpan === 1) {
                // !!! top left corner, just column
                // nothing to do
                this.state.mergePoints.push({ row: row, column: column });
                this.unmerge();
            }
            else if (rowSpan > 1 && colSpan > 1) {
                // !!! top left corner, 2 dimensional cell
                this.state.mergePoints.push({ row: row, column: column });
                this.unmerge();
            }
            else if (rowSpan === 0 && colSpan === 0) {
                if (isMergedStarted) {
                    isMergedStarted = false;
                    mergedCellsToGo = this.getMergedColSpan(tableContent, row, column);
                    console.log("mergedCellsToGo", mergedCellsToGo);
                }
                // left side of merged cell
                // wont happen, is unmerged already
                if (mergedCellsToGo.toLeft === 0) {
                    console.log("impossible happened");
                }

                // first row of merged cell
                // top cell colSpan -1
                else if (mergedCellsToGo.toTop !== undefined && mergedCellsToGo.toTop === 0) {
                    this.increaseTopColSpan(tableContent, row, column, -1);
                }

                // middle of merged cell
                else if ((mergedCellsToGo.toLeft !== undefined && mergedCellsToGo.toLeft > 0)
                    && (mergedCellsToGo.toRight !== undefined && mergedCellsToGo.toRight > 0)) {
                }

                // right of merged cell
                else if (mergedCellsToGo.toRight === 0) {
                }

                if (mergedCellsToGo.toBottom !== undefined) {
                    if (mergedCellsToGo.toBottom === 0) {
                        mergedCellsToGo = {};
                        isMergedStarted = true;
                    }
                    else if (mergedCellsToGo.toBottom !== 0) {
                        mergedCellsToGo.toBottom -= 1;
                    }
                }
            }
            r.cells.splice(column, 1);
        });

        _.forEach(rows, r => {
            r.splice(column, 1);
        });
        tableContent.header.splice(column, 1);

        this.setState({ rows, headerCounter: headerCounter - 1 });
        this.prepareTableForUpload();   // if column deleted - immediately modify wiki article
    };

    private addColumn = (column: number, right: boolean) => () => {
        //console.log("add column", right);
        const col = right ? column + 1 : column;
        this.selectColumn(undefined);

        const { headerCounter, rows } = this.state;
        const tableContent = this.tableContent;

        _.forEach(rows, r => {
            r.push({ state: false, error: false, active: false, select: false });
        });
        tableContent.header.splice(col, 0, { content: "", colSpan: 1, rowSpan: 1 });

        let mergedCellsToGo: MergedCellCoordinates = {};
        let isMergedStarted = true;
        _.forEach(tableContent.rows, (r, row) => {
            let newCell: WikiTableCellDownloadDto = { content: "", colSpan: 1, rowSpan: 1 };

            const rowSpan = r.cells[column].rowSpan ?? 1; // 1 || >1  || 0
            const colSpan = r.cells[column].colSpan ?? 1; // 1 || >1  || 0
            if (rowSpan === 1 && colSpan === 1) {
                // normal case,
                // nothing to do
            }
            else if (rowSpan === 1 && colSpan === 0) {
                // does not exist
                // nothing to do
            }
            else if (rowSpan > 1 && colSpan === 0) {
                // does not exist
                // nothing to do
            }
            else if (rowSpan === 1 && colSpan > 1) {
                // !!! top left corner, just row
                // left: nothing to do
                // right: colSpan +1, local colSpan = 0, rosSpan = 0, merged = true
                if (right) {
                    r.cells[column].colSpan = colSpan + 1;
                    newCell.colSpan = 0;
                    newCell.rowSpan = 0;
                    newCell.merged = true;
                }
            }
            else if (rowSpan > 1 && colSpan === 1) {
                // !!! top left corner, just column
                // nothing to do
            }
            else if (rowSpan > 1 && colSpan > 1) {
                // !!! top left corner, 2 dimensional cell
                // left: nothing to do
                // right: top colSpan +1, local colSpan = 0, rosSpan = 0, merged = true
                if (right) {
                    r.cells[column].colSpan = colSpan + 1;
                    newCell.colSpan = 0;
                    newCell.rowSpan = 0;
                    newCell.merged = true;
                }
                mergedCellsToGo.toTop = 0;
                mergedCellsToGo.toBottom = rowSpan - 2;
                mergedCellsToGo.toLeft = 0;
                mergedCellsToGo.toRight = colSpan;
                isMergedStarted = false;
            }
            else if (rowSpan === 0 && colSpan === 0) {
                //console.log("00 row", row, column, isMergedStarted, mergedCellsToGo);
                if (isMergedStarted) {
                    isMergedStarted = false;
                    mergedCellsToGo = this.getMergedColSpan(tableContent, row, column);
                    //console.log("mergedCellsToGo", mergedCellsToGo);
                }
                // left side of merged cell
                // left: nothing to do
                // right: top colSpan +1, local colSpan = 0, rosSpan = 0, merged = false
                if (mergedCellsToGo.toLeft === 0) {
                    if (right) {
                        this.increaseTopColSpan(tableContent, row, column, +1);
                        newCell.colSpan = 0;
                        newCell.rowSpan = 0;
                        newCell.merged = true;
                    }
                }

                // middle of merged cell
                // left: top colSpan +1, local colSpan = 0, rosSpan = 0, merged = false
                // right: top colSpan +1, local colSpan = 0, rosSpan = 0, merged = false
                else if ((mergedCellsToGo.toLeft !== undefined && mergedCellsToGo.toLeft > 0)
                    && (mergedCellsToGo.toRight !== undefined && mergedCellsToGo.toRight > 0)) {
                    this.increaseTopColSpan(tableContent, row, column - mergedCellsToGo.toLeft, +1);
                    newCell.colSpan = 0;
                    newCell.rowSpan = 0;
                    newCell.merged = true;
                }

                // right of merged cell
                // left: top colSpan +1, local colSpan = 0, rosSpan = 0, merged = false
                // right: nothing to do
                else if (mergedCellsToGo.toRight === 0) {
                    const left = mergedCellsToGo.toLeft ?? 0;
                    if (!right) {
                        this.increaseTopColSpan(tableContent, row, column - left, +1);
                        newCell.colSpan = 0;
                        newCell.rowSpan = 0;
                        newCell.merged = true;
                    }
                }

                if (mergedCellsToGo.toBottom !== undefined) {
                    if (mergedCellsToGo.toBottom === 0) {
                        mergedCellsToGo = {};
                        isMergedStarted = true;
                    }
                    else if (mergedCellsToGo.toBottom !== 0) {
                        mergedCellsToGo.toBottom -= 1;
                    }
                }
            }

            r.cells.splice(col, 0, newCell);
        });

        this.setState({ rows, headerCounter: headerCounter + 1 });
        this.prepareTableForUpload();   // if column added - immediately modify wiki article
    };
    private increaseTopColSpan = (t: WikiTableDownloadDto, row: number, column: number, sign: number) => {
        let colSpan;
        for (let i = column; i >= 0; i--) {
            colSpan = t.rows[row].cells[i].colSpan ?? 1;
            if (colSpan > 1) {
                t.rows[row].cells[i].colSpan = colSpan + 1 * sign;
                return;
            }
        }
    }

    private getMergedColSpan = (t: WikiTableDownloadDto, row: number, column: number): MergedCellCoordinates => {
        //console.log("getMergedColSpan", row, column);
        let distance = 1;
        for (let i = column - 1; i >= 0; i--) {
            const colSpan = t.rows[row].cells[i].colSpan ?? 1;
            const rowSpan = t.rows[row].cells[i].rowSpan ?? 1;
            if (colSpan > 1) {
                return { toTop: 0, toBottom: rowSpan - 1, toLeft: distance, toRight: colSpan - distance - 1 };
            }
            distance++;
        }
        return {};
    };

    private addRow = (row: number, up: boolean) => () => {
        //console.log("add row", up);
        // up ? insert above : insert below
        this.selectRow(undefined);
        this.resetActivateRow();
        const tableContent = this.tableContent;
        const rows = this.state.rows;

        let cells = Array<WikiTableCellDownloadDto>();
        let stateCells = Array<Cell>();
        for (let j = 0; j < tableContent.header.length; j++) {
            cells.push({ content: "", colSpan: 1, rowSpan: 1, merged: undefined });
            stateCells.push({ state: false, error: false, active: false, select: false });
        }
        const newRow: WikiTableRowDownloadDto = { cells: cells };

        const currentRow = tableContent.rows[row];
        let isMergedStarted = true;
        let mergedCellsToGo: MergedCellCoordinates = {};
        _.forEach(currentRow.cells, (c, column) => {
            const rowSpan = c.rowSpan ?? 1; // 1 || >1 || 0
            const colSpan = c.colSpan ?? 1; // 1 || >1 || 0
            if (rowSpan === 1 && colSpan === 1) {
                // normal case,
                // nothing to do
            }
            else if (rowSpan === 1 && colSpan === 0) {
                // does not exist
                // nothing to do
            }
            else if (rowSpan > 1 && colSpan === 0) {
                // does not exist
                // nothing to do
            }
            else if (rowSpan === 1 && colSpan > 1) {
                // !!! top left corner, just row
                // up: nothing to do
                // down: nothing to do
            }
            else if (rowSpan > 1 && colSpan === 1) {
                // !!! top left corner, just column
                // up: nothing to do
                // down: current row span +1, local rowspan = 0
                if (!up) {
                    const rowSpan = tableContent.rows[row].cells[column].rowSpan ?? 1;
                    tableContent.rows[row].cells[column].rowSpan = rowSpan + 1;
                    newRow.cells[column].rowSpan = 0;
                    newRow.cells[column].colSpan = 0;
                    newRow.cells[column].merged = true;
                }
            }
            else if (rowSpan > 1 && colSpan > 1) {
                // !!! top left corner, 2 dimensional cell
                // up: nothing to do
                // down: current row span +1, local rowspan = 0, local colspan = 0
                if (!up) {
                    const rowSpan = tableContent.rows[row].cells[column].rowSpan ?? 1;
                    tableContent.rows[row].cells[column].rowSpan = rowSpan + 1;
                    newRow.cells[column].rowSpan = 0;
                    newRow.cells[column].colSpan = 0;
                    newRow.cells[column].merged = true;
                }
                mergedCellsToGo = { toTop: 0, toBottom: rowSpan - 1, toLeft: 1, toRight: colSpan - 2 };
                isMergedStarted = false;
            }
            else if (rowSpan === 0 && colSpan === 0) {
                //console.log("00 ", row, column, isMergedStarted, mergedCellsToGo);
                if (isMergedStarted) {
                    isMergedStarted = false;
                    mergedCellsToGo = this.getMergedSpan(tableContent, row, column);
                    //console.log("!", mergedCellsToGo);
                }

                // down first column
                // up: top rowspan +1, local rowspan = 0, local colspan = 0
                // down: this is last row in merged cell ? nothing to do : top rowspan +1, local rowspan = 0, local colspan = 0
                if (mergedCellsToGo.toLeft === 0) {
                    if (up) {
                        this.increaseTopRowSpan(tableContent, row, column, +1);
                        newRow.cells[column].rowSpan = 0;
                        newRow.cells[column].colSpan = 0;
                        newRow.cells[column].merged = true;
                    }
                    else {
                        if (mergedCellsToGo.toBottom !== undefined && mergedCellsToGo.toBottom > 0) {
                            this.increaseTopRowSpan(tableContent, row, column, +1);
                            newRow.cells[column].rowSpan = 0;
                            newRow.cells[column].colSpan = 0;
                            newRow.cells[column].merged = true;
                        }
                    }
                }

                // right first row
                // up: nothing to do
                // down: local colspan = 0, local rowspan = 0
                else if (mergedCellsToGo.toBottom === undefined || mergedCellsToGo.toTop === 0) {
                    if (!up) {
                        newRow.cells[column].rowSpan = 0;
                        newRow.cells[column].colSpan = 0;
                        newRow.cells[column].merged = true;
                    }
                }

                // middle
                // up: local rowspan = 0, local colspan = 0
                // down: this is last row in merged cell ? nothing to do : local rowspan = 0, local colspan = 0
                else if (mergedCellsToGo.toLeft !== undefined && mergedCellsToGo.toLeft > 0) {
                    if (up) {
                        newRow.cells[column].rowSpan = 0;
                        newRow.cells[column].colSpan = 0;
                        newRow.cells[column].merged = true;
                    }
                    else {
                        if (mergedCellsToGo.toBottom !== undefined && mergedCellsToGo.toBottom > 0) {
                            newRow.cells[column].rowSpan = 0;
                            newRow.cells[column].colSpan = 0;
                            newRow.cells[column].merged = true;
                        }
                    }
                }

                if (mergedCellsToGo.toRight === 0) {
                    isMergedStarted = true;
                    mergedCellsToGo = {};
                }
                if (mergedCellsToGo.toRight !== undefined) {
                    mergedCellsToGo.toRight -= 1;
                }
                if (mergedCellsToGo.toLeft !== undefined) {
                    mergedCellsToGo.toLeft += 1;
                }
            }
        });

        if (up)
            tableContent.rows.splice(row, 0, newRow);
        else
            tableContent.rows.splice(row + 1, 0, newRow);
        rows.splice(row, 0, stateCells);
        this.setState({ rows });
        this.prepareTableForUpload();   // if row added - immediately modify wiki article
    };
    private getMergedSpan = (t: WikiTableDownloadDto, row: number, column: number): MergedCellCoordinates => {
        let mcc: MergedCellCoordinates = {};
        let counterUp = 1;
        for (let i = row - 1; i >= 0; i--) {
            const rowSpan = t.rows[i].cells[column].rowSpan ?? 1;
            const colSpan = t.rows[i].cells[column].colSpan ?? 1;
            if (rowSpan > 1) {
                return { toLeft: 0, toRight: colSpan - 1, toTop: counterUp, toBottom: rowSpan - counterUp - 1 };
            }
            counterUp++;
        }
        return mcc;
    }
    private increaseTopRowSpan = (t: WikiTableDownloadDto, row: number, column: number, sign: number) => {
        let rowSpan;
        for (let i = row - 1; i >= 0; i--) {
            rowSpan = t.rows[i].cells[column].rowSpan ?? 1;
            if (rowSpan > 1) {
                t.rows[i].cells[column].rowSpan = rowSpan + 1 * sign;
                return;
            }
        }
    }
    private delRow = (row: number) => () => {
        this.selectRow(undefined);
        this.resetActivateRow();
        const tableContent = this.tableContent;
        const rows = this.state.rows;
        const currentRow = tableContent.rows[row];

        let isMergedStarted = true;
        let mergedCellsToGo: MergedCellCoordinates = {};
        _.forEach(currentRow.cells, (cell, column) => {
            const rowSpan = cell.rowSpan ?? 1; // 1 || >1 || 0
            const colSpan = cell.colSpan ?? 1; // 1 || >1 || 0
            if (rowSpan === 1 && colSpan === 1) {
                // normal case,
                // nothing to do
            }
            else if (rowSpan === 1 && colSpan === 0) {
                // does not exist
                // nothing to do
            }
            else if (rowSpan > 1 && colSpan === 0) {
                // does not exist
                // nothing to do
            }
            else if (rowSpan === 1 && colSpan > 1) {
                // !!! top left corner, just row
                // nothing to do
            }
            else if (rowSpan > 1 && colSpan === 1) {
                // !!! top left corner, just column
                // unmerge cell
                this.state.mergePoints.push({ row: row, column: column });
                this.unmerge();
            }
            else if (rowSpan > 1 && colSpan > 1) {
                // !!! top left corner, 2 dimensional cell
                // unmerge cell
                this.state.mergePoints.push({ row: row, column: column });
                this.unmerge();
            }
            else if (rowSpan === 0 && colSpan === 0) {
                if (isMergedStarted) {
                    isMergedStarted = false;
                    mergedCellsToGo = this.getMergedSpan(tableContent, row, column);
                }

                // down first column
                // top row span -1
                if (mergedCellsToGo.toLeft === 0) {
                    this.increaseTopRowSpan(tableContent, row, column, -1);
                }

                // right first row
                // nothing to do
                else if (mergedCellsToGo.toBottom === undefined || mergedCellsToGo.toBottom < 0) {

                }

                // inbetween
                // nothing to do
                else if (mergedCellsToGo.toLeft !== undefined && mergedCellsToGo.toLeft > 0) {

                }

                if (mergedCellsToGo.toRight === 0) {
                    isMergedStarted = true;
                    mergedCellsToGo = {};
                }
                if (mergedCellsToGo.toRight !== undefined) {
                    mergedCellsToGo.toRight -= 1;
                }
                if (mergedCellsToGo.toLeft !== undefined) {
                    mergedCellsToGo.toLeft += 1;
                }
            }
        });
        tableContent.rows.splice(row, 1);
        rows.splice(row, 1);
        this.setState({ rows });
        this.prepareTableForUpload();   // if row deleted - immediately modify wiki article
    };

    private activateColumn = (column: number, activateDirection?: ActivateDirection) => () => {
        this.resetActivateRow();
        this.selectColumn(column);
        this.setState({ activateDirection });
    };
    private selectColumn = (column?: number) => {
        const rows = this.state.rows;
        let currentState = false;
        if (column !== undefined)
            currentState = !rows[0][column].select;
        _.forEach(rows, row => {
            _.forEach(row, (cell, j) => {
                cell.select = j === column ? currentState : false;
            })
        });
    };
    private resetActivateRow = () => {
        const rows = this.state.rows;
        for (let i = 0; i < rows.length; i++) {
            for (let j = 0; j < rows[i].length; j++) {
                rows[i][j].active = false;
            }
        }
        this.setState({ rows });
    };
    private activateRow = (row: number, column: number, activateDirection?: ActivateDirection) => () => {
        //console.log("activateRow");
        const rows = this.state.rows;
        const value = rows[row][column].active;
        this.resetActivateRow();
        this.selectRow(row);
        rows[row][column].active = !value;
        this.setState({ rows, activateDirection });
    };
    private selectRow = (row?: number) => {
        const rows = this.state.rows;
        let currentState = false;
        if (row !== undefined)
            currentState = !rows[row][0].select;
        _.forEach(rows, (r, i) => {
            _.forEach(r, cell => { cell.select = i === row ? currentState : false; });
        });
    };
    private handleClick = (event: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>, data: { action: string, target: any }, target: HTMLElement) => {
        const td = this.getTd(data.target.parentElement);
        if (td === null || !this.state.editMode)
            return;
        if (data.action === "info") {
            console.log(data.action, td.dataset.row, td.dataset.column);
        }
        else if (data.action === "addrowabove") {
            this.addRow(parseInt(td.dataset.row ?? ""), true)();
        }
        else if (data.action === "addrowbelow") {
            this.addRow(parseInt(td.dataset.row ?? ""), false)();
        }
        else if (data.action === "delrow") {
            this.delRow(parseInt(td.dataset.row ?? ""))();
        }
        else if (data.action === "addcolbefore") {
            this.addColumn(parseInt(td.dataset.column ?? ""), false)();
        }
        else if (data.action === "addcolafter") {
            this.addColumn(parseInt(td.dataset.column ?? ""), true)();
        }
        else if (data.action === "delcol") {
            this.delColumn(parseInt(td.dataset.column ?? ""))();
        }
    };
    private getTd = (element: any): HTMLTableCellElement => {
        if (element instanceof HTMLTableCellElement) {
            return element as HTMLTableCellElement;
        }
        if (element !== null)
            return this.getTd(element.parentElement);
        return element;
    };

    private unmerge = () => {
        const stateRows = this.state.rows;
        const rows = this.tableContent.rows;
        const mp = this.state.mergePoints[0];
        if (mp === undefined) {
            console.log("unmerge cell is undefined");
            return;
        }
        const colSpan = rows[mp.row].cells[mp.column].colSpan ?? 1;
        const rowSpan = rows[mp.row].cells[mp.column].rowSpan ?? 1;
        //console.log("unmerge", colSpan, rowSpan);
        if (colSpan > 1 || rowSpan > 1) {
            for (let i = mp.row; i < (mp.row + rowSpan); i++)
                for (let j = mp.column; j < (mp.column + colSpan); j++) {
                    rows[i].cells[j].colSpan = 1;
                    rows[i].cells[j].rowSpan = 1;
                    rows[i].cells[j].merged = undefined;
                    stateRows[i][j].state = false;
                    stateRows[i][j].error = false;
                }
        }
        else {
            stateRows[mp.row][mp.column].state = false;
            stateRows[mp.row][mp.column].error = false
        }
        this.setState({ mergePoints: [], rows: stateRows });
        this.prepareTableForUpload();   // if unmerged - immediately modify wiki article
    };
    private unmark = () => {
        const rows = this.state.rows;

        for (let i = 0; i < rows.length; i++) {
            for (let j = 0; j < rows[i].length; j++) {
                rows[i][j].state = false;
            }
        }
        this.setState({ mergePoints: [] });
    };
    private unsetError = () => {
        const rows = this.state.rows;

        for (let i = 0; i < rows.length; i++) {
            for (let j = 0; j < rows[i].length; j++) {
                rows[i][j].error = false;
            }
        }
        this.setState({ mergePoints: [] });
    };
    private prepareTableForUpload = () => {
        const tableContent = _.cloneDeep(this.tableContent);
        tableContent.header = tableContent.rows.splice(0, 1)[0].cells;
        //console.log("table for upload:", tableContent);
        this.props.onChange(tableContent);
    }

    private reset = (cdu: boolean) => {
        //console.log("reset called", cdu);
        this.tableContent = _.cloneDeep(this.props.table);

        if (this.tableContent.header === undefined || this.tableContent.rows === undefined) {
            this.tableContent = this.generate();
        }
        if (this.tableContent.header.length > 0)
            this.tableContent.rows.splice(0, 0, { cells: _.cloneDeep(this.tableContent.header) });
        else
            this.tableContent.header = _.cloneDeep(this.tableContent.rows[0].cells);    // if header is empty - make a new one

        let rows = Array<Cell[]>();
        for (let i = 0; i < this.tableContent.rows.length + 1; i++) {
            let cells = Array<Cell>();
            for (let j = 0; j < this.tableContent.header.length; j++) {
                cells.push({ state: false, error: false, active: false, select: false });
            }
            rows.push(cells);
        }
        let editor: EditorState = this.state.editor;
        //console.log("editor", editor);
        if (!cdu) {
            editor = { active: true, content: "", row: 0, column: 0 };
            if (this.tableContent.rows.length !== 0 && this.tableContent.rows[0].cells.length !== 0)
                editor.content = this.tableContent.rows[0].cells[0].content;
        }

        this.setState({ rows, headerCounter: this.tableContent.header.length, mergePoints: [], activateDirection: undefined, editor });
    };
    private generate = () => {
        const columns = 1;
        const rows = 1;

        const header: Array<WikiTableCellDownloadDto> = new Array<WikiTableCellDownloadDto>();
        let rr = new Array<WikiTableRowDownloadDto>();
        for (let i = 0; i < columns; i++) {
            //header.push({ content: "column #" + i, colSpan: 1, rowSpan: 1, });
            header.push({ content: "title", colSpan: 1, rowSpan: 1, });
        }
        for (let i = 0; i < rows; i++) {
            let cells = Array<WikiTableCellDownloadDto>();
            for (let j = 0; j < columns; j++) {
                //cells.push({ content: i + "" + j, colSpan: 1, rowSpan: 1, });
                cells.push({ content: "cell", colSpan: 1, rowSpan: 1, });
            }
            rr.push({ cells: cells });
        }

        const tableContent: WikiTableDownloadDto = {
            lng: "de-DE",
            header: header,
            rows: rr
        };

        return tableContent;
    };
    private merge = () => {
        const rows = this.tableContent.rows;
        const stateCells = _.clone(this.state.rows);

        const mps = this.makeDefault(this.state.mergePoints); // marked cells to {upper left; down right}

        // merging merged?
        for (let i = mps[0].row; i <= mps[1].row; i++)
            for (let j = mps[0].column; j <= mps[1].column; j++) {
                if (rows[i].cells[j].merged !== undefined) {
                    console.log("attempt to merge already merged cells. unmerge first", i, j);
                    stateCells[i][j].error = true;
                    this.setState({ rows: stateCells });
                    this.unmark();
                    return;
                }
            }

        // calculate column span
        let colSpan = 0;
        for (let i = mps[0].column; i <= mps[1].column; i++) {
            colSpan += rows[mps[0].row].cells[i].colSpan ?? 1;
        }

        // calculate row span
        let rowSpan = 0;
        for (let i = mps[0].row; i <= mps[1].row; i++) {
            rowSpan += rows[i].cells[mps[0].column].rowSpan ?? 1;
        }

        // init content cells
        for (let i = mps[0].row; i <= mps[1].row; i++)
            for (let j = mps[0].column; j <= mps[1].column; j++) {
                rows[i].cells[j].merged = true;
                rows[i].cells[j].colSpan = 0;
                rows[i].cells[j].rowSpan = 0;
                stateCells[i][j].state = false;
            }

        // init upper left corner
        rows[mps[0].row].cells[mps[0].column].colSpan = colSpan;
        rows[mps[0].row].cells[mps[0].column].rowSpan = rowSpan;
        rows[mps[0].row].cells[mps[0].column].merged = false;

        this.setState({ mergePoints: [] });
        this.prepareTableForUpload();   // if merged- immediately modify wiki article
    };
    private makeDefault = (mps: MergePoint[]): MergePoint[] => {
        if (mps.length !== 2) {
            return mps;
        }
        //marked cells to {upper left; down right}

        //const mps = this.state.mergePoints;
        let defaultedMps = new Array<MergePoint>();
        if (mps[0].row <= mps[1].row && mps[0].column <= mps[1].column) {
            // default expected, upper left and down right
            defaultedMps = mps;
        }
        else if (mps[0].row <= mps[1].row && mps[0].column >= mps[1].column) {
            // upper right - down left
            defaultedMps.push({ row: mps[0].row, column: mps[1].column });
            defaultedMps.push({ row: mps[1].row, column: mps[0].column });
        }
        else if (mps[0].row >= mps[1].row && mps[0].column >= mps[1].column) {
            // down right - upper left
            defaultedMps.push(mps[1]);
            defaultedMps.push(mps[0]);
        }
        else {
            // down left - upper right
            defaultedMps.push({ row: mps[1].row, column: mps[0].column });
            defaultedMps.push({ row: mps[0].row, column: mps[1].column });
        }
        return defaultedMps;
    };
    private toggleEditMode = () => {
        this.setState({ editMode: !this.state.editMode, editor: { active: true, content: " ", row: -1, column: -1 } });
        this.resetActivateRow();
        if (this.state.editMode) {
            //this.prepareTableForUpload();
        }
    }

    private toggleShowReallyDestroy = () => {
        this.setState({ showReallyDestroy: !this.state.showReallyDestroy })
    }

    private setCoordinates = (row: number, column: number) => () => {
        const rows = _.clone(this.state.rows);
        this.unsetError();
        //const colIndex = _.findIndex(rows[row], r => r === cell);
        const mp: MergePoint = { row: row, column: column };

        rows[row][column].state = !rows[row][column].state;
        //console.log("index", index, rows[row], cell);

        this.resetActivateRow();
        this.selectRow();

        let mergePoints = _.clone(this.state.mergePoints);
        if (rows[row][column].state) {
            mergePoints.push(mp);
            if (mergePoints.length > 2) {
                this.markBetween([mergePoints[0], mergePoints[1]], false);
                mergePoints.shift();
                mergePoints.shift();
                // if (r)
                //     rows[r.row][r.column].state = true;
                this.setState({ mergePoints });
                return;
            }
        }
        else {
            _.remove(mergePoints, m => m.row === mp.row && m.column === mp.column);
        }
        if (mergePoints.length === 2) {
            mergePoints = this.markBetween(mergePoints, true);
        }
        this.setState({ mergePoints });
    };
    private markBetween = (mps: MergePoint[], state: boolean): MergePoint[] => {
        const rows = this.state.rows;
        const m = this.makeDefault(mps);
        for (let i = m[0].row; i <= m[1].row; i++)
            for (let j = m[0].column; j <= m[1].column; j++) {
                rows[i][j].state = state;
            }
        return mps;
    };

    private destroyTable = () => {
        const table: WikiTableDownloadDto = { lng: this.props.table.lng, header: [], rows: [] };
        this.props.onChange(table);
    }
}
export default translate("wiki")(WikiEditTable);