import { appendPx, belowBreakpointOrEqual, fadeColor, fadeIn, getColor, keyframes, lightenColor, styled } from 'imaginarity-react-ui';

export const slideInLeft = keyframes`
    from {
        transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        transform-origin: 0% 50%;
        filter: blur(40px);
        opacity: 0;
    }
    to {
        transform: translateX(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    } 
  `;
export const slideInRight = keyframes`
  from {
      transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
      transform-origin: 0% 50%;
      filter: blur(40px);
      opacity: 0;
  }
  to {
      transform: translateX(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      filter: blur(0);
      opacity: 1;
  } 
`;

export const OuterContainer = styled.div<{ editing: boolean, invisible: boolean }>`
  transition: 0.1s all ease-out;
  width: 100%;
  display: grid;
  grid-template-columns: ${props => belowBreakpointOrEqual(props, "tablet") ? "40px auto max-content" : "40px 180px auto max-content"};
  grid-template-rows: ${props => belowBreakpointOrEqual(props, "tablet") ? "40px max-content" : "1fr"};
  ${props => props.editing && "background: rgba(0,0,0,0.05);"};
  font-size: 0.85rem;
  color: ${props => props.invisible ? lightenColor(props, getColor(props, props.theme.colors.accent), 90) : getColor(props, props.theme.colors.mainForeground)};
  margin: ${props => props.editing ? "15px 0" : 0};
  padding: ${props => props.editing ? "10px 0 10px 5px" : "0px 0px 0px 5px"};
  border-left:3px solid ${props => props.editing ? getColor(props, props.theme.colors.accent) : getColor(props, props.theme.colors.mainBackground)};
`;
export const IconContainer = styled.div`
  grid-row-start: 1;
  grid-column-start: 1;
  align-items: center;
`;
export const TitleContainer = styled.div<{ editing: boolean }>`
  grid-row-start: 1;
  grid-column-start: 2;
  align-items: center;
  line-height:40px;
  font-size: 0.8rem;
  font-weight: bolder;
`;
export const ButtonContainer = styled.div`
  grid-row-start: 1;
  grid-column-start: ${props => belowBreakpointOrEqual(props, "tablet") ? 3 : 4};
`;
export const DataContainer = styled.div<{ noLineHeight?: boolean, isDropdown?: boolean, onEdit?: boolean }>`
  grid-row-start: ${props => belowBreakpointOrEqual(props, "tablet") ? 2 : 1};
  grid-column-start: ${props => belowBreakpointOrEqual(props, "tablet") ? 2 : 3};
  grid-column-end: ${props => belowBreakpointOrEqual(props, "tablet") ? 4 : 4};
  //line-height: ${props => props.noLineHeight ? "unset" : "40px"};
  line-height: ${props => belowBreakpointOrEqual(props, "tablet") ? props.isDropdown ? 40 : 20 : 40}px;
  align-items: center;
  height: ${props => belowBreakpointOrEqual(props, "tablet") ? props.isDropdown ? "40px" : "fit-content" : "40px"};
  padding-right: ${props => belowBreakpointOrEqual(props, "tablet") ? props.isDropdown ? 0 : 10 : 0}px;
  margin-top: ${props => belowBreakpointOrEqual(props, "tablet") ? props.isDropdown ? 0 : props.onEdit ? 10 : 0 : 0}px;
  margin-bottom:  ${p => p.isDropdown && belowBreakpointOrEqual(p, "tablet") ? "10px" : 0};
  &>div>div{
    border-style: ${p => p.isDropdown ? "none !important" : "inherit"};
    border-right-style: ${p => p.isDropdown ? "solid !important" : "inherit"};
    border-left-style: ${p => p.isDropdown ? "solid !important" : "inherit"};
    border-top-style: ${p => p.isDropdown && belowBreakpointOrEqual(p, "tablet") ? "solid !important" : "inherit"};
    border-bottom-style: ${p => p.isDropdown && belowBreakpointOrEqual(p, "tablet") ? "solid !important" : "inherit"};
    border-color: ${p => p.isDropdown ? getColor(p, "@middleLightGrey") : "inherit"};
  }
`;

export const PasswordContainerNew = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr 40px;
  grid-template-rows: max-content ;
  row-gap:${p => belowBreakpointOrEqual(p, "tablet") ? "0" : "10px"};
  column-gap: 0px;
  grid-row-start: ${props => belowBreakpointOrEqual(props, "tablet") ? 2 : 1};
  grid-column-start: ${props => belowBreakpointOrEqual(props, "tablet") ? 2 : 3};
  grid-column-end: ${props => belowBreakpointOrEqual(props, "tablet") ? 4 : 4};
`;
export const PasswordContainerFirstDescription = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  padding-right: ${props => belowBreakpointOrEqual(props, "tablet") ? 0 : "10px"};
`;
export const PasswordContainer2Description = styled.div`
  grid-column-start: ${p => belowBreakpointOrEqual(p, "tablet") ? 1 : 1};
  grid-column-end: ${p => belowBreakpointOrEqual(p, "tablet") ? 2 : 2};
  line-height: 40px;
  grid-row: 2;
`;
export const PasswordContainer3Description = styled.div`
  grid-column-start: ${p => belowBreakpointOrEqual(p, "tablet") ? 1 : 1};
  grid-column-end: ${p => belowBreakpointOrEqual(p, "tablet") ? 4 : 2};
  grid-row: ${p => belowBreakpointOrEqual(p, "tablet") ? 4 : 3};
  line-height: 40px;
`;
export const PasswordContainer2Input = styled.div`
  grid-column-start: ${p => belowBreakpointOrEqual(p, "tablet") ? 1 : 2};
  grid-column-end: ${p => belowBreakpointOrEqual(p, "tablet") ? 3 : 3};
  padding-left: ${p => belowBreakpointOrEqual(p, "tablet") ? 0 : "20px"};
  padding-right: ${p => belowBreakpointOrEqual(p, "tablet") ? 0 : "10px"};
  grid-row: ${p => belowBreakpointOrEqual(p, "tablet") ? 3 : 2};  
`;
export const PasswordContainer3Input = styled.div`
  grid-column-start: ${p => belowBreakpointOrEqual(p, "tablet") ? 1 : 2};
  grid-column-end: ${p => belowBreakpointOrEqual(p, "tablet") ? 3 : 3};
  padding-left: ${p => belowBreakpointOrEqual(p, "tablet") ? 0 : "20px"};
  padding-right: ${p => belowBreakpointOrEqual(p, "tablet") ? 0 : "10px"};
  grid-row: ${p => belowBreakpointOrEqual(p, "tablet") ? 5 : 3};
`;
export const PasswordContainer4Input = styled.div`
  grid-column-start: ${p => belowBreakpointOrEqual(p, "tablet") ? 1 : 2};
  grid-column-end: ${p => belowBreakpointOrEqual(p, "tablet") ? 3 : 3};
  padding-left: ${p => belowBreakpointOrEqual(p, "tablet") ? 0 : "20px"};
  padding-right: ${p => belowBreakpointOrEqual(p, "tablet") ? 0 : "10px"};
  grid-row: ${p => belowBreakpointOrEqual(p, "tablet") ? 6 : 4};
  .info{
    color: ${p => getColor(p, "@accent")};
    margin-top: ${p => belowBreakpointOrEqual(p, "tablet") ? "-5px" : "-15px"};
    text-transform: uppercase;
    font-size: 0.7rem;
    letter-spacing: 1px;
    svg{
      stroke: ${p => getColor(p, "@accent")};
    }
  }
`;
export const PasswordContainerEditButton = styled.div`
  width: 40px;
  height: 40px;
  float: right;
  display: ${props => belowBreakpointOrEqual(props, "tablet") ? "block" : "none"};
`;
export const PasswordContainerButton = styled.div`
  grid-column: 3;
  grid-row: 1;
  display: ${props => belowBreakpointOrEqual(props, "tablet") ? "none" : "block"};
`;
export const PasswordContainerCheckButton = styled.div`
  grid-column: 3;
  grid-row: ${p => belowBreakpointOrEqual(p, "tablet") ? 5 : 3};
`;
export const Spacer = styled.div`
  width: 100%;
  margin: 2px 0 2px 0;
  border-bottom:1px solid ${props => getColor(props, props.theme.colors.middleLightGrey)};
`;
export class UserSC {
  public static Container = styled.div`
    text-align: center;
    height: 100px;
`;
  public static Header = styled.div`
    font-weight: bold;
    font-size: x-large;
`;
  public static Content = styled.div`
    margin-top: 10px;
`;
  public static AttributeContainer = styled.div<{ onEdit: boolean, editable: boolean, noBorder: boolean }>`
  border-left: ${props => props.noBorder ? 0 : "4px"} solid ${props => getColor(props, props.theme.colors.accent)};
  width: 100%;
  margin-bottom: 5px;
  display: table;
  padding: 1px;
    background-color: ${props => props.onEdit && getColor(props, props.theme.colors.veryLightGrey)};
  &:hover{
    background-color: ${props => props.editable && getColor(props, props.theme.colors.veryLightGrey)};
  }
`;

  public static IconContainer = styled.div`
  padding-left: 7px;
  width: 4%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
`;

  public static InnerContainer = styled.div`
  display: table-row;
  height: 38px;
`;

  public static ValueContainer = styled.div<{ editable: boolean, slogan: boolean }>`
  width: ${props => props.editable ? "80%" : "95%"};
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  padding-left: 5px;

  &:before { 
    content: ${props => props.slogan === true ? "open-quote" : ""};
    font-weight: 800;
    font-size:1.5em;
    float:left;
    padding-right:1px;
    color:${props => fadeColor(props, getColor(props, props.theme.colors.accent), 50)};
} 
&:after { 
    content: ${props => props.slogan === true ? "close-quote" : ""};
    font-weight: 800;
    font-size:1.5em;
    padding-left:1px;
    color:${props => fadeColor(props, getColor(props, props.theme.colors.accent), 50)};
} 
`;
  public static ValueNameContainer = styled.div`
  width: 40%;
  display: table-cell;
  vertical-align: middle;
`;
  public static ValueAttributeContainer = styled.div`
  width: 60%;
  display: table-cell;
  vertical-align: middle;
`;
  public static EditingsContainer = styled.div`
  padding-left: 5px;
  width: 15%;
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  text-align: right;
`;
  public static InputContainer = styled.div`
  animation: ${fadeIn} 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
  margin-bottom: -1em;
`;
  public static ImageContainer = styled.div`
  grid-row-start: 1;
  grid-column-start: 1;
  padding-top: 10px;
  align-items: center;
`;
  public static UserStatusContainer = styled.div<{ asking: boolean, showDropdown: boolean, cursor: "pointer" | "default" }>`
    width: 100%;
    height:${props => appendPx(props.theme.headerHeight)};
    overflow: hidden;
    cursor: ${props => props.cursor};
    background: ${props => props.showDropdown ? getColor(props, props.theme.colors.veryLightGrey) : (props.asking ? lightenColor(props, getColor(props, props.theme.colors.accent), 100) : getColor(props, props.theme.colors.mainBackground))};
    transition: 0.2s all ease-out;
    border-bottom: 1px solid ${props => getColor(props, props.theme.colors.mainBackground)};
    &:hover{
      background: ${props => lightenColor(props, props.theme.colors.mainBackground, 90)};
      color: ${props => getColor(props, props.theme.colors.darkerGrey)};
      border-bottom: 1px solid ${props => getColor(props, props.theme.colors.middleLightGrey)};
    }
`;
  public static AvatarImage = styled.div`
    cursor: pointer;
    img{
    transition: 0.2s all ease-out;
      &:hover{
        filter: grayscale(1);
        transform-origin: center center;
        transform: scale(0.95);
    }
    }
`;
  public static UserAvatar = styled.div`
    float: right;
    height: calc(${props => appendPx(props.theme.headerHeight)} - 20px);
    width: calc(${props => appendPx(props.theme.headerHeight)} - 20px);
    margin-top:10px;
    margin-right: calc(5% + 5px);
    svg{
        animation: ${slideInLeft} 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
        stroke: ${props => getColor(props, props.theme.colors.mainBackground)};
    }
`;
  public static UserInfo = styled.div`
    float: left;
    text-align:right;
    width: calc(100% - ${props => appendPx(props.theme.headerHeight)} - 20px);
    position: relative;
`;
  public static UserInfoBell = styled.div`
  position: absolute;
  left: 10px;
  top: 17px;
  svg{
    fill: ${p => fadeColor(p, "@accentRed", 20)};
  }
`;
  public static UserInfoMessagesBox = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  transition: 0.2s all ease-out;
  cursor: pointer;
    &:hover{
    background: ${p => getColor(p, "@veryLightGrey")};
  }
`;
  public static UserInfoMessagesBoxFooter = styled.div`
  border-top: 1px solid ${p => getColor(p, "@middleLightGrey")};
  background: ${p => getColor(p, "@veryLightGrey")};
  line-height: 40px;
  width: 100%;
  height: 40px;
`;
  public static MessageSystem = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 10px;
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    background: ${p => fadeColor(p, "@accentBlue", 10)};
    svg{
        margin-top: -7px;
        margin-left: 2px;
        float: left;
        stroke: ${p => getColor(p, "@darkGrey")};
    }
    `;
  public static MessageAvatar = styled.img`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 10px;
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    `;

  public static UserInfoMessagesBoxItem = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; 
  line-height: 40px;
`;





  public static UserInfoFigure = styled.div`
  position: absolute;
  left: 26px;
  top: 18px;
  font-size: 0.8em;
  color: ${p => getColor(p, "@accentRed")};
`;
  public static UserInfoName = styled.div`
    margin-top:15px;
    font-weight: bolder;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left:10px;
`;
  public static UserInfoEmail = styled.div`
    line-height: 15px;
    font-size: 0.7rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left:10px;
`;
  public static LogOut = styled.div`
    margin-top:10px;
    button>div{margin:0;}
`;
  public static ReallyLogoutContainer = styled.div`
    float:right;
    text-align: center;
    font-size: 1rem;
    color: ${props => getColor(props, props.theme.colors.mainBackground)};
    font-weight: 100;
    line-height:40px;
    padding:0 10px;
    width:calc(100% - 80px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
  public static LogIn = styled.div`
    padding-top:5px;
    height:${props => appendPx(props.theme.headerHeight)};
    width: 100%;
    background: ${props => getColor(props, props.theme.colors.lightGrey)};
    transition: 0.1s all ease-out;
    button{ color: ${props => getColor(props, props.theme.colors.veryLightGrey)}; }
    svg{ stroke: ${props => getColor(props, props.theme.colors.veryLightGrey)} }
    &:hover{
        background:  ${props => getColor(props, props.theme.colors.accent)};
        button{ color: ${props => getColor(props, props.theme.colors.mainBackground)} }
        svg{ stroke: ${props => getColor(props, props.theme.colors.mainBackground)} }
    }
`;
  public static PostsBookmarksEtc = styled.div`
    display: block;
    width: 100%;
    height: 40px;
    margin-top: 20px;
    background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
`;
  public static PostsBookmarksEtcContent = styled.div`
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    //margin-bottom:30px;
    min-height: 40px;
`;





  public static UserButtonsContainer = styled.div`
    position: absolute;
    z-index: 2147483637;
    top: ${p => appendPx(p.theme.headerHeight)};
    //right: 0;
    background: ${p => getColor(p, p.theme.colors.mainBackground)};  
    width: ${p => appendPx(p.theme.sidebars.default.maxWidth)};
    padding: 10px;
    box-shadow: 0 6px 8px -6px ${p => getColor(p, p.theme.colors.darkerGrey)};  
    border: 1px solid ${props => getColor(props, props.theme.colors.middleLightGrey)};
    border-top: none;
`;
}

export const RenderUser = styled.div`
  margin-top: ${props => belowBreakpointOrEqual(props, "tablet") ? -5 : 6}px;
`;
export const EPContainer = styled.div`
  padding: 10px 10px 5px 10px;
  margin-top: -13px;
  box-shadow: 0 6px 8px -6px ${p => getColor(p, "@darkGrey")};
  width: calc(100% - 4px);
  margin-left: 2px;
`;
