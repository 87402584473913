import * as React from 'react';
export interface MyHomeStartJRRProps {
}

const MyHomeStartJRR = (p: MyHomeStartJRRProps) => {

    return (
        <>

            <div style={{ height: 10 }} />
            <h2>Job Role Ratio</h2>

            <div style={{ height: 10 }} />
            <iframe title="test" src="https://porsche-ag.eu10.sapanalytics.cloud/sap/fpa/ui/tenants/8f995/app.html#/story2&/s2/FFE05E0006A00242BB05CDB5DD87F698/?mode=view" style={{ width: "100%", height: "800px", border: "1px solid #f0f0f0" }}></iframe>

        </>
    );
}
export default MyHomeStartJRR;
