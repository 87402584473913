import SidebarTitleContainer from 'components/Sidebars/SidebarTitleContainer';
import _ from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { useImgI18N } from 'services/ImgI18N';
import { Container } from 'views/Feed/FeedFilterAll';
import PlaylistShortInfo from './PlaylistShortInfo';
import { PlaylistDownloadDto } from 'collaboration-service';



const mapper = (state: ApplicationState) => ({
    playlistStates: state.playlistsState.playlistStates,
    playlistStatesTopic: state.playlistsState.playlistStatesTopic,
    routeParams: state.params,
});


interface WikiPlaylistContentInfoProps {
}

const WikiPlaylistContentInfo = (p: WikiPlaylistContentInfoProps) => {
    const { routeParams, playlistStates, playlistStatesTopic } = useSelector(mapper, shallowCompare);
    const id = routeParams?.id as string;

    const { t } = useImgI18N('general');

    return (
        <>
            {playlistStates &&
                <Container>
                    <SidebarTitleContainer icon="user" title={t("personal playlists")} />
                    {_.map(_.filter(playlistStates, p => p.state !== "Finished"), (pp, i) => {
                        const active = id === pp.playlist.id;
                        const pl = pp.playlist as PlaylistDownloadDto;
                        return (
                            <React.Fragment key={i} >
                                <PlaylistShortInfo key={pp.id} playlist={pl} active={active} />
                            </React.Fragment>
                        );
                    }
                    )}
                </Container>
            }
            {playlistStatesTopic &&
                <Container>
                    {/* <SidebarTitleContainer icon="playlist" title={t("topic specific playlists")} /> */}
                    <SidebarTitleContainer icon="playlist" title={t("my playlists")} />
                    {_.map(_.filter(playlistStatesTopic, p => p.state !== "Finished"), (ppt, j) => {
                        const active = id === ppt.playlist.id;
                        const pl = ppt.playlist as PlaylistDownloadDto;
                        return (
                            <React.Fragment key={j} >
                                <PlaylistShortInfo playlist={pl} active={active} />
                            </React.Fragment>
                        );
                    }
                    )}
                </Container>
            }
        </>
    );
}
export default WikiPlaylistContentInfo;


