import { appendPx, belowBreakpointOrEqual, getButtonSizes, getColor, styled } from "imaginarity-react-ui";
import { keyframes } from "styled-components";

export class ComponentsSC {
    public static TitleContainer = styled.div`
    line-height: 60px;
    font-size: 1.5rem;
    background: ${props => getColor(props, props.theme.colors.veryLightGrey)};
    width: 100%;
    padding: 0 20px;
    color: ${props => getColor(props, props.theme.colors.mainForeground)};
    border-top: 1px solid ${props => getColor(props, props.theme.colors.middleLightGrey)};
    border-bottom: 1px solid ${props => getColor(props, props.theme.colors.middleLightGrey)};
    margin-bottom: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;  
    svg{ 
        stroke: ${props => getColor(props, props.theme.colors.mainForeground)};
        stroke-width: 2px;
    }
`;
    public static MenuContainer = styled.div<{ fluid?: boolean }>`
    box-shadow: 0px 8px 6px -6px ${p => getColor(p, p.theme.colors.mainForeground)};
    background-color: ${p => getColor(p, p.theme.menus.tertiary_menu.backgroundColor)};
`;
    public static Container = styled.div`
    padding: 5px;
    ${props => belowBreakpointOrEqual(props, "tablet") ? "display: none;" : ""};
`;
    public static AppStartContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    //width: 320px;
    //height: 200px;
    width: 80vw;
    height: 80vh;
    background-color: ${props => getColor(props, props.theme.colors.accentBlack)};
    box-shadow: ${props => props.theme.appStart.boxShadow};
    background: url(${props => props.theme.appStart.backgroundUrl});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    display: grid;
    align-items: center;
    grid-template-rows: 70px 1fr 30px 30px 10px;
`;
    public static Header = styled.div`
    //grid-row-start: 1;
    color: ${props => getColor(props, props.theme.appStart.color)};
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => appendPx(props.theme.fontSizes.h2)};
    text-align: center;
    font-weight: ${props => props.theme.fontWeights.default};
    //line-height: ${props => props.theme.buttonSizes?.default.buttonHeight ?? "40px"};
`;
    public static Content = styled.div`
    grid-row-start: 2;
    color: ${props => getColor(props, props.theme.appStart.color)};
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => appendPx(props.theme.fontSizes.h3)};
    text-align: center;
    font-weight: ${props => props.theme.fontWeights.default};
    padding: 15px;
`;
    public static Progress = styled.div.attrs<{ progress: number }>(props => ({
        style: {
            width: `calc(${props.progress}% - 20px)`
        }
    })) <{ progress: number }>`
    grid-row-start: 3;
    margin-left:10px;
    margin-right: 10px;
    height: 10px;
    margin-top: 10px;
    background-color: ${props => getColor(props, props.theme.appStart.progressColor)};
    box-shadow: ${props => props.theme.appStart.progressBoxShadow ? props.theme.appStart.progressBoxShadow : "none"};
`;
    public static ProgressMsg = styled.div`
    grid-row-start: 4;
    width: 100%;
    text-align: center;
    font-size: ${props => appendPx(props.theme.fontSizes.default)};
    height: 30px;
    color: ${props => getColor(props, props.theme.appStart.color)};
    font-family: ${props => props.theme.fontFamily};
`;

    public static slideIn = keyframes`
    from {
        transform: translateX(-600px);
    }
    to {
        transform: translateX(0);
    } 
  `;

    public static HeaderBar = styled.div`
    height: ${p => appendPx(getButtonSizes(p.theme, "large").buttonHeight)};
    line-height: ${p => appendPx(getButtonSizes(p.theme, "large").buttonHeight)};
    display: grid;
    grid-template-columns: 1fr ${p => appendPx(getButtonSizes(p.theme, "large").buttonHeight)};
    //margin-bottom: -10px;
    svg{
        transform: scale(1.4);
    }
`;
    public static HeaderBarTitle = styled.div`
    overflow-x: hidden;
    float: left;
    width: 100%;
    transition: transform 0.2s ease-out;
    font-size: 1rem;
    text-align: center;
    //text-transform: uppercase;
    color: ${p => getColor(p, "@mainForeground")};
    //font-weight: 100;
    //letter-spacing: 0.5pt;
`;
    public static HeaderBarMenuTitle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: ${p => appendPx(getButtonSizes(p.theme, "large").buttonHeight)};
    z-index: 2147483637;

    overflow-x: hidden;
    overflow-y: hidden;
    font-size: 1rem;
    text-align: left;
    text-transform: uppercase;
    color: ${p => getColor(p, "@mainForeground")};
    background: ${p => getColor(p, "@veryLightGrey")};
    font-weight: 100;
    letter-spacing: 0.5pt;
    height: ${p => appendPx(getButtonSizes(p.theme, "large").buttonHeight)};
    line-height: ${p => appendPx(getButtonSizes(p.theme, "large").buttonHeight)};
    padding-left: 10px;
    border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
`;

    public static HeaderBarBtn = styled.div`
    
`;
    public static ButtonContainer = styled.div`
    position: fixed;
    top: ${p => appendPx(getButtonSizes(p.theme, "large").buttonHeight)};
    left: 0px;
    right: 0px;
    bottom: 0;
    z-index: 2147483637;
    max-height: calc(100vh - ${p => appendPx(getButtonSizes(p.theme, "large").buttonHeight)});
    overflow-y: auto;
    background: ${p => getColor(p, "@mainBackground")};
    animation: ${this.slideIn} 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
`;
    public static ButtonContainerGrid = styled.div<{ active?: boolean, fixed?: boolean, lastItem?: boolean }>`
        width: 100%;
        height: 50px;
        border-bottom: 1px solid ${p => p.fixed || p.lastItem ? getColor(p, "@middleLightGrey") : getColor(p, "@veryLightGrey")};
        display: grid;
        grid-template-columns: 50px 1fr;
        line-height: 50px;
        background: ${p => p.active ? getColor(p, "@darkGrey") : p.fixed ? getColor(p, "@veryLightGrey") : "transparent"};
        color: ${p => p.active ? getColor(p, "@mainBackground") : getColor(p, "@darkGrey")};
        font-size: 1rem;
        svg{
            transform: scale(1.4);
            stroke-width: 1.5;
            stroke: ${p => p.active ? getColor(p, "@mainBackground") : getColor(p, "@darkGrey")};
        }
`;
    public static ButtonContainerGridIcon = styled.div`
        text-align: center;
`;
    public static ButtonContainerGridTitle = styled.div<{ italic?: boolean }>`
        text-align: left;
        font-style: ${p => p.italic ? "italic" : "normal"};
`;
    public static ButtonContainerGridLogout = styled.div`
        text-align: center;
        float: right;
        width: 50px;
        height: 50px;
        background: ${p => getColor(p, "@accent")};
        margin-left: 10px;
        svg{
            stroke: ${p => getColor(p, "@mainBackground")};
        }
`;
    public static ButtonContainerGridHeader = styled.div<{ active: boolean }>`
        text-align: center;
        float: right;
        width: 50px;
        height: 50px;
        background: ${p => p.active ? getColor(p, "@accent") : getColor(p, "@mainBackground")};
        svg{
            stroke: ${p => p.active ? getColor(p, "@mainBackground") : getColor(p, "@darkerGrey")};
            transform: scale(1.4);
        }
`;
}