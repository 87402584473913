import { styled, getColor, belowBreakpointOrEqual } from "imaginarity-react-ui";

const MasterContentMaster = styled.div`
    height: 100%;   
    display: grid;
    display: -ms-grid;
    width: 100%;
    grid-template-rows: ${props => props.theme.headerHeight}px 1fr;
    -ms-grid-rows: ${props => props.theme.headerHeight}px 1fr;
    -ms-grid-columns: 1fr;
`;
export const MasterLayoutContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    height: 100%;
    margin: auto;
    box-shadow: ${props => props.theme.shadowedBordersOverMaxSize ?? "none"};
`;
export const MasterLeft = styled(MasterContentMaster)`
    color: ${props => getColor(props, props.theme.leftArea.color)};
    background-color: ${props => getColor(props, props.theme.leftArea.backgroundColor)};  
`;
export const MasterMain = styled(MasterContentMaster)`
    color: ${props => getColor(props, props.theme.mainArea.color)};
    background-color: ${props => getColor(props, props.theme.mainArea.backgroundColor)};       
`;
export const MasterRight = styled(MasterContentMaster)`
    color: ${props => getColor(props, props.theme.rightArea.color)};
    background-color: ${props => getColor(props, props.theme.rightArea.backgroundColor)};       
`;
export const MasterHeader = styled.div`
    background-color: ${props => getColor(props, props.theme.headerArea.backgroundColor)};
    color: ${props => getColor(props, props.theme.headerArea.color)};
    grid-row-start: 1;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-row: 1;
    grid-column: 1;
    height: ${props => props.theme.headerHeight}px;
    width: 100%;
    align-items: center;
`;
export const MasterContent = styled.div<{ overflowY?: boolean, noPadding?: boolean }>`
    grid-row-start: 2;
    -ms-grid-row: 2;
    grid-row: 2;
    height: calc(100% - ${props => props.theme.headerHeight}px);
    overflow-X: hidden;
    overflow-Y: ${props => props.overflowY ? "auto" : "hidden"};
    padding:${props => props.noPadding || belowBreakpointOrEqual(props, "mobile") ? "0px" : "0 5%"};
    position:relative;
`;