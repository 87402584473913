import { WelcomeRoadSC } from 'components/WelcomeRoad/WelcomeRoadSC';
import { styled } from 'imaginarity-react-ui';
import * as React from 'react';
import Div100vh from 'react-div-100vh';
import { connect } from 'react-redux';
import { ApplicationState } from "services/ApplicationState/ApplicationState";
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { AppEntry } from 'services/ApplicationState/MasterLayoutRouter';
import { ThemeContext } from 'styled-components';

const mapper = (state: ApplicationState) => ({ params: state.params });

export const SsoAuthenticationFailedEntry: AppEntry = {
    contentRenderer: () => <RPWD />,
    icon: "user",
    name: "sso_failed",
    title: "SSO Failed",
    route: "ssoFailed",
    noLogin: true,
    hidden: true,
    left: {
        hidden: true,
    },
    right: {
        hidden: true,
    }
}

const Container = styled.div`
  position: relative;
  height: 100vh;
  display: grid;
  display: -ms-grid;
  grid-template-rows: 1fr auto 1fr;
  -ms-grid-rows: 1fr auto 1fr;
  -ms-grid-columns: 1fr;
`;

const Center = styled.div`
  grid-row: 2;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-column: 1;
`;

const BackgroundContainer = styled(WelcomeRoadSC.FullContainer)`
    background: ${p => p.theme.colors.mainBackground};
`;

interface SsoAuthenticationFailedProps extends ComponentWithMappedApplicationState<typeof mapper> {
}

const SsoAuthenticationFailed = (p: SsoAuthenticationFailedProps) => {
    const token = p.params?.token as string | undefined;
    const theme = React.useContext(ThemeContext);
    console.log("token", token);
    console.log("theme", theme);

    return (
        <Div100vh>
            <BackgroundContainer>
                <Container >
                    <Center>
                        SSO Authentication failed!
                    </Center>
                </Container>
            </BackgroundContainer>
        </Div100vh>
    );
}
const RPWD = connect(mapper)(SsoAuthenticationFailed);