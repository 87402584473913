import { AnswerDownloadDto, SortQuestionDownloadDto, SortUserAnswer } from 'collaboration-service';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { Media } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { QuizSC as T } from '../QuizSC';
import { getMediaFromLoadState, QuestionImplementationProps } from './Question';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage });

export interface SortQuestionProps extends QuestionImplementationProps, ComponentWithMappedApplicationState<typeof mapper> {
}

interface ExtendedSortUserAnswer extends SortUserAnswer {
    answers: AnswerDownloadDto[];
}
export interface SortQuestionState {
    userAnswer: ExtendedSortUserAnswer;
}

class SortQuestion extends React.Component<SortQuestionProps, SortQuestionState> {
    constructor(props: SortQuestionProps) {
        super(props);

        this.state = {
            userAnswer: {
                id: "",
                answers: []
            },
        }
    }

    componentDidMount() {
        this.reset();
    }

    componentDidUpdate(prevProps: SortQuestionProps) {
        if (this.props.question.id !== prevProps.question.id) {
            this.reset();
        }
    }

    public render() {
        const data = this.props.question as SortQuestionDownloadDto;
        const { loaded } = this.props;
        //console.log(this.state.userAnswer);

        return (
            <>
                <T.Functions>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="pa">
                            {(provided, snapshot) =>
                                <div ref={provided.innerRef}>
                                    {_.map(this.state.userAnswer.answers, (a, i) => {
                                        const mediaIndex = _.findIndex(loaded, l => l.media?.reference === a.media?.reference ?? "");
                                        const hasImage = a.media?.links.length! > 0;
                                        const hasText = a.descriptions.length! > 0;
                                        return (
                                            <Draggable
                                                draggableId={"answer-" + a.id}
                                                index={i}
                                                key={a.id}
                                                isDragDisabled={this.props.displayCorrect || this.props.timeIsOver}
                                            >
                                                {(p, s) => {
                                                    return (
                                                        <T.SQdraggableContainer
                                                            ref={p.innerRef}
                                                            {...p.draggableProps}
                                                            {...p.dragHandleProps}
                                                            isDragging={s.isDragging}
                                                            style={{
                                                                ...p.draggableProps.style,
                                                                left: "auto",
                                                                top: "auto",
                                                            }}
                                                        >
                                                            {this.props.displayCorrect &&
                                                                <div style={{ position: "relative" }}>
                                                                    <T.Ribbon color="info" ><span>
                                                                        {_.findIndex(data.possibleAnswers, (pa: AnswerDownloadDto) => pa.id === a.id) + 1}
                                                                    </span> </T.Ribbon>
                                                                </div>
                                                            }
                                                            {hasText && !hasImage &&
                                                                <T.SortText full>
                                                                    {getTranslated(a.descriptions, this.props.contentLanguage)?.text}
                                                                </T.SortText>
                                                            }
                                                            {hasImage &&
                                                                <Media
                                                                    media={getMediaFromLoadState(loaded, mediaIndex) ?? { url: "", type: "" }}
                                                                    height="78px"
                                                                    withImageBackground
                                                                    imageBackgroundHeight={78}
                                                                    imageModal
                                                                />
                                                            }
                                                            <T.CoolaterateIcon
                                                                full
                                                                name={i === 0 ? "chevron down" : i === (data.possibleAnswers.length - 1) ? "chevron up" : "chevron up down"}
                                                                size="28px"
                                                            />
                                                        </T.SQdraggableContainer>
                                                    );
                                                }}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            }
                        </Droppable>
                    </DragDropContext>
                </T.Functions>
            </>
        );
    }


    private onDragEnd = (result: DropResult) => {
        if (result.destination) {
            const answers = _.clone(this.state.userAnswer.answers);
            const ele = answers.splice(result.source.index, 1);
            answers.splice(result.destination.index, 0, ...ele);
            const userAnswer = this.state.userAnswer;
            userAnswer.selectedAnswers = _.map(answers, c => c.id);
            userAnswer.answers = answers;
            if (this.props.answer)
                this.props.answer(userAnswer);
            this.setState({ userAnswer });
        }
    }

    private reset = () => {
        const data = this.props.question as SortQuestionDownloadDto;
        const { answers, questionNumber } = this.props;
        const ua = ((answers ?? [])[questionNumber ?? 0] as any)?.userAnswer as ExtendedSortUserAnswer;
        this.setState({
            userAnswer: {
                id: ua?.id ?? "",
                selectedAnswers: _.map(data.possibleAnswers, a => a.id),
                answers: ua?.answers ?? data.possibleAnswers
            }
        });
    };
}

export default connect(mapper)(SortQuestion);