import { CategoryDownloadDto, ControllerHelper, ElementNode, PodcastPostDownloadDto, PostController, PostControllerTube, PostDownloadDto, ViewStats } from 'collaboration-service';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import PodcastFeedAudio from 'components/Podcast/PodcastFeedVideo';
import PodcastFeedVideoTable from 'components/Podcast/PodcastFeedVideoTable';
import { AutoGrid, AutoGridColumn, Button, Icon, Loader, LoaderInline, getUriFromLinkByName, useBelowBreakpoint, useBelowBreakpointOrEqual, useThemePart } from 'imaginarity-react-ui';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { breakpoints } from 'services/ApplicationState/MasterLayout';
import { PluginActions } from 'services/ApplicationState/PluginStateHandling';
import { routeNames } from 'services/Config';
import { combineRoutes } from 'services/Helpers/RoutingHelper';
import ScrollHelper from 'services/Helpers/ScrollHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import History from 'services/History';
import { ImgI18NTranslatedComponentProps, translate, useImgI18N } from 'services/ImgI18N';
import { adminGroupsOfType, changeLastGroupToType } from 'services/StoreDependantHelpers';
import { PostHubEventType } from 'services/signalR/PostHubConnection';
import { useAppTheme } from 'services/useAppTheme';
import usePostHub from 'services/usePostHub/usePostHub';
import useSearchableFeed from 'views/UseSearchableFeed/UseSearchableFeed';
import PodcastHeader from './PodcastHeader';
import { PodcastTheme } from './PodcastMain';
import { PodcastSC as T } from './PodcastSC';
import PodcastSearch from './PodcastSearch';

export const PodcastToDoItems = []

const mapper = (state: ApplicationState) => ({
    params: state.params,
    contentLanguage: state.contentLanguage,
    posts: state.podcastState.currentPosts,
    categories: state.podcastState.categories,
    currentCategoryStack: state.podcastState.currentCategoryStack,
    filter: state.podcastState.filter,
    viewStats: state.podcastState.viewStats,
    viewMode: state.podcastState.viewMode,
    route: state.route,
    tableView: state.podcastState.tableView
});
export interface PodcastFeedProps extends ImgI18NTranslatedComponentProps, ComponentWithMappedApplicationState<typeof mapper> {
    trending?: boolean;
}
export interface PodcastFeedState {
    posts: PostDownloadDto[];
    token?: string;
    loading: boolean;
    showBookmarkedPodcasts: boolean;
    showRecentlyViewedPodcastss: boolean;
}

const PodcastFeed = (p: PodcastFeedProps) => {
    const [short, setShort] = React.useState<boolean>(true);
    const [showFavorites, setShowFavorites] = React.useState<boolean>(false);
    const [scrollHelper] = React.useState(new ScrollHelper());
    const [showPodcasts, setShowPodcasts] = React.useState<boolean>(true);
    const podcastTheme = useAppTheme<PodcastTheme>("podcastTheme");
    const [search, setSearch] = React.useState<string>();
    const { t } = useImgI18N('podcast');
    const { filter, dispatch, categories, currentCategoryStack, posts, contentLanguage, params, viewStats, viewMode, tableView } = p;

    const setPosts = React.useMemo(() => (posts?: PodcastPostDownloadDto[]) => {
        dispatch(Actions.setPodcastCurrentPosts(posts));
    }, [dispatch]);

    const setViewStats = React.useMemo(() => (viewStats: ViewStats[]) => {
        dispatch(Actions.addPodcastViewStats(viewStats));
    }, [dispatch]);

    const setCategoriesAndCategoryStack = React.useMemo(() => (categories: CategoryDownloadDto[] | undefined, categoryStack: ElementNode<CategoryDownloadDto>[] | undefined) => {
        dispatch(PluginActions.batchActions([Actions.setPodcastCategories(categories), Actions.setPodcastCurrentCategoryStack(categoryStack)]));
    }, [dispatch]);

    const gridView = !tableView;

    const {
        bookmarks,
        containerDivProps,
        currentCategory,
        gotoCategory,
        inCategory,
        loadMoreBookmarks,
        loading,
        loadingPosts,
        searching,
        tiles,
        currentFilter,
        subCatItemCount,
        loadMoreElement,
    } = useSearchableFeed<PodcastPostDownloadDto>({
        categoryMapName: "porsche_tube",
        groupType: "PODCAST",
        filter,
        setPosts,
        setCategoriesAndCategoryStack,
        setSearch,
        categories,
        categoryStack: currentCategoryStack,
        params,
        posts,
        scrollHelper,
        search,
        setViewStats,
        getViewStatsControllerMethod: PostControllerTube.GetPodcastPostsViews,
        getCountForCategories: PostController.GetCountForAllGroups,
        viewStats,
        viewMode,
        withBookmarks: true,
        inActive: !showPodcasts,
        loadingCount: 25
    });

    const ids = React.useMemo(() => _.uniq(_.map(posts, o => o.group_id)), [posts])
    const eventTypes: PostHubEventType[] = React.useMemo(() => ["update", "add", "delete"], []);
    const onUpdate = React.useMemo(() => (post: PostDownloadDto, type: PostHubEventType) => {
        const update = async () => {
            if (type === "update") {
                const newPosts = _.clone(posts) ?? [];
                const idx = newPosts.findIndex(f => f.id === post.id);
                if (idx >= 0) {
                    const np = await ControllerHelper.singleCall({ id: post.id }, PostController.GetSinglePost, true) as PodcastPostDownloadDto;
                    if (np) {
                        if (newPosts[idx].hidden !== np.hidden) {
                            if (np.hidden) {
                                newPosts.splice(idx, 1);
                                setPosts(newPosts);
                            }
                        }
                        else {
                            newPosts.splice(idx, 1, np);
                            setPosts(newPosts);
                        }
                    }
                }
            }
            if (type === "delete") {
                const newPosts = _.clone(posts) ?? [];
                const idx = newPosts.findIndex(f => f.id === post.id);
                if (idx >= 0) {
                    newPosts.splice(idx, 1);
                    setPosts(newPosts);
                }
            }
        };
        update();
    }, [posts, setPosts]);

    usePostHub({
        onUpdate,
        ids,
        eventTypes
    });

    const scrollTo = React.useMemo(() => (id: string) => {
        scrollHelper.scrollTo(id);
        if (id === 'favorites') {
            setShowFavorites(true);
        }
    }, [scrollHelper]);

    React.useEffect(() => {
        dispatch(Actions.setScrollTo(scrollTo));
        changeLastGroupToType(["PODCAST"]);
    }, [dispatch, scrollTo]);

    const showFoundPodcasts = tiles.length > 0;
    const headerRenderer = React.useMemo(() => (title: string, toggle: () => void, noButton?: boolean) => (
        <>
            <div style={{ height: 0 }} ref={scrollHelper.getRef("myPodcasts")} />
            <T.SectionConatinerTitle style={{ marginBottom: 20, cursor: "pointer" }} onClick={toggle}>
                <Icon name={noButton === true ? "editor list bullet" : "play circle"} style={{ float: "left", marginRight: 10 }} />
                {title}
                {noButton !== true &&
                    <Button
                        kind={podcastTheme.darkMode ? "transparentButtonDark" : "transparentButton"}
                        icon={showPodcasts ? "chevron up" : "chevron down"}
                        onClick={toggle}
                        floated="right"
                    />
                }
            </T.SectionConatinerTitle>
        </>
    ), [scrollHelper, showPodcasts, podcastTheme.darkMode]);

    const isAdmin = (adminGroupsOfType("PODCAST") ?? []).length > 0;

    const tileRenderer = React.useMemo(() => (p: PostDownloadDto, i: number) => {
        const idx = currentCategoryStack ? currentCategoryStack.length - 1 : -1;
        const currentCategoryId = (idx >= 0 && currentCategoryStack !== undefined) ? currentCategoryStack[idx]?.node?.id ?? "" : "";
        return (
            <AutoGridColumn key={p.id} >
                <T.PodcastPostContainerNew >
                    <PodcastFeedAudio
                        post={p as PodcastPostDownloadDto}
                        key={p.id}
                        gridView={gridView}
                        currentCategoryId={currentCategoryId}
                        isAdmin={isAdmin}
                    />
                    <T.TextShadower top={true} />
                    <T.TextShadower top={false} />
                </T.PodcastPostContainerNew>
            </AutoGridColumn>
        );
    }, [gridView, currentCategoryStack, isAdmin]);

    const tileRendererMobile = React.useMemo(() => (p: PostDownloadDto, i: number) => {
        const idx = currentCategoryStack ? currentCategoryStack.length - 1 : -1;
        const currentCategoryId = (idx >= 0 && currentCategoryStack !== undefined) ? currentCategoryStack[idx]?.node?.id ?? "" : "";
        return (
            <div style={{ width: "calc(100% + 10px)", transform: "translateX(-5px)", marginBottom: 40 }}>
                <PodcastFeedAudio
                    post={p as PodcastPostDownloadDto}
                    key={p.id}
                    gridView={gridView}
                    currentCategoryId={currentCategoryId}
                    isAdmin={isAdmin}
                />
            </div>
        );
    }, [gridView, currentCategoryStack, isAdmin]);

    const tileRowRenderer = React.useMemo(() => (p: PostDownloadDto, i: number) => {
        const idx = currentCategoryStack ? currentCategoryStack.length - 1 : -1;
        const currentCategoryId = (idx >= 0 && currentCategoryStack !== undefined) ? currentCategoryStack[idx]?.node?.id ?? "" : "";
        const odd = i % 2 !== 0;
        return (
            <PodcastFeedVideoTable
                post={p as PodcastPostDownloadDto}
                key={p.id}
                currentCategoryId={currentCategoryId}
                odd={odd}
            />
        );
    }, [currentCategoryStack]);

    const onSearch = (val: string) => {
        if (val !== search) {
            setSearch(val);
        }
    }

    const onBookmarks = (short: boolean) => () => {
        setShort(short);
    };
    const goBack = () => {
        History.goBack();
    }

    const actWidth = useThemePart(t => t.content.width);
    const windowWidth = window.innerWidth;
    const cWidth = (actWidth + 11) * 100 / 90;
    const actSidebarWidth = useThemePart(t => t.sidebars.default.maxWidth as number);
    const noWhiteSpace = cWidth < breakpoints.wide.width;
    const isMobile = useBelowBreakpoint("tablet");
    const largeMobileCheck = useBelowBreakpointOrEqual("largeMobile");
    const tabletCheck = useBelowBreakpointOrEqual("tablet");
    const paneWidth = largeMobileCheck ? (actWidth - 10) : tabletCheck ? 280 : 350; // 240 : 225
    const columns = largeMobileCheck ? 1 : _.floor(actWidth / paneWidth);
    const gap = largeMobileCheck ? 10 : 20;
    const categoryTitle = searching ? p.t("search results") : currentCategory ? getTranslated(currentCategory.names, p.contentLanguage)?.text : podcastTheme.homeHeaderTitle;
    const categoryImage = currentCategory ? getUriFromLinkByName(currentCategory.media, "self") ?? podcastTheme.noCategoryImage : podcastTheme.homeImage;

    return (
        <>
            <div ref={scrollHelper.getRef("top")} style={{ height: 0 }} />
            <div {...containerDivProps} style={{ zIndex: 1, position: "relative", minHeight: "calc(100% - 60px)" }}>
                {!isMobile && <T.PodcastBGimage width='30vh' right={noWhiteSpace ? "0px" : `calc((${windowWidth}px - ${cWidth}px - ${actSidebarWidth} + 12px) / 2)`} />}
                {largeMobileCheck && p.route === combineRoutes([routeNames.podcast]) &&
                    <div style={{ paddingBottom: 20, marginTop: 5 }}>
                        <PodcastSearch
                            onSearch={onSearch}
                            filter={currentFilter}
                            lightMode={!podcastTheme.darkMode}
                            accent={podcastTheme.podcastAccent}
                        />
                    </div>
                }
                <T.PodcastHomeMainContainer >
                    <PodcastHeader
                        asSearchResult={searching}
                        categoryImage={categoryImage}
                        categoryName={categoryTitle}
                        lightMode={!podcastTheme.darkMode}
                    >
                        {!inCategory && !largeMobileCheck ?
                            <PodcastSearch
                                onSearch={onSearch}
                                filter={currentFilter}
                                lightMode={!podcastTheme.darkMode}
                                accent={podcastTheme.podcastAccent}
                            /> :
                            <div />
                        }
                    </PodcastHeader>
                    {inCategory ?
                        <>
                            <div style={{ background: "rgba(0,0,0,0.2)", width: 40, height: 40, float: "right" }}>
                                <Button
                                    kind="transparentButton"
                                    icon="chevron left"
                                    floated="right"
                                    onClick={goBack}
                                    iconColor={podcastTheme.podcastAccent}
                                />
                            </div>
                            <div style={{ width: "calc(100% - 40px)" }}>
                                <Breadcrumbs
                                    darkMode={podcastTheme.darkMode}
                                    root="podcast"
                                    asSearchResult={searching && (posts?.length ?? 0) !== 0}
                                    categoryRouteName="podcast_cat"
                                    currentCategoryStack={currentCategoryStack}
                                    accent={podcastTheme.podcastAccent}
                                />
                            </div>
                        </>
                        :
                        <div style={{ height: 20 }} />
                    }
                    {!searching && (categories?.length ?? 0) > 0 &&
                        <>
                            {subCatItemCount["done"] !== 1 &&
                                <LoaderInline active infoText={p.t("loading")} animationColor={podcastTheme.podcastAccent} />
                            }
                            {gridView ?
                                <>
                                    <T.SectionConatinerTitle style={{ marginBottom: 20 }}>
                                        <Icon name="content aggregation" style={{ float: "left", marginRight: 10 }} />
                                        {p.t("categories")}
                                    </T.SectionConatinerTitle>
                                    <AutoGrid
                                        columns={tabletCheck ? columns + 1 : columns + 2}
                                        gap={gap}
                                    >
                                        {_.map(_.filter(categories, cat => subCatItemCount[cat.id] !== 0), cat => {
                                            const curCount = subCatItemCount[cat.id];
                                            const catHasNoPodcasts = curCount === 0;
                                            return (
                                                <AutoGridColumn key={cat.id}>
                                                    {subCatItemCount["done"] === 1 ?
                                                        <T.PodcastCategoryContainer
                                                            activated={!catHasNoPodcasts}
                                                            onClick={catHasNoPodcasts ? undefined : gotoCategory("podcast_cat", cat.id)}
                                                        >
                                                            <T.PC
                                                                imageUrl={getUriFromLinkByName(cat.media, "self") ?? podcastTheme.noCategoryImage}
                                                            />
                                                            {cat.svgImage &&
                                                                <T.PodcastCategoryImage>
                                                                    <img src={getUriFromLinkByName(cat.svgImage, "self")} alt="" />
                                                                </T.PodcastCategoryImage>
                                                            }
                                                            {!cat.svgImage &&
                                                                <T.PodcastCategoryText>
                                                                    {getTranslated(cat.names, contentLanguage)?.text}
                                                                </T.PodcastCategoryText>
                                                            }
                                                            {curCount !== undefined &&
                                                                <T.CategoryVideosCount accent={podcastTheme.podcastAccent}>
                                                                    {curCount ? curCount : p.t("no podcasts")}
                                                                </T.CategoryVideosCount>
                                                            }

                                                        </T.PodcastCategoryContainer>
                                                        :
                                                        <div style={{ width: "100%", position: "relative", paddingBottom: "100%" }} />
                                                    }
                                                </AutoGridColumn>
                                            );
                                        })}
                                    </AutoGrid>
                                </>
                                :
                                <>
                                    {p.categories && p.categories.length > 0 && headerRenderer(p.t("categories"), () => { }, true)}
                                    {_.map(categories, (cat, i) => {
                                        const odd = i % 2 !== 0;
                                        return (
                                            <T.PodcastCategoryContainerCat
                                                onClick={gotoCategory("podcast_cat", cat.id)}
                                                key={cat.id}
                                                odd={odd ?? false}
                                            >
                                                <T.PodcastCategoryContainerCatImage>
                                                    <T.PodcastPostTableThumbCat
                                                        imageUrl={getUriFromLinkByName(cat.media, "self") ?? podcastTheme.noCategoryImage}
                                                    />
                                                </T.PodcastCategoryContainerCatImage>

                                                <T.PodcastCategoryContainerCatTitle>
                                                    {!cat.svgImage && getTranslated(cat.names, contentLanguage)?.text}
                                                    {cat.svgImage &&
                                                        <img src={getUriFromLinkByName(cat.svgImage, "self")} alt="" style={{ float: "right", height: 80, marginTop: -20 }} />
                                                    }
                                                </T.PodcastCategoryContainerCatTitle>
                                            </T.PodcastCategoryContainerCat>
                                        );
                                    })}
                                </>
                            }
                        </>
                    }
                    {bookmarks && bookmarks.length !== 0 && currentCategory === undefined && !searching &&
                        <>
                            <div style={{ height: 40 }} ref={scrollHelper.getRef("favorites")} />
                            <T.SectionConatinerTitle style={{ marginBottom: 20, marginTop: -20, cursor: "pointer", zIndex: 1000 }} onClick={() => setShowFavorites(showFavorites ? false : true)}>
                                <Icon name="bookmark" style={{ float: "left", marginRight: 10 }} />
                                {p.t("bookmarked podcasts")}
                                <Button
                                    kind={podcastTheme.darkMode ? "transparentButtonDark" : "transparentButton"}
                                    icon={showFavorites ? "chevron up" : "chevron down"}
                                    onClick={() => setShowFavorites(showFavorites ? false : true)}
                                    floated="right"
                                />
                            </T.SectionConatinerTitle>

                            {showFavorites && !loading &&
                                <>
                                    {!gridView ?
                                        <>
                                            <T.PodcastTableViewHeader>
                                                <div style={{ textAlign: "left" }}>Preview</div>
                                                <div style={{ textAlign: "left", paddingLeft: 10 }}>Title</div>
                                                <Icon name="video player" />
                                                <Icon name="eye open" />
                                                <Icon name="star full" />
                                                <Icon name="comment" />
                                                <div style={{ fontSize: "0.85em" }}>play</div>
                                            </T.PodcastTableViewHeader>
                                            {_.map(short ? bookmarks.slice(0, 3) : bookmarks, (p: PodcastPostDownloadDto, i) => {
                                                return tileRowRenderer(p, i);
                                            })}
                                            {!short &&
                                                <T.PodcastTableViewRowShowMore onClick={onBookmarks(true)} color="@accentRed" >
                                                    <Icon
                                                        name="minus square"
                                                        color="@accentRed"
                                                        marginTop={4}
                                                        style={{ float: "left", marginRight: 10 }}
                                                    />
                                                    {p.t("show less bookmarks")}
                                                    <Button kind="transparentButtonDark" floated="right" icon="minus" onClick={onBookmarks(true)} />
                                                </T.PodcastTableViewRowShowMore>
                                            }
                                            {short && bookmarks.length > 3 &&
                                                <T.PodcastTableViewRowShowMore onClick={onBookmarks(false)} color={"@accentGreen"}>
                                                    <Icon
                                                        name="add square"
                                                        color="@accentGreen"
                                                        marginTop={4}
                                                        style={{ float: "left", marginRight: 10 }}
                                                    />
                                                    {p.t("show more bookmarks")}
                                                    <Button kind="transparentButtonDark" floated="right" icon="add" onClick={onBookmarks(false)} />
                                                </T.PodcastTableViewRowShowMore>
                                            }
                                            {!short && loadMoreBookmarks ?
                                                <T.PodcastTableViewRowShowMore onClick={loadMoreBookmarks} color="@accentGreen">
                                                    <Icon
                                                        name="add square"
                                                        color="@accentGreen"
                                                        marginTop={4}
                                                        style={{ float: "left", marginRight: 10 }}
                                                    />
                                                    {p.t("load more bookmarks")}
                                                    <Button kind="transparentButtonDark" floated="right" icon="add" onClick={loadMoreBookmarks} />
                                                </T.PodcastTableViewRowShowMore>
                                                :
                                                <div />
                                            }
                                        </>
                                        :
                                        <>
                                            <AutoGrid columns={gridView ? columns : 1} gap={gridView ? gap : 0} >
                                                {_.map(short ? bookmarks.slice(0, 3) : bookmarks, (p: PodcastPostDownloadDto, i) => {
                                                    return tileRenderer(p, i);
                                                })}
                                                <AutoGridColumn span={short ? 0 : 1}>
                                                    {!short &&
                                                        <T.BookmarkedAction onClick={onBookmarks(true)}>
                                                            <div className="BookmarkedActionIcon">
                                                                <Icon
                                                                    size={50}
                                                                    name="minus"
                                                                />
                                                            </div>
                                                            <div className="BookmarkedActionInfo">
                                                                {p.t("show less bookmarks")}
                                                            </div>
                                                        </T.BookmarkedAction>
                                                    }
                                                    {short && bookmarks.length > 3 &&
                                                        <T.BookmarkedAction onClick={onBookmarks(false)}>
                                                            <div className="BookmarkedActionIcon">
                                                                <Icon
                                                                    size={50}
                                                                    name="add"
                                                                />
                                                            </div>
                                                            <div className="BookmarkedActionInfo">
                                                                {p.t("show more bookmarks")}
                                                            </div>
                                                        </T.BookmarkedAction>
                                                    }
                                                </AutoGridColumn>
                                                <AutoGridColumn>
                                                    {!short && loadMoreBookmarks ?
                                                        <T.BookmarkedAction onClick={loadMoreBookmarks}>
                                                            <div className="BookmarkedActionIcon">
                                                                <Icon size={50} name="add" />
                                                            </div>
                                                            <div className="BookmarkedActionInfo">
                                                                {p.t("load more favorites")}
                                                            </div>
                                                        </T.BookmarkedAction>
                                                        :
                                                        <div />
                                                    }
                                                </AutoGridColumn>
                                            </AutoGrid>
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                    <div style={{ marginTop: 20 }} />
                    {showFoundPodcasts && p.categories && p.categories.length > 0 && headerRenderer(p.route.includes("cat/") ? p.t("podcasts located in {{cat}}", { cat: categoryTitle }) : p.t("uncategorized podcasts"), () => { setShowPodcasts(!showPodcasts); })}
                    <>
                        {showPodcasts &&
                            <>
                                {showFoundPodcasts &&
                                    <>
                                        {!gridView ?
                                            <>
                                                <T.PodcastTableViewHeader>
                                                    <div style={{ textAlign: "left" }}>{p.t("preview")}</div>
                                                    <div style={{ textAlign: "left", paddingLeft: 10 }}>{p.t("title")}</div>
                                                    <Icon name="video player" />
                                                    <Icon name="eye open" />
                                                    <Icon name="star full" />
                                                    <Icon name="comment" />
                                                    <div style={{ fontSize: "0.85em" }}>{p.t("play")}</div>
                                                </T.PodcastTableViewHeader>
                                                {_.map(tiles, tileRowRenderer)}
                                            </>
                                            :
                                            <>
                                                {podcastTheme.showNewTileAppearance && isMobile ?
                                                    <>
                                                        {_.map(tiles, tileRendererMobile)}
                                                    </>
                                                    :
                                                    <AutoGrid columns={columns} gap={gap}  >
                                                        {_.map(tiles, tileRenderer)}
                                                    </AutoGrid>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                <div style={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                                    <LoaderInline
                                        infoText={p.t("loading more podcasts")}
                                        textColor="#FFF"
                                        backgroundColor="rgba(0,0,0,0.4)"
                                        active={loadingPosts}
                                        animationColor={podcastTheme.podcastAccent}
                                    />
                                </div>
                            </>
                        }
                    </>

                    <Loader active={loading && !loadingPosts} light size="small" color={podcastTheme.podcastAccent} infoText={t("loading podcasts")} infoColor={podcastTheme.podcastAccent} />
                    <div ref={scrollHelper.getRef("down")} />
                    {loadMoreElement}
                </T.PodcastHomeMainContainer>
            </div>
        </>
    );
}
export default translate("podcast")(connect(mapper)(PodcastFeed));