import { detect } from "detect-browser";
export interface BrowserInfo {
    name: string;
    os: string;
    version: string;
}

export function getBrowser(): BrowserInfo {
    const toRet = detect();
    if (toRet === null)
        return {
            name: "unknown",
            os: "unknown",
            version: "-1"
        };
    return {
        name: toRet.name.toLocaleLowerCase(),
        os: toRet.os ?? "unknown",
        version: toRet.version ?? "unknown"
    };
}

export function redirect(target: string) {
    if (!(window.location.href = target))
        window.location.replace(target)
}