import { MediaDownloadDto, MultipleChoiceQuestionDownloadDto, MultipleChoiceUserAnswer } from 'collaboration-service';
import { Media } from 'imaginarity-react-ui';
import * as _ from 'lodash';
import * as React from 'react';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { QuizSC as T } from '../QuizSC';
import { QuestionImplementationProps } from './Question';


export const MultipleChoiceQuestion = (props: QuestionImplementationProps) => {
    const [idx, setIdx] = React.useState<number[]>([]);
    const { answer, question, answers, questionNumber } = props;
    const data = question as MultipleChoiceQuestionDownloadDto;
    const qId = question.id;
    const createAnswer = React.useCallback((sel: number[]) => {
        const answer: MultipleChoiceUserAnswer = {
            id: "",
            questionId: data.id,
            elapsedTime: 0,
            selectedAnswers: _.map(sel, i => data.possibleAnswers[i].id),
        };
        (answer as any).idx = sel;
        return answer;
    }, [data]);

    React.useEffect(() => {
        if (answer)
            answer(createAnswer([]));
    }, [answer, createAnswer]);

    React.useEffect(() => {
        const i = ((answers ?? [])[questionNumber ?? 0] as any)?.userAnswer?.idx ?? [];
        setIdx(i);
    }, [qId, answers, questionNumber]);


    const setSelected = (i: number) => () => {
        if (props.timeIsOver || props.displayCorrect)
            return;
        let ii = _.clone(idx);
        if (data.singleChoice)
            ii = [i];
        else {
            const pos = _.findIndex(ii, x => x === i);
            if (pos >= 0)
                ii.splice(pos, 1);
            else
                ii.push(i);
        }
        if (answer)
            answer(createAnswer(ii));
        setIdx(ii);
    };
    const { t } = props;

    return (
        <>

            <T.Functions style={{ textAlign: "center" }}>
                {_.map(data.possibleAnswers, (a, i) => {
                    const sel = _.findIndex(idx, x => x === i) >= 0;
                    const hasImage = a.media?.links.length! > 0;
                    const hasText = a.descriptions.length! > 0;
                    //const fullWidth = data.possibleAnswers.length % 2 !== 0;
                    const fullWidth = true;

                    return (
                        <T.MCQcontainer selected={sel} key={i} onClick={setSelected(i)} fullWidth={fullWidth}>
                            {data.possibleAnswers && props.displayCorrect &&
                                <>
                                    {a.isCorrect ?
                                        <T.Ribbon color="correct" ><span>{t("correct")}</span> </T.Ribbon>
                                        :
                                        <T.Ribbon color="wrong" ><span>{t("wrong")}</span> </T.Ribbon>
                                    }
                                </>
                            }
                            {hasImage &&
                                <Media
                                    media={{ media: a.media ?? {} as MediaDownloadDto, refName: "self" }}
                                    height="78px"
                                    withImageBackground
                                    imageBackgroundHeight={78}
                                    imageModal
                                />
                            }
                            {hasText && !hasImage &&
                                <T.SortText >
                                    {getTranslated(a.descriptions, props.contentLanguage)?.text ?? ""}
                                </T.SortText>
                            }
                            <T.MultipleChoiceIcon
                                name={sel ? "check circle" : "circle"}
                                selected={sel}
                            />
                        </T.MCQcontainer>
                    );
                })}
            </T.Functions>
        </>
    );
}
