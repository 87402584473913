import { ControllerHelper, PodcastPostDownloadDto, PostController } from 'collaboration-service';
import { Loader } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { lngDefinitions } from './PodcastAudio';
import { PodcastSC as T } from './PodcastSC';



const mapper = (state: ApplicationState) => ({
    routeParams: state.params,
    contentLanguage: state.contentLanguage,
})

interface PodcastLinkedAudioProps extends ComponentWithMappedApplicationState<typeof mapper> {
    publicAccess?: boolean;
}

const PodcastLinkedVideo = (p: PodcastLinkedAudioProps) => {
    const [audio, setAudio] = React.useState<PodcastPostDownloadDto>();
    const [error, setError] = React.useState<string>();

    const { routeParams, contentLanguage, publicAccess } = p;

    React.useEffect(() => {
        const load = async () => {
            const id = (routeParams?.id ?? "") as string;
            const res = await ControllerHelper.singleCall({ id }, publicAccess ? PostController.AccessPublicLink : PostController.AccessPrivateLink, true);
            //console.log('res => ', res);
            if (res)
                setAudio(res as PodcastPostDownloadDto);
            else
                setError("Could not load podcast!");
        };
        setAudio(undefined);
        setError(undefined);
        load();
    }, [routeParams, setAudio, setError, publicAccess])

    const translatedContent = getTranslated(audio?.content, contentLanguage);
    const v = translatedContent && translatedContent.useContent ? getMediaLink(translatedContent, c => c.content) : translatedContent.link;
    const sdef = translatedContent ? getTranslated(translatedContent.content?.subtitles, contentLanguage) : undefined;

    return (
        <T.TLVContainer>
            {!audio && !error && <Loader size="small" active light />}
            {error && <T.TLVError>{error}</T.TLVError>}
            {audio &&
                <T.TLVVideo>
                    <audio
                        controlsList="nodownload"
                        controls
                        autoPlay={true}
                        playsInline
                        crossOrigin="anonymous"
                    >
                        <source src={v} type="video/mp4" />
                        {_.map(translatedContent.content.subtitles, (s, idx) => {

                            const l = _.find(s.links, l => l.ref === "self");
                            const d = _.find(lngDefinitions, d => d.value.toLowerCase() === s.lng?.toLowerCase());
                            const label = d?.label;
                            if (l && d && label) {

                                //console.log('#', idx, label, s.lng, l?.uri);

                                return (
                                    <track key={s.reference} src={l?.uri} label={label} kind="captions" srcLang={s.lng} default={sdef !== undefined && sdef.lng === s.lng} />
                                );
                            }
                            return null;
                        })}
                    </audio>
                </T.TLVVideo>
            }
        </T.TLVContainer>
    );
}
export default connect(mapper)(PodcastLinkedVideo);