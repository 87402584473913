import { Button, ButtonFloates, ButtonKind, ButtonSizes, ImgIcons } from 'imaginarity-react-ui';
import * as React from 'react';


interface DownloadButtonProps {
    downloadUrl: string;
    fileName: string;
    icon?: ImgIcons;
    kind?: ButtonKind;
    text?: string;
    floated?: ButtonFloates;
    size?: ButtonSizes;
    fluid?: boolean;
    disabled?: boolean;
    active?: boolean;
    bordered?: boolean;
    iconColor?: string;
    tooltipText?: string;
}

const sanitizeFileName = (fileName: string): string => {
    let sanitized = fileName.replace(/<[^>]*>/g, ''); // Entfernt HTML-Tags
    sanitized = sanitized.replace(/[^a-zA-Z0-9\-_.]/g, '_'); // Ersetzt unerwünschte Zeichen durch Unterstrich
    sanitized = sanitized.replace(/__+/g, '_'); // Entfernt doppelte Unterstriche
    sanitized = sanitized.replace(/_+(\.[^.]+)$/, '$1'); // Entfernt Unterstriche unmittelbar vor der Dateiendung
    sanitized = sanitized.replace(/_+$/, ''); // Entfernt Unterstriche am Ende des Dateinamens

    return sanitized;
};


const DownloadButton = (p: DownloadButtonProps) => {
    const { downloadUrl, fileName, icon, kind, text, floated, size, fluid, disabled, active, bordered, iconColor, tooltipText } = p;

    const handleDownload = async () => {
        try {
            const response = await fetch(downloadUrl);
            if (!response.ok) throw new Error('Network response was not ok.');
            const blob = await response.blob();
            const sanitizedFileName = sanitizeFileName(fileName);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = sanitizedFileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download Error:', error);
        }
    };

    return (
        <Button
            icon={icon ?? "download"}
            kind={kind}
            onClick={handleDownload}
            floated={floated}
            content={text}
            size={size}
            fluid={fluid}
            disabled={disabled}
            active={active}
            bordered={bordered}
            iconColor={iconColor}
            tooltip={tooltipText ? { tooltipText: tooltipText } : undefined}
        />
    );
}
export default DownloadButton;