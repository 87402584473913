import { ExplanationDownloadDto } from 'collaboration-service';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { belowBreakpointOrEqual, Icon, ImgThemeConsumer, Media } from 'imaginarity-react-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../services/ApplicationState/ApplicationState';
import { getTranslated } from '../../services/Helpers/TranslationHelpers';
import { ImgI18NTranslatedComponentProps, translate } from '../../services/ImgI18N';
import QuizButton from './QuizButton';
import { QuizSC as T } from './QuizSC';

const mapper = (state: ApplicationState) => ({ routeParams: state.params, contentLanguage: state.contentLanguage });

export type ExplanationTypes = "preInfo" | "postInfoCorrect" | "postInfoWrong";


export interface QuestionExplanationProps extends ComponentWithMappedApplicationState<typeof mapper>, ImgI18NTranslatedComponentProps {
    explanation: ExplanationDownloadDto;
    explanationType: ExplanationTypes;
    goOn?: () => void;
    isPpeQuiz?: boolean;
}

class QuestionExplanation extends React.Component<QuestionExplanationProps> {
    public render() {
        const e = this.props.explanation;
        const t = this.props.explanationType;
        const type = t === "preInfo" ? this.props.t("introduction") : t === "postInfoCorrect" ? this.props.t("correct answer") : this.props.t("wrong answer");
        const icon = t === "preInfo" ? "info" : t === "postInfoCorrect" ? "check" : "times";
        return (
            <ImgThemeConsumer>
                {(theme) => {
                    const isMobile = belowBreakpointOrEqual({ theme }, "mobile");
                    return (
                        <>
                            {isMobile ?
                                <T.QuizBackgroundMobile>
                                    <T.QuizBackgroundMobileGrid>
                                        <T.TitleMobile>
                                            <Icon name={icon} size="30px" marginTop={0} style={{ float: "left", marginRight: 10, marginTop: 7 }} />
                                            {type}
                                        </T.TitleMobile>
                                        {e !== undefined &&
                                            <T.FunctionsMobile>
                                                <Media
                                                    media={{ media: e.media, refName: "self" }}
                                                    controls
                                                    width="100%"
                                                    imageModal
                                                />
                                                <div style={{ textAlign: "left", display: "block", marginTop: 20 }}>
                                                    <T.FunctionsHeader>
                                                        {getTranslated(e.headlines, this.props.contentLanguage)?.text ?? ""}
                                                    </T.FunctionsHeader>
                                                    {getTranslated(e.descriptions).text}
                                                </div>
                                            </T.FunctionsMobile>
                                        }
                                        <T.FooterMobile>
                                            <QuizButton text1={this.props.t("ok")} onClick={this.props.goOn} diameter={50} />
                                        </T.FooterMobile>
                                    </T.QuizBackgroundMobileGrid>
                                </T.QuizBackgroundMobile>
                                :
                                <T.QuizBackground style={{
                                    backgroundColor: this.props.isPpeQuiz ? "transparent" : "inherit",
                                    backgroundImage: this.props.isPpeQuiz ? "none" : ""
                                }}>
                                    <T.ContainerBackgroundBlur style={{
                                        background: this.props.isPpeQuiz ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.5)"
                                    }}
                                        isPpeQuiz={this.props.isPpeQuiz}
                                    >
                                        <T.ContainerGrid imageAvailable={false} decreaseView={true}>
                                            <T.HeaderInfo>
                                                <Icon name={icon} size="30px" marginTop={0} style={{ float: "left", marginRight: 10, marginTop: 7 }} />
                                                {type}
                                            </T.HeaderInfo>
                                            {e !== undefined &&
                                                <T.Functions style={{ overflowY: "auto" }}>
                                                    <Media
                                                        media={{ media: e.media, refName: "self" }}
                                                        width="100%"
                                                    />
                                                    <T.FunctionsHeader>
                                                        {getTranslated(e.headlines, this.props.contentLanguage)?.text ?? ""}
                                                    </T.FunctionsHeader>
                                                    {getTranslated(e.descriptions).text}
                                                </T.Functions>
                                            }
                                            <T.Footer>
                                                <QuizButton text1={this.props.t("ok")} onClick={this.props.goOn} diameter={90} />
                                            </T.Footer>
                                        </T.ContainerGrid>
                                    </T.ContainerBackgroundBlur>
                                </T.QuizBackground>
                            }
                        </>
                    );
                }}
            </ImgThemeConsumer>);
    }
}
export default translate("quiz")(connect(mapper)(QuestionExplanation));
