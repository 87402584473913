import { PlaylistDownloadDto } from "collaboration-service";
import { Image } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from "react";
import { gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { getTranslated } from "services/Helpers/TranslationHelpers";
import { PlaylistSC as T } from "./PlaylistSC";

interface PlaylistShortInfoProps {
    playlist: PlaylistDownloadDto;
    active: boolean;
}
const PlaylistShortInfo = (p: PlaylistShortInfoProps) => {
    const media = _.find(p.playlist.media.links, l => l.ref === "self")?.uri ?? "";
    const id = p.playlist.id;
    return (
        <T.PlaylistLinkDiv onClick={p.active ? undefined : gotoNamedRouteMethod("playlist_detail", { id: id })} >
            <T.PlaylistContainer active={p.active}>
                <T.PlaylistIcon>
                    <Image width="24px" height="24px" src={media} squared rounded />
                </T.PlaylistIcon>
                <T.PlaylistText title={p.playlist.id}>
                    {getTranslated(p.playlist.headlines).text}
                </T.PlaylistText>
                {/* <T.PlaylistHover>
                    <Icon name="circle dot" size="30px" marginTop={8} style={{ paddingLeft: 10 }} />
                </T.PlaylistHover> */}
            </T.PlaylistContainer>
        </T.PlaylistLinkDiv>
    );
};
export default PlaylistShortInfo;