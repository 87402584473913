import { FourPicturesOneSolutionQuestionDownloadDto, FourPicturesOneSolutionUserAnswer, LanguageStringDownloadDto } from 'collaboration-service';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { AutoGrid, AutoGridColumn, belowBreakpointOrEqual, Divider, ImgThemeConsumer } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { QuizSC as T } from '../QuizSC';
import { QuestionImplementationProps } from './Question';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage });
export interface FourPicturesOneSolutionQuestionProps extends QuestionImplementationProps, ComponentWithMappedApplicationState<typeof mapper> {
}

interface ExtendedFourPicturesOneSolutionUserAnswer extends FourPicturesOneSolutionUserAnswer {
    suggestions: Array<string | undefined>;
    solution: Array<string | undefined>;
    solutionLength: number;
}
export interface FourPicturesOneSolutionQuestionState {
    userAnswer: ExtendedFourPicturesOneSolutionUserAnswer;
}
class FourPicturesOneSolutionQuestion extends React.Component<FourPicturesOneSolutionQuestionProps, FourPicturesOneSolutionQuestionState> {
    constructor(props: FourPicturesOneSolutionQuestionProps) {
        super(props);
        this.state = {
            userAnswer: {
                id: "",
                suggestions: [],
                solution: [],
                solutionLength: 0
            },
        }
    }

    componentDidMount() {
        this.reset();
    }

    componentDidUpdate(prevProps: FourPicturesOneSolutionQuestionProps, prevState: FourPicturesOneSolutionQuestionState) {
        if (this.props.question.id !== prevProps.question.id) {
            this.reset();
        }
    }

    public render() {
        const { contentLanguage } = this.props;
        const data = this.props.question as FourPicturesOneSolutionQuestionDownloadDto;
        const correctAnswer = getTranslated(data.correctAnswers, contentLanguage)?.text;
        const { solutionLength, solution, suggestions } = this.state.userAnswer;

        return (
            <ImgThemeConsumer>
                {(theme) => {
                    const isTablet = belowBreakpointOrEqual({ theme }, "tablet");
                    const gap = 1;
                    const panes = theme.content.width >= 410 ? 10 : theme.content.width < 410 && theme.content.width >= 371 ? 9 : 8;
                    const columns = (solutionLength < panes ? 8 : isTablet && solutionLength >= panes) ? panes : solutionLength > 10 ? panes : solutionLength;
                    const solutionPanes = theme.content.width >= 410 ? solutionLength <= 10 ? solutionLength : 10 : theme.content.width < 410 && theme.content.width >= 371 ? solutionLength <= 9 ? solutionLength : 9 : solutionLength <= 8 ? solutionLength : 8;
                    const solutionColumns = (solutionLength < solutionPanes ? 8 : isTablet && solutionLength >= solutionPanes) ? solutionPanes : solutionLength >= 16 ? Math.ceil(solutionLength / 2) : solutionLength;
                    return (
                        <>
                            <T.Functions>
                                <AutoGrid
                                    columns={columns}
                                    gap={gap}
                                >
                                    {_.times(20, x => {
                                        const move = () => {
                                            if (!this.props.timeIsOver)
                                                this.moveToCell(x);
                                        }
                                        return (
                                            <AutoGridColumn key={x}>
                                                {suggestions[x] ?
                                                    <T.FourPicLetters onClick={move} selected={true}>
                                                        {suggestions[x]}
                                                    </T.FourPicLetters>
                                                    :
                                                    <T.FourPicLetters selected={false}>-</T.FourPicLetters>
                                                }
                                            </AutoGridColumn>
                                        );
                                    })}
                                </AutoGrid>
                                <div>
                                    <Divider
                                        icon={correctAnswer ? undefined : "bulb"}
                                        color={"@mainForeground"}
                                        size="large"
                                        content={correctAnswer}
                                        uppercase
                                    />
                                    <div style={{ textAlign: "center", width: "100%" }}>
                                        <AutoGrid
                                            columns={solutionColumns}
                                            gap={1}
                                        >
                                            {_.times(solutionLength, x => {
                                                const remove = () => { if (!this.props.timeIsOver) this.remove(x); }
                                                const correct = solution[x] === (correctAnswer ?? [])[x]?.toUpperCase();
                                                return (
                                                    <div key={x}>
                                                        {solution[x] ?
                                                            <AutoGridColumn>
                                                                <T.FourPicCell onClick={remove} correct={this.props.displayCorrect ? correct : undefined}>
                                                                    {solution[x]}
                                                                </T.FourPicCell>
                                                            </AutoGridColumn>
                                                            :
                                                            <AutoGridColumn>
                                                                <T.FourPicCell empty>?</T.FourPicCell>
                                                            </AutoGridColumn>
                                                        }
                                                    </div>
                                                );
                                            })}
                                        </AutoGrid>
                                    </div>
                                </div>
                            </T.Functions>
                        </>
                    );
                }
                }
            </ImgThemeConsumer>
        );
    }
    private moveToCell = (pos: number) => {
        if (this.props.displayCorrect) {
            return;
        }
        const userAnswer = _.clone(this.state.userAnswer);
        const solution = _.clone(userAnswer.solution);
        const suggestions = _.clone(userAnswer.suggestions);

        const idx = _.findIndex(solution, s => s === undefined);
        if (idx >= 0) {
            solution[idx] = suggestions[pos];
            suggestions[pos] = undefined;
        }

        const text = _.map(solution, v => v).join('');
        userAnswer.answer = { lng: userAnswer?.answer?.lng ?? this.props.contentLanguage ?? "de-DE", text };
        userAnswer.suggestions = suggestions;
        userAnswer.solution = solution;
        if (this.props.answer)
            this.props.answer(userAnswer);
        this.setState({ userAnswer });
    };

    private remove = (pos: number) => {
        if (this.props.displayCorrect) {
            return;
        }
        const userAnswer = _.clone(this.state.userAnswer);
        const solution = _.clone(userAnswer.solution);
        const suggestions = _.clone(userAnswer.suggestions);

        const idx = _.findIndex(suggestions, s => s === undefined);
        if (idx >= 0) {
            suggestions[idx] = solution[pos];
            solution[pos] = undefined;
        }
        const text = _.map(solution, v => v).join('');
        userAnswer.answer = { lng: userAnswer?.answer?.lng ?? this.props.contentLanguage ?? "de-DE", text };
        userAnswer.suggestions = suggestions;
        userAnswer.solution = solution;
        if (this.props.answer)
            this.props.answer(userAnswer);
        this.setState({ userAnswer });
    }

    private reset = () => {
        const { contentLanguage, answers, questionNumber } = this.props;
        const userAnswer = ((answers ?? [])[questionNumber ?? 0] as any)?.userAnswer as ExtendedFourPicturesOneSolutionUserAnswer;

        const data = this.props.question as FourPicturesOneSolutionQuestionDownloadDto;
        const suggestions = getTranslated(data.suggestions, contentLanguage)?.text.split('');
        const lng = getTranslated(data.suggestions, contentLanguage)?.lng;
        const idx = _.findIndex(data.suggestions, s => _.isEqual(s, suggestions));
        const solutionLength = data.solutionLength.length === 0 ? 0 : idx < 0 ? data.solutionLength[0] : data.solutionLength[idx];
        const solution: Array<string | undefined> = [];
        for (let i = 0; i < solutionLength; i++)
            solution.push(undefined);

        this.setState({
            userAnswer: {
                answer: userAnswer?.answer ?? { lng, text: "" } as LanguageStringDownloadDto,
                suggestions: userAnswer?.suggestions ?? suggestions,
                solution: userAnswer?.solution ?? solution,
                solutionLength,
            } as ExtendedFourPicturesOneSolutionUserAnswer,
        });

    };
}

export default connect(mapper)(FourPicturesOneSolutionQuestion);
