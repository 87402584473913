import { ImgIcons, RecursivePartial, ThemeBorderRadius, ThemeColor, ThemeDimension, ThemeMargin } from 'imaginarity-react-ui';


export interface WikiArticleHomePane {
    backgroundColor: string;
    backgroundColorHover: string;
    borderBottomColor: string;
    borderBottomColorHover: string;

    homePaneDot1Color: string;
    homePaneDot2Color: string;
    homePaneDot3Color: string;
    homePaneDot4Color: string;

    homePaneDot1Position: WikiHomePaneDotPosition;
    homePaneDot2Position: WikiHomePaneDotPosition;
    homePaneDot3Position: WikiHomePaneDotPosition;
    homePaneDot4Position: WikiHomePaneDotPosition;
    homePaneDotBorderRadius: ThemeBorderRadius;
    homePaneDotWidth: ThemeDimension;
    homePaneDotHeight: ThemeDimension;
    homePaneDotShadowed: boolean;
    homePaneDotBackgroundColor: string;
    homePaneDotBorderWidth: ThemeDimension;
}

export interface WikiCategoryTheme {
    bannerOverlayBackgroundColor: string;
    bannerOverlayTitleColor: string;
    bannerOverlayVersionFontColor: string;
    bannerOverlayVersionBackgroundColor: string;

    categoryBackgroundColorActive: string;
    categoryBackgroundColor: string;
    categoryBackgroundHoverColor: string;
    categoryFontColor: string;
    categoryFontColorActive: string;
    categoryFontHoverColor: string;
    categoryBorderLeftColor: string;

    articleBackgroundColor: string;
    articleBackgroundHoverColor: string;
    articleFontColor: string;
    articleFontHoverColor: string;
    articleBorderBottomColor: string;
    articleBorderRightColor: string;
}


export interface WikiArticleTheme {
    minimizeIcon: ImgIcons;
    maximizeIcon: ImgIcons;
}

export interface WikiHomePaneDotPosition {
    left?: ThemeMargin;
    right?: ThemeMargin;
    top?: ThemeMargin;
    bottom?: ThemeMargin;
}

export interface SelectVideoFromTubeSettings {
    showFilter: boolean;
}
export interface WikiSettings {
    selectVideoFromTube: SelectVideoFromTubeSettings
}

export interface WikiTheme extends RecursivePartial<ThemeColor> {
    searchBackgroundUrl: string;
    searchContainerHeight: ThemeDimension;
    homePaneTheme: WikiArticleHomePane;
    categoryTheme: WikiCategoryTheme;
    articleTheme: WikiArticleTheme;
    showNewQuizBanner: boolean;
    selectVideo: WikiSettings;
}



export const wikiTheme: WikiTheme = {
    backgroundColor: "@mainBackground",
    searchBackgroundUrl: "https://cdn.imaginarity.com/public/images/ppe/wikiSearchBG.jpg",
    searchContainerHeight: 127,
    showNewQuizBanner: false,
    selectVideo: {
        selectVideoFromTube: {
            showFilter: true
        }
    },
    homePaneTheme: {
        backgroundColor: "fade('@veryLightGrey', 40%)",
        backgroundColorHover: "@veryLightGrey",
        borderBottomColor: "lighten('@veryLightGrey', 90%)",
        borderBottomColorHover: "@darkGrey",

        homePaneDot1Color: "@accentBlue",
        homePaneDot2Color: "@accentGreen",
        homePaneDot3Color: "@accentYellow",
        homePaneDot4Color: "@accentRed",

        homePaneDotBorderRadius: 8,
        homePaneDotHeight: 16,
        homePaneDotWidth: 16,
        homePaneDotShadowed: true,
        homePaneDotBackgroundColor: "#FCFBFB",
        homePaneDotBorderWidth: 3,

        homePaneDot1Position: {
            right: 5,
            bottom: -4,
        },
        homePaneDot2Position: {
            right: 25,
            bottom: -4,
        },
        homePaneDot3Position: {
            right: 45,
            bottom: -4,
        },
        homePaneDot4Position: {
            right: 65,
            bottom: -4,
        },
    },

    articleTheme: {
        minimizeIcon: "chevron up",
        maximizeIcon: "chevron down",
    },

    categoryTheme: {
        bannerOverlayBackgroundColor: "fade('@darkGrey', 40%)",
        bannerOverlayTitleColor: "@mainBackground",
        bannerOverlayVersionFontColor: "fade('@mainBackground', 80%)",
        bannerOverlayVersionBackgroundColor: "fade('@darkGrey', 20%)",

        categoryBackgroundColorActive: "@veryLightGrey",
        categoryBackgroundColor: "@mainBackground",
        categoryBackgroundHoverColor: "@mainBackground",
        categoryFontColorActive: "@darkGrey",
        categoryFontColor: "@darkGrey",
        categoryFontHoverColor: "@accent",
        categoryBorderLeftColor: "@accent",

        articleBackgroundColor: "@mainBackground",
        articleBackgroundHoverColor: "@mainBackground",
        articleFontColor: "@darkGrey",
        articleFontHoverColor: "@accent",
        articleBorderBottomColor: "@veryLightGrey",
        articleBorderRightColor: "@accent",
    },
}
