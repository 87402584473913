import { WikiArticleDownloadDto } from "collaboration-service";
import SafeHTML from "components/SafeHTML/SafeHTML";
import { Icon, ImgIcons, useThemePart } from "imaginarity-react-ui";
import * as React from "react";
import { ApplicationState, useAppSelector } from "services/ApplicationState/ApplicationState";
import { getLink, shallowCompare } from "services/Helpers";
import { createNamedRoute } from "services/Helpers/RoutingHelper";
import { sanitizedNothingAllowedAttributes } from "services/Helpers/SanitizedHelper";
import { getTranslated } from "services/Helpers/TranslationHelpers";
import { useImgI18N } from "services/ImgI18N";
import { PlaylistDetailNodeItemProps, useHidePlaylistNodeItem } from "../PlaylistDetailNodeItem";
import { PlaylistDetailSC as T } from "../PlaylistDetailSC";
import PlaylistWikiDimmer from "./Dimmer/PlaylistWikiDimmer";

const mapper = (s: ApplicationState) => ({
    currentGroup: s.currentGroup,
    contentLanguage: s.contentLanguage,
    filter: s.playlistsState.filter,
});

const WikiArticlePlaylistNodeItem = (p: PlaylistDetailNodeItemProps<WikiArticleDownloadDto>) => {
    const { item, state, playlistId, playlistContent, isOptional, nodeReference, transition, warning, isMobile, nodeIsDone, loadPlaylistStates, playlistStateId } = p;
    const { contentLanguage, } = useAppSelector(mapper, shallowCompare);
    const { t } = useImgI18N("wiki");
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const link = createNamedRoute("wiki_article", { id: item.id }, true);
    const emptyImageUrl = useThemePart(t => t.emptyImageUrl);

    const image = getLink(item.media, "self")?.uri ?? emptyImageUrl;

    const elementIsReference = item.id === nodeReference;

    const togglePreview = React.useMemo(() => () => {
        setShowModal(!showModal);
    }, [showModal]);

    const result = useHidePlaylistNodeItem(p);
    if (result.hide)
        return null;

    return (
        <T.PLItemContainer isMobile={isMobile}>
            <T.PLItemImage imageUrl={image} isMobile={isMobile} highlighted={elementIsReference} color={warning ? "@warning" : "@accentRed"} onClick={togglePreview} />
            <T.PLItemContainerContent isMobile={isMobile} onClick={togglePreview}>
                <T.PLItemContainerTitle isMobile={isMobile}>
                    {elementIsReference &&
                        <T.PLItemContainerTitleDot color={warning ? "@warning" : "@accentRed"} />
                    }
                    <SafeHTML html={getTranslated(item.headlines, contentLanguage)?.text ?? ""} allowedTags={[]} allowedAttributes={{}} />
                </T.PLItemContainerTitle>
                {!isMobile &&
                    <T.PLItemContainerDescription>
                        {item.descriptions &&
                            <SafeHTML
                                html={getTranslated(item.descriptions, contentLanguage).text ?? ""}
                                allowedTags={["strong", "span", "b", "i", "u"]}
                                allowedAttributes={sanitizedNothingAllowedAttributes}
                            />
                        }
                    </T.PLItemContainerDescription>
                }
                <T.PLItemContainerBottomCat isMobile={isMobile}>
                    <Icon name="wiki" marginTop={3} style={{ float: "left", marginLeft: -2, marginRight: 10 }} size="16px" />
                    {item.type}
                    {playlistContent?.duration &&
                        <>
                            <span style={{ padding: "0 10px" }}>&bull;</span>
                            {`${Math.floor(playlistContent.duration * 60 * 100) / 100} Min`}
                        </>
                    }
                </T.PLItemContainerBottomCat>
            </T.PLItemContainerContent>
            <T.PLItemContainerRight isMobile={isMobile} onClick={togglePreview}>
                {isOptional ?
                    <T.ColoredDiv color={state.state === "Finished" ? "@accentGreen" : "inherit"}>
                        <div style={{ float: "right" }}>{state.state === "Finished" ? t("100%") : ""}</div>
                        <Icon name={state.state === "Finished" ? "check circle" : "empty"} style={{ float: "right", marginRight: 5 }} marginTop={2} color={state.state === "Finished" ? "@accentGreen" : "inherit"} />
                    </T.ColoredDiv>
                    :
                    (transition === "ReferenceDone" || transition === "ReferenceDoneWithValue") ?
                        elementIsReference ?
                            <T.ColoredDiv color={(result.text === "100%" || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color}>
                                <div style={{ float: "right" }}>
                                    {(result.text === "100%" || nodeIsDone) ? "100%" : warning ? "warning" : result.text}
                                </div>
                                <Icon name={(result.text === "100%" || nodeIsDone) ? "check circle" : warning ? "exclamation triangle" : result.icon as ImgIcons} style={{ float: "right", marginRight: 5 }} marginTop={4} color={(result.text === "100%" || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color} />
                            </T.ColoredDiv>
                            :
                            <T.ColoredDiv color={result.text === "100%" ? "@accentGreen" : "@middleLightGrey"}>
                                <div style={{ float: "right" }}>{result.text === "100%" ? "100%" : t("optional")}</div>
                                <Icon name="empty" style={{ float: "right", marginRight: 5 }} marginTop={4} />
                            </T.ColoredDiv>
                        :
                        <T.ColoredDiv color={result.text === "100%" ? "@accentGreen" : warning ? "@warning" : result.color}>
                            <div style={{ float: "right" }}>{result.text}</div>
                            <Icon
                                name={result.icon as ImgIcons}
                                style={{ float: "right", marginRight: 5 }}
                                marginTop={4}
                                color={result.text === "100%" ? "@accentGreen" : warning ? "@warning" : result.color}
                            />
                        </T.ColoredDiv>
                }
            </T.PLItemContainerRight>
            {showModal && <PlaylistWikiDimmer
                id={p.state.referenceId}
                item={item}
                link={link ?? ""}
                result={result}
                playlistId={playlistId ?? ""}
                isOptional={isOptional ?? false}
                transition={transition}
                elementIsReference={elementIsReference}
                nodeIsDone={nodeIsDone ?? false}
                warning={warning ?? false}
                state={state}
                closeDimmer={togglePreview}
                loadPlaylistStates={loadPlaylistStates}
                playlistStateId={playlistStateId}
            />
            }
        </T.PLItemContainer>
    );
}
export default WikiArticlePlaylistNodeItem;