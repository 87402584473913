import * as DateFnsLocal from 'date-fns/locale';
import * as _ from "lodash";
import * as React from "react";
import { Subtract } from "utility-types";

type OneLanguage = { [namespace: string]: { [key: string]: string } };
type LanguageMap = { [lng: string]: OneLanguage };


export interface ImgI18NConfig {
    baseLanguage: string;
    standardNamespace: string;
    fallbackPrefix?: string;
    getTranslations: (lng: string) => Promise<OneLanguage | undefined>;
    addTranslations?: (lng: string, namespace: string, key: string, translation: string) => Promise<void>;
    visibleLanguages?: string[];
    getCountryFlag: (lng: string) => string | undefined;
    dontUseBaseLanguage?: boolean;
}

export class ImgI18N {


    private static instance: ImgI18N | undefined;
    public static getInstance() {
        if (!this.instance)
            throw new Error("You need to call init before getting instance!");
        return this.instance;
    }

    private init: boolean = false;
    private lngMap: LanguageMap = {};
    private regex: RegExp;
    private lng: string;
    private visibleLanguages: string[] = ["de-DE", "en-GB", "en-US", "es-ES", "fr-FR", "it-IT", "ja-JP", "ko-KR", "nl-NL", "pt-BR", "zh-CN", "zh-TW", "ru-RU"];
    public get currentLanguage() {
        return this.lng;
    }
    private lngLocals: { [key: string]: { locale: Locale, flag: string, visible: boolean } } = {};
    public get languageLocals() {
        return this.lngLocals;
    }

    private initLanguageLocals = () => {
        const dateFnsLanguages = [
            DateFnsLocal.af,
            //DateFnsLocal.ar,
            DateFnsLocal.arDZ,
            DateFnsLocal.arMA,
            DateFnsLocal.arSA,
            DateFnsLocal.az,
            DateFnsLocal.be,
            DateFnsLocal.bg,
            DateFnsLocal.bn,
            DateFnsLocal.bs,
            DateFnsLocal.ca,
            DateFnsLocal.cs,
            DateFnsLocal.cy,
            DateFnsLocal.da,
            DateFnsLocal.de,
            DateFnsLocal.deAT,
            DateFnsLocal.el,
            DateFnsLocal.enAU,
            DateFnsLocal.enCA,
            DateFnsLocal.enGB,
            DateFnsLocal.enIN,
            DateFnsLocal.enNZ,
            DateFnsLocal.enUS,
            DateFnsLocal.enZA,
            DateFnsLocal.eo,
            DateFnsLocal.es,
            DateFnsLocal.et,
            DateFnsLocal.eu,
            DateFnsLocal.faIR,
            DateFnsLocal.fi,
            //DateFnsLocal.fil,
            DateFnsLocal.fr,
            DateFnsLocal.frCA,
            DateFnsLocal.frCH,
            DateFnsLocal.gd,
            DateFnsLocal.gl,
            DateFnsLocal.gu,
            DateFnsLocal.he,
            DateFnsLocal.hi,
            DateFnsLocal.hr,
            DateFnsLocal.ht,
            DateFnsLocal.hu,
            DateFnsLocal.hy,
            DateFnsLocal.id,
            DateFnsLocal.is,
            DateFnsLocal.it,
            DateFnsLocal.ja,
            DateFnsLocal.ka,
            DateFnsLocal.kk,
            DateFnsLocal.kn,
            DateFnsLocal.ko,
            DateFnsLocal.lb,
            DateFnsLocal.lt,
            DateFnsLocal.lv,
            DateFnsLocal.mk,
            DateFnsLocal.mn,
            DateFnsLocal.ms,
            DateFnsLocal.mt,
            DateFnsLocal.nb,
            DateFnsLocal.nl,
            DateFnsLocal.nlBE,
            DateFnsLocal.nn,
            DateFnsLocal.pl,
            DateFnsLocal.pt,
            DateFnsLocal.ptBR,
            DateFnsLocal.ro,
            DateFnsLocal.ru,
            DateFnsLocal.sk,
            DateFnsLocal.sl,
            DateFnsLocal.sq,
            DateFnsLocal.sr,
            DateFnsLocal.srLatn,
            DateFnsLocal.sv,
            DateFnsLocal.ta,
            DateFnsLocal.te,
            DateFnsLocal.th,
            DateFnsLocal.tr,
            DateFnsLocal.ug,
            DateFnsLocal.uk,
            DateFnsLocal.uz,
            DateFnsLocal.vi,
            DateFnsLocal.zhCN,
            DateFnsLocal.zhTW,
        ];
        _.forEach(dateFnsLanguages, i => {
            const key = i.code;
            if (key)
                this.lngLocals[key] = { locale: i, flag: "", visible: false };
        });
        this.lngLocals["en"] = { locale: DateFnsLocal.enGB, flag: "GB", visible: false };
        this.lngLocals["zh"] = { locale: DateFnsLocal.zhCN, flag: "CN", visible: false };
        _.forEach(this.visibleLanguages, l => {
            if (this.languageLocals[l])
                this.languageLocals[l].visible = true;
            else {
                const s = l.split("-");
                if (this.languageLocals[s[0]]) {
                    this.languageLocals[l] = { ...this.languageLocals[s[0]] };
                    this.languageLocals[l].visible = true;
                }
            }
        });
        this.visibleLanguages = _.filter(this.visibleLanguages, l => this.languageLocals[l].visible);
        _.forEach(this.languageLocals, (l, k) => {
            const f = this.config.getCountryFlag(k);
            if (f)
                l.flag = f;
        });
    }

    public languageLocal = (lng?: string) => {
        if (!lng)
            lng = this.lng;
        let locale = this.languageLocals[lng];
        if (!locale) {
            const baseLng = lng.includes("-") ? lng.split("-")[0] : lng;
            if (baseLng !== lng)
                locale = this.languageLocals[baseLng];
        }
        if (!locale)
            locale = this.languageLocals[this.config.baseLanguage];

        return locale.locale;
    }

    public get languages() {
        return this.visibleLanguages;
    }

    public set currentLanguage(lng: string) {
        if (this.lng === lng)
            return;
        const msg = `language change ${this.lng} => ${lng}`
        this.lng = lng;
        if (!this.lngMap[lng])
            this.loadLanguage(lng);
        else
            this.update(msg);
    }

    private constructor(private config: ImgI18NConfig) {
        if (config.visibleLanguages)
            this.visibleLanguages = config.visibleLanguages;
        this.initialize();
        this.regex = RegExp(/\{\{([a-zA-Z0-9_]+)\}\}/gi);
        this.lng = this.config.baseLanguage;
    }

    public static init(config: ImgI18NConfig) {
        this.instance = new ImgI18N(config);
    }

    public getConfig() {
        return _.clone(this.config);
    }
    public initialize = async () => {
        if (this.init)
            return;
        this.initLanguageLocals();
        this.loadLanguage(this.config.baseLanguage);
        this.init = true;
    }

    private notsaving_t = (namespace: string, key: string, data?: { [key: string]: any }): { ok: boolean, result: string } => {
        const idx = key.indexOf("::");
        if (idx >= 0) {
            const [ns, k] = key.split("::");
            return this.notsaving_t(ns, k, data);
        }
        const language = this.lngMap[this.lng];
        if (!language) {
            this.loadLanguage(this.lng);
            return { ok: true, result: this.replacePlaceHolders(key, data) };
        }
        // try to get correct translation
        const ns = language ? language[namespace] : undefined;
        const transNs = ns ? ns[key] : undefined;
        // console.log("ns => ", _.clone(ns));
        // console.log(`ns => ${ns}, transNs = ${transNs}`);
        if (transNs)
            return { ok: true, result: this.replacePlaceHolders(transNs, data) };
        // try to get it using fallback prefix
        else {
            const fallback = this.config.fallbackPrefix && language ? language[`${this.config.fallbackPrefix}${namespace}`] : undefined;
            const transFb = fallback ? fallback[key] : undefined;
            // console.log("fallback => ", _.clone(fallback));
            // console.log(`fallback => ${fallback}, transNs = ${transFb}`);
            if (transFb) {
                return { ok: true, result: this.replacePlaceHolders(transFb, data) };
            }
        }
        // try to get it from base language
        if (this.lng !== this.config.baseLanguage) {
            const bl = this.lngMap[this.config.baseLanguage];
            const blNs = bl ? bl[namespace] : undefined;
            const blTransNs = blNs ? blNs[key] : undefined;

            if (blTransNs) {
                return { ok: true, result: this.replacePlaceHolders(blTransNs, data) };
            }
            // try to get it from base language with fallback prefix
            else {
                const blFallback = this.config.fallbackPrefix && bl ? bl[`${this.config.fallbackPrefix}${namespace}`] : undefined;
                const blTransFb = blFallback ? blFallback[key] : undefined;
                if (blTransFb) {
                    return { ok: true, result: this.replacePlaceHolders(blTransFb, data) };
                }
            }
        }
        // use key as result 
        return { ok: false, result: this.replacePlaceHolders(key, data) };
    }

    private suffixFindings = (suffixes: string[], namespace: string, key: string, data?: { [key: string]: any }) => {
        for (let i = 0; i < suffixes.length; i++) {
            const res = this.notsaving_t(namespace, `${key}_${suffixes[i]}`, data);
            if (res.ok)
                return res.result;
        }
        return undefined;
    }

    private suffixArray = [
        ["0", "plural"],
        ["1", "singular"],
        ["2", "plural"],
        ["3", "plural"],
        ["4", "plural"],
        ["5", "plural"],
        ["6", "plural"],
        ["7", "plural"],
        ["8", "plural"],
    ]

    public t = (namespace: string, key: string, data?: { [key: string]: any }): string => {
        var list: { [key: string]: number } = (window as any)["usedNamespaces"] ?? {};
        if (list[namespace])
            list[namespace]++;
        else
            list[namespace] = 1;
        (window as any)["usedNamespaces"] = list;
        const idx = key.indexOf("::");
        if (idx >= 0) {
            const [ns, k] = key.split("::");
            const x = this.t(ns, k, data);
            return x;
        }
        if (data && Object.keys(data).indexOf("count") >= 0 && data["count"] && data["count"] >= 0) {
            const res = this.suffixFindings(this.suffixArray[Math.min(data["count"], this.suffixArray.length - 1)], namespace, key, data)
            if (res)
                return res;
        }
        const language = this.lngMap[this.lng];
        if (!language) {
            this.loadLanguage(this.lng);
            return this.replacePlaceHolders(key, data);
        }
        // try to get correct translation
        const ns = language ? language[namespace] : undefined;
        const transNs = ns ? ns[key] : undefined;
        // console.log("ns => ", _.clone(ns));
        // console.log(`ns => ${ns}, transNs = ${transNs}`);
        if (transNs)
            return this.replacePlaceHolders(transNs, data);
        // try to get it using fallback prefix
        else {
            const fallback = this.config.fallbackPrefix && language ? language[`${this.config.fallbackPrefix}${namespace}`] : undefined;
            const transFb = fallback ? fallback[key] : undefined;
            // console.log("fallback => ", _.clone(fallback));
            // console.log(`fallback => ${fallback}, transNs = ${transFb}`);
            if (transFb) {
                this.saveMissing(this.lng, namespace, key, transFb);
                return this.replacePlaceHolders(transFb, data);
            }
        }
        if (this.config.dontUseBaseLanguage)
            return key ? `${namespace}::${key}` : "";

        // try to get it from base language
        if (this.lng !== this.config.baseLanguage) {
            const bl = this.lngMap[this.config.baseLanguage];
            const blNs = bl ? bl[namespace] : undefined;
            const blTransNs = blNs ? blNs[key] : undefined;

            if (blTransNs) {
                return this.replacePlaceHolders(blTransNs, data);
            }
            // try to get it from base language with fallback prefix
            else {
                const blFallback = this.config.fallbackPrefix && bl ? bl[`${this.config.fallbackPrefix}${namespace}`] : undefined;
                const blTransFb = blFallback ? blFallback[key] : undefined;
                if (blTransFb) {
                    this.saveMissing(this.config.baseLanguage, namespace, key, blTransFb);
                    return this.replacePlaceHolders(blTransFb, data);
                }
            }
        }
        // use key as result 
        this.saveMissing(this.config.baseLanguage, namespace, key, key);
        return this.replacePlaceHolders(key, data);
    }

    public subscribe = (callback: () => void) => {
        this.subscriptions.push(callback);
    }

    public unsubscribe = (callback: () => void) => {
        const i = _.findIndex(this.subscriptions, s => s === callback);
        if (i >= 0)
            this.subscriptions.splice(i, 1);
    }



    /* private stuff*/

    private replacePlaceHolders = (str: string, data?: { [key: string]: any }) => {
        if (data) {
            let result = str;
            let res = this.regex.exec(result);
            while (res) {
                result = result.replace(res[0], data[res[1].trim()]?.toString() ?? "undefined");
                res = this.regex.exec(str);
            }
            return result;
        }
        return str;
    }
    private loadingLanguages: string[] = [];

    private loadLanguage = async (lng: string) => {
        if (_.find(this.loadingLanguages, l => l === lng))
            return;
        //console.log("loading lngs for: ", lng);
        this.loadingLanguages.push(lng);
        const lngs = await this.config.getTranslations(lng);
        if (lngs) {
            this.lngMap[lng] = lngs;
            const i = _.findIndex(this.loadingLanguages, l => l === lng);
            this.loadingLanguages.splice(i, 1);
            this.update(`${lng} loaded`);
        }
        else {
            if (!this.lngMap[this.config.baseLanguage]) {
                if (_.find(this.loadingLanguages, l => l === this.config.baseLanguage))
                    return;
                const bl = await this.config.getTranslations(this.config.baseLanguage);
                if (bl) {
                    this.lngMap[this.config.baseLanguage] = bl;
                    this.update("base lng loaded");
                }
            }

        }
    }

    private currentSavings: Array<{
        lng: string;
        namespace: string;
        key: string;
        translation: string;
    }> = [];

    private addTranslation = (lng: string, namespace: string, key: string, translation: string) => {
        if (!this.lngMap[lng])
            this.lngMap[lng] = {};
        if (!this.lngMap[lng][namespace])
            this.lngMap[lng][namespace] = {};
        this.lngMap[lng][namespace][key] = translation;
    }

    private saveMissing = (lng: string, namespace: string, key: string, translation: string) => {
        if (key === "")
            return;
        //console.log(`would save ${lng}--${namespace}--${key}--${translation}`);
        const find = _.find(this.currentSavings, c => c.lng === lng && c.namespace === namespace && c.key === key && c.translation === translation);
        if (this.config.addTranslations && !find) {
            //console.log(`trying to save missing: ${lng} => ${namespace} => ${key} => ${translation}`);
            if (lng !== this.config.baseLanguage) {
                const bl = this.lngMap[this.config.baseLanguage];
                const blNs = bl ? bl[namespace] : undefined;
                const blK = blNs ? blNs[key] : undefined;
                if (!bl || !blNs || !blK) {
                    let toSet = key;
                    const fallback = this.config.fallbackPrefix && bl ? bl[`${this.config.fallbackPrefix}${namespace}`] : undefined;
                    const transFb = fallback ? fallback[key] : undefined;
                    // console.log("fallback => ", _.clone(fallback));
                    // console.log(`fallback => ${fallback}, transNs = ${transFb}`);
                    if (transFb)
                        toSet = transFb;
                    this.saveMissing(this.config.baseLanguage, namespace, key, toSet);
                }

            }
            this.currentSavings.push({ lng, namespace, key, translation });
            this.config.addTranslations(lng, namespace, key, translation).then(() => {
                const i = _.findIndex(this.currentSavings, c => c.lng === lng && c.namespace === namespace && c.key === key && c.translation === translation);
                this.addTranslation(lng, namespace, key, translation);
                if (i >= 0)
                    this.currentSavings.splice(i, 1);
            });
        }
    }

    private subscriptions: Array<() => void> = [];
    private update = (reason: string) => {
        //console.log("update for: " + reason);
        //        console.log(this.subscriptions);
        _.forEach(this.subscriptions, s => s());
    }


}

export type ImgI18NTranslateFunction = (text: string, data?: { [key: string]: string | number | undefined }) => string;
export type ImgI18NTranslateFunctionJSX = (text: string, data?: { [key: string]: string | number | JSX.Element | undefined }) => string | JSX.Element[];


export interface ImgI18NTranslatedComponentProps {
    currentLanguage: string;
    currentNamespace: string;
    changeLanguage: (lng: string) => void;
    t: ImgI18NTranslateFunction;
    imgI18NUpdateCounter: number;
    //t_jsx: ImgI18NTranslateFunctionJSX;
}

interface ImgI18NTranslatedComponentState {
    count: number;
}

export const translate = (namespace: string) => {
    return <P extends ImgI18NTranslatedComponentProps>(Component: React.ComponentType<P>) =>
        class Translate extends React.PureComponent<Subtract<P, ImgI18NTranslatedComponentProps>, ImgI18NTranslatedComponentState>{
            private mounted: boolean = false;
            public state: ImgI18NTranslatedComponentState = {
                count: 0
            };

            public async componentDidMount() {
                this.mounted = true;
                await ImgI18N.getInstance().initialize();
                ImgI18N.getInstance().subscribe(this.updated);
            }
            public componentWillUnmount() {
                this.mounted = false;
                ImgI18N.getInstance().unsubscribe(this.updated);
            }

            private updated = _.debounce(() => {
                //                console.log("updated!");
                if (this.mounted)
                    this.setState({ count: this.state.count + 1 });
            }, 500);

            public t: ImgI18NTranslateFunction = (text, data) => {
                if (text.startsWith("@")) {
                    const ci = text.indexOf(":");
                    const ns = text.substr(1, ci - 1);
                    const t = text.substr(ci + 1);
                    // console.log(`special NS : ${ImgI18N.getInstance().currentLanguage} => "${ns}" => "${t}"`);
                    return ImgI18N.getInstance().t(ns, t.toLowerCase(), data);
                }
                // console.log(`${ImgI18N.getInstance().currentLanguage} => "${namespace}" => "${text}"`);
                return ImgI18N.getInstance().t(namespace, text.toLowerCase(), data);
            }

            public changeLanguage = (lng: string) => {
                const i = ImgI18N.getInstance();
                i.currentLanguage = lng;
            }

            render() {
                const i = ImgI18N.getInstance();
                return <Component
                    {...this.props as P}
                    currentLanguage={i.currentLanguage}
                    changeLanguage={this.changeLanguage}
                    currentNamespace={namespace}
                    t={this.t}
                    imgI18NUpdateCounter={this.state.count}
                />
            }
        }

}

export const useImgI18N = (namespace?: string) => {
    const [updateCount, setUpdateCount] = React.useState(0);
    const update = React.useMemo(() => () => {
        setUpdateCount(updateCount + 1);
    }, [updateCount]);

    React.useEffect(() => {
        ImgI18N.getInstance().subscribe(update);
        return () => {
            ImgI18N.getInstance().unsubscribe(update);
        }
    }, [update]);

    const t = React.useMemo(() =>
        (val: string, data?: { [key: string]: any; }) => ImgI18N.getInstance().t(namespace ?? ImgI18N.getInstance().getConfig().standardNamespace, val.toLowerCase(), data),
        // eslint-disable-next-line
        [namespace, updateCount]);

    const changeLanguage = React.useMemo(() => (lng: string) => ImgI18N.getInstance().currentLanguage = lng, []);
    const currentLanguage = React.useMemo(() => ImgI18N.getInstance().currentLanguage,
        // eslint-disable-next-line
        [updateCount]);
    const currentNamespace = React.useMemo(() => namespace ?? ImgI18N.getInstance().getConfig().standardNamespace, [namespace]);
    const currentLocale = React.useMemo(() => ImgI18N.getInstance().languageLocals, []);
    const createT = React.useMemo(() => (ns: string) => (val: string, data?: { [key: string]: any; }) => ImgI18N.getInstance().t(ns, val.toLowerCase(), data), []);

    const toReturn = React.useMemo(() => {
        //console.log('newVal in I18N => ');
        return {
            currentLanguage,
            currentNamespace,
            changeLanguage,
            t,
            imgI18NUpdateCounter: updateCount,
            createT,
            currentLocale,
        }

    }, [currentLanguage, currentNamespace, changeLanguage, t, updateCount, createT, currentLocale]);

    return toReturn;
};