import { BookmarkController, ControllerHelper, PostInteraction, QuizDownloadDto } from 'collaboration-service';
import CommentsRatingsView from 'components/General/CommentsRatingsView';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { formatDistanceToNow } from 'date-fns';
import { Button, ButtonGroup, Icon, Image, RatingView } from 'imaginarity-react-ui';
import { FloatedLeft } from 'layout/masterLayouts/MasterLayoutsSC';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { PostProps } from 'services/ApplicationState/ApplicationComponentsFactory';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { getLink } from 'services/Helpers';
import { gotoNamedRoute } from 'services/Helpers/RoutingHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { ImgI18N, ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import QuizButton from 'views/Quiz/QuizButton';
import { ButtonsContainer, Card, Comments, CommentsRatingsContent, CountText, Created, Desc, GridContainer, Indicator, LikeButton, PostRibbon, SingleOrMulti, SPContent, SPHeader, StartButtonPost, Title } from '../PostClasses';
import QuizIncomplete from './QuizIncomplete';
import QuizStats from './QuizStats';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage, user: state.user });
export interface QuizPostProps extends PostProps, ImgI18NTranslatedComponentProps, ComponentWithMappedApplicationState<typeof mapper> {
}
export interface QuizPostState {
    likeCount: number;
    isBookmarked: boolean;
    commentCount: number;
    ratingCount: number;
    rating?: number;
    showComments: boolean;
    show: string;
}
class QuizPost extends React.Component<QuizPostProps, QuizPostState> {
    constructor(props: QuizPostProps) {
        super(props);

        this.state = {
            likeCount: this.props.post.likeCount,
            isBookmarked: this.props.post.isBookmarked,
            commentCount: this.props.post.commentCount,
            ratingCount: this.props.post.ratingCount,
            rating: this.props.post.rating ?? 0,
            showComments: false,
            show: "statistics",
        }
    }

    public render() {
        const post = this.props.post as QuizDownloadDto;
        const { t, contentLanguage } = this.props;
        const uri = getLink(post.media?.links, "self")?.uri;
        const headline = getTranslated(post.headlines, contentLanguage).text ?? "";
        const desc = getTranslated(post.descriptions, this.props.contentLanguage).text ?? "";
        const created = formatDistanceToNow(
            new Date(post.created),
            { addSuffix: true, locale: ImgI18N.getInstance().languageLocal(contentLanguage) }
        )

        return (
            <Card>
                <PostRibbon gradientColor1="#2A4B6D" gradientColor2="#3A6B9B"><span>{t("Quiz")}</span> </PostRibbon>
                <GridContainer>
                    {uri ?
                        <div style={{ margin: "10px" }}>
                            <Image src={uri} mediaType={post.media.mediaType} />
                        </div>
                        : <div />
                    }
                    <SPContent>
                        <Title>
                            <SPHeader><SafeHTML html={headline} /></SPHeader>
                        </Title>
                        <Created>
                            {created}
                        </Created>
                        <SingleOrMulti>
                            {post.singlePlayerAllowed && post.multiPlayerAllowed && t("single- and multiplayer game")}
                            {post.singlePlayerAllowed && !post.multiPlayerAllowed && t("singleplayer game")}
                            {!post.singlePlayerAllowed && post.multiPlayerAllowed && t("multiplayer game")}
                        </SingleOrMulti>
                        <Desc>
                            <SafeHTML html={desc ?? ""} />
                        </Desc>
                        {post.maxRepeats <= 15 &&
                            <CountText attention={post.maxRepeats <= 1}>
                                <Icon
                                    name="exclamation circle"
                                    color={post.maxRepeats <= 1 ? "@accentRed" : "@lightGrey"}
                                    marginTop={0}
                                    size={20}
                                    style={{ float: "left", marginRight: 3 }}
                                />
                                {post.maxRepeats === 0 ?
                                    t("{{user}}, you have no tries left", { user: this.props.user?.firstName })
                                    :
                                    t("{{user}}, you have {{count}} {{tries}} left", { user: this.props.user?.firstName, count: post.maxRepeats, tries: post.maxRepeats <= 1 ? "try" : "tries" })
                                }
                            </CountText>
                        }
                        <StartButtonPost>
                            <QuizButton
                                text1={this.props.t("play")}
                                diameter={70}
                                onClick={this.openModal}
                            />
                        </StartButtonPost>
                    </SPContent>
                </GridContainer>
                <Comments>
                    <Button
                        kind="transparentButton"
                        icon="comment"
                        floated="left"
                        active={this.state.showComments}
                        onClick={this.showComments(!this.state.showComments)}
                        index={this.state.commentCount > 0 ? this.state.commentCount : undefined}
                    />
                    <FloatedLeft>
                        <Button
                            icon="questionark circle"
                            kind="transparentButton"
                            onClick={this.showComments(!this.state.showComments, "statistics")}
                            active={this.state.showComments}
                            tooltip={{ tooltipText: t("quiz info") }}
                            index={post.questionBuckets !== undefined ? post.questionBuckets[0]?.questions.length : undefined}
                        />
                    </FloatedLeft>
                    {post.hasUnfinishedGames &&
                        <FloatedLeft>
                            <Button
                                icon="redo"
                                kind="transparentButton"
                                onClick={this.showComments(!this.state.showComments, "incomplete")}
                                tooltip={{ tooltipText: t("you have unfinished games") }}
                            />
                        </FloatedLeft>

                    }
                    <LikeButton active={post.isBookmarked} float="right">
                        <Button
                            kind="transparentButton"
                            icon={post.isBookmarked ? "bookmark" : "bookmark outline"}
                            onClick={this.onBookmark(!post.isBookmarked)}
                        />
                    </LikeButton>
                    <div
                        onClick={this.showComments(!this.state.showComments, "ratings")}
                        style={{ float: "right", marginTop: 10, cursor: "pointer" }}
                    >
                        {this.state.ratingCount > 0 &&
                            <Indicator float="right" isType="rating">
                                {this.state.ratingCount}
                            </Indicator>
                        }
                        <RatingView
                            rating={this.state.rating ?? this.props.post.rating}
                            maxStars={5}
                            size="18px"
                        />
                    </div>
                </Comments>
                {
                    this.state.showComments &&
                    <>
                        <ButtonsContainer>
                            <ButtonGroup floated="left" >
                                <Button
                                    icon="comment"
                                    //size={this.getButtonSize(theme)}
                                    content={this.state.commentCount + " " + (post.commentCount === 1 ? t("comment") : t("comments"))}
                                    kind={this.state.show === "comments" ? "secondary" : "transparentButton"}
                                    onClick={this.onShow("comments")} />

                                <Button
                                    icon="star half"
                                    //size={this.getButtonSize(theme)}
                                    content={this.state.ratingCount + " " + (post.ratingCount === 1 ? t("rating") : t("ratings"))}
                                    kind={this.state.show === "ratings" ? "secondary" : "transparentButton"}
                                    onClick={this.onShow("ratings")} />
                                <Button
                                    icon="stats"
                                    //size={this.getButtonSize(theme)}
                                    content={this.state.ratingCount + " " + (post.ratingCount === 1 ? t("statistics") : t("statistics"))}
                                    kind={this.state.show === "statistics" ? "secondary" : "transparentButton"}
                                    onClick={this.onShow("statistics")} />
                                {post.hasUnfinishedGames &&
                                    <Button
                                        icon="redo"
                                        //size={this.getButtonSize(theme)}
                                        content={t("incomplete")}
                                        kind={this.state.show === "incomplete" ? "secondary" : "transparentButton"}
                                        onClick={this.onShow("incomplete")} />
                                }
                            </ButtonGroup>
                        </ButtonsContainer>
                        <CommentsRatingsContent>
                            {this.state.show === "comments" &&
                                <CommentsRatingsView
                                    post={post}
                                    onCountChanged={this.commentCountChanged}
                                    noLeftBorder
                                    getIncoming={PostInteraction.GetPostComments}
                                    addCommentRating={PostInteraction.AddPostComment}
                                    onDeleteComment={PostInteraction.DeletePostComment}
                                    onSubmitComment={PostInteraction.UpdatePostComment}
                                />
                            }
                            {this.state.show === "ratings" &&
                                <CommentsRatingsView
                                    post={post}
                                    onCountChanged={this.ratingCountChanged}
                                    noLeftBorder
                                    asRating
                                    getIncoming={PostInteraction.GetPostRatings}
                                    addCommentRating={PostInteraction.AddPostRating}
                                />
                            }
                            {this.state.show === "statistics" &&
                                <QuizStats id={post.id} />
                            }
                            {this.state.show === "incomplete" &&
                                <QuizIncomplete id={post.id} />
                            }
                        </CommentsRatingsContent>
                    </>
                }
            </Card >
        );
    }
    private onShow = (show: string) => () => {
        this.setState({ show });
    };
    private ratingCountChanged = (ratingCount: number, rating?: number) => {
        let value = rating;
        if (value)
            value = this.props.post.rating + value / ratingCount;
        this.setState({ ratingCount, rating: value });
    }
    private commentCountChanged = (commentCount: number) => {
        this.setState({ commentCount });
    }
    private showComments = (showComments: boolean, show: string = "comments") => () => {
        this.setState({ showComments, show });
    };
    private onBookmark = (isBookmarked: boolean) => _.debounce(async () => {
        await ControllerHelper.singleCall({ postid: this.props.post.id }, isBookmarked ? BookmarkController.PostBookmark : BookmarkController.RemoveBookmark);
        this.setState({ isBookmarked });
    }, 500);
    private openModal = () => {
        console.log("openModel called");
        const post = this.props.post as QuizDownloadDto;
        if (post.singlePlayerAllowed && !post.multiPlayerAllowed)
            gotoNamedRoute("quiz_singleplayer", { quizId: this.props.post.id });
        else if (!post.singlePlayerAllowed && post.multiPlayerAllowed)
            gotoNamedRoute("quiz_multiplayer", { quizId: this.props.post.id });
        else
            gotoNamedRoute("quiz_selector", { quizId: this.props.post.id });



    };
}

export default translate("feed")(connect(mapper)(QuizPost));