import { appendPx, belowBreakpointOrEqual, fadeColor, fromThemeCreator, getColor, keyframes, lightenColor, styled, Theme, ThemeMargin } from 'imaginarity-react-ui';
import { wikiTheme, WikiTheme } from 'views/Wiki/WikiTheme';

const fromTheme = fromThemeCreator<WikiTheme>("wikiTheme");

export type BobbelColor = "dot1Color" | "dot2Color" | "dot3Color" | "dot4Color";

const bobbelPositions: { left?: ThemeMargin, right?: ThemeMargin, top?: ThemeMargin, bottom?: ThemeMargin }[] = [
    { top: appendPx(wikiTheme.homePaneTheme.homePaneDot1Position.top ?? "unset"), right: appendPx(wikiTheme.homePaneTheme.homePaneDot1Position.right ?? "unset"), bottom: appendPx(wikiTheme.homePaneTheme.homePaneDot1Position.bottom ?? "unset"), left: appendPx(wikiTheme.homePaneTheme.homePaneDot1Position.left ?? "unset") },
    { top: appendPx(wikiTheme.homePaneTheme.homePaneDot2Position.top ?? "unset"), right: appendPx(wikiTheme.homePaneTheme.homePaneDot2Position.right ?? "unset"), bottom: appendPx(wikiTheme.homePaneTheme.homePaneDot2Position.bottom ?? "unset"), left: appendPx(wikiTheme.homePaneTheme.homePaneDot2Position.left ?? "unset") },
    { top: appendPx(wikiTheme.homePaneTheme.homePaneDot3Position.top ?? "unset"), right: appendPx(wikiTheme.homePaneTheme.homePaneDot3Position.right ?? "unset"), bottom: appendPx(wikiTheme.homePaneTheme.homePaneDot3Position.bottom ?? "unset"), left: appendPx(wikiTheme.homePaneTheme.homePaneDot3Position.left ?? "unset") },
    { top: appendPx(wikiTheme.homePaneTheme.homePaneDot4Position.top ?? "unset"), right: appendPx(wikiTheme.homePaneTheme.homePaneDot4Position.right ?? "unset"), bottom: appendPx(wikiTheme.homePaneTheme.homePaneDot4Position.bottom ?? "unset"), left: appendPx(wikiTheme.homePaneTheme.homePaneDot4Position.left ?? "unset") },
];

const returnColor = (theme: Theme, color: BobbelColor) => {
    switch (color) {
        case "dot1Color":
            return wikiTheme.homePaneTheme.homePaneDot1Color;
        case "dot2Color":
            return wikiTheme.homePaneTheme.homePaneDot2Color;
        case "dot3Color":
            return wikiTheme.homePaneTheme.homePaneDot3Color;
        case "dot4Color":
            return wikiTheme.homePaneTheme.homePaneDot4Color;
        default:
            return theme.colors.lightGrey;
    }
}

export const NewsContainer = styled.div<{ open: boolean }>`
    position: absolute;
    //width: calc(100% - 40px);
    width: 100%;
    height: 40px;
    top: 0px;
    overflow: hidden;
    border-radius: 0px;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    background: ${props => props.open ? getColor(props, props.theme.colors.darkGrey) : fadeColor(props, getColor(props, props.theme.colors.mainBackground), 80)};
    left: 0px;
    padding-right: 15px;
    padding-bottom: 5px;
    transition: 0.1s all ease-out;
    border-right:3px solid transparent;
    transition: 0.2s all ease-out;
    display: grid;
    display: -ms-grid;
    grid-template-columns: max-content 1fr minmax(0, max-content) ;
    -ms-grid-columns: max-content 1fr max-content ;
    grid-template-rows: 40px;
    -ms-grid-rows: 40px;
    &:hover{
        background: ${props => props.open ? getColor(props, props.theme.colors.darkGrey) : fadeColor(props, getColor(props, props.theme.colors.mainBackground), 80)};
        border-right:3px solid ${props => getColor(props, props.theme.colors.accent)};
    }
`;

export const NewsTitle = styled.div<{ open: boolean }>`
    color: ${props => getColor(props, props.theme.colors.mainBackground)};
    line-height: 40px;
    height: 40px;
    border-radius: 0px;
    text-align: left;
    font-size: 1em;
    padding: 0 10px;
    font-weight: 500;
    background: ${props => getColor(props, props.theme.colors.darkGrey)};
    -webkit-user-select: none;
    user-select: none;
    -webkit-font-smoothing: antialiased;
`;

export const scaleIn = keyframes`
    from {transform: scale(0); opacity:1;}
    to {transform: scale(1); opacity:1;}
  `;

export const growIn = keyframes`
  from {
            transform: scaleX(0.4);
            transform-origin: 0% 0%;
  }
  to {
            transform: scaleX(1);
            transform-origin: 0% 0%;
  }
`;
export const fadeIn = keyframes`
    from {opacity: 0;}
    to {opacity: 1;}
`;
export const fadeOut = keyframes`
    from {opacity: 1;}
    to {opacity: 0;}
`;
const squeeze = keyframes`
    0% {transform: scale3d(1, 1, 1);}
    5% {transform: scale3d(1.25, 0.75, 1);}
    6.7% {transform: scale3d(0.75, 1.25, 1);}
    8.3% {transform: scale3d(1.15, 0.85, 1);}
    10.8% {transform: scale3d(0.95, 1.05, 1);}
    12.5% {transform: scale3d(1.05, 0.95, 1);}
    16.7% {transform: scale3d(1, 1, 1);}
    100% {transform: scale3d(1, 1, 1);}
  `;

export class WikiSC {
    public static CategoriesContainer = styled.div<{ minimize: boolean }>`
    height: fit-content;
    max-height: ${p => p.minimize ? "200px" : "400px"};
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    transition: all 0.2s ease;
    display: block;
`;
    public static ArticlesContainer = styled.div`
    height: fit-content;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
`;
    public static SubContainer = styled.div<{ marginLeft: number }>`
    margin-left: ${p => p.marginLeft}px;
`;
    public static ToC = styled.div`
    height: fit-content;
    max-height: 250px;
    overflow-y: auto;
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
`;
    public static WASTitle = styled.div`
    width: 100%;
    font-size: 0.9rem;
    font-weight: bolder;
    padding-top: 8px;
    margin-bottom: -3px;
`;
    public static WASTitleCats = styled.div`
    width: 100%;
    font-size: 0.9rem;
    font-weight: bolder;
    padding-bottom:5px;
    margin-top: -5px;
`;
    public static PaneContentContainer = styled.div<{ noMinHeight: boolean, noMarginTop: boolean }>`
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    margin-top: ${p => p.noMarginTop ? 0 : 10}px;
    width: 100%;
    min-height: ${p => p.noMinHeight ? "100px" : "500px"};
`;
    public static PaneContainer = styled.div`
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    padding-bottom:10px;
`;
    public static ContentContainer = styled.div<{ visible: boolean }>`
    grid-row: 2;
    grid-column: 1 / span 3;
    border: 1px solid ${p => p.visible ? getColor(p, p.theme.colors.middleLightGrey) : "transparent"};
    margin-bottom: ${p => p.visible ? 30 : 0}px;
    margin-top:-1px;
    padding: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
    public static CategoryTop = styled.div<{ noHover?: boolean, selected: boolean, selectedItem?: boolean }>`
    display: block;
    width: 100%;
    padding-right:10px;
    line-height: 1.6rem;
    font-size: 0.9rem;
    background: ${props => props.selectedItem ? getColor(props, props.theme.colors.accent) : props.selected ? lightenColor(props, props.theme.colors.mainBackground, 90) : getColor(props, props.theme.colors.mainBackground)};
    color: ${props => props.selectedItem ? getColor(props, props.theme.colors.mainBackground) : props.selected ? getColor(props, props.theme.colors.accentBlack) : getColor(props, props.theme.colors.darkGrey)};
    border-left: 3px solid ${props => props.selected ? getColor(props, props.theme.colors.accent) : getColor(props, props.theme.colors.mainBackground)};
    transition: all 0.2s ease-out;
    cursor: ${p => p.noHover ? "default" : "pointer"};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    svg{
        stroke: ${props => props.selectedItem ? getColor(props, props.theme.colors.mainBackground) : props.selected ? getColor(props, props.theme.colors.accentBlack) : getColor(props, props.theme.colors.darkGrey)};
    }
    &:hover {
        background: ${props => props.noHover ? getColor(props, props.theme.colors.mainBackground) : props.selectedItem ? getColor(props, props.theme.colors.accent) : props.selected ? lightenColor(props, props.theme.colors.mainBackground, 85) : lightenColor(props, props.theme.colors.mainBackground, 95)};
        color: ${props => props.noHover ? getColor(props, props.theme.colors.darkGrey) : props.selectedItem ? getColor(props, props.theme.colors.mainBackground) : getColor(props, props.theme.colors.accentBlack)};
        border-left: 3px solid ${props => props.noHover ? getColor(props, props.theme.colors.mainBackground) : props.noHover ? getColor(props, props.theme.colors.mainBackground) : props.selected ? getColor(props, props.theme.colors.accent) : getColor(props, props.theme.colors.veryLightGrey)};
        svg{
        stroke: ${props => props.noHover ? getColor(props, props.theme.colors.darkGrey) : props.selectedItem ? getColor(props, props.theme.colors.mainBackground) : getColor(props, props.theme.colors.accentBlack)};
    }
    }
`;
    public static SubCategoryTop = styled(WikiSC.CategoryTop)`
    background: ${props => props.selectedItem ? getColor(props, props.theme.colors.accent) : props.selected ? lightenColor(props, props.theme.colors.veryLightGrey, 90) : getColor(props, props.theme.colors.veryLightGrey)};
    padding-left: 15px;
    border-left: 3px solid ${props => props.selected ? getColor(props, props.theme.colors.accent) : getColor(props, props.theme.colors.veryLightGrey)};
    &:hover {
        background: ${props => props.selectedItem ? getColor(props, props.theme.colors.accent) : props.selected ? lightenColor(props, props.theme.colors.veryLightGrey, 85) : lightenColor(props, props.theme.colors.veryLightGrey, 95)};
        border-left: 3px solid ${props => props.selected ? getColor(props, props.theme.colors.accent) : getColor(props, props.theme.colors.middleLightGrey)};
    }
`;
    public static SubCategoryTopSearch = styled(WikiSC.CategoryTop)`
    background: ${props => props.selectedItem ? getColor(props, props.theme.colors.accent) : props.selected ? lightenColor(props, props.theme.colors.mainBackground, 90) : props.theme.colors.mainBackground};
    border-left: 3px solid ${props => props.selected ? props.theme.colors.accent : props.theme.colors.mainBackground};
    padding-left: 15px;
    &:hover {
        background: ${props => props.selectedItem ? getColor(props, props.theme.colors.accent) : props.selected ? lightenColor(props, props.theme.colors.mainBackground, 85) : lightenColor(props, props.theme.colors.mainBackground, 95)};
        border-left: 3px solid ${props => props.selected ? getColor(props, props.theme.colors.accent) : getColor(props, props.theme.colors.middleLightGrey)};
    }
`;
    public static HotSpotContainerOuter = styled.div`
    position: relative;
    width: calc(100% - 0.5rem);
    left: 0.25rem;
`;
    public static HotSpotContainer = styled.div<{ paddingTop: number }>`
    position: relative;
    width: 100%;
    padding-bottom: ${p => p.paddingTop}%;
    top: 0;
    left:0;
    z-index:1;
    margin-bottom: 40px;
`;
    public static HotSpotContainerImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index:1;
`;
    public static HotSpotContainerImageNoPointer = styled.div`
    pointer-events: none;
`;
    public static OverlayElement = styled.div<{ marginTop: number }>`
    margin-top: ${p => p.marginTop}px;
    background: ${p => getColor(p, p.theme.colors.mainBackground)};
    padding: 10px;
`;
    public static InputElement = styled.div<{ edit: boolean, smallDisplay: boolean }>`
    display: block;
    width: 100%;
    margin-bottom: 2px;
    transition: 0.2s all ease-out;
    color: ${p => p.edit ? getColor(p, p.theme.colors.mainBackground) : getColor(p, p.theme.colors.darkGrey)};
    background: ${p => p.edit ? fadeColor(p, getColor(p, p.theme.colors.accent), 100) : getColor(p, p.theme.colors.veryLightGrey)};
    transition: 0.2s all ease-out;
    svg{
        stroke:${p => p.edit ? getColor(p, p.theme.colors.mainBackground) : getColor(p, p.theme.colors.darkGrey)};
    }
`;
    public static EditorStyled = styled.div<{ padded: boolean }>`
    width: 100%; 
    height: auto;
    margin-top: -17px;
    padding-right: ${p => p.padded ? "20px" : 0};
`;
    public static EditorStyledTitle = styled.div`
    width: 100%; 
    height: 40px;
    line-height:40px;
    padding: 0 10px;
    font-size: 0.9rem;
    font-weight: bolder;
    background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
    border-bottom: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    margin-bottom:-1px;
`;
    public static Info = styled.div`
    display: inline-block;
    padding-left: 10px;
    line-height: 40px;
    font-weight: bold;
`;
    public static EditorStyledInfo = styled.div`
    width: 100%;
    padding-left: 10px;
    line-height: 24px;
    color: ${p => getColor(p, p.theme.colors.darkGrey)};
    svg{
        stroke: ${p => getColor(p, p.theme.colors.darkGrey)};
    }
`;
    public static ButtonGrid = styled.div`
    display: grid;
    display: -ms-grid;
    grid-template-columns: 40px 40px 40px 1fr 40px 40px 40px;
    -ms-grid-columns: 40px 40px 40px 1fr 40px 40px 40px;
    grid-template-rows: 40px 40px 40px;
    -ms-grid-rows: 40px 40px 40px;
    margin-top: 2px;
    margin-bottom: -1px;
    background-color: ${p => getColor(p, p.theme.colors.veryLightGrey)};
`;
    public static ShiftUpWide = styled.div`
    grid-column: 2;
    -ms-grid-column: 2;
    grid-row: 1;
    -ms-grid-row: 1;
`;
    public static ShiftRightWide = styled.div`
    grid-column: 3;
    -ms-grid-column: 3;
    grid-row: 2;
    -ms-grid-row: 2;
`;
    public static ShiftDownWide = styled.div`
    grid-column: 2;
    -ms-grid-column: 2;
    grid-row: 3;
    -ms-grid-row: 3;
`;
    public static ShiftLeftWide = styled.div`
    grid-column: 1;
    -ms-grid-column: 1;
    grid-row: 2;
    -ms-grid-row: 2;
`;
    public static InfoShift = styled.div`
    grid-column: 4;
    -ms-grid-column: 4;
    grid-row: 2;
    -ms-grid-row: 2;
    line-height: 40px;
    text-align: center;
    font-weight: bolder;
    font-size: 1rem;
    text-transform: uppercase;
`;
    public static InfoLeft = styled.div`
    grid-column: 2;
    -ms-grid-column: 2;
    grid-row: 2;
    -ms-grid-row: 2;
    line-height: 40px;
    text-align: center;
    font-size: 0.7rem;
    background: ${p => lightenColor(p, getColor(p, p.theme.colors.veryLightGrey), 97)};
    border-radius: 20px;
`;
    public static InfoRight = styled.div`
    grid-column: 6;
    -ms-grid-column: 6;
    grid-row: 2;
    -ms-grid-row: 2;
    line-height: 40px;
    text-align: center;
    font-size: 0.7rem;
    background: ${p => lightenColor(p, getColor(p, p.theme.colors.veryLightGrey), 97)};
    border-radius: 20px;
`;
    public static ShiftUp = styled.div`
    grid-column: 6;
    -ms-grid-column: 6;
    grid-row: 1;
    -ms-grid-row: 1;
`;
    public static ShiftRight = styled.div`
    grid-column: 7;
    -ms-grid-column: 7;
    grid-row: 2;
    -ms-grid-row: 2;
`;
    public static ShiftDown = styled.div`
    grid-column: 6;
    -ms-grid-column: 6;
    grid-row: 3;
    -ms-grid-row: 3;
`;
    public static ShiftLeft = styled.div`
    grid-column: 5;
    -ms-grid-column: 5;
    grid-row: 2;
    -ms-grid-row: 2;
`;
    public static ButtonGridButtonsBtm = styled.div`
    grid-column: 4;
    -ms-grid-column: 4;
    grid-row: 3;
    -ms-grid-row: 3;
    text-align: center;
    line-height: 40px;
    font-weight: bolder;
    font-size: 1rem;
`;
    public static ButtonGridButtonsTop = styled.div`
    grid-column: 4;
    -ms-grid-column: 4;
    grid-row: 1;
    -ms-grid-row: 1;
    text-align: center;
`;
    public static WEDEditorStyled = styled.div`
    position: "absolute";
    top: 100; 
    left: 100; 
    width: 300; 
    height: 100;
    z-index: 2000;
`;
    public static TSS = styled.div<{ editMode: boolean }>`
        width: 100%;
        position: relative;
        padding-bottom: ${p => p.editMode ? "30px" : "10px"};
        padding-left: ${p => p.editMode ? "20px" : 0};
        padding-right: ${p => p.editMode ? "20px" : 0};
        margin-bottom: ${p => p.editMode ? "20px" : 0};
        transition: 0.2s all ease-out;
        overflow: auto;
    p{
        margin: 2px 0 2px 0 !important;
    }
    table{
        width: 100%;
        border-right: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
        border-top: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
        padding: 0;
        margin:0;
        margin-top: ${p => p.editMode ? "20px" : "0px"};
        border-spacing: 0;
        transition: 0.2s all ease-out;
        overflow-x: auto;
    }
    th{
        background: ${p => fadeColor(p, getColor(p, p.theme.colors.lightGrey), 10)};
        border-left:1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
        border-bottom: 2px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
        text-align: left;
        vertical-align: top;
        margin:0;
        padding: 8px 5px;
        position: relative;
        top:0;
        left:0;
        transition: 0.2s all ease-out;
    }
    td  {
        border-left: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
        border-bottom: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
        position: relative;
        top:0;
        left:0;
        vertical-align: top;
        margin:0;
        padding:5px;
        height: 36px;
        empty-cells: show;
        background: ${p => getColor(p, p.theme.colors.mainBackground)};
        &.marked.check{
            background: ${p => fadeColor(p, getColor(p, p.theme.colors.accentGreen), 20)};
        }
        &.error.check{
            background: ${p => fadeColor(p, getColor(p, p.theme.colors.accentRed), 20)};
        }
        &.select{
            background: ${p => fadeColor(p, getColor(p, p.theme.colors.accentBlue), 20)};
        }
        &.header{
            background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
            font-weight: bolder;
        }
        &.underEdit{
            background: ${p => fadeColor(p, getColor(p, p.theme.colors.accentYellow), 20)};
        }
    }
`;
    public static EditColumnIconTop = styled.div<{ active: boolean }>`
    position: absolute;
    top:-23px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    svg{
        transform: scale(${p => p.active ? 1.2 : 1});
        transform-origin: center bottom;
        transition: 0.2s all ease-out;
        stroke: ${p => p.active ? getColor(p, p.theme.colors.accentBlue) : fadeColor(p, getColor(p, p.theme.colors.lightGrey), 50)} !important;
    }
    &:hover{
        svg{
            transform: scale(1.2);
            stroke: ${p => getColor(p, p.theme.colors.accent)} !important;
            stroke-width: 3px;
        }
    }
`;
    public static EditColumnIconBottom = styled.div<{ active: boolean }>`
    position: absolute;
    bottom:-23px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    svg{
        transform: scale(${p => p.active ? 1.2 : 1});
        transform-origin: center top;
        transition: 0.2s all ease-out;
        stroke: ${p => p.active ? getColor(p, p.theme.colors.accentBlue) : fadeColor(p, getColor(p, p.theme.colors.lightGrey), 50)} !important;
    }
    &:hover{
        svg{
            transform: scale(1.2);
            stroke: ${p => getColor(p, p.theme.colors.accent)} !important;
            stroke-width: 3px;
        }
    }
`;
    public static EditIcon = styled.div`
    float: right;
    min-width:50px;
    min-height:25px;
`;
    public static ColumnSettingsContainer = styled.div`
    position: absolute;
    background: #E5ECF4;
    border-radius: 0px;
    border: 2px solid ${p => getColor(p, p.theme.colors.accentBlue)};
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.2s all ease-out;
    box-shadow: 0 6px 8px -6px #000;
    z-index: 200000;
    animation: ${fadeIn} 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;
    public static ColumnSettingsContainerBottom = styled.div`
    position: absolute;
    background: #E5ECF4;
    border-radius: 0px;
    border: 2px solid ${p => getColor(p, p.theme.colors.accentBlue)};
    top: -81px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.2s all ease-out;
    box-shadow: 0 6px 8px -6px #000;
    z-index: 200000;
    animation: ${fadeIn} 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;
    public static RowSettingsContainer = styled.div`
    position: absolute;
    background: #E5ECF4;
    border-radius: 0px;
    border: 2px solid ${p => getColor(p, p.theme.colors.accentBlue)};
    top: 50%;
    transform: translateY(-50%);
    left: -1px;
    transition: 0.2s all ease-out;
    box-shadow: 0 6px 8px -6px #000;
    z-index: 200000;
    animation: ${fadeIn} 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;
    public static RowSettingsContainerRight = styled.div`
    position: absolute;
    background: #E5ECF4;
    border-radius: 0px;
    border: 2px solid ${p => getColor(p, p.theme.colors.accentBlue)};
    top: 50%;
    transform: translateY(-50%);
    right: -1px;
    transition: 0.2s all ease-out;
    box-shadow: 0 6px 8px -6px #000;
    z-index: 200000;
    animation: ${fadeIn} 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;
    public static EditRowIconLeft = styled.div<{ active: boolean }>`
    position: absolute;
    top: 50%;
    left: -19px;
    transform: translateY(-50%);
    cursor: pointer;
    svg{
        transform: scale(${p => p.active ? 1.2 : 1});
        transform-origin: center right;
        transition: 0.2s all ease-out;
        stroke: ${p => p.active ? getColor(p, p.theme.colors.accentBlue) : fadeColor(p, getColor(p, p.theme.colors.lightGrey), 50)} !important;
    }
    &:hover{
        svg{
            transform: scale(1.1);
            stroke: ${p => getColor(p, p.theme.colors.accent)} !important;
            stroke-width:3px;
        }
    }
`;
    public static EditRowIconRight = styled.div<{ active: boolean }>`
    position: absolute;
    top: 50%;
    right: -19px;
    transform: translateY(-50%);
    cursor: pointer;
    svg{
        transform: scale(${p => p.active ? 1.2 : 1});
        transform-origin: center left;
        transition: 0.2s all ease-out;
        stroke: ${p => p.active ? getColor(p, p.theme.colors.accentBlue) : fadeColor(p, getColor(p, p.theme.colors.lightGrey), 50)} !important;
    }
    &:hover{
        svg{
            transform: scale(1.1);
            stroke: ${p => getColor(p, p.theme.colors.accent)} !important;
            stroke-width:3px;
        }
    }
`;
    public static NewsLi = styled.div<{ open: boolean }>`
    list-style: none;
    line-height: 40px;
    height: 40px;
    color: ${props => props.open ? getColor(props, props.theme.colors.veryLightGrey) : getColor(props, props.theme.colors.darkGrey)};
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -webkit-user-select: none;
    user-select: none;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    animation: ${fadeIn} 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
    transition: 0.2s all ease-out;
    font-weight: ${props => props.open ? 500 : 400};
    width: 100%;
    &:hover{
        color: ${props => props.open ? getColor(props, props.theme.colors.veryLightGrey) : getColor(props, props.theme.colors.accentBlack)};
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0) 100%); 
        font-weight: 500;
    }
`;
    public static NewsDate = styled.div<{ open: boolean }>`
    padding-left:10px;
    -webkit-user-select: none;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    height: 40px;
    line-height:40px;
    color: ${props => props.open ? getColor(props, props.theme.colors.veryLightGrey) : getColor(props, props.theme.colors.darkGrey)};
`;

    public static WikiNewsBoxDesktopContainer = styled.div`
    z-index: 2147483637;
    position: relative;
    top: 40px;
    left:0;
    height: ${props => belowBreakpointOrEqual(props, "tablet") ? "calc(50vh + 80px)" : "calc(30vh + 80px)"}; 
    border: 1px solid ${props => getColor(props, props.theme.colors.lightGrey)};
    background: ${props => getColor(props, props.theme.colors.veryLightGrey)};
    width: 100%;
    padding: 15px;
    font-size: 0.9em;
    box-shadow: 0 8px 6px -6px ${props => getColor(props, props.theme.colors.darkGrey)};
    animation: ${fadeIn} 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
`;
    public static WikiNewsBoxContent = styled.div`
   position:absolute;
   top:15px;
   left:15px;
   overflow-y: auto;
   overflow-x: hidden;
   height:${props => belowBreakpointOrEqual(props, "tablet") ? "50vh" : "30vh"};
   width:calc(100% - 30px);
   padding:10px;
   font-size:0.9rem;
`;
    public static Buttons = styled.div`
   width:calc(100% - 30px);
   min-height:40px;
   position:absolute;
   bottom:10px;
   button>div{margin: 0;}
`;
    public static WikiNewsBoxHeadline = styled.div`
   display:block;
   font-size: 1rem;
   font-weight: 600;
`;
    public static WikiNewsBoxCreator = styled.div`
   display:block;
   font-size: 0.7rem;
`;
    public static CategoryPane = styled.div<{ paneWidth: number }>`
    position: relative;
    width: ${props => props.paneWidth}px;
    height: ${props => props.paneWidth}px;
    border-bottom: ${props => "1px solid " + getColor(props, fromTheme(props, t => t.homePaneTheme.borderBottomColor, t => lightenColor(props, props.theme.colors.veryLightGrey, 90)))};
    text-align:left;
    cursor:pointer;
    transition: 0.1s all ease-out;
    animation: ${fadeIn} 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
    background: ${props => getColor(props, fromTheme(props, t => t.homePaneTheme.backgroundColor, t => fadeColor(props, getColor(props, props.theme.colors.veryLightGrey), 40)))};
    &:hover{
        border-bottom-color: ${props => getColor(props, fromTheme(props, t => t.homePaneTheme.borderBottomColorHover, t => getColor(props, props.theme.colors.darkGrey)))};
        background:${props => getColor(props, fromTheme(props, t => t.homePaneTheme.backgroundColorHover, t => fadeColor(props, getColor(props, props.theme.colors.veryLightGrey), 100)))};
        color: ${props => getColor(props, props.theme.colors.accent)};
    }
    &:active{
        border-bottom-color: ${props => getColor(props, props.theme.colors.accent)};
        background: ${props => lightenColor(props, props.theme.colors.veryLightGrey, 90)};
        color: ${props => getColor(props, props.theme.colors.accent)};
        transform: ${props => belowBreakpointOrEqual(props, "tablet") ? "scale(1)" : "scale(0.98)"};
    }
`;
    public static CategoryTitle = styled.div`
    position: absolute;
    top:65%;
    left: 10px;
    right: 10px;
    font-weight: 600;
    line-height: 0.94em;
    font-size:1.0rem;
`;
    public static CategoryQuizButton = styled.div`
    position: absolute;
    color: ${props => getColor(props, props.theme.colors.darkGrey)};
    left:0;
    bottom: 5px;
    font-size: 0.87em;
    //height: 20px;
    //line-height: 20px;
    padding-left: 10px;
    //background: ${props => getColor(props, fromTheme(props, t => t.homePaneTheme.backgroundColor, t => fadeColor(props, getColor(props, props.theme.colors.veryLightGrey), 40)))};
    //border-bottom: 1px solid ${props => getColor(props, fromTheme(props, t => t.homePaneTheme.borderBottomColor, t => lightenColor(props, props.theme.colors.veryLightGrey, 90)))};
    cursor:pointer;
    transition: 0.1s all ease-out;
    svg{
        fill: ${props => getColor(props, props.theme.colors.accent)};
    }
    &:hover{
        //border-bottom-color: ${props => getColor(props, fromTheme(props, t => t.homePaneTheme.borderBottomColorHover, t => getColor(props, props.theme.colors.darkGrey)))};
        //background:${props => getColor(props, fromTheme(props, t => t.homePaneTheme.backgroundColorHover, t => fadeColor(props, getColor(props, props.theme.colors.veryLightGrey), 100)))};
        //color: ${props => getColor(props, props.theme.colors.accent)};
    }
`;
    public static OuterBobbel = styled.div<{ position: number, background: string }>`
    position: absolute;
    height: ${props => appendPx(fromTheme(props, t => t.homePaneTheme.homePaneDotHeight, t => 16))};
    width: ${props => appendPx(fromTheme(props, t => t.homePaneTheme.homePaneDotWidth, t => 16))};
    border-radius: ${props => appendPx(fromTheme(props, t => t.homePaneTheme.homePaneDotBorderRadius, t => 20))};
    right: ${props => bobbelPositions[props.position]?.right !== undefined ? appendPx(bobbelPositions[props.position]?.right ?? 0) : "unset"};
    left: ${props => bobbelPositions[props.position]?.left !== undefined ? appendPx(bobbelPositions[props.position]?.left ?? 0) : "unset"};
    top: ${props => bobbelPositions[props.position]?.top !== undefined ? appendPx(bobbelPositions[props.position]?.top ?? 0) : "unset"};
    bottom: ${props => bobbelPositions[props.position]?.bottom !== undefined ? appendPx(bobbelPositions[props.position]?.bottom ?? 0) : "unset"};
    animation: ${scaleIn} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
    background: ${props => fromTheme(props, t => t.homePaneTheme.homePaneDotBackgroundColor, t => t.colors.mainBackground)};
    z-index: 1001;
`;
    public static WikiHomePaneContainerBobbel = styled(WikiSC.OuterBobbel) <{ pulse?: boolean, background?: string, bullet: "Active" | "Passive", color: BobbelColor }>`
    box-shadow: ${props => props.bullet === "Active" && fromTheme(props, t => t.homePaneTheme.homePaneDotShadowed, t => false) ? "0px 3px 4px -2px" + getColor(props, props.theme.colors.darkGrey) : "none"};
    border: ${props => appendPx(fromTheme(props, t => t.homePaneTheme.homePaneDotBorderWidth, t => 4))} solid  ${props => props.background};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => fadeColor(props, returnColor(props.theme, props.color), props.bullet === "Active" ? 100 : 30)};
    animation: ${squeeze} ${props => props.pulse && props.bullet === "Active" ? "2.4s infinite both" : "none"};
    z-index: 1000;
`;
    public static Container = styled.div`
    padding: ${props => belowBreakpointOrEqual(props, "tablet") ? "0 10px" : "0px"};  
    padding-bottom:40px;
`;
    public static Headline = styled.div`
    font-size:1.5rem; 
    line-height:40px;
    font-weight: bolder;
    margin-bottom:10px;
    width:100%;
`;
    public static Grid = styled.div`
    display: grid;
    grid-template-columns: ${props => belowBreakpointOrEqual(props, "tablet") ? "1fr" : "1fr 2fr"}; 
    grid-template-rows: ${props => belowBreakpointOrEqual(props, "tablet") ? "min-content" : "1fr"}; 
    margin-bottom: 10px;
`;
    public static Label = styled.div<{ color?: string }>`
    color: ${p => p.color ?? ""};
    font-weight: bolder;
    line-height:1.5rem;
    font-size:0.9rem;
`;
    public static DateContainer = styled.div`
    //margin-left: -4px;
    //width: calc(100% + 18px);
    width: 100%;
    margin-bottom:-25px;
`;
    public static GridDateContainer = styled.div`
    display: grid;
    display: -ms-grid;
    grid-template-columns: ${props => belowBreakpointOrEqual(props, "tablet") ? "1fr" : "1fr 2fr"}; 
    grid-template-rows: ${props => belowBreakpointOrEqual(props, "tablet") ? "min-content" : "40px"};     
    -ms-grid-columns: ${props => belowBreakpointOrEqual(props, "tablet") ? "1fr" : "1fr 2fr"}; 
    -ms-grid-rows: ${props => belowBreakpointOrEqual(props, "tablet") ? "min-content" : "40px"}; 
    margin-bottom: 10px;
    //margin-top: ${props => belowBreakpointOrEqual(props, "tablet") ? "-20px" : "0"}; 
    margin-top: 0; 
`;
    public static GridCheckboxOuterText = styled(WikiSC.Grid)`
    -ms-grid-rows: unset;
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr;     
    -ms-grid-columns: 1fr; 
    -ms-grid-rows: 1fr; 
`;
    public static GridCol1Text = styled.div`
    width: 100%;
    grid-column: 1;
    grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
`;
    public static GridCol2Text = styled.div`
    margin-top:5px;
    width: 100%;
    grid-column: 1;
    grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    overflow: auto; 
    background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
`;
    public static GridCheckboxOuter = styled(WikiSC.Grid)`
    grid-template-rows: unset; 
    -ms-grid-rows: unset;
`;
    public static ResetSearchIcon = styled.div`
    position: absolute;
    top: 8px;
    right: 15px;
    cursor: pointer;
    &:hover{
        svg{
            stroke: ${p => getColor(p, p.theme.colors.accent)};
        }
    }
`;
    public static SelectCellContainer = styled.div<{ rows: number, cols: number, width: string, height: string }>`
    background: ${p => getColor(p, p.theme.colors.mainBackground)};
    float: left;
    padding: 1px 0 0 1px;
    width: ${p => "calc(" + p.cols + " * " + p.width + " + " + p.cols + "px)"};
    position: relative;
    height: ${p => "calc(" + p.rows + " * " + p.height + " + " + p.rows + "px)"};
`;
    public static SelectCell = styled.div<{ active: boolean, set: boolean, width: string, height: string }>`
    background: ${p => p.set ? p.active ? fadeColor(p, getColor(p, p.theme.colors.accentGreen), 20) : fadeColor(p, getColor(p, p.theme.colors.accentGreen), 10) : p.active ? fadeColor(p, getColor(p, p.theme.colors.darkGrey), 10) : getColor(p, p.theme.colors.veryLightGrey)};
    border: 1px solid ${p => p.set ? getColor(p, p.theme.colors.accentGreen) : p.active ? getColor(p, p.theme.colors.lightGrey) : getColor(p, p.theme.colors.middleLightGrey)};
    line-height: ${p => p.height};
    width: ${p => p.width};
    min-width: 10px;
    height: ${p => p.height};
    min-height: 10px;
    margin: 0 1px 1px 0;
    cursor: pointer;
    &:hover{
        background: ${p => p.set ? p.active ? fadeColor(p, getColor(p, p.theme.colors.accentGreen), 25) : fadeColor(p, getColor(p, p.theme.colors.accentGreen), 20) : fadeColor(p, getColor(p, p.theme.colors.darkGrey), 15)};
        border: 1px solid ${p => p.set ? getColor(p, p.theme.colors.accentGreen) : getColor(p, p.theme.colors.lightGrey)};
    }
`;

    public static InfoContainerTemp = styled.div<{ size: string }>`
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    color: ${p => fadeColor(p, getColor(p, p.theme.colors.darkGrey), 20)};
    font-size: ${p => p.size};
    pointer-events: none;
    width: 100%;
    text-align: center;
    transition: 0.12s all ease-out;
    svg{
        stroke: ${p => fadeColor(p, getColor(p, p.theme.colors.darkGrey), 20)};
    }
`;
    public static InfoContainer = styled.div<{ size: string, rows: number, cols: number, width: string, height: string }>`
    position: absolute;
    top: 0;
    left: 0;
    color: ${p => fadeColor(p, getColor(p, p.theme.colors.accentGreen), 60)};
    font-size: ${p => p.size};
    pointer-events: none;
    width: ${p => "calc(" + p.cols + " * " + p.width + " + " + p.cols + "px)"};
    height: ${p => "calc(" + p.rows + " * " + p.height + " + " + p.rows + "px)"};
    line-height: ${p => "calc(" + p.rows + " * " + p.height + " + " + p.rows + "px)"};
    transition: 0.12s all ease-out;
    text-align: center;
    font-weight: bolder;
    svg{
        stroke: ${p => fadeColor(p, getColor(p, p.theme.colors.accentGreen), 60)};
        stroke-width: 4;
    }
`;

    public static WikiQuizSliderInfo = styled.div`
    position: absolute;
    width: 60px;
    height: 60px;
    padding: 5px;
    text-align: center;
    top: -1px;
    right: -1px;    
    color: ${p => getColor(p, p.theme.colors.mainBackground)} !important;
    background: linear-gradient(335deg, ${p => fadeColor(p, getColor(p, p.theme.colors.accent), 80)} 20%, ${p => fadeColor(p, getColor(p, p.theme.colors.accent), 100)} 80%, ${p => fadeColor(p, getColor(p, p.theme.colors.accent), 80)} 100%);
    clip-path: polygon(60% 0, 100% 40%, 100% 100%, 100% 100%, 0 0, 0 0);    
`;
    public static WikiQuizSliderInfoInsideFigure = styled.div`
    position: absolute;
    top: -6px;
    right: -9px;
    width: 60px;
    line-height: 60px;
    height: 60px;
    transform: rotate(45deg);
    display: grid;
    grid-template-columns: 1fr;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 0.75em;
`;
}