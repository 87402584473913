import { ButtonSizes, explodePadding, fadeColor, getColor, keyframes, styled } from 'imaginarity-react-ui';

const keysDown = keyframes`
    from{top: 100%; filter: blur(20px); opacity:0.5;}
    to{top: 2px; filter: blur(0); opacity:1;}
`;
const keysUp = keyframes`
    from{bottom: 100%; filter: blur(20px); opacity: 0.5;}
    to{bottom: 0px; filter: blur(0); opacity: 1;}
`;
export const FloatedRight = styled.div<{ margin?: string }>`
    float: right;
    margin: ${p => p.margin ?? 0};
    button>div{margin:0;}
`;
export const FloatedLeft = styled.div<{ margin?: string }>`
    float: left;
    margin: ${p => p.margin ?? 0};
    button>div{margin:0;}
`;
export const PaddedLeftPx = styled.div<{ width: number }>`
    padding-left: ${p => p.width}px;
`;
export const PaddedRightPx = styled.div<{ width: number }>`
    padding-right: ${p => p.width}px;
`;
export const PaddedDiv = styled.div<{ unit: string; top?: number, right?: number, bottom?: number, left?: number }>`
    padding-top: ${p => (p.top ?? 0) + p.unit};
    padding-right: ${p => (p.right ?? 0) + p.unit};
    padding-bottom: ${p => (p.bottom ?? 0) + p.unit};
    padding-left: ${p => (p.left ?? 0) + p.unit};
`;
export class MasterLayoutsSC {
    public static Content = styled.div<{ scroll?: "scroll" | "hidden" | "auto", colStart: number, colEnd: number, noPadding?: boolean, hidden?: boolean }>`
    height: 100%;
    grid-row: 2;
    grid-column-start: ${p => p.colStart};
    grid-column-end: ${p => p.colEnd};
    overflow-y: ${p => p.scroll ?? "auto"};
    overflow-x: hidden;
    background: ${p => getColor(p, p.theme.backgroundColor)};

    color: ${p => getColor(p, p.theme.color)};
    ${p => p.noPadding ? `padding: 0px;` : `padding: ${p.theme.padding};`}
    position: relative;
    ${p => p.hidden ? "display: none;" : ""}
`;
    public static ContentArea = styled.div<{ width: number, height: number, noPadding?: boolean }>`
    position: absolute;
    top: ${p => p.noPadding ? 0 : explodePadding(p.theme.padding).top};
    left: ${p => p.noPadding ? 0 : explodePadding(p.theme.padding).left};
    width: ${p => p.width}px;
    height: ${p => p.height}px;
`;
    public static ContentSidebarArea = styled.div<{ width: number, height: number, noPadding?: boolean }>`
    position: absolute;
    top: ${p => p.noPadding ? 0 : explodePadding(p.theme.padding).top};
    left: ${p => p.noPadding ? 0 : explodePadding(p.theme.padding).left};
    width: ${p => p.width + p.theme.sidebars.padding.right + p.theme.sidebars.padding.left}px;
    height: ${p => p.height + p.theme.sidebars.padding.top + p.theme.sidebars.padding.bottom}px;
    padding: ${p => `${p.theme.sidebars.padding.top}px ${p.theme.sidebars.padding.right}px ${p.theme.sidebars.padding.bottom}px ${p.theme.sidebars.padding.left}px`};
`;
    public static ContentAreaGridDataPrivacy = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content;
`;
    public static ContentAreaGridDataPrivacyTop = styled.div`
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;
    public static ContentAreaGridDataPrivacyBottom = styled.div`
    padding-top: ${p => `${p.theme.sidebars.padding.bottom}px`};
    overflow: hidden;
`;
    public static ContentTitle = styled.div<{ col: number, fullWidth?: boolean; }>`
    grid-row: 1;
    ${p => p.fullWidth ? `
        grid-column-start: 1;
        grid-column-end: 4;
    ` : `
        grid-column: ${p.col};
        `}
`;
    public static ToggleContainer = styled.div<{ start: number, end: number }>`
    pointer-events: none;
    position: relative;
    grid-column-start: ${p => p.start};
    grid-column-end: ${p => p.end};
    grid-row: 2;
`;
    public static ToggleLeft = styled.div<{ size?: ButtonSizes, down?: boolean, leftOff: boolean }>`
    pointer-events: all;
    position: absolute;
    left: 5px;
    opacity: 0;
    ${p => p.down ? `bottom:100%;` : `top:100%;`}
    animation: ${p => p.down ? keysUp : keysDown} 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) 1s forwards 1;
    transition: all 0.2s ease-out;
    cursor: pointer;
    svg{
            stroke: ${p => getColor(p, p.theme.colors.lightGrey)};
        }
    &:hover{
        transform: scale(1.2) translateX(${p => p.leftOff ? 5 : -5}px);
        svg{
            stroke: ${p => getColor(p, p.theme.colors.accent)};
        }
    }
`;
    public static ToggleRight = styled.div<{ size?: ButtonSizes, down?: boolean, rightOff: boolean }>`
    pointer-events: all;
    position: absolute;
    right: 10px;
    opacity: 0;
    ${p => p.down ? `bottom:100%;` : `top:100%;`}
    animation: ${p => p.down ? keysUp : keysDown} 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) 1s forwards 1;
    transition: all 0.2s ease-out;
    cursor: pointer;
    svg{
            stroke: ${p => getColor(p, p.theme.colors.lightGrey)};
        }
    &:hover{
        transform: scale(1.2) translateX(${p => p.rightOff ? -5 : 5}px);
        svg{
            stroke: ${p => getColor(p, p.theme.colors.accent)};
        }
    }
`;


    public static ToggleLeftAlternative = styled.div<{ size?: ButtonSizes, down?: boolean, leftOff: boolean }>`
    pointer-events: all;
    position: absolute;
    left: 0px;
    top: calc(50% - 80px);
    opacity: 1;
    transition: all 0.1s ease-out;
    background: ${p => getColor(p, p.theme.leftArea.backgroundColor)};
    height: 80px;
    line-height: 80px;
    width: 10px;
    z-index: 999;
    cursor: pointer;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    svg{
            display: none;
        }
    &:hover{
        width: 30px;
        box-shadow: 6px 0 8px -6px #000;
        svg{
            display: block;
            stroke: ${p => getColor(p, p.theme.leftArea.color)};
            margin-top: 10px;
            margin-left: -3px;
        }
    }
`;
    public static ToggleRightAlternative = styled.div<{ size?: ButtonSizes, down?: boolean, rightOff: boolean }>`
    pointer-events: all;
    position: absolute;
    right: 0px;
    top: calc(50% - 80px);
    opacity: 1;
    transition: all 0.1s ease-out;
    background: ${p => p.rightOff ? getColor(p, p.theme.colors.darkGrey) : fadeColor(p, p.theme.rightArea.backgroundColor, 90)};
    height: 80px;
    line-height: 80px;
    width: 10px;
    z-index: 999;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    svg{
        display: none;
        }
    &:hover{
        width: 30px;
        box-shadow: -6px 0 8px -6px #000;
        background: ${p => fadeColor(p, p.theme.rightArea.backgroundColor, 100)};
        svg{
            display: block;
            stroke: ${p => getColor(p, p.theme.rightArea.color)};
            margin-top: 10px;
            margin-right: -3px;
        }
    }
`;
}

