import { Button, ButtonProps, ImgIcons } from "imaginarity-react-ui";
import * as React from "react";
import { useImgI18N } from "services/ImgI18N";
import styled from "styled-components";

const Conatiner = styled.div`
    button>div{margin: 0;}
`;

interface CancelWithConfirmationProps {
    namespace?: string;
    cancel: () => void;
    children?: JSX.Element;
    cancelText?: string;
    confirmText?: string;
    icon?: ImgIcons;
    iconOnly?: boolean;
    floated?: "left" | "right";
    tooltipInitialButton?: string;
    kind?: ButtonProps["kind"];
    iconColor?: string;
}

const CancelWithConfirmation = (p: CancelWithConfirmationProps) => {
    const { namespace, cancel, children, cancelText, confirmText, iconOnly, floated, icon, tooltipInitialButton, kind, iconColor } = p;
    const [check, setCheck] = React.useState<boolean>(false);
    const { t } = useImgI18N(namespace ?? "general");

    const cancelProcess = () => {
        setCheck(false);
        cancel();
    }

    return (
        <Conatiner style={{ float: floated ?? "left" }}>
            <Button
                kind={check ? "secondary" : (kind ?? "secondary")}
                icon={check ? "times" : icon ?? "times"}
                iconColor={check ? undefined : iconColor}
                onClick={() => setCheck(!check)}
                floated={floated ?? "left"}
                content={iconOnly ? undefined : check ? undefined : t(cancelText ?? "cancel")}
                tooltip={tooltipInitialButton ? { tooltipText: check ? t(cancelText ?? "cancel") : tooltipInitialButton, position: "bottom" } : undefined}
            />
            {check &&
                <Button
                    kind="primary"
                    icon="check"
                    onClick={cancelProcess}
                    floated={floated ?? "left"}
                    content={t(confirmText ?? "yes, cancel process")}
                />
            }
            {children}
        </Conatiner>

    )
}

export default CancelWithConfirmation;