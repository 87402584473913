import { ColumnFiltersState, RowSelectionState, SortingState, Updater } from "@tanstack/react-table";
import * as _ from "lodash";
import { reducerBatchJob, reducerSetIfChanged, reducerUpdater } from "services/Helpers";
import { ColumnDefintion, LoadingDataTableMinimalElementType, LoadingDataTableSpecialButtonProps } from "./LoadingDataTable";

export type LoadingDataTableActions<ElementType extends LoadingDataTableMinimalElementType, GetterDataType extends object> =
    | { type: "batch", value: LoadingDataTableActions<ElementType, GetterDataType>[] }
    | { type: "set_multi_select", value?: boolean }
    | { type: "set_data", value: ElementType[] }
    | { type: "set_loading", value: boolean }
    | { type: "set_expandedTable", value: boolean }
    | { type: "set_rowSelection", value: RowSelectionState }
    | { type: "set_columns", value: ColumnDefintion<ElementType>[] }
    | { type: "set_loaderData", value?: GetterDataType }
    | { type: "set_buttons", value?: LoadingDataTableSpecialButtonProps<ElementType>[] }
    | { type: "set_secondary_buttons", value?: LoadingDataTableSpecialButtonProps<ElementType>[] }
    | { type: "set_columnFilters", value: ColumnFiltersState }
    | { type: "set_sorting", value: SortingState }
    | { type: "remove_element", value: ElementType }
    | { type: "update_element", value: ElementType }
    | { type: "add_element", value: ElementType }
    | { type: "toggle_expandedTable" }
    | { type: "update_columnFilters", value: Updater<ColumnFiltersState> }
    | { type: "update_rowSelection", value: Updater<RowSelectionState> }
    | { type: "update_sorting", value: Updater<SortingState> }
    ;

export interface LoadingDataTableState<ElementType extends LoadingDataTableMinimalElementType, GetterDataType extends object> {
    data: ElementType[];
    loading: boolean;
    expandedTable: boolean;
    rowSelection: RowSelectionState;
    columns: ColumnDefintion<ElementType>[];
    loaderData?: GetterDataType;
    buttons?: LoadingDataTableSpecialButtonProps<ElementType>[];
    secondaryButtons?: LoadingDataTableSpecialButtonProps<ElementType>[];
    columnFilters: ColumnFiltersState;
    sorting: SortingState;
    multiSelect?: boolean;
}

export function loadingDataTableInitState<ElementType extends LoadingDataTableMinimalElementType, GetterDataType extends object>(): LoadingDataTableState<ElementType, GetterDataType> {
    return ({
        data: [],
        loading: false,
        expandedTable: false,
        rowSelection: {},
        columns: [],
        columnFilters: [],
        sorting: [],
    })
}

export const loadingDataTableLastRowClickTime: { id?: string, time?: number } = {};

export const loadingDataTableReducer = <ElementType extends LoadingDataTableMinimalElementType, GetterDataType extends object>(state: LoadingDataTableState<ElementType, GetterDataType>, action: LoadingDataTableActions<ElementType, GetterDataType>,): LoadingDataTableState<ElementType, GetterDataType> => {
    switch (action.type) {
        case "batch":
            return reducerBatchJob(state, action.value, loadingDataTableReducer);
        case "set_multi_select":
            return reducerSetIfChanged(state, "multiSelect", action.value);
        case "set_data":
            return reducerSetIfChanged(state, "data", action.value);
        case "set_buttons":
            return reducerSetIfChanged(state, "buttons", action.value);
        case "set_secondary_buttons":
            return reducerSetIfChanged(state, "secondaryButtons", action.value);
        case "set_columnFilters":
            return reducerSetIfChanged(state, "columnFilters", action.value);
        case "set_columns":
            return reducerSetIfChanged(state, "columns", action.value);
        case "set_expandedTable":
            return reducerSetIfChanged(state, "expandedTable", action.value);
        case "set_loaderData":
            return reducerSetIfChanged(state, "loaderData", action.value);
        case "set_loading":
            return reducerSetIfChanged(state, "loading", action.value);
        case "set_rowSelection":
            return reducerSetIfChanged(state, "rowSelection", action.value);
        case "set_sorting":
            return reducerSetIfChanged(state, "sorting", action.value);

        case "remove_element": {
            const newD = _.clone(state.data);
            const idx = _.findIndex(newD, dd => dd.id === action.value.id);
            if (idx >= 0) {
                newD.splice(idx, 1);
                return reducerSetIfChanged(state, "data", newD);
            }
            return state;
        }
        case "update_element": {
            const newD = _.clone(state.data);
            const idx = _.findIndex(newD, dd => dd.id === action.value.id);
            if (idx >= 0) {
                newD[idx] = action.value;
                return reducerSetIfChanged(state, "data", newD);
            }
            return state;
        }
        case "add_element": {
            const newD = _.clone(state.data);
            newD.unshift(action.value);
            //const idx = _.findIndex(newD, dd => dd.id === action.value.id);
            return reducerSetIfChanged(state, "data", newD);
        }
        case "toggle_expandedTable":
            return { ...state, expandedTable: !state.expandedTable }
        case "update_columnFilters":
            return reducerUpdater(state, "columnFilters", action.value);
        case "update_rowSelection": {
            //console.log("LoadingDataTableState.tsx::107 => state.rowSelection", state.rowSelection);
            // const tR = reducerUpdater(state, "rowSelection", action.value);
            const tR = {
                ...state,
                rowSelection: typeof action.value === "function" ? { ...action.value(state.multiSelect ? state.rowSelection : {}), } : action.value
            }
            const keys = Object.keys(tR.rowSelection);
            if (keys.length > 0) {
                loadingDataTableLastRowClickTime.id = keys[0];
                loadingDataTableLastRowClickTime.time = new Date().getTime();
            }
            //console.log("LoadingDataTableState.tsx::109 => tR.rowSelection", tR.rowSelection);

            return tR;
        }
        case "update_sorting":
            return reducerUpdater(state, "sorting", action.value);
    }
}