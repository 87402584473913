
import { BookmarkController, ControllerHelper, PostInteraction, SlideShowDownloadDto } from 'collaboration-service';
import CommentsRatingsView from 'components/General/CommentsRatingsView';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Button, ButtonGroup, ButtonLabeled, Divider, Image, RatingView } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { PostProps } from 'services/ApplicationState/ApplicationComponentsFactory';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { getLink } from 'services/Helpers';
import { gotoNamedRoute } from 'services/Helpers/RoutingHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { ButtonsContainer, Card, CommentsRatingsContent, GridContainer, PostRibbon, Publicated, SPCommentsRatingsBookmarks, SPContent, SPDesc, SPHeader, SPTitle } from '../PostClasses';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage, userGroups: state.userGroups });
export interface SlideshowPostProps extends PostProps, ImgI18NTranslatedComponentProps, ComponentWithMappedApplicationState<typeof mapper> {
}

interface SlideshowPostState {
    likeCount: number;
    isBookmarked: boolean;
    commentCount: number;
    ratingCount: number;
    rating?: number;
    showComments: boolean;
    show: string;
}

class SlideshowPost extends React.Component<SlideshowPostProps, SlideshowPostState> {

    constructor(p: SlideshowPostProps) {
        super(p);
        this.state = {
            likeCount: this.props.post.likeCount,
            isBookmarked: this.props.post.isBookmarked,
            commentCount: this.props.post.commentCount,
            ratingCount: this.props.post.ratingCount,
            rating: this.props.post.ratingCount ?? 0,
            showComments: false,
            show: "comments",
        };
    }

    public render() {
        const post = this.props.post as SlideShowDownloadDto;
        const { t, contentLanguage } = this.props;
        const userGroup = _.find(this.props.userGroups, g => g.id === post.group_id);
        const uri = getLink(post.media?.links, "self")?.uri;
        const headline = getTranslated(post.headlines, contentLanguage).text ?? "";
        const avatar = getLink(userGroup?.avatar?.links, "self")?.uri ?? "";
        const desc = getTranslated(post.descriptions, this.props.contentLanguage).text ?? "";

        return (
            <Card>
                <PostRibbon gradientColor1="#3C007E" gradientColor2="#8D57CB"><span>{t("Power Slides")}</span> </PostRibbon>
                <GridContainer onClick={this.openLink}>
                    {uri &&
                        <div style={{ margin: "10px" }}>
                            <Image src={uri} mediaType={post.media.mediaType} />
                        </div>
                    }
                    <SPContent>
                        <SPTitle>
                            <Image src={avatar} rounded squared width="30px" />
                            <SPHeader><SafeHTML html={headline} /></SPHeader>
                        </SPTitle>
                        <SPDesc>
                            <SafeHTML html={desc} />
                        </SPDesc>
                        <div style={{ padding: "10px" }}>
                            <Divider color="@lightGrey" />
                        </div>
                        <Publicated>
                            {t("publicated in ").toUpperCase() + userGroup?.name.toUpperCase()}
                        </Publicated>
                    </SPContent>
                </GridContainer>
                <SPCommentsRatingsBookmarks className="CommentsRatingsBookmarks">
                    <ButtonLabeled labelPosition="right" kind="secondary" label={this.state.commentCount.toString()} icon="comment" floated="left" onClick={this.showComments(!this.state.showComments)} />
                    <div style={{ display: "inline", lineHeight: "3" }}>
                        {post.languageSlides[0].slides.length + " " + t("slides")}
                    </div>
                    <div style={{ float: "right" }} >
                        <Button kind="secondary" icon={post.isBookmarked ? "bookmark outline" : "bookmark"} onClick={this.onBookmark(!post.isBookmarked)} />

                        <div onClick={this.showComments(!this.state.showComments, "ratings")} style={{ float: "right", marginTop: 10 }}>
                            <RatingView
                                rating={this.state.rating ?? post.rating}
                                maxStars={5}
                                ratingCount={this.state.ratingCount}
                                size="20px"
                            />
                        </div>
                    </div>
                </SPCommentsRatingsBookmarks>
                {this.state.showComments &&
                    <>
                        <ButtonsContainer>
                            <ButtonGroup floated="left" >
                                <Button
                                    icon="comment"
                                    //size={this.getButtonSize(theme)}
                                    content={this.state.commentCount + " " + (post.commentCount === 1 ? t("comment") : t("comments"))}
                                    kind={this.state.show === "comments" ? "secondary" : "tertiary"}
                                    onClick={this.onShow("comments")} />

                                <Button
                                    icon="star half"
                                    //size={this.getButtonSize(theme)}
                                    content={this.state.ratingCount + " " + (post.ratingCount === 1 ? t("rating") : t("ratings"))}
                                    kind={this.state.show === "ratings" ? "secondary" : "tertiary"}
                                    onClick={this.onShow("ratings")} />
                            </ButtonGroup>
                        </ButtonsContainer>
                        <CommentsRatingsContent>
                            {this.state.show === "comments" &&
                                <CommentsRatingsView
                                    post={post}
                                    onCountChanged={this.commentCountChanged}
                                    noLeftBorder
                                    getIncoming={PostInteraction.GetPostComments}
                                    addCommentRating={PostInteraction.AddPostComment}
                                    onDeleteComment={PostInteraction.DeletePostComment}
                                    onSubmitComment={PostInteraction.UpdatePostComment}
                                />
                            }
                            {this.state.show === "ratings" &&
                                <CommentsRatingsView
                                    post={post}
                                    onCountChanged={this.ratingCountChanged}
                                    noLeftBorder
                                    asRating
                                    getIncoming={PostInteraction.GetPostRatings}
                                    addCommentRating={PostInteraction.AddPostRating}
                                />
                            }
                        </CommentsRatingsContent>
                    </>
                }
            </Card>
        );
    }
    private openLink = () => {
        gotoNamedRoute("slideshow_slides_show", { id: this.props.post.id });
    }
    private onShow = (show: string) => () => {
        console.log('show', show);

        this.setState({ show });
    };
    private ratingCountChanged = (ratingCount: number, rating?: number) => {
        let value = rating;
        if (value)
            value = this.props.post.rating + value / ratingCount;
        this.setState({ ratingCount, rating: value });
    }
    private commentCountChanged = (commentCount: number) => {
        this.setState({ commentCount });
    }
    private showComments = (showComments: boolean, show: string = "comments") => () => {
        this.setState({ showComments, show });
    };
    private onBookmark = (isBookmarked: boolean) => _.debounce(async () => {
        await ControllerHelper.singleCall({ postid: this.props.post.id }, isBookmarked ? BookmarkController.PostBookmark : BookmarkController.RemoveBookmark);
        this.setState({ isBookmarked });
    }, 500);
}

export default translate("feed")(connect(mapper)(SlideshowPost));