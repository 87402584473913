import { ColorScheme } from "imaginarity-statistics";

interface FilteredImage {
    opacity?: number;
    filter?: string;
    url: string;
}
export interface QuizTheme {
    headerTitle: string;
    headerImage: string;
    showTableView: boolean;
    headerRightImage: FilteredImage;
    noCategoryImage: string;
    darkMode: boolean;
    backgroundColor: string;
    color: string;
    quizAccent: string;
    statsColor: ColorScheme[];
    showTooltips: boolean;
}

export const quizTheme: QuizTheme = {
    backgroundColor: "@mainBackground", //backgroundcolor
    color: "@darkerGrey", //fontColor
    darkMode: false, //true if light backgroundcolor
    headerRightImage: { //banner right image
        opacity: 1,
        filter: "grayscale(0)",
        url: "https://cdn.imaginarity.com/public/projectImages/imgQuizHeaderRight.png"
    },
    headerTitle: "imaginarity quiz", //quiz banner title
    headerImage: "https://cdn.imaginarity.com/public/projectImages/imgQuizHeaderImage.svg", //quiz banner left image (squared)
    noCategoryImage: "https://cdn.imaginarity.com/public/projectImages/imgNoImageAvailable.svg", //alternative image if no image is set
    showTableView: true, //true if you want to see the button tableview
    showTooltips: true,
    quizAccent: "@accent",
    statsColor: [
        { name: "points, time, possible points", colors: ["#019300", "#004B93", "#e7d107"] },
        { name: "count, correct", colors: ["#e7d107", "#019300",] },
    ]
}

