import * as _ from "lodash";

export interface StateTransition<States, UserData> {
    delta: (stateMachine: StateMachine<States, UserData>, userdata?: UserData) => boolean;
    toState: States;
    work?: () => void;
}

export interface StateTransitionPair<States, UserData> {
    fromState: States;
    transitions: Array<StateTransition<States, UserData>>;
}

export interface StateWork<States> {
    onEnter?: () => void;
    onLeave?: () => void;
    state: States;
}

export class StateMachine<States, UserData> {
    private stateTransitions: Array<StateTransitionPair<States, UserData>>;
    private stateWorks: Array<StateWork<States>>;
    private currentState: States;

    public constructor(startState: States) {
        this.currentState = startState;
        this.stateTransitions = [];
        this.stateWorks = [];
    }

    public get state() {
        return this.currentState;
    }

    public reduce = (userdata?: UserData) => {
        const transition = _.find(this.stateTransitions, s => s.fromState === this.currentState);
        if (transition) {
            for (let i = 0; i < transition.transitions.length; i++) {
                const t = transition.transitions[i];
                const newState = t.delta(this, userdata) ? t.toState : transition.fromState;
                if (newState !== this.currentState) {
                    const oldStateWork = _.find(this.stateWorks, w => w.state === this.currentState);
                    if (oldStateWork && oldStateWork.onLeave)
                        oldStateWork.onLeave();
                    if (t.work)
                        t.work();
                    this.currentState = newState;
                    const newStateWork = _.find(this.stateWorks, w => w.state === newState);
                    if (newStateWork && newStateWork.onEnter)
                        newStateWork.onEnter();
                    break;
                }
            }
        }
        else
            throw new Error("Could not find state!");
        return this.currentState;
    }

    public reduceComplete = (userdata?: UserData) => {
        let newState: States;
        let oldState: States;

        do {
            oldState = this.currentState;
            newState = this.reduce(userdata);

        } while (newState !== oldState);

        return newState;
    }

    public addWork = (state: States, onEnter: (() => void) | undefined, onLeave: (() => void) | undefined) => {
        this.stateWorks.push({ state, onEnter, onLeave });
    }

    public addTransition = (fromState: States, delta: (stateMachine: StateMachine<States, UserData>, userdata?: UserData) => boolean, toState: States, work?: () => void) => {
        const transition = _.find(this.stateTransitions, s => s.fromState === fromState);
        if (transition)
            transition.transitions.push({ delta, toState, work });
        else
            this.stateTransitions.push({
                fromState, transitions: [{ delta, toState, work }]
            });
    }

    public addWorks = (works: Array<{ state: States, onEnter?: () => void, onLeave?: () => void }>) => {
        _.forEach(works, w => this.addWork(w.state, w.onEnter, w.onLeave));
    }

    public addTransitions = (transitions: Array<{ fromState: States, delta: (stateMachine: StateMachine<States, UserData>, userdata?: UserData) => boolean, toState: States, work?: () => void }>) => {
        _.forEach(transitions, t => {
            this.addTransition(t.fromState, t.delta, t.toState, t.work);
        });
    }
}