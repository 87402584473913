import { ControllerHelper, EventController, EventUploadDto, PostDownloadDto } from "collaboration-service";
import * as _ from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "services/ApplicationState/ApplicationState";
import { getMediaLink } from "services/Helpers/MediaHelpers";
import { useImgI18N } from "services/ImgI18N";
import { podcastTheme } from "./PodcastMain";
import PodcastPlayer from "./PodcastPlayer";

interface PodcastContentProps {
    namespace?: string;
    src: string;
    currentPost: PostDownloadDto;
    mobileView: boolean;
    onEnded?: () => void;
    noAutoplay?: boolean;
    onTimeUpdate?: { percentage: number, callback: () => void };
    stopAnimations?: boolean;
}

export const setAudioDefaultTrackByLngPodcast = (aud: HTMLAudioElement | null | undefined, lng: string | undefined) => {
    if (aud && aud.readyState === 4) {
        const tracks = aud.textTracks;

        if (lng) {
            const t = tracks.getTrackById(lng);
            if (t) {
                _.forEach(tracks, tt => {
                    tt.mode = tt.id === t.id ? "showing" : "disabled";
                });
            }
        }
    }
}

const PodcastContent = (p: PodcastContentProps) => {

    const { src, onEnded, namespace, onTimeUpdate, currentPost, stopAnimations, mobileView } = p;
    const contentLanguage = useSelector((s: ApplicationState) => s.contentLanguage);
    const { currentLanguage } = useImgI18N(namespace ?? "Podcast");
    const audioRef = React.useRef<HTMLAudioElement | null>(null);
    const thumb = (currentPost.media?.thumbnails ?? [])[0] ?? currentPost.media;
    const imgUrl = getMediaLink(thumb, t => t) ?? podcastTheme.noPreviewPodcastImage;
    const [, setCallOnTimeUpdate] = React.useState<boolean>(false);

    const rs = audioRef.current?.readyState ?? 0;
    const lng = contentLanguage ?? currentLanguage;

    React.useEffect(() => {
        if (rs === 4)
            setAudioDefaultTrackByLngPodcast(audioRef.current, lng);
    }, [audioRef, rs, lng]);

    React.useEffect(() => {
        setCallOnTimeUpdate(false);
    }, [onTimeUpdate, src]);

    const finishedPodcast = React.useMemo(() => () => {
        if (currentPost) {
            const ev: EventUploadDto = {
                eventType: "Finished",
                group_id: currentPost.group_id,
                payload: {
                    "PostId": currentPost.id,
                }
            };
            ControllerHelper.singleCall({ ev, createRestriction: { checkGroupId: true, checkPayload: ["PostId"], checkUserId: true, maxAgeInSeconds: 10 } }, EventController.PostEventWithRestriction);
        }
    }, [currentPost])

    const update = React.useMemo(() => ({ percentage: 90, callback: finishedPodcast }), [finishedPodcast]);

    return (
        <React.Fragment>
            {src === undefined ?
                <h3 style={{ color: podcastTheme.podcastAccent, margin: "50px 0 20px 0", padding: mobileView ? "0 10px" : undefined }}>An error has occurred, could not load podcast url</h3>
                :
                <PodcastPlayer
                    src={src}
                    imgUrl={imgUrl}
                    onEnded={onEnded}
                    onTimeUpdate={update}
                    stopAnimations={stopAnimations}
                />
            }
        </React.Fragment >
    )
}
export default PodcastContent;