import { shallowEqual } from "react-redux";
import { ComponentWithMappedApplicationState } from "services/ApplicationState/HelperInterfaces";
import { ApplicationState } from "../ApplicationState/ApplicationState";
/*
export function stateConnected<T extends ComponentType<Matching<{ state: any; } & DispatchProp<AnyAction>, GetProps<T>>>>(comp: T) {
    return connect((state: ApplicationState) => ({ state }))(comp);
}
*/
export const mapState = (state: ApplicationState) => ({ state });

export type ComponentWithApplicationState = ComponentWithMappedApplicationState<typeof mapState>;

export const shallowEqualWithoutState = <T extends { state: ApplicationState }>(left: T, right: T) => {
    const { state: x, ...lprops } = left;
    const { state: y, ...rprops } = right;
    return shallowEqual(lprops, rprops);
}

export function saveToken(token: string | null) {
    return new Promise<void>((res, rej) => {
        if ("localStorage" in window && localStorage !== undefined) {
            if (token)
                localStorage.setItem("token", token);
            else
                localStorage.removeItem("token");
            res();
        } else {
            if ("caches" in window && caches !== undefined) {
                caches.open("img-login").then(
                    (cache: Cache) => {
                        if (cache) {
                            if (token)
                                cache.put("/token", new Response(token)).then(res);
                            else
                                cache.delete("/token").then(() => res());
                        }
                        else {
                            rej("Cache undefined");
                        }
                    },
                    () => rej("Could not open cache"));
            }
            else
                rej("Cache not supported");
        }
    });
}

interface TokenResult {
    error?: string;
    token?: string;
}

export function checkForCredentials(): Promise<TokenResult> {
    return new Promise<TokenResult>((res) => {
        if ("localStorage" in window && localStorage !== undefined) {
            res({ token: localStorage.getItem("token") ?? undefined });
        }
        else {
            if ("caches" in window && caches !== undefined) {
                caches.open("img-login").then(
                    (cache: Cache) => {
                        if (cache) {
                            cache.match("/token").then(
                                r => {
                                    if (r)
                                        r.text().then(
                                            token => {
                                                res({ token });
                                            },
                                            () => res({ error: "Invalid token" }));
                                    else
                                        res({ error: "Token undefined" });
                                },
                                () => res({ error: "No token found" }));
                        }
                        else {
                            res({ error: "Cache undefined" });
                        }
                    },
                    () => res({ error: "Could not open cache" }));
            }
            else
                res({ error: "Cache not supported" })
        }
    });
}