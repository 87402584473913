import { ControllerHelper } from "collaboration-service";
import controllerFactory from "collaboration-service/dist/controllers/ControllersFactory";
import memoizee from "memoizee";
import * as _ from "lodash";

interface BackendCallResolve {
    (data: any[]): void;
}
interface BackendCallReject {
    (error: any): void;
}

interface BackendCall {
    filter: any;
    mappings?: Mapping[];
    waiters?: Array<{ res: BackendCallResolve, rej: BackendCallReject }>;
    data?: any[];
}

interface Mapping {
    element: string;
    properties: PropertyMapping[];
}

interface PropertyMapping {
    type?: string;
    propertyName?: string;
    propertyPredicate?: string;
    targetName: string;
}

class BackendCallCache {
    private executeBackend = (controller: string, method: string, filter: any, mappings?: Mapping[]): Promise<any[]> => {
        const newEle: BackendCall = {
            filter,
            mappings: mappings,
            waiters: []
        };
        return new Promise<any[]>((resOut, rejOut) => {
            const res = (data: any[]) => {
                resOut(data);
                _.forEach(newEle.waiters, w => w.res(data));
                newEle.waiters = [];
            };
            const rej = (reason: any) => {
                rejOut(reason);
                _.forEach(newEle.waiters, w => w.rej(reason));
                newEle.waiters = [];
            }
            const ctr = controllerFactory(controller);
            if (!ctr)
                rej(`could not find controller ${controller}`);
            const getData = ControllerHelper.promise<any, any>({ filter }, ctr[method]);
            if (mappings) {
                const fillTemplate = (templateString: string, templateVars: any) => {
                    // eslint-disable-next-line
                    return new Function("return `" + templateString + "`;").call(templateVars);
                };
                getData.then((data: any) => {
                    const mappingArray = mappings as Mapping[];
                    const newData: any[] = [];
                    _.forEach(data, d => {
                        const newDataElement: any = Object.assign({}, d);
                        _.forEach(mappingArray, m => {
                            if (d.hasOwnProperty(m.element)) {
                                const element = d[m.element];
                                _.forEach(m.properties, p => {
                                    if (newDataElement.hasOwnProperty(p.targetName))
                                        delete newDataElement[p.targetName];
                                    if (p.type === "stringFormat") {
                                        if (p.propertyPredicate)
                                            newDataElement[p.targetName] = fillTemplate(p.propertyPredicate, element);
                                    } else {
                                        if (p.propertyName)
                                            newDataElement[p.targetName] = element[p.propertyName];
                                    }
                                });
                            }
                        });
                        newData.push(newDataElement);
                    });
                    newEle.data = newData;
                    res(newData);
                }, rej);
            }
            else
                getData.then((data: any) => {
                    newEle.data = data;
                    res(data);
                }, rej);
        });
    }

    public execute = memoizee(this.executeBackend, {
        length: 4, maxAge: 60000, normalizer: (args: any[]) => {
            return args[0] + args[1] + JSON.stringify(args[2]);
        }
    });
}

export default BackendCallCache;