import { ControllerHelper, GameController, GameResultDto } from 'collaboration-service';
import { format } from "date-fns";
import { Icon, ImgThemeConsumer, Label, LoaderInline, Table, TableBody, TableCell, TableHead, TableRow } from 'imaginarity-react-ui';
import { DiagramData, DiagramType, ImgStatisticsConfig, LineDiagramData, RenderDiagram } from 'imaginarity-statistics';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { ImgI18N, ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { quizTheme } from 'views/Quiz/QuizMain';
import { QuizSC } from 'views/Quiz/QuizSC';



ImgStatisticsConfig.getInstance().setFormatter("QuizStatDate", (data: any, payload?: any) => {
    if (!data)
        return "Ø";
    const inst = ImgI18N.getInstance();
    const opt = { locale: inst.languageLocals[inst.currentLanguage]?.locale };
    if (!payload)
        return format(new Date(data), "Pp", opt);
    return format(new Date(data), payload.format, opt);
});

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage });

export interface QuizStatsProps extends ImgI18NTranslatedComponentProps, ComponentWithMappedApplicationState<typeof mapper> {
    id: string;
    height?: number;
    isMobile?: boolean;
}

export interface QuizStatsState {
    results?: GameResultDto[];
    highscore?: GameResultDto[];
    loading: boolean;
    diagram?: DiagramData[];
}

class QuizStats extends React.Component<QuizStatsProps, QuizStatsState> {
    constructor(props: QuizStatsProps) {
        super(props);

        this.state = {
            loading: true,
        }
    }

    private colorsPTP = _.find(quizTheme.statsColor, c => c.name === "points, time, possible points");
    private colorsCC = _.find(quizTheme.statsColor, c => c.name === "count, correct");

    async componentDidMount() {

        const quizId = this.props.id;
        const [highscore, results] = await ControllerHelper
            .addCall({ quizId }, GameController.GetGameHighScoreForQuiz, true)
            .addCall({ quizId }, GameController.GetGameResultsForQuiz, true)
            .execute<GameResultDto[], GameResultDto[]>();

        const diagram: LineDiagramData[] = [];
        if (results && results.length > 0) {
            const res = _.map(results, r => {
                const { when, ...rest } = r;
                return ({ when, ...rest });
            });
            const type: DiagramType = results.length < 3 ? "Bar" : "Line";
            const tr = results.length < 2 ? 0 : Math.min(results.length * 15, 60);

            const createdDiagrams: LineDiagramData[] = [
                {
                    id: "0",
                    colors: this.colorsPTP,
                    url: res,
                    title: this.props.t("correct/wrong"),
                    groupBy: ["when"],
                    groupByFormatters: {
                        when: { type: "QuizStatDate", payload: { format: "P" } }
                    },
                    aggregators: [
                        {
                            label: this.props.t("points"),
                            type: "sum",
                            element: "points",
                        },
                        {
                            label: this.props.t("time [min]"),
                            type: "sum",
                            element: "timeTaken",
                            factor: 1.0 / (1000.0 * 60.0),
                            digits: 1
                        },
                        {
                            label: this.props.t("max. points"),
                            type: "sum",
                            element: "pointsPossible",
                        },
                    ],
                    type,
                    showAxisPointer: true,
                    layout: { w: 4, h: 2, x: 9, y: 0 },
                    axisX: {
                        show: true,
                        tickRotation: tr,
                        label: {
                            show: true,
                            fontSize: 10,
                        }
                    },
                    axisY: {
                        show: true,
                        label: {
                            show: true,
                            fontSize: 10,
                            padding: {
                                right: 5,
                            }
                        }
                    },
                    area: {
                        show: true,
                        opacity: 3.0,
                    },
                    margin: {
                        top: 20,
                    },
                    padding: {
                        top: 15,
                        bottom: 0,
                        right: 0,
                        left: 0,
                    },

                },
                {
                    id: "1",
                    colors: this.colorsCC,
                    url: res,
                    title: this.props.t("correct/wrong"),
                    groupBy: ["when"],
                    groupByFormatters: {
                        when:
                        {
                            type: "QuizStatDate",
                            payload: { format: "P" },
                        }
                    },
                    aggregators: [
                        {
                            label: this.props.t("count"),
                            type: "sum",
                            element: "questionsCount",
                        },
                        {
                            label: this.props.t("correct"),
                            type: "sum",
                            element: "rightAnswersCount",
                        },
                    ],
                    type,
                    showAxisPointer: true,
                    layout: { w: 4, h: 2, x: 9, y: 0 },
                    axisX: {
                        show: true,
                        tickRotation: tr,
                        label: {
                            show: true,
                            fontSize: 10,
                        }
                    },
                    axisY: {
                        show: true,
                        label: {
                            show: true,
                            fontSize: 10,
                            padding: {
                                right: 5,
                            }
                        }
                    },
                    area: {
                        show: true,
                        opacity: 3.0,
                    },
                    margin: {
                        top: 30,
                    },
                    padding: {
                        top: 5,
                        bottom: 0,
                        right: 0,
                        left: 0,
                    },
                },
            ];
            diagram.push(...createdDiagrams);
        }

        this.setState({ highscore, results, loading: false, diagram });
    }

    public render() {
        const { highscore, diagram, loading } = this.state;
        const height = this.props.isMobile ? "auto" : this.props.height ? (this.props.height - 42 + "px") : "auto"
        return (
            <ImgThemeConsumer>
                {(theme) => {
                    return (
                        <QuizSC.StatsContainer height={height}>
                            {highscore && highscore.length > 0 &&
                                <>
                                    <QuizSC.StatsHL noBorder>
                                        <Icon name='table' color={!quizTheme.darkMode ? "@accent" : quizTheme.color} marginTop={2} style={{ float: "left", marginRight: 5 }} />
                                        {this.props.t("quiz highscore")}
                                    </QuizSC.StatsHL>
                                    <QuizSC.QSHighScoreTable darkMode={!!quizTheme.darkMode}>
                                        <Table>
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell>#</TableCell>
                                                    <TableCell>{this.props.t("player")}</TableCell>
                                                    <TableCell>{this.props.t("score")} </TableCell>
                                                    <TableCell>{this.props.t("time needed")} </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {_.map(highscore, (h, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            <TableCell>{i + 1}</TableCell>
                                                            <TableCell>{h.user.firstName + " " + h.user.secondName}</TableCell>
                                                            <TableCell>
                                                                {this.props.t("{{value}} %", { value: (Math.round(h.percentPoints * 100) / 100) })}
                                                            </TableCell>
                                                            <TableCell>
                                                                {this.props.t("{{seconds}} sec.", { seconds: (Math.round(h.timeTaken / 10) / 100) })}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </QuizSC.QSHighScoreTable>
                                </>
                            }
                            {!loading &&

                                <>
                                    {diagram && diagram.length !== 0 &&
                                        <>
                                            <QuizSC.StatsHL>
                                                <Icon name='chart bar' color={!quizTheme.darkMode ? "@accent" : quizTheme.color} marginTop={2} style={{ float: "left", marginRight: 5 }} />
                                                {this.props.t("personal quiz statistics")}
                                            </QuizSC.StatsHL>
                                            <QuizSC.DiagramOuterContainer>
                                                <QuizSC.LegendTop >
                                                    <Label icon="circle dot" smaller content={this.props.t("points")} iconColor={this.colorsPTP?.colors[0]} marginRight={5} />
                                                    <Label icon="circle dot" smaller content={this.props.t("time taken in min")} iconColor={this.colorsPTP?.colors[1]} marginRight={5} />
                                                    <Label icon="circle dot" smaller content={this.props.t("possible points")} iconColor={this.colorsPTP?.colors[2]} marginRight={10} />
                                                </QuizSC.LegendTop>
                                                {_.map(diagram, dia =>
                                                    <QuizSC.DiagramContainer key={dia.id}>
                                                        {RenderDiagram({ dia })}
                                                    </QuizSC.DiagramContainer>
                                                )}
                                                <QuizSC.LegendBottom>
                                                    <Label icon="circle dot" smaller content={this.props.t("question count")} iconColor={this.colorsCC?.colors[0]} marginRight={5} />
                                                    <Label icon="circle dot" smaller content={this.props.t("correct answers")} iconColor={this.colorsCC?.colors[1]} marginRight={10} />
                                                </QuizSC.LegendBottom>
                                            </QuizSC.DiagramOuterContainer>
                                        </>
                                    }
                                    {(diagram === undefined || diagram?.length === 0) &&
                                        <div>{this.props.t("no results available")}</div>

                                    }
                                </>
                            }
                            {loading &&
                                <LoaderInline
                                    infoText={this.props.t("loading")}
                                    animationColor={"@accent"}
                                    backgroundColor={"@veryLightGrey"}
                                    textColor={"@mainForeground"}
                                    active
                                />
                            }
                        </QuizSC.StatsContainer>
                    );
                }}
            </ImgThemeConsumer>
        );
    }
}

export default translate("feed")(connect(mapper)(QuizStats));
