import * as React from 'react';
import { QuizSC as T } from '../QuizSC';
import ImageWithAreas from './ImageWithAreas';
import { QuestionImplementationProps } from './Question';

class MarkAreaInPictureQuestion extends React.Component<QuestionImplementationProps> {

    public render() {
        const { loaded } = this.props;
        return (
            <>
                <T.Image>
                    {loaded && loaded.length > 0 &&
                        <ImageWithAreas {...this.props} />
                    }
                </T.Image>
            </>
        );
    }
}
export default MarkAreaInPictureQuestion;