import { ControllerHelper, EventController, EventUploadDto, PlaylistItemStateDownloadDto, PostDownloadDto } from 'collaboration-service';
import { Button, getColor, Icon, Loader, styled } from 'imaginarity-react-ui';
import * as React from 'react';
import { useImgI18N } from 'services/ImgI18N';


const Info = styled.div`
    line-height: 40px;
    float: right;
    color: ${p => getColor(p, "@accentGreen")};
    margin-left: 10px;
`;
const Outer = styled.div`
    margin-left: 5px;
    float: right;
    transition: all 0.2s ease-out;
    margin-right: -10px;
    overflow: hidden;
`;

interface MarkAsReadButtonProps {
    playlistItemState: PlaylistItemStateDownloadDto;
    playlistId?: string;
    item: PostDownloadDto;
    lastAction?: () => Promise<void>;
}

const MarkAsReadButton = (p: MarkAsReadButtonProps) => {
    const { item, playlistItemState, playlistId, lastAction } = p;
    const { t } = useImgI18N('playlist');
    const [alreadyDone, setAlreadyDone] = React.useState<boolean>(playlistItemState.state === "Finished");
    const [loaderActive, setLoaderActive] = React.useState<boolean>(false);

    const markAsDone = React.useMemo(() => () => {
        setLoaderActive(true);
        if (playlistId) {
            const ev: EventUploadDto = {
                eventType: "PostViewed",
                group_id: item.group_id,
                payload: {
                    "PostId": item.id,
                    "From": "playlist",
                    "PlaylistId": playlistId,
                    "NodeId": playlistItemState.playListNodeId,
                }
            };
            ControllerHelper.singleCall({ ev }, EventController.PostEvent);
            const ev1: EventUploadDto = {
                eventType: "Finished",
                group_id: item.group_id,
                payload: {
                    "PostId": item.id,
                    "From": "playlist",
                    "PlaylistId": playlistId,
                    "NodeId": playlistItemState.playListNodeId,
                }
            };
            ControllerHelper.singleCall({ ev: ev1 }, EventController.PostEvent);
        }

        setTimeout(() => {
            setAlreadyDone(true);
            setLoaderActive(false);
            if (lastAction)
                lastAction();
        }, 5000);
    }, [playlistId, playlistItemState, item.id, item.group_id, lastAction]);

    //const eleType = item?.type === "FileEntry" ? documentKindByType(item?.media?.mediaType) : undefined;

    if (alreadyDone || playlistId === undefined)
        return null;

    return (
        <>
            <Loader active={loaderActive} size='small' light />
            <Outer>
                {alreadyDone ?
                    <Info>
                        {`${item.type} is marked as done`}
                        <Icon name="thumb up" style={{ float: "right", marginLeft: 5 }} size="20px" marginTop={4} color="@accentGreen" />
                    </Info>
                    :
                    <Button
                        icon="check"
                        content={t("mark as done")}
                        kind={"primary"}
                        floated="right"
                        onClick={markAsDone}
                    />
                }
            </Outer>
        </>
    );
}
export default MarkAsReadButton;