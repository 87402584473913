import { Dimmer, Icon, LottieIcon, useBelowBreakpoint } from 'imaginarity-react-ui';
import * as _ from "lodash";
import React, { CSSProperties } from 'react';
import { useGlobalEvent, useUrlPreload } from 'services/Helpers';
import { useImgI18N } from 'services/ImgI18N';
import { ImageSliderImgSC as T } from './ImageSliderImgSC';
import ImageCarousel from './ImageCarousel';

interface ImageSliderImgFlexProps {
    images: { url: string }[] | { url: string };
    width: number;
    height: number;
    isIphone?: boolean;
    onOpen?: (open: boolean) => void;
    idx?: (id: number) => void;
}

const ImageSliderImgFlex: React.FC<ImageSliderImgFlexProps> = ({ images, width, height, isIphone, onOpen, idx }) => {
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [isFullscreen, setIsFullscreen] = React.useState(false);
    const urls = React.useMemo(() => _.map(images, (i: { url: string }) => i.url), [images]);

    const { imageUrls: loadedImages, loading } = useUrlPreload(urls);
    const [startX, setStartX] = React.useState<number | null>(null);
    const [isDragging, setIsDragging] = React.useState(false);
    const isMobile = useBelowBreakpoint("tablet");
    const { t } = useImgI18N("feed");
    const imageCount = loadedImages?.length || 0;

    const toggleFullscreen = React.useCallback(() => {
        const newFullscreenState = !isFullscreen;
        setIsFullscreen(newFullscreenState);
        if (onOpen) {
            onOpen(newFullscreenState);
        }
    }, [isFullscreen, onOpen]);

    const closeFullscreen = React.useCallback(() => {
        if (isFullscreen) {
            setIsFullscreen(false);
            if (onOpen) {
                onOpen(false);
            }
        }
    }, [isFullscreen, onOpen]);

    const handleTouchStart = React.useCallback((e: React.TouchEvent) => {
        setStartX(e.touches[0].clientX);
    }, []);

    const handleMouseDown = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setStartX(e.clientX);
        setIsDragging(true);
    }, []);

    const handleMouseMove = React.useCallback((e: React.MouseEvent) => {
        if (!isDragging || startX === null) return;
    }, [isDragging, startX]);

    const handleSwipeEnd = React.useCallback((endX: number) => {
        if (startX === null) return;
        const deltaX = startX - endX;
        if (deltaX > 50 && currentIndex < imageCount - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        } else if (deltaX < -50 && currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
        setStartX(null);
    }, [startX, currentIndex, imageCount]);

    const handleMouseUp = React.useCallback((e: React.MouseEvent) => {
        if (!isDragging) return;

        if (startX !== null && Math.abs(startX - e.clientX) < 10) {
            toggleFullscreen();
        } else {
            handleSwipeEnd(e.clientX);
        }
        setIsDragging(false);
        setStartX(null);
    }, [isDragging, startX, handleSwipeEnd, toggleFullscreen]);


    const handleMouseLeave = React.useCallback((e: React.MouseEvent) => {
        if (isDragging) {
            handleSwipeEnd(e.clientX);
            setIsDragging(false);
        }
    }, [isDragging, handleSwipeEnd]);

    React.useEffect(() => {
        if (isIphone) {
            document.body.style.overflow = isFullscreen ? 'hidden' : 'auto';
        }
    }, [isFullscreen, isIphone]);

    // deny zoom on iPhone
    useGlobalEvent("touchstart", (e) => {
        if (isIphone && (e as TouchEvent).touches?.length > 1) {
            e.preventDefault();
        }
    }, { passive: false });

    // deny zoom on iPhone
    useGlobalEvent("gesturestart", (e) => {
        if (isIphone) {
            e.preventDefault();
        }
    });

    let lastDistance = React.useRef<number | null>(null);

    const getDistance = React.useCallback((touches: TouchList) => {
        if (touches.length < 2) return 0;
        const [touch1, touch2] = Array.from(touches);
        const dx = touch2.clientX - touch1.clientX;
        const dy = touch2.clientY - touch1.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    }, []);

    React.useEffect(() => {
        if (!isFullscreen || !isMobile) {
            lastDistance.current = null;
        }
    }, [isFullscreen, isMobile]);

    const handleTouchMove = React.useCallback(
        (e: TouchEvent) => {
            if (!isFullscreen || !isMobile || e.touches.length <= 1) return;
            const distance = getDistance(e.touches);

            lastDistance.current = distance;
        },
        [getDistance, isFullscreen, isMobile]
    );

    const handleTouchEnd = React.useCallback(() => {
        lastDistance.current = null;
    }, []);

    React.useEffect(() => {
        if (!isFullscreen || !isMobile) {
            lastDistance.current = null;
            return;
        }

        const handleTouchMoveListener = (e: Event) => handleTouchMove(e as TouchEvent);
        const handleTouchEndListener = () => handleTouchEnd();
        window.addEventListener("touchmove", handleTouchMoveListener, { passive: false });
        window.addEventListener("touchend", handleTouchEndListener);
        return () => {
            window.removeEventListener("touchmove", handleTouchMoveListener);
            window.removeEventListener("touchend", handleTouchEndListener);
        };
    }, [isFullscreen, isMobile, handleTouchMove, handleTouchEnd]);

    const handleNextImage = React.useCallback(() => {
        setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, imageCount - 1));
    }, [imageCount]);

    const handlePrevImage = React.useCallback(() => {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }, []);

    React.useEffect(() => {
        if (isMobile) return;
        if (!isFullscreen) return;

        const handleKeyDown = (e: KeyboardEvent) => {
            switch (e.key) {
                case "ArrowRight":
                    if (currentIndex < imageCount - 1) {
                        setCurrentIndex((prev) => prev + 1);
                    }
                    break;
                case "ArrowLeft":
                    if (currentIndex > 0) {
                        setCurrentIndex((prev) => prev - 1);
                    }
                    break;
                case "Escape":
                    closeFullscreen();
                    break;
                default:
                    break;
            }
        };
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isMobile, currentIndex, imageCount, closeFullscreen, isFullscreen]);

    const dotNavigation = React.useMemo(() => (
        _.map(loadedImages, (_, i) => (
            <T.DotNavigationSpan
                active={i === currentIndex}
                onClick={i === currentIndex ? undefined : () => setCurrentIndex(i)}
                key={i}
            >
                <Icon
                    name={i === currentIndex ? "circle dot" : "circle"}
                    color="@mainBackground"
                    size={22}
                    style={{ margin: "0 5px" }}
                />
            </T.DotNavigationSpan>
        ))
    ), [loadedImages, currentIndex]);

    const sliderTransform = React.useMemo(() => `translateX(-${currentIndex * 100}%)`, [currentIndex]);

    const handleSwipe = React.useCallback((deltaX: number) => {
        if (deltaX > 50 && currentIndex < imageCount - 1) {
            setCurrentIndex((prev) => prev + 1);
        } else if (deltaX < -50 && currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
        }
        setStartX(null);
    }, [currentIndex, imageCount]);

    const sliderStyle: CSSProperties = {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        transition: "transform 0.3s ease-in-out",
        transform: sliderTransform,
        height: "100%",
        width: "100%",
        touchAction: "none",
    };

    return (
        <T.SliderContainer width={width} height={height} style={{ maxHeight: "unset" }}>
            <div
                style={sliderStyle}
                onTouchStart={handleTouchStart}
                onTouchEnd={(e) => {
                    if (startX === null) return;
                    const deltaX = startX - e.changedTouches[0].clientX;
                    handleSwipe(deltaX);
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
            >
                {_.map(loadedImages, (image, index) => {

                    return (
                        <div
                            key={index}
                            style={{
                                flex: "0 0 100%",
                                height: "100%",
                                position: "relative",
                                overflow: "hidden",
                                touchAction: "none"
                            }}
                        >
                            <img
                                src={image}
                                alt={`Slide ${index}`}
                                style={{
                                    opacity: !loading ? 1 : 0,
                                    transition: "opacity 0.3s ease-in-out",
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "100%",
                                    objectPosition: "center",
                                    display: "block",
                                }}
                            />
                        </div>
                    );
                })}
                {loading && (
                    <T.LottieSpinner>
                        <LottieIcon
                            url={"images/lottiIcons/imgloader.json"}
                            speed={1}
                            autoplay
                            loop
                            height={100}
                            width={100}
                        />
                    </T.LottieSpinner>
                )}
            </div>
            {imageCount > 1 && (
                <>
                    {currentIndex > 0 && (
                        <T.NavButtonArea position="left" onClick={handlePrevImage} style={{ transform: isMobile ? "translateY(calc(-50% - 25px))" : "translateY(-50%)" }}>
                            <Icon name="chevron left" size="50px" color="background" />
                        </T.NavButtonArea>
                    )}
                    {currentIndex < imageCount - 1 && (
                        <T.NavButtonArea position="right" onClick={handleNextImage} style={{ transform: isMobile ? "translateY(calc(-50% - 25px))" : "translateY(-50%)" }}>
                            <Icon name="chevron right" size="50px" color="background" />
                        </T.NavButtonArea>
                    )}
                    <T.DotNavigation style={{ bottom: isMobile ? 50 : 20, zIndex: 105 }}>
                        {dotNavigation}
                    </T.DotNavigation>
                </>
            )}

            <Dimmer active={isFullscreen}>
                {isMobile ?
                    <div style={{ position: "relative" }}>
                        <ImageCarousel
                            loadedImages={loadedImages}
                            closeFullscreen={closeFullscreen}
                            curIdx={currentIndex}
                        />
                    </div>
                    : (

                        <T.MobileDimmer
                            onTouchStart={(e) => {
                                setStartX(e.touches[0].clientX);
                            }}
                            onTouchMove={(e) => {
                                if (startX === null) return;

                                const deltaX = e.touches[0].clientX - startX;
                                const container = e.currentTarget.querySelector(
                                    ".image-slider-container"
                                ) as HTMLDivElement | null;
                                if (container) {
                                    container.style.transition = "none";
                                    container.style.transform = `translateX(calc(-${currentIndex * 100}% + ${deltaX}px))`;
                                }
                            }}
                            onTouchEnd={(e) => {
                                if (startX === null) return;
                                const deltaX = startX - e.changedTouches[0].clientX;
                                handleSwipe(deltaX);
                            }}
                            onMouseDown={(e) => setStartX(e.clientX)}
                            onMouseMove={(e) => {
                                if (startX === null) return;

                                const deltaX = e.clientX - startX;
                                const container = e.currentTarget.querySelector(
                                    ".image-slider-container"
                                ) as HTMLDivElement | null;
                                if (container) {
                                    container.style.transition = "none";
                                    container.style.transform = `translateX(calc(-${currentIndex * 100}% + ${deltaX}px))`;
                                }
                            }}
                            onMouseUp={(e) => {
                                if (startX === null) return;
                                const deltaX = startX - e.clientX;
                                handleSwipe(deltaX);
                            }}
                            onMouseLeave={(e) => {
                                const container = e.currentTarget.querySelector(
                                    ".image-slider-container"
                                ) as HTMLDivElement | null;
                                if (container) {
                                    container.style.transition = "transform 0.3s ease-in-out";
                                    container.style.transform = `translateX(-${currentIndex * 100}%)`;
                                }
                                setStartX(null);
                            }}
                        >
                            <div
                                className="image-slider-container"
                                style={{
                                    width: `${imageCount * 100}%`,
                                    height: "100%",
                                    display: "flex",
                                    transform: `translateX(-${currentIndex * 100}%)`,
                                    willChange: "transform",
                                }}
                                onTouchMove={(e) => {
                                    if (startX === null) return;

                                    const deltaX = e.touches[0].clientX - startX;
                                    setStartX(null);
                                    e.currentTarget.style.transform = `translateX(calc(-${currentIndex * 100}% + ${deltaX}px))`;
                                }}
                                onTouchEnd={(e) => {
                                    if (startX === null) return;
                                    const deltaX = startX - e.changedTouches[0].clientX;
                                    handleSwipe(deltaX);
                                }}
                            >
                                {_.map(loadedImages, (image, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            flex: "0 0 100%",
                                            height: "100%",
                                            backgroundImage: `url("${image}")`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                            backgroundSize: "contain",
                                        }}
                                    />
                                ))}
                            </div>

                            {currentIndex > 0 && (
                                <T.NavButtonAreaDimmerLeft onClick={() => setCurrentIndex(currentIndex - 1)}>
                                    <Icon name="chevron left" size="50px" color="background" />
                                </T.NavButtonAreaDimmerLeft>
                            )}
                            {currentIndex < imageCount - 1 && (
                                <T.NavButtonAreaDimmerRight onClick={() => setCurrentIndex(currentIndex + 1)}>
                                    <Icon name="chevron right" size="50px" color="background" />
                                </T.NavButtonAreaDimmerRight>
                            )}
                            <T.NavButtonAreaDimmerCenter onClick={closeFullscreen}>
                                <Icon name="times" size="50px" color="@accent" />
                            </T.NavButtonAreaDimmerCenter>
                            <div style={{ position: "fixed", bottom: imageCount > 1 ? 36 : 48, right: 20, color: "#fff", textAlign: "right" }}>
                                <div>{t("press [ESC] to close")}</div>
                                {imageCount > 1 &&
                                    <div>{t("use the arrow keys to swipe back and forth")}</div>
                                }
                            </div>
                        </T.MobileDimmer>
                    )}
            </Dimmer>

        </T.SliderContainer>
    );
};

export default ImageSliderImgFlex;
