import { belowBreakpointOrEqual, fadeColor, getColor, lightenColor, styled } from "imaginarity-react-ui";

export class PorscheMomentPostSC {
    public static AvatarMouseOverContainer = styled.div<{ idx: number }>`
    position: absolute;
    top: -30px;
    right: ${p => 15 * p.idx + 40}px;
    z-index: ${p => 100 - p.idx};
    cursor: pointer;
    transform: scale(1);
    transform-origin: bottom center;
    transition: all 0.2s ease-out;
    border-radius: 20px;
    img{
        //border: 1px solid #fff;
    }
    &:hover{
        transform: scale(1.4) translateY(-1px);
        z-index: ${p => 100 + p.idx};
        img{
            box-shadow: 0 6px 8px -6px #000;
    }
    }
`;
    public static OuterContainer = styled.div<{ shadowed: boolean }>`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    width: ${p => belowBreakpointOrEqual(p, "tablet") ? "calc(100% + 10px)" : "100%"};
    margin-left: ${p => belowBreakpointOrEqual(p, "tablet") ? -5 : 0}px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    position: relative;
    ${p => p.shadowed && !belowBreakpointOrEqual(p, "tablet") && "box-shadow: 0px -5px 30px -20px #313639;"}
`;
    public static Organisation = styled.div`
    position: absolute;
    top: -20px;
    left: 100px;
    z-index: 20;
    color: ${p => getColor(p, "@darkGrey")};
    width: calc(100% - 100px);
    font-size: smaller;
`;
    public static Poster = styled.div<{ hasOrganisation: boolean }>`
    position: absolute;
    top: ${p => p.hasOrganisation ? -35 : -20}px;
    left: 100px;
    z-index: 20;
    color: ${p => getColor(p, "@darkGrey")};
    font-size: 1.1em;
    /* font-weight: bolder; */
    width: calc(100% - 100px);    
`;
    public static ButtonBar = styled.div`
    background: ${p => getColor(p, "@veryLightGrey")};
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    button>div{margin: 0;}
    box-shadow: 0 6px 8px -6px #000;
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    column-gap: 10px;
`;
    public static ButtonBarBtnContainer = styled.div<{ active: boolean, filledIcon?: boolean }>`
    background: ${p => p.active ? fadeColor(p, "@darkGrey", 8) : getColor(p, "@veryLightGrey")};
    svg{
        fill: ${p => p.filledIcon === true ? getColor(p, "@accent") : "none"};
    }
    button{
        color: ${p => getColor(p, "@darkGrey")};
    }
    &:hover{
        background: ${p => p.active ? fadeColor(p, "@darkGrey", 12) : fadeColor(p, "@darkGrey", 8)};
    }
`;
    public static PostTime = styled.div`
    padding-left: 90px;
    font-size: smaller;
`;
    public static BtnIndicator = styled.div`
float: left;
button>div>div>div>div{
        padding-left: 0;
}
`;

    public static PostContainer = styled.div<{ background: string, gradColor1: string, gradColor2: string, showCarbon: boolean }>`
        color: ${p => getColor(p, "@darkGrey")};
        width: calc(100% + 0px);
        box-shadow: 0 6px 8px -6px #000;
        transform: translateX(-0px);
        z-index: 50;
        padding: 10px;
        line-height: 20px;
        position: relative;
        margin-top: 60px;
        background-color: ${p => p.background === "" ? getColor(p, "@darkGrey") : p.background};
       ${p => p.background === "" && `
        background-image: ${p.showCarbon ? `linear-gradient(120deg, ${p.gradColor1 === "" ? getColor(p, "@accent") : p.gradColor1}, ${p.gradColor2 === "" ? "transparent" : "transparent"}), 
            linear-gradient(45deg, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 25%, transparent 25%, transparent 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)}), 
            linear-gradient(45deg, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 25%, transparent 25%, transparent 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)}), 
            linear-gradient(to bottom, ${p.gradColor2 === "" ? "rgb(5, 5, 5)" : lightenColor(p, p.gradColor2, 120)}, ${p.gradColor2 === "" ? "rgb(20, 20, 20)" : lightenColor(p, p.gradColor2, 170)})` :
                (p.gradColor1 === "" && p.gradColor2 === "") ? `none` :
                    `
                linear-gradient(120deg, ${p.gradColor1}, ${p.gradColor2})
                `};
            background-size:cover, 10px 10px, 10px 10px, 10px 5px;
            background-position: center center,0px 0px, 5px 5px, 0px 0px;
            `}
`;
    public static PostContainerStandards = styled.div<{ background: string, gradColor1: string, gradColor2: string, showCarbon: boolean }>`
        width: calc(100% + 0px);
        z-index: 50;
        //border-top: 1px solid #fff;
        background-color: ${p => p.background === "" ? getColor(p, "@darkGrey") : p.background};
        display: grid;
        grid-template-columns: repeat(4, 1fr);
       ${p => p.background === "" && `
        background-image: ${p.showCarbon ? `linear-gradient(120deg, ${p.gradColor1 === "" ? getColor(p, "@accent") : p.gradColor1}, ${p.gradColor2 === "" ? "transparent" : "transparent"}), 
            linear-gradient(45deg, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 25%, transparent 25%, transparent 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)}), 
            linear-gradient(45deg, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 25%, transparent 25%, transparent 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)}), 
            linear-gradient(to bottom, ${p.gradColor2 === "" ? "rgb(5, 5, 5)" : lightenColor(p, p.gradColor2, 120)}, ${p.gradColor2 === "" ? "rgb(20, 20, 20)" : lightenColor(p, p.gradColor2, 170)})` :
                (p.gradColor1 === "" && p.gradColor2 === "") ? `none` :
                    `
                linear-gradient(120deg, ${p.gradColor1}, ${p.gradColor2})
                `};
            background-size:cover, 10px 10px, 10px 10px, 10px 5px;
            background-position: center center,0px 0px, 5px 5px, 0px 0px;
            `}
`;
    public static AudioContainer = styled.div`
        width: 100%;
        padding: 20px;
        background-color: ${p => getColor(p, "@darkGrey")};
        background-image: linear-gradient(120deg, ${p => fadeColor(p, "@accentBlack", 70)}, ${p => fadeColor(p, "@accentBlack", 30)}), 
            linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), 
            linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), 
            linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
        background-size:cover, 10px 10px, 10px 10px, 10px 5px;
        background-position: center center,0px 0px, 5px 5px, 0px 0px;
        audio{
            outline: none;
            width: 100%;
        }
`;
    public static MediaNoMargin = styled.div`
    margin-bottom: -5px;
`;
    public static DarkenContainer = styled.div<{ trans: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: ${p => p.trans ? "linear-gradient(" + getColor(p, '@veryLightGrey') + ", " + getColor(p, '@accentBlack') + ")" : "linear-gradient(" + fadeColor(p, '@veryLightGrey', 20) + ", " + fadeColor(p, '@accentBlack', 95) + ")"};
`;
    public static TopRightFlag = styled.div`
    position: absolute;
    right: 10px;
    top: 32px;
    z-index: 500;
`;
    public static LottieHint = styled.div`
    svg{
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(20vw, 18vh) !important;
    }
`;
    public static PostContentHeadlineFlag = styled.div`
    float: right;
    margin-top: -3px;
    margin-left: 5px;
`;
    public static PostContentHeadlineText = styled.div`
    float: right;
    margin-top: 0px;
    font-weight: 400;
    font-size: 0.8em;
    margin-left: 10px;
`;

    public static PostContentHeadline = styled.div`
    padding: 10px;
    font-size: larger;
    font-weight: bolder;
    width: 100%;
    background: ${p => getColor(p, "@mainBackground")};       
    border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
    padding-top: 15px;
`;

    public static ShowInfoContainerCloseButton = styled.div`
    position: fixed;
    bottom: 50px;
    right: 0;
    svg{
        transform: scale(1.25);
    }
`;

    public static PostContent = styled.div`
    padding: 10px;
    width: 100%;
    background: ${p => getColor(p, "@mainBackground")};       
    border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
    .smallParagraph p{
        margin: 0;
    }
`;
    public static PostContentP = styled.div`
    padding: 10px;
    width: 100%;
    background: ${p => getColor(p, "@mainBackground")};       
    border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
    p{
        margin: 0 !important;
    }
`;
    public static CalculatedRestHeight = styled.div<{ rest: number }>`
    width: 100%;
    background: ${p => getColor(p, "@mainBackground")};
    height: calc(100vh - ${p => p.rest}px);
`;
    public static PostContentInfo = styled.div`
   color:  ${p => getColor(p, "@lightGrey")};
   font-size: 0.85em;
   border-top: 1px solid ${p => getColor(p, "@veryLightGrey")};
   width: calc(100% + 20px);
   margin-left: -10px;
   padding: 0 10px;
   background: ${p => fadeColor(p, "@veryLightGrey", 50)};
   line-height: 30px;
   transform: translateY(12px);
   margin-top: -12px;
   svg{
    stroke:  ${p => getColor(p, "@lightGrey")};
    }
`;
    public static PostContainerImage = styled.div`
        position: absolute;
        width: 50px;
        height: 50px;
        bottom: 10px;
        left: 10px;
        border-radius: 30px;
        background-color: ${p => getColor(p, "@veryLightGrey")};
        background-image: linear-gradient(-30deg, ${p => getColor(p, "@veryLightGrey")}, ${p => getColor(p, "@mainBackground")});
        padding: 2px;
        z-index: 10;
        box-shadow: -6px -3px 6px -5px ${p => getColor(p, "@lightGrey")}, 3px 6px 8px -6px ${p => getColor(p, "@darkGrey")};
        cursor: pointer;
`;

    public static LuxuryContainer = styled.div<{ right: number }>`
    position: absolute;
    right: ${p => p.right}px;
    top: -7px;
    z-index: 0;
`;

    public static Standard = styled.div<{ selected: boolean }>`
    //background-color: ${p => p.selected ? "rgba(0,0,0,0.2)" : "transparent"};
    color: ${p => p.selected ? getColor(p, p.theme.colors.mainBackground) : fadeColor(p, getColor(p, p.theme.colors.mainBackground), 60)};
    height: 30px;
    line-height: 30px;
    width: 100%;
    font-weight: ${p => p.selected ? "bold" : "100"};
    font-size: ${p => belowBreakpointOrEqual(p, "mobile") ? "0.65rem" : belowBreakpointOrEqual(p, "tablet") ? "0.75rem" : p.selected ? "0.85rem" : "0.75rem"};
    letter-spacing: ${p => belowBreakpointOrEqual(p, "mobile") ? "unset" : "1px"};
    text-align: center;
    text-transform: uppercase;
    position: relative;
    text-shadow: ${p => p.selected ? "1px 3px 2px #000" : "unset"};
`;
    public static MoreInfoContainer = styled.div`
    background: ${p => getColor(p, "@veryLightGrey")};
    border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
    box-shadow: 0 6px 8px -6px #000;
    button>div{margin: 0;}
`;
    public static MoreInfoContainerHeader = styled.div`
    font-weight: bolder;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
    width: 100%;
    padding-left: 10px;
`;
    public static MoreInfoContainerItems = styled.div`
    width: 100%;
    padding: 10px;
    background: #fff;
`;

    public static ReadMoreInfo = styled.div`
        color: ${p => getColor(p, "@accentBlue")};
`;
}