import * as React from 'react';
import { QuizSC as T } from './QuizSC';
export interface QuizButtonProps {
    text1: string;
    text2?: string;
    diameter?: number;
    onClick?: () => void;
    backgroundColor?: string;
    borderColor?: string;
    lightMode?: boolean;
    disabled?: boolean;
}
export interface QuizButtonState {
}

class QuizButton extends React.Component<QuizButtonProps, QuizButtonState> {
    constructor(props: QuizButtonProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        const p = this.props;
        const dia = p.diameter ?? 100;
        const calcDia = (c: number) => {
            return (dia * c / 100);
        };
        return (
            <T.PlayButton
                viewBox={"0 0 " + dia + " " + dia}
                height={dia}
                width={dia}
                onClick={p.disabled ? undefined : p.onClick}
                bgColor={p.backgroundColor}
                borderColor={p.borderColor}
                lightMode={p.lightMode ?? false}
                disabled={p.disabled}
            >
                <filter id="dropshadow" height="140%">
                    <feGaussianBlur in="SourceAlpha" stdDeviation="1.3" />
                    <feOffset dx="-0.5" dy="2" result="offsetblur" />
                    <feComponentTransfer>
                        <feFuncA type="linear" slope="0.3" />
                    </feComponentTransfer>
                    <feMerge>
                        <feMergeNode />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                <g>
                    <circle cx={calcDia(50)} cy={calcDia(50)} r={calcDia(50)} className='QuizPlayButtonBg' />
                    <circle cx={calcDia(50)} cy={calcDia(50)} r={calcDia(42)} strokeWidth={calcDia(10)} className='QuizPlayButton' />
                    <text
                        x={calcDia(50)}
                        y={p.text2 ? calcDia(50) : calcDia(58)}
                        style={{ fontSize: p.text2 ? calcDia(32) + "px" : calcDia(30) + "px", fontWeight: "bolder", filter: "url(#dropshadow)" }}
                        textAnchor="middle"
                        className="QuizPlayButtonText"
                    >
                        {p.text1}
                    </text>
                    {p.text2 &&
                        <text
                            x={calcDia(50)}
                            y={calcDia(70)}
                            style={{ fontSize: calcDia(20) + "px", fontWeight: "bolder", filter: "url(#dropshadow)" }}
                            textAnchor="middle"
                            className="QuizPlayButtonText"
                        >
                            {p.text2}
                        </text>
                    }
                </g>
            </T.PlayButton>
        );
    }
}
export default QuizButton;