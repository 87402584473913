
import { store } from "App";
import { ContentPostContentLocationDownloadDto, FileEntryDownloadDto, FixedDate, LanguageStringDownloadDto, MediaDownloadDto, PostDownloadDto, TranslationController, UserShortInfoDownloadDto } from "collaboration-service";
import { format } from "date-fns";
import { ISO_3166 } from "imaginarity-react-ui";
import * as _ from 'lodash';
import { ImgI18N, ImgI18NTranslateFunction } from "services/ImgI18N";

const motherLanguages = {
    "de": "de-DE",
    "fr": "fr-FR",
    "es": "es-ES",
    "pt": "pt-BR",
    "it": "it-IT",
    "en": "en-GB",
}

export function getTranslated<C extends { lng?: string }>(c?: C[], lang?: string, data?: any): C {
    const l = lang ?? ImgI18N.getInstance().currentLanguage;
    const baseLng = ImgI18N.getInstance().getConfig().baseLanguage;
    let value = _.find(c, (obj) => obj?.lng === l)
    if (!value) {
        const i = l.indexOf("-");
        if (i >= 0) {
            const baseL = l.substring(0, i);
            value = _.find(c, (obj) => obj?.lng === baseL);
            if (!value) {
                var motherL = (motherLanguages as any)[baseL];
                if (motherL)
                    value = _.find(c, (obj) => obj?.lng === motherL);
            }
        }
        if (!value)
            value = _.find(c, (obj) => obj?.lng === baseLng);
        if (!value) {
            const baseBaseL = baseLng.substring(0, i);
            value = _.find(c, (obj) => obj?.lng === baseBaseL);
        }

    }
    return value ?? (c && _.some(c) ? c[0] : {} as C)
}

export function getTranslatedForTube<C extends { lng?: string, contentAttribute?: string }>(c: C[] | undefined, lang: string | undefined, contentAttribute: Array<string | undefined> | undefined): C {
    const ct = getTranslated(c, lang);
    const l = ct.lng;
    const filtered = _.filter(c, obj => obj.lng === l);
    if (!contentAttribute)
        return filtered[0] ?? {} as C;
    let value: C | undefined = undefined;
    for (const ca of contentAttribute) {
        value = _.find(filtered, f => f.contentAttribute === ca);
        if (value)
            break;
    }
    return value ?? {} as C;
}


export function getTranslatedUserName(user: UserShortInfoDownloadDto, withoutTitle?: boolean) {
    if (user) {
        const i = ImgI18N.getInstance();
        if (user.title && !withoutTitle)
            return i.t(i.getConfig().standardNamespace, "{{title}} {{firstname}} {{lastname}}", { title: user.title, firstname: user.firstName, lastname: user.secondName });
        return i.t(i.getConfig().standardNamespace, "{{firstname}} {{lastname}}", { firstname: user.firstName, lastname: user.secondName });
    }
    return ""
}
export function getTranslatedUserEmail(user: UserShortInfoDownloadDto) {
    if (user) {
        const i = ImgI18N.getInstance();
        return i.t(i.getConfig().standardNamespace, "{{email}}", { email: user.email });
    }
    return ""
}


export function getTranslatedUserNameWithEmail(user: UserShortInfoDownloadDto, withoutTitle?: boolean) {
    if (user) {
        const i = ImgI18N.getInstance();
        if (user.title && !withoutTitle)
            return i.t(i.getConfig().standardNamespace, "{{title}} {{firstname}} {{lastname}} ({{email}})", { title: user.title, firstname: user.firstName, lastname: user.secondName, email: user.email });
        return i.t(i.getConfig().standardNamespace, "{{firstname}} {{lastname}} ({{email}})", { firstname: user.firstName, lastname: user.secondName, email: user.email });
    }
    return ""
}

export function getTranslatedDate(date: FixedDate) {
    return `${("0" + date.day).slice(-2)}.${("0" + date.month).slice(-2)}.${date.year}`
}

export function getTranslatedStandardDate(d: Date | string, fStr?: string) {
    const date = new Date(d);
    const opt = { locale: ImgI18N.getInstance().languageLocal() };
    return format(date, fStr ?? "P", opt);
}

export function getTranslatedOrInit<C extends { lng?: string, text: string }>(c: C[], lng: string): C {
    let cur = _.find(c, ele => ele.lng === lng);
    if (!cur) {
        cur = { lng, text: "" } as C;
        c.push(cur);
    }
    return cur;
}

export function getTranslatedExact<C extends { lng?: string }>(c?: C[], lang?: string, data?: any): C | undefined {
    const l = lang ?? ImgI18N.getInstance().currentLanguage;
    let value = _.find(c, (obj) => obj?.lng === l);
    return value;
}
export function getTranslatedExact2<C extends { lng?: string }>(c?: C[], lang?: string, data?: any): C | undefined {
    const l = lang ?? ImgI18N.getInstance().currentLanguage;
    console.log('l', l);
    console.log('c', c);

    let value = _.find(c, (obj) => obj?.lng === l);
    return value;
}

export const getOrTranslatePostLanguageStrings = async <T extends PostDownloadDto>(from?: T, lang?: string) => {
    return new Promise<T | undefined>((res, rej) => {
        let current = ImgI18N.getInstance().currentLanguage;
        if (current.includes('-')) {
            current = current.split('-')[0];
        }
        const lng = lang ? lang : current;
        if (from) {
            const hasDLng = from.descriptions === undefined || from.descriptions.length === 0 || _.findIndex(from.descriptions, d => d.lng === lng && d.text !== null && d.text !== undefined) >= 0;
            const hasHLng = _.findIndex(from.headlines, h => h.lng === lng) >= 0;
            if (!hasDLng || !hasHLng)
                TranslationController.TranslatePost({ post: from, to: lng }, t => res(t as T), rej);
            else
                res(undefined);
        }
        else
            res(undefined);
    });
}


export const translateDate = (date: string | Date, formatString: string) => {
    return format(new Date(date), formatString, { locale: ImgI18N.getInstance().languageLocal() });
}

export function t_for(namespace: string) {
    return (val: string, data?: { [key: string]: any; }) => {
        return ImgI18N.getInstance().t(namespace, val.toLowerCase(), data);
    };
}

type GetCMSContentProps = { fileEntry?: FileEntryDownloadDto, location?: ContentPostContentLocationDownloadDto, exact?: boolean };

// export function getCMSContent(p: GetCMSContentProps & { language?: string }) {
//     const { language, ...rest } = p;
//     const state = store.getState();
//     const lng = language ?? (state.contentLanguage ?? ImgI18N.getInstance().currentLanguage);
//     return getTranslated(getCMSContentNotTranslated(rest), lng);
// }

export function getCMSContentNotTranslated(p: GetCMSContentProps) {
    const { fileEntry, location, exact } = p;
    if (!fileEntry)
        return undefined;
    const state = store.getState();
    const loc = location ?? state.cmsState.location;
    const cts = fileEntry.contents[loc?.id ?? ""] ?? (exact ? undefined : fileEntry.contents[Object.keys(fileEntry.contents)[0]]);
    return cts?.contents;
}

interface GetCMSContentLng extends GetCMSContentProps {
    language: string,
    filePosts: { [key: string]: PostDownloadDto[] };
}

export function getCMSContent(p: GetCMSContentLng): { text?: LanguageStringDownloadDto, media?: MediaDownloadDto, post?: PostDownloadDto } {
    const { fileEntry, language, location, exact, filePosts } = p;
    if (!fileEntry)
        return {};
    const lng = language;
    const loc = location;
    const cts = fileEntry.contents[loc?.id ?? ""] ?? (exact ? undefined : fileEntry.contents[Object.keys(fileEntry.contents)[0]]);
    if (cts?.referenceId) {
        const post = _.find(filePosts[fileEntry.id], pp => pp.id === cts.referenceId);
        const toRet = { text: getTranslated(post?.headlines, lng), media: undefined, post };
        return toRet;
    }
    return { media: getTranslated(cts?.contents, lng) };
}

export const shortenBytes = (value: number, digits?: number, pairValue?: number): [number, string, number] => {
    const pow = Math.pow(10, digits ?? 0)
    const p = pairValue ?? 0;
    if (value < 1000)
        return [value, "b", p];
    if (value < 1000000)
        return [Math.floor(value * pow / 1000) / pow, "kb", Math.floor(p * pow / 1000) / pow];
    if (value < 1000000000)
        return [Math.floor(value * pow / 1000000) / pow, "mb", Math.floor(p * pow / 1000000) / pow];
    //    if (value < 1000000000000)
    return [Math.floor(value * pow / 1000000000) / pow, "gb", Math.floor(p * pow / 1000000000) / pow];
}

export const shortenUploadBytes = (currentBytes: number, ofBytes: number, t: ImgI18NTranslateFunction) => {
    const c = shortenBytes(ofBytes, 1, currentBytes);
    return t("{{current}} of {{sum}}{{unit}} done", { current: c[2], sum: c[0], unit: c[1] });
}

export const getLanguageFlag = (lng: string) => {
    const fs = lng.split("-");
    const f = (fs.length > 1 ? fs[1] : fs[0].toUpperCase()) as ISO_3166;
    return f;
}