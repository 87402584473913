import { BestPracticePostDownloadDto, CategoryDownloadDto, ChatDownloadDto, ChatMessageDownloadDto, CmsSearchResultDto, ConfigurationsWithCategories, ContentPostContentLocationDownloadDto, ContentPostDownloadDto, DashboardDownloadDto, DashboardElementTemplateDownloadDto, ExtendedDataCollection, FacetResult, FetchPostsFilter, FileEntriesDownloadDto, FileEntryDownloadDto, FileEntrySearchFilter, GroupDownloadDto, InAppMessageDownloadDto, ItemSearchResult, PALMSFilter, PlaylistDownloadDto, PlaylistStateDownloadDto, PodcastPostDownloadDto, PostDownloadDto, ProjectGroupDownloadDto, ProjectStructureTemplateDownloadDto, QuizDownloadDto, SearchSystems, SlideDownloadDto, SlideShowDownloadDto, SlideTemplateDownloadDto, TranslationBatchDownloadDto, TubePostDownloadDto, UserShortInfoDownloadDto, UserTaskDownloadDto, ViewStats, WikiArticleDownloadDto, WikiCategoryDownloadDto, WikiSearchResultDto, WorkflowDownloadDto, ZipUploadInProgress } from "collaboration-service";
import { ElementNode } from "collaboration-service/dist/dtos/ElementNode";
import { FileEntrySearchResultDto } from "collaboration-service/dist/dtos/FileEntrySearchResultDto";
import { ProjectGroupSearch } from "collaboration-service/dist/dtos/ProjectGroupSearch";
import { SearchResultsDto } from "collaboration-service/dist/dtos/SearchResultsDto";
import { GeneralSearchHelpers } from "components/General/GeneralSearch";
import { Layouts } from "react-grid-layout";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import BackendCallCache from "services/BackendCallCache";
import { GameHubService } from "services/signalR/GameHubService";
import { RouteParams } from "universal-router";
import { FeedSearchProps } from 'views/Feed/FeedFilter';
import { SortingBy } from "views/MyHome/MyHomeSettings";
import { LngSlides, SlideBgOption } from "views/SlideShow/SlideshowSettings";
import { LastSearch } from "views/UseSearchFeed/UseSearchFeed";
import { ApplicationAction, PostsWithToken } from "./Actions";
import { AppEntry } from "./MasterLayoutRouter";
import { PluginState } from "./PluginStateHandling";

export type EditTab = "General" | "Content";

export interface I18N {
    currentLanguage: string;
}

export interface ApplicationState extends PluginState {
    hasFocus: boolean;
    gameHubService: GameHubService;
    i18n: I18N;
    contentLanguage?: string;
    // set by router
    noLogin?: boolean;
    doLogin?: boolean;
    // set by router
    params?: RouteParams;
    noPadding?: boolean;
    search?: string;
    searchResultPos?: number;
    searchResultIds?: string[];
    filteredAppEntries?: AppEntry[];

    //framework extension
    masterLayoutRootExtension?: () => JSX.Element;

    feedState: FeedState;

    slideshowState: SlideShowState;
    wikiState: WikiState;

    tubeState: SearchAbleFeedState<TubePostDownloadDto> & { quality: "SD" | "HD" | "4K" };
    podcastState: SearchAbleFeedState<PodcastPostDownloadDto> & { quality: "SD" | "HD" | "4K" };
    contentState: SearchAbleFeedState<ContentPostDownloadDto>;
    quizState: SearchAbleFeedState<QuizDownloadDto>;

    //globalSearchState: SearchAbleFeedState<PostDownloadDto>;
    globalSearchState: GlobalSearchState;

    playlistsState: PlaylistsState;
    bestpracticeState: BestPracticeState;
    thePageState: ThePageState;
    cmsState: CMSState;
    chatState: ChatState;
    adminState: AdminState;
    qandaState: QandAState;
    myHomeState: MyHomeState;

    inboxState: InboxState;

    currentGroup?: GroupDownloadDto;

    needSave?: boolean;
    canSave?: boolean;
    active?: boolean;
    save?: () => void;
    add?: () => void;
    cancel?: () => void;
    scrollTo?: (id: string, delay?: number) => void;
    action?: () => void;
    reloadTasks?: () => void;
    userTasks: PostsWithToken<UserTaskDownloadDto>;

    loadingDataTableState?: LoadingDataTableState;
    mobileMenuState?: MobileMenuState;
    requestUpdate?: boolean;

    leftContentRenderers: Array<() => JSX.Element>;
    rightContentRenderers: Array<() => JSX.Element>;
}

export interface InboxState {
    topMessages?: InAppMessageDownloadDto[];
    messages?: InAppMessageDownloadDto[];
}

export interface AdminState {
    group?: GroupDownloadDto;
    leftSidebar?: boolean;
    rightSidebar?: boolean;
}

export interface GlobalSearchState {
    //filter: FetchPostsFilter;
    globalSearchFilter: FetchPostsFilter;
    globalSearchSystems: SearchSystems;
    search: string;
    results: Array<ItemSearchResult<PostDownloadDto> | WikiSearchResultDto>;
    viewStats: ViewStats[];
    //scrollTo?: (id: string) => void;
    lastSearch?: LastSearch;
}


export interface ContentFilterTypes {
    mediaTypeIds: string[];
    contentTypeIds: string[];
}

export interface PlaylistState {
    playlist: PlaylistDownloadDto;
    state: { name: string, date: Date, likes: number, done: boolean };
}

export interface PlaylistStateMissing {
    headline?: boolean;
    description?: boolean;
    media?: boolean;
    content?: boolean;
    group?: boolean;
    linear?: string[];
}

export interface PlaylistFilter {
    [key: string]: boolean;
}
export interface PlaylistFilterCount {
    [key: string]: number;
}

export enum Ordering {
    date,
    alpha
}
export interface PlaylistsState {
    playlistStates?: PlaylistStateDownloadDto[];
    playlistStatesTopic?: PlaylistStateDownloadDto[];
    playlistStatesTopicWhen?: Date;
    editMode?: boolean;
    save?: (save: boolean) => void;
    missing?: PlaylistStateMissing;
    rightSidebar?: boolean;
    leftSidebar?: boolean;
    filter?: PlaylistFilter;
    filterCount?: PlaylistFilterCount;
    ordering: Ordering;
}

export interface WikiState {
    groupId?: string;
    showIntro?: boolean;
    currentArticle?: WikiArticleDownloadDto;
    categories?: WikiCategoryDownloadDto[];
    category?: WikiCategoryDownloadDto;
    categoryId?: string;
    editMode?: boolean;
    //scrollTo?: (id: string) => void;
    search?: string;
    inNewsEdit?: boolean;
    focusTarget?: string;
    saveArticle?: (update: boolean) => void;
    playlistStatesForArticle?: PlaylistStateDownloadDto[];
    breadcrumbs?: WikiCategoryDownloadDto[][];
    referenceLanguage?: string;
    shownReferences: string[];
    saveable?: boolean;
    onNewsClose: () => void;
    onNewsSave?: () => void;
    onNewsDelete?: () => void;
}

export interface ThePageState {
    addElement?: boolean;
    editMode: boolean;
    dashboardElementTemplates?: DashboardElementTemplateDownloadDto[];
    dashboards?: DashboardDownloadDto[];
    dashboard?: DashboardDownloadDto;
    backendCache: BackendCallCache;
    editLayouts?: Layouts;
    editDashboard?: DashboardDownloadDto;
    filter?: PALMSFilter;
    preDetailFilterStack: Array<PALMSFilter | undefined>;
    detailId?: string;
    detailIdStack: Array<string | undefined>;
}

export interface FeedState {
    trending: boolean;
    searchProps?: FeedSearchProps;
    save?: (save?: boolean) => void;
    categories?: CategoryDownloadDto[];
    currentCategory?: CategoryDownloadDto;
    next?: () => void;
    prev?: () => void;
    selectedTab?: EditTab;
    show?: "posts" | "bookmarks";
    facets?: { [key: string]: FacetResult[] };
    rightSidebar?: boolean;
}

export interface SlideShowState {
    currentSlideShow?: SlideShowDownloadDto;
    lngSlides?: LngSlides;
    slide?: SlideDownloadDto;
    templates?: SlideTemplateDownloadDto[];
    selectionTemplate?: boolean;
    backgrounds?: SlideBgOption[];
    copy?: () => void;
    importAs?: () => void;
    updateSlide?: (slide: SlideDownloadDto, slideMedia: { [key: string]: File }) => void;
}
export interface SearchAbleFeedState<T> {
    currentPost?: T;
    currentPosts?: T[];
    categories?: CategoryDownloadDto[];
    currentCategoryStack: ElementNode<CategoryDownloadDto>[];
    //scrollTo?: (id: string) => void;
    viewMode?: "alpha" | "date" | "trending";
    filter: FetchPostsFilter;
    viewStats: ViewStats[];
    onResetSearch?: () => void;
    search?: string;
    contentFilterTypes?: ContentFilterTypes;
    focusTarget?: string;
    tableView?: boolean;
}

export interface BestPracticeFilterState {
    categories?: string[];
    locations?: string[];
    lng?: string;
    rating?: number;
    groups?: string[];
    org?: string | null;
    org1?: string | null;
    org2?: string | null;
}

export interface BestPracticeState {
    currentPost?: BestPracticePostDownloadDto;
    reportImplementation?: () => void;
    inEdit?: boolean;
    ordering?: "date" | "trending" | "invisible" | "searched" | "own";
    postToken?: string;
    posts?: BestPracticePostDownloadDto[];
    filter?: BestPracticeFilterState;
    searchTerm?: string;
    categories?: CategoryDownloadDto[];
    locations?: ContentPostContentLocationDownloadDto[];
    ownPosts?: BestPracticePostDownloadDto[];
    ownPostToken?: string;
    //scrollTo?: (id: string) => void;
    focusTarget?: string;
    facets?: { [key: string]: FacetResult[] };
    rightSidebar?: boolean;
}

export interface ChatState {
    chat?: ChatDownloadDto;
    members?: UserShortInfoDownloadDto[];
    chatters?: UserShortInfoDownloadDto[];
    messages?: ChatMessageDownloadDto[];
    addingChat?: boolean;
    updateInfoChat?: boolean;
    updateInfoChats?: boolean;
    chats?: ChatDownloadDto[];
}
export interface CMSSearchState {
    filter: FetchPostsFilter;
    searchTerm?: string;
    searchResults?: CmsSearchResultDto;
    fileEntryFilter: FileEntrySearchFilter;
    token?: string;
}

export interface ActionResult {
    action: string;
    result: boolean;
}

export interface ProjectsFilesCount {
    [id: string]: number;
}
export interface CMSState {
    loadingProjectFailed?: boolean;
    loadingFilesFailed?: boolean;
    projects?: ExtendedDataCollection<ProjectGroupDownloadDto, string>;
    contentProjects?: ProjectGroupDownloadDto[];
    currentProject?: ProjectGroupDownloadDto;
    currentFiles?: { [path: string]: FileEntryDownloadDto[] };
    location?: ContentPostContentLocationDownloadDto;
    locations?: ContentPostContentLocationDownloadDto[];
    template?: ProjectStructureTemplateDownloadDto;
    filePosts: { [key: string]: PostDownloadDto[] };

    searchResults?: CmsSearchResultDto;
    contentSearchResults?: CmsSearchResultDto;

    searchResultsFiles?: SearchResultsDto<FileEntrySearchResultDto>;
    searchResultsProjects?: SearchResultsDto<ProjectGroupSearch>;
    contentSearchResultsFiles?: SearchResultsDto<FileEntrySearchResultDto>;
    contentSearchResultsProjects?: SearchResultsDto<ProjectGroupSearch>;

    projectsFilesCount?: ProjectsFilesCount;

    categories?: CategoryDownloadDto[];
    filterTerm?: string;
    searchState?: CMSSearchState;
    showArchive?: boolean;
    showUpload?: boolean;
    showAddReference?: boolean;
    showProjectInfo?: boolean;
    showDownloadInfo?: boolean;
    fileToRename?: FileEntryDownloadDto;
    fileToMove?: FileEntryDownloadDto[];
    folderToRename?: string;
    showTasks?: boolean;
    workflows: WorkflowDownloadDto[];
    wfTypeIsGroup: boolean;
    contentSearchHelpers?: GeneralSearchHelpers;
    searchHelpers?: GeneralSearchHelpers;
    downloadLink?: string;
    translationBatches?: TranslationBatchDownloadDto[];
    token?: string;
    zipProgresses?: ZipUploadInProgress[];
    directoryAndFileActionsDisabled?: boolean;
    rightSidebar?: boolean;
    selectedDirs: FileEntriesDownloadDto[];
    selectedFiles: FileEntryDownloadDto[];
    categoryConfiguration?: ConfigurationsWithCategories;
    tableView?: boolean;
    actionResult?: ActionResult;
}

export interface QandAState {
    reply?: () => void;
    edit?: () => void;
    editForum?: () => void;
}

export interface MyHomeState {
    showDetailedPage: boolean;
    sortedBy: SortingBy;
    sortAscending: boolean;
    progressOnTrack: boolean;
    progressIncomplete: boolean;
    progressOverdue: boolean;
    progressCompleted: boolean;
    leftSidebar?: boolean;
    rightSidebar?: boolean;
}

export interface LoadingDataTableState {
    showFilterOnLoadingDataTable?: boolean;
}

export interface MobileMenuState {
    showMobileMenu?: boolean;
    logoutConfirmed?: boolean;
}

export const useAppSelector: TypedUseSelectorHook<ApplicationState> = useSelector;
export type AppDispatch = ThunkDispatch<ApplicationState, any, ApplicationAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();