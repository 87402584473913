import { fadeIn } from "components/Wiki/WikiSC";
import { aboveBreakpoint, belowBreakpointOrEqual, fadeColor, getColor, styled } from "imaginarity-react-ui";
import { moduleBackgrounds } from "services/Config";

export class WelcomeRoadSC {
    public static FullContainer = styled.div`
        position: fixed;
        top: 0;
        left: 0;
        background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
        width: 100vw;
        height: 100%;
        overflow: hidden;
    `;
    public static ContentContainer = styled.div`
        position: absolute;
        top: 50%;
        left: 50%;
        background: ${p => getColor(p, p.theme.colors.mainBackground)};
        width: ${p => belowBreakpointOrEqual(p, "mobile") ? "100vw" : belowBreakpointOrEqual(p, "tablet") ? "70vw" : "50vw"};
        /* height: ${p => belowBreakpointOrEqual(p, "mobile") ? "100vh" : belowBreakpointOrEqual(p, "tablet") ? "70vh" : "50vh"}; */
        height: ${p => belowBreakpointOrEqual(p, "mobile") ? "100%" : belowBreakpointOrEqual(p, "tablet") ? "70%" : "50%"};
        transform: translate(-50% , -50%);
        border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
        box-shadow: 0 6px 8px -6px ${p => getColor(p, p.theme.colors.mainForeground)};
        transition: all 0.2s ease-out;
    `;
    public static ContentContainerInnerStart = styled.div`
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50% , -50%);
        width: 100%;
        background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
        line-height: 3rem;
        text-align: center;
    `;
    public static ContentContainerInner = styled.div`
        position: relative;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        padding: 0 20px;
        text-align: left;
        font-size: 1rem;
    `;
    public static StartImage = styled.div<{ small?: boolean }>`
        position: absolute;
        top: ${p => p.small ? "10px" : "20px"};
        left: 50%;
        transform: translateX(-50%);
        img{
            max-height: ${p => p.small ? "40px" : "120px"};
            width: auto;
            max-width: 80vw;
        }
    `;
    public static StartVideo = styled.div`
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        video{
            ${p => aboveBreakpoint(p, "mobile") && "max-height: calc(25vh - 60px);"};
            width: auto;
            max-width: 80vw;
        }
    `;
    public static VideoContentContainer = styled.div`
        video{
            float: ${p => aboveBreakpoint(p, "tablet") ? "right" : "unset"};
            margin-left: ${p => aboveBreakpoint(p, "tablet") ? "20px" : "unset"};
            margin-bottom: ${p => aboveBreakpoint(p, "tablet") ? "unset" : "20px"};
            width: ${p => aboveBreakpoint(p, "tablet") ? "50%" : "100%"};
            max-width: 80vw;
        }
    `;

    public static StartHeader = styled.div`
        font-size: 2rem;
        font-weight: bolder;
        text-align: center;
    `;
    public static InfoText = styled.div`
        position: absolute;
        bottom: 30px;
        left: 10px;
        right: 10px;
        font-size: 0.9rem;
        line-height: 1.3rem;
        text-align: center;
`;
    public static WelcomeHeader = styled.div`
        font-size: 1.5rem;
        font-weight: bolder;
        text-align: center;
        line-height: 2rem;
        background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
        margin-bottom: 10px;
    `;
    public static ButtonBar = styled.div<{ height: number }>`
        position: absolute;
        left:0;
        bottom: 0;
        height: ${p => p.height}px;
        width: 100%;
    `;

    public static Content = styled.div<{ height: number }>`
        height: 40px;
        width: 100%;
        padding: 20px 10px;
        height: calc(${p => belowBreakpointOrEqual(p, "mobile") ? "90vh" : belowBreakpointOrEqual(p, "tablet") ? "70vh" : "50vh"} - 140px - ${p => p.height}px);
        overflow-y: auto;
    `;

    public static MissingInputs = styled.div<{ mobile: boolean }>`
        position: absolute;
        bottom: ${p => p.mobile ? "-30px" : "40px"};
        padding: 3px 20px;
        left: 0px;
        text-align: left;
        background: ${p => fadeColor(p, getColor(p, p.theme.colors.accentRed), 5)};
        border-bottom: 1px solid ${p => getColor(p, p.theme.colors.accentRed)};
        color: ${p => getColor(p, p.theme.colors.accentRed)};
        width: 100%;
        text-align: center;
    `;
    public static MissingItem = styled.div`
        display: inline-block;
        margin: 0 10px;
        font-weight: bolder;
        font-size: 0.7rem;
    `;
    public static Container = styled.div`
    position: relative;
    top: 0;
    width: 100%;
    max-width: 1920px;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    background-image: url(${props => props.theme.appStart.backgroundUrl});
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 70vh;
    
`;
    public static Info = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: ${p => belowBreakpointOrEqual(p, "tablet") ? "100%" : "320px"};
    height: 100%;
    color: ${p => getColor(p, "@darkerGrey")};
    padding: 40px;
    background-image: linear-gradient(rgba(0,0,0,0.02), rgba(0,0,0,0.06));
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: all 0.2s ease-out;
`;
    public static TitleBig = styled.div`
    font-weight: bold;
    font-size: 1.1rem;
    color: ${p => getColor(p, "@accent")};
    margin-top: 15px;
    padding-right: ${p => belowBreakpointOrEqual(p, "tablet") ? "0" : "110px"};
    margin-bottom: 5px;
`;
    public static Title = styled.div`
    font-weight: bold;
    font-size: 1rem;
    color: ${p => getColor(p, "@darkerGrey")};
    padding-right: ${p => belowBreakpointOrEqual(p, "tablet") ? "0" : "110px"};
    margin-top: 15px;
    margin-bottom: 5px;
    padding-left: 10px;
`;
    public static Normal = styled.div`
    font-size: 0.9rem;
    padding-right: ${p => belowBreakpointOrEqual(p, "tablet") ? "0" : "150px"};
    color: ${p => getColor(p, "@darkerGrey")};
    padding-left: 30px;
    margin-bottom: 5px;
    .normal_id{
        padding-left: 50px;
    }
    li{
        padding-left: 0px;
        margin-left: 15px;
    }
`;
    public static ToUContent = styled.div`
    position: fixed;
    top: ${p => belowBreakpointOrEqual(p, "tablet") ? "110px" : "0px"};
    left: ${p => belowBreakpointOrEqual(p, "tablet") ? "0%" : "320px"};
    width: ${p => belowBreakpointOrEqual(p, "tablet") ? "100%" : "calc(100% - 320px)"};
    height: 100%;
    color: ${p => getColor(p, "@darkerGrey")};
    padding: ${p => belowBreakpointOrEqual(p, "tablet") ? "10px 20px 20px 21px" : "40px"};
    background-image: linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.04));
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: all 0.2s ease-out;
    overflow: auto;
    .driveTable {
    width: 100%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 15px;
    }
    .drive {
    background-color: ${p => fadeColor(p, getColor(p, "@accentGreen"), 20)};
    border-left: 3px solid ${p => getColor(p, "@accentGreen")};
    padding: 10px;
    }
    .caution{
    background-color: ${p => fadeColor(p, getColor(p, "@accentYellow"), 20)};
    border-left: 3px solid ${p => getColor(p, "@accentYellow")};
    padding: 10px;}
    .stop{
    background-color: ${p => fadeColor(p, getColor(p, "@accentRed"), 20)};
    border-left: 3px solid ${p => getColor(p, "@accentRed")};
    padding: 10px; 
    }
    .descdrive{
    background-color: ${p => fadeColor(p, getColor(p, "@accentGreen"), 5)};
    padding: 10px;
    }
    .desccaution{
    background-color: ${p => fadeColor(p, getColor(p, "@accentYellow"), 5)};
    padding: 10px;
    }
    .descstop{
    background-color: ${p => fadeColor(p, getColor(p, "@accentRed"), 5)};
    padding: 10px;
    }
`;
    public static Large = styled.div`
    font-size: ${p => belowBreakpointOrEqual(p, "tablet") ? 1.2 : 1.8}rem;
    line-height: ${p => belowBreakpointOrEqual(p, "tablet") ? 1.4 : 2.2}rem;
    transition: all 0.2s ease-out;
    width: fit-content;
`;
    public static Small = styled.div`
    margin: 1rem 0;
    font-size: ${p => belowBreakpointOrEqual(p, "tablet") ? 1 : 1.1}rem;
    transition: all 0.2s ease-out;
`;
    public static GrContainer = styled.div`
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 1920px;
    margin: auto;
    height: 100%;
    overflow: hidden;
    background-image: url('${p => moduleBackgrounds.welcomeRoadBackground}');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
`;
    public static GrInfo = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: ${p => belowBreakpointOrEqual(p, "mobile") ? "100%" : "320px"};
    height: 100%;
    color: ${p => getColor(p, "@darkerGrey")};
    padding: 40px;
    background-image: linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.04));
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: all 0.2s ease-out;
`;
    public static GrLarge = styled.div`
    font-size: ${p => belowBreakpointOrEqual(p, "mobile") ? 1.2 : 1.8}rem;
    line-height: ${p => belowBreakpointOrEqual(p, "mobile") ? 1.4 : 2.2}rem;
    transition: all 0.2s ease-out;
`;
    public static GrSmall = styled.div`
    margin: 1rem 0;
    font-size: ${p => belowBreakpointOrEqual(p, "mobile") ? 1 : 1.2}rem;
    transition: all 0.2s ease-out;
    animation:  ${fadeIn} 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.0s both;
`;
}
