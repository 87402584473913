import { ImgIcons, RecursivePartial, ScreenBreakPoint, SizeConditionalComponent, Theme } from "imaginarity-react-ui";
import * as React from "react";
import { ApplicationState } from "./ApplicationState";
import { DesktopAppMenuProps } from "./DesktopAppMenu";
import { ComponentWithMappedApplicationState } from "./HelperInterfaces";
import { MasterLayoutAccordionProps } from "./MasterLayoutAccordion";
import { MobileButtonsProps } from "./MobileButtons";
import { PluginState } from "./PluginStateHandling";

export declare type MasterLayoutModuleType = "ContentInteraction" | "UserInteraction" | "ContentContext" | "ContentInfo" | "SearchAndFilter" | "SystemInfo";

export interface MasterLayoutModuleComponentProps {
    module: MasterLayoutModule;
    setBadge: (module: MasterLayoutModule, toAdd: number, add?: boolean) => void;
}

export class MasterLayoutModuleComponent<P extends MasterLayoutModuleComponentProps = MasterLayoutModuleComponentProps, S = {}> extends React.PureComponent<P, S> {
}


export interface MasterLayoutModule {
    type: MasterLayoutModuleType;
    icon: ImgIcons;
    name: string;
    badge?: number;
    open?: boolean;
    maxHeight?: string;
    supportedContentTypes?: string[];
    noAccordion?: boolean;
    available?: (state: ApplicationState) => boolean;
    moduleRenderer: <T extends MasterLayoutModuleComponentProps>(props: T) => JSX.Element;
    props?: any;
}

export interface TitleComponentProps {
    done?: () => void;
}

export interface TitleComponent {
    component: (props: TitleComponentProps) => JSX.Element;
    icon: ImgIcons;
}


export interface ContentProps {
    iconOpened?: ImgIcons;
    iconClosed?: ImgIcons;
    hidden?: boolean;
    manageOpen?: {
        get: () => Promise<boolean>;
        toggle: () => Promise<void>;
    };
}

export const masterLayoutPropsMapper = (state: PluginState) => ({
    activeModules: state.activeModules,
    modules: state.modules,
    currentAppEntry: state.currentAppEntry,
    currentAppView: state.currentAppView,
    buttons: state.buttons,

});

export interface MasterLayoutPropsNotConnected extends SizeConditionalComponent {
    leftModules?: MasterLayoutModuleType[];
    rightModules?: MasterLayoutModuleType[];
    leftHeader?: JSX.Element;
    rightHeader?: JSX.Element;
    accordion?: React.ComponentClass<MasterLayoutAccordionProps>;
    desktopAppMenu?: React.ComponentClass<DesktopAppMenuProps>;
    burgerMenu?: React.ComponentClass<any>;
    mobileButtons?: React.ComponentClass<MobileButtonsProps>;
    baseThemeManipulation?: (theme: Readonly<Theme>) => RecursivePartial<Theme>;
}

export interface MasterLayoutProps extends MasterLayoutPropsNotConnected, ComponentWithMappedApplicationState<typeof masterLayoutPropsMapper> {
}




export const breakpoints = {
    smallMobile: { width: 320 } as ScreenBreakPoint,
    mobile: { width: 375 } as ScreenBreakPoint,
    largeMobile: { width: 414 } as ScreenBreakPoint,
    tablet: { width: 768 } as ScreenBreakPoint,
    desktop: { width: 1280 } as ScreenBreakPoint,
    wide: { width: 1600 } as ScreenBreakPoint,
    ultraWide: { width: 1920 } as ScreenBreakPoint,
    extremlyWide: { width: 2560 } as ScreenBreakPoint
}

