import { AnswerQuestionResultDto } from "collaboration-service";
import * as _ from "lodash";
import * as React from 'react';
import { QuizSC as T } from '../QuizSC';

interface QuestionIndicatorProps {
    current: number;
    count: number;
    answers: Array<AnswerQuestionResultDto | undefined>;
    isMobile: boolean;
    isTablet: boolean;
    paddingRight: number;
}
interface QuestionindicatorState extends AnswerQuestionResultDto {
    rightAnswer?: boolean;
}



class QuestionIndicator extends React.Component<QuestionIndicatorProps, QuestionindicatorState> {
    public render() {
        const count = this.props.count;
        const isTablet = this.props.isTablet;
        const isMobile = this.props.isMobile;
        const calcWidth = count * 30;
        const w = isMobile ? calcWidth > 160 ? 160 : calcWidth : isTablet ? calcWidth > 250 ? 250 : calcWidth : calcWidth > 350 ? 350 : calcWidth;

        return (
            <T.SVGcontainer width={w} paddingRight={this.props.paddingRight - 3}>
                {this.drawProgressBar(w)}
            </T.SVGcontainer>
        );
    }

    private drawProgressBar = (width: number) => {
        const count = this.props.count;
        const maxDist = 40;
        const minDist = 20;
        const rightMargin = 10;

        const vp = "0 0 " + width + " 20";
        let sx = 7;
        const w = Math.min(width, maxDist * count + rightMargin + sx);
        if (w < width) {
            sx += width - w;
        }
        const dist = (width - rightMargin - sx) / count;
        const dist2 = (width - sx - 2) / count;
        //const cx = rightMargin + this.props.current * dist + sx;

        if (dist >= minDist)
            return (
                <T.Svg viewBox={vp}>
                    <T.Line x1={sx + 4} y1={10} x2={width} y2={10} strokeWidth={1} />
                    {_.times(count, c => {
                        let col1 = "color11";
                        let col2 = "color21";
                        if (c === this.props.current) {
                            col1 = "color5";
                            col2 = "color21";
                        }
                        if (c < this.props.current) {
                            const answer = this.props.answers[c] ? this.props.answers[c] : undefined;
                            col1 = "color11";
                            col2 = answer && answer.rightAnswer ? "color3" : "color4";
                        }
                        return (
                            <g key={c}>
                                <T.Circle r={7} cx={rightMargin + c * dist + sx} cy={10} className={col1} />
                                <T.Circle r={5} cx={rightMargin + c * dist + sx} cy={10} className={col2} strokeWidth={2} stroke="white" />
                            </g>
                        );
                    })}
                </T.Svg>
            );
        else {
            const x1 = rightMargin + sx;
            const x2 = width - dist;
            return (
                <T.Svg viewBox={vp}>
                    <T.Line x1={sx + 4} y1={10} x2={width} y2={10} strokeWidth={1} />
                    <g>
                        <T.Circle r={7} cx={x1} cy={10} className='color2' />
                        <T.Circle r={6} cx={x1} cy={10} className='color21' />
                        <T.Circle r={7} cx={x2} cy={10} className='color2' />
                        {/* <Circle r={6} cx={x2} cy={10} className={this.props.current < count - 1 ? "color21" : "color1"} /> */}
                        <T.Circle r={6} cx={x2} cy={10} className='color21' />
                    </g>
                    {x2 > x1 &&
                        <g>
                            <T.Rect x={x1} y={10 - 7} height={14} width={x2 - x1} className='color2' strokeWidth={0} />
                            <T.Rect x={x1} y={10 - 6} height={12} width={x2 - x1} strokeWidth={0} className="color21" />
                        </g>
                    }
                    {dist > 0 &&
                        // <Rect x={x1 - 4} y={6} rx={4} height={8} width={dist * this.props.current + 2} className='color1' />
                        <T.Rect x={x1 - 4} y={6} rx={4} height={8} width={dist2 * this.props.current} className='color1' />
                    }
                </T.Svg>
            );
        }
    }
}
export default QuestionIndicator;