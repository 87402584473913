import { Icon, ImgIcons, Tooltip } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { TabsBarSC as T } from './TabsBarSC';

export interface TabsProps {
    title: string;
    active: boolean;
    icon?: ImgIcons;
    hidden?: boolean;
    checked?: boolean;
    msg?: string;
}

interface TabsBarProps {
    input: TabsProps[];
    onChange?: (idx: number) => void;
    additionalContent?: JSX.Element | JSX.Element[];
    verticalSlider?: boolean;
    activeBold?: boolean;
    shadowed?: boolean;
}

const TabsBar = (p: TabsBarProps) => {
    const { input, onChange, additionalContent, verticalSlider, activeBold, shadowed } = p;
    const [tabs, setTabs] = React.useState<TabsProps[]>();
    const [actTab, setActTab] = React.useState<number>(-1);

    React.useEffect(() => {
        setTabs(input);
    }, [input]);

    const changeTab = React.useMemo(() => (tab: TabsProps) => {

        const copy = tabs ? _.clone(tabs) : [];
        for (let i = 0; i < copy.length; i++) {
            const c = copy[i];
            if (c.active) {
                setActTab(i);
            }
            if (c === tab) {
                c.active = true;
                if (onChange)
                    onChange(i);
            }
            else c.active = false;
        }
        setTabs(copy);
    }, [tabs, onChange]);


    const filteredTabs = _.filter(tabs, f => f.hidden !== true);

    return (
        <T.Container tabsLength={filteredTabs.length} shadowed={shadowed}>
            {_.map(filteredTabs, (t, i) => {
                const direction = verticalSlider ? "bottom" : i < actTab ? "left" : "right";
                return (
                    <T.Tab key={i} active={t.active} onClick={() => changeTab(t)} checked={t.checked} activeBold={activeBold} shadowed={shadowed}>
                        {t.icon && <Icon name={t.icon} style={{ float: "left", marginRight: 10 }} />}
                        {t.title}
                        {t.active && <T.Underline hasIcon={t.icon !== undefined} direction={direction} checked={t.checked} />}
                        {t.checked === true && <Icon name={"check"} color={"@accentGreen"} style={{ float: "right", marginLeft: 10 }} />}
                        {t.msg && <Tooltip noMargin tooltipText={t.msg} position='top'>
                            <Icon name={"asterisk"} color={"@accentRed"} style={{ float: "right", marginLeft: 10 }} />
                        </Tooltip>
                        }
                    </T.Tab>
                );
            })}
            <T.Rest>
                {additionalContent}
            </T.Rest>
        </T.Container>
    );
}
export default TabsBar;