
import { Icon, ImgIcons, LottieIcon, belowBreakpointOrEqual, getColor, lightenColor } from 'imaginarity-react-ui';
import * as React from 'react';
import { useImgI18N } from 'services/ImgI18N';
import styled from 'styled-components';


const OuterContainer = styled.div<{ shadowed: boolean }>`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    width: ${p => belowBreakpointOrEqual(p, "tablet") ? "calc(100% + 10px)" : "100%"};
    margin-left: ${p => belowBreakpointOrEqual(p, "tablet") ? -5 : 0}px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    position: relative;
    z-index: 5;
    ${p => p.shadowed && !belowBreakpointOrEqual(p, "tablet") && "box-shadow: 0px -5px 30px -20px #313639;"}
`;

const Poster = styled.div<{ hasOrganisation: boolean }>`
    position: absolute;
    top: ${p => p.hasOrganisation ? -35 : -20}px;
    left: 100px;
    z-index: 20;
    color: ${p => getColor(p, "@darkGrey")};
    font-size: 1.1em;
    font-weight: bolder;
    width: calc(100% - 100px);
    text-transform: capitalize;
    letter-spacing: 0;
`;

const PostContainer = styled.div<{ background: string, gradColor1: string, gradColor2: string, showCarbon: boolean }>`
        color: ${p => getColor(p, "@mainBackground")};
        width: calc(100% + 0px);
        box-shadow: 0 6px 8px -6px #000;
        transform: translateX(-0px);
        z-index: 5;
        padding: 10px;
        line-height: 20px;
        position: relative;
        margin-top: 60px;
        text-transform: uppercase;
        padding-left: 100px;
    letter-spacing: 1px;
    background-color: ${p => p.background === "" ? getColor(p, "@darkGrey") : p.background};
        ${p => p.background === "" && `
            background-image: ${p.showCarbon ? `linear-gradient(120deg, ${p.gradColor1 === "" ? getColor(p, "@accent") : getColor(p, p.gradColor1)}, ${p.gradColor2 === "" ? "transparent" : "transparent"}), 
                linear-gradient(45deg, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 25%, transparent 25%, transparent 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)}), 
                linear-gradient(45deg, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 25%, transparent 25%, transparent 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)}), 
                linear-gradient(to bottom, ${p.gradColor2 === "" ? "rgb(5, 5, 5)" : lightenColor(p, p.gradColor2, 120)}, ${p.gradColor2 === "" ? "rgb(20, 20, 20)" : lightenColor(p, p.gradColor2, 170)})` :
            (p.gradColor1 === "" && p.gradColor2 === "") ? `none` :
                `
                    linear-gradient(120deg, ${getColor(p, p.gradColor1)}, ${getColor(p, p.gradColor2)})
                    `};
                background-size:cover, 10px 10px, 10px 10px, 10px 5px;
                background-position: center center,0px 0px, 5px 5px, 0px 0px;
                `}
`;
const PostContainerImage = styled.div<{ hasIcon?: boolean }>`
        position: absolute;
        width: 80px;
        height: 80px;
        bottom: 10px;
        left: 10px;
        border-radius: 40px;
        background-color: ${p => getColor(p, "@veryLightGrey")};
        background-image: linear-gradient(-30deg, ${p => getColor(p, "@veryLightGrey")}, ${p => getColor(p, "@mainBackground")});
        z-index: 10;
        box-shadow: -6px -3px 6px -5px ${p => getColor(p, "@lightGrey")}, 3px 6px 8px -6px ${p => getColor(p, "@darkGrey")};
        *{
            stroke: ${p => getColor(p, "@accentRed")};
            ${p => p.hasIcon && `stroke-width: 1;`}
        }
`;
export interface PorscheMomentPostMobileLoaderProps {
    onClick?: () => void;
    infoText?: string,
    icon?: ImgIcons;
    rotate?: boolean;
}

const PorscheMomentPostMobileLoader = (p: PorscheMomentPostMobileLoaderProps) => {
    const { onClick, infoText, icon, rotate } = p;
    const { t } = useImgI18N("feed");
    const [loading, setLoading] = React.useState<boolean>(onClick ? false : true);
    React.useEffect(() => {
        setLoading(onClick ? false : true);
    }, [onClick]);

    const click = React.useMemo(() => () => {
        if (onClick) {
            setLoading(true);
            onClick();
        }
    }, [onClick]);

    return (
        <OuterContainer shadowed={true} onClick={click}>
            <PostContainer background={""} gradColor1="@accentRed" gradColor2="@darkGrey" showCarbon >
                {infoText ? infoText : (loading ? t("loading more posts...") : t("click to load more posts"))}
                <PostContainerImage hasIcon={icon !== undefined} style={{ transform: rotate ? "rotate(-180deg)" : "rotate(0deg)", padding: loading ? 10 : 0 }}>
                    {icon ?
                        <Icon name={icon} size={50} marginTop={0} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                        :
                        <LottieIcon url={loading ? "images/lottiIcons/spinner.json" : "images/lottiIcons/pulsingArrow.json"}
                            speed={1}
                            autoplay
                            loop
                            height={loading ? 60 : 80}
                            width={loading ? 60 : 80}
                        />
                    }
                </PostContainerImage>
                <Poster hasOrganisation={true}>
                    {t("system information")}
                </Poster>
            </PostContainer>
        </OuterContainer>
    );
}
export default PorscheMomentPostMobileLoader;