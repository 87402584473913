import { BookmarkController, CategoryDownloadDto, ContentController, ControllerHelper, ControllerMethod, ElementNode, EventController, EventUploadDto, FetchPostsFilter, FileEntryDownloadDto, PostController, PostDownloadDto, TokenContainer, ViewStats } from "collaboration-service";
import { ExtendedDataCollection } from "imaginarity-azure";
import * as _ from "lodash";
import * as React from "react";
import { AppRouteNames, ViewRouteNames } from "services/AppEntryDefinitions";
import { sortCategories } from "services/Helpers";
import { gotoNamedRoute } from "services/Helpers/RoutingHelper";
import ScrollHelper from "services/Helpers/ScrollHelper";
import { ImgI18N } from "services/ImgI18N";
import { RouteParams } from "universal-router";

type CatItemCount = { [id: string]: number };

interface ExtensionType {
    views: number;
    viewed: boolean;
    selected: boolean;
}

export interface ExtendedFileEntryDownloadDto extends FileEntryDownloadDto, ExtensionType { }

interface SearchableFeed<T> {
    loadMore: () => Promise<void>;
    loadMoreBookmarks?: (() => Promise<void>);
    loadingPosts: boolean;
    tiles: (T & ExtensionType)[];
    bookmarks?: T[];
    gotoCategory: (routeName: AppRouteNames | ViewRouteNames, catid: string) => () => void;
    currentCategory?: CategoryDownloadDto;
    searching: boolean;
    inCategory: boolean;
    containerDivProps: { ref?: ((h: HTMLElement | null) => void); };
    loading: boolean;
    currentFilter: FetchPostsFilter;
    subCatItemCount: CatItemCount;
    loadMoreElement: JSX.Element;
}
interface UseSearchableFeedProps<T> {
    contentLanguage?: string;
    viewMode?: "alpha" | "date" | "trending";
    search?: string;
    setSearch: (val?: string) => void;


    viewStats?: ViewStats[];
    setViewStats?: (views: ViewStats[]) => void;
    getViewStatsControllerMethod?: ControllerMethod<ViewStats[], { postIds: string[] }>;
    getCountForCategories?: ControllerMethod<number, { token: TokenContainer, filter: FetchPostsFilter }>;
    filter: FetchPostsFilter;

    params?: RouteParams;
    groupType: string | undefined;
    categoryMapName: string | undefined;
    categories?: CategoryDownloadDto[];
    categoryStack?: ElementNode<CategoryDownloadDto>[];
    withBookmarks?: boolean;
    setCategoriesAndCategoryStack: (categories: CategoryDownloadDto[] | undefined, categoryStack: ElementNode<CategoryDownloadDto>[] | undefined) => void;
    posts?: T[];
    setPosts: (posts?: T[]) => void;
    scrollHelper?: ScrollHelper;
    inActive?: boolean;
    ignoreCategoryFilter?: boolean;
    lastGroupId?: string;
    loadingCount?: number;
    loadMoreElementThreshold?: number;
}

export type SFR<Extension, T> = SearchableFeed<T> & Extension;


export interface UseSearchableFeedPluginResult<Extension, T> {
    mapProps?: (props: UseSearchableFeedProps<T>) => UseSearchableFeedProps<T>;
    mapResult: (res: SearchableFeed<T>) => SFR<Extension, T>;
}
export type UseSearchableFeedPlugin<Extension, T> = (p: UseSearchableFeedProps<T>) => UseSearchableFeedPluginResult<Extension, T>;

type RT<T, A, B, C, D, E, F> = SearchableFeed<T> & A & B & C & D & E & F;


const useSearchableFeed = <T extends PostDownloadDto,
    A extends object = {},
    B extends object = {},
    C extends object = {},
    D extends object = {},
    E extends object = {},
    F extends object = {},
>(p: UseSearchableFeedProps<T>,
    pluginA?: UseSearchableFeedPlugin<A, T>,
    pluginB?: UseSearchableFeedPlugin<B, T>,
    pluginC?: UseSearchableFeedPlugin<C, T>,
    pluginD?: UseSearchableFeedPlugin<D, T>,
    pluginE?: UseSearchableFeedPlugin<E, T>,
    pluginF?: UseSearchableFeedPlugin<F, T>
): RT<T, A, B, C, D, E, F> => {
    const [token, setToken] = React.useState<string>();
    const [loadingPosts, setLoadingPosts] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [bookmarks, setBookmarks] = React.useState<{ elements: T[], token: string }>();
    const [subCatItemCount, setSubCatItemCount] = React.useState<CatItemCount>({});

    let props = p;
    const pA = React.useMemo(() => pluginA ? pluginA(props) : undefined, [pluginA, props]);
    if (pA?.mapProps)
        props = pA.mapProps(props);
    const pB = React.useMemo(() => pluginB ? pluginB(props) : undefined, [pluginB, props]);
    if (pB?.mapProps)
        props = pB.mapProps(props);
    const pC = React.useMemo(() => pluginC ? pluginC(props) : undefined, [pluginC, props]);
    if (pC?.mapProps)
        props = pC.mapProps(props);
    const pD = React.useMemo(() => pluginD ? pluginD(props) : undefined, [pluginD, props]);
    if (pD?.mapProps)
        props = pD.mapProps(props);
    const pE = React.useMemo(() => pluginE ? pluginE(props) : undefined, [pluginE, props]);
    if (pE?.mapProps)
        props = pE.mapProps(props);
    const pF = React.useMemo(() => pluginF ? pluginF(props) : undefined, [pluginF, props]);
    if (pF?.mapProps)
        props = pF.mapProps(props);

    const { viewMode,
        filter,
        posts,
        setPosts,
        categoryStack,
        categories,
        search,
        params,
        setSearch,
        setCategoriesAndCategoryStack,
        groupType,
        categoryMapName,
        setViewStats,
        getViewStatsControllerMethod,
        getCountForCategories,
        viewStats,
        withBookmarks,
        scrollHelper,
        inActive,
        contentLanguage,
        ignoreCategoryFilter,
        lastGroupId,
        loadingCount
    } = props;

    const catid0 = params?.catid as string;
    const catid1 = params?.catid1 as string;
    const catid2 = params?.catid2 as string;
    const catid3 = params?.catid3 as string;
    const catid4 = params?.catid4 as string;
    const catid5 = params?.catid5 as string;
    const bt = bookmarks?.token;

    const getViews = React.useMemo(() => async (newPosts: T[]) => {
        if (setViewStats && getViewStatsControllerMethod) {
            const ids = _.map(newPosts, p => p.id);
            const views: ViewStats[] = await ControllerHelper.singleCall({ postIds: ids }, getViewStatsControllerMethod);
            setViewStats(views);
        }
    }, [setViewStats, getViewStatsControllerMethod]);

    React.useEffect(() => {
        // if (scrollHelper)
        //     console.log("UseSearchableFeed.tsx::164 => scrollHelper", scrollHelper);
        return () => {
            // console.log("UseSearchableFeed.tsx::166 => cleanup!");

            if (scrollHelper)
                scrollHelper.cleanUp();
        }

    }, [scrollHelper]);

    const createSearchEvent = (search: string, foundSomething: boolean, groupType: string | undefined) => {
        const url = window.location.protocol + window.location.host + window.location.pathname;
        if (search && search.length > 2) {
            if (!foundSomething) {
                const event: EventUploadDto = {
                    eventType: "Searched",
                    requestUri: url,
                    groupType,
                    payload: {
                        searchText: search,
                        foundSomething,
                    }
                };
                EventController.PostEvent({
                    ev: event
                });
            }
            const searchElements = _.orderBy(_.split(search, " "));
            for (const searchElement of searchElements) {
                if (searchElement.length > 2) {
                    const event: EventUploadDto = {
                        eventType: "SearchedTerm",
                        requestUri: url,
                        groupType,
                        payload: {
                            term: searchElement
                        }
                    };
                    EventController.PostEvent({
                        ev: event
                    });
                }
            }
        }
    };

    const currentFilter = React.useMemo(() => {
        const f: FetchPostsFilter = { ...filter, onlyFittingAllCategories: true, excludeCategories: _.map(categories, c => c.id) };
        if (!ignoreCategoryFilter && (categoryStack?.length ?? 0) > 0)
            f.categories = _.map(categoryStack, c => c.node!.id);
        if (lastGroupId)
            f.onlyInGroups = [lastGroupId];

        return f;
    }, [categories, categoryStack, filter, ignoreCategoryFilter, lastGroupId]);

    const load = React.useMemo(() => {
        return async (ps: T[] | undefined, token: string) => {

            const searched = (search !== undefined && search !== "");
            let res: ExtendedDataCollection<PostDownloadDto, string> = { elements: [], data: "", links: [] };
            if (categoryStack || ignoreCategoryFilter) {
                setLoadingPosts(true);
                const f = currentFilter;
                if (searched) {
                    res = await ControllerHelper.singleCall({
                        text: search ?? "", filter: f,
                        lng: contentLanguage ?? ImgI18N.getInstance().currentLanguage
                    }, PostController.SearchPostText2);
                    createSearchEvent(search ?? "", _.some(res.elements), groupType);

                }
                else {
                    switch (viewMode) {
                        case "trending":
                            res = await ControllerHelper.singleCall({ token: { token }, filter: f, loadingCount }, PostController.GetPagedForAllGroupsTrending)
                            break;
                        case "alpha":
                            res = await ControllerHelper.singleCall({ token: { token }, filter: f, loadingCount, by: "Headline", additionalData: contentLanguage ?? ImgI18N.getInstance().currentLanguage }, PostController.GetPagedForAllGroupsSortedBy)
                            break;
                        default:
                            res = await ControllerHelper.singleCall({ token: { token }, filter: f, loadingCount }, PostController.GetPagedForAllGroups)
                            break;
                    }
                }
            }
            setLoadingPosts(false);

            getViews(res.elements as T[]);
            const p = token === undefined || token === "" ? [] : _.clone(ps ?? []);
            p.push(...(res.elements as T[]))
            setPosts(p);
            setToken(res.data);
        }
    }, [viewMode, search, setPosts, setToken, getViews, contentLanguage, currentFilter, categoryStack, ignoreCategoryFilter, groupType, loadingCount]);

    const loadBookmarks = React.useMemo(() => async () => {
        //console.log("loadBookmarks");

        const bm = await ControllerHelper.singleCall({ token: { token: bookmarks?.token }, filter }, BookmarkController.GetPagedFiltered, true);
        if (bm) {
            const p = bookmarks && bookmarks.token !== "" && bookmarks.token !== undefined && bookmarks.token !== null ? _.clone(bookmarks.elements) : [];
            const e = _.map(bm.elements, e => e.post as T);
            p.push(...e);
            let allIn = true;
            for (let i = 0; i < e.length; i++) {
                allIn = allIn && _.findIndex(bookmarks?.elements, b => b.id === e[i].id) >= 0;
                if (!allIn)
                    break;
            }

            if (!allIn)
                setBookmarks({ elements: p, token: bm.data });
        }
        else {
            if ((bookmarks?.elements?.length ?? -1) !== 0)
                setBookmarks({ elements: [], token: "" });
        }

    }, [setBookmarks, bookmarks, filter]);
    const loadMoreBookmarks = React.useMemo(() => async () => {
        //console.log("load more bookmarks called");

        const bm = await ControllerHelper.singleCall({ token: { token: bookmarks?.token }, filter }, BookmarkController.GetPagedFiltered, true);
        if (bm) {
            const p = bookmarks && bookmarks.token !== "" && bookmarks.token !== undefined && bookmarks.token !== null ? _.clone(bookmarks.elements) : [];
            const e = _.map(bm.elements, e => e.post as T);
            p.push(...e);
            let allIn = true;
            for (let i = 0; i < e.length; i++) {
                allIn = allIn && _.findIndex(bookmarks?.elements, b => b.id === e[i].id) >= 0;
                if (!allIn)
                    break;
            }

            if (!allIn)
                setBookmarks({ elements: p, token: bm.data });
        }

    }, [setBookmarks, bookmarks, filter]);
    React.useEffect(() => {
        setLoading(true);
        const loadData = async () => {
            const cs: string[] = [];
            if (catid0) cs.push(catid0);
            if (catid1) cs.push(catid1);
            if (catid2) cs.push(catid2);
            if (catid3) cs.push(catid3);
            if (catid4) cs.push(catid4);
            if (catid5) cs.push(catid5);
            const categoryDtosRes = await ControllerHelper.singleCall({
                definition: {
                    categoryStack: cs,
                    groupType: groupType,
                    mapName: categoryMapName
                }
            }, ContentController.GetCategoriesForAllGroupsByGroupTypeMapped);
            setCategoriesAndCategoryStack(sortCategories(categoryDtosRes.elements), categoryDtosRes.data);
            setLoading(false);
        }
        //console.log("here we go again!");
        loadData();
    }, [catid0, catid1, catid2, catid3, catid4, catid5, setLoading, categoryMapName, groupType, setCategoriesAndCategoryStack]);

    React.useEffect(() => {
        setPosts(undefined);
        load([], "");
        //console.log("here we for posts!");
    }, [setPosts, load]);

    React.useEffect(() => {
        const loadData = async () => {
            if (withBookmarks && !bt)
                loadBookmarks();
        }
        //console.log("here we for bm!");
        loadData();
    }, [loadBookmarks, withBookmarks, bt]);

    const loadMore = React.useMemo(() => async () => {
        if (token !== "" && token !== undefined)
            return await load(posts, token);
        return undefined;
    }, [load, token, posts]);

    const tiles = React.useMemo(() => _.map(posts, p => {
        const v = _.find(viewStats, v => v.postId === p.id);
        return {
            ...p,
            views: v?.views ?? 0,
            viewed: v?.viewed ?? false,
            selected: false
        };
    }), [posts, viewStats]);

    const goCategory = React.useMemo(() => (routeName: ViewRouteNames | AppRouteNames, catid: string) => () => {
        setSearch("");
        setLoading(true);
        const c0 = catid0 ?? catid;
        const c1 = catid1 ?? (c0 && catid !== c0 ? catid : undefined);
        const c2 = catid2 ?? (c1 && catid !== c1 ? catid : undefined);
        const c3 = catid3 ?? (c2 && catid !== c2 ? catid : undefined);
        const c4 = catid4 ?? (c3 && catid !== c3 ? catid : undefined);
        const c5 = catid5 ?? (c4 && catid !== c4 ? catid : undefined);
        gotoNamedRoute(routeName, { catid: c0, catid1: c1, catid2: c2, catid3: c3, catid4: c4, catid5: c5 });
    }, [catid0, catid1, catid2, catid3, catid4, catid5, setSearch]);

    const onScroll = React.useMemo(() => {
        if (scrollHelper)
            return scrollHelper.executeOnScroll(150,
                async () => {
                    if (token !== undefined && token !== "" && !inActive)
                        await load(posts, token);
                },
                () => token !== null && token !== undefined);
        else
            return (event: Event) => new Promise<void>(res => res());
    },
        [scrollHelper, token, posts, load, inActive]);

    const currentCategory = categoryStack && categoryStack.length > 0 ? categoryStack[categoryStack.length - 1].node : undefined;
    const searching = (search !== undefined && search !== "");


    React.useEffect(() => {
        const cats = _.filter(_.map(categoryStack, c => c.node?.id!), f => f !== undefined);
        const load = async () => {
            const toSet: CatItemCount = {};
            if (categories && getCountForCategories) {
                const ps = _.map(categories, async c => {
                    const f = _.clone(filter);
                    f.onlyFittingAllCategories = true;
                    f.categories = [...cats, c.id];
                    const res = await ControllerHelper.singleCall({ token: {}, filter: f }, getCountForCategories, true);
                    // console.log(`${c.names[0].text} => ${res}`);
                    toSet[c.id] = res ?? 0;
                })
                await Promise.all(ps)
            }
            toSet["done"] = 1;
            setSubCatItemCount(toSet);

        }
        // console.log("UseSearchableFeed.tsx::359 => categoryStack", categoryStack);
        // console.log("UseSearchableFeed.tsx::359 => categories", categories);
        setSubCatItemCount({});
        load();

    }, [categories, categoryStack, filter, getCountForCategories])

    const loadMoreElement = React.useMemo(() => <div className="loadMoreElement" />, []);
    const elements = bookmarks?.elements;
    const toRet: RT<T, A, B, C, D, E, F> = React.useMemo(() => {
        const ele = elements ?? [];
        let res: any =
        {
            loadMore,
            loadingPosts,
            tiles,
            bookmarks: ele,
            loadMoreBookmarks: bt !== undefined && bt !== null && bt !== "" ? loadMoreBookmarks : undefined,
            gotoCategory: goCategory,
            currentCategory,
            inCategory: currentCategory !== undefined,
            searching,
            containerDivProps: { ref: scrollHelper ? scrollHelper.setOnScrollOnParent(onScroll) : undefined },
            loading,
            currentFilter,
            subCatItemCount,
            loadMoreElement,
        };
        if (pA) {
            res = pA.mapResult(res);
            if (pB) {
                res = pB.mapResult(res);
                if (pC) {
                    res = pC.mapResult(res);
                    if (pD) {
                        res = pD.mapResult(res);
                        if (pE) {
                            res = pE.mapResult(res);
                            if (pF) {
                                res = pF.mapResult(res);
                            }

                        }
                    }
                }
            }

        }

        return res;
    }, [loadMore, loadingPosts, tiles, elements, loadMoreBookmarks, currentCategory, searching, scrollHelper, loading, goCategory, onScroll, bt, currentFilter, pA, pB, pC, pD, pE, pF, subCatItemCount, loadMoreElement]);
    return toRet;
}

export const useTestPlugin = <T extends PostDownloadDto>(p: UseSearchableFeedProps<T>) => {
    console.log('UseSearchableFeed.tsx:354:creating! =>');
    const res: UseSearchableFeedPluginResult<{ x: number }, T> = {
        mapResult: (res) => ({ ...res, x: 17 })
    };
    return res;
};

export default useSearchableFeed;