import { CSSObjectWithLabel, Theme, appendPx, customStyles, getColor } from "imaginarity-react-ui";
import _ from "lodash";

export const myHomeConfig = {
    takeContentWidth: true,
    showNoEntriesInfo: true,
    deactivateTabIfNoEntries: true,
};

export const myHomeLearningPathDeviceBreakpoints: MyHomeLearningPathBreakpoint[] = [
    { name: "mobile", width: 480 },
    { name: "tablet", width: 760 },
    { name: "smallDesktop", width: 1100 },
    { name: "desktop", width: 1400 },
    { name: "largeDesktop", width: 1920 },
];

export const myHomeLearningPathContentBreakpoints: MyHomeLearningPathBreakpoint[] = [
    { name: "small", width: 440 },
    { name: "medium", width: 600 },
    { name: "large", width: 760 },
];

export const filterOptions = [
    { title: "overdue", key: "overdue" },
    { title: "due by end of month", key: "thisMonth" },
    { title: "due within next month", key: "nextMonth" },
    { title: "still to be done", key: "notStarted" },
    { title: "last edited", key: "incomplete" },
    { title: "completed", key: "completed" },
];

export const slideBreakpoints: SlideBreakpoints[] = [
    {
        breakpoint: 0,
        statusGap: 12, slidesToShow: 1, slideWidth: 0.85, slidesGap: 12, tableGap: 8
    },
    {
        breakpoint: (_.find(myHomeLearningPathDeviceBreakpoints, b => b.name === "mobile")?.width || 0),
        statusGap: 16, slidesToShow: 2, slideWidth: 1.78, slidesGap: 16, tableGap: 8
    },
    {
        breakpoint: (_.find(myHomeLearningPathDeviceBreakpoints, b => b.name === "tablet")?.width || 0),
        statusGap: 16, slidesToShow: 2, slideWidth: 1.1, slidesGap: 16, tableGap: 16
    },
    {
        breakpoint: (_.find(myHomeLearningPathDeviceBreakpoints, b => b.name === "smallDesktop")?.width || 0),
        statusGap: 24, slidesToShow: 3, slideWidth: 1, slidesGap: 24, tableGap: 16
    },
    {
        breakpoint: (_.find(myHomeLearningPathDeviceBreakpoints, b => b.name === "desktop")?.width || 0),
        statusGap: 24, slidesToShow: 3, slideWidth: 1, slidesGap: 24, tableGap: 24
    },
    {
        breakpoint: (_.find(myHomeLearningPathDeviceBreakpoints, b => b.name === "largeDesktop")?.width || 0),
        statusGap: 24, slidesToShow: 4, slideWidth: 1, slidesGap: 24, tableGap: 64
    }
];


export const myHomeStyles = (theme: Theme) => {
    const s = customStyles(theme, false);
    return {
        ...s,
        control: (provided: any, state: any): any => ({
            ...s.control(provided, state),
            backgroundColor: getColor({ theme }, "@mainBackground"),
            borderColor: getColor({ theme: theme }, "@accentBlack"),
            height: 44,
            marginTop: -10,
            "&:hover": {
                backgroundColor: getColor({ theme }, "@mainBackground"),
                borderColor: getColor({ theme: theme }, "@accentBlack"),
            }
        }),
        singleValue: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
            ...provided,
            color: getColor({ theme }, "@accentBlack"),
        }),
        valueContainer: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
            ...provided,
            height: 40,
        }),
        indicatorSeparator: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
            ...provided,
            backgroundColor: "transparent",
        }),
        indicatorsContainer: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
            ...provided,
            "svg path": {
                d: "path('M26,10l-11,8.2L4,10v1.7L15,20l11-8.2V10z') !important",
                transform: "scale(0.7) translateX(-3px)",
                color: getColor({ theme }, "@accentBlack"),
            },
            "&:hover svg path": {
                color: getColor({ theme }, "@accent"),
            }
        }),
        input: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
            ...provided,
            color: getColor({ theme: theme }, "@accentBlack"),
        }),
        menu: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
            ...provided,
            borderRadius: appendPx(theme.select.borderRadius),
            marginTop: -41,
            marginLeft: 3,
            width: "calc(100% - 6px)",
            top: "100%",
            bottom: "unset",
            boxShadow: "0 15px 25px -20px #000",
            border: "1px solid #000",
            paddingTop: 37,
            background: "transparent"
        }),
        option: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
            ...provided,
            color: getColor({ theme: theme }, "@accentBlack"),
            //fontSize: "14px",
            padding: "7px !important",
            lineHeight: "18px",
            wordBreak: "break-word",
            hyphens: "auto",
            borderTop: "1px solid transparent",
            borderBottom: "1px solid transparent",
            transition: "all 0.2s ease-out",
            backgroundColor: getColor({ theme: theme }, "@mainBackground"),
            "&:hover": {
                borderTop: "1px solid #E3E4E5",
                borderBottom: "1px solid #E3E4E5",
                backgroundColor: "#F2F2F2",
                color: getColor({ theme: theme }, "@accent"),
            },
            // "&::after": {
            //     content: "'✓'",
            //     marginLeft: 5
            // }
        } as any
        ),
    }
};




// INTERFACES

export interface MyHomeLearningPathBreakpoint {
    name: string;
    width: number
}

export interface JobRoles {
    value: string;
    label: string;
    list: MyHomeLearningPathItem[];
};

export interface MyHomeLearningPathItem {
    id: string;
    title: string;
    image: string;
    category: string;
    url: string;
    type: LearningPathTypes;
    dueDate?: string;
    completed?: number;
    lastEdited?: string;
};






// TYPES

export type LearningPathTypes = "global" | "local";
export type SortingBy = "name" | "category" | "date" | "progress" | undefined;
export type SlideBreakpoints = {
    breakpoint: number;
    statusGap: number;
    slidesToShow: number;
    slideWidth: number;
    slidesGap: number;
    tableGap: number;
};


