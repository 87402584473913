import { GroupDownloadDto } from 'collaboration-service';
import { appendPx, components, getColor, GroupBase, Icon, ImgOnChangeValue, ImgSelect, ImgSelectProps, OptionProps, scaleSize, styled } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { shallowCompare, visitGroups } from 'services/Helpers';
import { useImgI18N } from 'services/ImgI18N';

interface AdminGroupSelectionProps<IsClearable extends boolean> extends Omit<ImgSelectProps<GroupDownloadDto, false, GroupBase<GroupDownloadDto>, IsClearable>, "onChange" | "isMulti" | "isClearable"> {
    filterTypes?: string[];
    value?: GroupDownloadDto;
    onGroupSelected?: (group: ImgOnChangeValue<GroupDownloadDto, false, IsClearable>) => void;
    showTypes?: boolean;
    isClearable?: IsClearable;
}

const mapper = (state: ApplicationState) => ({
    adminGroups: state.adminGroups,
    group: state.adminState.group,
})


type OptionsType = { value: GroupDownloadDto, label: string, indent: number };

const IC = styled.div<{ topMargin?: boolean, empty: number }>`
    display: grid;
    grid-template-columns: ${p => appendPx(scaleSize(p.theme.iconSize, p.empty))} ${p => appendPx(p.theme.iconSize)} 1fr;
    //height: calc(${p => appendPx(p.theme.iconSize)} + 18px);
    //padding: 0px 5px 0px 5px;
    padding: 0;
    cursor: pointer;
    &:hover{
        svg{
            stroke: ${p => getColor(p, "@mainBackground")};
        }
    }
`;

const I = styled.div`
    grid-column: 2;
    //height: calc(${p => appendPx(p.theme.iconSize)} + 14px);
`;

const T = styled.div`
    grid-column: 3;
    padding-left: 10px;
    //height: calc(${p => appendPx(p.theme.iconSize)} + 14px);
    //line-height: calc(${p => appendPx(p.theme.iconSize)} + 14px);
`;

const GroupOption = (p: OptionProps<OptionsType, false, GroupBase<OptionsType>>) => {
    const { t } = useImgI18N("admin");
    const cur = p.data;
    const content = React.useMemo(() => (
        <IC empty={cur.indent}>
            <I>
                <Icon name="folder" marginTop={0} />
            </I>
            <T>{cur.label}<div style={{ float: "right" }}>({t(cur.value.groupType)})</div></T>
        </IC>
    ), [cur, t]);
    return (
        <components.Option {...p}>
            {content}
        </components.Option>
    )
}

const GroupOptionNoTypes = (p: OptionProps<OptionsType, false, GroupBase<OptionsType>>) => {
    const cur = p.data;
    const content = React.useMemo(() => (
        <IC empty={cur.indent}>
            <I>
                <Icon name="folder" marginTop={0} />
            </I>
            <T>
                {cur.label}</T>
        </IC>
    ), [cur]);
    return (
        <components.Option {...p}>
            {content}
        </components.Option>
    )
}

const AdminGroupSelection = <IsClearable extends boolean = false>(p: AdminGroupSelectionProps<IsClearable>) => {
    const { filterTypes, value, onGroupSelected, showTypes, isClearable, ...rest } = p;
    const { adminGroups, group } = useSelector(mapper, shallowCompare);
    const dispatch = useAppDispatch();


    //console.log("AdminGroupSelection.tsx::95 => value", value); 
    //console.log("AdminGroupSelection.tsx::96 => group", group);



    const options = React.useMemo(() => {
        const toRet: OptionsType[] = [];
        visitGroups(adminGroups, (g, l) => { toRet.push({ value: g, label: g.name, indent: l }); return true });
        return filterTypes ? _.filter(toRet, t => _.findIndex(filterTypes, f => t.value.groupType === f) >= 0) : toRet;
    }, [adminGroups, filterTypes]);

    const onChange = React.useMemo(() => (newValue: ImgOnChangeValue<OptionsType, false, IsClearable>) => {
        if (onGroupSelected)
            (onGroupSelected as any)(newValue?.value);
        else
            dispatch(Actions.adminSetGroup(newValue?.value));
    }, [onGroupSelected, dispatch]);

    const curValue = React.useMemo(() => {
        const val = onGroupSelected ? (value ?? group) : group;
        return _.find(options, o => o.value.id === val?.id)
    }, [value, options, onGroupSelected, group]);

    return (
        <ImgSelect
            options={options}
            components={{
                Option: showTypes ? GroupOption : GroupOptionNoTypes
            }}
            value={curValue ?? null}
            onChange={onChange as any}
            isSearchable
            isClearable={isClearable}
            {...rest as any}
        />
    );
}
export default AdminGroupSelection;