import { styled } from "imaginarity-react-ui";


const CloseArea = styled.div`
    z-index: 2147483636;
    position: fixed;
    inset: 0;
    background: rgba(0,0,0,0.2);
    cursor: default;
    //backdrop-filter: grayscale(1) blur(0.5px);
`;

export default CloseArea;