import { ControllerHelper, URLContent, URLController, URLData } from "collaboration-service";
import he from "he";
import { Icon, Image, LinkTo } from "imaginarity-react-ui";
import * as React from 'react';
import { windowOpen } from "services/Helpers/LinkHelpers";
import { GeneralSC as T } from "./GeneralSC";


interface UrlComponentProps extends URLData {
}

interface UrlComponentState {
    data?: URLContent;
    loading: boolean;
}

export const getUrlData = async (url: string): Promise<{ data?: URLContent }> => {
    const urlData: URLData = {
        url,
        routeDescriptions: [
            {
                route: "",
                pattern: "",
                renderType: "",
            },
        ]
    };
    const data = await ControllerHelper.singleCall({ data: urlData }, URLController.GetUrlInfo, true);
    if (!data)
        return {};
    return { data };
}


class UrlComponent extends React.Component<UrlComponentProps, UrlComponentState> {
    private mounted: boolean = false;
    constructor(props: UrlComponentProps) {
        super(props);
        this.state = {
            loading: true
        };
    }
    public UNSAFE_componentWillMount() {
        this.mounted = true;
        this.checkUrl(this.props);
    }

    public componentWillUnmount() {
        this.mounted = false;
    }
    public UNSAFE_componentWillReceiveProps(props: UrlComponentProps) {
        if (this.props.url !== props.url) {
            this.setState({ data: undefined }, () => this.checkUrl(props));
        }
    }

    public render() {
        if (this.state.data) {
            if (this.state.data.image) {
                const desc = this.state.data.description ? he.decode(this.state.data.description) : "";
                const title = this.state.data.title ? he.decode(this.state.data.title) : "";
                return (
                    <T.UrlCompContainer
                        onClick={this.openLink}
                    >
                        <T.UrlCompContainerTitle>
                            {title}
                        </T.UrlCompContainerTitle>
                        <T.UrlCompContainerIcon>
                            <Icon name="link external" marginTop={0} style={{ float: 'right' }} />
                        </T.UrlCompContainerIcon>
                        <T.UrlCompContainerImage>
                            <Image src={this.state.data.image} thumbnail squared />
                        </T.UrlCompContainerImage>
                        <T.UrlCompContainerDescription noDesc={desc === "" ?? false}>
                            {desc !== "" && desc}
                            {desc === "" && "no description available"}
                        </T.UrlCompContainerDescription>
                        <T.UrlCompContainerLink>
                            {this.props.url}
                        </T.UrlCompContainerLink>
                    </T.UrlCompContainer>
                );
            }
        }
        if (!this.state.loading)
            return (<LinkTo
                icon="link external"
                marginToIcon="5px"
                noAnimation
                noBoldOnHover
                linkText={this.props.url}
                url={this.props.url}
                target="_blank"
            />
            );
        else
            return <div />;
    }

    private checkUrl = async (props: URLData) => {
        if (!this.mounted)
            return;
        this.setState({ loading: true });
        var res = await getUrlData(props.url);
        this.setState({ data: res.data, loading: false });
    }
    private openLink = () => {
        windowOpen(this.props.url, "_blank");
    }
}
export default UrlComponent;
