import EditPartContainer from 'components/PostEdit/EditPartContainer';
import { Button, getColor, Icon, Input, styled, useThemePart } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { ViewRouteNames } from 'services/AppEntryDefinitions';
import { ApplicationState, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { AccessHelpers } from 'services/Helpers';
import { gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { isAdmin } from 'services/StoreDependantHelpers';

const mapper = (s: ApplicationState) => ({ user: s.user });

const Container = styled.div<{ gapH: number }>`
    display: flex;
    flex-wrap: wrap; 
    column-gap: ${p => p.gapH}px; 
    row-gap: 10px; 
    justify-content: left;
    width: 100%; 
    padding: 10px;
    box-shadow: 0 6px 8px -6px #000;
    border-top: none;
    margin-top: -5px;
`;

const Item = styled.div<{ buttonMinWidth: number }>`
    width: ${p => p.buttonMinWidth}px; 
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    transition: all 0.2s ease-out;
    &>div{margin: 0;}
    &:hover {border: 1px solid ${p => getColor(p, "@darkGrey")};} 
`;
const Info = styled.div`
    font-size: 0.9em;
    color: ${p => getColor(p, "@middleLightGrey")};
    margin-top: 10px;
    transition: all 0.2s ease-out;
    &:hover {color: ${p => getColor(p, "@darkGrey")}; svg{stroke: ${p => getColor(p, "@darkGrey")};}} 

`;
const Alert = styled.div`
    float: right;
    color: ${p => getColor(p, "@accentRed")};
    text-align: right;

`;

const calculateGapAndCols = (contentWidth: number, buttonMinWidth: number, minGap: number) => {
    let cols = Math.floor(contentWidth / buttonMinWidth);
    let gapH = (contentWidth - cols * buttonMinWidth) / (cols - 1);
    while (gapH < minGap && cols > 1) {
        cols -= 1;
        gapH = (contentWidth - cols * buttonMinWidth) / (cols - 1);
    }
    return { gapH };
};

type GroupDefinition = "groups" | "users" | "cms" | "other";
interface RouteDefinition {
    route: ViewRouteNames;
    group: GroupDefinition;
    text?: string;
    access?: boolean;
}
const buttonMinWidth = 200;
const minGap = 10;

const AdminStart = () => {
    const { user } = useAppSelector(mapper);
    const contentWidth = useThemePart(x => x.content.width) - 22;
    const isGlobalAdmin = AccessHelpers.isGlobalAdmin()(user);
    const isAdminSomewhere = AccessHelpers.isAdminSomewhere()(user);
    const { gapH } = calculateGapAndCols(contentWidth, buttonMinWidth, minGap);

    const routes: RouteDefinition[] = [
        { route: "admin_tube_group", group: "groups", access: (isGlobalAdmin || isAdmin("TUBE")), text: "Tube Group Admin" },
        { route: "admin_podcast_group", group: "groups", access: (isGlobalAdmin || isAdmin("PODCAST")), text: "Podcast Group Admin" },
        { route: "admin_slideshow_group", group: "groups", access: (isGlobalAdmin || isAdmin("ALIDESHOW")), text: "Slideshow Group Admin" },
        { route: "admin_quiz_group", group: "groups", access: (isGlobalAdmin || isAdmin("QUIZ")), text: "Quiz Group Admin" },
        { route: "admin_user", group: "users", access: isGlobalAdmin, text: "Edit User" },
        { route: "admin_newUser", group: "users", access: isGlobalAdmin, text: "Add New User" },
        { route: "admin_user_ppn_check", group: "users", access: isGlobalAdmin, text: "Check PPN User" },
        { route: "admin_group_mails", group: "cms", access: (isGlobalAdmin || isAdmin("CMS")), text: "Manage CMS Mails" },
        { route: "admin_categories", group: "cms", access: (isGlobalAdmin || isAdmin("CMS")), text: "Manage CMS Categories" },
        { route: "admin_locations", group: "cms", access: (isGlobalAdmin || isAdmin("CMS")), text: "Manage CMS Locations" },
        { route: "admin", group: "other", access: (isGlobalAdmin || isAdminSomewhere), text: "Old Admin Area" },
        { route: "admin_ciaNews", group: "other", access: (isGlobalAdmin || isAdmin("CUSTOMER INFORMATION")), text: "CIA News" },
        { route: "admin_translateNS", group: "other", access: isGlobalAdmin, text: "List of used Namespaces" },
        { route: "admin_cat_tree", group: "other", text: "Category Tree" },
        { route: "testUCTS", group: "other", text: "Test UCTS" },
        // { route: "admin_tube_upload", group: "other", },
        // { route: "admin_podcast_upload", group: "other", },
        // { route: "admin_quiz_and_question_edit", group: "other", },
        // { route: "admin_start", group: "other", },
        // { route: "admin_edit_category_like", group: "other", },
        // { route: "admin_edit_mail", group: "other", },
    ];

    const groupedRoutes = _.groupBy(routes, "group");
    const groupOrder: GroupDefinition[] = ["groups", "users", "cms", "other"];
    const [filter, setFilter] = React.useState<string>("");
    const filteredRoutes = React.useMemo(() => {
        if (!filter) return groupedRoutes;

        return _.mapValues(groupedRoutes, (routes) =>
            routes.filter(
                (routeDef) =>
                    routeDef.route.includes(filter) ||
                    routeDef.group.includes(filter) ||
                    (routeDef.text && routeDef.text.toLowerCase().includes(filter.toLowerCase()))
            )
        );
    }, [filter, groupedRoutes]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value);
    const handleClearFilter = () => setFilter("");

    React.useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape") handleClearFilter();
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, []);

    const formatRouteName = React.useCallback((route: string): string => {
        return route.replace(/^admin_/, "").replace(/_/g, " ");
    }, []);
    const [openEditors, setOpenEditors] = React.useState<{ [key: string]: boolean }>({});

    const isOpen = React.useMemo(() => (name: string) => {
        return !openEditors[name];
    }, [openEditors]);
    const toggleEditor = React.useMemo(() => (name: string) => () => {
        setOpenEditors(ce => {
            const newCe = _.clone(ce);
            if (newCe[name])
                newCe[name] = false;
            else
                newCe[name] = true;
            return newCe;
        })
    }, []);
    const hasEntries = React.useMemo(
        () => _.some(filteredRoutes, (group) => group.length > 0),
        [filteredRoutes]
    );
    return (
        <div>
            <div style={{ marginTop: 20, display: "grid", gridTemplateColumns: "1fr 250px max-content", columnGap: 5, height: 60, padding: "10px 0" }}>
                <div style={{ lineHeight: "40px", fontSize: "1.3em", fontWeight: "bolder" }}>Admin Start</div>
                <div style={{}}>
                    <Input
                        value={filter}
                        icon='search'
                        onChange={handleInputChange}
                        placeholder="Filter admin sites..."
                    />
                </div>
                <div>
                    <Button onClick={handleClearFilter} icon="times" kind="primary" disabled={filter === ""} />
                </div>
            </div>
            <div style={{ textTransform: "capitalize" }}>
                {_.map(groupOrder, (groupName) => (
                    filteredRoutes[groupName]?.length > 0 && (
                        <div key={groupName}>
                            <EditPartContainer
                                icon="cog"
                                title={groupName}
                                isOpen={isOpen}
                                toggleOpen={toggleEditor}
                                bold
                            >
                                <Container gapH={gapH}>
                                    {_.map(filteredRoutes[groupName], (routeDef, idx) => {
                                        if (routeDef.access === false) return null;
                                        return (
                                            <Item onClick={gotoNamedRouteMethod(routeDef.route)} buttonMinWidth={buttonMinWidth} key={idx}>
                                                <Button
                                                    kind="transparentButton"
                                                    onClick={gotoNamedRouteMethod(routeDef.route)}
                                                    content={routeDef.text ?? formatRouteName(routeDef.route)}
                                                    fluid
                                                    tooltip={{ tooltipText: routeDef.route, position: "bottom" }}
                                                />
                                            </Item>
                                        );
                                    })}
                                </Container>
                            </EditPartContainer>
                        </div>
                    )
                ))}
            </div>
            <Info>
                <Icon name="info" style={{ float: "left", marginRight: 5 }} color='@middleLightGrey' marginTop={1} />
                You may not be able to see all admin entries, depending on your authorisations.
                {filter !== "" &&
                    <Alert onClick={handleClearFilter}>
                        {!hasEntries && (
                            <div style={{ fontWeight: "bolder" }}>No entries found matching your filter criteria!</div>
                        )}
                        <div>press esc to reset filter</div>
                    </Alert>}
            </Info>
        </div>
    );
};
export default AdminStart;