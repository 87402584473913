import { slideInLeft, slideInRight } from "components/User/UserSC";
import { fadeIn } from "components/Wiki/WikiSC";
import {
  appendPx,
  belowBreakpointOrEqual,
  fadeColor,
  fromThemeCreator,
  getColor,
  Icon,
  keyframes,
  lightenColor,
  styled,
} from "imaginarity-react-ui";
import { ContentTheme } from "views/ContentPage/ContentMain";
import { WikiTheme } from "views/Wiki/WikiTheme";
import { cmsConfig } from "./CMSConfig";
import { CMSTheme } from "./CMSTheme";

const fromTheme = fromThemeCreator<WikiTheme>("wikiTheme");
const selectFromTheme = fromThemeCreator<ContentTheme>("contentTheme");
const fromCmsTheme = fromThemeCreator<CMSTheme>("cmsTheme");

const flicker = keyframes`
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  `;

export namespace CMSSC {
  export const CmsAppIcon = styled.div<{ url: string; margin?: string }>`
    /* display: inline-block; */
    float: left;
    width: 20px;
    height: 20px;
    ${(p) => `margin: ${p.margin ?? "9px 6px 0px 0px"}`};
    background-image: url(${(p) => p.url});
    background-repeat: no-repeat;
    background-position: center left;
    background-size: contain;
  `;
  export const CategoryHeaderContainerHome = styled.div<{
    isContent?: boolean;
  }>`
    width:100%;
    position:relative;
    height: ${(props) =>
      appendPx(
        fromTheme(
          props,
          (t) => t.searchContainerHeight,
          (t) => 127
        )
      )};
    border-bottom: 1px solid ${(props) =>
      lightenColor(
        props,
        getColor(props, props.theme.colors.veryLightGrey),
        95
      )};
    margin-bottom:10px;
    button>div{margin: 0;}
    z-index: 50;
    &::before {
        content: "";
        position: absolute;
        top: 0; 
        left: 0;
        width: 100%; 
        height: 100%;
        background:${(props) => lightenColor(props, getColor(props, props.theme.colors.veryLightGrey), 100)};
        background-image: url(${(p) => fromCmsTheme(p, (t) => p.isContent ? t.headerImage.headerContentImageUrl : t.headerImage.headerImageUrl, (t) => "")});
        background-repeat: ${(p) => fromCmsTheme(p, (t) => t.headerImage.backgroundRepeat, (t) => "no-repeat")};
        background-position: ${(p) => fromCmsTheme(p, (t) => t.headerImage.backgroundPosition, (t) => "center center")};
        background-size:${(p) => fromCmsTheme(p, (t) => t.headerImage.backgroundSize, (t) => "cover")};
    }
 `;

  export const CategoryHeaderContainerHomeResult = styled(
    CategoryHeaderContainerHome
  )`
    &::before {
      //filter: grayscale(100%) opacity(0.4);
    }
  `;
  export const CategorySearchContainerSwitcherRight = styled.div<{
    active: boolean;
  }>`
    //cursor: pointer;
    width: 16px;
    position: absolute;
    right: 10px;
    top: 8px;
    transition: 0.2s all ease-out;
    &:hover {
      svg {
        stroke: ${(p) =>
      p.active ? getColor(p, "@accentBlack") : getColor(p, "@accent")};
      }
    }
  `;
  export const CategorySearchContainerSwitcherMiddle = styled.div<{
    active: boolean;
  }>`
    //cursor: pointer;
    position: absolute;
    right: 30px;
    width: 16px;
    top: 8px;
    transition: 0.2s all ease-out;
    &:hover {
      svg {
        stroke: ${(p) =>
      p.active ? getColor(p, "@accentBlack") : getColor(p, "@accent")};
      }
    }
  `;
  export const CategorySearchContainerSwitcherLeft = styled.div<{
    active: boolean;
  }>`
    //cursor: pointer;
    position: absolute;
    right: 50px;
    width: 16px;
    top: 8px;
    transition: 0.2s all ease-out;
    &:hover {
      svg {
        stroke: ${(p) =>
      p.active ? getColor(p, "@accentBlack") : getColor(p, "@accent")};
      }
    }
  `;
  export const CategorySearchContainerHome = styled.div`
    position: absolute;
    opacity: 1;
    z-index: 2147483630;
    top: ${(props) => (belowBreakpointOrEqual(props, "tablet") ? "0%" : "50%")};
    left: ${(props) =>
      belowBreakpointOrEqual(props, "tablet") ? "10px" : "30%"};
    width: ${(props) =>
      belowBreakpointOrEqual(props, "tablet") ? "calc(100% - 20px)" : "40%"};
    transform: translateY(
      ${(props) => (belowBreakpointOrEqual(props, "tablet") ? "50%" : "-50%")}
    );
    & span {
      margin-right: 33px !important;
    }
  `;
  export const CategorySearchContainerSwitcher = styled.div<{
    active: boolean;
  }>`
    float: right;
    //font-size: 0.8em;
    line-height: 26px;
    margin-top: 7px;
    font-weight: 100;
    padding: 0 10px;
    cursor: ${(p) => (p.active ? "default" : "pointer")};
    transition: 0.2s all ease-out;
    color: ${(p) =>
      p.active
        ? getColor(p, "@mainBackground")
        : fadeColor(p, "@mainBackground", 80)};
    text-shadow: 0 0 5px #000;
    &:hover {
      color: ${(p) => getColor(p, "@mainBackground")};
    }
  `;
  export const CMSHomeTilePID = styled.div`
    position: absolute;
    text-align: right;
    padding-right: 5px;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    //font-weight: bolder;
    color: ${(p) => getColor(p, p.theme.colors.mainBackground)};
    z-index: 2147483630;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-image: linear-gradient(
      to left,
      ${(p) => fadeColor(p, p.theme.colors.darkerGrey, 80)},
      transparent 90%
    );
  `;
  export const CategoryPane = styled.div<{
    paneWidth: number;
    copyMode?: boolean;
    isDisabled?: boolean;
  }>`
        position: relative;
        width: ${(props) => props.paneWidth}px;
        height: calc((${(props) => props.paneWidth}px * 9 / 16) + 60px);
        border: ${(p) =>
      p.copyMode
        ? "1px solid " + getColor(p, p.theme.colors.middleLightGrey)
        : "none"};
        border-bottom: 1px solid ${(p) =>
      p.copyMode
        ? getColor(p, p.theme.colors.middleLightGrey)
        : getColor(p, p.theme.colors.mainBorderColor)};
        text-align:left;
        cursor: pointer;
        transition: 0.1s all ease-out;
        animation: ${fadeIn} 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
        background: ${(p) =>
      p.copyMode
        ? getColor(p, p.theme.colors.veryLightGrey)
        : getColor(p, p.theme.colors.mainBackground)};
        position: relative;
        box-shadow: 0 0 0 0 transparent;
        color: ${(props) => getColor(props, props.theme.colors.mainForeground)};
        ${(p) =>
      p.isDisabled
        ? ""
        : `
        &:hover{
            border-bottom-color: ${getColor(p, p.theme.colors.accent)};
            background: ${lightenColor(p, p.theme.colors.mainBackground, 95)};
            color: ${lightenColor(p, p.theme.colors.mainForeground, 60)};
            box-shadow: 0 6px 8px -6px ${fadeColor(
          p,
          p.theme.colors.mainForeground,
          60
        )};
        }
        `}
        &:active{
            color: ${(props) => getColor(props, props.theme.colors.accent)};
        }
`;

  export const CategoryPaneInfo = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 12px;
    background: ${(props) =>
      getColor(props, props.theme.colors.mainBackground)};
    border: 20px solid ${(props) => getColor(props, props.theme.colors.middleLightGrey)};
    svg{
        stroke-width: 2;
    }
`;
  export const CategoryPaneImage = styled.div<{ image: string }>`
        transition: 0.2s all ease-out;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        aspect-ratio: 16 / 9;
        background: url('${(p) => p.image}');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
`;
  export const Hint = styled.div`
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background: ${(p) => fadeColor(p, "@mainBackground", 90)};
        color: ${(p) => getColor(p, "@accentGreen")};
        border-top: 1px solid ${(p) => getColor(p, "@accentGreen")};
        border-bottom: 1px solid ${(p) => getColor(p, "@accentGreen")};
        padding-left: 5px;
        padding-right: 5px;
        line-height: 40px;
`;
  export const CategoryPaneImageBg = styled.div`
    transition: 0.2s all ease-out;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    aspect-ratio: 16 / 9;
    background: ${(p) => getColor(p, "@veryLightGrey")};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  `;
  export const CategoryPaneCopyHint = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    font-size: 1em;
    font-weight: 100;
    color: ${(props) => getColor(props, props.theme.colors.mainBackground)};
    background: ${(props) =>
      fadeColor(props, getColor(props, props.theme.colors.darkerGrey), 70)};
    line-height: 40px;
    height: 40px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    animation: ${fadeIn} 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    text-shadow: 0px 0px 8px
      ${(props) =>
      fadeColor(
        props,
        getColor(props, props.theme.colors.mainBackground),
        80
      )};
  `;
  export const CategoryPaneDeleteHint = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    font-size: 0.85em;
    color: ${(props) => getColor(props, props.theme.colors.accent)};
    background: ${(props) =>
      getColor(props, props.theme.colors.mainBackground)};
    line-height: 40px;
    height: 60px;
    text-align: left;
    font-weight: bolder;
    padding: 10px 0 10px 5px;
    animation: ${fadeIn} 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    text-shadow: 0px 0px 8px
      ${(props) =>
      fadeColor(
        props,
        getColor(props, props.theme.colors.mainBackground),
        80
      )};
  `;
  export const CategoryPaneButton = styled.div<{ isDisabled?: boolean }>`
    position: absolute;
    bottom: 5px;
    background: cyan;
    right: 5px;
    svg {
      stroke: ${(p) => getColor(p, p.theme.colors.lightGrey)};
    }
    ${(p) =>
      p.isDisabled
        ? ""
        : `&:hover{svg{stroke: ${getColor(p, p.theme.colors.darkerGrey)};}}`};
  `;
  export const CategoryPaneButton2 = styled.div`
    position: absolute;
    bottom: 5px;
    right: 45px;
    background: pink;
    svg {
      stroke: ${(p) => getColor(p, p.theme.colors.lightGrey)};
    }
    &:hover {
      svg {
        stroke: ${(p) => getColor(p, p.theme.colors.darkerGrey)};
      }
    }
  `;
  export const CategoryPaneButton3 = styled.div`
    position: absolute;
    bottom: 5px;
    right: 25px;
    background: gold;
    svg {
      stroke: ${(p) => getColor(p, p.theme.colors.lightGrey)};
    }
    &:hover {
      svg {
        stroke: ${(p) => getColor(p, p.theme.colors.darkerGrey)};
      }
    }
  `;

  export const CategorySearchContainerHomeTitle = styled.div<{
    isMobile: boolean;
  }>`
    position: absolute;
    text-align: left;
    bottom: ${(p) => (p.isMobile ? "0px" : "0px")};
    left: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: ${(p) => (p.isMobile ? "10px" : "20px")};
    font-size: ${(p) => (p.isMobile ? "1.1rem" : "1.2rem")};
    //font-weight: bolder;
    color: ${(p) => getColor(p, p.theme.colors.mainBackground)};
    z-index: 2147483630;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    //text-transform: uppercase;
    text-shadow: 0px 0px 6px ${(p) => getColor(p, p.theme.colors.darkerGrey)};
    background-image: linear-gradient(
        to right,
        ${(p) => fadeColor(p, p.theme.colors.darkerGrey, 80)},
        transparent 70%
      ),
      linear-gradient(
        to left,
        ${(p) => fadeColor(p, p.theme.colors.darkerGrey, 80)},
        transparent 30%
      );
    svg {
      stroke-width: 2.1px;
      stroke: ${(p) => getColor(p, p.theme.colors.mainBackground)};
    }
  `;

  export const CategoryPaneFooterContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 5px;
  `;

  export const CategoryPaneFooterTitle = styled.div`
    font-weight: 500;
    line-height: 25px;
    font-size: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 4;
  `;
  export const CategoryPaneFooterName = styled.div`
    font-weight: 300;
    line-height: 25px;
    font-size: 0.8rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    *{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    }
  `;
  export const CategoryPaneFooterFiles = styled.div`
    font-weight: 300;
    line-height: 25px;
    font-size: 0.8rem;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
    padding-right: 5px;
  `;
  export const CategoryPaneFooterBtn = styled.div<{ isDisabled?: boolean }>`
    float: right;
    margin-left: 5px;
    svg {
      stroke: ${(p) => getColor(p, p.theme.colors.lightGrey)};
    }
    ${(p) =>
      p.isDisabled
        ? ""
        : `&:hover{svg{stroke: ${getColor(p, p.theme.colors.darkerGrey)};}}`};
  `;

  export const CategoryPaneAdmin = styled.div`
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 87px;
    font-weight: 300;
    line-height: 25px;
    font-size: 0.8rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    background: green;
  `;
  export const CategoryPaneFiles = styled.div`
    position: absolute;
    bottom: 5px;
    left: calc(100% - 135px);
    right: 65px;
    font-weight: 300;
    line-height: 25px;
    font-size: 0.8rem;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    background: orange;
  `;

  export const WarningHistoricalView = styled.div`
    background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
    border-left: 3px solid ${(p) => getColor(p, p.theme.colors.accent)};
    padding-left: 45px;
    padding-bottom: 20px;
    //margin-top: -20px;
    font-weight: bolder;
    position: relative;
  `;
  export const WHVitem = styled.div`
    display: block;
    width: 100%;
    line-height: 1.2rem;
    height: 1.2rem;
    svg {
      float: left;
      margin-right: 5px;
      margin-top: 10px;
      stroke: ${(p) => getColor(p, p.theme.colors.accent)};
    }
  `;

  export const CategoryHeaderContainer = styled.div`
    width: ${(p) => p.theme.content.width}px;
    background: ${(p) => getColor(p, p.theme.colors.mainBackground)};
    height: fit-content;
    z-index: 10;
    padding: 5px 0;
    margin: 10px 0 40px 0;
  `;
  export const TitCont = styled.div`
    position: relative;
    button>div{margin: 0;}
  `;
  export const InfoContainer = styled.div`
    width: 100%;
    position: relative;
  `;
  export const InfoContainerButton = styled.div`
    position: absolute;
    width: 40px;
    top: 0;
    right: 1px;
    transition: all 0.2s ease-out;
    &:hover {
      background: rgba(0, 0, 0, 0.05);
      svg {
        stroke-width: 3;
      }
    }
  `;

  export const Breadcrumb = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;
  export const BreadcrumbActiveSite = styled.span`
    margin-top: 2px;
  `;
  export const TopButtonBar = styled.div<{ noSearch?: boolean }>`
    margin-top: 20px;
    margin-bottom: 5px;
    width: 100%;
    display: grid;
    grid-template-columns: max-content 1fr ${(p) =>
      !p.noSearch
        ? belowBreakpointOrEqual(p, "tablet")
          ? "max-content"
          : "200px"
        : 0};
    grid-gap: 5px;
    button > div {
      margin: 0;
    }
  `;

  export const BlankContainer = styled.div<{
    float?: "left" | "right";
    width?: number;
    height?: number;
    background?: string;
  }>`
    float: ${(p) => p.float ?? "unset"};
    width: ${(p) => (p.width ? appendPx(p.width) : "unset")};
    height: ${(p) => (p.height ? appendPx(p.height) : "unset")};
    background: ${(p) => (p.background ? getColor(p, p.background) : "unset")};
  `;
  export const RefHelper = styled.div`
    height: 0;
  `;
  export const TBBLeft = styled.div<{ smallDevice: boolean }>`
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: ${(p) => (p.smallDevice ? 4 : 2)};
  `;

  export const TBBRight = styled.div`
    position: relative;
    grid-row: 1;
    grid-column-start: 2;
    grid-column-end: 3;
  `;

  export const InputFilterContainer = styled.div`
    position: relative;
    input {
      padding-right: 30px;
      height: 38px;
    }
    input::placeholder {
      color: ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    }
    svg {
      margin-top: 3px;
    }
  `;
  export const InputFilterContainerCB = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    svg {
      margin-top: -3px;
    }
  `;

  export const InputResetIcon = styled.div`
    position: absolute;
    top: 4px;
    right: 12px;
    transition: all 0.2s ease-out;
    cursor: pointer;
    svg {
      stroke: ${(p) => getColor(p, p.theme.colors.lightGrey)};
    }
    &:hover {
      svg {
        stroke: ${(p) => getColor(p, p.theme.colors.accent)};
        stroke-width: 2;
      }
    }
  `;
  export const TopButtonBarSpacer = styled.div`
    float: left;
    width: 5px;
    height: 40px;
  `;
  export const TopButtonBarSpacerRight = styled.div`
    float: right;
    width: 5px;
    height: 40px;
  `;
  export const TopButtonBarSpacerLeft = styled.div`
    float: left;
    width: 5px;
    height: 40px;
  `;
  export const LegendItemNew = styled.div`
    width: fit-content;
    margin-right: 15px;
    display: inline-block;
    font-size: 0.8rem;
    color: ${(p) => getColor(p, p.theme.colors.lightGrey)};
    border: 1px solid transparent;
  `;
  export const LegendItem = styled.div`
    width: fit-content;
    margin-right: 15px;
    display: inline-block;
    font-size: 0.8rem;
    cursor: help;
    border: 1px solid transparent;
    transition: all 0.2s ease-out;
    &:hover {
      color: ${(p) => getColor(p, p.theme.colors.mainForeground)};
      svg {
        stroke: ${(p) => getColor(p, p.theme.colors.mainForeground)};
      }
    }
  `;
  export const TableGridOuterContainer = styled.div`
    position: relative;
  `;
  export const MoreResultsButtons = styled.div`
        height: 40px;
        border-top: 1px solid ${(p) =>
      getColor(p, p.theme.colors.middleLightGrey)};
        //background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
`;
  export const TableGridLegend = styled.div`
    width: 100%;
    padding-left: 5px;
    padding-bottom: 200px;
    line-height: 20px;
    margin-bottom: 40px;
    margin-top: 10px;
    z-index: 60;
    border-top: 1px solid ${(p) => getColor(p, p.theme.colors.mainBorderColor)};
  `;
  export const LegendContainer = styled.div`
    border-top: 1px solid ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    width: calc(100% - 10px);
    line-height: 40px;
    margin-bottom: 40px;
    margin-left: -5px;
    margin-top: -1px;
  `;
  export const TableGrid = styled.div<{ header?: boolean }>`
    display: grid;
    grid-template-columns: 50px 1fr 100px 100px 150px 200px 50px;
    grid-template-rows: 1fr;
    margin: 2px 0;
    line-height: 40px;
    font-weight: ${(p) => (p.header ? "bolder" : "normal")};
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-bottom: 1px solid
      ${(p) =>
      p.header
        ? getColor(p, p.theme.colors.middleLightGrey)
        : getColor(p, p.theme.colors.veryLightGrey)};
    z-index: 1;
    cursor: ${(p) => (p.header ? "default" : "pointer")};
    transition: all 0.2s ease-out;
    &:hover {
      background: ${(p) =>
      p.header ? "inherit" : getColor(p, p.theme.colors.veryLightGrey)};
    }
  `;
  export const TableGridCheckbox = styled.div`
    grid-column: 1;
  `;
  export const TableGridName = styled.div`
    grid-column: 2;
    padding: 0 10px;
  `;
  export const TableGridIcons = styled.div`
    grid-column: 3;
    padding: 0 10px;
  `;
  export const TableGridVersion = styled.div`
    grid-column: 4;
    padding: 0 10px;
  `;
  export const TableGridChanged = styled.div`
    grid-column: 5;
    padding: 0 10px;
  `;
  export const TableGridWorkflow = styled.div`
    grid-column: 6;
    padding: 0 10px;
  `;
  export const TableGridMore = styled.div`
    grid-column: 7;
    z-index: 5;
  `;
  export const TableGridMoreMenuItem = styled.div<{
    disabled?: boolean;
    rightButtons?: boolean;
    warning?: boolean;
  }>`
    display: grid;
    grid-template-columns: 39px 210px ${(p) =>
      p.rightButtons ? " 40px 40px" : ""};
    grid-template-rows: 40px;
    line-height: 40px;
    font-weight: normal;
    transition: all 0.2s ease-out;
    color: ${(p) =>
      p.warning
        ? lightenColor(p, p.theme.colors.accentRed, 90)
        : p.disabled
          ? getColor(p, p.theme.colors.lightGrey)
          : getColor(p, p.theme.colors.darkerGrey)};
    background: ${(p) =>
      p.warning
        ? getColor(p, p.theme.colors.veryLightGrey)
        : p.disabled
          ? "inherit"
          : getColor(p, p.theme.colors.mainBackground)};
    cursor: ${(p) => (p.disabled ? "default" : "pointer")};
    svg {
      stroke: ${(p) =>
      p.warning
        ? lightenColor(p, p.theme.colors.accentRed, 90)
        : p.disabled
          ? getColor(p, p.theme.colors.lightGrey)
          : getColor(p, p.theme.colors.darkerGrey)};
    }
    &:hover {
      background: ${(p) =>
      p.warning
        ? lightenColor(p, p.theme.colors.veryLightGrey, 98)
        : p.disabled
          ? "inherit"
          : getColor(p, p.theme.colors.veryLightGrey)};
      svg {
        stroke: ${(p) =>
      p.warning
        ? lightenColor(p, p.theme.colors.accentRed, 90)
        : p.disabled
          ? getColor(p, p.theme.colors.lightGrey)
          : getColor(p, p.theme.colors.darkerGrey)};
      }
    }
  `;
  export const TableGridMoreMenuItemText = styled.div`
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `;
  export const TableGridMoreMenuItemSpacer = styled.div`
    height: 1px;
    width: 100%;
    background: ${(p) => getColor(p, "@accent")};
  `;
  export const TableGridMoreMenu = styled.div<{
    left?: boolean;
    isInUpperScreen?: boolean;
  }>`
    position: absolute;
    bottom: ${(p) => (p.isInUpperScreen === true ? "unset" : "-40px")};
    top: ${(p) => (p.isInUpperScreen === true ? "0px" : "unset")};
    ${(p) => (p.left ? "left" : "right")}: 40px;
    background: ${(p) => getColor(p, p.theme.colors.mainBackground)};
    border: 1px solid ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    box-shadow: 0 6px 8px -6px ${(p) => getColor(p, p.theme.colors.mainForeground)};
    z-index: 2147483637;
    font-size: 0.9rem;
  `;
  export const TableGridMoreMenuLegend = styled.div`
    font-weight: normal;
    padding-top: 5px;
    padding-left: 10px;
    width: 100%;
    line-height: 20px;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 24px 24px;
    background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
    cursor: default;
  `;

  export const TableGridMoreMenuLegendLeft = styled.div`
    text-align: left;
  `;
  export const TableGridMoreMenuLegendRight = styled.div`
    text-align: right;
  `;
  export const TableGridMoreMenuLegendLeftChecked = styled.div`
    text-align: left;
    svg {
      stroke: #fff;
    }
  `;

  export const TableGridMoreMenuDivider = styled.div<{ left?: boolean }>`
    height: 1px;
    background: ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    width: 100%;
    margin: 0;
  `;

  export const RolesGrid = styled.div<{
    editMode: boolean;
    deleteConfirm: boolean;
    isHeader?: boolean;
  }>`
    display: grid;
    grid-template-columns: max-content 25% 25% 1fr;
    grid-template-rows: 1fr;
    padding-top: ${(p) => (p.isHeader ? 0 : p.editMode ? "0px" : "0px")};
    padding-bottom: ${(p) => (p.isHeader ? 0 : p.editMode ? "0px" : "0px")};
    margin: 2px;
    transition: 0.2s all ease-out;
    border: 1px solid ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    font-weight: ${(p) => (p.isHeader ? "bolder" : "normal")};
    &:last-child {
      margin-bottom: 0px;
    }
    &:hover {
      color: ${(p) =>
      p.isHeader ? "inherit" : getColor(p, p.theme.colors.accentBlack)};
      background: ${(p) => (p.isHeader ? "inherit" : "inherit")};
      border-bottom: 1px solid
        ${(p) =>
      p.isHeader ? "inherit" : getColor(p, p.theme.colors.middleLightGrey)};
    }
  `;
  export const RolesGridFooter = styled(RolesGrid)`
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -2px;
    border: 1px solid ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    font-weight: normal;
    background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
    border-top: none;
    box-shadow: 0 6px 8px -6px ${(p) => getColor(p, p.theme.colors.mainForeground)};
    &:hover {
      color: inherit;
      background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
      border-bottom: 1px solid
        ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    }
  `;
  export const Pos1 = styled.div<{ editMode: boolean; isHeader?: boolean }>`
    grid-column: 1;
    border-right: 1px solid
      ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    button > div {
      margin: 0;
    }
  `;
  export const Pos2 = styled.div<{ editMode?: boolean; error?: boolean }>`
    grid-column: 2;
    line-height: 20px;
    padding: ${(p) => (p.editMode ? 0 : 10)}px;
    //font-weight: bolder;
    background: ${(p) =>
      p.editMode
        ? p.error
          ? fadeColor(p, "@accentRed", 10)
          : fadeColor(p, "@accentGreen", 10)
        : "inherit"};
    border-right: 1px solid
      ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
  `;
  export const Pos3 = styled.div<{ editMode?: boolean; error?: boolean }>`
    grid-column: 3;
    padding: ${(p) => (p.editMode ? 0 : 10)}px;
    line-height: 20px;
    border-right: 1px solid
      ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    background: ${(p) =>
      p.editMode
        ? p.error
          ? fadeColor(p, "@accentRed", 10)
          : fadeColor(p, "@accentGreen", 10)
        : "inherit"};
  `;
  export const Pos4 = styled.div<{
    editMode: boolean;
    isHeader?: boolean;
    error?: boolean;
  }>`
        grid-column: 4;
        padding: ${(p) => (p.isHeader ? 10 : p.editMode ? 0 : 10)}px;
        padding-left: ${(p) => (p.isHeader ? 10 : p.editMode ? 0 : 0)}px;
        background: ${(p) =>
      p.editMode
        ? p.error
          ? fadeColor(p, "@accentRed", 10)
          : fadeColor(p, "@accentGreen", 10)
        : "inherit"};
    `;

  export const Pos1Warning = styled.div`
    line-height: 30px;
    margin-left: 5px;
    font-size: 0.9em;
    color: ${(p) => getColor(p, p.theme.colors.accentRed)};
    font-weight: 400;
    animation: ${slideInLeft} 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  `;
  export const Pos5 = styled.div`
    grid-column: 5;
    padding-right: 2px;
    padding-top: 2px;
    button > div {
      margin: 0;
    }
  `;
  const entrance = keyframes`
    from {background: darkred; color: #fff;}
    to {background: #FFF; color: rgb(49, 54, 57);}
    `;
  export const ProjectRolesGrid = styled.div<{ confirmed?: boolean }>`
        display: grid;
        //grid-template-columns: ${(p) => (p.confirmed ? 80 : 40)}px 150px 1fr;
        grid-template-columns:  50px minmax(150px, max-content) 1fr ${(p) =>
      p.confirmed ? 90 : 50}px;
        margin-bottom: 2px;
        transition: 0.3s all ease-out;
        //animation: ${entrance} 0.2s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    &:last-child{
        margin-bottom: 0px;
    }
    &:hover{
        color: ${(p) => getColor(p, p.theme.colors.accentBlack)};
    }
    `;
  export const StickyTop = styled.div<{ position: string }>`
    position: ${(p) => p.position};
    top: ${(p) => (p.position === "fixed" ? "60px" : "unset")};
    width: ${(p) =>
      p.position === "fixed" ? p.theme.content.width - 20 + "px" : "100%"};
    z-index: 1;
    background-color: ${(p) =>
      p.position === "fixed"
        ? getColor(p, p.theme.colors.mainBackground)
        : "unset"};
  `;
  export const ContentArea = styled.div<{ position: string }>`
        //position: ${(p) => p.position};
        //top: ${(p) => (p.position === "fixed" ? "170px" : "unset")};
        //width:  ${(p) => p.position === "fixed" ? p.theme.content.width - 20 + "px" : "100%"};
        //height: ${(p) => p.position === "fixed" ? "calc(100vh - 220px)" : "auto"}; 
        //overflow-y: auto;
        //overflow-x: hidden;
        z-index: 1;
`;

  export const UploadContainer = styled.div<{ open?: boolean }>`
    ${(p) => (p.open ? "" : "display: none;")}
    width:  100%;
    border: 1px solid
      ${(p) =>
      p.open ? getColor(p, p.theme.colors.middleLightGrey) : "transparent"};
    padding: 0;
    height: ${(p) => (p.open ? "unset" : "0px")};
    min-height: ${(p) => (p.open ? "150px" : "0px")};
    opacity: ${(p) => (p.open ? 1 : 0)};
    transition: 0.2s all ease-out;
    box-shadow: 0 6px 8px -6px ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    background: ${(p) => getColor(p, p.theme.colors.mainBackground)};
    margin-bottom: ${(p) => (p.open ? 40 : 0)}px;
    & > button {
      margin: 0;
    }
    position: relative;
  `;
  export const UploadContainerTitle = styled.div`
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid
      ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
    padding-left: 10px;
    font-size: 0.9rem;
    font-weight: bolder;
    & > button {
      margin: 0;
    }
  `;
  export const UploadContainerForm = styled.div`
    width: 100%;
    padding: 0 10px;
    margin-top: 10px;
    button > div {
      margin: 0;
    }
  `;

  export const UploadContainerInfo = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    grid-template-columns: max-content max-content;
  `;
  export const UploadContainerInfoText = styled.div`
    height: 40px;
    line-height: 40px;
    padding-right: 10px;
    color: ${(p) => getColor(p, p.theme.colors.accentRed)};
    svg {
      stroke: ${(p) => getColor(p, p.theme.colors.accentRed)};
    }
  `;
  export const EditProjectContainerInfoText = styled(UploadContainerInfoText)`
    position: absolute;
    top: 0;
    right: 10px;
    padding-right: 0px;
  `;

  export const FileTitleContainer = styled.div`
    width: 100%;
    height: 40px;
    line-height: 40px;
    //border-bottom: 1px solid ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    font-weight: bolder;
    padding-left: 10px;
    margin-top: 20px;
    button>div{
        margin: 0;
    }
`;
  export const WarningContainer = styled.div`
    width: 100%;
    height: 40px;
    line-height: 40px;
    color: ${(p) => getColor(p, p.theme.colors.accent)};
  `;

  export const WarningContainerNoResults = styled.div`
    width: 100%;
    height: 40px;
    color: ${(p) => getColor(p, p.theme.colors.accent)};
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-column-gap: 5px;
    margin-top: 20px;
  `;
  export const WarningContainerNoResultsInfo = styled.div`
    width: 100%;
    height: 40px;
    line-height: 40px;
    border: 1px solid ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    color: ${(p) => getColor(p, p.theme.colors.accent)};
  `;
  export const FilePreviewAreaContainer = styled.div<{ height?: number }>`
    position: relative;
    width: 100%;
    height: ${(p) => (p.height ? p.height : 110)}px;
    //background-color: ${(p) => getColor(p, "@veryLightGrey")};
    //padding: 10px;

    .slider-control-left-bottom{
        cursor: pointer;
        //margin-left: -15px;
        transition: all 0.2s ease-out;
        svg{
            stroke: ${(p) =>
      belowBreakpointOrEqual(p, "mobile")
        ? getColor(p, p.theme.colors.mainForeground)
        : getColor(p, p.theme.colors.veryLightGrey)};
        &:hover{
            stroke: ${(p) =>
      belowBreakpointOrEqual(p, "mobile")
        ? getColor(p, p.theme.colors.darkerGrey)
        : getColor(p, p.theme.colors.middleLightGrey)};
        }
        }
    }
    .slider-control-right-bottom{
        cursor: pointer;
        margin-bottom: -5px;
        transition: all 0.2s ease-out;
        svg{
            stroke: ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
        &:hover{
            stroke: ${(p) => getColor(p, p.theme.colors.lightGrey)};
            }
        }
    }
    .slider-control-centerright{
        display: none;
    }
    .slider-control-left-bottom{
        cursor: pointer;
        margin-bottom: -5px;
        transition: all 0.2s ease-out;
        svg{
            stroke: ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
        &:hover{
            stroke: ${(p) => getColor(p, p.theme.colors.lightGrey)};
            }
        }
    }
    .slider-control-centerleft{
        display: none;
    }
    .slider-control-bottomcenter {
        position: absolute;
        bottom: ${(p) => (belowBreakpointOrEqual(p, "tablet") ? 0 : 0)};
        left: 50%;
        transform: translateX(-50%);

    ul {
        position: relative;
        top: 0px;
        display: flex;
        margin: 0px;
        padding: 0px;
        list-style-type: none;

        li {
            button {
                cursor: pointer;
                opacity: 1;
                background: transparent;
                border: none;
                fill: ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
                padding: ${(p) =>
      belowBreakpointOrEqual(p, "tablet") ? "1px 3px" : "1px 6px"};
                &:hover{
                    fill: ${(p) => getColor(p, p.theme.colors.lightGrey)};
                }
            }
        }
    }

    .active {
        button {
            fill: ${(p) => getColor(p, p.theme.colors.accent)}!important;
        }
    }
}
`;
  export const FilePreviewArea = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  `;

  export const FilePreviewItem = styled.img<{ single: boolean }>`
        //border: ${(p) => p.single ? "none" : "1px solid " + getColor(p, p.theme.colors.middleLightGrey)};
        padding: 10px;
        height: 100px;
        width: auto;
        max-width: 100%;
        object-fit: contain;
        background: ${(p) => getColor(p, p.theme.colors.mainBackground)};
        box-shadow: 0 3px 4px -3px ${(p) => getColor(p, p.theme.colors.mainForeground)};
`;

  export const FileGrid = styled.div<{ borderColor?: string }>`
    display: grid;
    grid-template-columns: ${(props) =>
      belowBreakpointOrEqual(props, "tablet")
        ? "110px"
        : "187px"} 1fr 2fr max-content;
    grid-template-rows: max-content;
    margin-bottom: -10px;
    background: ${(p) => getColor(p, p.theme.colors.mainBackground)};
    width: 100%;
    transition: 0.2s all ease-out;
    ${(p) =>
      p.borderColor ? `border: 1px solid ${getColor(p, p.borderColor)};` : ""}
  `;
  export const FileLabel = styled.div`
    line-height: 40px;
    grid-column-start: 1;
    grid-column-end: 2;
    padding-left: 10px;
    align-self: start;
    //font-weight: bolder;
  `;
  export const FileContentVersions = styled.div`
    margin-left: 10px;
    font-size: 1em;
    color: ${(p) => getColor(p, p.theme.colors.darkGrey)};
    border: 1px solid ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    padding: 0;
    width: calc(100% - 10px);
  `;
  export const CMSFileEntryAppIconContainer = styled.div`
    & > div {
      margin-top: -2px;
      margin-right: -2px;
      float: right;
      width: 40px;
      height: 40px;
    }
  `;
  export const MissingItem = styled.div`
    color: ${(p) => getColor(p, p.theme.colors.accentRed)};
  `;
  export const SaveInfo = styled.div`
    color: ${(p) => getColor(p, p.theme.colors.accentRed)};
    float: right;
    margin-right: 10px;
`;
  export const FileContent = styled.div<{
    start: number;
    end: number;
    asListItem?: boolean;
    borderTopColor?: string;
  }>`
    line-height: 40px;
    grid-column-start: ${(p) => p.start};
    grid-column-end: ${(p) => p.end};
    border-bottom: ${(p) =>
      p.asListItem
        ? "1px solid " + getColor(p, p.theme.colors.veryLightGrey)
        : "none"};
    align-self: center;
    padding-left: ${(props) =>
      belowBreakpointOrEqual(props, "tablet") ? 10 : 0}px;
    button > div {
      margin: 0;
    }
    ${(p) =>
      p.borderTopColor
        ? `border-top: 1px solid ${getColor(p, p.borderTopColor)};`
        : ""}
    //text-overflow: ellipsis;
        //white-space: nowrap;
        //overflow: hidden;
        em {
      color: ${(p) => getColor(p, p.theme.colors.lightGrey)};
      font-style: normal;
      font-size: 0.9em;
    }
    audio {
      border: 1px solid ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
      background-color: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
      box-shadow: 0 6px 8px -6px ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
      &::-webkit-media-controls-enclosure {
        border-radius: 0;
      }
    }
  `;
  export const FileContentLink = styled.div`
    display: inline-block;
    font-weight: bolder;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
      color: ${(p) => getColor(p, p.theme.colors.accent)};
    }
  `;
  export const FileContentVideo = styled.video`
    height: 100px;
    box-shadow: 0 6px 8px -6px ${(p) => getColor(p, p.theme.colors.mainForeground)};
    padding: 5px;
  `;
  export const ReferencedItemInfo = styled.div`
        //color: ${(p) => getColor(p, p.theme.colors.accent)};
        display: grid;
        grid-template-columns: max-content 1fr 40px;
        grid-template-rows: max-content;
        column-gap: 20px;
        line-height: 22px;
        margin-top: 10px;
        padding-bottom: 10px;
`;
  export const ReferencedItemInfoTitle = styled.div`
    font-weight: bolder;
    text-transform: capitalize;
  `;
  export const ReferencedItemInfoContent = styled.div``;
  export const ReferencedItemInfoButton = styled.div`
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
    align-self: start;
  `;
  export const ReferencedItemInfoContentLink = styled.div`
    cursor: pointer;
    &:hover {
      color: ${(p) => getColor(p, p.theme.colors.accent)};
    }
  `;
  export const FileContentImage = styled.img`
    height: 100px;
    box-shadow: 0 6px 8px -6px ${(p) => getColor(p, p.theme.colors.mainForeground)};
    padding: 5px;
  `;
  export const TagItemContainer = styled.div`
    line-height: 40px;
  `;
  export const TagItemContainerCats = styled.div`
    float: left;
    * {
      cursor: default !important;
    }
    &:hover {
      color: ${(p) => getColor(p, p.theme.colors.accentBlack)};
    }
  `;
  export const TagItem = styled.div`
    margin-right: 10px;
    display: inline-block;
  `;
  export const TagItemInfo = styled.div`
    display: inline-block;
    margin-top: 4px;
    border: 1px solid ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    padding: 5px;
    font-size: 0.85em;
    line-height: 12px;
    margin-right: 3px;
    color: ${(p) => getColor(p, p.theme.colors.lightGrey)};
    transition: all 0.2s ease-out;
    svg {
      stroke: ${(p) => getColor(p, p.theme.colors.lightGrey)};
    }
    &:hover {
      color: ${(p) => getColor(p, p.theme.colors.mainForeground)};
      background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
      border: 1px solid ${(p) => getColor(p, p.theme.colors.lightGrey)};
      svg {
        stroke: ${(p) => getColor(p, p.theme.colors.mainForeground)};
      }
    }
  `;

  export const FileContentRightsNames = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 24px;
    width: 100%;
    line-height: 24px;
    padding: 8px 0;
  `;
  export const FileContentRights = styled.div`
    display: grid;
    grid-template-columns: max-content max-content max-content;
    column-gap: 40px;
    grid-template-rows: 24px;
    width: 100%;
    line-height: 24px;
    padding: 8px 0;
  `;

  export const FileContentUserImg = styled.img`
    float: left;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    margin-top: 3px;
    margin-right: 10px;
  `;

  export const Grid = styled.div<{ backgroundColor?: string, highlighted?: boolean }>`
    min-height: 40px;
    display: grid;
    grid-template-columns: ${(props) => belowBreakpointOrEqual(props, "tablet") ? "1fr" : props.highlighted ? "187px 1fr" : "200px 1fr"};
    grid-template-rows: ${(props) => belowBreakpointOrEqual(props, "tablet") ? "min-content" : "max-content"};
    margin-bottom: 10px;
    background-color: ${(p) => p.backgroundColor ? getColor(p, p.backgroundColor) : ""};
    border: ${(p) => p.highlighted ? "1px solid " + getColor(p, p.theme.colors.middleLightGrey) : "none"};
    border-left: ${(p) => p.highlighted ? "3px solid " + getColor(p, p.theme.colors.accent) : "none"};
    padding: ${(p) => (p.highlighted ? 10 : 0)}px;
    box-shadow: ${(p) => (p.highlighted ? "0 6px 8px -6px #000" : "none")};
    button > div {
      margin: 0;
    }
  `;


  export const FullWidthCell = styled.div`
    grid-column-start: 1;
    grid-column-end: ${(props) =>
      belowBreakpointOrEqual(props, "tablet") ? 2 : 3};
  `;
  export const BottomButtonContainer = styled.div`
    padding-top: 10px;
    border-top: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    width: 100%;
    margin-top: 40px;
  `;
  export const GridBackgroundImage = styled.div<{ backgroundColor?: string, preview: string }>`
    min-height: 80px;
    display: grid;
    grid-template-columns: ${(props) => belowBreakpointOrEqual(props, "tablet") ? "1fr" : "200px 1fr"};
    grid-template-rows: ${(props) => belowBreakpointOrEqual(props, "tablet") ? "min-content" : "min-content"};
    margin: 0px;
    background-color: ${(p) => p.backgroundColor ? getColor(p, p.backgroundColor) : ""};
    background-image: url(${(p) => p.preview});
    background-repeat: no-repeat;
    background-position: ${(props) => belowBreakpointOrEqual(props, "tablet") ? "right 10px" : "top right"};
    background-size: ${(props) => belowBreakpointOrEqual(props, "tablet") ? "50%" : "contain"};
  `;

  export const Label = styled.div<{ color?: string }>`
    color: ${(p) => (p.color ? getColor(p, p.color) : "")};
    padding-top: 7px;
    font-size: 0.9rem;
    width: 100%;
    padding-bottom: ${(p) => belowBreakpointOrEqual(p, "tablet") ? "5px" : "unset"};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `;
  export const DwnLinkContainer = styled.div`
    margin-top: -15px;
    display: grid;
    grid-template-columns: max-content 1fr minmax(0, max-content);
    button > div {
      margin: 0;
    }
  `;
  export const DwnLinkModalFooter = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 10px;
  `;
  export const DwnLinkContainerMissingInfo = styled.div`
    color: ${(p) => getColor(p, "@accentRed")};
    height: 40px;
  `;
  export const TimeSpan = styled.div`
    display: grid;
    grid-template-columns: 1fr 40px 1fr;
  `;
  export const TimeSpanSub = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `;
  export const TimeSpanLabel = styled.div`
    line-height: 40px;
  `;
  export const InputContainer = styled.div<{ notAllowed: boolean }>`
    cursor: ${(p) => (p.notAllowed ? "not-allowed" : "default")};
    input {
      color: ${(p) =>
      p.notAllowed ? fadeColor(p, "@mainForeground", 70) : "inherit"};
    }
    svg {
      stroke: ${(p) =>
      p.notAllowed ? fadeColor(p, "@mainForeground", 70) : "inherit"};
    }
    &:hover {
      input {
        color: ${(p) => (p.notAllowed ? getColor(p, "@accentRed") : "inherit")};
      }
      svg {
        stroke: ${(p) =>
      p.notAllowed ? getColor(p, "@mainForeground") : "inherit"};
      }
    }
  `;
  export const InputInfo = styled.div`
    color: ${(p) => getColor(p, "@lightGrey")};
    margin-bottom: 10px;
    margin-top: -15px;
    font-size: 0.85em;
  `;

  export const InputCounterResult = styled.div<{ alert: boolean }>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 2px;
    color: ${(p) =>
      p.alert ? getColor(p, "@accentRed") : getColor(p, "@middleLightGrey")};
  `;

  export const LabelWarning = styled.div<{ alert: boolean }>`
    font-size: 0.8em;
    color: ${(p) =>
      p.alert ? getColor(p, "@accentRed") : getColor(p, "@lightGrey")};
    margin-top: -13px;
    text-align: left;
  `;

  export const LabelInputDisabled = styled.div`
    svg {
      stroke: ${(p) => getColor(p, "@lightGrey")};
    }
    input {
      color: ${(p) => getColor(p, "@lightGrey")};
    }
  `;

  export const ErrorBox = styled.div`
    color: ${(p) => getColor(p, "@accentRed")};
    background: ${(p) => fadeColor(p, "@accentRed", 5)};
    //text-transform: uppercase;
    //font-weight: bolder;
    //letter-spacing: 0.5px;
    height: 40px;
    line-height: 40px;
    //width: calc(100% - 150px);
    text-align: center;
    padding: 0 10px;
    border: 1px solid ${(p) => getColor(p, "@accentRed")};
    float: right;
    margin-left: 3px;
  `;
  export const GridContent = styled.div`
    grid-column: 2;
  `;

  export const TemplateContainerGrid = styled.div`
    display: grid;
    grid-template-columns: minmax(180px, max-content) 1fr;
    grid-gap: 10px;
    button > div {
      margin: 0;
    }
  `;
  export const TemplateContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 150px;
    //overflow: auto;
  `;
  export const GridCheckboxOuter = styled(CMSSC.Grid)`
    grid-template-rows: unset;
    -ms-grid-rows: unset;
  `;
  export const TagInputContainer = styled.div`
    .react-tagsinput {
      background-color: transparent;
      border: 1px solid
        ${(props) => getColor(props, props.theme.colors.middleLightGrey)};
      padding: 0 5px;
      line-height: 43px;
      height: 43px;
    }

    .react-tagsinput--focused {
      border-color: ${(props) =>
      getColor(props, props.theme.colors.middleLightGrey)};
    }

    .react-tagsinput-tag {
      background-color: ${(props) =>
      getColor(props, props.theme.colors.veryLightGrey)};
      border-radius: 0px;
      border: none;
      color: ${(p) => getColor(p, p.theme.colors.mainForeground)};
      display: inline-block;
      font-family: inherit;
      font-size: 0.85em;
      height: 34px;
      line-height: 34px;
      padding: 0 12px 0 10px;
      margin: 0 5px;
      position: relative;
    }

    .react-tagsinput-remove {
      cursor: pointer;
      margin-left: 10px;
      color: ${(props) => getColor(props, props.theme.colors.mainForeground)};
    }
    .react-tagsinput-tag a::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 5px;
      width: 8px;
      height: 2px;
      background-color: ${(props) =>
      getColor(props, props.theme.colors.darkGrey)};
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    .react-tagsinput-tag a::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 5px;
      width: 8px;
      height: 2px;
      background-color: ${(props) =>
      getColor(props, props.theme.colors.darkGrey)};
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      &:hover {
        background-color: ${(props) =>
      getColor(props, props.theme.colors.accent)};
      }
    }

    .react-tagsinput-input {
      background: transparent;
      width: unset;
      min-width: 80px;
      border: 0;
      color: ${(props) => getColor(props, props.theme.colors.mainForeground)};
    }
  `;

  export const RolesIcon = styled.div`
    grid-column: 4;
    grid-row: 1;
    button > div {
      margin: 0;
    }
  `;
  export const Responsible = styled.div`
    grid-column: 1;
    grid-row: 1;
    button > div {
      margin: 0;
    }
  `;
  export const RolesUser = styled.div`
    grid-column-start: 2;
    grid-column-end: ${(props) =>
      belowBreakpointOrEqual(props, "tablet") ? 4 : 3};
    line-height: 44px;
    padding-left: ${(props) =>
      belowBreakpointOrEqual(props, "tablet") ? "5px" : "0px"};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `;
  export const RolesUserAvatar = styled.img`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-top: 5px;
    float: left;
    margin-right: 10px;
  `;
  export const RolesUserName = styled.div`
    float: left;
    line-height: ${(p) => (belowBreakpointOrEqual(p, "tablet") ? 20 : 40)}px;
  `;

  export const RolesUserAvatarEmail = styled.a`
    float: right;
    transition: all 0.2s ease-out;
    svg {
      stroke: ${(p) => getColor(p, p.theme.colors.lightGrey)};
    }
    &:hover {
      svg {
        stroke: ${(p) => getColor(p, p.theme.colors.accent)};
      }
    }
  `;

  export const RolesRoles = styled.div`
    //grid-column: 3;
    //grid-column: 2;
    grid-column-start: ${(props) =>
      belowBreakpointOrEqual(props, "tablet") ? 2 : 3};
    grid-column-end: ${(props) =>
      belowBreakpointOrEqual(props, "tablet") ? 5 : 4};
    margin-bottom: ${(props) =>
      belowBreakpointOrEqual(props, "tablet") ? "5px" : "0px"};
  `;

  export const Container = styled.div`
    padding: 0px;
    padding-bottom: 40px;
  `;
  export const ContainerBGImage = styled.div<{ url: string }>`
      width: calc(100% + 22px);
      transform: translate(-20px, -11px);
      background: #fff;
      background-image: linear-gradient(#CCCCCC, #EEEEEE), url(${p => p.url});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      filter: grayscale(1) opacity(0.05) brightness(2);
      background-blend-mode: multiply;
      position: absolute;
      inset: 0;
  `;

  export const ContainerEdit = styled.div`
    padding: 0px;
    padding-bottom: 40px;
    padding: 20px 10px;
    border: 1px solid ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    border-top: none;
    box-shadow: 0 6px 8px -6px ${(p) => getColor(p, p.theme.colors.darkGrey)};
  `;
  export const ProjectContainer = styled.div`
    display: grid;
    grid-template-columns: ${(props) =>
      belowBreakpointOrEqual(props, "tablet") ? "1fr" : "max-content 1fr"};
    column-gap: 40px;
    grid-template-rows: max-content;
    row-gap: 5px;
  `;
  export const SidbarPreviewContainer = styled.div<{
    resetFontSize?: boolean;
    sidebarView: boolean;
  }>`
    margin-bottom: ${(p) => (p.resetFontSize === true ? 0 : 5)}px;
    width: 100%;
  `;

  export const TemplateContainerPreview = styled.div<{
    resetFontSize?: boolean;
    sidebarView: boolean;
    height: number;
    noBgColor: boolean;
  }>`
    border: ${(p) =>
      p.sidebarView
        ? "none"
        : "1px solid " + getColor(p, p.theme.colors.middleLightGrey)};
    height: ${(p) => p.height}px;
    //min-height: 100px;
    width: 100%;
    position: relative;
    font-size: ${(p) => (p.resetFontSize === true ? "0.875rem" : "0.85rem")};
    padding-right: 10px;
    background: ${(p) =>
      p.noBgColor
        ? "transparent"
        : p.sidebarView
          ? lightenColor(p, p.theme.backgroundColor, 90)
          : "inherit"};
    transition: all 0.2s ease-out;
  `;
  export const TemplateContainerPreviewContent = styled.div<{
    resetFontSize?: boolean;
    sidebarView: boolean;
  }>`
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 0;
    right: 0;
    text-align: left;
    overflow: ${(p) => (p.sidebarView ? "hidden" : "auto")};
    //font-size: 0.8rem;
    font-size: ${(p) => (p.resetFontSize === true ? "0.875rem" : "0.8rem")};
    padding: 5px 10px;
    transition: all 0.2s ease-out;
    ul {
      margin: 0;
      padding-left: 10px;
    }
    li {
      line-height: 18px;
      list-style-type: none;
      div {
        float: left;
        width: 25px;
      }
    }
  `;
  export const TemplateContainerPreviewTitle = styled.div`
    position: absolute;
    color: ${(props) => getColor(props, props.theme.colors.lightGrey)};
    top: 5px;
    right: 10px;
    text-align: right;
    z-index: 100;
    font-weight: 100;
    font-size: 0.8em;
  `;
  export const TemplateRolesGrid = styled.div<{ active: boolean }>`
    display: grid;
    grid-template-columns: max-content 1fr ${cmsConfig.showOrderButtonsForTemplates && "40px 40px"
    };
    grid-template-rows: 40px;
    transition: 0.3s all ease-out;
    background: ${(p) =>
      p.active
        ? lightenColor(p, p.theme.colors.veryLightGrey, 95)
        : getColor(p, p.theme.colors.mainBackground)};
    border: 1px solid ${(props) =>
      getColor(props, props.theme.colors.middleLightGrey)};
    border-bottom: none;
    button>div{margin:0;}
    &:last-child{
        margin-bottom: 0px;
        //border-bottom: 1px solid ${(props) => getColor(props, props.theme.colors.middleLightGrey)};
    }
    &:hover{
        color: ${(p) => getColor(p, p.theme.colors.accentBlack)};
        background: ${(p) =>
      p.active
        ? lightenColor(p, p.theme.colors.veryLightGrey, 95)
        : getColor(p, p.theme.colors.veryLightGrey)};
    }
`;
  export const TemplateRolesGridHeader = styled(TemplateRolesGrid)`
    font-weight: bolder;
    &:hover {
      color: ${(p) => getColor(p, p.theme.colors.mainForeground)};
      background: ${(p) => getColor(p, p.theme.colors.mainBackground)};
    }
  `;
  export const TemplateRolesGridFooter = styled(TemplateRolesGrid)`
    grid-template-columns: 1fr;
    background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
    box-shadow: 0 6px 8px -6px ${(p) => getColor(p, p.theme.colors.mainForeground)};
  `;

  export const TemplatesIcon = styled.div`
    grid-column: 1;
    border-right: 1px solid
      ${(props) => getColor(props, props.theme.colors.middleLightGrey)};
    button > div {
      margin: 0;
    }
  `;
  export const TemplateText = styled.div`
    grid-column: 2;
    line-height: 40px;
    padding: 0 10px;
  `;

  export const TemplateDirectory = styled.div<{ hasChilds: number }>`
        grid-column: 2;  
        line-height: 40px;
        padding: 0 0 0 10px;
        //margin-left: ${(p) => p.hasChilds * 40}px;
    input{
        transition: all 0.2s ease-out;
        color: ${(props) => getColor(props, props.theme.colors.mainForeground)};
        cursor: pointer;
        width: 100%;
        ::placeholder {
            color: ${(props) => getColor(props, props.theme.colors.accentRed)};
        }
    }
    svg{
        stroke: ${(props) => getColor(props, props.theme.colors.accentRed)};
    }
    .folderInput:focus{
        background: rgba(0,0,0,0.05);
        }
`;

  export const TemplateTextInfo = styled.span`
    float: right;
    font-weight: normal;
    font-size: 0.8em;
    color: ${(p) => getColor(p, p.theme.colors.lightGrey)};
    &:hover {
      color: ${(p) => getColor(p, p.theme.colors.mainForeground)};
      svg {
        stroke: ${(p) => getColor(p, p.theme.colors.mainForeground)};
      }
    }
  `;
  export const TemplateTextWarning = styled.div`
    line-height: 44px;
    padding: 0 10px;
    text-align: right;
    color: ${(p) => getColor(p, p.theme.colors.accentRed)};
    //font-weight: bolder;
    letter-spacing: 0.5px;
  `;

  export const Slider = styled.div`
    .slider-control-left {
      cursor: pointer;
      transition: all 0.2s ease-out;
      margin-left: ${(p) =>
      belowBreakpointOrEqual(p, "tablet") ? 0 : "-15px"};
      margin-top: ${(p) =>
      belowBreakpointOrEqual(p, "tablet") ? "-85px" : "-15px"};
      padding: 35vh 0;
      svg {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        stroke: ${(p) =>
      belowBreakpointOrEqual(p, "tablet")
        ? getColor(p, p.theme.colors.veryLightGrey)
        : getColor(p, p.theme.colors.mainBackground)};
      }
      &:hover {
        svg {
          stroke: ${(p) =>
      belowBreakpointOrEqual(p, "tablet")
        ? getColor(p, p.theme.colors.middleLightGrey)
        : getColor(p, p.theme.colors.accent)};
        }
      }
    }
      .slider-slide > img {
          height: 100%;
      }
    .slider-control-right {
      cursor: pointer;
      transition: all 0.2s ease-out;
      margin-right: ${(p) =>
      belowBreakpointOrEqual(p, "tablet") ? 0 : "-15px"};
      margin-top: ${(p) =>
      belowBreakpointOrEqual(p, "tablet") ? "-85px" : "-15px"};
      padding: 35vh 0;
      svg {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        stroke: ${(p) =>
      belowBreakpointOrEqual(p, "tablet")
        ? getColor(p, p.theme.colors.veryLightGrey)
        : getColor(p, p.theme.colors.mainBackground)};
      }
      &:hover {
        svg {
          stroke: ${(p) =>
      belowBreakpointOrEqual(p, "tablet")
        ? getColor(p, p.theme.colors.middleLightGrey)
        : getColor(p, p.theme.colors.accent)};
        }
      }
    }
  `;

  export const Warning = styled.div`
    line-height: 20px;
    //width: calc(100% + 18px);
    width: 100%;
    //margin-left: -9px;
    //margin-top: -9px;
    //height: 40px;
    padding: 10px 7px 10px 10px;
    //font-weight: bolder;
    border: 1px solid ${(p) => getColor(p, p.theme.colors.accentRed)};
    background: ${(props) =>
      fadeColor(props, getColor(props, props.theme.colors.accentRed), 5)};
    color: ${(p) => getColor(p, p.theme.colors.accentRed)};
  `;

  export const Flag = styled.div<{
    available: boolean;
    editMode: boolean;
    selected?: boolean;
    bordered?: boolean;
    isLastNotSelected?: boolean;
  }>`
    margin-top: 0px;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: relative;
    background: ${(p) =>
      !p.available
        ? getColor(p, "@mainBackground")
        : p.editMode
          ? p.selected
            ? getColor(p, p.theme.colors.alertInfoSuccess)
            : p.isLastNotSelected
              ? getColor(p, p.theme.colors.alertInfoError)
              : getColor(p, p.theme.colors.mainBackground)
          : p.bordered
            ? getColor(p, p.theme.colors.mainBackground)
            : getColor(p, p.theme.colors.mainBackground)};
    border: 1px solid
      ${(p) =>
      p.bordered
        ? getColor(p, "@middleLightGrey")
        : getColor(p, "@veryLightGrey")};
    margin-bottom: 5px;
    margin-right: ${(p) => (p.editMode ? 30 : 5)}px;
    transition: all 0.2s ease-out;
    padding-top: 3px;
    box-shadow: 0 4px 6px -4px ${(p) => (p.bordered ? getColor(p, p.theme.colors.darkGrey) : "transparent")};
    &:hover {
      border: 1px solid
        ${(p) =>
      p.bordered
        ? getColor(p, "@lightGrey")
        : getColor(p, "@middleLightGrey")};
    }
    svg {
      filter: ${(p) => (p.available ? "none" : "grayScale(0.9)")};
      opacity: ${(p) => (p.available ? 1.0 : 0.4)};
    }
  `;

  export const DeleteLanguage = styled.div<{ disabled: boolean }>`
    position: absolute;
    bottom: 7px;
    right: -30px;
    opacity: ${(p) => (p.disabled ? 0.4 : 1)};
    svg {
      stroke: ${(p) => (p.disabled ? getColor(p, "@lightGrey") : "inherit")};
      opacity: ${(p) => (p.disabled ? 0.4 : 1)};
    }
  `;

  export const FlagUpload = styled.div<{ available: boolean }>`
    float: left;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    transition: 0.2s all ease - out;
    // svg{
    //     filter:  ${(p) => (p.available ? "none" : "opacity(0.5)")};
    //         &:hover{
    //         filter: opacity(1);
    //     }
    // }
`;

  export const Chevron = styled(Icon) <{
    opened: boolean;
    hasNeighbourCategories: boolean;
  }>`
    float: left;
    cursor: ${(p) => (p.hasNeighbourCategories ? "pointer" : "default")};
    svg {
      stroke: ${(p) =>
      p.opened ? getColor(p, "@accent") : getColor(p, "@mainForeground")};
      transition: all 0.2s ease-out;
      transform-origin: center center;
      transform: rotate(${(p) => (p.opened ? 90 : 0)}deg);
      &:hover {
        transform: rotate(
          ${(p) =>
      p.opened && p.hasNeighbourCategories
        ? 90
        : p.hasNeighbourCategories
          ? 90
          : 0}deg
        );
        stroke: ${(p) =>
      p.hasNeighbourCategories
        ? getColor(p, "@accentBlack")
        : getColor(p, "@mainForeground")};
      }
    }
  `;

  export const FolderIcon = styled(Icon)`
    float: left;
    margin-right: 6px;
    margin-top: 3px;
    svg {
      stroke: ${(p) => getColor(p, "@mainForeground")};
    }
  `;

  export const MoreParentItem = styled.div<{ active?: boolean }>`
    transition: all 0.2s ease-out;
    cursor: pointer;
    padding: 0px 10px;
    background: ${(p) =>
      p.active ? lightenColor(p, "@veryLightGrey", 96) : "transparent"};
    color: ${(p) =>
      p.active ? getColor(p, "@darkerGrey") : "@mainForeground"};
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:hover {
      background: ${(p) =>
      p.active ? getColor(p, "@mainForeground") : getColor(p, "@accent")};
      color: ${(p) => getColor(p, "@mainBackground")};
      svg {
        stroke: ${(p) =>
      p.active ? "inherit" : getColor(p, "@mainBackground")};
      }
    }
    svg {
      stroke: ${(p) =>
      p.active ? getColor(p, "@darkerGrey") : "@mainForeground"};
    }
  `;

  export const MoreParentContainer = styled.div`
    background: ${(p) => getColor(p, "@veryLightGrey")};
    border: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    z-index: 500;
    line-height: 24px;
    position: absolute;
    top: 22px;
    left: -20px;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: 0 6px 8px -6px #000;
    animation: ${fadeIn} 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  `;
  export const MoreLocalContainer = styled.div`
    background: ${(p) => getColor(p, "@veryLightGrey")};
    border: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    z-index: 500;
    line-height: 24px;
    position: absolute;
    top: 22px;
    left: -20px;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: 0 6px 8px -6px #000;
    animation: ${fadeIn} 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  `;

  export const NoPreviewText = styled.div`
    color: ${(p) => getColor(p, "@veryLightGrey")};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em;
    line-height: 1.6em;
    width: 270px;
    border: 1px solid ${(p) => getColor(p, "@accent")};
    padding: 20px;
    background: rgba(0, 0, 0, 0.4);
  `;

  export const AppIcon = styled.div<{ url: string }>`
    width: 40px;
    height: 40px;
    background-image: url(${(p) => p.url});
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    float: right;
  `;

  export const HistoryContainer = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    width: 100%;
    padding-left: 15px;
    margin-top: 0px;
    line-height: 20px;
  `;
  export const SearchFilter = styled.div<{
    showExtraFilterOnSearchResult?: boolean;
  }>`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ${(p) =>
      p.showExtraFilterOnSearchResult && "40px 40px"} max-content;
    //grid-template-columns: ${(p) => belowBreakpointOrEqual(p, "tablet") ? "1fr " + p.showExtraFilterOnSearchResult && "40px 40px" : "1fr 1fr 1fr " + p.showExtraFilterOnSearchResult && "40px 40px"} max-content;
    width: 100%;   
    column-gap: 5px; 
`;
  export const SFBox = styled.div`
    padding: 0;
  `;
  export const SFBoxBtn = styled.div`
    padding: 0;
    margin-top: 20px;
    button > div {
      margin: 0;
    }
  `;
  export const SFBoxButton = styled.div`
    margin-top: 20px;
    button > div {
      margin: 0;
    }
  `;

  export const TRH = styled.tr`
    height: 40px;
    line-height: 40px;
    transition: all 0.2s ease-out;
  `;
  export const TRsearch = styled.tr`
    transition: all 0.2s ease-out;
    border-bottom: 1px solid
      ${(p) =>
      p.theme.loadingDataTable?.cell?.cellBorders?.bottom?.color
        ? getColor(
          p,
          p.theme.loadingDataTable?.cell?.cellBorders?.bottom.color
        )
        : lightenColor(p, "@mainBorderColor", 115)};
    ${(p) =>
      p.theme.loadingDataTable?.cell?.alternateTableRowColor &&
      `&:nth-child(odd){ background-color: ${lightenColor(
        p,
        p.theme?.loadingDataTable?.cell?.backgroundColor ?? "@mainBackground",
        98
      )};}`}
    &:hover {
      background: ${(p) =>
      lightenColor(
        p,
        p.theme?.loadingDataTable?.cell?.backgroundColor ?? "@mainBackground",
        96
      )};
      ${(p) =>
      p.theme.loadingDataTable?.cell?.alternateTableRowColor &&
      `&:nth-child(odd){ background-color: ${lightenColor(
        p,
        p.theme?.loadingDataTable?.cell?.backgroundColor ?? "@mainBackground",
        94
      )};}`}
    }
    :last-child {
      td {
        border-bottom: 1px solid transparent;
      }
    }
  `;
  export const TR = styled.tr<{ isCurrentEntry: boolean; bgColor?: string }>`
    transition: all 0.2s ease-out;
    font-weight: ${(p) => (p.isCurrentEntry ? "bolder" : "normal")};
    background: ${(p) =>
      p.bgColor
        ? getColor(p, p.bgColor)
        : p.isCurrentEntry
          ? fadeColor(p, getColor(p, "@darkGrey"), 5)
          : "transparent"};
    ${(p) =>
      p.theme.loadingDataTable?.cell?.alternateTableRowColor &&
      `&:nth-child(odd){ background-color: ${lightenColor(
        p,
        p.theme?.loadingDataTable?.cell?.backgroundColor ?? "@mainBackground",
        98
      )};}`}
    &:hover {
      background: ${(p) =>
      p.isCurrentEntry
        ? fadeColor(p, getColor(p, "@darkGrey"), 7)
        : fadeColor(p, getColor(p, "@darkGrey"), 3)};
    }
  `;
  export const THSearch = styled.th`
    border-bottom: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    text-align: left;
    transition: all 0.2s ease-out;
    line-height: 40px;
    font-size: 1em;
    font-weight: bolder;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    button > div {
      margin: 0;
    }
  `;
  export const TDSearch = styled.td`
    //border-bottom: 1px solid ${(p) => getColor(p, "@veryLightGrey")};
    transition: all 0.2s ease-out;
    line-height: 40px;
    font-size: 0.95em;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    button>div{
        margin: 0;
    }
`;
  export const TDSearchPreviewImage = styled.div`
    cursor: pointer;
    button > div {
      margin: 0;
    }
    &:hover {
      background: ${(p) => lightenColor(p, "@veryLightGrey", 95)};
    }
  `;
  export const TD = styled.td<{ isCurrentEntry?: boolean }>`
    border-bottom: 1px solid ${(p) => getColor(p, "@veryLightGrey")};
    transition: all 0.2s ease-out;
    vertical-align: top;
    line-height: 20px;
    font-size: 0.95em;
    padding: 10px 0;
    padding-left: 5px;
    //height: 40px;
    button>div{margin:0;}
    &:first-child{
        border-left: 1px solid ${(p) => p.isCurrentEntry ? getColor(p, "@accent") : "transparent"};
    }
    &:last-child{
        //border-right: 1px solid ${(p) => getColor(p, "@veryLightGrey")};
        border-right: none;
    }
`;
  export const TH = styled.th`
    height: 20px;
    border-bottom: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    transition: all 0.2s ease-out;
    text-align: left;
    padding-left: 5px;
    //background: ${(p) => getColor(p, "@veryLightGrey")};
`;

  export const AW = styled.th`
    height: 20px;
    width: 20px;
    border: 1px solid ${(p) => getColor(p, "@lightGrey")};
    float: left;
    border-radius: 10px;
    margin-right: 10px;
    background: ${(p) => fadeColor(p, "@accentBlue", 5)};
  `;

  export const ResetButtonContainer = styled.div<{ active: boolean }>`
    svg {
      stroke-width: ${(p) => (p.active ? "2px" : "inherit")};
    }
    button > div {
      margin: 0;
    }
  `;
  export const FNEcontainer = styled.div`
    width: 100%;
    height: 40px;
    border: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    padding: 7px 7px;
    display: flex;
  `;
  export const FNEcontainerItem = styled.div<{ height: number }>`
    display: flex;
    flex-direction: column;
    height: ${(p) => p.height}px;
    overflow: auto;
    svg {
      cursor: pointer;
      &:hover {
        stroke: ${(p) => getColor(p, "@accentBlack")};
      }
    }
  `;
  export const FNEcontainerItemIcon = styled.div<{ clickable: boolean }>`
    float: right;
    svg {
      cursor: ${(p) => (p.clickable ? "pointer" : "default")};
      &:hover {
        stroke: ${(p) => getColor(p, "@accent")};
        stroke-width: 3px;
      }
    }
  `;
  export const FNEcontainerItemSpacer = styled.div`
    height: 26px;
    line-height: 26px;
    display: flex;
    padding: 0px 10px;
    margin: 0 5px 0 0;
    box-sizing: border-box;
    border-bottom: 1px solid ${(p) => getColor(p, "@lightGrey")};
    width: 10px;
  `;
  export const FNEcheckboxContainer = styled.div<{ disabled: boolean }>`
    color: ${(p) =>
      p.disabled ? getColor(p, "@lightGrey") : getColor(p, "@mainForeground")};
    transition: 0.2s all ease-out;
    &:hover {
      background: ${(p) =>
      p.disabled ? "inherit" : getColor(p, "@veryLightGrey")};
      color: ${(p) =>
      p.disabled ? getColor(p, "@lightGrey") : getColor(p, "@accentBlack")};
    }
  `;
  export const FNEpreviewElementTitle = styled.div`
    line-height: 18px;
    padding: 0;
    margin: 0;
    margin-top: 1px;
    //float: left;
    width: 80px;
  `;
  export const FNEpreviewElement = styled.span`
    transition: 0.2s all ease-out;
    animation: ${fadeIn} 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s both;
    color: ${(p) => getColor(p, "@darkerGrey")};
    line-height: 18px;
    letter-spacing: 0.7px;
    &:after {
      content: "_";
      color: ${(p) => getColor(p, "@accent")};
      font-weight: bold;
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  `;
  export const DropdownInfo = styled.div`
    text-align: right;
    margin-top: -48px;
    margin-left: 50%;
    width: calc(50% - 50px);
    font-size: 0.8em;
    color: ${(p) => getColor(p, "@accentRed")};
    pointer-events: none;
  `;
  export const DropdownInfoText = styled.div`
    animation: ${slideInRight} 0.6s cubic-bezier(0.23, 1, 0.32, 1) both 0.5s;
  `;
  export const AnpContainer = styled.div`
    position: relative;
  `;
  export const CpfContainer = styled.div`
    position: relative;
    float: left;
    button > div {
      margin: 0;
    }
  `;
  export const DmContainer = styled.div`
    position: relative;
    button > div {
      margin: 0;
    }
  `;
  export const ContentContainer = styled.div`
    background: ${(p) =>
      getColor(p, p.theme.applicationTheming.contentTheme.backgroundColor)};
    max-width: 700px;
  `;
  export const ContentPostContainer = styled.div<{ height?: string }>`
    position: relative;
    width: 100%;
    min-height: ${(p) => (belowBreakpointOrEqual(p, "desktop") ? 180 : 230)}px;
    padding-bottom: ${(p) =>
      p.height
        ? p.height
        : belowBreakpointOrEqual(p, "mobile")
          ? " 100%"
          : belowBreakpointOrEqual(p, "tablet")
            ? "40%"
            : belowBreakpointOrEqual(p, "desktop")
              ? "20%"
              : "33%"};
    background: ${(props) =>
      getColor(
        props,
        selectFromTheme(
          props,
          (t) => t.postItem.backgroundColor,
          (t) => fadeColor(props, getColor(props, "@accentBlack"), 30)
        )
      )};
    color: ${(props) =>
      getColor(
        props,
        selectFromTheme(
          props,
          (t) => t.postItem.color,
          (t) => getColor(props, "@mainBackground")
        )
      )};
    transition: 0.2s all ease-out;
    cursor: ${(p) => (p.height ? "default" : "pointer")};
    transform-origin: center center;
    border: 1px solid transparent;
    svg {
      stroke: rgba(255, 255, 255, 0.3);
    }
    &:hover {
      transform: scale(${(p) => (p.height ? 1 : 1.0)});
      box-shadow: ${(p) =>
      p.height
        ? "none"
        : "0px 0px 40px -15px " +
        getColor(
          p,
          (p.theme.applicationTheming.contentTheme as ContentTheme)
            .contentAccent
        ) +
        ", 0px 6px 12px -6px " +
        getColor(
          p,
          (p.theme.applicationTheming.contentTheme as ContentTheme)
            .contentAccent
        )};
      background: rgba(0, 0, 0, 0.5);
      border: 1px ridge
        ${(p) =>
      getColor(
        p,
        (p.theme.applicationTheming.contentTheme as ContentTheme)
          .contentAccent
      )};
      svg {
        stroke: rgba(255, 255, 255, 0.8);
      }
    }
  `;
  export const FmContainer = styled.div``;
  export const FmIC = styled.div<{ x: number; y: number }>`
    position: fixed;
    left: ${(p) => p.x}px;
    top: ${(p) => p.y}px;
    z-index: 2147483637;
  `;
  export const FgSelectedRow = styled.div<{ selected: boolean }>`
    font-weight: ${(p) => (p.selected ? "bolder" : "normal")};
    transition: all 0.2s ease-out;
    position: relative;
    align-self: center;
  `;
  export const FgSelectedRowStatusIcon = styled.div`
    float: left;
    height: 38px;
    width: 20px;
    text-align: center;
    transition: all 0.2s ease-out;
    transform-origin: center center;
    &:hover {
      svg {
        transform-origin: center center;
        transition: all 0.2s ease-out;
        transform: scale(1.2);
      }
    }
  `;
  export const FgC = styled.div`
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    max-height: calc(${(p) => appendPx(p.theme.content.height)} - 165px);
    padding-bottom: 10px;
  `;
  export const TTIconContainer = styled.div`
    width: 20px;
    height: 20px;
    margin-top: 5px;
    line-height: 20px;
    text-align: center;
`;
  export const FgTRH = styled.tr`
    height: 40px;
    transition: all 0.2s ease-out;
  `;
  export const DirViewFileNameContainer = styled.div`
    display: -webkit-box;
    line-height: 19px;
    align-self: center;
    word-break: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `;
  export const FgTR = styled.tr<{ noHover?: boolean }>`
    height: 40px;
    transition: all 0.2s ease-out;
    background-color: ${(p) =>
      getColor(
        p,
        p.theme?.loadingDataTable?.cell?.backgroundColor ?? "@mainBackground"
      )};
    border-bottom: 1px solid
      ${(p) =>
      p.theme.loadingDataTable?.cell?.cellBorders?.bottom?.color
        ? getColor(
          p,
          p.theme.loadingDataTable?.cell?.cellBorders?.bottom.color
        )
        : lightenColor(p, "@mainBorderColor", 115)};

    :last-child {
      td {
        border-bottom: 1px solid transparent;
        //padding-bottom: 10px;
      }
    }
    ${(p) =>
      p.theme.loadingDataTable?.cell?.alternateTableRowColor &&
      !p.noHover &&
      `&:nth-child(odd){ background-color: ${lightenColor(
        p,
        p.theme?.loadingDataTable?.cell?.backgroundColor ?? "@mainBackground",
        98
      )};}`}
    &:hover {
      background: ${(p) =>
      p.noHover
        ? "transparent"
        : lightenColor(
          p,
          p.theme?.loadingDataTable?.cell?.backgroundColor ??
          "@mainBackground",
          96
        )};
      ${(p) =>
      p.theme.loadingDataTable?.cell?.alternateTableRowColor &&
      !p.noHover &&
      `&:nth-child(odd){ background-color: ${lightenColor(
        p,
        p.theme?.loadingDataTable?.cell?.backgroundColor ?? "@mainBackground",
        94
      )};}`}
    }
  `;
  export const FgTD = styled.td`
    //border-bottom: 1px solid ${(p) => lightenColor(p, "@mainBorderColor", 115)};
    button>div{margin:0;}
    transition: all 0.2s ease-out;
    padding-left: 5px;
    font-size: 0.95em;
    line-height: 40px;
    &:first-child{
        padding-left: 10px;
    }
    .folder{
        svg{
            stroke-width: 1px;
        }
    }
`;
  export const FgTH = styled.th<{ tabbedView: boolean; isSorted: boolean }>`
    height: 20px;
    border-spacing: 1px;
    transition: all 0.2s ease-out;
    text-align: left;
    padding-left: 5px;
    position: relative;
    ${(p) =>
      p.tabbedView &&
      p.isSorted &&
      `&:after{
                content: '';
                position: absolute;
                top:0;
                bottom: 0;
                left: 0px;
                width: 1px;
                background: ${getColor(p, "@middleLightGrey")};
            }`}

    ${(p) =>
      !p.tabbedView &&
      `&:after{
                content: '';
                position: absolute;
                bottom: 0;
                left:0;
                right:0;
                height: 1px;
                background: ${getColor(
        p,
        p.isSorted ? "@middleLightGrey" : "@middleLightGrey"
      )};
            }`}
    &:first-child {
      padding-left: 10px;
    }
  `;
  export const PreContainer = styled.div<{ isPdf?: boolean }>`
    height: ${(p) => (p.isPdf ? "100vh" : "calc(90vh - 40px)")};
    margin-top: ${(p) => (p.isPdf ? 0 : -20)}px;
    display: flex;
  `;
  export const PreFooter = styled.div`
    height: 40px;
    left: 0;
    right: 0;
    bottom: 20px;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    line-height: 40px;
    color: #fff;
    font-size: 1rem;
  `;
  export const PreImage = styled.img`
    object-fit: scale-down;
    margin-left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    max-height: 100%;
  `;
  export const PreVideo = styled.video<{ height?: string }>`
    object-fit: contain;
    margin-left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    max-height: 100%;
  `;
  export const PrePdf = styled.object`
    object-fit: contain;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 100vw;
  `;
  export const TmContainer = styled.div`
    position: relative;
  `;
  export const WvC = styled.div<{
    hasPredecessor: boolean;
    isSmallDisplay: boolean;
  }>`
    display: grid;
    grid-template-columns: ${(p) =>
      p.hasPredecessor && (p.isSmallDisplay ? "42px" : "70px")} 1fr ${(p) =>
        p.isSmallDisplay ? 42 : 70}px 1fr ${(p) =>
          p.isSmallDisplay ? 42 : 70}px;
    border: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    padding: 10px;
  `;
  export const WvAC = styled.div<{ isSmallDisplay: boolean }>`
    position: relative;
    width: 100%;
    transform-origin: center center;
    transform: scale(${(p) => (p.isSmallDisplay ? 0.8 : 1)});
  `;
  export const WvArrow = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    button > div {
      margin: 0;
    }
    svg {
      transform: scale(1.5);
    }
  `;
  export const WvUserGrid = styled.div`
    display: grid;
    grid-template-columns: ${(props) =>
      belowBreakpointOrEqual(props, "tablet") ? "1fr" : "1fr 1fr"};
    border: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    padding: 10px;
    column-gap: 10px;
    row-gap: 10px;
  `;
  export const WvDiscussionContainer = styled.div`
    border: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    padding: 0;
    margin-left: 10px;
    & > div {
      padding: 10px;
    }
  `;
  export const WvSpacer = styled.div<{ color: string }>`
    width: 100%;
    text-align: center;
    margin-top: -45px;
    margin-bottom: -5px;
    animation: ${fadeIn} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s both;
  `;
  export const WvWorkflowStateContainer = styled.div<{ color: string }>`
    border: 1px solid ${(p) => getColor(p, p.color)};
    padding: 0;
    margin-bottom: 50px;
    margin-top: 0px;
    box-shadow: 0 6px 8px -6px ${(p) => fadeColor(p, p.color, 50)};
    transition: all 0.2s ease-out;
    overflow: hidden;
    transition: all 0.2s ease-out;
    background: ${(p) => fadeColor(p, p.color, 3)};
  `;
  export const WvWorkflowStateContainerTitle = styled.div<{ color: string }>`
    color: ${(p) => lightenColor(p, p.color, 70)};
    padding-left: 10px;
    height: 40px;
    line-height: 40px;
    font-weight: bolder;
    background: ${(p) => fadeColor(p, p.color, 5)};
    svg {
      stroke: ${(p) => getColor(p, p.color)};
      fill: ${(p) => fadeColor(p, p.color, 10)};
    }
  `;
  export const WvWorkflowStateContainerFooter = styled.div<{ color: string }>`
    height: 40px;
    line-height: 40px;
    //background: ${(p) => fadeColor(p, "@middleLightGrey", 5)};
    background: ${(p) => fadeColor(p, p.color, 4)};
`;
  export const WvWorkflowStateContainerContent = styled.div`
    padding: 10px;
  `;
  export const WvUserTile = styled.div`
    background-color: ${(p) => getColor(p, "@veryLightGrey")};
    width: 100%;
    min-height: 40px;
    max-height: 210px;
    background-color: ${(p) => getColor(p, "@mainBackground")};
    display: grid;
    grid-template-rows: 40px 1fr;
    border: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    box-shadow: 0 6px 8px -6px ${(p) => getColor(p, "@middleLightGrey")};
  `;
  export const WvContent = styled.div`
    grid-row: 2;
    width: 100%;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
  `;
  export const WvAllUsers = styled.div<{ all?: boolean }>`
    width: 100%;
    font-weight: bolder;
    color: ${(p) => getColor(p, p.all ? "@accentGreen" : "@accentRed")};
  `;
  export const TreeContainer = styled.div<{ selected?: boolean }>`
    background: ${(p) => lightenColor(p, p.theme.backgroundColor, 103)};
    border: 1px solid ${(p) => lightenColor(p, p.theme.backgroundColor, 90)};
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    position: relative;
    div > svg {
      stroke: ${(p) => getColor(p, p.theme.color)} !important;
    }
    .treeview > div {
      border-left: 1px solid
        ${(p) => lightenColor(p, p.theme.backgroundColor, 80)} !important;
      &:after {
        content: "" !important;
        position: absolute !important;
        bottom: 0px !important;
        left: -5px !important;
        width: 11px !important;
        border-top: 1px solid
          ${(p) => lightenColor(p, p.theme.backgroundColor, 80)} !important;
        height: 14px;
      }
      margin-left: 14px !important;
      padding-bottom: 0px !important;
      transition: all 0.2s ease !important;
      div {
        color: ${(p) => getColor(p, p.theme.color)} !important;
      }
      div > svg {
        stroke: ${(p) => getColor(p, p.theme.color)} !important;
      }
      div:hover {
        background: ${(p) =>
      fadeColor(p, p.theme.colors.accentBlack, 10)} !important;
        transition: all 0.2s ease !important;
        & > span {
          transition: all 0.2s ease !important;
          color: ${(p) => lightenColor(p, p.theme.color, 200)} !important;
          text-shadow: 0 0 3px #000;
        }
        & > svg {
          stroke: ${(p) => getColor(p, p.theme.colors.accentOrange)} !important;
        }
      }
    }
  `;
  export const TeRow = styled.div`
    display: flex;
    flex-direction: row;
    button > div {
      margin: 0;
    }
  `;
  export const TeColumn = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 40px;
  `;
  export const TeFolder = styled.div`
    float: left;
    font-size: 20px;
    line-height: 40px;
    margin-right: 10px;
  `;
  export const TeTemplateEntryContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;
  export const AssignedTasksContainer = styled.div`
    margin-top: 5px;
    margin-bottom: 10px;
    min-height: 300px;
  `;
  export const AssignedTasksContainerOpenGrid = styled.div<{ open: boolean }>`
    display: grid;
    width: 100%;
    padding-left: 5px;
    grid-template-columns: max-content 1fr max-content 40px;
    color: ${(p) => getColor(p, p.theme.color)};
    font-size: 0.95em;
    background: ${(p) => p.open ? lightenColor(p, p.theme.backgroundColor, 80) : "transparent"};
    border-top: ${(p) => p.open ? 1 : 0}px solid ${p => getColor(p, "@darkerGrey")};
    cursor: pointer;
    button > div {
      margin: 0;
    }
  `;
  export const ATCGicon = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    line-height: 40px;
    padding-left: 0px;
    &.flicker {
      animation: ${flicker} 0.5s ease-in-out infinite both;
    }
  `;
  export const ATCGtitle = styled.div<{ expiredTask: boolean }>`
    grid-column-start: 2;
    grid-column-end: 3;
    line-height: 40px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${(p) =>
      p.expiredTask
        ? lightenColor(p, p.theme.colors.accentYellow, 130)
        : getColor(p, p.theme.color)};
    cursor: pointer;
    overflow: hidden;
`;
  export const ATCGdate = styled.div<{ expiredTask: boolean }>`
    grid-column-start: 3;
    grid-column-end: 4;
    line-height: 40px;
    font-weight: normal;
    text-align: right;
    padding-right: 10px;
    color: ${(p) =>
      p.expiredTask
        ? lightenColor(p, p.theme.colors.hoverColor, 130)
        : getColor(p, p.theme.color)};
  `;
  export const ATCGinfo = styled.div`
    grid-column-start: 4;
    grid-column-end: 5;
    line-height: 40px;
  `;
  export const ATCGmore = styled.div<{ open: boolean }>`
    grid-column-start: 2;
    grid-column-end: 5;
    z-index: 10;
    min-height: ${(p) => (p.open ? 40 : 0)}px;
    max-height: 0px;
    //transition: 0.7s max-height ease-out;
    transition: 0.2s all ease-out;
    font-size: 0.95em;
    display: grid;
    grid-template-columns: max-content 1fr;
    //padding: ${(p) => (p.open ? 10 : 0)}px;
    padding: 0 10px;

    color: ${(p) => getColor(p, p.theme.color)};
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
    overflow: hidden;
    &.shown{
        max-height: 1500px;
        padding: 0 10px;
        margin-top: 9px;
        margin-bottom: 10px;
    }
  `;
  export const ATCGmoreLeft = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    line-height: 24px;
    color: ${(p) => lightenColor(p, p.theme.color, 80)};
    &:after {
      content: ":";
    }
  `;
  export const ATCGmoreLeftBtnContainer = styled.div`
    float: left;
    width: 40px;
    height: 40px;
    background: ${(p) => lightenColor(p, "backgroundColor", 60)};
  `;
  export const ATCGmoreLeftBtn = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    margin-top: 5px;
  `;
  export const ATCGmoreRightMsg = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    padding-left: 10px;
    padding-right: 0px;
    line-height: 24px;
  `;
  export const ATCGmoreRight = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    padding-left: 10px;
    padding-right: 0px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `;
  export const ATCGmoreRightBtn = styled.div<{ expiredTask: boolean }>`
    grid-column-start: 2;
    grid-column-end: 3;
    /* padding-left: 10px; */
    margin-top: 10px;
    background: ${(p) => p.expiredTask ? lightenColor(p, p.theme.colors.accentYellow, 100) : lightenColor(p, cmsConfig.rightSiedbarTaskAccentColor.color, cmsConfig.rightSiedbarTaskAccentColor.lightenLevel)};
    button>div{
      color: ${(p) => p.expiredTask ? getColor(p, "@accentBlack") : getColor(p, cmsConfig.rightSiedbarTaskAccentColor.textColor)};
        svg{
        stroke: ${(p) => p.expiredTask ? getColor(p, "@accentBlack") : getColor(p, cmsConfig.rightSiedbarTaskAccentColor.textColor)};
        }
      }
  `;
  export const ATCGmoreWarningLeft = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    color: ${(p) => lightenColor(p, p.theme.colors.accentYellow, 130)};
    &:after {
      content: ":";
    }
  `;
  export const ATCGmoreWarningRight = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    color: ${(p) => lightenColor(p, p.theme.colors.accentYellow, 130)};
    padding-left: 10px;
  `;
  export const AssignedTasksContainerUserLine = styled.div`
    position: fixed;
    width: 1px;
    height: 10px;
    background: ${(p) => lightenColor(p, p.theme.backgroundColor, 85)};
    right: 40px;
    top: 51px;
  `;
  export const DownloadSuccess = styled.div`
    float: right;
    font-size: 0.85em;
    width: 240px;
    line-height: 18px;
    color: ${(p) => getColor(p, p.theme.colors.lightGrey)};
  `;
  export const CMSTracking = styled.div``;
  export const CMSTrackingOuterContainer = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    padding: 10px;
    padding-bottom: 0px;
  `;
  export const CMSTrackingContainer = styled.div<{ smallDevice: boolean }>`
    display: grid;
    grid-template-columns: ${(p) =>
      p.smallDevice
        ? "max-content 1fr 50px max-content"
        : "max-content 170px 60px minmax(170px, max-content) 1fr"};
    column-gap: 10px;
  `;
  export const CMSTrackingLocation = styled.div`
    padding-right: 10px;
  `;
  export const CMSTrackingDate = styled.div`
    svg {
      margin-top: -1px;
    }
  `;
  export const CMSTrackingFlag = styled.div`
    margin-top: -3px;
  `;
  export const CMSTrackingVersion = styled.div`
    padding-left: 5px;
  `;
  export const CMSTrackingUser = styled.div``;
  export const CMSTrackingCommentIcon = styled.div``;
  export const CMSTrackingComment = styled.div<{
    smallDevice: boolean;
    light?: boolean;
  }>`
    font-style: italic;
    color: ${(p) =>
      p.light
        ? getColor(p, "@middleLightGrey")
        : fadeColor(p, p.theme.colors.mainForeground, 90)};
    //&:before{ content: open-quote; }
    //&:after{ content: close-quote; }
    svg {
      stroke: ${(p) =>
      p.light
        ? getColor(p, "@middleLightGrey")
        : fadeColor(p, p.theme.colors.mainForeground, 90)};
    }
    ${(p) =>
      p.smallDevice &&
      `
    grid-column-start: 2;
    grid-column-end: 5;
    margin-bottom: 5px;
    margin-top: -5px;
    padding-left: 3px;
    `}
  `;
  export const DownloadBtnContainer = styled.div<{ disabled: boolean }>`
    float: right;
    button {
      & > div {
        cursor: ${(p) => (p.disabled ? "default" : undefined)} !important;
      }
      color: ${(p) =>
      p.disabled
        ? getColor(p, p.theme.colors.middleLightGrey)
        : undefined} !important;
    }
    svg {
      stroke: ${(p) =>
      p.disabled ? getColor(p, p.theme.colors.middleLightGrey) : undefined};
    }
  `;

  export const ReferencedFilesContainer = styled.div`
    display: grid;
    grid-template-columns: ${(p) =>
      belowBreakpointOrEqual(p, "tablet")
        ? "1fr"
        : "max-content max-content 1fr max-content 60px"};
    grid-template-rows: max-content;
    align-items: center;
    transition: 0.2s all ease-out;
  `;

  export const FilesChildrenContainer = styled.div<{ isMobile: boolean }>`
    display: grid;
    grid-template-columns: ${(p) => p.isMobile ? "1fr max-content" : "max-content max-content max-content 1fr max-content"};
    transition: 0.2s all ease-out;
    width: 100%;
    overflow: hidden;
    button > div {
      margin: 0;
    }
  `;
  export const ReferencedFilesContainerItem = styled.div`
        display: contents;
        & > div{
            transition: 0.2s all ease-out;
            background: ${(p) => getColor(p, p.theme.colors.mainBackground)};
            height:${(p) => (belowBreakpointOrEqual(p, "tablet") ? 20 : 40)}px;
            line-height: ${(p) =>
      belowBreakpointOrEqual(p, "tablet") ? 20 : 40}px;
            cursor: pointer;
        }
        &:hover > div{
            //background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
            color: ${(p) => getColor(p, p.theme.colors.accent)};
            }
`;
  export const FilesChildrenContainerItem = styled.div`
    display: contents;
    &>div{
        transition: 0.2s all ease-out;
        background: ${(p) => getColor(p, p.theme.colors.mainBackground)};
    }
    &:hover > div{
        background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
        }
`;
  export const RFTitleVersions = styled.div`
    font-weight: bolder;
    border-bottom: 1px solid ${(p) => lightenColor(p, p.theme.colors.veryLightGrey, 97)};
    height: 40px;
    line-height: 40px;
    padding-left: 30px;
  `;
  export const RFtitle = styled.div<{ nonPadded?: boolean }>`
    font-weight: bolder;
    border-bottom: 1px solid
      ${(p) => lightenColor(p, p.theme.colors.veryLightGrey, 97)};
    height: 40px;
    line-height: 40px;
    padding-left: ${(p) => (p.nonPadded ? 0 : 40)}px;
  `;
  export const RFProject = styled.div`
    padding-left: ${(p) => (belowBreakpointOrEqual(p, "tablet") ? 10 : 10)}px;
  `;
  export const RFProjectId = styled.div`
    padding-left: ${(p) => (belowBreakpointOrEqual(p, "tablet") ? 10 : 20)}px;
  `;
  export const RFPath = styled.div`
    padding-left: ${(p) => (belowBreakpointOrEqual(p, "tablet") ? 10 : 20)}px;
  `;
  export const MobileTitle = styled.div`
    float: left;
    margin-right: 5px;
    font-weight: bolder;
  `;
  export const ChildrenText = styled.div<{ isMobile: boolean }>`
    height: ${p => p.isMobile ? 20 : 40}px;
    line-height: ${p => p.isMobile ? 20 : 40}px;
    padding-left: ${p => p.isMobile ? 10 : 30}px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &.svgCorrection{
      svg{
        transform: translateY(4px);
      }
    }
  `;
  export const ChildrenTextBtn = styled.div``;
  export const MobileTitleBtn = styled.div`
    float: right;
    margin: 0 5px;
  `;
  export const RFReferencedBy = styled.div`
    padding-left: ${(p) => (belowBreakpointOrEqual(p, "tablet") ? 10 : 20)}px;
    a {
      color: ${(p) => getColor(p, p.theme.colors.darkGrey)};
      &:hover {
        color: ${(p) => getColor(p, p.theme.colors.accent)};
        svg {
          stroke: ${(p) => getColor(p, p.theme.colors.accent)};
        }
      }
    }
  `;
  export const RFButton = styled.div`
    button > div {
      margin: 0;
    }
  `;

  export const SCContainer = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 20px;
    margin-top: -10px;
    transition: all 0.2s ease-out;
    border: 1px solid ${(p) => getColor(p, "color")};
  `;
  export const SCLeft = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    color: ${(p) => getColor(p, "@darkGrey")};
    line-height: 20px;
    padding: 10px 0px 0px 0px;
    font-weight: bolder;
  `;
  export const SCRight = styled.div`
        grid-column-start: 2;
        grid-column-end: 3;
        line-height: 20px;
        padding: 10px 10px 0px 0px;
        //background: ${(p) => getColor(p, "@mainBackground")};
        color: ${(p) => getColor(p, "@darkGrey")};
`;
  export const SCUserAvatar = styled.img`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    float: left;
    margin-right: 10px;
  `;
  export const SCTagItem = styled.div`
    margin-right: 10px;
    display: inline-block;
  `;
  export const SCProjectRolesGrid = styled.div<{ confirmed?: boolean }>`
    display: grid;
    grid-template-columns: minmax(160px, max-content) 1fr;
    grid-template-rows: max-content;
    margin-top: -5px;
    margin-bottom: -3px;
    transition: 0.3s all ease-out;
  `;
  export const SCTagItemInfo = styled.div`
    display: inline-block;
    margin-top: 4px;
    padding: 5px;
    line-height: 12px;
    margin-right: 3px;
    transition: all 0.2s ease-out;
  `;

  export const FileSiteHeader = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr;
    background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
    width: 100%;
    height: 40px;
    line-height: 40px;
  `;
  export const FileSiteHeaderIcon = styled.div`
    border-left: 3px solid ${(p) => getColor(p, p.theme.colors.accent)};
    position: relative;
    background: ${(p) => lightenColor(p, "@veryLightGrey", 97)};
  `;
  export const HeaderSearch = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    border-left: 3px solid ${(p) => getColor(p, p.theme.colors.accent)};
    background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
    width: 100%;
    min-height: 40px;
    margin-bottom: 20px;
    button > div {
      margin: 0;
    }
  `;

  export const TableDeactivated = styled.div<{ disabled: boolean }>`
    transition: all 0.2s ease-out;
    opacity: ${(p) => (p.disabled ? 0.8 : undefined)};
    pointer-events: ${(p) => (p.disabled ? "none" : "auto")} !important;
  `;

  export const HeaderSearchInfo = styled.div`
    float: left;
    font-weight: normal;
    line-height: 20px;
    padding: 10px 0 10px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `;
  export const FileSiteHeaderIconTrans = styled.div`
    position: absolute;
    top: -4px;
    left: 3px;
    & > div {
      width: 32px;
      height: 32px;
    }
  `;
  export const FileSiteHeaderTitle = styled.div`
    color: ${(p) => getColor(p, p.theme.colors.darkerGrey)};
    font-weight: bolder;
    padding-left: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  `;

  export const TableGridMoreMenuItemSorting = styled.div`
    width: 250px;
    display: grid;
    grid-template-columns: 39px 1fr 40px 40px;
    grid-template-rows: 40px;
    line-height: 40px;
    font-weight: normal;
    transition: all 0.2s ease-out;
    color: ${(p) => getColor(p, p.theme.colors.darkerGrey)};
    background: ${(p) => getColor(p, p.theme.colors.mainBackground)};
    cursor: pointer;
    svg {
      stroke: ${(p) => getColor(p, p.theme.colors.darkerGrey)};
    }
    &:hover {
      background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
      svg {
        stroke: ${(p) => getColor(p, p.theme.colors.darkerGrey)};
      }
    }
  `;

  export const FilterInfo = styled.div<{ shown: boolean; open: boolean }>`
    display: ${(p) => (p.shown ? "block" : "none")};
    float: left;
    height: 40px;
    line-height: 24px;
    margin-right: 5px;
    transition: 0.2s all ease-out;
  `;
  export const SidebarFilterToggleOld = styled.div<{ display: "block" | "none" }>`
   display: ${(p) => p.display};
   width: 100%;
   margin-top: -5px;
   transition: 0.2s all ease-out;
   margin-bottom: 20px;
   padding: 0 10px 10px 10px;
   background: ${(p) => lightenColor(p, p.theme.backgroundColor, 120)};
   //border: 1px solid ${(p) => lightenColor(p, p.theme.backgroundColor, 110)};
   border-left: 1px solid ${(p) => getColor(p, "@accent")};
   box-shadow: 0 6px 8px -6px #000;
 `;
  export const SidebarFilterToggle = styled.div`
    width: 100%;
    z-index: 10;
    //margin-top: -5px;
    margin-bottom: 10px;
    padding: 0 10px;
    background: ${(p) => lightenColor(p, p.theme.backgroundColor, 120)};
    border-left: 1px solid ${(p) => getColor(p, "@accent")};
    box-shadow: 0 0 0 0 transparent;
    max-height: 0px;
    overflow: hidden;
    transition: 0.2s max-height ease-out;
    &.shown{
        max-height: 1500px;
        padding: 0 10px 10px 10px;
        box-shadow: 0 6px 8px -6px #000;
    }
  `;
  export const SbUserSearch = styled.div<{ selected?: boolean }>`
    & > div > div {
      background-color: ${(p) =>
      p.selected
        ? fromCmsTheme(
          p,
          (t) =>
            fadeColor(
              p,
              t.select.filledColor,
              t.select.filledFadings ? t.select.filledFadings[0] : 5
            ),
          (t) => ""
        )
        : fromCmsTheme(
          p,
          (t) =>
            fadeColor(
              p,
              t.select.emptyColor,
              t.select.emptyFadings ? t.select.emptyFadings[0] : 5
            ),
          (t) => ""
        )} !important;
      border-color: ${(p) =>
      p.selected
        ? fromCmsTheme(
          p,
          (t) =>
            fadeColor(
              p,
              t.select.filledColor,
              t.select.filledFadings ? t.select.filledFadings[1] : 5
            ),
          (t) => ""
        )
        : fromCmsTheme(
          p,
          (t) =>
            fadeColor(
              p,
              t.select.emptyColor,
              t.select.emptyFadings ? t.select.emptyFadings[1] : 5
            ),
          (t) => ""
        )} !important;
      &:hover {
        background-color: ${(p) =>
      p.selected
        ? fromCmsTheme(
          p,
          (t) =>
            fadeColor(
              p,
              t.select.filledColor,
              t.select.filledFadings ? t.select.filledFadings[2] : 5
            ),
          (t) => ""
        )
        : fromCmsTheme(
          p,
          (t) =>
            fadeColor(
              p,
              t.select.emptyColor,
              t.select.emptyFadings ? t.select.emptyFadings[2] : 5
            ),
          (t) => ""
        )} !important;
        border-color: ${(p) =>
      p.selected
        ? fromCmsTheme(
          p,
          (t) =>
            fadeColor(
              p,
              t.select.filledColor,
              t.select.filledFadings ? t.select.filledFadings[3] : 5
            ),
          (t) => ""
        )
        : fromCmsTheme(
          p,
          (t) =>
            fadeColor(
              p,
              t.select.emptyColor,
              t.select.emptyFadings ? t.select.emptyFadings[3] : 5
            ),
          (t) => ""
        )} !important;
      }
    }
  `;
  export const UploadInProgressContainer = styled.div<{ color: string }>`
    width: 100%;
    //background: ${(p) => fadeColor(p, p.color, 5)};
`;
  export const UploadInProgressItem = styled.div<{ color: string }>`
    color: ${(p) => lightenColor(p, p.color, 70)};
    line-height: 18px;
    padding: 5px 10px 5px 10px;
    font-size: 0.85em;
    &:hover {
      background: ${(p) => fadeColor(p, p.color, 5)};
    }
    svg {
      stroke: ${(p) => getColor(p, p.color)};
    }
  `;
  export const UploadInProgressItemContainer = styled.div<{
    hasContent: string | Date | undefined;
  }>`
    display: inline-block;
    padding-right: ${(p) =>
      p.hasContent === undefined || p.hasContent === "" ? 0 : 15}px;
    margin-bottom: 5px;
    &:first-child {
      font-weight: bolder;
    }
  `;
  export const CMSHomeDimmerContainer = styled.div<{ width: number }>`
    max-width: ${(p) => p.width}px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${(p) => getColor(p, p.theme.colors.mainBackground)};
    padding: 20px;
    text-align: left;
    border: 1px solid ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
  `;

  export const FileMenuOverflowContainer = styled.div`
    max-height: 160px;
    overflow-x: hidden;
    overflow-y: auto;
    background: ${(p) => getColor(p, p.theme.colors.accent)};
  `;
  export const UEntry = styled.div<{ uploading?: boolean }>`
    display: grid;
    grid-template-columns: 1fr ${(p) => (p.uploading ? "1fr" : "40px")};
  `;

  export const DZ = styled.div<{ hasFile: boolean; small?: boolean }>`
    padding: 9px;
    height: ${(p) => (p.small ? 40 : 120)}px;
    text-transform: ${(p) => (p.hasFile ? "inherit" : "uppercase")};
    color: ${(p) => getColor(p, p.hasFile ? "@mainForeground" : "@lightGrey")};
    border: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    background-color: ${(p) =>
      getColor(p, p.hasFile ? "background" : "@veryLightGrey")};
  `;
  export const TZC = styled.div`
    color: ${(p) => getColor(p, "@darkGrey")};
  `;
  export const MC = styled.div`
    width: 100%;
    height: 120px;
    border: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    padding: 0;
    color: ${(p) => getColor(p, "@darkGrey")};
    font-size: 12px;
    overflow: hidden;
  `;
  export const SBInfo = styled.div<{ color: string }>`
    color: ${(p) => getColor(p, p.color)};
    padding-left: 5px;
    font-weight: bolder;
  `;
  export const TransBInfoContainer = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 40px;
    border: 1px solid ${p => getColor(p, "@veryLightGrey")};
    margin-top: -5px;
    border-top: none;
    padding: 10px;
    box-shadow: 0 6px 8px -6px ${p => getColor(p, "@darkGrey")};
  `;
  export const TransBInfoLog = styled.ul`
    margin: 0px;
    padding: 0px;
    margin-left: 17px;
`;

  export const SaveDivider = styled.ul`
  grid-column-start: 1;
  grid-column-end: 3;
  background: ${p => getColor(p, "@middleLightGrey")};
  width: 100%;
  height: 1px;
  margin-top: 20px;
`;

  export const AdminTabsRadioContainer = styled.div`
    float: right;
    margin-right: 1px;
    display: grid;
    grid-template-columns: max-content max-content max-content;
`;
  export const AdminTabsRadioSingleContainer = styled.div<{ active: boolean }>`
    padding: 10px 20px 0 20px;
    height: 40px;
    border-left: ${p => p.active ? 1 : 0}px solid ${p => getColor(p, "@middleLightGrey")};
    border-top: ${p => p.active ? 1 : 0}px solid ${p => getColor(p, "@middleLightGrey")};
    border-right: ${p => p.active ? 1 : 0}px solid ${p => getColor(p, "@middleLightGrey")};
    background: ${p => p => p.active ? getColor(p, "backgroundColor") : "transparent"};
    position: relative;
    div{
        font-weight:  ${p => p.active ? "bolder" : "default"};
    }
`;
  export const AdminTabsRadioSingleContainerBottom = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    height: 3px;
    width: 100%;
    background: ${p => getColor(p, "backgroundColor")};
`;
  export const AdminTabsRadioContainerUnderline = styled.div`
  position: absolute;
  left: 42px;
  right: 13px;
  height: 3px;
  background: ${p => getColor(p, "@accent")};
  top: 36px;
`;
  export const BItem = styled.div<{ selected: boolean }>`
    ${p => p.selected ? "font-weight: bolder;" : ""}
    cursor: pointer;
    transition: transform 0.2s ease-out;
    background: ${p => getColor(p, "@mainBackground")};
    padding: 5px;
    &:hover{
        background-color: ${p => getColor(p, "@veryLightGrey")};
    }
`;

  export const Item = styled(BItem)`
    height: 32px;
    display: grid;
    grid-template-columns: 25px 1fr 25px;
`;
  export const SelectedItemsContainer = styled.div`
    padding: 10px;
    margin-top: 5px;
    margin-bottom: -10px;
    border-top: 1px solid ${p => getColor(p, "@mainBorderColor")};
    border-left: 3px solid ${p => getColor(p, "@accent")};
    background-color: ${p => getColor(p, "@veryLightGrey")};
`;
  export const ItemsContainer = styled.div<{ maxHeight?: string }>`
    max-height: ${p => p.maxHeight ?? 300}px;
    overflow-y: auto;
    overflow-x: hidden;
`;
  export const SelectedItem = styled.div`
    line-height: 32px;
    display: inline-block;
    margin-right: 5px;
    margin-top: 5px;
    padding-left: 10px;
    border: 1px solid #ccc;
    background: ${p => getColor(p, "@veryLightGrey")};
`;
  export const DeleteIcon = styled.div`
    float: right;
    padding: 0 5px;
    line-height: 32px;
    cursor: pointer;
    transition: transform 0.2s ease-out;
    svg{
        stroke: ${p => getColor(p, "@lightGrey")};
        &:hover{
            stroke: ${p => getColor(p, "@accentRed")};
    }
    }
`;
  export const Footer = styled.div`
   width: 100%;
   padding: 10px;
   padding-top: 0px;
`;
  export const AddRefTypeContainer = styled.div<{ marginBottom: number }>`
   display: grid;
   grid-template-columns: max-content max-content 1fr;
   column-gap: 5px;
   margin-bottom: ${p => p.marginBottom}px;
`;
  export const UseTreeSpacer = styled.div`
    width: 30px;
`;


  export const ActionRow = styled.div`
    border-top: 1px solid ${p => getColor(p, "@middleLightGrey")};
    padding-top: 10px;
    button>div{margin: 0;}
  `;

  export const ErrorInfo = styled.div<{ w: number }>`
    position: absolute; 
    left: ${p => p.w - 6}px; 
    top: 9px;
    color: ${p => getColor(p, "@middleLightGrey")};
  `;
}
