import { detect } from "detect-browser";
import * as React from "react";


const useCopyToClipboard = (content?: string, resetTime?: number) => {
    const [linkCopied, setLinkCopied] = React.useState<boolean>(false);
    const copyLinkToClipboard = React.useMemo(() => {
        setLinkCopied(v => v ? false : v);
        return () => {
            const browser = detect();
            if (browser?.name === "safari" || browser?.name === "ios" || browser?.name === "ios-webview") {
                var clipBoardElem = document.createElement("input");
                clipBoardElem.setAttribute("type", "text")
                document.body.appendChild(clipBoardElem);
                clipBoardElem.value = content ?? "";
                clipBoardElem.select();
                document.execCommand('copy');
                setLinkCopied(true);
                setTimeout(() => setLinkCopied(false), resetTime ?? 4000);
            }
            else {
                navigator.clipboard.writeText(content ?? "").then(() => {
                    setLinkCopied(true);
                    setTimeout(() => setLinkCopied(false), resetTime ?? 4000);
                });
            }
        }
    }, [content, setLinkCopied, resetTime]);
    return {
        copyLinkToClipboard,
        linkCopied,
        setLinkCopied,
    }
}

export default useCopyToClipboard;