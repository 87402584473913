import * as React from "react";
//import { useSelector } from "react-redux";
import { ControllerHelper, PostInteraction, UserDownloadDto, UserShortInfoDownloadDto } from "collaboration-service";
import UserTile from "components/UserSearch/UserTile";
import { Button, LoaderInline } from "imaginarity-react-ui";
//import { shallowCompare } from "services/Helpers";
//import { useImgI18N } from "services/ImgI18N";
import * as _ from "lodash";
import { useImgI18N } from "services/ImgI18N";
import { PorscheMomentPostSC as T } from "../PorscheMomentPostSC";

interface PMLikesProps {
    likeCount: number;
    id: string;
    showLikes: boolean;
    setShowLikes: (value: React.SetStateAction<boolean>) => void;
    user?: UserDownloadDto;
}

const PMLikes = (p: PMLikesProps) => {
    const { setShowLikes, user, id, showLikes, likeCount } = p;
    const { t } = useImgI18N("feed");
    const [likers, setLikers] = React.useState<Array<UserShortInfoDownloadDto | undefined>>();



    React.useEffect(() => {
        const l = async () => {
            const res = await ControllerHelper.singleCall({ id }, PostInteraction.GetLikes, true);
            const toSet = res ?? [];
            if (toSet.length !== likeCount) {
                setTimeout(() => {
                    const ll = async () => {
                        const res = await ControllerHelper.singleCall({ id }, PostInteraction.GetLikes, true);
                        setLikers(res ?? []);
                    }
                    ll();
                }, 2000);
            }
            else
                setLikers(toSet);
        }
        if (showLikes)
            l();
    }, [id, showLikes, likeCount])

    const chunks = _.chunk(likers, 10);
    if (!showLikes)
        return <div />;
    if (!likers)
        return <LoaderInline active />
    return (
        <T.MoreInfoContainer>
            <T.MoreInfoContainerHeader>
                {t("post liked by")}:
                <Button kind="secondary" icon="times" floated='right' onClick={() => setShowLikes(false)} tooltip={{ tooltipText: t("close likers list") }} />
            </T.MoreInfoContainerHeader>
            <T.MoreInfoContainerItems>
                {_.map(chunks, (chunk, idx) =>
                    <div key={idx}>
                        {_.map(chunk, (liker, i) => {
                            const likedAlready = liker?.id === user?.id;
                            return (
                                liker &&
                                <UserTile
                                    key={i}
                                    user={liker}
                                    showAvatar
                                    isMyself={likedAlready}
                                    linkToProfile
                                />
                            );
                        })}
                    </div>
                )}
            </T.MoreInfoContainerItems>
        </T.MoreInfoContainer>
    )
}

export default PMLikes;