import { CMSController, ControllerHelper, FileEntryDownloadDto, WorkflowController, WorkflowDownloadDto } from 'collaboration-service';
import MoreButton, { MoreButtonEntryProps, MoreMenuButton } from 'components/MoreButton/MoreButton';
import { LinkDto, LinkType } from "imaginarity-azure";
import { Button, ButtonKind, ButtonSizes, ImgIcons, Theme, appendPx, fadeColor, getButtonSizes, getColor, styled } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { PluginActions } from 'services/ApplicationState/PluginStateHandling';
import { getLink, shallowCompare } from 'services/Helpers';
import { createFullNamedRoute, gotoNamedRoute, useCheckWhereIAm } from 'services/Helpers/RoutingHelper';
import History from 'services/History';
import { useImgI18N } from 'services/ImgI18N';
import useCopyToClipboard from 'services/useCopyToClipboard';
import { ThemeContext } from 'styled-components';
import { cmsConfig } from './CMSConfig';
import CMSFileEntryAppIcon from './CMSFileEntryAppIcon';
import { CMSFilesGridEventType } from './CMSFilesGrid';
import { CMSSC } from './CMSSC';

const mapper = (state: ApplicationState) => ({
    routeParams: state.params,
    workflows: state.cmsState.workflows,
    contentLanguage: state.contentLanguage,
    project: state.cmsState.currentProject,
    actionsDisabled: state.cmsState.directoryAndFileActionsDisabled,
    selectedFiles: state.cmsState.selectedFiles,
    selectedDirs: state.cmsState.selectedDirs,
});

const WarningContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${p => getColor(p, "@mainBackground")};
    color: ${p => getColor(p, "@darkGrey")};
    max-width: 450px;
    box-shadow: 0 0 80px -10px ${p => getColor(p, "@darkGrey")}, 0 6px 8px -6px ${p => getColor(p, "@darkGrey")};
    font-size: 1.1em;
    z-index: 2147483637;
`;
const WarningTitle = styled.div`
     height: 41px;
     line-height: 41px;
     background: ${p => getColor(p, "@accent")};
     color: ${p => getColor(p, "@mainBackground")};
    border: 1px solid ${p => getColor(p, "@accent")};
    font-size: 1.2em;
    padding-left: 40px;
    text-transform: capitalize;
`;
const WarningContent = styled.div`
     padding: 20px 40px;
`;

export const NoMoreButton = styled.div<{ size?: ButtonSizes }>`
    margin-left: 3px;
    ${p => {
        const x = getButtonSizes(p.theme);
        const y = getButtonSizes(p.theme, p.size ?? "small");
        return `padding-top: calc((${appendPx(x.buttonHeight)} - ${appendPx(y.buttonHeight)}) / 2); height: ${appendPx(x.buttonHeight)};`
    }}
`;

interface CMSFileMenuProps {
    showPreview: (file: FileEntryDownloadDto) => void;
    current: FileEntryDownloadDto;
    info?: (info: string) => void;
    kind?: ButtonKind;
    selected?: boolean;
    selectRow?: (set?: boolean) => void;
    onLinkCopied?: (event: CMSFilesGridEventType) => void;
}

const menuItemFileIcon = (icon: FileEntryDownloadDto) => {
    return (<CMSFileEntryAppIcon fileEntry={icon} margin="8px 0 0 8px" />);
}

const CMSFileMenu = (p: CMSFileMenuProps) => {
    const showActionInfo = cmsConfig.showActionInfo;
    const dispatch = useAppDispatch();
    const { t, currentLanguage } = useImgI18N("cms");
    const { routeParams, workflows, contentLanguage, project, actionsDisabled, selectedDirs, selectedFiles } = useSelector(mapper, shallowCompare);
    const { current, showPreview: setPreview, info, kind, selected, selectRow, onLinkCopied } = p;
    const id = routeParams?.id as string;
    const [showInfo, setShowInfo] = React.useState<"outputConfirm" | "deleteConfirm" | "deleteRefConfirm" | "chooseWorkflow" | undefined>(undefined);
    const lng = contentLanguage ?? currentLanguage;
    const theme = React.useContext<Theme>(ThemeContext);

    const [showAlert, setShowAlert] = React.useState<string | null>(null);


    //console.log("CMSFileMenu.tsx::64 => selectedDirs", selectedDirs);
    //console.log("CMSFileMenu.tsx::65 => selectedFiles", selectedFiles);

    const url = current?.id && project?.id ? createFullNamedRoute("cms_file", { id: current.id, projectId: project.id }) : undefined;
    const { copyLinkToClipboard, linkCopied } = useCopyToClipboard(url);

    React.useEffect(() => {
        if (onLinkCopied)
            onLinkCopied({ type: "fileLinkCopied", id: current.id, copied: linkCopied });
    }, [linkCopied, onLinkCopied, current.id])

    const { directorySelected, multiSelected } = React.useMemo(() => {
        const directorySelected = selectedDirs.length > 0;
        return {
            multiSelected: selectedDirs.length + selectedFiles.length > 0 && !selected,
            directorySelected,
        }
    }, [selectedDirs, selectedFiles, selected])

    const select = React.useCallback(() => {
        if (selectRow)
            selectRow();
    }, [selectRow]);

    const itemId = current.id;

    const goTo = React.useMemo(() => () => {
        setShowInfo(undefined);
        gotoNamedRoute("cms_file", { projectId: id, id: itemId });
    }, [itemId, id]);

    const preview = React.useMemo(() => () => {
        setShowInfo(undefined);
        setPreview(current);
    }, [current, setPreview]);

    const { mayChangeOutput, changePO,
        applyWF, mayDelReference, deleteReference,
        // mayChangeReferenceToCopy, changeReferenceToCopy,
        mayRename, showRename, mayDel, delFile, showMove,
        changeAV, mayChangeArchive, mayAdvance, mayApprove, mayMove
    } = React.useMemo(() => {
        const sr = selected ? selectedFiles : [current, ...selectedFiles]
        const linkOrAlt = (links: { links: LinkDto[] } | LinkDto[] | undefined, type: LinkType, altType?: LinkType) => getLink(links, type) ?? (altType ? getLink(links, altType) : undefined);


        const linkCheck = (type: LinkType, altType?: LinkType) => {
            if (directorySelected)
                return false;
            if (selectedFiles.length === 0)
                return linkOrAlt(current, type, altType) !== undefined;
            const checkForAll = (moreCheck?: (fe: FileEntryDownloadDto) => void) => {
                for (let i = 0; i < sr.length; i++) {
                    const o = sr[i];
                    if (moreCheck)
                        moreCheck(o);
                    if (!linkOrAlt(o, type, altType))
                        return false;
                }
                return true;
            }
            switch (type) {
                case "changeOutput": {
                    let outputs = 0;
                    const res = checkForAll((fe) => outputs += fe.isProjectOutput ? 1 : 0);
                    return (outputs === 0 || outputs === sr.length) ? res : false;
                }
                case "changeArchive": {
                    let outputs = 0;
                    const res = checkForAll((fe) => outputs += fe.isArchived ? 1 : 0);
                    return (outputs === 0 || outputs === sr.length) ? res : false;
                }
                default:
                    return checkForAll();
            }
        }

        const mayChangeOutput = linkCheck("changeOutput");
        const mayDelReference = linkCheck("deleteReference");
        const mayMove = linkCheck("update", "changeReferenceToCopy");
        const mayChangeReferenceToCopy = linkCheck("changeReferenceToCopy");
        const mayDel = linkCheck("delete");
        const mayRename = selectedFiles.length > 1 ? false : linkCheck("renameContent");
        const mayChangeArchive = linkCheck("changeArchive");

        const first = sr[0] as FileEntryDownloadDto;
        let mayAdvance = !directorySelected ? (sr.length > 0 ? getLink(first, "workflowAdvance") : getLink(current, "workflowAdvance")) : undefined;
        let mayApprove = !directorySelected ? (sr.length > 0 ? getLink(first, "workflowApprove") : getLink(current, "workflowApprove")) : undefined;
        let firstNextNameAdv = mayAdvance?.view;
        let firstNextNameApr = mayAdvance?.view;

        for (let i = 0; i < sr.length; i++) {
            if (!mayAdvance)
                break;
            const cur = sr[i] as FileEntryDownloadDto;
            const l = getLink(cur, "workflowAdvance");
            if (!l || l.view !== firstNextNameAdv)
                mayAdvance = undefined;
            const l1 = getLink(cur, "workflowApprove");
            if (!l1 || l1.view !== firstNextNameApr)
                mayApprove = undefined;
        }
        // console.log(current.name);
        // console.log("--------------------------------------------")
        // console.log(current.links);
        // console.log("CMSFileMenu.tsx::97 => mayChangeOutput", mayChangeOutput);
        // console.log("CMSFileMenu.tsx::97 => mayDelReference", mayDelReference);
        // console.log("CMSFileMenu.tsx::97 => mayDel", mayDel);
        // console.log("CMSFileMenu.tsx::97 => mayChangeReferenceToCopy", mayChangeReferenceToCopy);
        // console.log("CMSFileMenu.tsx::97 => mayRename", mayRename);
        // console.log("CMSFileMenu.tsx::97 => mayChange", mayChange);
        // console.log("--------------------------------------------")

        const context = selectedFiles.length === 0 ? [current] : selectedFiles;

        const changePO = () => {
            const m = _.map(context, c => Actions.updateCMSFile({ ...c, isProjectOutput: !c.isProjectOutput, workflowState: "" }))
            dispatch(PluginActions.batchActions(m));
            if (mayChangeOutput) {
                _.forEach(context, async c => {
                    var res = await ControllerHelper.singleCall({ fileEntryId: c.id, isProjectOutput: !c.isProjectOutput, workflowId: "" }, CMSController.ChangeProjectOutput, true);
                    if (!res)
                        setShowAlert(`Could not remove project output for ${c.name}`);
                    // alert(`Could not remove project output for ${c.name}`);
                });
            }
            if (info && showActionInfo)
                current.isProjectOutput ? info("file successfully changed to no project output") : info("file successfully changed to project output");
        };

        const applyWF = (workflow: WorkflowDownloadDto) => () => {
            const m = _.map(context, c => Actions.updateCMSFile({ ...c, isProjectOutput: true, workflowState: workflow.states[0].name }));
            dispatch(PluginActions.batchActions(m));
            _.forEach(context, c => ControllerHelper.singleCall({ fileEntryId: c.id, isProjectOutput: true, workflowId: workflow.id }, CMSController.ChangeProjectOutput));
            if (info && showActionInfo)
                current.isProjectOutput ? info("file(s) successfully changed to no project output") : info("file(s) successfully changed to project output");
            setShowInfo(undefined);
        }
        setShowInfo(undefined);
        const deleteReference = () => {
            _.forEach(context, c => ControllerHelper.singleCall({ id: c.id }, CMSController.DeleteCopyReferenceFile));
        }
        const changeReferenceToCopy = async () => {
            _.forEach(context, c => ControllerHelper.singleCall({ id: c.id, reason: [{ lng, text: `created local copy for project "${project?.headlines[0]?.text ?? "unknown"}"` }], comment: "created local copy" }, CMSController.ChangeReferenceToCopy));
        }
        const showRename = () => {
            dispatch(PluginActions.batchActions([Actions.setCMSFileToRename(current), Actions.setCMSDirectoryAndFileMenuDisabled(true)]));
        }
        const showMove = () => {
            dispatch(PluginActions.batchActions([Actions.setCMSFileToMove(context), Actions.setCMSDirectoryAndFileMenuDisabled(true)]));
        }
        const delFile = () => {
            const exec = async () => {
                const res = await ControllerHelper.singleCall({ fileEntryIds: _.map(context, c => c.id) }, CMSController.DeleteContents);
                const result = res === null;
                let action = result ? t('deleted: ') : t('failed to delete: ');
                action += _.map(context, c => c.name).toString();
                dispatch(PluginActions.batchActions([
                    Actions.setCMSActionResult({ action, result }),
                    Actions.setActive(undefined),
                ]));
            };

            dispatch(Actions.setActive(true));

            exec();
        }
        const changeAV = () => {
            const m = _.map(context, c => Actions.updateCMSFile({ ...c, isArchived: !c.isArchived }));
            dispatch(PluginActions.batchActions(m));
            if (mayChangeArchive) {
                const dis = _.filter(context, c => c.isArchived);
                const en = _.filter(context, c => !c.isArchived);
                if (dis.length > 0)
                    ControllerHelper.singleCall({ fileEntryIds: _.map(dis, c => c.id), isArchived: false }, CMSController.ChangeFilesArchived);
                if (en.length > 0)
                    ControllerHelper.singleCall({ fileEntryIds: _.map(en, c => c.id), isArchived: true }, CMSController.ChangeFilesArchived);
            }
            if (info && showActionInfo)
                current.isArchived ? info("file(s) successfully restored") : info("file(s) successfully archived");
        };
        return {
            mayAdvance, changeAV, mayChangeArchive, mayChangeOutput, changePO, applyWF, mayDelReference, deleteReference,
            mayChangeReferenceToCopy, changeReferenceToCopy, mayRename, showRename, showMove, mayDel, delFile, mayApprove, mayMove
        }
    }, [current, dispatch, info, showActionInfo, lng, project, directorySelected, selectedFiles, selected, t]);

    const check = useCheckWhereIAm();
    const isFile = check("cms_file");

    const entries = React.useMemo(() => {
        const hidePreview = current.name.endsWith(".tif") || current.name.endsWith(".tiff")
        const toRet: MoreButtonEntryProps<FileEntryDownloadDto>[] = [];
        if (!directorySelected) {
            toRet.push({ onClick: select, icon: selected ? "square" : "check square", content: selected ? t("deselect entry") : t("select entry"), hideOnClick: true })
        }
        if (!isFile && !directorySelected && ((selectedFiles.length === 1 && selected) || selectedFiles.length === 0)) {
            toRet.push({
                onClick: goTo,
                renderIcon: menuItemFileIcon,
                content: t("file details and download")
            })

            // toRet.push({ onClick: preview, icon: "image", content: t("preview"), hideOnClick: true })
            if (!hidePreview)
                toRet.push({ onClick: preview, icon: "image", content: t("preview"), hideOnClick: true })
        }
        if (mayChangeOutput && workflows.length > 0) {
            if (!current.isProjectOutput) {
                if (showInfo === "chooseWorkflow") {
                    toRet.push({ onClick: () => setShowInfo(undefined), icon: "flag back", content: t("cancel project output change") })
                }
                toRet.push({
                    onClick: () => setShowInfo("chooseWorkflow"),
                    icon: "flag",
                    content: t("change to project output"),
                    renderSubComponent: (ele, hide) => (
                        <CMSSC.FileMenuOverflowContainer>
                            {_.map(workflows, w => <MoreMenuButton showSubComponent ele={ele} key={w.id} icon="workflow" content={w.name} onClick={() => { hide(); applyWF(w)() }} />)}
                        </CMSSC.FileMenuOverflowContainer>
                    ),
                    showSubComponent: showInfo === "chooseWorkflow"
                })
            }
            else {
                toRet.push({
                    onClick: () => setShowInfo("outputConfirm"), icon: "flag back", content: t("change to no project output"), renderSubComponent: (ele, hide) => (
                        <>
                            <MoreMenuButton icon="times" onClick={hide} content={t("cancel project output change")} ele={ele} key={"cancel"} />
                            <MoreMenuButton icon="check" onClick={() => { hide(); changePO(); }} content={t("yes, change to no project output")} ele={ele} key={"yes"} />
                        </>
                    ), showSubComponent: showInfo === "outputConfirm"
                })
            }
        }
        const mayArchive = current.isArchived ? true : current.isPublished ? false : true;

        if (mayChangeArchive && !current.isProjectOutput && mayArchive)
            toRet.push({ onClick: changeAV, icon: "box", content: current.isArchived ? t("unarchive file") : t("archive file") })

        if (mayDelReference)
            toRet.push({
                onClick: () => setShowInfo("deleteRefConfirm"),
                icon: "delete",
                content: t("delete reference"),
                renderSubComponent: (ele, hide) => (
                    <>
                        <MoreMenuButton icon="times" onClick={hide} content={t("cancel deletion")} ele={ele} key={"cancel"} />
                        <MoreMenuButton icon="check" onClick={() => { hide(); deleteReference(); check("cms_file") && History.goBack(); }} content={t("yes, delete reference")} ele={ele} key={"yes"} />
                    </>
                ),
                showSubComponent: showInfo === "deleteRefConfirm"
            })

        if (mayRename && check("cms_dir"))
            toRet.push({ onClick: showRename, icon: "pencil", content: t("rename file"), hideOnClick: true })
        if ((mayMove) && check("cms_dir"))
            toRet.push({ onClick: showMove, icon: "forward", content: t("move file"), hideOnClick: true })

        // UserStory 9647 -> Bug 9320

        // if (mayChangeReferenceToCopy)
        //     toRet.push({ onClick: changeReferenceToCopy, icon: "copy", content: t("copy reference to local") })


        if (mayDel)
            toRet.push({
                onClick: () => setShowInfo("deleteConfirm"),
                icon: "delete",
                content: t("delete file"),
                renderSubComponent: (ele, hide) => (
                    <>
                        <MoreMenuButton icon="times" onClick={hide} content={t("cancel deletion")} ele={ele} key={"cancel"} />
                        <MoreMenuButton icon="check" onClick={() => { hide(); delFile(); check("cms_file") && History.goBack(); }} content={t("yes, delete file")} ele={ele} key={"yes"} />
                    </>
                ),
                showSubComponent: showInfo === "deleteConfirm"
            })
        if (mayAdvance) {
            const n = ((mayAdvance.view ?? "").split("|") ?? ["", ""])[1];
            const exec = async () => {
                if (selectedFiles.length === 0)
                    await ControllerHelper.singleCall({ itemId: current.id }, WorkflowController.AdvanceWorkflow);
                else {
                    const ps = _.map(selectedFiles, s => ControllerHelper.singleCall({ itemId: s.id }, WorkflowController.AdvanceWorkflow));
                    await Promise.all(ps);
                }
            }
            toRet.push({
                onClick: exec,
                icon: "chevron right double",
                content: `advance to "${n}"`,
                hideOnClick: true,
            })
        }
        if (mayApprove) {
            //            const n = ((mayApprove.view ?? "").split("|") ?? ["", ""])[1];
            const exec = async () => {
                if (selectedFiles.length === 0)
                    await ControllerHelper.singleCall({ itemId: current.id, approve: true, reason: "fast approve" }, WorkflowController.ApproveWorkflowStep);
                else {
                    const ps = _.map(selectedFiles, s => ControllerHelper.singleCall({ itemId: s.id, approve: true, reason: "fast approve" }, WorkflowController.ApproveWorkflowStep));
                    await Promise.all(ps);
                }
            }
            toRet.push({
                onClick: exec,
                icon: "check",
                content: `approve`,
                hideOnClick: true,
            })
        }

        if (url && selectedFiles.length <= 1 && selectedDirs.length === 0) {
            toRet.push({
                onClick: copyLinkToClipboard,
                icon: "link",
                content: "create link to file",
                hideOnClick: true
            })
        }
        return toRet;
    }, [current, t, workflows, isFile, applyWF, mayChangeOutput, preview, changePO,
        changeAV, deleteReference, goTo, check, mayChangeArchive,
        // mayChangeReferenceToCopy, changeReferenceToCopy,
        mayDelReference, mayRename, showRename,
        mayDel, delFile, showInfo, setShowInfo, showMove, selectedFiles, directorySelected,
        mayAdvance, selected, select, mayApprove, mayMove, url, copyLinkToClipboard, selectedDirs.length]);

    const onHide = React.useMemo(() => () => {
        setShowInfo(undefined);
    }, []);

    const entriesSub = _.filter(entries, e => (e as any).icon !== "square" && (e as any).icon !== "check square");
    const entriesCount = entriesSub.length;

    return (
        <>
            {entriesCount > 0 && !actionsDisabled ?
                (multiSelected ?
                    <div />
                    // <NoMoreButton>
                    //     <Button
                    //         icon="checkbox unchecked"
                    //         iconColor={cmsConfig.multiSelectMoreButtonIconColor}
                    //         size="small" kind={kind ?? "fullTransparentButton"}
                    //         tooltip={{ tooltipText: t("select to apply common for operations") }}
                    //         onClick={select}
                    //     />
                    // </NoMoreButton>

                    // <MoreButton
                    //     iconColor='@accent'
                    //     ele={current}
                    //     entries={entries}
                    //     hideMoreButton
                    //     kind={kind ?? "transparentButton"}
                    //     onHide={onHide}
                    //     containerStyle={{ width: 320 }}
                    // // disabled={directorySelected || multiSelected || actionsDisabled}
                    // />

                    :
                    <MoreButton
                        ele={current}
                        entries={entries}
                        hideMoreButton
                        kind={kind ?? "transparentButton"}
                        onHide={onHide}
                        containerStyle={{ width: 320 }}
                    // disabled={directorySelected || multiSelected || actionsDisabled}
                    />
                )
                : <Button
                    cursor='default'
                    icon={cmsConfig.disabledMoreButtonIcon as ImgIcons}
                    iconColor={fadeColor({ theme }, cmsConfig.disabledMoreButtonIconColor, cmsConfig.disabledMoreButtonIconColorAlpha)}
                    kind={kind ?? "fullTransparentButton"}
                    disabled
                    tooltip={{ tooltipText: t("current selection avoids any operation for this entry"), position: "left" }}
                />
            }
            {showAlert && (
                <WarningContainer>
                    <WarningTitle>
                        {t("warning")}
                        <Button kind="transparentButtonDark" onClick={() => setShowAlert(null)} icon='times' floated='right' />
                    </WarningTitle>
                    <WarningContent>{showAlert}</WarningContent>
                </WarningContainer>
            )}
        </>
    );
}
export default CMSFileMenu;