import { Icon, ImgIcons } from 'imaginarity-react-ui';
import * as React from 'react';
import { CMSSC as T } from './CMSSC';

interface CMSTableLegendProps {
    icon: ImgIcons;
    text: string;
}

const CMSTableLegendItem = (p: CMSTableLegendProps) => {
    return (
        <T.LegendItemNew>
            <Icon name={p.icon} marginTop={3} color='@lightGrey' size="0.85rem" style={{ float: "left", marginRight: 5 }} />{p.text}
        </T.LegendItemNew>
    );
}
export default CMSTableLegendItem;