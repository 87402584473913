
import { AnswerQuestionResultDto, GameController, GameDownloadDto, PostDownloadDto, QuestionDownloadDto, QuestionResultMode, QuizDownloadDto, UserAnswer, UserShortInfoDownloadDto } from "collaboration-service";
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import * as _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../services/ApplicationState/ApplicationState';
import History from "../../services/History";
import { StateMachine } from "../../services/StateMachine";
import QuestionExplanation from "./QuestionExplanation";
import Question, { LoadState } from "./Questions/Question";
import TimeIndicator from "./Questions/TimeIndicator";
import QuizResult from './QuizResult';
import { QuizSC as T } from './QuizSC';


enum GameStates {
    StartGame = "StartGame",
    PrepareQuestion = "PrepareQuestion",
    PreviewExpl = "PreviewExpl",
    ShowQuestion = "ShowQuestion",
    FinalizeQuestion = "FinalizeQuestion",
    ShowRightExpl = "ShowRightExpl",
    ShowWrongExpl = "ShowWrongExpl",
    ShowAnswer = "ShowAnswer",
    ShowAnswerWaiting = "ShowAnswerWaiting",
    ShowAnswerQuestionFinished = "ShowAnswerQuestionFinished",
    WaitForOthers = "WaitForOthers",
    LoadingQuestionFinished = "LoadingQuestionFinished",
    ShowResult = "ShowResult",
    Canceled = "Canceled",
    End = "End"
}

enum StateMachineTransitionParameter {
    None,
    LoadingFinished,
    // singleplayer
    GoOn,
    // multiplayer
    VersusQuestionFinished,
    Canceled
}

const mapper = (state: ApplicationState) => ({ gameHubService: state.gameHubService, user: state.user });

export interface GameEngineProps extends ComponentWithMappedApplicationState<typeof mapper> {
    game: GameDownloadDto;
    quiz: QuizDownloadDto;
    players?: UserShortInfoDownloadDto[];
    isPpeQuiz?: boolean;
    isFromPlaylist?: boolean;
    closeModal?: () => void;
}

export interface ExtendedAnswerQuestionResultDto extends AnswerQuestionResultDto {
    userAnswer?: UserAnswer;
}

export interface GameEngineState {
    questions?: PostDownloadDto[];
    answers: Array<ExtendedAnswerQuestionResultDto | undefined>;
    playerAnswered: UserShortInfoDownloadDto[];
    curState: GameStates;
    players?: UserShortInfoDownloadDto[];
    loaded?: LoadState[];
}

class GameEngine extends React.Component<GameEngineProps, GameEngineState> {
    private curQuestion: number;
    private intermediateStates = [GameStates.StartGame, GameStates.PrepareQuestion];
    private sm: StateMachine<GameStates, StateMachineTransitionParameter>;
    //private mounted?: boolean;
    private userAnswer?: UserAnswer;
    private waitingDelayedClick?: any;
    constructor(props: GameEngineProps) {
        super(props);

        this.sm = new StateMachine<GameStates, StateMachineTransitionParameter>(GameStates.StartGame);
        if (!this.props.players || this.props.players.length === 0) {
            this.sm.addWorks([
                { state: GameStates.FinalizeQuestion, onEnter: this.onEnterFinalizeQuestion }
            ]);
            this.sm.addTransitions([
                {
                    fromState: GameStates.StartGame,
                    delta: () => true,
                    toState: GameStates.PrepareQuestion
                },
                {
                    fromState: GameStates.PrepareQuestion,
                    delta: this.prepareQuestionToPreviewExpl,
                    toState: GameStates.PreviewExpl
                },
                {
                    fromState: GameStates.PrepareQuestion,
                    delta: this.prepareQuestionToShowQuestion,
                    toState: GameStates.ShowQuestion
                },
                {
                    fromState: GameStates.PrepareQuestion,
                    delta: this.hideEndResult(false, this.isQuizFinished(0)),
                    toState: GameStates.ShowResult
                },
                {
                    fromState: GameStates.ShowResult,
                    delta: () => true,
                    toState: GameStates.End
                },
                {
                    fromState: GameStates.PrepareQuestion,
                    delta: this.prepareQuestionToEnd,
                    toState: GameStates.End
                },
                {
                    fromState: GameStates.PreviewExpl,
                    delta: () => true,
                    toState: GameStates.ShowQuestion,
                },
                {
                    fromState: GameStates.ShowQuestion,
                    delta: () => true,
                    toState: GameStates.FinalizeQuestion,
                },
                {
                    fromState: GameStates.FinalizeQuestion,
                    delta: this.isLoadingFinished(this.goOnIfResultModeMatches("ShowNothing")),
                    toState: GameStates.PrepareQuestion,
                    work: this.proceedToNextQuestion,
                },
                {
                    fromState: GameStates.FinalizeQuestion,
                    delta: this.isLoadingFinished(this.goOnIfResultModeMatches("ShowResult")),
                    toState: GameStates.PrepareQuestion,
                    work: this.proceedToNextQuestion,
                },
                {
                    fromState: GameStates.FinalizeQuestion,
                    delta: this.isLoadingFinished(this.goOnDependentOnAnswer(true)),
                    toState: GameStates.ShowRightExpl,
                },
                {
                    fromState: GameStates.FinalizeQuestion,
                    delta: this.isLoadingFinished(this.goOnDependentOnAnswer(false)),
                    toState: GameStates.ShowWrongExpl,
                },
                {
                    fromState: GameStates.FinalizeQuestion,
                    delta: this.isLoadingFinished(this.goOnIfResultModeMatches("ShowRightAnswer")),
                    toState: GameStates.ShowAnswer,
                },
                {
                    fromState: GameStates.ShowWrongExpl,
                    delta: this.goOnIfResultModeMatches("ShowRightAnswer"),
                    toState: GameStates.ShowAnswer,
                },
                {
                    fromState: GameStates.ShowRightExpl,
                    delta: this.goOnIfResultModeMatches("ShowRightAnswer"),
                    toState: GameStates.ShowAnswer,
                },
                {
                    fromState: GameStates.ShowRightExpl,
                    delta: () => true,
                    toState: GameStates.PrepareQuestion,
                    work: this.proceedToNextQuestion,
                },
                {
                    fromState: GameStates.ShowAnswer,
                    delta: () => true,
                    toState: GameStates.PrepareQuestion,
                    work: this.proceedToNextQuestion,
                },
            ]);
        } else {
            this.sm.addWorks([
                { state: GameStates.FinalizeQuestion, onEnter: this.onEnterFinalizeQuestion },
                { state: GameStates.End, onEnter: this.deregisterGame },
                { state: GameStates.ShowAnswer, onEnter: this.emulateClickAfter(30) },
                { state: GameStates.ShowAnswerQuestionFinished, onEnter: this.emulateClickAfter(30) },
            ]);
            this.sm.addTransitions([
                {
                    fromState: GameStates.StartGame,
                    delta: () => true,
                    toState: GameStates.ShowQuestion
                },
                {
                    fromState: GameStates.ShowQuestion,
                    delta: this.isGoOn(),
                    toState: GameStates.FinalizeQuestion
                },
                {
                    fromState: GameStates.ShowQuestion,
                    delta: this.isVersusQuestionFinished(),
                    toState: GameStates.ShowQuestion,
                    work: this.proceedToNextQuestion
                },
                {
                    fromState: GameStates.FinalizeQuestion,
                    delta: this.isLoadingFinished(this.goOnIfResultModeMatches("ShowRightAnswer")),
                    toState: GameStates.ShowAnswer,
                },
                {
                    fromState: GameStates.FinalizeQuestion,
                    delta: this.isLoadingFinished(this.goOnIfResultModeMatches("ShowNothing")),
                    toState: GameStates.WaitForOthers
                },
                {
                    fromState: GameStates.FinalizeQuestion,
                    delta: this.isLoadingFinished(this.goOnIfResultModeMatches("ShowResult")),
                    toState: GameStates.WaitForOthers
                },
                {
                    fromState: GameStates.FinalizeQuestion,
                    delta: this.isVersusQuestionFinished(),
                    toState: GameStates.LoadingQuestionFinished
                },
                {
                    fromState: GameStates.LoadingQuestionFinished,
                    delta: this.isLoadingFinished(this.goOnIfResultModeMatches("ShowRightAnswer")),
                    toState: GameStates.ShowAnswerQuestionFinished,
                },
                {
                    fromState: GameStates.LoadingQuestionFinished,
                    delta: this.isLoadingFinished(this.goOnIfResultModeMatches("ShowNothing", this.isNotQuizFinished(-1))),
                    toState: GameStates.ShowQuestion,
                    work: this.proceedToNextQuestion
                },
                {
                    fromState: GameStates.LoadingQuestionFinished,
                    delta: this.isLoadingFinished(this.goOnIfResultModeMatches("ShowResult", this.isNotQuizFinished(-1))),
                    toState: GameStates.ShowQuestion,
                    work: this.proceedToNextQuestion
                },
                {
                    fromState: GameStates.LoadingQuestionFinished,
                    delta: this.isLoadingFinished(this.goOnIfResultModeMatches("ShowNothing", this.isQuizFinished(-1))),
                    toState: GameStates.ShowResult
                },
                {
                    fromState: GameStates.LoadingQuestionFinished,
                    delta: this.isLoadingFinished(this.goOnIfResultModeMatches("ShowResult", this.isQuizFinished(-1))),
                    toState: GameStates.ShowResult
                },
                {
                    fromState: GameStates.ShowAnswer,
                    delta: this.isGoOn(),
                    toState: GameStates.ShowAnswerWaiting
                },
                {
                    fromState: GameStates.ShowAnswer,
                    delta: this.isVersusQuestionFinished(),
                    toState: GameStates.ShowAnswerQuestionFinished
                },
                {
                    fromState: GameStates.ShowAnswerWaiting,
                    delta: this.isVersusQuestionFinished(this.isNotQuizFinished(-1)),
                    toState: GameStates.ShowQuestion,
                    work: this.proceedToNextQuestion
                },
                {
                    fromState: GameStates.ShowAnswerQuestionFinished,
                    delta: this.isGoOn(this.isNotQuizFinished(-1)),
                    toState: GameStates.ShowQuestion,
                    work: this.proceedToNextQuestion
                },
                {
                    fromState: GameStates.WaitForOthers,
                    delta: this.isVersusQuestionFinished(this.isNotQuizFinished(-1)),
                    toState: GameStates.ShowQuestion,
                    work: this.proceedToNextQuestion
                },
                {
                    fromState: GameStates.ShowAnswerWaiting,
                    delta: this.isVersusQuestionFinished(this.isQuizFinished(-1)),
                    toState: GameStates.ShowResult,
                },
                {
                    fromState: GameStates.ShowAnswerQuestionFinished,
                    delta: this.isGoOn(this.isQuizFinished(-1)),
                    toState: GameStates.ShowResult,
                },
                {
                    fromState: GameStates.WaitForOthers,
                    delta: this.isVersusQuestionFinished(this.isQuizFinished(-1)),
                    toState: GameStates.ShowResult,
                },
                {
                    fromState: GameStates.ShowQuestion,
                    delta: this.isCanceled(),
                    toState: GameStates.Canceled,
                },
                {
                    fromState: GameStates.ShowAnswer,
                    delta: this.isCanceled(),
                    toState: GameStates.Canceled,
                },
                {
                    fromState: GameStates.ShowAnswerWaiting,
                    delta: this.isCanceled(),
                    toState: GameStates.Canceled,
                },
                {
                    fromState: GameStates.ShowAnswerQuestionFinished,
                    delta: this.isCanceled(),
                    toState: GameStates.Canceled,
                },
                {
                    fromState: GameStates.WaitForOthers,
                    delta: this.isCanceled(),
                    toState: GameStates.Canceled,
                },
                {
                    fromState: GameStates.Canceled,
                    delta: () => true,
                    toState: GameStates.End,
                },
                {
                    fromState: GameStates.ShowResult,
                    delta: this.isGoOn(),
                    toState: GameStates.End
                },
            ]);
        }


        this.state = {
            curState: this.sm.state,
            questions: props.game.questions,
            answers: _.map(props.game.questions, q => undefined),
            players: props.players,
            playerAnswered: [],
        };
        this.curQuestion = 0;
    }



    public UNSAFE_componentWillMount() {
        this.props.gameHubService.registerCallback("questionAnswered", this.questionAnswered);
        this.props.gameHubService.registerCallback("finishedQuestion", this.finishedQuestion);
        this.props.gameHubService.registerCallback("cancelGame", this.gameCanceled);
        this.props.gameHubService.registerCallback("removePlayer", this.playerRemoved);
        //this.mounted = true;
        this.transitionStateMachineState(StateMachineTransitionParameter.None);
        if (this.props.game.lastQuestionAnswered !== undefined && this.props.game.lastQuestionAnswered > -1) {
            this.curQuestion = this.props.game.lastQuestionAnswered + 1;
            const curState = GameStates.PrepareQuestion;
            this.setState({ curState });
        }
    }

    public componentWillUnmount() {
        this.props.gameHubService.deregisterCallback("questionAnswered", this.questionAnswered);
        this.props.gameHubService.deregisterCallback("finishedQuestion", this.finishedQuestion);
        this.props.gameHubService.deregisterCallback("cancelGame", this.gameCanceled);
        this.props.gameHubService.deregisterCallback("removePlayer", this.playerRemoved);
        //this.mounted = false;
    }

    public render() {
        const { playerAnswered, players, answers } = this.state;

        console.log("state machine: ", this.state.curState);
        const go = () => this.goOn();
        const questions = this.state.questions ? this.state.questions : [];

        if (this.state.curState === GameStates.PreviewExpl) {
            const expl = (questions[this.curQuestion] as QuestionDownloadDto).prefixExplanation!;
            return <QuestionExplanation explanation={expl} explanationType="preInfo" goOn={this.goOn} isPpeQuiz={this.props.isPpeQuiz} />
        }
        //            return this.renderQuestion(questions[this.curQuestion], this.curQuestion, questions.length, false, "Prefix");
        else if (this.state.curState === GameStates.ShowRightExpl) {
            const expl = (questions[this.curQuestion] as QuestionDownloadDto).postfixCorrectExplanation!;
            return <QuestionExplanation explanation={expl} explanationType="postInfoCorrect" goOn={this.goOn} isPpeQuiz={this.props.isPpeQuiz} />
        }
        //        return this.renderQuestion(questions[this.curQuestion], this.curQuestion, questions.length, false, "Right");
        else if (this.state.curState === GameStates.ShowWrongExpl) {
            const expl = (questions[this.curQuestion] as QuestionDownloadDto).postfixWrongExplanation!;
            return <QuestionExplanation explanation={expl} explanationType="postInfoWrong" goOn={this.goOn} isPpeQuiz={this.props.isPpeQuiz} />
        }
        //        return this.renderQuestion(questions[this.curQuestion], this.curQuestion, questions.length, false, "Wrong");
        else if (this.state.curState === GameStates.End) {
            this.backToHub(this.props.game.group_id);
        }
        else if (this.state.curState === GameStates.ShowResult) {
            return <QuizResult
                quiz={this.props.quiz}
                gameId={this.props.game.id}
                onClose={go}
                isPpeQuiz={this.props.isPpeQuiz}
                closeModal={this.props.closeModal}
                isFromPlaylist={this.props.isFromPlaylist}
            />;
        }
        else if (this.state.curState === GameStates.ShowAnswer ||
            this.state.curState === GameStates.ShowAnswerWaiting ||
            this.state.curState === GameStates.ShowAnswerQuestionFinished) {
            const answerIndex = this.curQuestion;
            const answer = this.state.answers[answerIndex];
            if (answer && answer.questionWithAnswers)
                return (<>
                    <Question
                        gameid={this.props.game.id}
                        question={answer.questionWithAnswers as QuestionDownloadDto}
                        questionNumber={this.curQuestion}
                        numberOfQuestions={questions.length}
                        answers={this.state.answers}
                        goOn={this.goOn}
                        displayCorrect
                        preLoaded={this.state.loaded}
                        players={players}
                        playersAnswered={playerAnswered}
                        isPpeQuiz={this.props.isPpeQuiz}
                        closeModal={this.props.closeModal}
                    />
                    {/*this.renderQuestion(answer.questionWithAnswers, answerIndex, questions.length, true)*/}
                    {this.state.curState === GameStates.ShowAnswerWaiting &&
                        <T.MessageBox>Waiting for other players to answer component!</T.MessageBox>
                    }
                </>
                );
        }
        else if (this.state.curState === GameStates.WaitForOthers) {
            return (
                <T.MessageBox>Waiting for other players to answer component!</T.MessageBox>
            );
        }
        else if (this.state.curState === GameStates.Canceled) {
            //const goOn = () => this.transitionStateMachineState(StateMachineTransitionParameter.GoOn);
            return (
                <T.MessageBox>Host has left the game! Error component</T.MessageBox>
            );
        }

        return <Question
            gameid={this.props.game.id}
            question={questions[this.curQuestion] as QuestionDownloadDto}
            questionNumber={this.curQuestion}
            numberOfQuestions={questions.length}
            answers={answers}
            goOn={this.goOn}
            getLoaded={this.getLoaded}
            players={players}
            playersAnswered={playerAnswered}
            isPpeQuiz={this.props.isPpeQuiz}
            closeModal={this.props.closeModal}
        />
        //this.renderQuestion(questions[this.curQuestion], this.curQuestion, questions.length, false);
    }
    private getLoaded = (loaded: LoadState[]) => {
        this.setState({ loaded });
    };

    private transitionStateMachineState = (parameter: StateMachineTransitionParameter, reactStateProperties?: any) => {

        const transition = () => {
            let newState: GameStates | undefined;
            const check = (ns: GameStates) => {
                return _.find(this.intermediateStates, (s: GameStates) => s === ns);
            };
            while (!newState || check(newState)) {
                newState = this.sm.reduce(parameter);
            }
            this.setState({ curState: newState });
        };
        if (reactStateProperties)
            this.setState({ ...reactStateProperties }, transition);
        else
            transition();
    }

    private goOn = (userAnswer?: UserAnswer) => {

        if (this.state.curState === GameStates.ShowAnswerQuestionFinished) {
            this.setState({ playerAnswered: [] });
        }
        //console.log("GOON");
        this.userAnswer = userAnswer;
        if (this.userAnswer)
            this.userAnswer.elapsedTime = TimeIndicator.ellapsed;
        this.transitionStateMachineState(StateMachineTransitionParameter.GoOn);
        if (this.waitingDelayedClick) {
            clearTimeout(this.waitingDelayedClick);
            this.waitingDelayedClick = undefined;
        }
    }

    // private onStart = () => {
    //     //
    // }

    private backToHub = (id: string) => {
        // todo???
        History.goBack();
    }

    private proceedToNextQuestion = () => {
        //console.log("curQuestion" + (this.curQuestion + 1) + "/" + (this.state.questions ? this.state.questions.length : "0"));
        this.curQuestion++;
    }

    private prepareQuestionToPreviewExpl = () => {
        if (this.state.questions && this.curQuestion < this.state.questions.length) {
            const question = this.state.questions[this.curQuestion] as QuestionDownloadDto;
            if (question.prefixExplanation)
                return true;
            return false;
        }
        return false;
    }

    private prepareQuestionToShowQuestion = () => {
        if (this.state.questions && this.curQuestion < this.state.questions.length) {
            const question = this.state.questions[this.curQuestion] as QuestionDownloadDto;
            if (!question.prefixExplanation)
                return true;
            return false;
        }
        return false;
    }

    private onEnterFinalizeQuestion = () => {
        if (this.userAnswer && this.state.questions) {
            const qType = this.state.questions[this.curQuestion].type;
            const userAnswer = _.clone(this.userAnswer);
            userAnswer.elapsedTime = userAnswer.elapsedTime ? userAnswer.elapsedTime / 1000 : 0;
            userAnswer.questionId = this.state.questions[this.curQuestion].id;
            const curQuestion = this.curQuestion;
            const success = (answer: ExtendedAnswerQuestionResultDto) => {
                // answer = null => no result and no correction for question!
                if (!answer) {
                    // no answer just go on! but do not show results if we reached end
                    const answers = this.state.answers.slice();
                    answers[curQuestion] = {};
                    this.transitionStateMachineState(StateMachineTransitionParameter.LoadingFinished, { answers });
                }
                else {
                    const answers = this.state.answers.slice();
                    answer.userAnswer = userAnswer;
                    answers[curQuestion] = answer;
                    this.transitionStateMachineState(StateMachineTransitionParameter.LoadingFinished, { answers });
                }
            };
            const fail = () => console.log("Failed to send answer");
            switch (qType) {
                case "SortQuestion":
                    GameController.AnswerSortQuestion({ gameId: this.props.game.id, userAnswer }, success, fail);
                    break;
                case "FourPicturesOneSolutionQuestion":
                    GameController.AnswerFourPictureQuestion({ gameId: this.props.game.id, userAnswer }, success, fail);
                    break;
                case "CorrelatePairsQuestion":
                    GameController.AnswerCorrelatePairsQuestion({ gameId: this.props.game.id, userAnswer }, success, fail);
                    break;
                case "MarkInPictureQuestion":
                    GameController.AnswerMarkInPictureQuestion({ gameId: this.props.game.id, userAnswer }, success, fail);
                    break;
                case "MarkAreaInPictureQuestion":
                    GameController.AnswerMarkAreaInPictureQuestion({ gameId: this.props.game.id, userAnswer }, success, fail);
                    break;
                case "EstimateQuestion":
                    GameController.AnswerEstimateQuestion({ gameId: this.props.game.id, userAnswer }, success, fail);
                    break;
                default:
                    GameController.AnswerMultipleChoiceQuestion({ gameId: this.props.game.id, userAnswer }, success, fail);
                    break;
            }
        }
    }

    private prepareQuestionToEnd = () => {
        if (this.state.questions && this.curQuestion >= this.state.questions.length)
            return true;
        return false;
    }

    private goOnIfResultModeMatches = (resMode: QuestionResultMode, composition?: () => boolean) => {
        return () => {
            if (this.props.quiz.questionResultMode === resMode && (composition ? composition() : true))
                return true;
            return false;
        };
    }

    private goOnDependentOnAnswer = (correct: boolean) => {
        return () => {
            if (this.props.players && this.props.players.length > 0) {
                return false;
            }
            const a = this.state.answers[this.curQuestion];
            const q = this.state.questions ? this.state.questions[this.curQuestion] as QuestionDownloadDto : undefined;
            if (correct) {
                if (a && a.rightAnswer && q && q.postfixCorrectExplanation) {
                    return true;
                }
            }
            else {
                if (a && !a.rightAnswer && q && q.postfixWrongExplanation) {
                    return true;
                }
            }
            return false;
        };
    }

    private isQuizFinished = (offset: number, composition?: () => boolean) => {
        return () => {
            //            console.log("this.state.questions = ", this.state.questions, " ? this.state.curQuestion >= this.state.questions.length", this.curQuestion, " >= ", (this.state.questions ? this.state.questions.length : "undefined"));
            return (this.state.questions ? this.curQuestion >= this.state.questions.length + offset : false) && (composition ? composition() : true);
        };
    }

    private isNotQuizFinished = (offset: number, composition?: () => boolean) => {
        return () => (this.state.questions ? this.curQuestion < this.state.questions.length + offset : false) && (composition ? composition() : true);
    }

    private hideEndResult = (hideEndResult: boolean, composition?: () => boolean) => {
        return () => this.props.quiz.hideEndResults === hideEndResult && (composition ? composition() : true);
    }

    private isGoOn(composition?: () => boolean) {
        return (sm: StateMachine<GameStates, StateMachineTransitionParameter>, parameter?: StateMachineTransitionParameter) => {
            if (parameter === StateMachineTransitionParameter.GoOn)
                return true && (composition ? composition() : true);
            return false;
        };
    }

    private isVersusQuestionFinished(composition?: () => boolean) {
        return (sm: StateMachine<GameStates, StateMachineTransitionParameter>, parameter?: StateMachineTransitionParameter) => {
            if (parameter === StateMachineTransitionParameter.VersusQuestionFinished)
                return true && (composition ? composition() : true);
            return false;
        };
    }

    private isCanceled() {
        return (sm: StateMachine<GameStates, StateMachineTransitionParameter>, parameter?: StateMachineTransitionParameter) => {
            if (parameter === StateMachineTransitionParameter.Canceled)
                return true;
            return false;
        };
    }

    private isLoadingFinished(composition?: () => boolean) {
        return (sm: StateMachine<GameStates, StateMachineTransitionParameter>, parameter?: StateMachineTransitionParameter) => {
            if (parameter === StateMachineTransitionParameter.LoadingFinished)
                return true && composition ? composition() : true;
            return false;
        };
    }


    private questionAnswered = (user: UserShortInfoDownloadDto) => {
        //console.log("Question answered by user: " + user.id);
        const playerAnswered = this.state.playerAnswered.slice();
        const idx = _.findIndex(playerAnswered, p => p.id === user.id);
        if (idx < 0)
            playerAnswered.push(user);
        this.setState({ playerAnswered });
        //console.log('playerAnswered', playerAnswered);

    }

    private finishedQuestion = (answerQuestionResults: AnswerQuestionResultDto[]) => {
        //console.log("Question finished");
        this.transitionStateMachineState(StateMachineTransitionParameter.VersusQuestionFinished);
    }

    private deregisterGame = () => {
        this.props.gameHubService.deregisterToGame();
    }

    private gameCanceled = () => {
        this.transitionStateMachineState(StateMachineTransitionParameter.Canceled);
    }

    private playerRemoved = (userId: string) => {
        //console.log("players removed");

        if (this.state.players) {
            const players = _.filter(this.state.players, (p) => p.id !== userId);
            this.setState({ players });
        }
    }

    // private onTimeOver = () => {
    //     console.log("time over!!");
    // }

    private emulateClickAfter = (delayInSeconds: number) => () => {
        //console.log("start timeout");
        if (!this.waitingDelayedClick)
            this.waitingDelayedClick = setTimeout(this.goOn, delayInSeconds * 1000);
    }
}


export default connect(mapper)(GameEngine);