import * as React from "react";
import { UserShortInfoDownloadDto } from "collaboration-service";

const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg;
const ARGUMENT_NAMES = /([^\s,]+)/g;
function args(func: any) {
    const fnStr = func.toString().replace(STRIP_COMMENTS, '');
    const result = fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')')).match(ARGUMENT_NAMES);
    // eslint-disable-next-line
    const rStr = "return\\s*" + result[0] + ".([^;\}]*)";
    const findRet = new RegExp(rStr, "mg");
    const res = findRet.exec(fnStr);
    if (res)
        return res[1].split(".");
    return [];
}
function getPath<T>(func1: (v: Required<T>) => any) {
    return args(func1);
}

export interface TypeRendererTypes {
    UserShortInfoDownloadDto: UserShortInfoDownloadDto;
}
type TypeRendererTypesNames = keyof TypeRendererTypes;
export type TypeRenderer<S extends { data: T } = any, T = any> = ((props: S) => JSX.Element) | React.MemoExoticComponent<(props: S) => JSX.Element> | React.ComponentClass<S, any>;
type TypeRenderMap<T = any> = {
    [key in TypeRendererTypesNames]?: {
        default: TypeRenderer<{ data: T }>;
        [key: string]: TypeRenderer<{ data: T }>;
    }
};

export class TypeRendererRegistry {
    private _renderMap: TypeRenderMap = {};
    public getRenderer<T = any>(func: (i: TypeRendererTypes) => T) {
        const typename = getPath(func)[0] as TypeRendererTypesNames;
        return (this._renderMap[typename]?.default ?? ((props: any) => <div>{`no renderer for ${typename} found!`}</div>)) as TypeRenderer<{ data: T }>;
    }
    public registerRenderer<T>(func: (i: TypeRendererTypes) => T, renderer: TypeRenderer<{ data: T }>) {
        const typename = getPath(func)[0] as TypeRendererTypesNames;
        if (!this._renderMap[typename])
            this._renderMap[typename] = { default: renderer };
        else {
            const rm = this._renderMap[typename];
            if (rm) {
                if (rm.default)
                    console.warn(`Renderer for type ${typename} was already registered...`);
                rm.default = renderer;
            }
        }
    }


    public getRendererWithName<T = any>(name: string, func: (i: TypeRendererTypes) => T) {
        const typename = getPath(func)[0] as TypeRendererTypesNames;
        const rm = this._renderMap[typename];
        const r = rm ? rm[name] : undefined;
        return (r ?? ((props: any) => <div>{`no renderer for ${typename} found!`}</div>)) as TypeRenderer<{ data: T }>;
    }
    public registerRendererWithName<T>(name: string, func: (i: TypeRendererTypes) => T, renderer: TypeRenderer<{ data: T }>) {
        const typename = getPath(func)[0] as TypeRendererTypesNames;
        if (!this._renderMap[typename])
            this._renderMap[typename] = { default: renderer };
        else {
            const rm = this._renderMap[typename];
            if (rm) {
                if (rm[name])
                    console.warn(`Renderer for type ${typename} was already registered...`);
                rm[name] = renderer;
            }
        }
    }

}