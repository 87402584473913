import { store } from "App";
import { createHashHistory as createHistory } from "history";

export default createHistory();

export const pushToHistory = (toAdd: string) => {
    const r = store.getState().route;
    const h = createHistory();
    const rt = r.endsWith("/") ? r : r + "/";
    const ta = toAdd.startsWith("/") ? toAdd.substr(1) : toAdd;
    h.push(`${rt}${ta}`);
}