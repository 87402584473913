
import { styled } from 'imaginarity-react-ui';

export class DashboardSC {
    public static Container = styled.div<{ tileWidth: number }>`
    width: ${p => p.theme.content.width - 2 * (p.theme.content.width % p.tileWidth) * 0.5 + 1}px;    
`;
    public static NDContainer = styled.div<{ inserted: boolean }>`
    visibility: ${p => p.inserted ? "hidden" : "visible"};
    .react-draggable-dragging{
        position: fixed;
        z-index: 9999;
    }
`;
}