import { EstimateQuestionDownloadDto, EstimateUserAnswer } from 'collaboration-service';
import InfoBox from 'components/InfoBox/InfoBox';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { QuizSC as T } from '../QuizSC';
import { QuestionImplementationProps } from './Question';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage });

export interface EstimateQuestionProps extends QuestionImplementationProps, ComponentWithMappedApplicationState<typeof mapper> {
}

interface ExtendedEstimateUserAnswer extends EstimateUserAnswer {
    percent?: number;
}
export interface EstimateQuestionState {
    numberFormater: Intl.NumberFormat;
    percent: number;
    userAnswer: ExtendedEstimateUserAnswer;
    correctPercent?: number;
    deltaPercent?: number;
}

class EstimateQuestion extends React.Component<EstimateQuestionProps, EstimateQuestionState> {
    constructor(props: EstimateQuestionProps) {
        super(props);
        const ua = ((props.answers ?? [])[props.questionNumber ?? 0] as any)?.userAnswer;
        const percent = ua?.percent ?? 50;
        const answer = ua?.answer ?? this.calcValue(0).v;
        const digits = (this.props.question as EstimateQuestionDownloadDto).digits ?? 0;
        this.state = {
            numberFormater: Intl.NumberFormat(this.props.contentLanguage ?? this.props.currentLanguage,
                { minimumFractionDigits: digits, maximumFractionDigits: digits, useGrouping: (this.props.question as EstimateQuestionDownloadDto).showKiloDelimiter ? true : false }
            ),
            percent,
            userAnswer: { answer } as EstimateUserAnswer,
        }
    }

    componentDidUpdate(prevProps: EstimateQuestionProps, prevState: EstimateQuestionState) {
        if (this.props.question.id !== prevProps.question.id) {
            this.setState({
                percent: 50,
                userAnswer: { answer: this.calcValue(0).v } as EstimateUserAnswer,
            });
        }
    }
    public render() {
        const { contentLanguage } = this.props;
        const data = this.props.question as EstimateQuestionDownloadDto;

        const v = (0.01 * (data.maxValue - data.minValue) * this.state.percent + data.minValue);
        const value = this.state.numberFormater.format(v);

        const correctPercent = data.correctValue
            ? (data.correctValue - data.minValue) /
            (0.01 * (data.maxValue - data.minValue))
            : 1;
        const deltaPercent = data.acceptableDelta
            ? data.acceptableDelta /
            (0.01 * (data.maxValue - data.minValue))
            : 1;
        const minValue = data.correctValue ? data.correctValue - data.acceptableDelta : 0;
        const maxValue = data.correctValue ? data.correctValue + data.acceptableDelta : 0;
        const isCorrect = this.state.userAnswer.answer ? this.state.userAnswer.answer >= minValue && this.state.userAnswer.answer <= maxValue : false;

        return (
            <>
                <T.Functions>
                    <T.EstiQuesTop>
                        <T.EstiQuesMin>
                            {this.state.numberFormater.format(data.minValue)}
                        </T.EstiQuesMin>
                        <T.EstiQuesValue showSolution={this.props.displayCorrect ?? false}>
                            {value}
                            {" " + getTranslated(data.units, contentLanguage)?.text}
                        </T.EstiQuesValue>
                        <T.EstiQuesMax>
                            {this.state.numberFormater.format(data.maxValue)}
                        </T.EstiQuesMax>
                    </T.EstiQuesTop>
                    <T.EstiQuesSliderContainer
                        notHooverable={this.props.displayCorrect ?? false}
                        onMouseDown={this.mouseEvent}
                        onMouseMove={this.mouseEvent}
                        onTouchMove={this.touchElement}
                    >
                        <T.EQSliderBar>
                            {correctPercent && deltaPercent && this.props.displayCorrect &&
                                <>
                                    <T.EQSliderCorrectLeft
                                        style={{
                                            left: (correctPercent - deltaPercent) + "%",
                                            width: "calc(" + deltaPercent + "% + 2px)"
                                        }}
                                    />
                                    <T.EQSliderCorrectRight
                                        style={{
                                            left: "calc(" + correctPercent + "% + 0px)",
                                            width: deltaPercent + "%"
                                        }}
                                    />
                                </>
                            }
                            <T.EQSlider
                                style={{ marginLeft: (this.state.percent - 2.5) + "%" }}
                                show={this.props.displayCorrect}
                                correct={isCorrect}
                            />
                        </T.EQSliderBar>
                        {this.props.displayCorrect && data.correctValue &&
                            <T.EstiQuesValueCorrect>
                                <InfoBox
                                    type={isCorrect ? "success" : "alert"}
                                    content={this.state.numberFormater.format(data.correctValue) + " " + getTranslated(data.units, contentLanguage)?.text}
                                />
                            </T.EstiQuesValueCorrect>
                        }
                    </T.EstiQuesSliderContainer>

                </T.Functions>
            </>
        );
    }
    private calcValue = (newPercent: number) => {
        const data = this.props.question as EstimateQuestionDownloadDto;
        let percent = newPercent;
        let v = (0.01 * (data.maxValue - data.minValue) * newPercent + data.minValue);
        const dPow = Math.pow(10, data.digits);
        if (data.step) {
            let step = 0.5;

            step = data.step * dPow;
            v = (v - data.minValue) * dPow;
            v = Math.round(v / step) * step;
            v = (v / dPow) + data.minValue;

            percent = (v - data.minValue) / (0.01 * (data.maxValue - data.minValue));
        }
        else {
            v = v * dPow;
            v = Math.round(v) / dPow;
        }
        return { v, percent };
    };

    private mouseEvent = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!this.props.displayCorrect && !this.props.timeIsOver) {
            if (event.buttons === 1)
                this.calcMove(event.clientX, event.clientY, event.target as HTMLDivElement);
        }
    };

    private touchElement = (event: React.TouchEvent<HTMLDivElement>) => {
        if (!this.props.displayCorrect && !this.props.timeIsOver) {
            this.calcMove(event.touches[0].clientX, event.touches[0].clientY, event.target as HTMLDivElement);
        }
    };
    private calcMove = (x: number, y: number, div: HTMLDivElement) => {
        if (div.parentNode) {
            const rect = (div.parentNode as HTMLDivElement).getBoundingClientRect();
            x = x - rect.left;
            if (x >= 0 && x <= rect.width) {

                const percent = (x / rect.width) * 100;
                this.calcPercent(percent);
            }
        }
    };
    private calcPercent = (newPercent: number) => {
        const { v, percent } = this.calcValue(newPercent);
        const { userAnswer } = this.state;
        userAnswer.answer = v;
        userAnswer.percent = percent;
        if (this.props.answer)
            this.props.answer(userAnswer);
        this.setState({ percent, userAnswer });
    };

}

export default connect(mapper)(EstimateQuestion);