import { Button, Icon, ImgIcons, ImgThemeConsumer } from 'imaginarity-react-ui';
import * as React from 'react';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { InfoBoxSC as T } from './InfoBoxSC';

export type InfoBoxTypes = "warning" | "alert" | "success" | "info" | "default";

export interface InfoBoxProps extends ImgI18NTranslatedComponentProps {
    content: string;
    type?: InfoBoxTypes;
    icon?: ImgIcons;
    closeable?: boolean;
    darkMode?: boolean;
    noBold?: boolean;
    noUppercase?: boolean;
    children?: JSX.Element;
    toggleAble?: boolean;
    animated?: boolean;
    initialOpen?: boolean;
    onClick?: () => void;
}

export interface InfoBoxState {
    hide: boolean;
    visible: boolean;
}
class InfoBox extends React.PureComponent<InfoBoxProps, InfoBoxState> {
    constructor(props: InfoBoxProps) {
        super(props);

        this.state = {
            hide: false,
            visible: this.props.initialOpen ?? true,
        }
    }

    public render() {
        const { content, type, icon, closeable, noBold, noUppercase, children, toggleAble, animated } = this.props;
        const darkMode = this.props.darkMode ?? false;
        const withIcon = icon !== undefined;
        return (
            <ImgThemeConsumer>
                {(theme) => {
                    const t = type;
                    const color = t === "alert" ? "@accentRed" : t === "warning" ? "@accentYellow" : t === "success" ? "@accentGreen" : t === "info" ? "@accentBlue" : theme.color;
                    return (
                        <T.InfoBoxContainer
                            color={color}
                            hide={this.state.hide}
                            darkMode={darkMode}
                            icon={withIcon}
                            noBold={noBold ?? false}
                            noUppercase={noUppercase ?? false}
                            animated={animated ?? false}
                        >
                            {icon &&
                                <div className="icon">
                                    <Icon name={icon} marginTop={5} size="22px" />
                                </div>
                            }
                            <div style={{ padding: "0 0 0 10px" }}>
                                {closeable &&
                                    <Button kind="halfTransparentButton" icon="times" floated="right" onClick={this.close} tooltip={{ tooltipText: this.props.t('click to close') }} />
                                }
                                {toggleAble &&
                                    <Button kind="halfTransparentButton" icon={this.state.visible ? "chevron up" : "chevron down"} floated="right" onClick={this.toggleVis} />
                                }
                                {content}
                            </div>
                            {children && this.state.visible &&
                                <div style={{ gridRowStart: 2, gridRowEnd: 3, gridColumnStart: 1, gridColumnEnd: withIcon ? 3 : 2 }}>{children}</div>
                            }
                        </T.InfoBoxContainer>
                    );
                }
                }
            </ImgThemeConsumer>
        );
    }
    private close = () => {
        this.setState({ hide: true });
        if (this.props.onClick)
            this.props.onClick();
    }
    private toggleVis = () => { this.setState({ visible: !this.state.visible }); }
}

export default translate("general")(InfoBox);