import { CategoryDownloadDto, ElementNode, PostDownloadDto } from 'collaboration-service';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { ImgIcons } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { AppRouteNames, ViewRouteNames } from 'services/AppEntryDefinitions';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { PluginActions } from 'services/ApplicationState/PluginStateHandling';
import { gotoNamedRoute } from 'services/Helpers/RoutingHelper';
import { sanitizedNothingAllowed, sanitizedNothingAllowedAttributes } from 'services/Helpers/SanitizedHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import BreadcrumbCategory from './BreadcrumbCategory';
import { BreadcrumbsSC as T } from './BreadcrumbsSC';

const mapper = (state: ApplicationState) => ({
    contentLanguage: state.contentLanguage,
    searchState: state.globalSearchState,
    params: state.params
});
export interface BreadcrumbsProps<T> extends ComponentWithMappedApplicationState<typeof mapper>, ImgI18NTranslatedComponentProps {
    post?: T;
    darkMode?: boolean;
    style?: React.CSSProperties;
    spacerIcon?: ImgIcons;
    root: ViewRouteNames | AppRouteNames;
    asSearchResult?: boolean;
    currentCategoryStack?: ElementNode<CategoryDownloadDto>[];
    categoryRouteName: ViewRouteNames;
    accent?: string;
    groupType?: string;
    mapName?: string;
}

export interface BreadcrumbsState {
}

class Breadcrumbs<T extends PostDownloadDto> extends React.Component<BreadcrumbsProps<T>, BreadcrumbsState> {
    constructor(props: BreadcrumbsProps<T>) {
        super(props);

        this.state = {
        }
    }

    public render() {
        const { currentCategoryStack } = this.props;

        const darkMode = this.props.darkMode ?? false;
        const props = _.pick(this.props, ["style"]);
        const spacerIcon = this.props.spacerIcon ?? "chevron right";
        return (
            <T.Container darkMode={darkMode} {...props}>
                <div onClick={this.goHome}>
                    <T.Home darkMode={darkMode} name="home" />
                </div>
                {this.props.asSearchResult ?
                    <>
                        <T.FolderIcon darkMode={darkMode} name={spacerIcon} size="16px" />
                        {this.props.t("search results")}
                    </>
                    :
                    <>
                        {_.map(currentCategoryStack, (cs, i) =>
                            <BreadcrumbCategory
                                key={i}
                                darkMode={darkMode}
                                spacerIcon={spacerIcon}
                                node={cs}
                                routeIndex={i}
                                categoryRouteName={this.props.categoryRouteName}
                                accent={this.props.accent}
                            />
                        )}
                    </>
                }

                {this.props.post &&
                    <>
                        <T.Chevron darkMode={darkMode} name={spacerIcon} opened={false} hasNeighbourCategories={false} />
                        <T.ParentItem
                            darkMode={darkMode}
                        >
                            <SafeHTML html={getTranslated(this.props.post.headlines, this.props.contentLanguage).text} allowedTags={sanitizedNothingAllowed} allowedAttributes={sanitizedNothingAllowedAttributes} />
                        </T.ParentItem>
                    </>
                }
            </T.Container>
        );
    }

    private goHome = () => {
        const root = this.props.root;
        let actions: AnyAction[] = [Actions.setGlobalSearchSearch("")];
        if (root === "tube")
            actions.push(Actions.setTubeSearch(""));
        else if (root === "content")
            actions.push(Actions.setContentSearch(""));
        this.props.dispatch(PluginActions.batchActions(actions));
        gotoNamedRoute(root);
    }
}
export default translate("feed")(connect(mapper)(Breadcrumbs));