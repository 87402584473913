import { UserShortInfoDownloadDto } from 'collaboration-service';
import FacetSelection from 'components/FacetSelection/FacetSelection';
import SidebarButton from 'components/Sidebars/SidebarButton';
import SidebarTitleContainer from 'components/Sidebars/SidebarTitleContainer';
import { Button, Checkbox, Flag, LottieIcon, belowBreakpointOrEqual, fadeColor, getColor, lightenColor, saturationColor, styled } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { Actions, WorkingActions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { PluginActions } from 'services/ApplicationState/PluginStateHandling';
import { CurrentSettings } from 'services/Config';
import { getFrontEndSettingFromState, setFrontEndSetting, shallowCompare } from 'services/Helpers';
import { gotoNamedRoute, gotoNamedRouteMethod, useCheckWhereIAm } from 'services/Helpers/RoutingHelper';
import { useImgI18N } from 'services/ImgI18N';
import { isInGroup } from 'services/StoreDependantHelpers';
import { ThemeContext } from 'styled-components';
import { FeedSearchProps, createFetchPostFilter } from './FeedFilter';
import { feedTheme } from './FeedMain';
import FeedSearchByKeywords from './FeedSearchByKeywords';
import FeedSearchByUser from './FeedSearchByUser';

export const Container = styled.div`
    display: block;
    padding-left: 0px;
    padding-bottom:10px;
    line-height:30px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const OrderFontSize = styled.div<{ isMobile: boolean }>`
    *{
        font-size: ${p => p.isMobile ? "14px" : "inherit"};
        }
`;
export const RadioButtonDescription = styled.div<{ selected: boolean }>`
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s ease-out;
    border-right: 2px solid transparent;
    color:${p => p.selected ? p.theme.colors.mainBackground : p.theme.colors.middleLightGrey};
    padding-right: 5px;
    cursor: pointer;
    width: 100%;
    &:hover{
        border-right: 2px solid ${p => p.theme.colors.darkerGrey};
        color:${p => getColor(p, "color")};
        background: ${p => fadeColor(p, "color", 5)};
    }
`;
export const RadioButtonsContainer = styled.div`
    padding-left: 5px;
    svg{
        margin-top: 0px;
        float: left;
        }
    *{
        stroke: ${p => p.theme.backgroundColor};
    }
`;
export const RadioButtonsContainerElement = styled.div`
    //display: flex;
    //flex-direction: row ;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 40px;
`;
export const ContainerElementFigures = styled.div`
    color: ${p => getColor(p, p.theme.color)};
    width: 40px;
    height: 25px;
    text-align: center;
    //background: rgba(0,0,0,0.05);
    line-height: 25px;
    font-size: 0.9em;
    display: grid;
    grid-template-columns: max-content max-content max-content;
`;
export const TypeContainer = styled.div<{ iconColor?: string, hoverable?: boolean }>`
    display: grid;
    //grid-template-columns: 1fr 40px 70px;  
    grid-template-columns: 1fr 40px 40px;  
    grid-template-rows: 40px ;
    width: 100%;
    margin: ${p => p.hoverable ? 0 : "5px 0"};
    transition: all 0.2s ease-out;
    border-left: ${p => p.hoverable ? "1px solid " + getColor(p, p.theme.backgroundColor) : "none"}; 
    cursor: ${p => p.hoverable ? "pointer" : "default"};
    svg{
        stroke: ${p => p.iconColor === "no" ? fadeColor(p, getColor(p, p.theme.color), 30) : "inherit"};
    }
    &:hover{
        background: ${p => p.hoverable ? getColor(p, "lighten('backgroundColor', 90%)") : "transparent"};
        border-left: ${p => p.hoverable ? "1px solid " + getColor(p, p.theme.colors.accent) : "none"}; 
        padding-left: ${p => p.hoverable ? "10px" : "0px"};
    }
`;
export const TypeContainerView = styled.div<{ iconColor?: string }>`
    display: grid;
    //grid-template-columns: 1fr 40px 70px;  
    grid-template-columns: 1fr max-content;  
    grid-template-rows: 40px ;
    width: 100%;
    margin: 5px 0;
    transition: all 0.2s ease-out;
    *{
        color: ${p => getColor(p, p.theme.leftArea.color)};
        stroke: ${p => getColor(p, p.theme.leftArea.color)};
        svg{
            margin-top: 5px;
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    svg{
        stroke: ${p => p.iconColor === "no" ? fadeColor(p, getColor(p, p.theme.colors.mainBackground), 30) : "inherit"};
    }
`;
export const TypeContainerViewAlternative = styled.div<{ iconColor?: string }>`
    display: grid;
    grid-template-columns: 1fr max-content;  
    grid-template-rows: 40px ;
    width: 100%;
    margin: 5px 0;
    transition: all 0.2s ease-out;
`;
export const TCtitle = styled.div<{ selected?: boolean }>`
    line-height: 40px;  
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;  
    //letter-spacing: 0.5pt;
    font-weight: 400;
    border-left: 1px solid ${p => p.selected ? getColor(p, p.theme.colors.accent) : "transparent"};
    color: ${p => p.selected ? getColor(p, p.theme.color) : getColor(p, p.theme.color)};
    transition: all 0.2s ease-out;
    padding-left: ${p => p.selected ? 10 : 0}px;
    &:hover{
        //color: ${p => lightenColor(p, p.theme.color, 20)};
        //color: ${p => saturationColor(p, p.theme.color, 0.1)};
    } 
`;
export const FilterContainer = styled.div`
    width: 100%;
    grid-column: 1 / span 2;
`;

export const FilterContainerHeader = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;   
    font-weight: 500;
    color: ${p => p.theme.colors.veryLightGrey};
    background: rgba(0,0,0,0.05);
    border-left: 1px solid ${p => p.theme.colors.accent};
    line-height: 40px;
    padding-left: 10px;
    font-size: 0.8rem;
    margin: 0 0 5px 0;
    //text-transform: uppercase;
`;
export const FilterUserContainerHeader = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;   
    font-weight: 500;
    font-size: 0.85rem;
    color: ${p => p.theme.color};
    line-height: 24px;
    padding: 0px 10px 0 0;
    margin: 10px 0 5px 0;
    text-transform: uppercase;
`;

const LottieMobile = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    *{
        stroke: ${p => getColor(p, "@accent")};
    }
`;
const LottieDesktop = styled.div`
    width: 100%;
    height: 40px;
    z-index: 2147483637;
    text-align: center;
    position: relative;
    margin-top: 10px;
`;
const LottieDesktopFile = styled.div`
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     *{
        stroke: ${p => getColor(p, "@accent")} !important;
    }
`;


const mapper = (state: ApplicationState) => ({
    user: state.user,
    userGroups: state.userGroups,
    trending: state.feedState.trending,
    facets: state.feedState.facets,
    feedSearchProps: state.feedState.searchProps,
    posts: state.posts,
});

export interface FeedFilterAllProps {
    filterIsSet?: (v: boolean) => void;
}

const FeedFilterAll = (p: FeedFilterAllProps) => {
    const { filterIsSet } = p;
    const { user, facets, userGroups, feedSearchProps, posts } = useAppSelector(mapper, shallowCompare);
    const dispatch = useAppDispatch();
    const { t } = useImgI18N("feed");
    const [showPMfilter, setShowPMfilter] = React.useState<boolean>(false);
    const [userSelected, setUserSelected] = React.useState<UserShortInfoDownloadDto>();
    const [loading, setLoading] = React.useState<boolean>(false);

    //console.log("rerender!")
    //console.log("FeedFilterAll.tsx::204 => feedSearchProps", JSON.stringify(feedSearchProps));

    const exciteGroups = React.useMemo(() => _.filter(userGroups, g => g.groupType === "EXCITE"), [userGroups]);

    const [excludedGroups, excluded] = React.useMemo(() => [getFrontEndSettingFromState<string[]>(user, "searchStickyGroupFilter") ?? [], getFrontEndSettingFromState<string[]>(user, "searchStickyFilter") ?? []], [user]);
    const exciteHasExcludedGroups = exciteGroups.some(group => excludedGroups.includes(group.id));
    const exciteExcludedGroupsCount = exciteGroups.filter(group => excludedGroups.includes(group.id)).length;

    // console.log("FeedFilterAll.tsx::211 => excluded", excluded);
    // console.log("FeedFilterAll.tsx::211 => excludedGroups", excludedGroups);

    const org = feedSearchProps?.filter?.organization;
    const org1 = feedSearchProps?.filter?.organization1;
    const org2 = feedSearchProps?.filter?.organization2;
    const filterExciteLuxury = feedSearchProps?.filter?.isLuxury ?? false;
    const search = feedSearchProps?.search ?? "";

    const trending = useCheckWhereIAm()("community_feed_trending");


    const startSearch = React.useMemo(() => (feedSearchProps: FeedSearchProps, changedBy: string) => {
        //console.log("x2");
        // console.log("FeedFilterAll.tsx::224 => new => feedSearchProps", changedBy, JSON.stringify(feedSearchProps));
        setLoading(true);

        dispatch(PluginActions.batchActions([
            PluginActions.setPosts(undefined, undefined),
            Actions.setFeedSearchProps(feedSearchProps)
        ]));
    }, [dispatch]);


    const toggleShowPMfilter = () => {
        setShowPMfilter(v => !v);
    }
    const { deselectAllExciteMthd, selectAllExciteMthd, filterByGroup } = React.useMemo(() => {
        const deselectAllExciteMthd = async () => {
            setLoading(true);
            const newExcludedGroups = _.union(excludedGroups, exciteGroups.map(g => g.id));
            const newUser = await setFrontEndSetting(user, "searchStickyGroupFilter", () => newExcludedGroups);
            if (newUser)
                dispatch(Actions.currentUserReceived(newUser));
            else
                setLoading(false);

        };
        const selectAllExciteMthd = async () => {
            setLoading(true);
            const newExcludedGroups = _.difference(excludedGroups, exciteGroups.map(g => g.id));
            const newUser = await setFrontEndSetting(user, "searchStickyGroupFilter", () => newExcludedGroups);
            if (newUser)
                dispatch(Actions.currentUserReceived(newUser));
            else
                setLoading(false);
        };
        const filterByGroup = (groupId: string) => async () => {
            setLoading(true);
            let newExcludedGroups = _.clone(excludedGroups);
            const idx = _.findIndex(newExcludedGroups, f => f === groupId);
            if (idx < 0)
                newExcludedGroups.push(groupId);
            else
                newExcludedGroups.splice(idx, 1);

            const newUser = await setFrontEndSetting(user, "searchStickyGroupFilter", changer => newExcludedGroups);
            if (newUser)
                dispatch(Actions.currentUserReceived(newUser));
            else
                setLoading(false);
        };
        return {
            deselectAllExciteMthd,
            selectAllExciteMthd,
            filterByGroup
        }

    }, [exciteGroups, excludedGroups, user, dispatch]);

    React.useEffect(() => {
        //     //console.log("xxxxx - 2")
        const filter = createFetchPostFilter(undefined, excluded, excludedGroups, undefined, undefined, undefined, undefined, null, null, null, filterExciteLuxury);
        startSearch({ ...feedSearchProps, filter: { ...feedSearchProps?.filter, excludedPostTypes: filter.excludedPostTypes, ignoredGroups: filter.ignoredGroups } }, "useEffect[excluded, excludedGroups, filterExciteLuxury, startSearch, feedSearchProps]");
    }, [excluded, excludedGroups, filterExciteLuxury, startSearch, feedSearchProps])

    // React.useEffect(() => console.log("FeedFilterAll.tsx::320 **Changed** => org", org), [org]);
    // React.useEffect(() => console.log("FeedFilterAll.tsx::321 **Changed** => org1", org1), [org1]);
    // React.useEffect(() => console.log("FeedFilterAll.tsx::322 **Changed** => org2", org2), [org2]);
    // React.useEffect(() => console.log("FeedFilterAll.tsx::323 **Changed** => searchUser", searchUser), [searchUser]);
    // React.useEffect(() => console.log("FeedFilterAll.tsx::324 **Changed** => excluded", excluded), [excluded]);
    // React.useEffect(() => console.log("FeedFilterAll.tsx::325 **Changed** => excludedGroups", excludedGroups), [excludedGroups]);
    // React.useEffect(() => console.log("FeedFilterAll.tsx::326 **Changed** => filterExciteLuxury", filterExciteLuxury), [filterExciteLuxury]);
    // React.useEffect(() => console.log("FeedFilterAll.tsx::327 **Changed** => startSearch", startSearch), [startSearch]);

    const momentsName = t(feedTheme.momentsName);

    const resetAll = async () => {
        setLoading(true);
        // dispatch(Actions.setFeedTrending(!trending));
        const filter = createFetchPostFilter(undefined, [], [], undefined, undefined, undefined, undefined, null, null, null, false);

        setUserSelected(undefined);

        startSearch({ filter, user: undefined, search }, "resetAll");
        // gotoNamedRouteMethod("community_feed");
        // gotoNamedRoute("community_feed");

        dispatch(WorkingActions.FEED.initialLoadPosts(true, 1, (t) => {
            gotoNamedRoute(trending ? "community_feed_trending" : "community_feed", { tokenId: t ?? "" });
        }));

        setShowPMfilter(false);

        const newUser = await setFrontEndSetting(user, "searchStickyFilter", () => undefined);
        if (newUser)
            dispatch(Actions.currentUserReceived(newUser));

        const newUser1 = await setFrontEndSetting(user, "searchStickyGroupFilter", () => undefined);
        if (newUser1)
            dispatch(Actions.currentUserReceived(newUser1));
        setLoading(false);



    };


    const { setOrg, setOrg1, setOrg2, setFilterExciteLuxury, setSearch } = React.useMemo(() => {
        const setOrg = (org: string | null) => {
            console.log("FeedFilterAll.tsx::330 => org", org);
            const filter = createFetchPostFilter(undefined, undefined, [], [], undefined, undefined, undefined, org, null, null, false);
            startSearch({ ...feedSearchProps, filter: { ...feedSearchProps?.filter, organization: filter.organization } }, "setOrg");
        }
        const setOrg1 = (org: string | null) => {
            const filter = createFetchPostFilter(undefined, undefined, [], [], undefined, undefined, undefined, null, org, null, false);
            startSearch({ ...feedSearchProps, filter: { ...feedSearchProps?.filter, organization1: filter.organization1 } }, "setOrg1");
        }
        const setOrg2 = (org: string | null) => {
            const filter = createFetchPostFilter(undefined, undefined, [], [], undefined, undefined, undefined, null, null, org, false);
            startSearch({ ...feedSearchProps, filter: { ...feedSearchProps?.filter, organization2: filter.organization2 } }, "setOrg2");
        }
        const setFilterExciteLuxury = (val: boolean) => {
            startSearch({ ...feedSearchProps, filter: { ...feedSearchProps?.filter, isLuxury: val } }, "setFilterExciteLuxury");
        }
        const setSearch = (val: string | undefined) => {
            startSearch({ ...feedSearchProps, search: val }, "setSearch")
        }
        return {
            setOrg,
            setOrg1,
            setOrg2,
            setFilterExciteLuxury,
            setSearch,
        }
    }, [feedSearchProps, startSearch])

    const resetSearch = () => {
        setSearch(undefined);
    };


    const theme = React.useContext(ThemeContext);
    const isMobile = belowBreakpointOrEqual({ theme }, "tablet");

    const resetButtonIsActive =
        // trending === true ||
        org !== undefined ||
        org1 !== undefined ||
        org2 !== undefined ||
        excluded.includes("PorscheMomentPost") ||
        excluded.includes("CommunityFeedPost") ||
        excludedGroups.length > 0 ||
        filterExciteLuxury === true ||
        userSelected !== undefined
        ;

    const resetSearchButtonActive = search !== "";

    React.useEffect(() => {
        if (filterIsSet) {
            if (resetSearchButtonActive || resetButtonIsActive)
                filterIsSet(true);
            else {
                filterIsSet(false);
            }
        }
    }, [filterIsSet, resetButtonIsActive, search, resetSearchButtonActive]);

    const resetText = (() => {
        switch (true) {
            case resetButtonIsActive && !resetSearchButtonActive:
                return "reset filter";
            case !resetButtonIsActive && resetSearchButtonActive:
                return "reset search";
            case resetButtonIsActive && resetSearchButtonActive:
                return "reset search and filter";
            default:
                return "";
        }
    })();

    React.useEffect(() => {
        if (posts) {
            // console.log("posts changed", posts)
            setLoading(false);
        }
    }, [posts, user]);

    // console.log("loading", loading);


    return (
        <>
            <Container style={{
                paddingBottom: isMobile ? showPMfilter ? 10 : 100 : 10,
                pointerEvents: loading ? "none" : undefined,
                filter: loading ? "grayscale(1)" : undefined,
                opacity: loading ? 0.3 : undefined,
                cursor: loading ? "default" : undefined,
                zIndex: 2147483637
            }}>
                {(resetButtonIsActive || resetSearchButtonActive) && !isMobile &&
                    <OrderFontSize isMobile={isMobile}>
                        <SidebarButton
                            title={t(resetText)}
                            bold
                            noAnimation
                            noContainerClick
                            icon={"undo"}
                            button={[
                                {
                                    icon: "filter times",
                                    onClick: resetAll,
                                    tooltipText: t("reset filters"),
                                    hidden: !resetButtonIsActive,
                                    kind: "primary",
                                },
                                {
                                    icon: "search close",
                                    onClick: resetSearch,
                                    tooltipText: t("reset search"),
                                    hidden: !resetSearchButtonActive,
                                    kind: "primary"
                                }
                            ]}
                        />
                    </OrderFontSize>
                }
                {!isMobile &&
                    <SidebarTitleContainer icon="chevron up down" title={t("ordering")} />
                }
                {!isMobile &&
                    <OrderFontSize isMobile={isMobile}>
                        <SidebarButton
                            title={trending ? t("trending order") : t("chronological order")}
                            bold={isMobile}
                            noAnimation={isMobile}
                            noContainerClick
                            icon={isMobile ? "chevron up down" : undefined}
                            button={[
                                {
                                    icon: "trending",
                                    onClick: gotoNamedRouteMethod("community_feed_trending"),
                                    selected: trending,
                                    // tooltipText: this.props.feedState.trending ? undefined : t("sort by trend"),
                                    disabled: trending ? true : false
                                },
                                {
                                    icon: "clock",
                                    onClick: gotoNamedRouteMethod("community_feed"),
                                    selected: !trending,
                                    // tooltipText: !this.props.feedState.trending ? undefined : t("sort chronological"),
                                    disabled: trending ? false : true
                                }
                            ]}
                        />
                    </OrderFontSize>
                }
                <FeedSearchByKeywords />


                {/* <FilterUserContainerHeader>{t("filter by user")}</FilterUserContainerHeader> */}
                <FacetSelection facetName='organization2' facets={facets}
                    //noCount 
                    value={org2} onChange={setOrg2} header={<SidebarTitleContainer icon="filter" title={t("filter by organization2")} />} />
                <FacetSelection
                    facetName='organization1'
                    facets={facets}
                    //noCount
                    value={org1}
                    onChange={setOrg1}
                    header={<SidebarTitleContainer icon="filter" title={t("filter by organization1")} />}
                    iconRendererForLabels={(ctry) => <Flag name={ctry as any} size={"16px"} paddingTop={2} />}
                    translateLabels={t} />
                <FacetSelection facetName='organization' facets={facets}
                    //noCount 
                    value={org} onChange={setOrg} header={<SidebarTitleContainer icon="filter" title={t("filter by organization")} />}
                />

                <SidebarTitleContainer icon="filter" title={t("filter by user")} />
                <FeedSearchByUser
                    startSearch={startSearch}
                    user={userSelected}
                    getUserSelected={(user) => setUserSelected(user)}
                    maxMenuHeight={isMobile ? showPMfilter ? 230 : 180 : undefined}
                    // maxMenuHeight={300}
                    menuShouldScrollIntoView

                />
                {isInGroup("EXCITE") &&
                    <div style={{ marginTop: 5 }}>
                        <SidebarButton
                            bold
                            icon='filter'
                            transparent
                            // title={t(momentsName)}
                            title={t("filter by group")}
                            noAnimation
                            noContainerClick
                            button={[
                                {
                                    icon: exciteGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? showPMfilter ? 'chevron up' : 'chevron down' : "chevron down",
                                    onClick: exciteGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? toggleShowPMfilter : undefined,
                                    selected: exciteGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? showPMfilter : undefined,
                                    tooltipText: exciteGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? showPMfilter ? t("hide {{post}} filter", { post: momentsName }) : t("show {{post}} filter", { post: momentsName }) : undefined,
                                    noArrow: false,
                                    hidden: !(exciteGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0)
                                },
                                {
                                    icon: (exciteHasExcludedGroups && !showPMfilter) ? "exclamation" : exciteHasExcludedGroups ? "checkbox unchecked" : "checkbox checked",
                                    onClick: (exciteHasExcludedGroups && !showPMfilter) ? toggleShowPMfilter : exciteHasExcludedGroups ? selectAllExciteMthd : deselectAllExciteMthd,
                                    hidden: exciteGroups.length < 2 || (!showPMfilter && !exciteHasExcludedGroups) || !(_.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0),
                                    tooltipText: (exciteHasExcludedGroups && !showPMfilter) ? t("{{type}} has {{count}} deselected groups", { type: momentsName, count: exciteExcludedGroupsCount }) : exciteHasExcludedGroups ? t("select all") : t("deselect all"),
                                    tooltipPosition: "bottom",
                                    noArrow: false,
                                    iconColor: (exciteHasExcludedGroups && !showPMfilter) ? "@accentYellow" : undefined
                                },
                                {
                                    icon: _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? "crown" : undefined,
                                    onClick: _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? () => setFilterExciteLuxury(!filterExciteLuxury) : undefined,
                                    tooltipText: filterExciteLuxury ? t("show all moments") : t("show only luxury moments"),
                                    noArrow: false,
                                    active: filterExciteLuxury,
                                    iconColor: filterExciteLuxury ? "@accentYellow" : undefined,
                                    kind: filterExciteLuxury ? "secondary" : undefined,
                                    hidden: !CurrentSettings.showLuxuryFilterInSidebar ? true : !(_.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0)
                                },


                            ]}
                        />
                    </div>
                }
                {showPMfilter && exciteGroups.length > 1 &&
                    <FilterContainer>
                        {/* {exciteGroups.length > 1 && */}
                        <RadioButtonsContainer>
                            {_.map(exciteGroups, e => {
                                const selected = _.findIndex(excludedGroups, g => _.isEqual(g, e.id)) < 0;
                                return (
                                    <RadioButtonsContainerElement key={e.id}>
                                        <Checkbox
                                            onClick={filterByGroup(e.id)}
                                            selected={selected}
                                            label={e.name}
                                        />
                                    </RadioButtonsContainerElement>
                                );
                            })}
                        </RadioButtonsContainer>
                        {/* } */}


                    </FilterContainer>
                }

                {resetButtonIsActive && isMobile &&
                    <div style={{ position: "absolute", bottom: 0, right: 50 }}>
                        <Button icon="filter times" floated="right" kind="halfTransparentButton" size='large' onClick={resetAll} />
                    </div>
                }


                {resetSearchButtonActive && isMobile && (
                    <>
                        <div style={{ position: "absolute", bottom: 0, right: resetButtonIsActive ? 100 : 50 }}>
                            <Button icon="search close" floated="right" kind="halfTransparentButton" size='large' onClick={resetSearch} />
                        </div>
                    </>
                )}
            </Container>
            {loading &&
                <>
                    {isMobile ?
                        <LottieMobile>
                            <LottieIcon url="images/lottiIcons/spinner.json" speed={1} autoplay loop width={"33vw"} />
                        </LottieMobile>
                        :
                        <LottieDesktop>
                            <LottieDesktopFile>
                                <LottieIcon url="images/lottiIcons/spinner.json" speed={1} autoplay loop width={40} />
                            </LottieDesktopFile>
                        </LottieDesktop>
                    }
                </>
            }
        </>
    )
}

export default FeedFilterAll;
