import * as React from "react";
import { AppRouteNames, ViewRouteNames } from "services/AppEntryDefinitions";
import { ApplicationState, useAppSelector } from "services/ApplicationState/ApplicationState";
import { shallowCompare } from "services/Helpers";
import { gotoNamedRoute } from "services/Helpers/RoutingHelper";

const mapper = (s: ApplicationState) => ({
    curAppEntry: s.currentAppEntry,
});

interface RedirectProps {
    from: AppRouteNames;
    to: AppRouteNames | ViewRouteNames;
}

const Redirect = (p: RedirectProps) => {
    const { from, to } = p;
    const { curAppEntry } = useAppSelector(mapper, shallowCompare);

    React.useEffect(() => {
        if (curAppEntry?.routeName === from)
            gotoNamedRoute(to);
    }, [curAppEntry, to, from]);

    return (
        <div />
    )
}

export default Redirect;