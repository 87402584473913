import { AnswerDownloadDto, CorrelatePairsQuestionDownloadDto, CorrelatePairsQuestionUserAnswer } from 'collaboration-service';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { AutoGrid, AutoGridColumn, Icon, Media } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { DragDropContext, Draggable, DragStart, Droppable, DropResult } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { QuizSC as T } from '../QuizSC';
import { getMediaFromLoadState, QuestionImplementationProps } from './Question';



const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage });

export interface CorrelatePairsQuestionProps extends QuestionImplementationProps, ComponentWithMappedApplicationState<typeof mapper> {
}

interface ExtendedCorrelatePairsQuestionUserAnswer extends CorrelatePairsQuestionUserAnswer {
    left: AnswerDownloadDto[];
    right: AnswerDownloadDto[];
}
export interface CorrelatePairsQuestionState {
    userAnswer: ExtendedCorrelatePairsQuestionUserAnswer;
    activeDragSource?: string;
}

class CorrelatePairsQuestion extends React.Component<CorrelatePairsQuestionProps, CorrelatePairsQuestionState> {
    constructor(props: CorrelatePairsQuestionProps) {
        super(props);

        this.state = {
            userAnswer: {
                id: "",
                left: [],
                right: [],
                selectedAnswers: []
            },
        }
    }

    componentDidMount() {
        this.fillLeftAndRight();
    }

    componentDidUpdate(prevProps: CorrelatePairsQuestionProps, prevState: CorrelatePairsQuestionState) {
        if (this.props.question.id !== prevProps.question.id) {
            this.fillLeftAndRight();
        }
    }

    public render() {
        const data = this.props.question as CorrelatePairsQuestionDownloadDto;
        const { left, right } = this.state.userAnswer;
        const { contentLanguage, loaded } = this.props;
        //console.log('this.state.userAnswer', this.state.userAnswer);


        const labels = ["A", "B", "C", "D", "E", "F", "G"];
        let currentLeftLabel = 0;
        let currentRightLabel = 0;
        const labelsLeft: { [id: string]: string; } = {};
        const labelsRight: { [id: string]: string; } = {};
        if (this.props.displayCorrect) {
            _.forEach(data.possibleAnswers, (a, i) => {
                const id = a.descriptions && a.descriptions.length > 0 ? getTranslated(a.descriptions, contentLanguage)?.text : a.id;
                if (i % 2 === 0) {
                    if (!labelsLeft[id]) {
                        labelsLeft[id] = labels[currentLeftLabel++];
                    }
                } else {
                    if (!labelsRight[id]) {
                        labelsRight[id] = labels[currentRightLabel++];
                    }
                }
            });
        }
        return (
            <>
                <T.Functions>
                    <AutoGrid columns={5} gap={1}>
                        <AutoGridColumn span={3}>
                            {left.map((a, index) => {
                                const id = a.descriptions && a.descriptions.length > 0 ? getTranslated(a.descriptions, contentLanguage)?.text : a.id;
                                return (
                                    <T.CPQContainerLeft key={a.id} >
                                        <T.CPQRibbonLeft><span>{labelsLeft[id]}</span> </T.CPQRibbonLeft>
                                        <T.SortText>
                                            {getTranslated(a.descriptions, contentLanguage)?.text}
                                        </T.SortText>
                                        <T.CPQIconRight>
                                            <Icon
                                                name="chevron right double"
                                                size="28px"
                                                marginTop={0}
                                                style={{ float: "left", marginLeft: 1 }}
                                            />
                                        </T.CPQIconRight>
                                    </T.CPQContainerLeft>
                                );
                            })}
                        </AutoGridColumn>
                        <AutoGridColumn span={2}>
                            <DragDropContext
                                onDragStart={this.onDragStart}
                                onDragEnd={this.onDragEnd}
                            >
                                <Droppable droppableId="droppable" mode='standard'>
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} >
                                            {right.map((a, index) => {
                                                const id = a.descriptions && a.descriptions.length > 0 ? getTranslated(a.descriptions, contentLanguage)?.text : a.id;
                                                const hasImage = a.media?.links.length! > 0;
                                                const hasText = a.descriptions.length! > 0;
                                                return (
                                                    <Draggable
                                                        key={a.id}
                                                        draggableId={a.id}
                                                        index={index}
                                                        isDragDisabled={this.props.displayCorrect || this.props.timeIsOver}
                                                    >
                                                        {(provided, snapshot) => {
                                                            const mediaIndex = _.findIndex(loaded, l => l.media?.reference === a.media?.reference ?? "");
                                                            return (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={{
                                                                        ...provided.draggableProps.style,
                                                                        left: "auto",
                                                                        top: "auto",
                                                                    }}

                                                                >
                                                                    <T.CPQContainerRight
                                                                        isDragging={snapshot.isDragging}
                                                                    >
                                                                        <T.CPQRibbonRight  ><span>{labelsRight[id]}</span> </T.CPQRibbonRight>
                                                                        {hasImage &&
                                                                            <Media
                                                                                media={getMediaFromLoadState(loaded, mediaIndex) ?? { url: "", type: "" }}
                                                                                height="78px"
                                                                                withImageBackground
                                                                                imageBackgroundHeight={78}
                                                                                imageModal
                                                                            />
                                                                        }
                                                                        {hasText && !hasImage &&
                                                                            <T.SortText
                                                                                withBg={hasImage}
                                                                                isDragging={snapshot.isDragging}
                                                                            >
                                                                                {getTranslated(a.descriptions, contentLanguage)?.text}
                                                                            </T.SortText>
                                                                        }
                                                                        <T.CoolaterateIcon
                                                                            name={index === 0 ? "chevron down" : index === (data.possibleAnswers.length / 2 - 1) ? "chevron up" : "chevron up down"}
                                                                            size="28px"
                                                                        />
                                                                    </T.CPQContainerRight>
                                                                </div>
                                                            );
                                                        }
                                                        }
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </AutoGridColumn>
                    </AutoGrid>
                </T.Functions >
            </>
        );
    }
    private onDragEnd = (result: DropResult) => {
        if (result.destination) {
            const userAnswer = _.clone(this.state.userAnswer);
            if (result.source.droppableId === "left") {
                const left = _.clone(userAnswer.left);
                const ele = left.splice(result.source.index, 1);
                left.splice(result.destination.index, 0, ...ele);
                const selectedAnswer = [];
                for (let i = 0; i < left.length; i++) {
                    selectedAnswer.push(left[i].id);
                    selectedAnswer.push(userAnswer.right[i].id);
                }
                userAnswer.selectedAnswers = selectedAnswer;
                userAnswer.left = left;
                this.setState({ activeDragSource: undefined, userAnswer });
            }
            else {
                const right = _.clone(userAnswer.right);
                const ele = right.splice(result.source.index, 1);
                right.splice(result.destination.index, 0, ...ele);
                const selectedAnswer = [];
                for (let i = 0; i < right.length; i++) {
                    selectedAnswer.push(userAnswer.left[i].id);
                    selectedAnswer.push(right[i].id);
                }
                userAnswer.selectedAnswers = selectedAnswer;
                userAnswer.right = right;
                if (this.props.answer)
                    this.props.answer(userAnswer);
                this.setState({ activeDragSource: undefined, userAnswer });
            }
        }
    };

    private onDragStart = (initial: DragStart) => {
        this.setState({ activeDragSource: initial.source.droppableId });
    };
    private fillLeftAndRight = () => {
        const data = this.props.question as CorrelatePairsQuestionDownloadDto;
        const { answers, questionNumber } = this.props;
        const ua = ((answers ?? [])[questionNumber ?? 0] as any)?.userAnswer as ExtendedCorrelatePairsQuestionUserAnswer;
        const userAnswer = _.clone(this.state.userAnswer);

        const left: AnswerDownloadDto[] = [];
        const right: AnswerDownloadDto[] = [];
        const sel: string[] = [];
        _.forEach(data.possibleAnswers, (a, i) => {
            if (i % 2 === 0)
                left.push(a);
            else
                right.push(a);
            sel.push(a.id);
        });
        userAnswer.selectedAnswers = sel;
        userAnswer.left = ua?.left ?? left;
        userAnswer.right = ua?.right ?? right;

        this.setState({ userAnswer });
    };
}

export default connect(mapper)(CorrelatePairsQuestion);
