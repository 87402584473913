import { MediaDownloadDto, PlaylistDownloadDto, PlaylistItemStateDownloadDto, PlaylistItemStateEnum, PlaylistStateDownloadDto } from 'collaboration-service';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Icon, ImgIcons, Tooltip, fadeColor, getColor, styled } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { ApplicationState, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { convertMinutesToDaysHoursMinutes, countNodeItemsToBeDonePerNode, countPlaylistItemsToBeDone, getHigherDate, getHighestAbsDueDate, getHighestDueDateRelativeInHours, shallowCompare } from 'services/Helpers';
import { createNamedRoute, gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { getTranslated, getTranslatedStandardDate } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage });

const CarouselSlideContainerOuter = styled.div<{ img: string }>`
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    background: #fff;
    background-image: url(${p => p.img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: all 0.2s ease-out;
    overflow: hidden;
    &::before{
        content:"";
        position: absolute; 
        top:0;
        right:0;
        bottom:0;
        left:0;
        background:inherit;
        transition: inherit;
    }
    &:hover::before { 
        transform: scale(1.1);
      }

      opacity: 1;
`;
const CarouselSlideContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    background: linear-gradient(to top, rgba(31, 31, 31, 0.9) 0%, rgba(31, 31, 31, 0.9) 20%, rgba(31, 31, 31, 0.85) 26.67%, rgba(32, 32, 32, 0.77) 33.33%, rgba(33, 33, 33, 0.667) 40%, rgba(34, 34, 34, 0.557) 46.67%, rgba(35, 35, 35, 0.443) 53.33%, rgba(36, 36, 36, 0.333) 60%, rgba(37, 37, 37, 0.23) 66.67%, rgba(38, 38, 38, 0.15) 73.33%, rgba(39, 39, 39, 0.082) 80%, rgba(39, 39, 39, 0.035) 86.67%, rgba(39, 39, 39, 0.008) 93.33%, rgba(39, 39, 39, 0) 100%);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content max-content;
    align-items: end;
    text-shadow: 1px 1px 2px #000;
`;
const CarouselSlideContainerTitle = styled.div`
        padding: 0 20px;
        color: ${p => getColor(p, "@mainBackground")};
        opacity: 1;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 22px;
    `;
const CarouselSlideContainerInfo = styled.div`
        padding: 0 20px 7px 20px;
        color: ${p => getColor(p, "@mainBackground")};
        opacity: 1;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
`;
const CarouselSlideContainerStatusBarContainer = styled.div<{ show: boolean }>`
        position: relative;
        height: 1px;
        width: calc(100% - 40px);
        margin-left: 20px;
        background: ${p => p.show ? fadeColor(p, "@mainBackground", 20) : "transparent"};
        margin-bottom: 16px;
    `;
const CarouselSlideContainerStatusBar = styled.div<{ percentage: number, color: string }>`
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: ${p => p.percentage}%;
        background: ${p => getColor(p, p.color)};
    `;
const ToolTipDiv = styled.div`
    margin-top: 10px;
`;
const ToolTipFooter = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 20px;
    width: calc(100% + 28px);
    padding: 10px 14px;
    margin-left: -14px;
    margin-bottom: -14px;
    font-size: 0.9em;
    border-top: 1px solid ${p => getColor(p, "@veryLightGrey")};
`;
const Mandatory = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    background: #fff;
    padding: 0 8px;
    font-size: 0.8em;
    color: #000;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
`;

export interface PlaylistOverviewCarouselItemProps {
    data: PlaylistDownloadDto | PlaylistStateDownloadDto;
    isAAO: boolean;
}

const PlaylistOverviewCarouselItem = (props: PlaylistOverviewCarouselItemProps) => {
    const { data, isAAO } = props;
    const { contentLanguage } = useAppSelector(mapper, shallowCompare);
    const { t } = useImgI18N("playlist");
    const [playlist, setPlaylist] = React.useState<PlaylistDownloadDto>();
    const [media, setMedia] = React.useState<MediaDownloadDto>();
    const dataType: "Playlist" | "PlaylistStates" = data.hasOwnProperty("playlist") ? "PlaylistStates" : "Playlist";
    const pState = data as PlaylistStateDownloadDto;

    React.useEffect(() => {
        if (dataType === "PlaylistStates") {
            const d = data as PlaylistStateDownloadDto;
            setPlaylist(d.playlist);
            setMedia(d.playlist.media);
        }
        else {
            const d = data as PlaylistDownloadDto;
            setPlaylist(d);
            setMedia(d.media);
        }
    },
        [data, dataType])

    const getState = React.useMemo(() => (states: PlaylistItemStateDownloadDto[]): PlaylistItemStateEnum => {
        let ret = 'NotAvailable' as PlaylistItemStateEnum;
        _.forEach(states, st => {
            if (st.state === 'Available')
                ret = 'Available';
        });
        _.forEach(states, st => {
            if (st.state === 'Started')
                ret = 'Started';
        });
        let finisheds = 0;
        _.forEach(states, st => {
            if (st.state === 'Finished')
                finisheds++;
        });
        if (finisheds === states.length)
            ret = 'Finished';
        return ret;
    }, []);




    const thumbnail = _.find(media?.links, l => l.ref === "self")?.uri ?? "";
    const absNodeDueDate = getHighestAbsDueDate(pState);
    const absNodeDueDateRelativeInHours = getHighestDueDateRelativeInHours(pState);
    const dueToDate = getHigherDate(absNodeDueDate, absNodeDueDateRelativeInHours);
    const curNodesDurationSum = (pState.curNodes || []).reduce((acc, curNode) => acc + (curNode.durationInMinutes || 0), 0);
    const prevNodesDurationSum = (pState.prevNodes || []).reduce((acc, prevNode) => acc + (prevNode.durationInMinutes || 0), 0);
    const nextNodesDurationSum = (pState.nextNodes || []).reduce((acc, nextNode) => acc + (nextNode.durationInMinutes || 0), 0);
    const sum = curNodesDurationSum + prevNodesDurationSum + nextNodesDurationSum;
    const sumDurationAll = convertMinutesToDaysHoursMinutes(sum, t, true);
    const detailRoute = playlist ? createNamedRoute("playlist_detail", { id: playlist.id }) : undefined;
    const item = data as PlaylistStateDownloadDto;
    const prevNodes = item.prevNodes ?? [];
    const curNodes = item.curNodes ?? [];
    const nextNodes = item.nextNodes ?? [];
    const curNodesReferenceIdsCount = countPlaylistItemsToBeDone(curNodes);
    const prevNodesReferenceIdsCount = countPlaylistItemsToBeDone(prevNodes);
    const nextNodesReferenceIdsCount = countPlaylistItemsToBeDone(nextNodes);
    const allNodesReferenceIdsCount = curNodesReferenceIdsCount + prevNodesReferenceIdsCount + nextNodesReferenceIdsCount;


    const getFinished = React.useMemo(() => (): number => {
        let count = 0;
        if (item) {
            count += _.filter(item.itemStates, s => s.state === 'Finished').length;
            count += _.filter(item.prevItemStates, s => s.state === 'Finished').length;
        }
        return count;
    }, [item]);

    const curNode = item.curNodes[0];


    const getCurrentLearningStatus = React.useMemo(() => (): { icon: ImgIcons; color: string; } => {
        const status = getState(item.itemStates ?? []);
        const dueTime = new Date(curNode?.absDueDate ?? new Date()).getTime() - new Date().getTime();
        if (status === 'Finished' && item.nextNodes.length === 0)
            return { icon: 'check circle', color: "@accentGreen" };
        if ((status !== 'Finished' && dueTime > 0) || isAAO) {
            return { icon: 'clock', color: "background" };
        }
        return { icon: 'exclamation triangle', color: "@accentRed" };
    }, [item, getState, curNode, isAAO]);

    const percent = Math.round(getFinished() / (isAAO ? item.itemStates.length : allNodesReferenceIdsCount) * 100);

    let criticalCount = 0;
    criticalCount += countNodeItemsToBeDonePerNode(pState, curNodes);
    criticalCount += countNodeItemsToBeDonePerNode(pState, prevNodes);
    criticalCount += countNodeItemsToBeDonePerNode(pState, nextNodes);

    return (
        <>
            <CarouselSlideContainerOuter img={thumbnail}>
                <CarouselSlideContainer onClick={detailRoute && playlist ? gotoNamedRouteMethod("playlist_detail", { id: playlist.id }) : undefined}>
                    <CarouselSlideContainerTitle>
                        <>{getTranslated(playlist?.headlines, contentLanguage)?.text}</>
                    </CarouselSlideContainerTitle>
                    <CarouselSlideContainerInfo>
                        <Tooltip
                            tooltipText=''
                            tooltipContent={<div style={{ textAlign: "justify" }}>
                                <div style={{ fontWeight: "bolder" }}>
                                    <SafeHTML
                                        allowedTags={["strong", "b"]}
                                        html={getTranslated(playlist?.headlines, contentLanguage)?.text}
                                    />
                                </div>
                                <ToolTipDiv>
                                    <SafeHTML
                                        html={getTranslated(playlist?.descriptions, contentLanguage)?.text}
                                    />
                                </ToolTipDiv>
                                {!isAAO &&
                                    <ToolTipFooter>
                                        <div>
                                            <Icon name="calendar" style={{ float: "left", marginRight: 5 }} marginTop={-2} size={16} />
                                            {dueToDate ? getTranslatedStandardDate(dueToDate, "PP") : ""}
                                        </div>
                                        {sum > 0 &&
                                            <div>
                                                <Icon name="clock" style={{ float: "left", marginRight: 5 }} marginTop={-1} size={16} />
                                                {sumDurationAll}
                                            </div>
                                        }
                                    </ToolTipFooter>
                                }

                            </div>}
                            noMargin position='bottom' notInline
                        >
                            <>
                                <Icon name={getCurrentLearningStatus().icon} style={{ float: "right" }} color={getCurrentLearningStatus().color} />
                                {t("{{fin}} / {{of}} completed", { fin: criticalCount, of: isAAO ? item.itemStates.length : allNodesReferenceIdsCount })}
                            </>
                        </Tooltip>

                    </CarouselSlideContainerInfo>
                    <CarouselSlideContainerStatusBarContainer show={true}>
                        <CarouselSlideContainerStatusBar percentage={percent} color={getCurrentLearningStatus().color} />
                    </CarouselSlideContainerStatusBarContainer>
                </CarouselSlideContainer>
                {!isAAO &&
                    <Mandatory>{t("mandatory")}</Mandatory>
                }
            </CarouselSlideContainerOuter>
            <div />
        </>
    );
}
export default PlaylistOverviewCarouselItem;
