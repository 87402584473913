import { CollaborationServiceSetup } from "collaboration-service";
import { LinkDto, LinkType } from "imaginarity-azure";
import * as _ from "lodash";
export const windowOpen = (url: string, param?: string) => {
    const u = url.replace("{{token}}", CollaborationServiceSetup.client.token);
    //    const mh = MobileHelper.getInstance();
    //    if (mh.iOSClient) {
    //        if (mh.appVersion > 1)
    //            MobileHelper.getInstance().openLink(u);
    //        else
    //            store.dispatch(ActionCreators.SetLinkAddress(u));
    //    }
    //    else
    window.open(u, param);
}
export function getLinkFromContent<T extends { links: LinkDto[] }>(content: T, ref?: LinkType) {
    return _.find(content.links, l => l.ref === (ref ?? "self"));
}

export function hasLink<T extends { links: LinkDto[] }>(content: T | undefined, ref: LinkType) {
    const idx = _.findIndex(content?.links, l => l.ref === ref as string);
    if (idx >= 0)
        return true;
    return false;
}

export function mayUpdate<T extends { links: LinkDto[] }>(content?: T) {
    if (!content)
        return false;
    return hasLink(content, "update");
}
export function may<T extends { links: LinkDto[] }>(content: T | undefined, what: LinkType) {
    if (!content)
        return false;
    return hasLink(content, what);
}
export function mayDelete<T extends { links: LinkDto[] }>(content?: T) {
    if (!content)
        return false;
    return hasLink(content, "delete");
}
