import { ControllerHelper, EventController, EventUploadDto, PlaylistEdgeTransitionFunction, PlaylistItemStateDownloadDto, TubePostDownloadDto } from 'collaboration-service';
import DateTimeFns from 'components/DateTime/DateTimeFns';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Button, Dimmer, Icon } from 'imaginarity-react-ui';
import * as React from 'react';
import { ApplicationState, useAppSelector } from "services/ApplicationState/ApplicationState";
import { DueInfo, shallowCompare } from 'services/Helpers';
import { sanitizedNothingAllowedAttributes } from 'services/Helpers/SanitizedHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { PlaylistDetailSC as T } from "../../PlaylistDetailSC";
import PreviewTubePodcast from './Previews/PreviewTubePodcast';
import MarkAsReadButton from './MarkAsReadButton';

const mapper = (s: ApplicationState) => ({
    contentLanguage: s.contentLanguage,
    currentGroup: s.currentGroup,
});

export interface LoadPlaylistStates {
    loadPlaylistStates?: () => Promise<void>;
}
interface PlaylistTubePodcastDimmerProps extends LoadPlaylistStates {
    playlistStateId?: string;
    id: string;
    item: TubePostDownloadDto;
    link: string;
    result: DueInfo;
    playlistId: string;
    state: PlaylistItemStateDownloadDto;
    isOptional: boolean;
    transition: PlaylistEdgeTransitionFunction | undefined;
    elementIsReference: boolean;
    nodeIsDone: boolean;
    warning: boolean;
    closeDimmer: () => void;
    isPodcast?: boolean;
}

const PlaylistTubePodcastDimmer = (p: PlaylistTubePodcastDimmerProps) => {
    const { contentLanguage, currentGroup } = useAppSelector(mapper, shallowCompare);
    const { playlistStateId, id, item, link, result, playlistId, state, isOptional, transition, elementIsReference, nodeIsDone, warning, closeDimmer, isPodcast, loadPlaylistStates } = p;
    const { t } = useImgI18N('playlist');

    React.useEffect(() => {
        if (currentGroup && playlistId) {
            const ev: EventUploadDto = {
                eventType: "PostViewed",
                group_id: currentGroup.id,
                payload: {
                    "PostId": item.id,
                    "From": "playlist",
                    "PlaylistId": playlistId,
                }
            };
            ControllerHelper.singleCall({ ev }, EventController.PostEvent);
        }
    }, [currentGroup, playlistId, item.id]);

    return (
        <Dimmer active >
            <T.DimmerContainer >
                <T.DimmerVideoContainer>
                    <PreviewTubePodcast
                        playlistStateId={playlistStateId}
                        id={id}
                        isPodcast={isPodcast}
                    />
                </T.DimmerVideoContainer>
                <T.DimmerInfoContainer>
                    <T.DimmerInfoContainerTitle>
                        <SafeHTML html={getTranslated(item.headlines, contentLanguage)?.text ?? ""} allowedTags={[]} allowedAttributes={{}} />
                    </T.DimmerInfoContainerTitle>
                    <T.DimmerInfoContainerButton>
                        <Button
                            icon="link external"
                            content={isPodcast ? t("open in podcast") : t("open in tube")}
                            bordered
                            kind="secondary"
                            floated="right"
                            asHtmlLinkToRoute={link}
                        />
                        <MarkAsReadButton
                            playlistId={playlistId}
                            playlistItemState={state}
                            item={item}
                            lastAction={loadPlaylistStates}
                        />
                    </T.DimmerInfoContainerButton>
                    <T.DimmerInfoContainerDate>
                        {result.dueToDate &&
                            <div style={{ float: "left" }}>
                                <DateTimeFns
                                    date={result.dueToDate}
                                    noTime
                                    hideIcons
                                    textColor="grey"
                                />
                            </div>
                        }
                        {isOptional ?
                            <T.ColoredSpan color={state.state === "Finished" ? "@accentGreen" : "inherit"}>
                                {state.state === "Finished" ? t("100%") : ""}
                            </T.ColoredSpan>
                            :
                            (transition === "ReferenceDone" || transition === "ReferenceDoneWithValue") ?
                                elementIsReference ?
                                    <T.ColoredSpan color={(result.text === "100%" || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color}>
                                        {nodeIsDone ? "100%" : warning ? "warning" : result.text}
                                    </T.ColoredSpan>
                                    :
                                    <T.ColoredSpan color={result.text === "100%" ? "@accentGreen" : "@middleLightGrey"}>
                                        {result.text === "100%" ? "100%" : t("optional")}
                                    </T.ColoredSpan>
                                :
                                <T.ColoredSpan color={result.text === "100%" ? "@accentGreen" : warning ? "@warning" : result.color}>
                                    {result.text}
                                </T.ColoredSpan>
                        }
                    </T.DimmerInfoContainerDate>
                </T.DimmerInfoContainer>

                <T.DimmerInfoContainerDescription>
                    <SafeHTML
                        html={getTranslated(item.descriptions, contentLanguage).text ?? ""}
                        allowedTags={["strong", "em", "b", "i", "u"]}
                        allowedAttributes={sanitizedNothingAllowedAttributes}
                    />
                </T.DimmerInfoContainerDescription>
                <T.DimmerInfoContainerClose onClick={closeDimmer} >
                    <Icon name="times" size={27} marginTop={3} />
                </T.DimmerInfoContainerClose>
            </T.DimmerContainer>
        </Dimmer>
    );
}
export default PlaylistTubePodcastDimmer;