import { UserCommentDownloadDto } from 'collaboration-service';
import CommentsView from 'components/Comments/CommentsView';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { formatDistanceToNow } from 'date-fns';
import { belowBreakpoint, Button, Dimmer, Divider, ImgThemeConsumer, PostImageStyles } from 'imaginarity-react-ui';
import * as React from 'react';
import { PostProps } from 'services/ApplicationState/ApplicationComponentsFactory';
import { ImgI18N } from 'services/ImgI18N';
import { feedTheme } from 'views/Feed/FeedMain';
import { BottomButton, BottomOpenButton } from 'views/QandA/QandAClasses';
import { getMediaLink } from '../../services/Helpers/MediaHelpers';
import { BadgeContentType, ButtonBarRight, ContentContent, ContentDate, ContentHeadline, ContentTop, ContentUser, IconCategoryCrumb, IconFloated, ImageButtonsBg, ImageSecond, ImageThird, InnerImageContainer, IsExcitePost, LightboxContainer, LightboxFooter, LightboxImage, OuterImageContainer, OuterImageContainerSpecial, PostInfos, SCItem, SettingContainer, SPCButtons, SPCContent, SPCContentMobile, SPCIBackground, SPCIBackgroundSpecial, SPCImage, SPContainer, SPContainerMobile, SPCType, TypeContainer } from './PostClasses';

export const colors = {
    "PorscheMomentPost": "#950013",
    "Quiz": "#FA5B0F",
    "CommunityFeedPost": "#4C1273",
    "SlideShow": "#0D47A1",
    "NewsPost": "#2E7D32",
    "BestPractice": "#313638",
};

export const postTypes = {
    "PorscheMomentPost": "Excite",
    "Quiz": "Porsche Quizzes",
    "CommunityFeedPost": "Community",
    "SlideShow": "SlideShow",
    "NewsPost": "SMW",
};

interface StandardPostProps extends PostProps {
}

interface StandardPostState {
    comments?: UserCommentDownloadDto[];
    showSettings: boolean;
    showComments: boolean;
    showRatings: boolean;
    showLightbox: boolean;
    bgImage: string;
    leftBoxImageStyle: PostImageStyles;
}

export interface SettingsBarProps {
    col: string;
    type: string;
    showSettings: boolean;
    showComments: boolean;
    showRatings: boolean;
    toggleSettings: () => void;
    toggleComments: () => void;
    toggleRatings: () => void;
    toggleLeftImageStyle(iStyle: PostImageStyles): () => void;
}

export interface ImageBarProps {
    col: string;
    type: string;
    activeLightbox: boolean;
    image: string;
    dummyImage1: string;
    dummyImage2: string;
    toggleLightbox: () => void;
    bgImage: string;
    changeImage(setImage: string): () => void;
    headline: string;
    name: string;
    style: PostImageStyles;
}

// const leftBarImageStyle = theme.posts.leftImageStyle;
const momentsName = feedTheme.momentsName;

export const ImageBar = (ibp: ImageBarProps) => (
    <SPCImage imageUrl={ibp.bgImage} bgColor={ibp.col}>
        <Dimmer active={ibp.activeLightbox} onClick={ibp.toggleLightbox} >
            <LightboxContainer>
                <LightboxImage src={ibp.bgImage} />
                <LightboxFooter>{ibp.headline} ({ibp.name})</LightboxFooter>
            </LightboxContainer>
        </Dimmer>

        {ibp.style === "thumbnails" &&
            <SPCIBackground
                imageUrl={ibp.image}
                bgColor={ibp.col}
                onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox}
            >
                {ibp.type === "PorscheMomentPost" &&
                    <IsExcitePost color={ibp.col}>{momentsName}</IsExcitePost>
                }
                {ibp.image !== undefined && ibp.image !== "" &&
                    <ImageButtonsBg color={ibp.col}>
                        <ImageSecond imageUrl={ibp.dummyImage1} color={ibp.col} onMouseEnter={ibp.changeImage(ibp.dummyImage1)} onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox} />
                        <ImageThird imageUrl={ibp.dummyImage2} color={ibp.col} onMouseEnter={ibp.changeImage(ibp.dummyImage2)} onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox} />
                    </ImageButtonsBg>
                }
            </SPCIBackground>
        }

        {ibp.style === "arrows" &&
            <SPCIBackground
                imageUrl={ibp.bgImage}
                bgColor={ibp.col}
            >
                {ibp.type === "PorscheMomentPost" &&
                    <IsExcitePost color={ibp.col}>{momentsName}</IsExcitePost>
                }
                {ibp.image !== undefined && ibp.image !== "" &&
                    <ImageButtonsBg color={ibp.col}>
                        <Button icon="search" kind="transparentButton" floated="left" onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox} />
                        <Button icon="chevron right" kind="transparentButton" floated="right" onClick={ibp.changeImage(ibp.dummyImage1)} />
                        <Button icon="chevron left" kind="transparentButton" floated="right" onClick={ibp.changeImage(ibp.dummyImage2)} />
                    </ImageButtonsBg>
                }
            </SPCIBackground>
        }

        {ibp.style === "accordion" &&
            <>
                {ibp.image !== undefined && ibp.image !== "" &&
                    <OuterImageContainer>
                        <InnerImageContainer imageUrl={ibp.image} onMouseEnter={ibp.changeImage(ibp.image)} onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox} />
                        {/* ADDITIONAL IMAGES */}
                        <InnerImageContainer imageUrl={ibp.dummyImage1} onMouseEnter={ibp.changeImage(ibp.dummyImage1)} onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox} />
                        <InnerImageContainer imageUrl={ibp.dummyImage2} onMouseEnter={ibp.changeImage(ibp.dummyImage2)} onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox} />
                    </OuterImageContainer>
                }
            </>
        }
    </SPCImage>
);

export const ImageBarSpecial = (ibp: ImageBarProps) => (
    <SPCImage imageUrl={ibp.bgImage} bgColor={ibp.col}>

        <Dimmer active={ibp.activeLightbox} onClick={ibp.toggleLightbox} >
            <LightboxContainer>
                <LightboxImage src={ibp.bgImage} />
                <LightboxFooter>{ibp.headline} ({ibp.name})</LightboxFooter>
            </LightboxContainer>
        </Dimmer>

        {ibp.style === "thumbnails" &&
            <SPCIBackgroundSpecial
                imageUrl={ibp.image}
                bgColor={ibp.col}
                onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox}
            >
                {ibp.type === "PorscheMomentPost" &&
                    <IsExcitePost color={ibp.col}>{momentsName}</IsExcitePost>
                }
                {ibp.image !== undefined && ibp.image !== "" &&
                    <ImageButtonsBg color={ibp.col}>
                        <ImageSecond imageUrl={ibp.dummyImage1} color={ibp.col} onMouseEnter={ibp.changeImage(ibp.dummyImage1)} onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox} />
                        <ImageThird imageUrl={ibp.dummyImage2} color={ibp.col} onMouseEnter={ibp.changeImage(ibp.dummyImage2)} onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox} />
                    </ImageButtonsBg>
                }
            </SPCIBackgroundSpecial>
        }

        {ibp.style === "arrows" &&
            <SPCIBackgroundSpecial
                imageUrl={ibp.bgImage}
                bgColor={ibp.col}
            >
                {ibp.type === "PorscheMomentPost" &&
                    <IsExcitePost color={ibp.col}>{momentsName}</IsExcitePost>
                }
                {ibp.image !== undefined && ibp.image !== "" &&
                    <ImageButtonsBg color={ibp.col}>
                        <Button icon="maximize" kind="transparentButton" floated="left" onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox} />
                        <Button icon="chevron right" kind="transparentButton" floated="right" onClick={ibp.changeImage(ibp.dummyImage1)} />
                        <Button icon="chevron left" kind="transparentButton" floated="right" onClick={ibp.changeImage(ibp.dummyImage2)} />
                    </ImageButtonsBg>
                }
            </SPCIBackgroundSpecial>
        }

        {ibp.style === "accordion" &&
            <>
                {ibp.image !== undefined && ibp.image !== "" &&
                    <OuterImageContainerSpecial>
                        <InnerImageContainer imageUrl={ibp.image} onMouseEnter={ibp.changeImage(ibp.image)} onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox} />
                        {/* ADDITIONAL IMAGES */}
                        <InnerImageContainer imageUrl={ibp.dummyImage1} onMouseEnter={ibp.changeImage(ibp.dummyImage1)} onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox} />
                        <InnerImageContainer imageUrl={ibp.dummyImage2} onMouseEnter={ibp.changeImage(ibp.dummyImage2)} onClick={ibp.image === undefined ? undefined : ibp.toggleLightbox} />
                    </OuterImageContainerSpecial>
                }
            </>
        }
    </SPCImage>
);



export const SettingsBar = (sbp: SettingsBarProps) => (
    <SPCButtons color={sbp.col}>
        <ButtonBarRight active={sbp.showComments} icon="comment" kind="transparentButton" size="default" padding="0px" onClick={sbp.toggleComments} />
        <ButtonBarRight icon="bookmark" kind="transparentButton" size="default" padding="0px" />
        {(sbp.type === "SlideShow" || sbp.type === "Quiz") &&
            <ButtonBarRight active={sbp.showRatings} icon="star empty" kind="transparentButton" size="default" padding="0px" onClick={sbp.toggleRatings} />
        }
        <ButtonBarRight icon="thumb up" kind="transparentButton" size="default" padding="0px" />

        <BottomButton>
            <ButtonBarRight icon="more" kind="transparentButton" size="default" padding="0px" onClick={sbp.toggleSettings} active={sbp.showSettings} />
            {(sbp.type === "SlideShow" || sbp.type === "Quiz" || sbp.type === "NewsPost") &&
                <BottomOpenButton color={sbp.col} >
                    <ImgThemeConsumer>
                        {(theme) => <ButtonBarRight fluid={theme.posts.postTypeColoredButton} icon="chevron right" kind={theme.posts.postTypeColoredButton ? "transparentButton" : "primary"} size="default" padding="0px" />}
                    </ImgThemeConsumer>
                </BottomOpenButton>
            }
        </BottomButton>
        {sbp.showSettings ?
            <SettingContainer padded={sbp.type !== "SlideShow" && sbp.type !== "Quiz" && sbp.type !== "NewsPost"}>
                <SCItem>
                    <Button icon="column thirds" kind="tertiary" size="default" onClick={sbp.toggleLeftImageStyle("accordion")} />
                </SCItem>
                <SCItem>
                    <Button icon="shapes" kind="tertiary" size="default" onClick={sbp.toggleLeftImageStyle("thumbnails")} />
                </SCItem>
                <SCItem>
                    <Button icon="chevron left right" kind="tertiary" size="default" onClick={sbp.toggleLeftImageStyle("arrows")} />
                </SCItem>

                <SCItem>
                    <Button icon="edit" kind="secondary" size="default" />
                </SCItem>

                <SCItem>
                    <Button icon="delete" kind="secondary" size="default" />
                </SCItem>
            </SettingContainer>
            : undefined}
    </SPCButtons>
);

export const dummyBG1 = "https://cdn.imaginarity.com/public/images/testImage1.jpg";
export const dummyBG2 = "https://cdn.imaginarity.com/public/images/testImage2.jpg";

class StandardPost extends React.Component<StandardPostProps, StandardPostState> {

    constructor(p: StandardPostProps) {
        super(p);
        this.state = {
            showSettings: false,
            showComments: false,
            showRatings: false,
            showLightbox: false,
            bgImage: getMediaLink(this.props.post, x => x.media) as string,
            leftBoxImageStyle: "accordion",
        };
    }

    public render() {
        const p = this.props.post;
        const type = p.type;
        const col = (colors as any)[type ?? ""] ?? "#7f7f7f";
        const pT = (postTypes as any)[type ?? ""] ?? "Community";
        const img1 = getMediaLink(p, x => x.media);
        const commentCount = p.commentCount;
        const avatar = getMediaLink(p, y => y.creator?.avatar);

        const created = formatDistanceToNow(new Date(p.created), { addSuffix: true, locale: ImgI18N.getInstance().languageLocal() });

        return (
            <ImgThemeConsumer>
                {(theme) => {
                    const isMobile = belowBreakpoint({ theme }, "tablet");
                    return (
                        isMobile ?
                            <SPContainerMobile bgColor={col}>
                                <TypeContainer>{pT}</TypeContainer>
                                <ImageBar
                                    col={col}
                                    type={type ?? ""}
                                    activeLightbox={this.state.showLightbox}
                                    bgImage={this.state.bgImage}
                                    changeImage={this.changeImage}
                                    image={img1 ?? ""}
                                    dummyImage1={dummyBG1 ?? ""}
                                    dummyImage2={dummyBG2 ?? ""}
                                    toggleLightbox={this.toggleLightbox}
                                    headline={p.headlines[0]?.text}
                                    name={p.creator ? p.creator.firstName + " " + p.creator.secondName : ""}
                                    style={this.state.leftBoxImageStyle}
                                />
                                <SettingsBar
                                    col={col}
                                    type={type ?? ""}
                                    showComments={this.state.showComments}
                                    showRatings={this.state.showRatings}
                                    showSettings={this.state.showSettings}
                                    toggleSettings={this.toggleSettings}
                                    toggleComments={this.toggleComments}
                                    toggleRatings={this.toggleRatings}
                                    toggleLeftImageStyle={this.toggleLeftImageStyle}
                                />
                                <SPCContentMobile >
                                    <BadgeContentType color={col}>
                                        {pT}
                                        <IconCategoryCrumb name="chevron right" color={col} size={8} />
                                        Cat_XY
                                        <PostInfos color={col}>Likes<IconCategoryCrumb name="chevron right" color={col} size={8} />5</PostInfos>
                                        <PostInfos color={col} onClick={this.toggleComments}>Kommentare<IconCategoryCrumb name="chevron right" color={col} size={8} />{commentCount}</PostInfos>
                                    </BadgeContentType>
                                    <ContentTop type={type} color={col}>
                                        <ContentDate>{created}</ContentDate>
                                        <ContentUser type={type} color={col}>
                                            <img src={avatar} alt="img" style={{ borderRadius: "50%", width: "24px", float: "left", marginRight: 5 }} />
                                            {p.creator?.firstName ?? ""} {p.creator?.secondName ?? ""}
                                        </ContentUser>
                                    </ContentTop>
                                    <ContentHeadline>
                                        <Divider color={theme.colors.veryLightGrey} />
                                        <IconFloated name="chevron right" size={18} color={col} />
                                        {p.headlines[0]?.text}
                                    </ContentHeadline>
                                    <ContentContent>
                                        {p.descriptions &&
                                            <SafeHTML html={p.descriptions[0]?.text} />
                                        }
                                        {this.state.showComments &&
                                            <>
                                                <Divider color={theme.colors.veryLightGrey} />
                                                <CommentsView typeColor={col} asRating />
                                            </>
                                        }
                                    </ContentContent>
                                </SPCContentMobile>
                            </SPContainerMobile>
                            :
                            <SPContainer bgColor={col}>
                                <SPCType bgColor={col} />
                                <ImageBar
                                    col={col}
                                    type={type ?? ""}
                                    activeLightbox={this.state.showLightbox}
                                    bgImage={this.state.bgImage}
                                    changeImage={this.changeImage}
                                    image={img1 ?? ""}
                                    dummyImage1={dummyBG1 ?? ""}
                                    dummyImage2={dummyBG2 ?? ""}
                                    toggleLightbox={this.toggleLightbox}
                                    headline={p.headlines[0]?.text ?? ""}
                                    name={p.creator ? p.creator?.firstName + " " + p.creator?.secondName : ""}
                                    style={this.state.leftBoxImageStyle}
                                />
                                <SPCContent >
                                    <BadgeContentType color={col}>
                                        {pT}
                                        <IconCategoryCrumb name="chevron right" color={col} size={8} />
                                        Cat_XY
                                        <PostInfos color={col}><IconCategoryCrumb name="thumb up" size={8} />5</PostInfos>
                                        <PostInfos color={col} onClick={this.toggleComments}><IconCategoryCrumb name="comment" size={8} />{commentCount}</PostInfos>
                                    </BadgeContentType>
                                    <ContentTop type={type} color={col}>
                                        <ContentDate>{created}</ContentDate>
                                        <ContentUser type={type} color={col}>
                                            <img src={avatar} alt="img" style={{ borderRadius: "50%", width: "24px", float: "left", marginRight: 5 }} />
                                            {p.creator?.firstName ?? ""} {p.creator?.secondName ?? ""}
                                        </ContentUser>
                                    </ContentTop>
                                    <ContentHeadline>
                                        <Divider color={theme.colors.veryLightGrey} />
                                        <IconFloated name="chevron right" size={18} color={col} />
                                        {p.headlines[0]?.text ?? ""}
                                    </ContentHeadline>
                                    <ContentContent>
                                        {p.descriptions &&
                                            <SafeHTML html={p.descriptions[0]?.text} />
                                        }

                                        {/* {p.descriptions && (p.descriptions[0]?.text ?? "")} */}
                                        {this.state.showComments &&
                                            <>
                                                <Divider color={theme.colors.veryLightGrey} />
                                                <CommentsView typeColor={col} asRating />
                                            </>
                                        }
                                    </ContentContent>
                                </SPCContent>
                                <SettingsBar
                                    col={col}
                                    type={type ?? ""}
                                    showComments={this.state.showComments}
                                    showRatings={this.state.showRatings}
                                    showSettings={this.state.showSettings}
                                    toggleSettings={this.toggleSettings}
                                    toggleComments={this.toggleComments}
                                    toggleRatings={this.toggleRatings}
                                    toggleLeftImageStyle={this.toggleLeftImageStyle}
                                />
                            </SPContainer>
                    )
                }}
            </ImgThemeConsumer>
        );
    }
    private toggleSettings = () => { this.setState({ showSettings: !this.state.showSettings }) };
    private toggleComments = () => { this.setState({ showComments: !this.state.showComments }) };
    private toggleRatings = () => { this.setState({ showRatings: !this.state.showRatings }) };
    private toggleLightbox = () => { this.setState({ showLightbox: !this.state.showLightbox }) };
    private changeImage = (setImage: string) => () => { this.setState({ bgImage: setImage }) };
    private toggleLeftImageStyle = (iStyle: PostImageStyles) => () => { this.setState({ leftBoxImageStyle: iStyle, showSettings: false }) };

}
export default StandardPost;

