import { Icon, ImgIcons } from 'imaginarity-react-ui';
import * as React from 'react';
import { SidebarContainerTitle, SidebarContainerTitleFilledIcon } from 'views/CustomSC';


interface SidebarTitleContainerProps {
    title: string;
    additionalInfo?: JSX.Element;
    icon?: ImgIcons;
    highlighted?: boolean;
    iconFillColor?: string;
    noBold?: boolean;
}

const SidebarTitleContainer = (p: SidebarTitleContainerProps) => {
    const { icon, title, additionalInfo, highlighted, iconFillColor, noBold } = p;
    return (
        <SidebarContainerTitle highlighted={highlighted} color={iconFillColor ?? undefined} noBold={noBold}>
            {icon &&
                <SidebarContainerTitleFilledIcon iconFillColor={iconFillColor ?? undefined}>
                    <Icon name={icon} style={{ float: "left", marginRight: 5 }} marginTop={4} />
                </SidebarContainerTitleFilledIcon>
            }
            {title}
            {additionalInfo &&
                <div style={{ float: "right" }}>
                    {additionalInfo}
                </div>
            }
        </SidebarContainerTitle>
    );
}
export default SidebarTitleContainer;