import { CategoryDownloadDto, ContentController, ControllerHelper } from 'collaboration-service';
import { SelectCount, SelectElementContainerWithCount } from 'components/SelectHelpers/SelectHelpers';
import { components, GroupBase, ImgOnChangeValue, ImgSelect, ImgSelectProps, MultiValueProps, OptionProps, SingleValueProps } from 'imaginarity-react-ui';
import { rootElement } from 'index';
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { getTranslated } from 'services/Helpers/TranslationHelpers';

export interface CategoryWithCount extends CategoryDownloadDto {
    count?: number;
}




const Option = (lng?: string) => <IsMulti extends boolean>(p: OptionProps<CategoryWithCount, IsMulti, GroupBase<CategoryWithCount>>) => {
    const { data } = p;

    return (
        <components.Option {...p} >
            <SelectElementContainerWithCount>
                <div>{getTranslated(data.names, lng)?.text ?? ""}</div>
                {(data.count ?? 0) > 0 && <SelectCount>{data.count ?? 0}</SelectCount>}
            </SelectElementContainerWithCount>
        </components.Option>
    )
}
const SingleValue = (lng?: string) => <IsMulti extends boolean>(p: SingleValueProps<CategoryWithCount, IsMulti, GroupBase<CategoryWithCount>>) => {
    const { data } = p;
    return (
        <components.SingleValue {...p} >
            <SelectElementContainerWithCount>
                <div>{getTranslated(data.names, lng)?.text ?? ""}</div>
                {(data.count ?? 0) > 0 && <SelectCount>{data.count ?? 0}</SelectCount>}
            </SelectElementContainerWithCount>
        </components.SingleValue>
    )
}

const MultiValue = (lng?: string) => <IsMulti extends boolean>(p: MultiValueProps<CategoryWithCount, IsMulti, GroupBase<CategoryWithCount>>) => {
    const { data } = p;

    return (
        <components.MultiValue {...p} >
            <SelectElementContainerWithCount>
                <div>{getTranslated(data.names, lng)?.text ?? ""}</div>
                {(data.count ?? 0) > 0 && <SelectCount>({data.count ?? 0})</SelectCount>}
            </SelectElementContainerWithCount>
        </components.MultiValue>
    )
}

export interface CategorySelectionExtendedProps<IsMulti extends boolean> extends Omit<ImgSelectProps<CategoryWithCount, IsMulti>, "onChange" | "isMulti"> {
    onCategoryChanged: (value: ImgOnChangeValue<CategoryWithCount, IsMulti, false>) => void;
    categories?: CategoryWithCount[];
    groupId?: string;
    groupType?: string;
    isMulti: IsMulti;
    categoryType?: string;
    menuPortal?: HTMLElement | null;
    lng?: string;
}

const CategorySelectionExtended = <IsMulti extends boolean = true>(p: CategorySelectionExtendedProps<IsMulti>) => {
    const { categories, groupId, groupType, isMulti, onCategoryChanged, value, categoryType, menuPortal, lng, ...rest } = p;
    const [options, setOptions] = React.useState<CategoryWithCount[]>([]);
    const contentLanguage = useSelector((s: ApplicationState) => s.contentLanguage);

    const so = React.useMemo(() => (cats: CategoryWithCount[]) => setOptions(_.reverse(_.sortBy(_.reverse(cats), c => c.count ?? 0))), [setOptions])

    React.useEffect(() => {
        const load = async () => {
            if (categories) {
                //console.log("using supplied cats");
                so(categories);
            }
            else {
                if (categoryType) {
                    if (groupId) {
                        //console.log("loading cats from group");
                        const cats = await ControllerHelper.singleCall({ groupid: groupId, categoryType }, ContentController.GetCategoriesForCategoryTypeWithConfigurations, true);
                        so(cats?.configurations[0]?.categories ?? []);
                    }
                    else {
                        //console.log("loading cats with grouptype");
                        const cats = await ControllerHelper.singleCall({ grouptype: groupType, categoryType }, ContentController.GetCategoriesForAllGroupsByGroupTypeForCategoryTypeWithConfigurations, true);
                        so(cats?.configurations[0]?.categories ?? []);
                    }
                }
                else {
                    if (groupId) {
                        //console.log("loading cats from group");
                        const cats = await ControllerHelper.singleCall({ groupid: groupId }, ContentController.GetCategories, true);
                        so(cats ?? []);
                    }
                    else {
                        //console.log("loading cats with grouptype");
                        const cats = await ControllerHelper.singleCall({ grouptype: groupType }, ContentController.GetCategoriesForAllGroupsByGroupType, true);
                        so(cats ?? []);
                    }
                }
            }
        }
        load();
    }, [groupId, categories, groupType, categoryType, so]);
    const val = React.useMemo(() => _.isArray(value) ? _.filter(options, o => _.find(value, v => v.id === o.id) !== undefined) : _.find(options, o => o.id === (value as any)?.id), [value, options]);

    if (!options)
        return <div />;
    return (
        <ImgSelect
            onChange={onCategoryChanged}
            options={options}
            value={val}
            components={{
                Option: Option(lng),
                SingleValue: SingleValue(lng),
                MultiValue: MultiValue(lng),
            }}
            getOptionLabel={o => getTranslated(o.names, lng ?? contentLanguage).text}
            getOptionValue={o => o.id}
            isMulti={isMulti}
            menuPortalTarget={menuPortal !== undefined ? menuPortal : rootElement}
            closeMenuOnSelect={isMulti ? false : true}
            {...rest}
        />
    );
}
export default CategorySelectionExtended;