import { BookmarkController, CategoryDownloadDto, ContentController, ControllerHelper, EventController, EventUploadDto, PodcastPostDownloadDto, PostController, PostControllerTube, PostInteraction, UserCommentDownloadDto, UserCommentsDownloadDto, UserRatingsDownloadDto, ViewStats } from 'collaboration-service';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import DownloadButton from 'components/DownloadButton/DownloadButton';
import CommentsRatingsView from 'components/General/CommentsRatingsView';
import InfoBox from 'components/InfoBox/InfoBox';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import SwitchButton from 'components/Switch/SwitchButton';
import { formatDistanceToNow, formatRelative } from 'date-fns';
import locale_de from "date-fns/locale/de";
import locale_el from "date-fns/locale/el";
import locale_en from "date-fns/locale/en-GB";
import locale_enUS from "date-fns/locale/en-US";
import locale_es from "date-fns/locale/es";
import locale_fr from "date-fns/locale/fr";
import locale_it from "date-fns/locale/it";
import locale_jp from "date-fns/locale/ja";
import locale_ko from "date-fns/locale/ko";
import locale_nl from "date-fns/locale/nl";
import locale_pt from "date-fns/locale/pt";
import locale_ru from "date-fns/locale/ru";
import locale_zhCN from "date-fns/locale/zh-CN";
import locale_zhTW from "date-fns/locale/zh-TW";
import { ExtendedDataCollection } from 'imaginarity-azure';
import { Button, Divider, Icon, Image, Loader, LoaderInline, RatingView, Theme, Tooltip, getUriFromLinkByName, useBelowBreakpoint } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { breakpoints } from 'services/ApplicationState/MasterLayout';
import { getLink, isChangedDateGreaterThanCreatedDateWithoutTime, reducerBatchJob, reducerSetIfChanged, shallowCompare, updateCategoriesAndCurrentStack } from 'services/Helpers';
import { getBrowser } from 'services/Helpers/BrowserHelper';
import { getMediaLink, getThumbnailOrMediaLinkForContent } from 'services/Helpers/MediaHelpers';
import { getCurrentCat, gotoNamedRoute, gotoNamedRouteMethod, tubeRoute } from 'services/Helpers/RoutingHelper';
import ScrollHelper from 'services/Helpers/ScrollHelper';
import { getTranslated, getTranslatedForTube as getTranslatedForPodcast, getTranslatedUserName } from 'services/Helpers/TranslationHelpers';
import History from 'services/History';
import { ImgI18N, useImgI18N } from 'services/ImgI18N';
import PostHubConnection from 'services/signalR/PostHubConnection';
import { useAppTheme, useThemePart } from 'services/useAppTheme';
import PodcastAudioInfo from './PodcastAudioInfo';
import PodcastAudioNeighbours from './PodcastAudioNeighbours';
import PodcastContent from './PodcastContent';
import PodcastHeader from './PodcastHeader';
import { PodcastTheme, podcastTheme } from './PodcastMain';
import { PodcastSC as T } from './PodcastSC';



export const lngDefinitions = [
    { label: "Japanese", value: "jp", newCode: "ja-JP", flag: ["jp"], order: 'J', locale: locale_jp, tvalue: "ja", visible: true },
    { label: "Chinese (Simplified)", newCode: "zh-CN", value: "zh-CN", flag: ["cn"], order: 'C', locale: locale_zhCN, tvalue: "zh-Hans", visible: true },
    { label: "Chinese (Traditional)", newCode: "zh-TW", value: "zh-TW", flag: ["tw"], order: 'T', locale: locale_zhTW, tvalue: "zh-Hans", visible: true },
    { label: "Korean", value: "ko", newCode: "ko-KR", flag: ["kr"], order: 'K', locale: locale_ko, tvalue: "ko", visible: true },
    { label: "Portuguese", value: "pt", newCode: "pt-BR", flag: ["pt"], order: 'P', locale: locale_pt, tvalue: "pt-BR", visible: true },
    { label: "English", value: "en", newCode: "en-GB", flag: ["gb"], order: 'A', locale: locale_en, tvalue: "en", visible: true },
    { label: "English", value: "en-US", newCode: "en-US", flag: ["us"], order: 'B', locale: locale_enUS, tvalue: "en", visible: true },
    { label: "English (korean)", newCode: "en-GB", value: "en-kr", flag: ["gb", "kr"], order: 'KR', locale: locale_en, tvalue: "en", visible: true },
    { label: "Spanish", value: "es", newCode: "es-ES", flag: ["es"], order: 'S', locale: locale_es, tvalue: "es", visible: true },
    { label: "French", value: "fr", newCode: "fr-FR", flag: ["fr"], order: 'F', locale: locale_fr, tvalue: "fr", visible: true },
    { label: "Italian", value: "it", newCode: "it-IT", flag: ["it"], order: 'I', locale: locale_it, tvalue: "it", visible: true },
    { label: "Dutch", value: "nl", newCode: "nl-NL", flag: ["nl"], order: 'D', locale: locale_nl, tvalue: "nl", visible: true },
    { label: "Russian", value: "ru", newCode: "ru-RU", flag: ["ru"], order: 'R', locale: locale_ru, tvalue: "ru", visible: true },
    { label: "German", value: "de", newCode: "de-DE", flag: ["de"], order: 'B1', locale: locale_de, tvalue: "de", visible: true },
    { label: "Swiss German", value: "de-CH", newCode: "de-CH", flag: ["de", "ch"], order: 'Sx1', locale: locale_de, tvalue: "de", visible: true },
    { label: "Swiss French", value: "fr-CH", newCode: "fr-CH", flag: ["fr", "ch"], order: 'Sx2', locale: locale_fr, tvalue: "fr", visible: true },
    { label: "Swiss Italian", value: "it-CH", newCode: "it-CH", flag: ["it", "ch"], order: 'Sx3', locale: locale_it, tvalue: "it", visible: true },
    { label: "Greek", value: "gr", flag: ["gr"], order: 'B1', locale: locale_el, tvalue: "el", visible: true },
];

type CommentTypes = "comments" | "ratings" | undefined;

const mapper = (state: ApplicationState) => ({
    params: state.params,
    contentLanguage: state.contentLanguage,
    focusTarget: state.podcastState.focusTarget,
    currentPost: state.podcastState.currentPost,
    currentCategoryStack: state.podcastState.currentCategoryStack,
    quality: state.podcastState.quality,
});
export interface PodcastAudioProps {
}

export interface PodcastAudioState {
    categories: CategoryDownloadDto[];
    comments?: UserCommentDownloadDto[];
    ratings?: UserCommentDownloadDto[];
    relativeDate: boolean;
    showNextPodcasts: boolean;
    loading: boolean;
    neighbours: PodcastPostDownloadDto[];
}

type PodcastActions =
    | { type: "batch", value: PodcastActions[] }
    | { type: "setCategories", value: CategoryDownloadDto[] }
    | { type: "setComments", value?: UserCommentDownloadDto[] }
    | { type: "setRatings", value?: UserCommentDownloadDto[] }
    | { type: "setRelativeDate", value: boolean }
    | { type: "setShowNextPodcasts", value: boolean }
    | { type: "setLoading", value: boolean }
    | { type: "setNeighbours", value: PodcastPostDownloadDto[] }
    ;

const initPodcastAudioState = (): PodcastAudioState => ({
    neighbours: [],
    categories: [],
    relativeDate: true,
    showNextPodcasts: false,
    loading: true,
});

const batch = (value: PodcastActions[]): PodcastActions => ({ type: "batch", value });
const setComments = (value?: UserCommentDownloadDto[]): PodcastActions => ({ type: "setComments", value });
const setRatings = (value?: UserCommentDownloadDto[]): PodcastActions => ({ type: "setRatings", value });
const setCategories = (value: CategoryDownloadDto[]): PodcastActions => ({ type: "setCategories", value });
const setRelativeDate = (value: boolean): PodcastActions => ({ type: "setRelativeDate", value });
const setShowNextPodcasts = (value: boolean): PodcastActions => ({ type: "setShowNextPodcasts", value });
const setLoading = (value: boolean): PodcastActions => ({ type: "setLoading", value });
const setNeighbours = (value: PodcastPostDownloadDto[]): PodcastActions => ({ type: "setNeighbours", value });

const reducer = (state: PodcastAudioState, action: PodcastActions): PodcastAudioState => {
    switch (action.type) {
        case "batch":
            return reducerBatchJob(state, action.value, reducer);
        case "setComments":
            return reducerSetIfChanged(state, "comments", action.value);
        case "setRatings":
            return reducerSetIfChanged(state, "ratings", action.value);
        case "setCategories":
            return reducerSetIfChanged(state, "categories", action.value);
        case "setRelativeDate":
            return reducerSetIfChanged(state, "relativeDate", action.value);
        case "setShowNextPodcasts":
            return reducerSetIfChanged(state, "showNextPodcasts", action.value);
        case "setLoading":
            return reducerSetIfChanged(state, "loading", action.value);
        case "setNeighbours":
            return reducerSetIfChanged(state, "neighbours", action.value);
    }
}

const themePartGetter = (t: Theme) => ({ width: t.content.width, emptyImageUrl: t.emptyImageUrl, emptyAvatarImageUrl: t.emptyAvatarImageUrl });

const PodcastAudio = (p: PodcastAudioProps) => {
    const [state, d] = React.useReducer(reducer, initPodcastAudioState());
    const { categories, loading, showNextPodcasts: snv, neighbours, relativeDate } = state;
    const { t } = useImgI18N("podcast");
    const [stopAnimations, setStopAnimations] = React.useState<boolean>(false);
    const { params, currentPost, currentCategoryStack, focusTarget, contentLanguage, quality } = useSelector(mapper, shallowCompare);
    const dispatch = useAppDispatch();
    const tubeTheme = useAppTheme<PodcastTheme>("podcastTheme");
    const { width, emptyImageUrl, emptyAvatarImageUrl } = useThemePart(themePartGetter);
    const isMobile = useBelowBreakpoint("tablet");
    const windowWidth = window.innerWidth;
    const cWidth = (width + 11) * 100 / 90;
    const actSidebarWidth = useThemePart(t => t.sidebars.default.maxWidth as number);
    const noWhiteSpace = cWidth < breakpoints.wide.width;
    const context = params?.context as string;

    const { scrollHelper, pr } = React.useMemo(() => {
        return {
            scrollHelper: new ScrollHelper(),
            pr: PostHubConnection.getInstance().subscribe((post) => {
                dispatch(Actions.setPodcastCurrentPost(post as PodcastPostDownloadDto));
            })
        }
    }, [dispatch]);
    const scrollTo = React.useMemo(() => (id: string) => scrollHelper.scrollTo(id), [scrollHelper]);

    const showNextPodcasts = context ? false : snv;

    React.useEffect(() => {
        const id = params?.id as string;
        const loadPost = async () => {
            const context = params?.context as string;

            if (!id) {
                dispatch(Actions.setPodcastCurrentPost(undefined));
                return;
            }
            d(setLoading(true));
            const post = context ?
                await ControllerHelper.singleCall({ id, context }, PostController.GetSinglePostWithContext) as PodcastPostDownloadDto :
                await ControllerHelper.singleCall({ id }, PostController.GetSinglePost) as PodcastPostDownloadDto;
            dispatch(Actions.setPodcastCurrentPost(post));
            if (pr)
                pr.signForIds([id]);
            d(setLoading(false));
        }

        scrollHelper.scrollTo("top");
        if (id !== currentPost?.id)
            loadPost();
        if (currentPost)
            dispatch(Actions.setScrollTo(scrollTo));

    }, [dispatch, pr, scrollHelper, params, scrollTo, currentPost]);

    React.useEffect(() => {
        if (currentCategoryStack === undefined)
            // TOFIX!!!
            updateCategoriesAndCurrentStack(dispatch, "TUBE", "porsche_tube", Actions.setPodcastCategories, Actions.setPodcastCurrentCategoryStack, params);

    }, [dispatch, currentCategoryStack, params]);

    React.useEffect(() => {
        const loadData = async () => {
            if (!currentPost)
                return;
            const id = currentPost.id;
            const [categories, comments, ratings, vs, neighbours] = await ControllerHelper
                .addCall({ grouptype: "PODCAST" }, ContentController.GetCategoriesForAllGroupsByGroupType)
                .addCall({ id }, PostInteraction.GetPostComments)
                .addCall({ id }, PostInteraction.GetPostRatings)
                .addCall({ postIds: [id] }, PostControllerTube.GetPostsViews)
                .addCall({ id: id, token: { token: "" } }, PostControllerTube.GetNeighbors)
                .execute<CategoryDownloadDto[], UserCommentsDownloadDto, UserRatingsDownloadDto, ViewStats[], ExtendedDataCollection<PodcastPostDownloadDto, string>>();

            //neighbours.sort(this.sortByDate);
            let views = 0;
            let viewed = false;
            if (vs && vs.length > 0 && vs[0].views) {
                views = vs[0].views;
                viewed = vs[0].viewed ?? false;
            }
            (currentPost as PodcastPostDownloadDto).views = views;
            (currentPost as PodcastPostDownloadDto).viewed = viewed;
            d(batch([
                setComments(comments.comments.reverse()),
                setRatings(ratings.comments.reverse()),
                setCategories(categories),
                setLoading(false),
                setNeighbours(neighbours.elements),
                setShowNextPodcasts(false),
            ]))
            const ev: EventUploadDto = {
                eventType: "PostViewed",
                group_id: currentPost.group_id,
                payload: { "PostId": currentPost.id, browser: getBrowser() }
            };
            EventController.PostEvent({ ev });
        };
        loadData();
    }, [currentPost])

    React.useEffect(() => {
        return () => {
            scrollHelper.scrollTo("top");
            dispatch(Actions.setPodcastCurrentPost(undefined));
            if (pr)
                pr.unsubscribe();
        }
    }, [dispatch, pr, scrollHelper]);

    const currentCatId = getCurrentCat(params);
    const currentCat = _.find(categories, c => c.id === currentCatId);
    const getDateFnsLng = ImgI18N.getInstance().languageLocal();

    const { translatedContent, onBookmark, creationDate, creationDateRelative, changedDate, changedDateRelative, avatar } = React.useMemo(() => {
        const translatedContent = getTranslatedForPodcast(currentPost?.content, contentLanguage, [quality, "HD", "SD", "4K", "", undefined]);
        if (quality !== translatedContent.contentAttribute && (translatedContent.contentAttribute === "HD" || translatedContent.contentAttribute === "SD" || translatedContent.contentAttribute === "4K"))
            dispatch(Actions.setPodcastQuality(translatedContent.contentAttribute));
        const onBookmark = (isBookmarked: boolean) => _.debounce(async () => {
            if (currentPost) {
                dispatch(Actions.setPodcastCurrentPost({ ...currentPost, isBookmarked }));
                const newPost = await ControllerHelper.singleCall({ postid: currentPost.id }, isBookmarked ? BookmarkController.PostBookmark : BookmarkController.RemoveBookmark, true);
                if (newPost)
                    dispatch(Actions.setPodcastCurrentPost(newPost as PodcastPostDownloadDto));
            }
        }, 500);
        const creationDate = formatDistanceToNow(currentPost ? new Date(currentPost.created as Date) : new Date(), { addSuffix: true, locale: getDateFnsLng });
        const creationDateRelative = formatRelative(currentPost ? new Date(currentPost.created as Date) : new Date(), new Date(), { locale: getDateFnsLng });
        const changedDate = formatDistanceToNow(currentPost ? new Date(currentPost.changed as Date) : new Date(), { addSuffix: true, locale: getDateFnsLng });
        const changedDateRelative = formatRelative(currentPost ? new Date(currentPost.changed as Date) : new Date(), new Date(), { locale: getDateFnsLng });
        const avatar = getLink(currentPost?.creator?.avatar?.links, "self")?.uri ?? emptyAvatarImageUrl;
        return {
            translatedContent,
            onBookmark,
            creationDate,
            creationDateRelative,
            changedDate,
            changedDateRelative,
            avatar,

        };
    }, [currentPost, contentLanguage, quality, dispatch, emptyAvatarImageUrl, getDateFnsLng]);

    const toggleRelativeDate = () => {
        d(setRelativeDate(!relativeDate));
    }

    const onShow = (commentType: CommentTypes) => () => {
        dispatch(Actions.setPodcastFocusTarget(commentType as string));
    };

    const finishedPodcast = React.useMemo(() => () => {
        if (currentPost) {
            const ev: EventUploadDto = {
                eventType: "Finished",
                group_id: currentPost.group_id,
                payload: {
                    "PostId": currentPost.id,
                }
            };
            ControllerHelper.singleCall({ ev, createRestriction: { checkGroupId: true, checkPayload: ["PostId"], checkUserId: true, maxAgeInSeconds: 10 } }, EventController.PostEventWithRestriction);
        }
    }, [currentPost])

    React.useEffect(() => {
        if (!translatedContent.useContent)
            finishedPodcast();
    }, [translatedContent, finishedPodcast])

    const toggleNextPodcasts = React.useCallback(() => {
        d(setShowNextPodcasts(!showNextPodcasts));
    }, [showNextPodcasts]);

    const openPodcast = (id: string) => () => {
        gotoNamedRoute("podcast_audio_details", tubeRoute(id));
        d(setShowNextPodcasts(false));
    }
    const goBack = () => {
        History.goBack();
    }

    const update = React.useMemo(() => ({ percentage: 90, callback: finishedPodcast }), [finishedPodcast]);

    if (!currentPost)
        return <Loader active size="small" infoText={t("loading")} color={podcastTheme.podcastAccent} light />;

    const vWidth = document.getElementById('podcastWidth')?.clientWidth ?? 400;
    const nextPodcastItems = Math.floor(vWidth / 220);
    const contentWidth = width ?? 400;
    const mobileView = contentWidth < 550;
    const categoryImage = currentCat ? getUriFromLinkByName(currentCat.media, "self") ?? emptyImageUrl : podcastTheme.homeImage;
    const showChangedDate = isChangedDateGreaterThanCreatedDateWithoutTime(currentPost.created, currentPost.changed);
    const dlUrl = getMediaLink(translatedContent, c => c.content) ?? "";
    const dlTitle = getTranslated(currentPost.headlines, contentLanguage).text;
    const viewed = currentPost.views >= 1000 ? (Math.round(currentPost.views / 100) / 10) + "K" : currentPost.views;

    return (
        <>
            <div style={{ height: 0 }} ref={scrollHelper.getRef("top")} />
            <T.PodcastHomeMainContainer>
                {!isMobile && <T.PodcastBGimage width={width * 0.05 + 220 + "px"} right={noWhiteSpace ? "0px" : `calc((${windowWidth}px - ${cWidth}px - ${actSidebarWidth} + 12px) / 2)`} />}
                <PodcastHeader
                    categoryImage={currentCat ? getUriFromLinkByName(currentCat.media, "self") ?? categoryImage : categoryImage}
                    categoryName={getTranslated(currentCat?.names, contentLanguage)?.text ?? ""}
                    lightMode={!tubeTheme.darkMode}
                />
                {!mobileView &&
                    <T.PodcastBackButtonContainer>
                        <Button
                            kind="transparentButton"
                            icon="home"
                            floated="right"
                            onClick={gotoNamedRouteMethod("podcast")}
                            iconColor={podcastTheme.podcastAccent}
                        />
                        <Button
                            kind="transparentButton"
                            icon="chevron left"
                            onClick={goBack}
                            floated="right"
                            iconColor={podcastTheme.podcastAccent}
                        />
                    </T.PodcastBackButtonContainer>
                }
                <div style={{ width: mobileView ? "calc(100% - 0px)" : "calc(100% - 80px)" }}>
                    <Breadcrumbs
                        darkMode={tubeTheme.darkMode}
                        root="podcast"
                        asSearchResult={false}
                        categoryRouteName="podcast_cat"
                        currentCategoryStack={currentCategoryStack}
                        post={currentPost}
                        accent={podcastTheme.podcastAccent}
                    />
                </div>
                {loading &&
                    <LoaderInline
                        infoText="loading"
                        animationColor={podcastTheme.podcastAccent}
                        backgroundColor={"rgba(0,0,0,0.1)"}
                        textColor={"@lightGrey"}
                        active
                    />}
                <Divider color="@darkGrey" />


                {mobileView &&
                    <div style={{ fontSize: "1.1rem", padding: "0 10px" }}>
                        <SafeHTML html={getTranslated(currentPost.headlines, contentLanguage).text} allowedTags={[]} allowedAttributes={{}} />
                    </div>
                }
                {!loading &&
                    <T.PCGrid mobileView={mobileView} contentWidth={contentWidth}>
                        <T.PCGridLeftHeaderBar id="podcastWidth" >
                            {!mobileView &&
                                <SafeHTML html={getTranslated(currentPost.headlines, contentLanguage).text} allowedTags={[]} allowedAttributes={{}} />
                            }
                            <div style={{ position: "absolute", top: 0, right: 0, background: "rgba(0,0,0,0.1)" }}>
                                <Button
                                    kind="halfTransparentButton"
                                    icon={currentPost.isBookmarked ? "bookmark" : "bookmark outline"}
                                    floated="right"
                                    onClick={onBookmark(!currentPost.isBookmarked)}
                                    tooltip={{ tooltipText: currentPost.isBookmarked ? t("unbookmark this podcast") : t("bookmark this podcast") }}
                                    iconColor={currentPost.isBookmarked ? podcastTheme.podcastAccent : 'color'}
                                />

                                <DownloadButton
                                    downloadUrl={dlUrl}
                                    fileName={dlTitle}
                                    kind="halfTransparentButton"
                                    floated="right"
                                    iconColor={currentPost.isBookmarked ? podcastTheme.podcastAccent : 'color'}
                                    tooltipText={t("download podcast")}
                                />
                                <div style={{ float: "right", display: "grid", gridTemplateColumns: "max-content max-content", columnGap: 10, fontSize: "0.8rem", paddingRight: 10, letterSpacing: 0, color: "rgba(255,255,255,0.6)" }}>
                                    <div>{t("show animations")}</div>
                                    <div style={{ opacity: stopAnimations ? 0.5 : 1 }}><SwitchButton isActive={() => setStopAnimations(!stopAnimations)} initialState={!stopAnimations} activeColor={podcastTheme.podcastAccent} /></div>
                                </div>
                            </div>
                        </T.PCGridLeftHeaderBar>
                        <T.PCGridRight mobileView={mobileView}>
                            {currentPost?.id ?
                                <PodcastAudioNeighbours id={currentPost.id} />
                                :
                                <InfoBox
                                    darkMode
                                    content="-"
                                    type="default"
                                    icon="info"
                                />
                            }

                        </T.PCGridRight>
                        <T.PCGridLeftVideo >
                            <div style={{ height: 0, marginTop: -10 }} ref={scrollHelper.getRef("video")} />
                            <PodcastContent
                                src={getMediaLink(translatedContent, c => c.content) ?? ""}
                                namespace="podcast"
                                onEnded={toggleNextPodcasts}
                                onTimeUpdate={update}
                                currentPost={currentPost}
                                stopAnimations={stopAnimations}
                                mobileView={mobileView}
                            />

                            {showNextPodcasts && neighbours.length > 0 &&
                                <T.PCGridLeftVideoNext>
                                    {_.map(neighbours, (t, idx) => {
                                        if (idx >= nextPodcastItems)
                                            return false;
                                        const uri = getThumbnailOrMediaLinkForContent(t.content, contentLanguage) ?? "";

                                        return (
                                            <T.PCNextItemContainer key={t.id} onClick={openPodcast(t.id)} >
                                                <T.PCNextItem imageUrl={uri} />
                                                <T.PCNextItemTitle>
                                                    <SafeHTML html={getTranslated(t.headlines, contentLanguage)?.text ?? ""} />
                                                </T.PCNextItemTitle>
                                            </T.PCNextItemContainer>);
                                    })}
                                    <T.PCNextItemCloseButton onClick={toggleNextPodcasts}>
                                        <Icon name="times" />
                                    </T.PCNextItemCloseButton>
                                </T.PCGridLeftVideoNext>
                            }
                        </T.PCGridLeftVideo>


                        <T.PCGridLeftUser mobileView={mobileView} style={{ marginTop: 20, padding: mobileView ? "0 10px" : undefined }}>

                            <div style={{ height: 0, marginTop: -10 }} ref={scrollHelper.getRef("info")} />
                            <T.PCGridLeftUserAvatar>
                                <Image src={avatar} rounded width="40px" />
                            </T.PCGridLeftUserAvatar>
                            <T.PCGridLeftUserName>
                                <T.PCGridLeftUserNameName>
                                    {getTranslatedUserName(currentPost.creator)}
                                </T.PCGridLeftUserNameName>
                                <T.PCGridLeftUserNameDate onClick={toggleRelativeDate}>
                                    {relativeDate ? creationDateRelative : creationDate}
                                    {showChangedDate &&
                                        <T.PCGridLeftUserChangedBy>
                                            {t("changed by {{name}} on {{date}}", { name: getTranslatedUserName(currentPost.changedBy), date: relativeDate ? changedDateRelative : changedDate })}
                                        </T.PCGridLeftUserChangedBy>
                                    }
                                </T.PCGridLeftUserNameDate>
                            </T.PCGridLeftUserName>
                            <T.PCGridLeftUserRating onClick={onShow("ratings")} style={{ cursor: "pointer", paddingTop: 2 }}>
                                <Tooltip tooltipText={currentPost.ratingCount === 0 ? t("this podcast has not been rated yet") : t("this podcast has already been rated {{count}} times", { count: currentPost.ratingCount })} position='top'>
                                    <RatingView maxStars={5} rating={currentPost.rating} size={20} filledOut filledColor={podcastTheme.podcastAccent} />
                                </Tooltip>
                            </T.PCGridLeftUserRating>

                            {!mobileView &&
                                <T.PCGridLeftUserButtons>
                                    <Button
                                        icon="mic on"
                                        kind={tubeTheme.darkMode ? "transparentButtonDark" : "transparentButton"}
                                        tooltip={{ tooltipText: t("this podcast has already been listened to {{count}} times", { count: currentPost.views }), position: "top" }}
                                        floated="right"
                                        disabled
                                        index={viewed}
                                        cursor="help"
                                        iconColor='#fff'
                                        indexColor={podcastTheme.podcastAccent}
                                    />
                                    <Button
                                        icon="comment"
                                        kind={tubeTheme.darkMode ? "transparentButtonDark" : "transparentButton"}
                                        tooltip={{ tooltipText: currentPost.commentCount === 0 ? t("this podcast has not been commented yet") : t("{{count}} users have already commented on this podcast", { count: currentPost.commentCount }), position: "top" }}
                                        floated="right"
                                        onClick={onShow("comments")}
                                    />
                                </T.PCGridLeftUserButtons>
                            }
                        </T.PCGridLeftUser>

                        <T.PCGridLeftDescription mobileView={mobileView}>
                            {!mobileView &&
                                <T.PCGridLeftDescriptionTitle>{t("description:")}</T.PCGridLeftDescriptionTitle>
                            }
                            <T.PCGridLeftDescriptionDescription mobileView={mobileView}>
                                <SafeHTML html={getTranslated(currentPost.descriptions, contentLanguage)?.text ?? ""} />
                            </T.PCGridLeftDescriptionDescription>
                            <T.PCGridLeftDescriptionButton mobileView={mobileView}>

                            </T.PCGridLeftDescriptionButton>
                            {currentPost &&
                                <PodcastAudioInfo mobileView={mobileView} post={currentPost} />
                            }
                        </T.PCGridLeftDescription>

                        <T.PCGridLeftComments style={{ marginTop: mobileView ? 0 : -10 }} mobileView={mobileView} >
                            <div style={{ height: 0, marginTop: -10 }} ref={scrollHelper.getRef("comments")} />
                            <T.PCGridLeftCommentsCommentsContainer>
                                <T.PCGridLeftButtons active={(focusTarget === "comments" || focusTarget === undefined)} color={podcastTheme.podcastAccent}>
                                    <Button
                                        icon="comment"
                                        content={currentPost.commentCount + " " + (currentPost.commentCount === 1 ? t("comment") : t("comments"))}
                                        kind='transparentButton'
                                        onClick={onShow("comments")}
                                        floated='left'
                                    />
                                </T.PCGridLeftButtons>
                                <T.PCGridLeftButtons active={focusTarget === "ratings"} color={podcastTheme.podcastAccent}>
                                    <Button
                                        icon="star half"
                                        content={currentPost.ratingCount + " " + (currentPost.ratingCount === 1 ? t("rating") : t("ratings"))}
                                        kind='transparentButton'
                                        onClick={onShow("ratings")}
                                        floated='left'
                                    />
                                </T.PCGridLeftButtons>
                            </T.PCGridLeftCommentsCommentsContainer>
                            <T.PCGridLeftCommentsCommentsRatings ref={scrollHelper.getRef("ratings")}>
                                <div style={{ display: (focusTarget === "comments" || focusTarget === undefined) ? "block" : "none" }}>
                                    <CommentsRatingsView
                                        post={currentPost}
                                        accent={podcastTheme.podcastAccent}
                                        noLeftBorder
                                        getIncoming={PostInteraction.GetPostComments}
                                        addCommentRating={PostInteraction.AddPostComment}
                                        onDeleteComment={PostInteraction.DeletePostComment}
                                        onSubmitComment={PostInteraction.UpdatePostComment}
                                        darkMode={podcastTheme.darkMode}
                                        inline={!mobileView}
                                        filledColor={podcastTheme.podcastAccent}
                                    />
                                </div>
                                <div style={{ display: focusTarget === "ratings" ? "block" : "none" }}>
                                    <CommentsRatingsView
                                        accent={podcastTheme.podcastAccent}
                                        post={currentPost}
                                        noLeftBorder
                                        asRating
                                        getIncoming={PostInteraction.GetPostRatings}
                                        addCommentRating={PostInteraction.AddPostRating}
                                        darkMode={podcastTheme.darkMode}
                                        inline={!mobileView}
                                        filledColor={podcastTheme.podcastAccent}
                                        filledOut
                                    />
                                </div>
                            </T.PCGridLeftCommentsCommentsRatings>
                        </T.PCGridLeftComments>
                    </T.PCGrid>
                }
            </T.PodcastHomeMainContainer>
        </>
    );
}
export default PodcastAudio; 