import { BaseClient } from "collaboration-service";
import { IconBaseProps } from "imaginarity-react-ui";
import { PluginActions, PluginState } from "./PluginStateHandling";
import { AnyAction, Dispatch } from "redux";
import { ImgEventHandler } from "./ImgEventHandler";
import { TypeRendererRegistry } from "./TypeRendererRegistry";
import { ApplicationComponentsFactory } from "./ApplicationComponentsFactory";

type ActionsType = typeof PluginActions;

export interface StateAndDispatch {
    state: PluginState;
    dispatch: Dispatch<AnyAction>;
}


export interface PluginParamsBase {
    icons?: { [key: string]: React.MemoExoticComponent<(props: IconBaseProps) => JSX.Element> | ((props: IconBaseProps) => JSX.Element) };
    componentsFactory?: ApplicationComponentsFactory;
}

export interface PluginConfiguration extends PluginParamsBase {
    store: ApplicationStore;
}

export interface PluginParams extends PluginParamsBase, StateAndDispatch {

}

export interface ApplicationStore {
    getState(): PluginState;
    dispatch: Dispatch<AnyAction>
    subscribe: (callback: () => void) => void;
}

export class PluginRegistry {
    private static _instance: PluginRegistry;
    private _store: ApplicationStore = {
        getState: () => ({} as PluginState),
        dispatch: <T extends {} = any>(p: T) => { return p },
        subscribe: (callback: () => void) => { }
    };
    private readonly _stateEventHandler: ImgEventHandler<StateAndDispatch> = new ImgEventHandler<StateAndDispatch>();
    private readonly _loadingPlugins = new ImgEventHandler<{ count: number, current: number, name: string }>();
    private readonly _loadedPlugin = new ImgEventHandler<string>();
    private readonly _typeRendererRegistry = new TypeRendererRegistry();

    private _backEndUrl: string = "";
    private _baseClient?: BaseClient;
    private _pluginsToLoad: Array<{ name: string, local?: boolean }> = [];
    private _actions?: ActionsType;

    private constructor() {
        (window as any).platformPluginRegistry = this;
    }

    public get loadedPlugin() { return this._loadedPlugin.expose(); }
    public get loadingPlugins() { return this._loadingPlugins.expose(); }
    public get stateEventHandler() { return this._stateEventHandler.expose(); }

    public static getInstance = (): PluginRegistry => {
        if (PluginRegistry._instance === undefined)
            PluginRegistry._instance = new PluginRegistry();
        return PluginRegistry._instance;
    }

    public set backEndUrl(url: string) {
        this._backEndUrl = url;
    }

    public set baseClient(client: BaseClient | undefined) {
        this._baseClient = client;
    }

    public get baseClient(): BaseClient | undefined {
        return this._baseClient;
    }

    public addPluginToLoad = (name: string, local?: boolean) => {
        this._pluginsToLoad.push({ name, local });
    }
    public loadPlugins = (params: PluginConfiguration): Promise<void> => {
        const { store, ...restParams } = params;
        this._store = store;
        this._actions = PluginActions;
        this._store.subscribe(() => this._stateEventHandler.trigger({ state: this._store.getState(), dispatch: this._store.dispatch }));

        const toLoad = this._pluginsToLoad.slice(0);
        this._pluginsToLoad = [];
        const count = toLoad.length;
        let loaded = 0;
        const promises: Array<Promise<void>> = [];
        this.loadingPlugins.trigger({ count, current: 0, name: "" });
        const pp: PluginParams = {
            ...restParams,
            state: store.getState(),
            dispatch: store.dispatch
        };
        toLoad.forEach(p => {
            promises.push(new Promise<void>((res, rej) => {
                const req = new XMLHttpRequest();
                if (p.local && process.env.NODE_ENV !== "production") {
                    console.log("loading plugin from public: " + p.name);
                    req.open("GET", "./plugins/" + p.name + ".plugin.js");
                }
                else {
                    req.open("GET", this._backEndUrl + "/plugins/" + p.name + ".plugin.js");
                }
                req.onload = () => {
                    //console.log(req.status);
                    if (req.status === 200) {
                        //                        console.log(req.responseText);
                        this.loadedPlugin.trigger(p.name);
                        // eslint-disable-next-line
                        eval(req.responseText).init(pp);
                        this._loadingPlugins.trigger({ count, current: ++loaded, name: p.name });
                        res();
                    }
                };
                req.send();
            }));
        });
        return new Promise<void>((res, rej) => Promise.all(promises).then(() => {
            if (this._store && this._stateEventHandler)
                this._stateEventHandler?.trigger({ state: this._store.getState(), dispatch: this._store.dispatch });
            res();
        },
            rej));
    }
    public pluginsToLoadCount = () => {
        return this._pluginsToLoad.length;
    }

    public get store(): ApplicationStore {
        return this._store;
    }

    public get actions() {
        return this._actions;
    }

    public get typeRendererRegistry() {
        return this._typeRendererRegistry;
    }
}