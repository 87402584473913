import { appendPx, belowBreakpoint, belowBreakpointOrEqual, ButtonSizes, css, getButtonSizes, getSidebarMaxWidth, getSidebarMinWidth, styled, Theme } from "imaginarity-react-ui";

export class MasterLayoutContentSC {
    static selectByBreakpoint = <T extends any>(theme: Theme, mobile: T, tablet: T, desktop: T): T => {
        if (belowBreakpointOrEqual({ theme }, "largeMobile"))
            return mobile;
        if (belowBreakpointOrEqual({ theme }, "tablet"))
            return tablet;
        return desktop;
    }

    static buttonHeight = (theme: Theme, size?: ButtonSizes) => {
        return appendPx(getButtonSizes(theme, "large").buttonHeight);
    }
    static AreaRows = css`
    ${p => MasterLayoutContentSC.selectByBreakpoint(p.theme,
        css`
            grid-template-rows: ${p => belowBreakpoint(p, "tablet") ? this.buttonHeight(p.theme, "large") : appendPx(p.theme.headerHeight)} calc(100% - ${props => MasterLayoutContentSC.buttonHeight(props.theme)} - ${props => appendPx(props.theme.mobileGap)} - ${p => belowBreakpoint(p, "tablet") ? this.buttonHeight(p.theme, "large") : appendPx(p.theme.headerHeight)}) ${props => MasterLayoutContentSC.buttonHeight(props.theme)} ${props => appendPx(props.theme.mobileGap)};
            `,
        css`
            grid-template-rows: ${p => belowBreakpoint(p, "tablet") ? this.buttonHeight(p.theme, "large") : appendPx(p.theme.headerHeight)} calc(100% - ${props => MasterLayoutContentSC.buttonHeight(props.theme)} - ${p => belowBreakpoint(p, "tablet") ? this.buttonHeight(p.theme, "large") : appendPx(p.theme.headerHeight)}) ${props => MasterLayoutContentSC.buttonHeight(props.theme)};
        `,
        css`
            grid-template-rows: ${p => belowBreakpoint(p, "tablet") ? this.buttonHeight(p.theme, "large") : appendPx(p.theme.headerHeight)} calc(100% - ${p => belowBreakpoint(p, "tablet") ? this.buttonHeight(p.theme, "large") : appendPx(p.theme.headerHeight)}) 0px;
        `)}
    `;
    static MainGrid = styled.div`
    display: grid;
    display: -ms-grid;
    height: 100%;    
    ${p => MasterLayoutContentSC.selectByBreakpoint(p.theme,
        css`
        grid-template-columns: 0px 1fr 0px;
        `,
        css`
        grid-template-columns: 4fr 2fr 4fr; //minmax(${p => getSidebarMinWidth(p)}, ${p => getSidebarMaxWidth(p)})  minmax(500px, 1fr) 0px;
        `,
        css`
        grid-template-columns: minmax(${p => getSidebarMinWidth(p)}, ${p => getSidebarMaxWidth(p)})  minmax(600px, 1fr) minmax(${p => getSidebarMinWidth(p)}, ${p => getSidebarMaxWidth(p)});
        `)}
    ${p => MasterLayoutContentSC.AreaRows};
`;
}