import * as React from 'react';
import { CMSController, ControllerHelper, FileEntryDownloadDto, MediaWithoutThumbnailsDownloadDto, PostDownloadDto, ProjectGroupDownloadDto } from "collaboration-service";
import * as _ from "lodash";
import { CMSState, useAppSelector } from "services/ApplicationState/ApplicationState";
import { getCMSContent } from 'services/Helpers/TranslationHelpers';
import { getLinkByName, useThemePart } from 'imaginarity-react-ui';

export const cmsFindIndexPathAndFileInCurrentFiles = (id: string, currentFiles?: CMSState['currentFiles']) => {
    if (!currentFiles)
        return undefined;
    const oKeys = Object.keys(currentFiles);
    for (let k = 0; k < oKeys.length; k++) {
        const key = oKeys[k];
        const cf = currentFiles[key];
        const i = _.findIndex(cf, f => f.id === id);
        if (i >= 0) {
            return { idx: i, key, file: cf[i] }
        }
    }
    return undefined;
}

export const usePreviewImageForFileEntry = (fe: PostDownloadDto, language: string) => {
    const [project, setProject] = React.useState<ProjectGroupDownloadDto>();
    const emptyImageUrl = useThemePart(t => t.emptyImageUrl);
    const projects = useAppSelector(s => s.cmsState.projects);
    const groupId = fe?.group_id;
    const type = fe?.type ?? "";

    React.useEffect(() => {
        const l = async () => {
            const proj = _.find(projects?.elements, p => p.id === groupId);
            if (proj)
                setProject(proj);
            else {
                const res = await ControllerHelper.singleCall({ ids: [groupId] }, CMSController.GetProjectGroupsByIds) as ProjectGroupDownloadDto[];
                setProject((res ?? [])[0]);
            }
        }
        if (type === "FileEntry" && groupId)
            l();
    }, [groupId, projects, type]);

    const preview = React.useMemo(() => {

        var preview: string | undefined = undefined;
        if (fe.type === "FileEntry") {
            var linkFrom: MediaWithoutThumbnailsDownloadDto | undefined = fe.media;
            if (!linkFrom) {
                const content = getCMSContent({ fileEntry: fe as FileEntryDownloadDto, language, filePosts: {} });
                linkFrom = (content?.media?.thumbnails ?? [])[0];
                const type = content?.media?.type ?? "";
                if (type.startsWith("image"))
                    linkFrom = content.media;
            }

            if (!linkFrom) {
                linkFrom = project?.media;
            }
            preview = getLinkByName(linkFrom, "self")?.uri;
        };
        return preview;
    }, [language, fe, project]);
    return preview ?? emptyImageUrl;
}