import { MarkInPictureQuestionDownloadDto, MarkInPictureUserAnswer } from 'collaboration-service';
import { fadeColor, getUriFromLinkByName, ImgThemeConsumer, Media, URLMedia } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { QuizSC as T } from '../QuizSC';
import { QuestionImplementationProps } from './Question';

const elementResizeDetectorMaker = require("element-resize-detector");
const erdUltraFast = elementResizeDetectorMaker({
    strategy: "scroll" //<- For ultra performance.
});
export interface ImageWithOverlayProps extends QuestionImplementationProps {
    answer?: (answer: MarkInPictureUserAnswer) => void;
}
export interface ImageWithOverlayState {
    userAnswer: MarkInPictureUserAnswer;
    width: number;
    height: number;
}

export default class ImageWithOverlay extends React.Component<ImageWithOverlayProps, ImageWithOverlayState> {
    constructor(props: ImageWithOverlayProps) {
        super(props);
        const ua = ((props.answers ?? [])[props.questionNumber ?? 0] as any)?.userAnswer as MarkInPictureUserAnswer;
        this.state = {
            width: 0,
            height: 0,
            userAnswer: {
                id: "",
                coordinates: ua?.coordinates ?? [],
            },
        }
    }

    componentDidUpdate(prevProps: ImageWithOverlayProps) {
        if (this.props.question.id !== prevProps.question.id) {
            this.setState({
                width: 0,
                height: 0,
                userAnswer: {
                    id: "",
                    coordinates: [],
                },
            });
        }
    }

    public render() {
        const { media, yResolution, xResolution, paddingTop, paddingRight, paddingBottom, paddingLeft, correctAnswers, markerColor } = this.props.question as MarkInPictureQuestionDownloadDto;

        const { displayCorrect, loaded } = this.props;
        const urlMedia: URLMedia | undefined = (loaded && loaded.length > 0) ?
            {
                url: loaded[0]?.url ?? "",
                type: loaded[0]?.media?.mediaType ?? ""
            }
            : undefined;

        const { width, height, userAnswer } = this.state;
        const coordinates = userAnswer.coordinates;
        // console.log('correctAnswers', correctAnswers);
        // console.log('marked', marked);

        // console.log('media', media.width, media.height);

        const h = height * (100 - paddingTop - paddingBottom) * 0.01;
        const w = width * (100 - paddingLeft - paddingRight) * 0.01;
        const rowHeight = h / yResolution;
        const cellWidth = w / xResolution;
        // console.log("rowHeight", rowHeight);
        // console.log("cellWidth", cellWidth);
        return (
            <ImgThemeConsumer>
                {(theme) => {
                    return (
                        <T.IMOOverlay
                            imageWidth={media.width ?? 16}
                            imageHeight={media.height ?? 9}
                            ref={this.setMediaContainer}
                        >
                            {urlMedia ?
                                <Media
                                    media={urlMedia}
                                    width="100%"
                                />
                                :
                                <T.IMOImg
                                    src={getUriFromLinkByName(media, "self")}
                                    width="100%"
                                />
                            }
                            {height !== 0 && width !== 0 &&
                                _.times(yResolution, y => {
                                    return _.times(xResolution, x => {

                                        let correctAnswer = undefined;
                                        if (displayCorrect)
                                            correctAnswer = _.findIndex(correctAnswers, v => v.x === x && v.y === y) >= 0;
                                        const found = _.findIndex(coordinates, v => v.x === x && v.y === y) >= 0;
                                        const topF = y > 0 ? _.findIndex(coordinates, v => v.x === x && v.y === y - 1) >= 0 : false;
                                        const leftF = x > 0 ? _.findIndex(coordinates, v => v.x === x - 1 && v.y === y) >= 0 : false;
                                        const rightF = x < xResolution - 1 ? _.findIndex(coordinates, v => v.x === x + 1 && v.y === y) >= 0 : false;
                                        const bottomF = y < yResolution - 1 ? _.findIndex(coordinates, v => v.x === x && v.y === y + 1) >= 0 : false;

                                        const click = () => {
                                            if (!this.props.timeIsOver && displayCorrect !== true)
                                                this.toggleMarked(x, y);
                                        };

                                        return (
                                            <T.IMOCell
                                                displayCorrect={displayCorrect}
                                                gridOpacity={0}
                                                top={rowHeight * y + paddingTop * height * 0.01}
                                                left={cellWidth * x + paddingLeft * width * 0.01}
                                                markerColor={markerColor ?? "#FFF"}
                                                height={rowHeight}
                                                width={cellWidth}
                                                key={y + "" + x}
                                                onClick={click}
                                            >
                                                {(found || correctAnswer) &&
                                                    <div style={{
                                                        position: "relative",
                                                        width: cellWidth - 2,
                                                        height: rowHeight - 2,
                                                        background: correctAnswer ? fadeColor({ theme }, theme.colors.accentGreen, 10) : fadeColor({ theme }, markerColor ?? "#FFF", 10)
                                                    }}
                                                    >
                                                        <T.IMODashing className={topF ? " off" : ""} correctAnswer={correctAnswer} markerColor={markerColor ?? "#FFF"} />
                                                        <T.IMODashing className={rightF ? " off" : ""} correctAnswer={correctAnswer} markerColor={markerColor ?? "#FFF"} />
                                                        <T.IMODashing className={bottomF ? " off" : ""} correctAnswer={correctAnswer} markerColor={markerColor ?? "#FFF"} />
                                                        <T.IMODashing className={leftF ? " off" : ""} correctAnswer={correctAnswer} markerColor={markerColor ?? "#FFF"} />
                                                    </div>
                                                }
                                            </T.IMOCell>
                                        );
                                    });
                                })}
                        </T.IMOOverlay>
                    );
                }}
            </ImgThemeConsumer>
        );
    }

    private setDim = _.debounce((width: number, height: number) => this.setState({ width, height }), 500);

    private setMediaContainer = (ele?: HTMLDivElement | null) => {
        if (ele) {
            erdUltraFast.listenTo({}, ele, (e: HTMLDivElement) => {
                // console.log('e', e);

                // console.log('w', e?.offsetWidth ?? 0);
                // console.log('h', e?.offsetHeight ?? 0);
                this.setDim(e?.offsetWidth ?? 0, e?.offsetHeight ?? 0);
            });
        }
    };
    private toggleMarked = (x: number, y: number) => {
        const userAnswer = _.clone(this.state.userAnswer);
        const coordinates = _.clone(userAnswer.coordinates) ?? [];
        const idx = _.findIndex(coordinates, v => v.x === x && v.y === y);
        if (idx >= 0)
            coordinates.splice(idx, 1);
        else
            coordinates.push({ x, y });

        userAnswer.coordinates = coordinates;
        if (this.props.answer)
            this.props.answer(userAnswer);
        this.setState({ userAnswer });
    };
}
