import * as React from "react";
import * as _ from "lodash";
import { useAppSelector } from "./ApplicationState/ApplicationState";
import { UseVisibleElementsServiceProps, useVisibleElementsService } from "./signalR/useVisibleElementsHubService";

export interface useVisibleTableProps extends UseVisibleElementsServiceProps {
}

const useVisibleTable = <T extends HTMLDivElement = HTMLDivElement>(p: useVisibleTableProps) => {
    const hasFocus = useAppSelector(s => s.hasFocus);
    const { pause, play, setVisible, start, stop } = useVisibleElementsService(p)
    const elements = React.useRef<Array<{ name: string, ids: string[], ele: T | null, threshold?: number | number[], observer: IntersectionObserver | undefined }>>([]);

    React.useEffect(() => {
        //console.log("useVisibleTable.tsx::15 => start it!!!!!!");

        start();
        return () => {
            //  console.log("useVisibleTable.tsx::17 => stop it!!");

            stop();
        };
    }, [start, stop]);

    React.useEffect(() => {
        if (hasFocus)
            play();
        else
            pause();
    }, [hasFocus, play, pause]);

    const setIntersecting = React.useCallback((name: string, ids: string[], value: boolean) => {
        setVisible(name, ids, value);
    }, [setVisible]);

    const addElementRef = React.useCallback((name: string, ids?: string[], threshold?: number | number[]) => (r: T | null) => {
        const idx = _.findIndex(elements.current, nn => nn.name === name && _.isEqual(nn.ids, ids));
        if (idx >= 0) {
            elements.current[idx].ele = r;
            if (r) {
                const obs = new IntersectionObserver(([entry]) => {
                    // if ((data as any)["sectionId"] === "image") {
                    //     console.log("useVisibleTable.tsx::65 => entry", entry);
                    // }
                    setIntersecting(name, ids ?? [], entry.isIntersecting);
                }, { threshold });
                elements.current[idx].observer = obs;
                obs.observe(r);
            }
            else {
                const obs = elements.current[idx].observer;
                if (obs) {
                    obs.disconnect();
                    elements.current[idx].observer = undefined;
                }
            }
        }
        else {
            if (r) {
                const obs = new IntersectionObserver(([entry]) => {
                    // if ((data as any)["sectionId"] === "image") {
                    //     console.log("useVisibleTable.tsx::65 => entry", entry);
                    // }
                    setIntersecting(name, ids ?? [], entry.isIntersecting);
                }, { threshold });

                obs.observe(r);
                elements.current.push({ ele: r, threshold, name, ids: ids ?? [], observer: obs });
            }
            else
                elements.current.push({ ele: r, threshold, name, ids: ids ?? [], observer: undefined });

        }
    }, [setIntersecting]);

    React.useEffect(() => {
        return () => {
            // das ist Absicht!!
            // eslint-disable-next-line
            _.forEach(elements.current, e => {
                const obs = e.observer;
                if (obs) {
                    obs.disconnect();
                    e.observer = undefined;
                }
            })
        }
    }, []);

    return {
        addElementRef,
        setElementVisibility: setIntersecting,
    }
}

export default useVisibleTable;