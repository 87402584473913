import { Theme, getColor, lightenColor, styled } from 'imaginarity-react-ui';
import * as React from 'react';
import { ThemeContext } from 'styled-components';


const OuterContainer = styled.div<{ floated?: "left" | "right", size: "default" | "large" }>`
    width: ${p => p.size === "default" ? 31 : 46}px;
    height: 100%;
    ${p => p.floated === undefined && `display: inline-block;`}
    float: ${p => p.floated !== undefined ? p.floated : "none"};
    position: relative;
    z-index: 0;
`;
const Inner = styled.div<{ size: "default" | "large", bgColor: string, borderColor: string, disabled?: boolean }>`
    width: ${p => p.size === "default" ? 31 : 46}px;
    height: ${p => p.size === "default" ? 18 : 26}px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: center center;
    background: ${p => p.bgColor};
    border: 1px solid  ${p => p.borderColor};
    transform: translate(-50%, -50%);
    border-radius: ${p => p.size === "default" ? 9 : 13}px;
    z-index: 1;
    cursor: ${p => p.disabled ? "not-allowed" : "pointer"};
`;
const Switch = styled.div<{ active: boolean, size: "default" | "large", dotColor: string, disabled?: boolean }>`
    width: ${p => p.size === "default" ? 13 : 20}px;
    height: ${p => p.size === "default" ? 13 : 20}px;
    position: absolute;
    left: 0;
    top: 50%;
    transform-origin: center center;
    transform: translate(${p => p.active ? p.size === "default" ? 15 : 22 : p.size === "default" ? 3 : 4}px, -50%);
    background: ${p => p.dotColor};
    border-radius: 50%;
    z-index: 2;
    transition: all 0.2s ease-out;
    cursor: ${p => p.disabled ? "not-allowed" : "pointer"};
`;

interface SwitchButtonProps {
    isActive: (res: boolean) => void;
    initialState?: boolean;
    floated?: "left" | "right";
    disabled?: boolean;
    activeColor?: string;
    size?: "default" | "large";
}

interface Colors {
    innerBackgroundColor: string;
    innerBorderColor: string;
    switchDotColor: string;
}

function isTextColorDarker(textColor: string, backgroundColor: string): boolean {
    // Entfernen Sie das #-Zeichen aus den hexadezimalen Farbcodes
    const cleanTextColor = textColor.replace("#", "");
    const cleanBackgroundColor = backgroundColor.replace("#", "");

    // Konvertieren Sie die hexadezimalen Farbcodes in dezimale Werte
    const textColorValue = parseInt(cleanTextColor, 16);
    const backgroundColorValue = parseInt(cleanBackgroundColor, 16);

    // Berechnen Sie die Helligkeit der Farben
    const textColorBrightness = (textColorValue >> 16) * 0.299 + ((textColorValue >> 8) & 0xFF) * 0.587 + (textColorValue & 0xFF) * 0.114;
    const backgroundColorBrightness = (backgroundColorValue >> 16) * 0.299 + ((backgroundColorValue >> 8) & 0xFF) * 0.587 + (backgroundColorValue & 0xFF) * 0.114;

    // Vergleichen Sie die Helligkeit und geben Sie das Ergebnis zurück
    return textColorBrightness < backgroundColorBrightness;
}


const SwitchButton = (p: SwitchButtonProps) => {
    const { isActive, initialState, floated, disabled, activeColor, size } = p;
    const [active, setActive] = React.useState<boolean>(false);
    const theme = React.useContext<Theme>(ThemeContext);

    React.useEffect(() => {
        if (initialState)
            setActive(initialState);
        else {
            setActive(false);
        }
    }, [initialState]);

    const toggleActive = React.useMemo(() => () => {
        setActive(!active);
        isActive(active);
    }, [active, isActive]);


    const textColor = getColor({ theme }, "color");
    const backgroundColor = getColor({ theme }, "backgroundColor");
    const textIsDarker = isTextColorDarker(textColor, backgroundColor);


    function getColors(disabled: boolean, active: boolean, activeColor?: string): Colors {
        if (disabled && active) {
            return {
                innerBackgroundColor: lightenColor({ theme }, "backgroundColor", 60),
                innerBorderColor: lightenColor({ theme }, "backgroundColor", 60),
                switchDotColor: getColor({ theme }, "backgroundColor"),
            };
        } else if (disabled && !active) {
            return {
                innerBackgroundColor: getColor({ theme }, "backgroundColor"),
                innerBorderColor: lightenColor({ theme }, "backgroundColor", 60),
                switchDotColor: lightenColor({ theme }, "backgroundColor", 60),
            };
        } else if (!disabled && active && !activeColor) {
            return {
                innerBackgroundColor: getColor({ theme }, textIsDarker ? "@accentGreen" : "@accentLightGreen"),
                innerBorderColor: getColor({ theme }, textIsDarker ? "@accentGreen" : "@accentLightGreen"),
                switchDotColor: getColor({ theme }, "backgroundColor"),
            };
        } else if (!disabled && active && activeColor !== undefined) {
            return {
                innerBackgroundColor: getColor({ theme }, activeColor),
                innerBorderColor: getColor({ theme }, activeColor),
                switchDotColor: getColor({ theme }, "@mainBackground"),
            };
        } else if (!disabled && !active && activeColor !== undefined) {
            return {
                innerBackgroundColor: getColor({ theme }, "backgroundColor"),
                innerBorderColor: lightenColor({ theme }, "color", 60),
                switchDotColor: lightenColor({ theme }, "color", 90),
            };
        } else {
            return {
                innerBackgroundColor: "backgroundColor",
                innerBorderColor: lightenColor({ theme }, "color", 70),
                switchDotColor: getColor({ theme }, "color"),
            };
        }
    }

    const colors = getColors(disabled ?? false, active, activeColor);
    const { innerBackgroundColor, innerBorderColor, switchDotColor } = colors;







    return (
        <OuterContainer floated={floated} size={size ?? "default"}>
            <Inner onClick={disabled ? undefined : toggleActive} disabled={disabled} size={size ?? "default"} bgColor={innerBackgroundColor} borderColor={innerBorderColor} />
            <Switch onClick={disabled ? undefined : toggleActive} disabled={disabled} size={size ?? "default"} active={active ?? false} dotColor={switchDotColor} />
        </OuterContainer>
    );
}
export default SwitchButton;