import { belowBreakpointOrEqual, Button, fadeColor, fadeIn, getColor, lightenColor, styled } from 'imaginarity-react-ui';
import * as React from 'react';



export const QandAInfo = styled.div`
    line-height: 40px;
    font-size: 1.5rem;
    color: ${props => getColor(props, props.theme.colors.mainForeground)};
    position: relative;
    svg{ 
        stroke: ${props => getColor(props, props.theme.colors.mainForeground)};
    }
`;

export const QuestionHeadline = styled.div`
    font-size:1.5em;
    line-height:1.5em;
    margin-bottom:1em;
    -webkit-column-span: all; 
    column-span: all; 
    width:100%;
    font-weight: bolder;
`;

export const QandANumber = styled.div<{ threadClosed: boolean, votes: number }>`
    float:right;
    color: ${props => props.threadClosed ? getColor(props, props.theme.colors.accentGreen) : props.votes < 0 ? getColor(props, props.theme.colors.accentRed) : getColor(props, props.theme.colors.mainForeground)};
    font-size:0.9rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1pt;
    cursor: ${props => props.threadClosed ? "default" : "pointer"};
    &:hover{
        color: ${props => props.threadClosed ? props.threadClosed ? getColor(props, props.theme.colors.accentGreen) : props.votes < 0 ? getColor(props, props.theme.colors.accentRed) : getColor(props, props.theme.colors.accentBlue) : getColor(props, props.theme.colors.accentBlack)};
    }
`;

export const QuestionContentContainer = styled.div<{ showVotes: boolean, isMobile: boolean }>`
    display: grid;
    grid-template-columns: ${props => props.isMobile ? "40px" : props.showVotes ? "100px" : "53px"} 1fr 40px;
    grid-template-rows: min-content;
    margin-top:10px;
    margin-bottom:-5px;
    background: ${props => getColor(props, props.theme.colors.mainBackground)};
    box-shadow: 0 3px 6px -6px ${props => getColor(props, props.theme.colors.mainForeground)};
    border-top:1px solid ${props => getColor(props, props.theme.colors.veryLightGrey)};
    animation: ${fadeIn} 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
`;

export const QuestionContentContainerAnswer = styled(QuestionContentContainer)`
    grid-template-columns: ${props => props.isMobile ? "40px auto 40px" : props.showVotes ? "100px auto 120px" : "53px auto 120px"} ; 
`;




export const QuestionInfo = styled.div<{ threadClosed: boolean, votes: number }>`
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: start;
    min-height:160px;
    border-left: ${p => p.threadClosed ? 3 : 1}px solid ${props => props.threadClosed ? getColor(props, props.theme.colors.accentGreen) : props.votes < 0 ? getColor(props, props.theme.colors.accentRed) : getColor(props, props.theme.colors.accentBlue)};
    background: ${props => props.threadClosed ? fadeColor(props, getColor(props, props.theme.colors.accentGreen), 10) : props.votes < 0 ? fadeColor(props, getColor(props, props.theme.colors.accentRed), 10) : getColor(props, props.theme.colors.veryLightGrey)};
    text-align: center;
    transition: 0.1s all ease-in-out;
    &:hover{
        background: ${props => lightenColor(props, getColor(props, props.theme.colors.veryLightGrey), 94)};
    }
    position: relative;
`;
export const BottomButton = styled.div`
    position: absolute;
    bottom:0;
    left:50%;
    transform: translateX(-50%);
`;
export const BottomButtonAnswer = styled.div<{ buttons: number, showHistory: boolean }>`
    position: absolute;
    bottom:0;
    width: calc(40px * ${props => props.buttons});
    right:0;
    background: ${props => props.showHistory ? lightenColor(props, getColor(props, props.theme.colors.veryLightGrey), 100) : "transparent"};
    text-align:right;
    box-shadow: ${props => props.showHistory ? "-3px -0 4px -2px darkgrey" : "none"};
    transition: 0.2s all ease-out;
`;

export const BottomOpenButton = styled.div<{ color: string }>`
    background:${props => props.color};
    svg{
        stroke: ${props => props.theme.colors.mainBackground};
    }
`;
export const QuestionContent = styled.div`
    grid-column-start: 2;
    grid-row-start: start;
    padding: ${p => belowBreakpointOrEqual(p, "tablet") ? 5 : 15}px;
    transition: 0.2s all ease-out;
    position:relative;
`;
export const QuestionButtonsRight = styled.div<{ threadClosed: boolean, votes: number }>`
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: start;
    background: ${props => props.threadClosed ? fadeColor(props, getColor(props, props.theme.colors.accentGreen), 10) : props.votes < 0 ? fadeColor(props, getColor(props, props.theme.colors.accentRed), 10) : getColor(props, props.theme.colors.veryLightGrey)};
    text-align: center;
    transition: 0.1s all ease-in-out;
    position: relative;
    button>div{margin: 0;}
    &:hover{
        background: ${props => lightenColor(props, getColor(props, props.theme.colors.veryLightGrey), 94)};
    }
`;

export const ButtonVote = styled(Button)`
    display: block;
    margin: -2px;
    transition: 0.2s all ease-out;
    
`;

export const ButtonVoteLeft = styled(Button)`
    display: block;
    margin: -2px;
    transition: 0.1s all ease-out;
    transform-origin: center;
    transform:scale(1);
    &:hover{
        transform:scale(1.5);
        svg{
            stroke: ${props => props.theme.colors.accent};
        }
    }
`;

export const ButtonVoteCorrect = styled(Button) <{ markAsCorrect?: boolean }>`
    display: block;
    margin: -2px;
    transition: 0.2s all ease-in-out;
    transform-origin: center;
    transform: scale(${props => props.markAsCorrect ? 1.8 : 1});
    svg{
        stroke: ${props => props.markAsCorrect ? getColor(props, props.theme.colors.accentGreen) : undefined};
    }
    &:hover{
        transform:scale(1.5);
        svg{
            stroke: ${props => props.theme.colors.accentGreen};
        }
    }
`;

export const Votes = styled.div<{ votes: number, isMobile: boolean }>`
    display: block;
    font-size: ${props => props.isMobile ? "1.0rem" : "1.5rem"};
    font-weight: bold;
    color: ${props => props.votes < 0 ? getColor(props, props.theme.colors.accentRed) : getColor(props, props.theme.colors.lightGrey)};
`;

export const HistoryContainer = styled.div`
    margin-top:1em;
    -webkit-column-span: all; 
    column-span: all; 
    max-width:100%;
    font-size: 0.8rem;
    animation: ${fadeIn} 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
`;

export const HistoryContainerHeader = styled.div`
    font-weight: bolder;
    background: ${props => getColor(props, props.theme.colors.veryLightGrey)};
    border-bottom: 1px solid ${props => getColor(props, props.theme.colors.middleLightGrey)};
    line-height: 40px;
`;

export const AnswerFilter = styled.div`
   width:100%;
   height:40px;
   margin-top:30px;
   background: ${props => lightenColor(props, getColor(props, props.theme.colors.veryLightGrey), 101)};
`;

export const FilterInfoLeft = styled.div<{ showVotes: boolean, isMobile: boolean, threadClosed: boolean }>`
   float: left;
   font-size:1.4rem;
   line-height:40px;
   text-align: center;
   color:  ${props => getColor(props, props.theme.colors.mainForeground)};
   width: ${props => props.isMobile ? "40px" : props.showVotes ? "100px" : "53px"};
   height:40px;
   border-left: ${p => p.threadClosed ? 3 : 1}px solid ${props => getColor(props, props.theme.colors.accentGreen)};
   background: ${props => getColor(props, props.theme.colors.veryLightGrey)};
`;


export const FilterInfo = styled.div`
   float: left;
   font-size:1.2rem;
   line-height:40px;
   margin-left:10px;
   color: ${props => getColor(props, props.theme.colors.mainForeground)};
   transition: 0.2s all ease-out;
`;

export const SortedBy = styled.div`
   float: right;
   line-height:40px;
   font-size:0.8rem;
   margin-right:10px;
   color: ${props => lightenColor(props, getColor(props, props.theme.colors.lightGrey), 100)};
`;

export const FilterButtons = styled.div`
   float: right;
`;

export const AnswerInfo = styled(QuestionInfo)`
    border-left: ${p => p.threadClosed ? 3 : 1}px solid ${props => props.threadClosed ? getColor(props, props.theme.colors.accentGreen) : props.votes < 0 ? getColor(props, props.theme.colors.accentRed) : getColor(props, props.theme.colors.lightGrey)};
    text-align: center;
`;

export const QandANumberAnswer = styled(QandANumber)`
    color:${props => props.threadClosed ? getColor(props, props.theme.colors.accentGreen) : props.votes < 0 ? getColor(props, props.theme.colors.accentRed) : getColor(props, props.theme.colors.mainForeground)};
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    text-align: right;
`;

export const UserInfo = styled.div`
    width:100%;
    min-height:40px;
    transition: all 0.2s ease-out;
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    grid-template-rows: 30px 20px;
`;
export const UserInfoAnswer = styled.div`
    width: calc(100% + 30px);
    padding: 15px;
    transform: translateX(-15px) translateY(-15px);
    height: 70px;
    transition: all 0.2s ease-out;
    border-bottom: 1px solid ${props => getColor(props, props.theme.colors.middleLightGrey)};
`;

export const AvatarQuestioner = styled.div`
   background: transparent;
   width: 40px;
   height: 40px;
   border-radius: 50px;
   float: left;
   margin-right:15px;   
`;
export const NameQuestioner = styled.div`
    font-size:0.85em;
    line-height: 15px;
    display: block;
`;
export const SectionQuestioner = styled.div`
    font-size:0.7rem;
    margin-bottom: 5px;
    margin-right: 15px;
    text-transform: uppercase;
    letter-spacing: 1pt;
    display: inline-block;
    color: ${props => getColor(props, props.theme.colors.lightGrey)};
`;
export const SectionRight = styled.div<{ isMobile: boolean }>`
    font-size:0.7rem;
    margin-bottom: 5px;
    margin-left: ${props => props.isMobile ? 0 : "15px"};
    text-transform: uppercase;
    letter-spacing: 1pt;
    display: ${props => props.isMobile ? "block" : "unset"};
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: right;
    line-height: ${p => belowBreakpointOrEqual(p, "tablet") ? "0.7rem" : "20px"};
`;
export const AnswerInfoHide = styled(AnswerInfo)`
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: start;
    min-height:68px;
    line-height:68px;
    position: relative;
    &:hover{
    background: ${props => props.threadClosed ? fadeColor(props, getColor(props, props.theme.colors.accentGreen), 10) : props.votes < 0 ? fadeColor(props, getColor(props, props.theme.colors.accentRed), 10) : getColor(props, props.theme.colors.veryLightGrey)};
    }
    position: relative;
`;


export const ElementContentContainer = styled(QuestionContentContainer)`
    font-size:1rem;
    color: ${props => props.theme.colors.mainForeground};
    cursor:pointer;
    transition: all 0.2s ease-out;
    &:hover{
        background: ${props => props.theme.colors.veryLightGrey};
    }
`;
export const Question = styled(NameQuestioner)`
    font-size:1rem;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    line-height: ${p => belowBreakpointOrEqual(p, "tablet") ? 20 : 30}px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;  
`;
export const AvatarGrid = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    opacity: 0.75;
    position:  ${p => belowBreakpointOrEqual(p, "tablet") ? "absolute" : "unset"};
    bottom: ${p => belowBreakpointOrEqual(p, "tablet") ? "5px" : "unset"};
    right: ${p => belowBreakpointOrEqual(p, "tablet") ? "-35px" : "unset"};
    img{
        transition: transform 0.1s ease-out;
        &:hover{
            transform: scale(1.5) rotate(22deg);
        }
    }
`;
export const UserGrid = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    opacity: 0.75;
    line-height: ${p => belowBreakpointOrEqual(p, "tablet") ? "0.7rem" : "20px"};
    margin-top: ${p => belowBreakpointOrEqual(p, "tablet") ? -5 : 0}px;
`;

export const BottomInfo = styled(SectionQuestioner)`
    color: ${props => props.theme.colors.mainForeground};
`;

export const AvatarElement = styled(AvatarQuestioner)`
   width: 16px;
   height: 16px;
   border-radius: 10px;
   margin-right:5px;
   margin-top:3px;
`;

export const Avatar = styled.div`
   background: ${props => getColor(props, props.theme.colors.mainBackground)};
   border: 1px solid ${props => getColor(props, props.theme.colors.middleLightGrey)};
   width: 40px;
   height: 40px;
   padding:2px;
   border-radius: 40px;
   float: left;
   margin-right:15px;
`;
export const AvatarAnswer = styled.div`
   width: 20px;
   height: 20px;
   border-radius: 10px;
   float: left;
   margin-right: 10px;
   margin-top: 5px;
`;

export const HeaderName = styled.div`
    font-size: 1em;
    line-height: 20px;
`;
export const Name = styled.div`
    font-size:1.2rem;
    margin-top:-5px;
`;
export const Section = styled.div`
    font-size:0.7rem;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 0.5pt;
    color: ${props => getColor(props, props.theme.colors.mainForeground)};
    display: inline-block;
    margin-right: 20px;
`;

export const Infos = styled.div`
    padding: 10px;
    background: ${props => getColor(props, props.theme.colors.darkerGrey)};
    width:100%;
    margin-top: 15px;
`;

export const InfoLine = styled.div`
    display: list-item;
    list-style-type: none;
    width: 100%;
`;

export const InfoLineLeft = styled.div`
    margin-left: 0px;
    font-size:0.8rem;
`;

export const InfoLineRight = styled.div`
    float: right;
`;

export const ButtonBar = styled.div`
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    button>div{margin:0;}
    svg{margin-top: 1px;}
`;

export const Figure = styled.div<{ showContent: boolean, maxColWidth: number }>`
    transition: all 0.2s ease-out;
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    box-shadow: 0 6px 8px -6px ${p => getColor(p, "@lightGrey")};
    height: 100%;
    position: relative;
    padding-bottom: ${p => p.showContent ? 40 : 0}px;
    overflow-x: hidden;
    overflow-y: hidden;
    max-width: ${p => p.maxColWidth}px;
`;
export const CatTitleContainer = styled.div`
    width: 100%;
    height: 40px;
    background: ${p => getColor(p, "@veryLightGrey")};
    border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
    display: grid;
    grid-template-columns: 1fr 160px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    color: ${p => getColor(p, "@mainForeground")};
    svg{
        stroke: ${p => getColor(p, "@mainForeground")};
    }
    &:hover{
        color: ${p => lightenColor(p, "@mainForeground", 50)};
        background: ${p => lightenColor(p, "@veryLightGrey", 97)};
        svg{
            stroke: ${p => lightenColor(p, "@mainForeground", 50)};
        }
    }
    button>div{margin: 0;}
`;
export const CatTitleContainerBtn = styled.div`
    svg{
        stroke: unset;
    }
    &:hover{
        svg{
            stroke: unset;
        }
    }
    button>div{margin: 0;}
`;
export const CatTitle = styled.div<{ showContent: boolean }>`
    width: 100%;
    line-height: 40px;
    height: 40px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 10px;
    font-weight: ${p => p.showContent ? "bolder" : "normal"};
`;
export const SubCategoryItemContainer = styled.div<{ expanded: boolean, active?: boolean, hasNoQuestions: boolean }>`
    display: grid;
    grid-template-columns: ${p => p.expanded ? "1fr 2fr" : "1fr 0"};
    transition: all 0.2s ease-out;
    background: ${p => p.active ? fadeColor(p, "@middleLightGrey", 30) : getColor(p, "@mainBackground")};
    color: ${p => p.hasNoQuestions ? p.active ? lightenColor(p, "@mainForeground", 50) : fadeColor(p, "@mainForeground", 70) : p.active ? lightenColor(p, "@mainForeground", 50) : getColor(p, "@mainForeground")};
    cursor: ${p => p.active ? "default" : "pointer"};
    font-weight:${p => p.active ? "bolder" : "normal"};
    svg{
            stroke: ${p => p.active ? getColor(p, "@mainForeground") : fadeColor(p, "@mainForeground", 70)};
        }
    &:hover{
        color: ${p => p.active ? getColor(p, "@mainForeground") : lightenColor(p, "@mainForeground", 50)};
        background: ${p => p.active ? fadeColor(p, "@middleLightGrey", 30) : getColor(p, "@veryLightGrey")};
    }
`;
export const AddThreadContainer = styled.div`
    display: grid;
    grid-template-columns: 200px 1fr;
    transition: all 0.2s ease-out;
    background: ${p => getColor(p, "@mainBackground")};
    color: ${p => getColor(p, "@darkerGrey")};
    padding: 10px;
    border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
    row-gap: 5px;
`;
export const SubCategoryItem = styled.div<{ level: number, expanded: boolean }>`
    width: 100%;
    line-height: ${p => p.expanded ? 32 : 24}px;
    height: ${p => p.expanded ? 32 : 24}px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 5px;
    padding-left: ${p => p.level * (p.expanded ? 15 : 5)}px;
    transition: all 0.2s ease-out;
    display: grid;
    grid-template-columns: ${p => p.expanded ? "max-content max-content 1fr max-content" : "30px 1fr max-content 20px 20px"};
`;
export const SubCategoryItemTitle = styled.div`
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
export const SubCategoryItemQuestions = styled.div<{ expanded: boolean }>`
    float: right;
    margin: 0 10px;
    text-align: right;
    svg{
        transform: translateX(${p => p.expanded ? "4px" : "-4px"});
    }
`;
export const ContainerFooter = styled.div`
    width: 100%;
    height: 40px;
    background: ${p => getColor(p, "@veryLightGrey")};
    border-top: 1px solid ${p => getColor(p, "@middleLightGrey")};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    button>div{margin: 0;}
`;

export const SubCategoryItemSubInfo = styled.div`
   display: inline-block;
   line-height: 24px;
   margin-left: 10px;
   font-size: 0.85em;
   color: ${p => getColor(p, "@lightGrey")};
`;

export const NoQuestionsInfo = styled.div`
   height: 40px;
   width: 100%;
   line-height: 40px;
   border: 1px solid ${p => getColor(p, "@middleLightGrey")};
   color: ${p => getColor(p, "@accentRed")};
   background: ${p => getColor(p, "@veryLightGrey")};
   padding: 0 0 0 10px;
   display: grid;
   grid-template-columns: 1fr max-content;
   column-gap: 10px;
`;


export const AnsweringContainer = styled.div<{ open: boolean }>`
   width: 100%;
   border: 1px solid ${p => p.open ? getColor(p, "@middleLightGrey") : "transparent"};
   color: ${p => getColor(p, "@mainForeground")};
   background: ${p => getColor(p, "@mainBackground")};
   box-shadow: 0 6px 8px -6px ${p => p.open ? getColor(p, "@lightGrey") : "transparent"};
   height: ${p => p.open ? "auto" : "0px"};
   overflow: hidden;
   margin-top: 20px;
   margin-bottom: ${p => p.open ? 20 : 0}px;
   transition: all 0.2s ease-in-out;
`;
export const AnsweringContainerTitleContainer = styled.div`
   width: 100%;
   border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
   background: ${p => getColor(p, "@veryLightGrey")};
   height: 40px;
   display: grid;
   grid-template-columns: 1fr 40px;
`;
export const AnsweringContainerTitle = styled.div`
    width: 100%;
    color: ${p => getColor(p, "@mainForeground")};
    padding-left: 10px;
    line-height: 40px;
    font-weight: bolder;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
export const AnsweringEditContainer = styled.div`
   width: 100%;
   display: grid;
   grid-template-columns: max-content 1fr;
   grid-template-rows: max-content;
   column-gap: 10px;
   padding: 10px 0;
`;
export const AnsweringEditContainerLeft = styled.div`
   width: 100%;
   padding: 0 10px;
   line-height: 40px;
   font-weight: bolder;
`;
export const AnsweringEditContainerRight = styled.div`
   width: 100%;
   padding-right: 10px;
   line-height: 40px;
`;

export const DropzoneArea = styled.div<{ hasFile?: boolean }>`
    padding: 0 10px;
    height: 120px;
    color: ${p => p.hasFile ? getColor(p, "@mainForeground") : getColor(p, "@lightGrey")};
    border: 1px solid  ${p => getColor(p, "@middleLightGrey")};
    transition: 0.2s all ease-in-out;
    background: ${p => getColor(p, "@mainBackground")};
    &:hover{
        background: ${p => getColor(p, "@veryLightGrey")};
        color: ${p => getColor(p, "@mainForeground")};
        border: 1px solid  ${p => getColor(p, "@lightGrey")};
        svg{
            stroke: ${p => getColor(p, "@mainForeground")};
    }
    }
`;
export const MediaArea = styled.div`
    width: 100%;
    height: 120px;
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    padding: 0px;
    color: ${p => getColor(p, "@mainForeground")};
    font-size: 12px;
    overflow: hidden;
`;
export const AddLabel = styled.div`
    color:  ${p => getColor(p, "@mainForeground")};
    padding: 10px 0;
    line-height: 20px;
    font-size:0.9rem;
    width: 100%;
`;
export const AddContent = styled.div<{ topBorder?: boolean }>`
    button>div{margin:0;}
    position: relative;
    border-top: ${p => p.topBorder ? "1px solid " + getColor(p, "@middleLightGrey") : "none"};
    padding-top: ${p => p.topBorder ? 10 : 0}px;
`;

export const DeactivateContent = styled.div<{ deactivate: boolean }>`
    filter: ${p => p.deactivate ? "grayscale(1)" : "grayscale(0)"};
    opacity: ${p => p.deactivate ? 0.5 : 1};
    pointer-events: ${p => p.deactivate ? "none" : "all"};
`;

export const ForumPathContainer = styled.span`
    display: inline-block;
    width: auto;
    padding-right: 5px;
    padding-top: 4px;
    line-height: 16px;
    color: ${p => getColor(p, "@lightGrey")};
    font-size: smaller;
    font-weight: normal;
    &:after  { 
        content:"→"; 
        padding-left: 5px;
    }
    &:last-child:after{
        content: "";
    }
`;

export const SelectedForumError = styled.div`
    line-height: 30px;
    padding: 0 5px;
    margin-top: 5px;
    display: inline-block;
    color: ${p => getColor(p, "@lightGrey")};
    cursor: pointer;
    &:hover{
        color: ${p => getColor(p, "@mainForeground")};
        svg{
            stroke:${p => getColor(p, "@mainForeground")};
        }
        }
    svg{
        stroke:${p => getColor(p, "@lightGrey")};
    }
`;
export const SelectedForum = styled.div`
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    line-height: 30px;
    padding: 0 5px;
    margin-top: 5px;
    margin-right: 5px;
    display: inline-block;
    svg{
        stroke:${p => getColor(p, "@lightGrey")};
        cursor: pointer;
        &:hover{
            stroke:${p => getColor(p, "@accentRed")};
        }
    }
`;


export const ForumInfoContainer = styled.div`
     display: grid;
     grid-template-columns: 1fr 40px;
     border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
     background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
     width: 100%;
     margin-bottom: 20px;
     box-shadow: 0 6px 8px -6px ${p => getColor(p, "@lightGrey")};
`;
export const ForumInfoContainerTitle = styled.div`
     font-weight: bolder;
     padding-left: 10px;
     height: 40px;
     line-height: 40px;
`;
export const ForumInfoContainerDescription = styled.div`
     grid-column-start: 1;
     grid-column-end: 3;
     padding: 10px;
     border-top: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
     background: ${p => getColor(p, p.theme.colors.mainBackground)};
`;
export const ForumInfoContainerInfoItemContainer = styled.div`
     margin-top: 10px;
     display: block;
`;
export const ForumInfoContainerInfoItem = styled.div`
     display: inline-block;
     margin-right: 15px;
`;


export default class QandAClasses extends React.Component<any> { }
