import { appendPx, createGlobalStyle, fadeColor, getColor, ThemeFontFace } from 'imaginarity-react-ui';
import * as _ from "lodash";

const createFontFace = (f: ThemeFontFace) => {
    const c = `@font-face { font-family: "${f.family}"; font-weight: ${f.fontWeight}; font-style: ${f.fontStyle}; src: url("${f.url}") format("${f.format}"); }`;
    return c;
}

const createLngFontFamilies = (baseFf: string, ffs?: { [lng: string]: string }) => {
    if (!ffs)
        return "";
    const toRet = _.join(_.map(ffs, (ff, lng) => `:lang(${lng}) {font-family: ${ff}, ${baseFf}}`), " ");
    //console.log("GlobalStyle.tsx::13 => toRet", toRet);

    return toRet;
}

export const GlobalStyle = createGlobalStyle`
    ${p => p.theme.fontFaces ? _.map(p.theme.fontFaces, f => createFontFace(f)) : ""}
    ${p => createLngFontFamilies(p.theme.fontFamily, p.theme.langFontFamilies)}
        body {
        font-family: ${props => props.theme.fontFamily};
        font-size: ${props => appendPx(props.theme.fontSize)};
        font-weight: ${props => props.theme.fontWeights.default};
        -moz-osx-font-smoothing: grayscale;
        margin: 0px;
        padding: 0px;
        overflow-x: hidden;
        min-width: 320px;
        background: ${props => getColor(props, props.theme.colors.mainBackground)};
        line-height: 1.4285em;
        color: ${props => fadeColor(props, getColor(props, props.theme.colors.accentBlack), 87)};
        box-sizing: border-box;
    }
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }
    .sizeconditional-masterlayout {
        position: fixed !important;
        width: 100vw;
        height: 100%;
    }
    .report-style-class {
        width: 100%;
        aspect-ratio: 1360 / 2520;
        /* height: 2600px; */
        margin: auto;
        border: none;
        /* height: 90vh; */
    }
    p {
        margin: 0em 0em 1em;
        line-height: 1.4285em;
    }
    a {
        text-decoration: none;
        color: ${p => p.theme.colors.accent};
    }
    img {
        border-style: none;
    }
    *, *:before, *:after {
        box-sizing: inherit;
        scrollbar-color: ${props => getColor(props, props.theme.scrollbarThumb)}  ${props => getColor(props, props.theme.scrollbarTrack)};
        scrollbar-width: thin;
    }
    ::-webkit-scrollbar {
        width: ${props => appendPx(props.theme.scrollbarWidth)} !important;
        height: ${props => appendPx(props.theme.scrollbarHeight)} !important;
    }
    ::-webkit-scrollbar-track {
        background-color:${props => getColor(props, props.theme.scrollbarTrack)} !important;
        border-radius: ${props => appendPx(props.theme.scrollbarRadius)};
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${props => getColor(props, props.theme.scrollbarThumb)} !important;
        border-radius: ${props => appendPx(props.theme.scrollbarRadius)} !important;  
        &:hover{
            background-color: ${props => getColor(props, props.theme.colors.accent)} !important;
        }       
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background-color: ${props => getColor(props, props.theme.colors.lightGrey)} !important; 
    }

    input:-webkit-autofill, input:-webkit-autofill:hover,  input:-webkit-autofill:focus {
        border: none;
        -webkit-text-fill-color: ${props => getColor(props, props.theme.colors.mainForeground)};
        -webkit-box-shadow: 0 0 0 1000px transparent inset;
        box-shadow: 0 0 0 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
    input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }
`;
