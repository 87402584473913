import * as React from 'react';
export interface MyHomeStartPRCProps {
}

const MyHomeStartPRC = (p: MyHomeStartPRCProps) => {

    return (
        <>

            <div style={{ height: 10 }} />
            <h2>PALMS Reporting Cockpit</h2>

            <div style={{ height: 10 }} />
            <iframe title="test" src="https://porsche-ag.eu10.sapanalytics.cloud/sap/fpa/ui/app.html#/story&/s/9A081E0360435C0C75F33F458BFEA1A6/?resourceType=STORY&mode=view" style={{ width: "100%", height: "800px", border: "1px solid #f0f0f0" }}></iframe>

        </>
    );
}
export default MyHomeStartPRC;
