import * as React from 'react';
export interface MyHomeStartMCProps {
}

const MyHomeStartMC = (p: MyHomeStartMCProps) => {

    return (
        <>

            <div style={{ height: 10 }} />
            <h2>Module Cockpit</h2>

            <div style={{ height: 10 }} />
            <iframe title="test" src="https://porsche-ag.eu10.sapanalytics.cloud/sap/fpa/ui/tenants/8f995/app.html#/analyticapp&/aa/CAB89186A0CB56E79BEFEDA50B8144A7/?view_id=appBuilding&mode=present&url_api=true" style={{ width: "100%", height: "800px", border: "1px solid #f0f0f0" }}></iframe>

        </>
    );
}
export default MyHomeStartMC;
