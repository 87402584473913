import { ContentPostDownloadDto } from 'collaboration-service';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { formatDistanceToNow } from 'date-fns';

import { Button, getUriFromLinkByName, Image, RatingView, Theme } from 'imaginarity-react-ui';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { PostProps } from 'services/ApplicationState/ApplicationComponentsFactory';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { gotoNamedRoute } from 'services/Helpers/RoutingHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { ImgI18N, useImgI18N } from 'services/ImgI18N';
import { ThemeContext } from 'styled-components';
import { Card, Comments, CPCreated, CPDesc, CPTitle, GridContainer, Indicator, PostRibbon, SPContent, SPHeader } from '../PostClasses';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage, user: state.user });
export interface ContentPostProps extends PostProps {
}
export interface ContentPostState {
    ratingCount: number;
    rating?: number;
}

const ContentPost = (p: ContentPostProps) => {
    const { post: oP } = p;
    const post = oP as ContentPostDownloadDto;
    const [ratingCount, setRatingCount] = React.useState<number>(0);
    const [rating, setRating] = React.useState<number>();

    const { t } = useImgI18N("content");
    const { contentLanguage } = useSelector(mapper, shallowCompare);
    const theme = React.useContext<Theme>(ThemeContext);

    React.useEffect(() => {
        setRatingCount(post.ratingCount);
        setRating(post.rating);
    }, [post])

    const id = post.id;
    const thumb = (getTranslated(post?.content, contentLanguage)?.thumbnails ?? [])[0];
    const media = thumb && thumb.reference ? thumb : post.media
    const imageUri = getUriFromLinkByName(media, "self") ?? theme.emptyImageUrl;
    const headline = getTranslated(post.headlines, contentLanguage).text ?? "";
    const desc = getTranslated(post.descriptions, contentLanguage).text ?? "";
    const created = formatDistanceToNow(
        new Date(post.created),
        { addSuffix: true, locale: ImgI18N.getInstance().languageLocal(contentLanguage) }
    )
    return (
        <Card>
            <PostRibbon gradientColor1="#017379" gradientColor2="#3AB4BA"><span>{t("smw")}</span> </PostRibbon>
            <GridContainer>
                <div style={{ margin: "10px" }}>
                    <Image src={imageUri} mediaType={media.mediaType} />
                </div>
                <SPContent>
                    <CPTitle>
                        <SPHeader>
                            <Button
                                kind="secondary"
                                floated="right"
                                content="open article"
                                onClick={() => gotoNamedRoute("content_art", { id })}
                            />
                            {headline}
                        </SPHeader>
                    </CPTitle>
                    <CPCreated>
                        {created}
                    </CPCreated>
                    <CPDesc>
                        <SafeHTML html={desc ?? ""} />
                    </CPDesc>

                </SPContent>
            </GridContainer>
            <Comments>
                <Button
                    kind="halfTransparentButton"
                    icon="chevron right"
                    floated="left"
                    onClick={() => gotoNamedRoute("content_art", { id })}
                />
                <div
                    style={{ float: "right", marginTop: 10, cursor: "pointer" }}
                >
                    {ratingCount > 0 &&
                        <Indicator float="right" isType="rating">
                            {ratingCount}
                        </Indicator>
                    }
                    <RatingView
                        rating={rating ?? post.rating}
                        maxStars={5}
                        size="18px"
                    />
                </div>
            </Comments>
        </Card >
    );

}

export default ContentPost;