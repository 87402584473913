import { editorEmptyString } from 'Dummies/TestImpl/TestEditor';
import { BookmarkController, ControllerHelper, MediaDownloadDto, PorscheMomentPostDownloadDto, PostController, PostInteraction } from 'collaboration-service';
import DateTimeFns from 'components/DateTime/DateTimeFns';
import CommentsRatingsView from 'components/General/CommentsRatingsView';
import UrlComponent from 'components/General/UrlComponent';
import ImageSliderImgFlex from 'components/ImageSlider/ImageSliderImgFlex';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Button, Flag, Icon, Image, Tooltip, getUriFromLinkByName, useBelowBreakpoint, useThemePart } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { Actions, WorkingActions } from 'services/ApplicationState/Actions';
import { PostProps } from 'services/ApplicationState/ApplicationComponentsFactory';
import { ApplicationState, useAppDispatch, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { CurrentSettings } from 'services/Config';
import { getLink, isFromIosHomeScreen, splitTextOnlyLastLink } from 'services/Helpers';
import { gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { getOrTranslatePostLanguageStrings, getTranslated, getTranslatedStandardDate } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { feedTheme } from 'views/Feed/FeedMain';
import PodcastPlayer from 'views/Podcast/PodcastPlayer';
import { getMediaLink } from '../../../services/Helpers/MediaHelpers';
import { MediaContainer } from '../PostClasses';
import { PorscheMomentPostSC as T } from './PorscheMomentPostSC';
import PMLikes from './SubComponents/PMLikes';

const elementResizeDetectorMaker = require("element-resize-detector");
const erdUltraFast = elementResizeDetectorMaker({
    strategy: "scroll" //<- For ultra performance.
});

const mapper = (state: ApplicationState) => ({
    contentLanguage: state.contentLanguage,
    userGroups: state.userGroups,
    user: state.user
});
export interface PorscheMomentPostMobileProps extends PostProps {
    paddedBottom: boolean;
}

const PorscheMomentPostMobile = (p: PorscheMomentPostMobileProps) => {
    const { post, postChanged, paddedBottom } = p;
    const { contentLanguage, user } = useAppSelector(mapper);
    const dispatch = useAppDispatch();
    const { t } = useImgI18N("feed");
    const [showComments, setShowComments] = React.useState<boolean>(false);
    const [showLikes, setShowLikes] = React.useState<boolean>(false);
    const [deleteConfirm, setDeleteConfirm] = React.useState<boolean>(false);
    const [sliderWidth, setSliderWidth] = React.useState<number>();
    // const browser = useThemePart(x => x.browser);
    // const isIOS = MobileHelper.getInstance().iOSClient || (browser === "ios" || browser === "crios" || browser === "fxios");
    // const isIOS = true;
    // const isMobile = useBelowBreakpointOrEqual("tablet");
    // const isMobile = useBelowBreakpointOrEqual("mobile");
    const isMobile = useBelowBreakpoint("tablet");

    const emptyAvatarImageUrl = useThemePart(x => x.emptyAvatarImageUrl);


    React.useEffect(() => {
        const t = async () => {
            const tPost = await getOrTranslatePostLanguageStrings(post, contentLanguage);
            if (tPost) {
                setShowLikes(false);
                setShowComments(false);
                postChanged(tPost);
            }
        }
        t();
    }, [post, contentLanguage, postChanged]);

    const {
        avatar, pm, images, mayDelete, mayUpdate, thumbUri, background, color, gradColor1, gradColor2, shadowed,
        showCarbon, media0Type, media0uri, mediaArray, isLuxuryMoment, isBookmarked, showStandards, flag, ctry,
        commentCountChanged, onBookmark, onDelete, onLike, deletePost } = React.useMemo(() => {
            const mediaArray: MediaDownloadDto[] = [];
            const type = post.type;

            if (type === "PorscheMomentPost") {
                const pm = post as PorscheMomentPostDownloadDto;
                if (pm.media)
                    mediaArray.push(pm.media);
                if (pm.media1)
                    mediaArray.push(pm.media1);
                if (pm.media2)
                    mediaArray.push(pm.media2);
                if (pm.media3)
                    mediaArray.push(pm.media3);
                if (pm.media4)
                    mediaArray.push(pm.media4);
            } else {
                if (post.media)
                    mediaArray.push(post.media);
            }
            const media0Type = mediaArray.length !== 0 ? mediaArray[0].mediaType : undefined;
            const media0uri = media0Type ? getUriFromLinkByName(mediaArray[0], "self") ?? "" : "";
            const isExcitePost = type === "PorscheMomentPost";
            const isCommunityPost = type === "CommunityFeedPost";
            const avatar = getMediaLink(post, y => y.creator?.avatar);
            const pm = isExcitePost ? post as PorscheMomentPostDownloadDto : undefined;
            const mayDelete = getLink(post.links, "delete") !== undefined;
            const mayUpdate = getLink(post.links, "update") !== undefined;
            const images = _.filter(_.map(mediaArray, m => ({ url: getUriFromLinkByName(m, "self") ?? "" })), f => f?.url !== undefined);
            const thumb = (post?.media?.thumbnails ?? [])[0];
            const thumbUri = getUriFromLinkByName(thumb);
            const isLuxuryMoment = CurrentSettings.showLuxuryRibbon && pm?.isLuxury;
            const color = isCommunityPost ? "#FFF" : "#FFF";
            const background = isCommunityPost ? "" : "";
            const gradColor1 = isCommunityPost ? "#0061BD" : isLuxuryMoment ? "#D7A200" : "#D5001C";
            const gradColor2 = isCommunityPost ? "#001930" : isLuxuryMoment ? "#1b1400" : "#270005";
            const showCarbon = isCommunityPost ? true : true;
            const shadowed = isCommunityPost ? true : true;
            const isBookmarked = post?.isBookmarked;
            const showStandards = (_.findIndex(pm?.standards, s => s === "int") >= 0) || (_.findIndex(pm?.standards, s => s === "rel") >= 0) || (_.findIndex(pm?.standards, s => s === "exc") >= 0) || (_.findIndex(pm?.standards, s => s === "eff") >= 0);
            const descLng = pm && pm.headlines && pm.headlines.length > 0 ? pm.headlines[0].lng : undefined;
            let flag: any;
            if (descLng) {
                flag = descLng.indexOf("-") > -1 ? descLng.split("-")[1].toUpperCase() : descLng.toUpperCase();
                if (flag === "EN") {
                    flag = "GB";
                }
            }
            let ctry = post?.organization1 as any;
            const deletePost = () => {
                if (post)
                    PostController.DeletePost({ postid: post.id },
                        () => {
                            dispatch(WorkingActions.FEED.initialLoadPosts());
                        },
                        error => console.log(error)
                    );
                setDeleteConfirm(false);
            };

            const onDelete = (mode: boolean) => () => {
                setDeleteConfirm(mode);
            };

            const commentCountChanged = (count: number) => {
                dispatch(Actions.updatePost({ ...post, commentCount: count }))
            }
            const onLike = (like: boolean) => async () => {
                const newPost = _.clone(post);
                newPost.likeCount += like ? 1 : -1;
                newPost.didLike = like;
                dispatch(Actions.updatePost(newPost));
                await ControllerHelper.singleCall({ id: post.id }, like ? PostInteraction.LikePost : PostInteraction.DislikePost);
                if (like === true)
                    setShowLikes(true);
            }
            const onBookmark = (isBookmarked: boolean) => async () => {
                const newPost = _.clone(post);
                newPost.isBookmarked = isBookmarked;
                dispatch(Actions.updatePost(newPost));
                const res = await ControllerHelper.singleCall({ postid: post.id }, isBookmarked ? BookmarkController.PostBookmark : BookmarkController.RemoveBookmark);
                if (res) {
                    postChanged(newPost); // signal back that event happened
                }
            }

            return ({
                avatar, pm, mayDelete, mayUpdate, images, thumbUri, color, background, gradColor1, gradColor2, showCarbon, shadowed,
                media0Type, media0uri, mediaArray, isLuxuryMoment, isBookmarked, showStandards, flag, ctry,
                onDelete, commentCountChanged, onLike, onBookmark, deletePost,
            });
        },
            [post, dispatch, postChanged]);

    const { desc, headline } = React.useMemo(() => {
        const headline = getTranslated(post.headlines, contentLanguage).text ?? "";
        const desc = getTranslated(post.descriptions, contentLanguage).text ?? "";
        return ({
            headline,
            desc,
        });
    }, [post, contentLanguage]);

    const updateMediaOuterContainerHeight = () => {
        if (mediaOuterContainerRef.current) {
            setMediaOuterContainerHeight(mediaOuterContainerRef.current.offsetHeight);
        }
    };

    const setMediaContainerRef = React.useMemo(() => (contextRef: HTMLElement | null) => {
        if (contextRef) {
            erdUltraFast.listenTo(contextRef, () => {
                setSliderWidth(contextRef.offsetWidth);
                updateMediaOuterContainerHeight();
            });
        }
    }, []);


    const openedFromHomeScreen = isFromIosHomeScreen();

    const mediaOuterContainerRef = React.useRef<HTMLDivElement>(null);
    const postContentHeadlineRefRef = React.useRef<HTMLDivElement>(null);
    const postContentRef = React.useRef<HTMLDivElement>(null);
    const likesRef = React.useRef<HTMLDivElement>(null);
    const commentsRef = React.useRef<HTMLDivElement>(null);

    const [mediaOuterContainerHeight, setMediaOuterContainerHeight] = React.useState<number>(0);
    const [postContentHeadlineHeight, setPostContentHeadlineHeight] = React.useState<number>(0);
    const [postContentHeight, setPostContentHeight] = React.useState<number>(0);
    const [likesContentHeight, setLikesContentHeight] = React.useState<number>(0);
    const [commentsContentHeight, setCommentsContentHeight] = React.useState<number>(0);

    React.useEffect(() => {
        setMediaOuterContainerHeight(mediaOuterContainerRef.current?.offsetHeight ?? 0);
        setPostContentHeadlineHeight(postContentHeadlineRefRef.current?.offsetHeight ?? 0);
        setPostContentHeight(postContentRef.current?.offsetHeight ?? 0);
        setLikesContentHeight(likesRef.current?.offsetHeight ?? 0);
        setCommentsContentHeight(commentsRef.current?.offsetHeight ?? 0);
    }, []);

    const rest = React.useMemo(() => {
        // if (isMobile && isIOS)
        if (isMobile)
            return (
                mediaOuterContainerHeight +
                (showStandards && CurrentSettings.showStandardsInNewMoments ? 40 : 0) +
                postContentHeadlineHeight +
                postContentHeight +
                (showLikes ? likesContentHeight : 0) +
                (showComments ? commentsContentHeight : 0) +
                (openedFromHomeScreen ? 0 : 100) + 360
            );
    }, [mediaOuterContainerHeight, postContentHeadlineHeight, postContentHeight, likesContentHeight, commentsContentHeight, showLikes,
        showComments, showStandards, isMobile, openedFromHomeScreen]);
    const mediaContainerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const updateDimensions = () => {
            if (mediaContainerRef.current) { setSliderWidth(mediaContainerRef.current.offsetWidth); }
            if (mediaOuterContainerRef.current) { setMediaOuterContainerHeight(mediaOuterContainerRef.current.offsetHeight); }
            if (postContentHeadlineRefRef.current) { setPostContentHeadlineHeight(postContentHeadlineRefRef.current.offsetHeight); }
            if (postContentRef.current) { setPostContentHeight(postContentRef.current.offsetHeight); }
            if (likesRef.current) { setLikesContentHeight(likesRef.current.offsetHeight); }
            if (commentsRef.current) { setCommentsContentHeight(commentsRef.current.offsetHeight); }
        };
        const resizeObserver = new ResizeObserver(updateDimensions);

        if (mediaOuterContainerRef.current) { resizeObserver.observe(mediaOuterContainerRef.current); }
        if (postContentHeadlineRefRef.current) { resizeObserver.observe(postContentHeadlineRefRef.current); }
        if (postContentRef.current) { resizeObserver.observe(postContentRef.current); }
        if (likesRef.current) { resizeObserver.observe(likesRef.current); }
        if (commentsRef.current) { resizeObserver.observe(commentsRef.current); }

        updateDimensions();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const handleImageLoad = () => {
        if (mediaOuterContainerRef.current) {
            setMediaOuterContainerHeight(mediaOuterContainerRef.current.offsetHeight);
        }
    };

    return (
        <T.OuterContainer shadowed={shadowed} style={{ zIndex: 5, paddingBottom: paddedBottom ? 100 : 0 }}>
            <T.PostContainer
                background={background}
                gradColor1={gradColor1}
                gradColor2={gradColor2}
                showCarbon={showCarbon}
                style={{
                    zIndex: 5,
                    maxWidth: "calc(100vw + 5px)"
                }}
            >
                {isLuxuryMoment &&
                    <T.LuxuryContainer right={pm ? (((pm.responsibleUsers.length - 1) * 15) + 50) : 70}>
                        <Image src={feedTheme.luxuryRibbonUrl} width='100px' />
                    </T.LuxuryContainer>
                }
                <T.PostContainerImage onClick={(post && post.creator) ? gotoNamedRouteMethod("user", { id: post.creator.id }) : undefined} style={{ height: 80, width: 80, borderRadius: 40, padding: 5 }}>
                    <Image src={avatar ?? emptyAvatarImageUrl} rounded />
                </T.PostContainerImage>
                <T.PostTime>
                    <DateTimeFns
                        iconSize={16}
                        marginTop={1}
                        date={post.changed}
                        iconColor={color}
                        textColor={color}
                        dateFormat="P"
                        timeFormat='p'
                        fluid />
                </T.PostTime>
                {post?.creator?.organisation && <T.Organisation>{post?.creator?.organisation}</T.Organisation>}
                {pm && _.filter(pm.responsibleUsers, r => r.id !== pm.creator?.id).length > 0 && _.map(pm.responsibleUsers, (r, i) => {
                    return (
                        <T.AvatarMouseOverContainer
                            key={i}
                            idx={i}
                            onClick={gotoNamedRouteMethod("user", { id: r.id })}
                        >
                            <Tooltip tooltipText={r.firstName + " " + r.secondName} noMargin notInline >
                                <Image src={getMediaLink(r, u => u.avatar) ?? emptyAvatarImageUrl} width="24px" rounded />
                            </Tooltip>
                        </T.AvatarMouseOverContainer>
                    );
                })
                }
                <T.Poster hasOrganisation={post?.creator?.organisation ? true : false}>
                    {(post?.creator?.firstName ?? "") + " " + (post?.creator?.secondName ?? "")}
                </T.Poster>


            </T.PostContainer>
            <div style={{ maxWidth: "calc(100vw + 5px)", height: "auto" }} ref={mediaOuterContainerRef}>
                {mediaArray.length > 0 &&
                    <MediaContainer ref={setMediaContainerRef} >
                        {media0Type?.startsWith("image") && sliderWidth && sliderWidth > 0 &&
                            <div
                                style={{
                                    width: "100%",
                                    overflow: "hidden",
                                    position: "relative",
                                    background: "#000",
                                    // marginTop: -100
                                }}
                            >
                                <ImageSliderImgFlex
                                    width={sliderWidth}
                                    height={sliderWidth}
                                    images={images}
                                    isIphone={isMobile}
                                />
                            </div>
                        }

                        {media0Type?.startsWith("video") && (
                            <div
                                style={{
                                    width: "100%",
                                    overflow: "hidden",
                                    position: "relative",
                                    background: "#000",
                                    // marginTop: -100
                                }}
                            >
                                <video
                                    width="100%"
                                    autoPlay={false}
                                    controls
                                    src={`${media0uri}${thumbUri ? "" : "#t=0.01"}`}
                                    typeof={media0Type}
                                    crossOrigin="anonymous"
                                    playsInline={true}
                                    preload="metadata"
                                    poster={thumbUri}
                                    onLoadedMetadata={handleImageLoad}
                                />
                            </div>
                        )}
                        {media0Type?.startsWith("audio") &&
                            <T.AudioContainer style={{ padding: CurrentSettings.useMiniPodcastPlayerForAudios ? 0 : 20 }}>
                                {CurrentSettings.useMiniPodcastPlayerForAudios ?
                                    <PodcastPlayer src={media0uri} mini />
                                    :
                                    <audio src={media0uri} controls crossOrigin="anonymous" controlsList="nodownload" preload='none' />
                                }
                            </T.AudioContainer>
                        }
                    </MediaContainer>
                }
                {/* <div style={{ position: "absolute", top: 0, left: 0, right: 0, height: 60, background: media0Type && media0Type?.startsWith("audio") ? "#000" : "rgba(0,0,0,0.6)" }} /> */}
                {/* <T.DarkenContainer trans={media0Type === undefined || media0Type?.startsWith("audio")} /> */}

            </div>

            {showStandards && CurrentSettings.showStandardsInNewMoments &&
                <T.PostContainerStandards background={background}
                    gradColor1={gradColor1}
                    gradColor2={gradColor2}
                    showCarbon={showCarbon}
                    style={{
                        zIndex: 5,
                        maxWidth: "calc(100vw + 5px)"
                    }}>
                    <div><T.Standard selected={_.findIndex(pm?.standards, s => s === "int") >= 0}>Integrity</T.Standard></div>
                    <div><T.Standard selected={_.findIndex(pm?.standards, s => s === "rel") >= 0}>Relationship</T.Standard></div>
                    <div><T.Standard selected={_.findIndex(pm?.standards, s => s === "exc") >= 0}>Excitement</T.Standard></div>
                    <div><T.Standard selected={_.findIndex(pm?.standards, s => s === "eff") >= 0}>Efficiency</T.Standard></div>
                </T.PostContainerStandards>
            }
            <T.PostContentHeadline ref={postContentHeadlineRefRef}>
                {flag &&
                    <>
                        <T.PostContentHeadlineFlag>
                            <Tooltip tooltipText={t("post language {{flag}}", { flag })} noMargin>
                                <Flag name={flag} size={'18px'} />
                            </Tooltip>
                        </T.PostContentHeadlineFlag>
                        <T.PostContentHeadlineText>
                            {t("post language")}:
                        </T.PostContentHeadlineText>
                    </>
                }
                <SafeHTML html={headline} />
            </T.PostContentHeadline>
            {(desc === editorEmptyString || desc === "") ?
                <>
                    {post.changed > post.created ?
                        <T.PostContent style={{ padding: "0 10px" }} ref={postContentRef}>
                            <T.PostContentInfo style={{ transform: "unset", marginTop: 0 }}>
                                <Icon name="info" style={{ float: "left", marginRight: 5, marginLeft: 1 }} size="18px" marginTop={4} />
                                {t("created on {{created}}, edited on {{edited}}", { created: getTranslatedStandardDate(post.created, "P"), edited: getTranslatedStandardDate(post.changed, "P") })}
                            </T.PostContentInfo>
                        </T.PostContent>
                        :
                        <div />
                    }
                </>
                : <T.PostContent ref={postContentRef}>
                    <SafeHTML html={desc ?? ""} />
                    {desc.includes("https://") && splitTextOnlyLastLink(desc, UrlComponent)}
                    {post.changed > post.created &&
                        <T.PostContentInfo>
                            {t("created on {{created}}, edited on {{edited}}", { created: getTranslatedStandardDate(post.created, "P -p"), edited: getTranslatedStandardDate(post.changed, "P -p") })}
                        </T.PostContentInfo>
                    }
                </T.PostContent>
            }

            {rest && <T.CalculatedRestHeight rest={rest} />}
            <T.ButtonBar>
                <T.ButtonBarBtnContainer active={post.didLike || showLikes} filledIcon={post.didLike}>
                    <Button
                        kind="fullTransparentButton"
                        icon="heart"
                        floated='left'
                        iconColor={post.didLike ? "@accentRed" : "@accentBlack"}
                        onClick={onLike(!post.didLike)}
                        tooltip={{ tooltipText: post.didLike ? t("undo like") : t("like post"), position: "top" }}
                    />
                    {post.likeCount > 0 ?
                        <T.BtnIndicator>
                            <Button
                                kind="fullTransparentButton"
                                content={post.likeCount.toString()}
                                onClick={() => setShowLikes(t => !t)}
                                tooltip={{ tooltipText: t("show likers"), position: "bottom" }}
                            />
                        </T.BtnIndicator>
                        :
                        <div />
                    }
                </T.ButtonBarBtnContainer>

                <T.ButtonBarBtnContainer active={showComments}>
                    <Button
                        kind="fullTransparentButton"
                        icon="comment"
                        floated="left"
                        iconColor={"@darkGrey"}
                        onClick={() => setShowComments(c => !c)}
                        tooltip={{ tooltipText: t("show comments"), position: "top" }}
                    />
                    {post.commentCount > 0 ?
                        <T.BtnIndicator>
                            <Button
                                kind="fullTransparentButton"
                                content={post.commentCount.toString()}
                                onClick={() => setShowComments(c => !c)}
                                tooltip={{ tooltipText: t("show comments"), position: "top" }}
                            />
                        </T.BtnIndicator>
                        :
                        <div />
                    }
                </T.ButtonBarBtnContainer>

                <div>
                    {mayDelete &&
                        <>
                            <Button
                                floated="right"
                                kind={deleteConfirm ? "secondary" : "transparentButton"}
                                icon={p !== undefined && deleteConfirm ? "times" : "delete"}
                                onClick={p !== undefined && deleteConfirm ? onDelete(false) : onDelete(true)}
                                tooltip={{ tooltipText: p !== undefined && deleteConfirm ? t("cancel") : t("delete post"), position: p !== undefined && deleteConfirm ? "bottom" : "top" }}
                            />
                            {p !== undefined && deleteConfirm &&
                                <Button
                                    floated="right"
                                    kind="primary"
                                    icon="check"
                                    onClick={deletePost}
                                    tooltip={{ tooltipText: t("yes, delete post"), position: "top" }}
                                />
                            }
                        </>
                    }
                    {mayUpdate && post?.type === "PorscheMomentPost" && !deleteConfirm &&
                        <Button
                            floated="right"
                            kind="transparentButton"
                            icon="edit"
                            onClick={gotoNamedRouteMethod("community_add_post", { id: post?.id })}
                            tooltip={{ tooltipText: t("edit post"), position: "bottom" }}
                        />
                    }
                    {!deleteConfirm &&
                        <T.ButtonBarBtnContainer active={isBookmarked} style={{ float: "right" }}>
                            <Button
                                kind="fullTransparentButton"
                                icon={isBookmarked ? "bookmark" : "bookmark outline"}
                                onClick={onBookmark(!isBookmarked)}
                                iconColor={"@darkGrey"}
                                tooltip={{ tooltipText: isBookmarked ? t("unbookmark post") : t("bookmark post"), position: "top" }}
                            />
                        </T.ButtonBarBtnContainer>
                    }
                </div>
            </T.ButtonBar>
            <div ref={likesRef}>
                {showLikes && (
                    <PMLikes
                        showLikes={showLikes}
                        user={user}
                        id={post.id}
                        setShowLikes={setShowLikes}
                        likeCount={post.likeCount}
                    />
                )}
            </div>
            <div ref={commentsRef}>
                {showComments && (
                    <T.MoreInfoContainer>
                        <T.MoreInfoContainerHeader>
                            {t("latest comments")}:
                            <Button
                                kind="secondary"
                                icon="times"
                                floated="right"
                                onClick={() => setShowComments(false)}
                                tooltip={{ tooltipText: t("close comments") }}
                            />
                        </T.MoreInfoContainerHeader>
                        <T.MoreInfoContainerItems style={{ padding: 0 }}>
                            <CommentsRatingsView
                                post={post}
                                onCountChanged={commentCountChanged}
                                noLeftBorder
                                getIncoming={PostInteraction.GetPostComments}
                                addCommentRating={PostInteraction.AddPostComment}
                                onDeleteComment={PostInteraction.DeletePostComment}
                                onSubmitComment={PostInteraction.UpdatePostComment}
                                inline={false}
                            />
                        </T.MoreInfoContainerItems>
                    </T.MoreInfoContainer>
                )}
            </div>

            {ctry &&
                <T.TopRightFlag>
                    <Tooltip tooltipText={t("post organization1 {{flag}}", { flag: ctry })} noMargin position='top'>
                        <Flag name={ctry} size={'18px'} />
                    </Tooltip>
                </T.TopRightFlag>
            }
        </T.OuterContainer>
    );
}
export default PorscheMomentPostMobile;
