import { PodcastPostDownloadDto } from 'collaboration-service';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Button, Icon, RatingView, Tooltip } from 'imaginarity-react-ui';
import { FloatedRight } from 'layout/masterLayouts/MasterLayoutsSC';
import _ from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import { gotoNamedRoute, tubeRoute } from 'services/Helpers/RoutingHelper';
import { sanitizedNothingAllowed, sanitizedNothingAllowedAttributes } from 'services/Helpers/SanitizedHelper';
import { getTranslated, getTranslatedForTube as getTranslatedForPodcast } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { useAppTheme } from 'services/useAppTheme';
import { PodcastTheme } from 'views/Podcast/PodcastMain';
import { formatPodcastTime } from 'views/Podcast/PodcastPlayer';
import { PodcastSC as T } from 'views/Podcast/PodcastSC';

const mapper = (state: ApplicationState) => ({
    contentLanguage: state.contentLanguage,
    podcastState: state.podcastState,
    groups: state.userGroups,
    quality: state.podcastState.quality,
});

export interface PodcastFeedAudioProps {
    post: PodcastPostDownloadDto;
    gridView: boolean;
    green?: boolean;
    blue?: boolean;
    yellow?: boolean;
    red?: boolean;
    open?: () => void;
    pulse?: boolean;
    currentCategoryId: string;
    isAdmin?: boolean;
}

export interface PodcastFeedVideoState {
    showInfo: boolean;
    showIndicators: boolean;
}

const PodcastFeedAudio = (p: PodcastFeedAudioProps) => {
    const { t } = useImgI18N('tube');
    const post = p.post as PodcastPostDownloadDto;
    const { contentLanguage, podcastState, quality } = useSelector(mapper, shallowCompare);
    const dispatch = useAppDispatch();
    const podcastTheme = useAppTheme<PodcastTheme>("podcastTheme");
    const viewed = _.find(podcastState.viewStats, vs => vs.postId === post.id)?.viewed ?? false;

    const { translatedContent } = React.useMemo(() => {
        const translatedContent = getTranslatedForPodcast(p.post.content, contentLanguage, [quality, "HD", "SD", "4K", "", undefined]);
        if (quality !== translatedContent.contentAttribute && (translatedContent.contentAttribute === "HD" || translatedContent.contentAttribute === "SD" || translatedContent.contentAttribute === "4K"))
            dispatch(Actions.setPodcastQuality(translatedContent.contentAttribute));

        return { translatedContent };
    }, [contentLanguage, quality, dispatch, p.post.content])

    const v = React.useMemo(() => {
        return translatedContent.useContent ? getMediaLink(translatedContent, c => c.content) : "";
    }, [translatedContent])

    const audioRef = React.useRef<HTMLAudioElement | null>(null);
    const [duration, setDuration] = React.useState<number>(0);

    React.useEffect(() => {
        const audio = new Audio(v);
        audioRef.current = audio;
        const setAudioData = () => {
            setDuration(audio.duration);
        };

        audio.addEventListener('loadedmetadata', setAudioData);

        return () => {
            audio.removeEventListener('loadedmetadata', setAudioData);
        };
    }, [v]);

    const { openAudio, thumb, headline } = React.useMemo(() => {
        const openAudio = () => {
            dispatch(Actions.setPodcastCurrentPost(post));
            gotoNamedRoute("podcast_audio_details", tubeRoute(post.id));
        };
        const translatedContent = getTranslated(post.content, contentLanguage);
        const thumb = (post.media?.thumbnails ?? [])[0] ?? post.media;
        const headline = getTranslated(post.headlines, contentLanguage)?.text ?? "";
        const availableResolutions = _.map(_.filter(post.content, c => c.lng === translatedContent.lng), m => m.contentAttribute !== "" && m.contentAttribute !== undefined ? m.contentAttribute : "SD");
        return { translatedContent, thumb, headline, availableResolutions, openAudio };

    }, [post, contentLanguage, dispatch]);

    return (
        <>
            <T.PC onClick={openAudio} imageUrl={getMediaLink(thumb, t => t) ?? podcastTheme.noPreviewPodcastImage} >
                <T.PodcastPostDescriptionTitle onClick={openAudio}>
                    {post.isBookmarked &&
                        <T.PodcastPostDescriptionViewed>
                            <Tooltip
                                position='top'
                                noMargin
                                notInline
                                tooltipText={t("bookmarked podcast")}
                            >
                                <Icon name="bookmark" style={{ float: "right", marginLeft: 10, marginRight: 0 }} marginTop={2} color={podcastTheme.podcastAccent} />
                            </Tooltip>
                        </T.PodcastPostDescriptionViewed>
                    }
                    {!post.isBookmarked && viewed &&
                        <T.PodcastPostDescriptionViewed>
                            <Tooltip
                                position='top'
                                noMargin
                                notInline
                                tooltipText={t("you've already heard the podcast")}
                            >
                                <Icon name="check thin" style={{ float: "right", marginLeft: 10, marginRight: 0 }} marginTop={2} />
                            </Tooltip>
                        </T.PodcastPostDescriptionViewed>
                    }
                    <SafeHTML html={headline} allowedTags={sanitizedNothingAllowed} allowedAttributes={sanitizedNothingAllowedAttributes} />
                </T.PodcastPostDescriptionTitle>
            </T.PC>


            <T.PodcastPostDescriptionText onClick={openAudio} >
                <SafeHTML html={getTranslated(post.descriptions, contentLanguage)?.text ?? ""} allowedTags={sanitizedNothingAllowed} allowedAttributes={sanitizedNothingAllowedAttributes} />
            </T.PodcastPostDescriptionText>

            <T.PodcastButtons onClick={openAudio}>
                <T.PodcastPostFooterInfosBottom>
                    <div style={{ float: "left", transform: "translateY(1px)" }}>
                        <RatingView maxStars={5} rating={post.rating} filledOut />
                    </div>
                </T.PodcastPostFooterInfosBottom>
                <div>
                    <Icon marginTop={3} name="comment" size={16} style={{ float: "left", marginRight: 5 }} />
                    <T.PodcastPostFooterFigure color={post.commentCount > 0 ? undefined : "transparent"}>
                        {post.commentCount}
                    </T.PodcastPostFooterFigure>
                </div>
                <div>
                    <Icon marginTop={3} name="mic on" size={16} style={{ float: "left", marginRight: 5 }} />
                    <T.PodcastPostFooterFigure color={post.views > 0 ? undefined : "transparent"}>
                        {post.views}
                    </T.PodcastPostFooterFigure>
                </div>
                <div>
                    {duration > 0 &&
                        <>
                            <Icon marginTop={3} name="clock" size={16} style={{ float: "left", marginRight: 5, fontSize: "0.85em" }} />
                            <FloatedRight>
                                {formatPodcastTime(duration)}
                            </FloatedRight>
                        </>
                    }
                </div>
                <div style={{ height: 40 }}>
                    <Button icon='play' kind="transparentButton" iconColor={podcastTheme.podcastAccent} iconTextColor={podcastTheme.podcastAccent} />
                </div>

            </T.PodcastButtons>
        </>
    );
}

export default PodcastFeedAudio;