import { ThemeColor } from 'imaginarity-react-ui';
interface ContentTile extends ThemeColor {

}

interface PostSettings {
    backgroundColor: string
    color: string;
}
interface FilteredImage {
    opacity?: number;
    filter?: string;
    url: string;
}
export interface ContentTheme extends ThemeColor {
    tile: ContentTile;
    headerRightImage: FilteredImage;
    postItem: PostSettings;
    showContentTableView: boolean;
    noCategoryImage: string;
    homeHeaderTitle: string;
    homeImage: string;
    contentAccent: string;
    darkMode: boolean;
}


export const contentTheme: ContentTheme = {
    backgroundColor: "#010816",
    darkMode: true,
    //backgroundColor: "linear-gradient(135deg, #021132, #010816)",
    color: "@mainBackground",
    showContentTableView: true,
    homeHeaderTitle: "imaginarity content",
    homeImage: "https://cdn.imaginarity.com/public/projectImages/imgContentHeaderImage.svg",
    noCategoryImage: "https://cdn.imaginarity.com/public/projectImages/imgNoImageAvailable.svg",
    contentAccent: "#35B2DB",
    tile: {
        backgroundColor: "fade('@accentBlack', 40%)",
        color: "@mainBackground",
    },
    headerRightImage: {
        opacity: 0.2,
        filter: "grayscale(0.0)",
        url: "https://cdn.imaginarity.com/public/projectImages/imgContentHeaderRight.svg"
    },
    postItem: {
        backgroundColor: "fade('@accentBlack', 30%)",
        color: "@mainBackground",
    },
}


