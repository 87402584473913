import * as React from "react";
import { ApplicationState, useAppSelector } from "./ApplicationState/ApplicationState";
import { shallowCompare } from "./Helpers";

interface PageStateConnectionProps {

}

const mapper = (s: ApplicationState) => ({
    currentAppEntry: s?.currentAppEntry,
    // currentAppView: s?.currentAppView,
    // routeParams: s?.params,
    // currentVideo: s?.tubeState?.currentPost,
});

// fullstory stuff, full story

const FS: FSType = (window as any).FS;

interface FSType {
    setVars: (scope: "page", values: { pageName: string, [key: string]: string }) => void;
}
const PageStateConnection = (p: PageStateConnectionProps) => {
    const { currentAppEntry /*, currentAppView, routeParams, currentVideo*/ } = useAppSelector(mapper, shallowCompare);


    React.useEffect(() => {
        if (currentAppEntry) {
            //console.log('currentAppEntry', currentAppEntry);
            FS.setVars("page", { pageName: currentAppEntry.name });
        }
    }, [currentAppEntry]);

    // React.useEffect(() => {
    //     if (currentAppView)
    //         console.log('currentAppView', currentAppView);
    // }, [currentAppView]);

    // React.useEffect(() => {
    //     if (routeParams && Object.keys(routeParams).length !== 0)
    //         console.log('routeParams', routeParams);
    // }, [routeParams]);

    // React.useEffect(() => {
    //     if (currentVideo)
    //         console.log('currentVideo', currentVideo);
    // }, [currentVideo]);

    return <div style={{ width: 0, height: 0 }} />;
}

export default PageStateConnection;