import { TubePostDownloadDto } from 'collaboration-service';
import { Button, ButtonGroup, Flag, getColor, Icon, Image, Input, Modal, styled } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { createFullNamedRoute, getQrImageUriForNamedRoute } from 'services/Helpers/RoutingHelper';
import { getLanguageFlag, getTranslatedForTube } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { isAdminInGroup } from 'services/StoreDependantHelpers';
import { TubeSC as T } from './TubeSC';

interface TubeVideoInfoProps {
    mobileView: boolean;
    post: TubePostDownloadDto;
}

const Norm = styled.div`
    display: inline-block;
    margin-right: 5px;
`;

const Red = styled(Norm)`
    position: relative;
    color: ${p => getColor(p, "@accent")};
    font-weight: 900;
`;
const Error = styled.span`
    color: ${p => getColor(p, "@lightGrey")};
`;


const TubeVideoInfo = (p: TubeVideoInfoProps) => {
    const { mobileView, post } = p;
    const { contentLanguage, quality } = useSelector((s: ApplicationState) => ({ contentLanguage: s.contentLanguage, quality: s.tubeState.quality }), shallowCompare);
    const { t, currentLanguage } = useImgI18N("tube");
    const lng = contentLanguage ?? currentLanguage;
    const [showQr, setShowQr] = React.useState<boolean>(false);
    const [linkIsCopied, setLinkIsCopied] = React.useState<boolean>(false);

    const isAdmin = isAdminInGroup(post.group_id);

    const { quals, linkInternal, linkExternal, content, lngs, subLngs } = React.useMemo(() => {
        const content = getTranslatedForTube(post?.content, lng, [quality, "SD", "HD", "4K", "", undefined]);
        const quals = _.map(_.filter(post?.content, c => c.lng === content.lng), m => m.contentAttribute);
        const subLngs = _.uniq(_.map(content.content?.subtitles, s => s.lng));
        return {
            quals,
            linkInternal: "intlink",
            linkExternal: "extlink",
            content,
            lngs: _.uniq(_.map(post?.content, c => c.lng)),
            subLngs
        }
    }, [post, lng, quality])

    const copyLinkToClipboard = React.useMemo(() => (name: string) => () => {
        const copyText = document.getElementById(name) as HTMLInputElement;
        copyText.select();
        try {
            document.execCommand("copy");
        } catch {
            console.log("cannot copy link");
        }
    }, []);

    const copyLink = React.useMemo(() => () => {
        const textarea = document.createElement("textarea");
        textarea.textContent = createFullNamedRoute("open_post", { id: post.id });
        if (textarea.textContent != null)
            setLinkIsCopied(true);
        else
            setLinkIsCopied(false);
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        } finally {
            document.body.removeChild(textarea);
        }
    }, [post]);

    const toggleQr = React.useMemo(() => () => {
        setShowQr(!showQr);
    }, [showQr]);

    // console.log('post.content => ', post.content);
    // console.log('contentLanguage => ', contentLanguage);
    // console.log('quality => ', quality);
    // console.log('content => ', content);

    return (
        <T.TubeVideoGridLeftMoreInfo mobileView={mobileView}>
            <T.TubeVideoGridLeftMoreInfoTitle>{t("fileformat:")}</T.TubeVideoGridLeftMoreInfoTitle>
            <T.TubeVideoGridLeftMoreInfoContent>{content.useContent ? content.content.mediaType : "link"}</T.TubeVideoGridLeftMoreInfoContent>
            {!mobileView && <div />}
            <T.TubeVideoGridLeftMoreInfoTitle>{t("media language:")}</T.TubeVideoGridLeftMoreInfoTitle>
            <T.TubeVideoGridLeftMoreInfoContent>{_.map(lngs, l => {
                const f = getLanguageFlag(l)
                if (l === content.lng)
                    return (
                        <Red key={l}>
                            <Flag name={f} size={18} />
                            <Icon
                                color="@veryLightGrey"
                                name="eye open"
                                size={14}
                                style={{
                                    position: "absolute",
                                    top: -8,
                                    left: -6,
                                }}
                            />
                        </Red>
                    );
                return (
                    <Norm key={l}>
                        <Flag name={f} size={18} />
                    </Norm>
                )
            })}
            </T.TubeVideoGridLeftMoreInfoContent>

            {!mobileView && <div />}
            <T.TubeVideoGridLeftMoreInfoTitle>{t("visible:")}</T.TubeVideoGridLeftMoreInfoTitle>
            <T.TubeVideoGridLeftMoreInfoContent title={post.hidden ? t("no") : t("yes")}>
                {post.hidden ?
                    <Icon name='times' color='@accentRed' />
                    :
                    <Icon name='check' color='@veryLightGrey' />
                }
            </T.TubeVideoGridLeftMoreInfoContent>
            <T.TubeVideoGridLeftMoreInfoTitle>{t("resolutions:")}</T.TubeVideoGridLeftMoreInfoTitle>
            <T.TubeVideoGridLeftMoreInfoContent>
                {_.map(quals, q => q === quality ? <Red key={q}>{q}</Red> : <Norm key={q}>{q}</Norm>)}
            </T.TubeVideoGridLeftMoreInfoContent>
            {!mobileView && <div />}
            <T.TubeVideoGridLeftMoreInfoTitle>{t("subtitle language:")}</T.TubeVideoGridLeftMoreInfoTitle>
            <T.TubeVideoGridLeftMoreInfoContent>
                {
                    subLngs.length > 0 ?
                        _.map(_.filter(subLngs, s => s !== undefined), l => {
                            const f = getLanguageFlag(l!)
                            return (
                                <Flag key={f} name={f} size={18} />
                            );
                        })
                        :
                        <Error>{t("not available for {{lng}}", { lng: content.lng })}</Error>

                }
            </T.TubeVideoGridLeftMoreInfoContent>

            {!mobileView && <div />}
            {isAdmin &&
                <>
                    <T.TubeVideoGridLeftMoreInfoTitle>{t("generic link:")}</T.TubeVideoGridLeftMoreInfoTitle>
                    <T.TubeVideoGridLeftMoreInfoContent>
                        <ButtonGroup fluid>
                            <Button
                                kind={mobileView ? "secondary_menu" : "transparentButton"}
                                icon="link"
                                content={linkIsCopied ? t("link copied") : t("copy link")}
                                onClick={copyLink}
                                active={linkIsCopied}
                                tooltip={{
                                    tooltipText: linkIsCopied ? t("link is copied to clipboard") : t("copy link to clipboard")
                                }} />
                            <Button
                                kind={mobileView ? "secondary_menu" : "transparentButton"}
                                icon={showQr ? "qr code times" : "qr code"}
                                content="qr code"
                                onClick={toggleQr}
                                tooltip={{ tooltipText: t("generate qr code") }}
                            />
                        </ButtonGroup>
                    </T.TubeVideoGridLeftMoreInfoContent>
                    <Modal
                        active={showQr}
                        header={t("qr code")}
                        icon="qr code"
                        fixed={false}
                        footer={<>
                            <Button active={linkIsCopied} kind={"secondary"} floated="left" content={linkIsCopied ? t("link is copied to clipboard") : t("copy link to clipboard")} onClick={copyLink} icon={linkIsCopied ? "check" : "link"} />
                            <Button kind="primary" floated="right" content={t("close")} onClick={toggleQr} icon="times" />
                        </>
                        }
                        width="small"
                        height="large"
                        shadow
                    >
                        <Image src={getQrImageUriForNamedRoute("open_post", { id: post.id })} thumbnail width='100%' />
                        <T.TubeVideoQrContainerLink onClick={copyLink}>
                            {createFullNamedRoute("open_post", { id: post.id })}
                        </T.TubeVideoQrContainerLink>
                    </Modal>
                </>
            }
            {/* <T.TubeVideoGridLeftMoreInfoTitle>{t("copy video link:")}</T.TubeVideoGridLeftMoreInfoTitle> */}

            {/* HIDDEN - BECAUSE INTERNAL AND EXTERNAL LINKS ARE NOT SET !!! */}
            {linkInternal !== "intlink" && linkExternal !== "extlink" &&
                <>
                    <T.TubeVideoGridLeftMoreInfoLink
                        onClick={copyLinkToClipboard("joinLinkInt")}
                        mobileView={mobileView}
                    >
                        <T.TubeVideoGridLeftMoreInfoLinkIcon title={t("internal link")}>
                            <a href={linkInternal} target="_blank" rel="noreferrer">
                                <Button
                                    kind="transparentButtonDark"
                                    icon="link internal"
                                />
                            </a>
                        </T.TubeVideoGridLeftMoreInfoLinkIcon>
                        <Input
                            id="joinLinkInt"
                            value={linkInternal}
                            width="100%"
                            kind="borderlessDark"
                            title={t("internal link")}
                        />
                        <Button
                            icon="copy"
                            kind="primary"
                            onClick={copyLinkToClipboard("joinLinkInt")}
                            tooltip={{ tooltipText: t("copy internal link to clipboard"), position: "left" }}
                        />
                    </T.TubeVideoGridLeftMoreInfoLink>
                    <T.TubeVideoGridLeftMoreInfoLink
                        onClick={copyLinkToClipboard("joinLinkExt")}
                        mobileView={mobileView}
                        second={true}
                    >
                        <T.TubeVideoGridLeftMoreInfoLinkIcon title={t("external link")}>
                            <a href={linkExternal} target="_blank" rel="noreferrer">
                                <Button
                                    kind="transparentButtonDark"
                                    icon="link external"
                                />
                            </a>
                        </T.TubeVideoGridLeftMoreInfoLinkIcon>
                        <Input
                            id="joinLinkExt"
                            value={linkExternal}
                            width="100%"
                            kind="borderlessDark"
                            title={t("external link")}
                        />
                        <Button
                            icon="copy"
                            kind="primary"
                            onClick={copyLinkToClipboard("joinLinkExt")}
                            tooltip={{ tooltipText: t("copy external link to clipboard"), position: "left" }}
                        />
                    </T.TubeVideoGridLeftMoreInfoLink>
                </>
            }
        </T.TubeVideoGridLeftMoreInfo>);
}
export default TubeVideoInfo;