import { fadeColor, styled } from "imaginarity-react-ui";

export const SelectElementContainerWithCount = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 5px;
`

export const SelectCount = styled.div`
    font-size: 0.85em;
    text-align: right;
    color: ${p => fadeColor(p, "color", 70)};
`;