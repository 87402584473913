import { PlaylistItemStateEnum } from "collaboration-service";
import { useImgI18N } from "services/ImgI18N";

export type documentKinds = "download" | "image" | "video" | "document" | "wbt" | "audio" | "wiki" | "bestpractice" | "quiz" | "feedpost" | "pdf";

type PlaylistStartItemButtonString = {
    start: string;
    continue: string;
    again: string;
}

const playlistStartItemButtonStrings: { [kind in documentKinds]: PlaylistStartItemButtonString } = {
    "download": {
        again: "download document again",
        continue: "download document again",
        start: "download document",
    },
    "image": {
        again: "view image again",
        continue: "continue viewing image",
        start: "view image",
    },
    "video": {
        again: "watch video again",
        continue: "continue watching video",
        start: "watch video",
    },
    "document": {
        again: "download document again",
        continue: "download document again",
        start: "download document",
    },
    "wbt": {
        again: "do training again",
        continue: "continue training",
        start: "start training",
    },
    "audio": {
        again: "listen to podcast again",
        continue: "continue listening podcast",
        start: "start listening podcast",
    },
    "wiki": {
        "again": "read article again",
        "continue": "continue reading article",
        "start": "open article"
    },
    "bestpractice": {
        "again": "read article again",
        "continue": "continue reading article",
        "start": "open article"
    },
    "quiz": {
        "again": "start quiz again",
        "continue": "continue quiz",
        "start": "start quiz"
    },
    "feedpost": {
        "again": "read post again",
        "continue": "continue reading post",
        "start": "read post"
    },
    "pdf": {
        again: "view pdf again",
        continue: "continue viewing pdf",
        start: "view pdf",
    },

};

export const usePlaylistButtonText = (kind: documentKinds, itemState: PlaylistItemStateEnum, namespace?: string) => {
    const { t } = useImgI18N(namespace ?? "playlist");
    const strings = playlistStartItemButtonStrings[kind] ?? playlistStartItemButtonStrings["download"];
    switch (itemState) {
        case 'Finished':
            return t(strings["again"]);
        case 'Started':
            return t(strings["continue"]);
        default:
            return t(strings["start"]);
    }
}



export default usePlaylistButtonText;