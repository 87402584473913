import { BestPracticePostDownloadDto, CategoryDownloadDto, ChatDownloadDto, ChatMessageDownloadDto, CMSController, ConfigurationsWithCategories, ContentController, ContentPostContentLocationDownloadDto, ContentPostDownloadDto, ControllerHelper, DashboardDownloadDto, DashboardElementDownloadDto, DashboardElementTemplateDownloadDto, ExtendedDataCollection, FacetResult, FetchPostsFilter, FileEntriesDownloadDto, FileEntryDownloadDto, GroupDownloadDto, InAppMessageDownloadDto, ItemSearchResult, PALMSFilter, PlaylistController, PlaylistStateDownloadDto, PodcastPostDownloadDto, PostController, PostDownloadDto, ProjectGroupDownloadDto, ProjectStructureTemplateDownloadDto, QuizDownloadDto, SearchSystems, SlideDownloadDto, SlideShowDownloadDto, SlideTemplateDownloadDto, TaskController, TranslationBatchController, TranslationBatchDownloadDto, TubePostDownloadDto, UserDownloadDto, UserShortInfoDownloadDto, UserTaskDownloadDto, ViewStats, WikiArticleDownloadDto, WikiCategoryDownloadDto, WikiController, WikiSearchResultDto, WorkflowController, WorkflowDownloadDto, ZipUploadInProgress } from "collaboration-service";
import { ElementNode } from "collaboration-service/dist/dtos/ElementNode";
import { FileEntrySearchResultDto } from "collaboration-service/dist/dtos/FileEntrySearchResultDto";
import { ProjectGroupSearch } from "collaboration-service/dist/dtos/ProjectGroupSearch";
import { SearchResultsDto } from "collaboration-service/dist/dtos/SearchResultsDto";
import * as _ from "lodash";
import { Layouts } from "react-grid-layout";
import { AnyAction, Dispatch } from "redux";
import { getFrontEndSettingFromState, mapExtendedDataCollection, setFrontEndSetting, visitGroups } from "services/Helpers";
import { RouteParams } from "universal-router";
import { cmsConfig } from "views/CMS/CMSConfig";
import { createFetchPostFilter, FeedSearchProps } from "views/Feed/FeedFilter";
import { SortingBy } from "views/MyHome/MyHomeSettings";
import { LngSlides, SlideBgOption } from "views/SlideShow/SlideshowSettings";
import { LastSearch } from "views/UseSearchFeed/UseSearchFeed";
import { ActionResult, ApplicationState, BestPracticeFilterState, ContentFilterTypes, EditTab, Ordering, PlaylistFilter, PlaylistFilterCount, PlaylistStateMissing, ProjectsFilesCount } from "./ApplicationState";
import { PluginAction, PluginActions } from "./PluginStateHandling";
import { TokenOperation } from "collaboration-service/dist/dtos/TokenOperation";

export type PostsWithToken<T> = { posts: T[], token?: string };

export type ApplicationActions =
    | { type: "SET_FOCUS", value: boolean }
    | { type: "CURRENT_USER_RECEIVED", value: UserDownloadDto }
    | { type: "EXECUTE", value: () => void }
    | { type: "SET_LOCATION_SEARCH", value?: string }
    | { type: "REQUEST_UPDATE_RECEIVED", value: boolean }
    | { type: "SET_ROUTE_AND_PARAMS_AND_RENDERER", value: { route: string, params: RouteParams | undefined, user?: UserDownloadDto, doLogin?: boolean, noLogin?: boolean, search?: string } }
    | { type: "REMOVE_CURRENT_USER" }
    | { type: "SET_CONTENT_LANGUAGE", value?: string }

    | { type: "SET_CMS_RIGHT_SIDEBAR", value?: boolean }

    | { type: "SET_SIDE_CONTENT_RENDERERS", leftContentRenderers: ApplicationState["leftContentRenderers"], rightContentRenderers: ApplicationState["rightContentRenderers"] }

    | { type: "SET_SEARCH_RESULT_POS", value?: number }
    | { type: "SET_SEARCH_RESULT_COUNT", value?: string[] }
    | { type: "SET_CURRENT_GROUP", value?: GroupDownloadDto }
    | { type: "ADD_SEARCH_RESULT_IDS", value?: string[] }

    | { type: "UPDATE_POST", value: PostDownloadDto }
    | { type: "REMOVE_POST", value: string }
    | { type: "CALCULATE_FILTERED_APP_ENTRIES" }
    | { type: "SET_USER_TASKS", value?: PostsWithToken<UserTaskDownloadDto> }

    | { type: "SET_MASTER_LAYOUT_EXTENSION", value?: () => JSX.Element }

    | { type: "SET_NEED_SAVE", value?: boolean }
    | { type: "SET_CAN_SAVE", value?: boolean }
    | { type: "SET_ACTIVE", value?: boolean }
    | { type: "SET_SAVE", value?: () => void }
    | { type: "SET_CANCEL", value?: () => void }
    | { type: "SET_SAVE_AND_CANCEL", value?: { save?: () => void, cancel?: () => void } }
    | { type: "SET_SCROLLTO", value?: (id: string, delay?: number) => void }
    | { type: "SET_ACTION", value?: () => void }
    | { type: "SET_ADD", value?: () => void }

    | { type: "SET_WIKI_GROUP_ID", value?: string }
    | { type: "SET_WIKI_CATEGORIES", value?: WikiCategoryDownloadDto[] }
    | { type: "SET_WIKI_CATEGORY", value?: WikiCategoryDownloadDto }
    | { type: "SET_WIKI_ARTICLE", value?: { wikiArticle?: WikiArticleDownloadDto, editMode?: boolean } }
    //SET_WIKI_SCROLL_TO: 1004,
    | { type: "SET_WIKI_SEARCH", value?: string }
    | { type: "SET_WIKI_NEWS_IN_EDIT", value?: boolean }
    | { type: "SET_WIKI_FOCUS_TARGET", value?: string }
    | { type: "SET_WIKI_SAVE_ARTICLE", value?: (update: boolean) => void }
    | { type: "SET_WIKI_PLS_FOR_ARTICLE", value?: PlaylistStateDownloadDto[] }
    | { type: "SET_WIKI_ARTICLE_BREADCRUMBS", value?: WikiCategoryDownloadDto[][] }
    | { type: "SET_WIKI_CATEGORY_ID", value?: string }
    | { type: "TOGGLE_WIKI_SHOWN_REFERENCES", value: string }
    | { type: "SET_WIKI_SET_SHOW_INTRO", value?: boolean }
    | { type: "SET_WIKI_SAVEABLE", value?: boolean }
    | { type: "SET_WIKI_NEWS_CLOSE", value: () => void }
    | { type: "SET_WIKI_NEWS_DELETE", value?: () => void }
    | { type: "SET_WIKI_NEWS_SAVE", value?: () => void }
    | { type: "SET_WIKI_REFERENCE_LNG", value?: string }

    | { type: "SET_TUBE_CURRENT_POST", value?: TubePostDownloadDto }
    | { type: "SET_TUBE_CURRENT_CATEGORY_STACK", value?: ElementNode<CategoryDownloadDto>[] }
    | { type: "SET_TUBE_CATEGORIES", value?: CategoryDownloadDto[] }
    //SET_TUBE_SCROLL_TO: 2005,
    | { type: "SET_TUBE_VIEW_STATS", value: ViewStats[] }
    | { type: "ADD_TUBE_VIEW_STATS", value: ViewStats[] }
    | { type: "SET_TUBE_SEARCH_RESET", value?: () => void }
    | { type: "SET_TUBE_CURRENT_POSTS", value?: TubePostDownloadDto[] }
    | { type: "SET_TUBE_FILTER", value?: Partial<FetchPostsFilter> }
    | { type: "SET_TUBE_VIEW_MODE", value?: string }
    | { type: "SET_TUBE_SEARCH", value?: string }
    | { type: "SET_TUBE_FOCUS_TARGET", value?: string }
    | { type: "SET_TUBE_TABLEVIEW", value?: boolean }
    | { type: "SET_TUBE_QUALITY", value: "SD" | "HD" | "4K" }

    | { type: "SET_PODCAST_CURRENT_POST", value?: PodcastPostDownloadDto }
    | { type: "SET_PODCAST_CURRENT_CATEGORY_STACK", value?: ElementNode<CategoryDownloadDto>[] }
    | { type: "SET_PODCAST_CATEGORIES", value?: CategoryDownloadDto[] }
    //SET_TUBE_SCROLL_TO: 2005,
    | { type: "SET_PODCAST_VIEW_STATS", value: ViewStats[] }
    | { type: "ADD_PODCAST_VIEW_STATS", value: ViewStats[] }
    | { type: "SET_PODCAST_SEARCH_RESET", value?: () => void }
    | { type: "SET_PODCAST_CURRENT_POSTS", value?: PodcastPostDownloadDto[] }
    | { type: "SET_PODCAST_FILTER", value?: Partial<FetchPostsFilter> }
    | { type: "SET_PODCAST_VIEW_MODE", value?: string }
    | { type: "SET_PODCAST_SEARCH", value?: string }
    | { type: "SET_PODCAST_FOCUS_TARGET", value?: string }
    | { type: "SET_PODCAST_TABLEVIEW", value?: boolean }
    | { type: "SET_PODCAST_QUALITY", value: "SD" | "HD" | "4K" }


    | { type: "SET_PL_PLAYLIST_STATES", value?: PlaylistStateDownloadDto[] }
    | { type: "SET_PL_PLAYLIST_STATES_TOPIC", value?: PlaylistStateDownloadDto[], when?: Date }
    | { type: "SET_PL_EDIT_MODE", value?: boolean }
    | { type: "SET_PL_SAVE", value?: (save: boolean) => void }
    | { type: "SET_PL_MISSING", value?: PlaylistStateMissing }
    | { type: "SET_PLAYLIST_RIGHT_SIDEBAR", value?: boolean }
    | { type: "SET_PLAYLIST_LEFT_SIDEBAR", value?: boolean }
    | { type: "SET_PLAYLIST_FILTER", value?: PlaylistFilter }
    | { type: "SET_PLAYLIST_FILTER_COUNT", value?: PlaylistFilterCount }
    | { type: "SET_PLAYLIST_ORDERING", value?: Ordering }

    | { type: "SET_SS_SLIDESHOW", value?: SlideShowDownloadDto }
    | { type: "SET_SS_LNG_SLIDES", value?: LngSlides }
    | { type: "SET_SS_SLIDE", value?: SlideDownloadDto }
    | { type: "SET_SS_TEMPLATES", value?: SlideTemplateDownloadDto[] }
    | { type: "SET_SS_SELECT_TEMPLATE", value?: boolean }
    | { type: "SET_SS_BACKGROUNDS", value?: SlideBgOption[] }
    | { type: "SET_SS_COPY", value?: () => void }
    | { type: "SET_SS_IMPORT_AS", value?: () => void }
    | { type: "SET_SS_UPDATE_SLIDE", value?: (slide: SlideDownloadDto, slideMedia: { [key: string]: File }) => void }

    | { type: "SET_BEST_POST", value?: BestPracticePostDownloadDto }
    | { type: "SET_BEST_REPORT_IMP", value?: () => void }
    | { type: "SET_BEST_EDIT", value?: boolean }
    | { type: "SET_BEST_ORDERING", value?: "date" | "trending" | "invisible" | "searched" | "own" }
    | { type: "SET_BEST_FILTER", value?: BestPracticeFilterState }
    | { type: "SET_BEST_POSTS", value?: PostsWithToken<BestPracticePostDownloadDto> }
    | { type: "SET_BEST_SEARCH_TERM", value?: string }
    | { type: "SET_BEST_CATS_AND_LOCS", value?: { categories?: CategoryDownloadDto[], locations?: ContentPostContentLocationDownloadDto[] } }
    | { type: "SET_BEST_OWN_POSTS", value?: PostsWithToken<BestPracticePostDownloadDto> }
    //SET_BEST_SCROLL_TO: 5010,
    | { type: "SET_BEST_FOCUS_TARGET", value?: string }
    | { type: "REMOVE_BEST_POST", value: string }
    | { type: "SET_BEST_FACETS", value?: { [key: string]: FacetResult[] } }
    | { type: "SET_BEST_RIGHT_SIDEBAR", value?: boolean }

    | { type: "SET_THE_PAGE_EDITMODE", value?: boolean }
    | { type: "SET_THE_PAGE_DB_TEMPLATES", value?: DashboardElementTemplateDownloadDto[] }
    | { type: "SET_THE_PAGE_DASHBOARDS", value?: DashboardDownloadDto[] }
    | { type: "SET_THE_PAGE_SEL_DASHBOARD", value: DashboardDownloadDto }
    | { type: "SET_THE_PAGE_EDIT_LAYOUTS", value?: Layouts }
    | { type: "SET_THE_PAGE_EDIT_DB", value?: DashboardDownloadDto }
    | { type: "SET_THE_PAGE_ADD_ELEMENT", value?: boolean }
    | { type: "SET_THE_PAGE_ADD_TEMP_ELEMENT", value: { mouseEvent: { clientX: number, clientY: number }, template: DashboardElementTemplateDownloadDto } }
    | { type: "SET_THE_PAGE_CLEAR_TEMP_ELEMENTS" }
    | { type: "SET_THE_PAGE_FINALIZE_TEMP_ELEMENT", value: DashboardElementTemplateDownloadDto }
    | { type: "DELETE_ELEMENT_FROM_THE_PAGE_EDIT_DASHBOARD", value: DashboardElementDownloadDto }
    | { type: "SET_THE_PAGE_PALMS_FILTER", value?: PALMSFilter }
    | { type: "SET_THE_PAGE_DETAILS_ID", value?: { id?: string, filter?: PALMSFilter } }

    | { type: "SET_CHAT_ADD", value?: boolean }
    | { type: "SET_CHAT", value?: ChatDownloadDto }
    | { type: "SET_CHAT_MEMBERS", value?: UserShortInfoDownloadDto[] }
    | { type: "SET_CHAT_UPDATE_INFO_CHAT", value?: boolean }
    | { type: "SET_CHAT_UPDATE_INFO_CHATS", value?: boolean }
    | { type: "SET_CHATS", value?: ChatDownloadDto[] }
    | { type: "SET_CHAT_UPDATE_MESSAGES", value?: ChatMessageDownloadDto[] }

    | { type: "SET_FEED_TRENDING", value?: boolean }
    | { type: "SET_FEED_SEARCH_PROPS", value?: FeedSearchProps }
    | { type: "SET_FEED_SAVE", value?: (save?: boolean) => void }
    | { type: "SET_FEED_PREV_AND_NEXT", prev?: () => void, next?: () => void }
    | { type: "SET_FEED_SELECTED_TAB", value?: EditTab }
    | { type: "SET_FEED_SHOW", value?: "posts" | "bookmarks" }
    | { type: "SET_FEED_FACETS", value?: { [key: string]: FacetResult[] } }
    | { type: "SET_FEED_RIGHT_SIDEBAR", value?: boolean }



    | { type: "SET_CONTENT_CURRENT_POST", value?: ContentPostDownloadDto }
    | { type: "SET_CONTENT_CURRENT_CATEGORY_STACK", value?: ElementNode<CategoryDownloadDto>[] }
    | { type: "SET_CONTENT_CATEGORIES", value?: CategoryDownloadDto[] }
    //SET_CONTENT_SCROLL_TO: 9005,
    | { type: "SET_CONTENT_VIEW_STATS", value: ViewStats[] }
    | { type: "ADD_CONTENT_VIEW_STATS", value: ViewStats[] }
    | { type: "SET_CONTENT_SEARCH_RESET", value?: () => void }
    | { type: "SET_CONTENT_CURRENT_POSTS", value?: ContentPostDownloadDto[] }
    | { type: "SET_CONTENT_FILTER", value?: Partial<FetchPostsFilter> }
    | { type: "SET_CONTENT_VIEW_MODE", value?: "trending" | "date" | "alpha" }
    | { type: "SET_CONTENT_SEARCH", value?: string }
    | { type: "SET_CONTENT_FILTER_TYPE", value?: ContentFilterTypes }

    | { type: "SET_QUIZ_CURRENT_POST", value?: QuizDownloadDto }
    | { type: "SET_QUIZ_CURRENT_CATEGORY_STACK", value?: ElementNode<CategoryDownloadDto>[] }
    | { type: "SET_QUIZ_CATEGORIES", value?: CategoryDownloadDto[] }
    //SET_QUIZ_SCROLL_TO: 10005,
    | { type: "SET_QUIZ_VIEW_STATS", value: ViewStats[] }
    | { type: "ADD_QUIZ_VIEW_STATS", value: ViewStats[] }
    | { type: "SET_QUIZ_SEARCH_RESET", value?: () => void }
    | { type: "SET_QUIZ_CURRENT_POSTS", value?: QuizDownloadDto[] }
    | { type: "SET_QUIZ_FILTER", value?: Partial<FetchPostsFilter> }
    | { type: "SET_QUIZ_VIEW_MODE", value?: "date" | "alpha" | "trending" }

    //SET_GLOBAL_SCROLL_TO: 11001,
    | { type: "SET_GLOBAL_VIEW_STATS", value: ViewStats[] }
    | { type: "ADD_GLOBAL_VIEW_STATS", value: ViewStats[] }
    | { type: "SET_GLOBAL_SEARCH", value?: string }
    | { type: "ADD_GLOBAL_SEARCH_RESULTS", value: Array<ItemSearchResult<PostDownloadDto> | WikiSearchResultDto> }
    | { type: "CLEAR_GLOBAL_SEARCH_RESULTS" }
    | { type: "SET_GLOBAL_SEARCH_LAST_SEARCH", value?: LastSearch }
    | { type: "SET_GLOBAL_SEARCH_SET_FILTER", value?: Partial<FetchPostsFilter> }
    | { type: "SET_GLOBAL_SEARCH_SET_SYSTEMS", value: SearchSystems }

    | { type: "SET_CMS_LOADING_PROJECT_FAILED", value?: boolean }
    | { type: "SET_CMS_LOADING_FILES_FAILED", value?: boolean }
    | { type: "SET_CMS_CURRENT_PROJECT_AND_WFS", value: { project: ProjectGroupDownloadDto | undefined, workflows: WorkflowDownloadDto[] } }
    | { type: "SET_CMS_TABLE_VIEW", value?: boolean }
    | { type: "SET_CMS_TEMPLATE", value?: ProjectStructureTemplateDownloadDto }
    | { type: "SET_CMS_PROJECTS", value?: ExtendedDataCollection<ProjectGroupDownloadDto, string> }
    | { type: "SET_CMS_FILES", value?: { [path: string]: FileEntryDownloadDto[] } }
    | { type: "SET_CMS_LOCATIONS", value?: ContentPostContentLocationDownloadDto[] }
    | { type: "SET_CMS_LOCATION", value?: ContentPostContentLocationDownloadDto }
    | { type: "UPDATE_CMS_FILE", value: FileEntryDownloadDto }
    | { type: "REMOVE_CMS_FILE", value: FileEntryDownloadDto }
    | { type: "ADD_CMS_FILE_POSTS", value: { id: string, posts?: PostDownloadDto[] } }
    | { type: "CLEAR_CMS_SEARCH_RESULT_ENTRIES" }
    | { type: "SET_CMS_SEARCH_RESULTS_FILES", value?: SearchResultsDto<FileEntrySearchResultDto> }
    | { type: "SET_CMS_SEARCH_RESULTS_PROJECTS", value?: SearchResultsDto<ProjectGroupSearch> }
    | { type: "SET_CMS_CATEGORIES", value?: CategoryDownloadDto[] }
    | { type: "SET_CMS_SHOW_ARCHIVE", value?: boolean }
    | { type: "SET_CMS_SHOW_UPLOAD", value?: boolean }
    | { type: "SET_CMS_WORKFLOW_TYPE_IS_GROUP", value: boolean }
    | { type: "SET_CMS_CONTENT_SEARCH_RESULTS_FILES", value?: SearchResultsDto<FileEntrySearchResultDto> }
    | { type: "SET_CMS_CONTENT_SEARCH_RESULTS_PROJECTS", value?: SearchResultsDto<ProjectGroupSearch> }
    | { type: "SET_CMS_SHOW_TASKS", value?: boolean }
    | { type: "SET_CMS_SHOW_ADD_REFERENCE", value?: boolean }
    | { type: "SET_CMS_FILE_TO_RENAME", value?: FileEntryDownloadDto }
    | { type: "SET_CMS_FILE_TO_MOVE", value?: FileEntryDownloadDto[] }
    | { type: "SET_CMS_FOLDER_TO_RENAME", value?: string }
    | { type: "SET_CMS_SHOW_PROJECT_INFO", value?: boolean }
    | { type: "SET_CMS_SHOW_DOWNLOAD_INFO", value?: boolean }
    | { type: "SET_CMS_FILTER_TERM", value?: string }
    | { type: "SET_CMS_DOWNLOADLINK", value?: string }
    //    |{type: "SET_CMS_UPDATE_FILE", value: 120034}
    | { type: "SET_CMS_CONTENT_PROJECTS", value?: ProjectGroupDownloadDto[] }
    | { type: "SET_CMS_TRANSLATION_BATCHES", value?: TranslationBatchDownloadDto[] }
    | { type: "SET_CMS_ZIP_PROGRESS", value?: ZipUploadInProgress[] }
    | { type: "SET_CMS_DIRECTORY_AND_FILE_ACTIONS_DISABLED", value?: boolean }
    | { type: "SET_CMS_FILES_GRID_SELECTED_ROWS", value: Array<FileEntryDownloadDto | FileEntriesDownloadDto> }
    | { type: "SET_CMS_CATEGORY_CONFIGURATION", value?: ConfigurationsWithCategories }
    | { type: "SET_CMS_ACTION_RESULT", value?: ActionResult }
    | { type: "ADD_CMS_PROJECTS_FILES_COUNT", value?: ProjectsFilesCount }

    | { type: "ADMIN_SET_GROUP", value?: GroupDownloadDto }
    | { type: "ADMIN_SET_LEFT_SIDEBAR", value?: boolean }
    | { type: "ADMIN_SET_RIGHT_SIDEBAR", value?: boolean }

    | { type: "SET_QANDA_REPLY", value?: () => void }
    | { type: "SET_QANDA_EDIT", value?: () => void }
    | { type: "SET_QANDA_EDIT_FORUM", value?: () => void }

    | { type: "SET_SHOW_FILTER_ON_LOADINGDATATABLE", value?: boolean }

    | { type: "SET_SHOW_MOBILE_MENU", value?: boolean }
    | { type: "SET_LOGOUT_CONFIRMED", value?: boolean }

    | { type: "SET_TOP_INBOX_MESSAGES", value?: InAppMessageDownloadDto[] }
    | { type: "SET_INBOX_MESSAGES", value?: InAppMessageDownloadDto[] }

    | { type: "SET_MYHOME_SHOWDETAILED_PAGE", value: boolean }
    | { type: "SET_MYHOME_SORTED_BY", value: SortingBy }
    | { type: "SET_MYHOME_SORT_ASCENDING", value: boolean }
    | { type: "SET_MYHOME_SHOW_PROGRESS_ONTRACK", value: boolean }
    | { type: "SET_MYHOME_SHOW_PROGRESS_INCOMPLETE", value: boolean }
    | { type: "SET_MYHOME_SHOW_PROGRESS_OVERDUE", value: boolean }
    | { type: "SET_MYHOME_SHOW_PROGRESS_COMPLETED", value: boolean }

    | { type: "SET_MYHOME_SET_LEFT_SIDEBAR", value?: boolean }
    | { type: "SET_MYHOME_SET_RIGHT_SIDEBAR", value?: boolean }
    ;






export type ApplicationAction = ApplicationActions | PluginAction;


export const WorkingActions = {
    changeGroupToAdminGroupByFrontEndSettingByType(groupType: string[], newGroup?: GroupDownloadDto) {
        return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
            //console.log("Actions.tsx::240 => groupType", groupType);

            const s = getState();
            const feName = "adminGroup:" + _.join(_.sortBy(groupType, g => g), "--");
            const groups = s.adminGroups ?? [];
            //console.log("Actions.tsx::247 => groups", groups);

            const agId = getFrontEndSettingFromState<string>(s.user, feName);

            const findAdminGroup = (id?: string) => {
                let g: GroupDownloadDto | undefined = undefined;
                visitGroups(groups, a => {
                    if ((id === undefined || a.id === id) && (groupType.length === 0 || _.find(groupType, t => a.groupType === t) !== undefined)) {
                        g = a;
                        return false;
                    }
                    return true;
                });
                return g;
            };

            let groupToSet: GroupDownloadDto | undefined = undefined;
            if (newGroup && findAdminGroup(newGroup.id))
                groupToSet = newGroup;
            else if (agId)
                groupToSet = findAdminGroup(agId);
            else
                groupToSet = findAdminGroup();

            // console.log("Actions.tsx::253 => agId", agId);
            // console.log("Actions.tsx::261 => groupToSet", groupToSet);
            // console.log("Actions.tsx::262 => s.currentGroup", s.adminState.group);
            // console.log("Actions.tsx::263 => newGroup", newGroup);



            if (groupToSet?.id !== s.adminState.group?.id) {
                //console.log("Actions.tsx::266 => set AdminGroup to groupToSet", groupToSet);

                dispatch(Actions.adminSetGroup(groupToSet));
            }

            if (agId !== groupToSet?.id) {
                //console.log("set front end settings...");
                setFrontEndSetting(s.user, feName, v => groupToSet?.id);
            }

        }
    },
    loadUserTasks(reload?: boolean) {
        return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
            const state = getState();
            if (reload) {
                const tasks = await ControllerHelper.singleCall({ count: 5, token: { token: "" } }, TaskController.Get, true);
                dispatch(Actions.setUserTasks({ posts: tasks.elements, token: tasks.data }));
            } else {
                if (state.userTasks.token !== "" && state.userTasks.token !== undefined && state.userTasks.token !== null) {
                    const tasks = await ControllerHelper.singleCall({ count: 5, token: { token: state.userTasks.token } }, TaskController.Get, true);
                    dispatch(Actions.setUserTasks({ posts: tasks.elements, token: tasks.data }));
                }
            }
        }
    },
    CMS: {
        loadProjects(reload?: boolean) {
            return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {

                const s = getState();
                const token = s.cmsState.projects?.data;

                const [posts, cats, configs] = await ControllerHelper
                    .addCall({ token: { token } }, CMSController.GetProjectGroups)
                    .addCall({ grouptype: 'CMS' }, ContentController.GetCategoriesForAllGroupsByGroupType, true)
                    .addCall({ grouptype: "CMS" }, ContentController.GetCategoriesForAllGroupsByGroupTypeWithConfigurations, true)
                    .execute<ExtendedDataCollection<ProjectGroupDownloadDto, string>, CategoryDownloadDto[], ConfigurationsWithCategories>();
                const actions: AnyAction[] = [Actions.setCMSCategories(cats), Actions.setCMSCategoryConfiguration(configs)];
                if (posts) {
                    if (token === null || token === "" || token === undefined)
                        actions.push(Actions.setCMSProjects(mapExtendedDataCollection<ProjectGroupDownloadDto, PostDownloadDto>(posts)));
                    else
                        actions.push(Actions.setCMSProjects({ elements: [...(s.cmsState.projects?.elements ?? []), ...(posts.elements as any as ProjectGroupDownloadDto[])], data: posts.data, links: [] }));
                }

                dispatch(PluginActions.batchActions(actions));
            }
        },
        loadFiles(id: string, token?: string) {
            return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
                const s = getState();
                const path = s.params?.path as string[];
                const pStr = _.join(path, "/");
                const res = await ControllerHelper.singleCall({ projectGroupId: id, path, token: { token } }, CMSController.GetFilesPartial, true);

                const cur = s.cmsState.currentFiles ?? {};
                const files = token ?
                    { ...cur, [pStr]: cur[pStr] ?? [] } :
                    { ...cur, [pStr]: [] };

                //token ? (s.cmsState.currentFiles ?? { [pStr]: [] }) : { [pStr]: [] };
                //console.log("Actions.tsx::251 => files", files);

                if (res) {
                    dispatch(PluginActions.batchActions([
                        Actions.setCMSFiles({ ...files, [pStr]: [...files[pStr], ...res.elements] }),
                        Actions.setCMSLoadingFilesFailed(undefined)
                    ]));
                    if (res.data !== undefined && res.data !== "" && res.data !== null)
                        dispatch(WorkingActions.CMS.loadFiles(id, res.data));
                }
                else {
                    dispatch(Actions.setCMSLoadingFilesFailed(true));
                }

            };
        },
        setProjectWithoutData(proj?: ProjectGroupDownloadDto) {
            return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
                const actions: AnyAction[] = [];
                if (proj) {
                    const cmsState = getState().cmsState;
                    if (cmsState.currentProject?.id !== proj.id) {
                        actions.push(Actions.setCMSCurrentProjectAndWorkflows(proj as any as ProjectGroupDownloadDto, []));
                        actions.push(Actions.setCMSFiles(undefined));
                        actions.push(Actions.setCMSLoadingFilesFailed(undefined));
                        actions.push(Actions.setCMSLocations(undefined));
                        actions.push(Actions.setCMSCategories(undefined));
                        dispatch(PluginActions.batchActions(actions));
                    }

                } else {
                    actions.push(Actions.setCMSCurrentProjectAndWorkflows(undefined, []));
                    actions.push(Actions.setCMSFiles(undefined));
                    actions.push(Actions.setCMSLoadingFilesFailed(undefined));
                    actions.push(Actions.setCMSLocations(undefined));
                    actions.push(Actions.setCMSCategories(undefined));
                    dispatch(PluginActions.batchActions(actions));
                }
            }
        },
        loadProject(id?: string, reload?: boolean) {
            return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
                // console.log("Actions.tsx::272 => id", id);
                const s = getState();
                dispatch(Actions.setCMSLoadingProjectFailed(undefined));
                const cmsState = s.cmsState;
                const path = s.params?.path as string[];
                const pStr = _.join(path, "/");
                const actions: AnyAction[] = [];
                if (reload || cmsState.currentProject?.id !== id || cmsState.currentFiles === undefined) {
                    if (id) {
                        const [proj, wfs, tbs, zipP] = await ControllerHelper
                            .addCall({ id }, PostController.GetSinglePost, true)
                            .addCall({ groupId: id }, WorkflowController.GetGroupWorkflows, true)
                            .addCall({ groupId: id, context: "" }, TranslationBatchController.GetTranslationBatches, true)
                            .addCall({ projectGroupId: id, path }, CMSController.GetZipProgresses, true)
                            .execute<
                                PostDownloadDto,
                                ExtendedDataCollection<WorkflowDownloadDto, string>,
                                TranslationBatchDownloadDto[],
                                ZipUploadInProgress[]>();
                        if (proj) {
                            actions.push(Actions.setCMSCurrentProjectAndWorkflows(proj as any as ProjectGroupDownloadDto, wfs?.elements ?? []));
                            actions.push(Actions.setCMSFiles(undefined));
                            actions.push(Actions.setCMSLoadingFilesFailed(undefined));
                            actions.push(Actions.setCMSZipProgresses(zipP));
                            if (cmsConfig.showBatches)
                                actions.push(Actions.setCMSTranslationBatches(tbs));
                            dispatch(PluginActions.batchActions(actions));
                            //dispatch(WorkingActions.CMS.loadFiles(proj.id));

                            const [locs, cats, configs] = await ControllerHelper
                                .addCall({ groupid: proj.group_id }, ContentController.GetContentPostContentLocations, true)
                                .addCall({ groupid: proj.group_id }, ContentController.GetCategories, true)
                                .addCall({ grouptype: "CMS" }, ContentController.GetCategoriesForAllGroupsByGroupTypeWithConfigurations, true)
                                .execute<ContentPostContentLocationDownloadDto[], CategoryDownloadDto[], ConfigurationsWithCategories>();
                            const actionsLater: AnyAction[] = [];
                            actionsLater.push(Actions.setCMSLocations(locs));
                            actionsLater.push(Actions.setCMSCategories(cats));
                            actionsLater.push(Actions.setCMSCategoryConfiguration(configs));
                            dispatch(WorkingActions.CMS.loadFiles(id));
                            dispatch(PluginActions.batchActions(actionsLater));
                        }
                        else {
                            console.log("here we go...");
                            actions.push(Actions.setCMSLoadingProjectFailed(true));
                            actions.push(Actions.setCMSCurrentProjectAndWorkflows(undefined, []));
                            actions.push(Actions.setCMSFiles(undefined));
                            actions.push(Actions.setCMSLoadingFilesFailed(undefined));
                            actions.push(Actions.setCMSLocations(undefined));
                            actions.push(Actions.setCMSCategories(undefined));
                            dispatch(PluginActions.batchActions(actions));
                        }
                    }
                    else {
                        actions.push(Actions.setCMSCurrentProjectAndWorkflows(undefined, []));
                        actions.push(Actions.setCMSFiles(undefined));
                        actions.push(Actions.setCMSLoadingFilesFailed(undefined));
                        actions.push(Actions.setCMSLocations(undefined));
                        actions.push(Actions.setCMSCategories(undefined));
                        dispatch(PluginActions.batchActions(actions));
                    }
                }
                else {
                    // console.log("Actions.tsx::319 => id", id);
                    // console.log("Actions.tsx::320 => s.cmsState.currentFiles", s.cmsState.currentFiles);
                    // console.log("Actions.tsx::321 => pStr", pStr);
                    if (id) {
                        var zipP = await ControllerHelper.singleCall({ projectGroupId: id, path }, CMSController.GetZipProgresses);
                        dispatch(Actions.setCMSZipProgresses(zipP));
                    }

                    if (id && (s.cmsState.currentFiles ?? {})[pStr] === undefined)
                        dispatch(WorkingActions.CMS.loadFiles(id));
                }

            }
        }
    },
    WIKI: {
        loadPlaylistState(force?: boolean) {
            return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
                let reload = true;
                const state = getState();
                if (state.playlistsState.playlistStatesTopicWhen) {
                    const now = new Date();
                    const diffMs = now.getTime() - state.playlistsState.playlistStatesTopicWhen.getTime();
                    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
                    if (diffMins < 10)
                        reload = false;
                }
                if (reload || force || state.playlistsState.playlistStatesTopic === undefined) {
                    const states = await ControllerHelper.singleCall({}, PlaylistController.GetPlaylistStates, true)
                    dispatch(Actions.setPlaylistStatesTopic(states?.elements ?? [], new Date()));
                }
            }
        },
        loadWikiHomeContent(reload?: boolean) {
            return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
                const state = getState();
                const groupId = state.currentGroup?.id;
                if (reload || state.wikiState.groupId !== groupId) {
                    if (groupId) {
                        const categories = await ControllerHelper
                            .singleCall({ groupid: groupId }, WikiController.GetWikiCategories, true);
                        dispatch(PluginActions.batchActions([
                            Actions.setWikiGroupId(groupId),
                            Actions.setWikiCategories(categories ?? []),
                            Actions.setWikiArticle(undefined),
                        ]));
                        dispatch(WorkingActions.WIKI.loadPlaylistState());
                    } else {
                        dispatch(PluginActions.batchActions([
                            Actions.setWikiGroupId(undefined),
                            Actions.setWikiCategories(undefined),
                            Actions.setWikiArticle(undefined),
                            Actions.setPlaylistStates([]),
                        ]));
                    }
                }
                else {
                    dispatch(Actions.setWikiArticle(undefined));
                }

                if (state.wikiState.showIntro === undefined) {
                    const events = (state.user as UserDownloadDto)?.userSettings?.events;
                    if (events) {
                        const event = _.find(events, e => e.name === "intro-video-ppe-seen");
                        if (event)
                            dispatch(Actions.setWikiShowIntro(false));
                        else
                            dispatch(Actions.setWikiShowIntro(true));
                    }
                    else
                        dispatch(Actions.setWikiShowIntro(true));
                }
            }
        }
    },
    FEED: {
        initialLoadPosts(force?: boolean, loadingCount?: number, work?: (token?: string) => void) {
            return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
                const state = getState();
                const { user } = state;
                const { searchProps: feedSearchProps, trending } = state.feedState;
                // console.log('loadPosts', feedSearchProps);

                const postsToken = force ? undefined : state.params?.tokenId as string;
                //console.log("Actions.tsx::560 => postsToken", postsToken);
                let filter = createFetchPostFilter(user, undefined, undefined, undefined, undefined, feedSearchProps?.user);
                if (feedSearchProps && feedSearchProps.filter)
                    filter = feedSearchProps.filter;

                //console.log("Actions.tsx::584 => postsToken", postsToken);

                let newPosts: ExtendedDataCollection<PostDownloadDto, string> | undefined = undefined;
                const actions: ApplicationAction[] = [];
                if (feedSearchProps && feedSearchProps.search && feedSearchProps.search.length > 0) {
                    const res = await ControllerHelper.singleCall({ text: feedSearchProps.search ?? "", filter, token: postsToken ? { token: postsToken, operation: "Current" } : undefined, loadingCount }, PostController.SearchPostText3WithDbCursor);
                    newPosts = { elements: res.results ?? [], data: res.forwardToken!, links: [] };
                    dispatch(Actions.setFeedFacets(res.facets));
                }
                else {
                    newPosts = trending ?
                        await ControllerHelper.singleCall({ token: { token: postsToken ?? "", operation: "Current" }, filter, loadingCount }, PostController.GetPagedForAllGroupsTrendingWithDbCursor) :
                        await ControllerHelper.singleCall({ token: { token: postsToken ?? "", operation: "Current" }, filter, loadingCount }, PostController.GetPagedForAllGroupsWithDbCursor);
                    const res = await ControllerHelper.singleCall({ text: "", filter, loadingCount }, PostController.SearchPostText3WithDbCursor);
                    actions.push(Actions.setFeedFacets(res.facets));
                }

                if (newPosts) {
                    actions.push(PluginActions.setPosts(newPosts.elements, newPosts.data));
                    actions.push(Actions.setFeedSearchProps(feedSearchProps));
                    if (work)
                        work(newPosts.data);
                }
                else {
                    actions.push(Actions.setFeedSearchProps(feedSearchProps));
                }
                dispatch(PluginActions.batchActions(actions));


            }
        },
        loadPosts(loadingCount?: number, work?: (token?: string) => void, direction?: TokenOperation) {
            return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
                const state = getState();
                const { user } = state;
                const { searchProps: feedSearchProps, trending } = state.feedState;
                // console.log('loadPosts', feedSearchProps);
                // console.log("Actions.tsx::560 => postsToken", postsToken);

                const postsToken = state.postsToken;
                let filter = createFetchPostFilter(user, undefined, undefined, undefined, undefined, feedSearchProps?.user);
                if (feedSearchProps && feedSearchProps.filter)
                    filter = feedSearchProps.filter;


                //console.log("Actions.tsx::584 => postsToken", postsToken);

                //console.log('curT', postsToken);
                // console.log('trending', trending);
                //console.log('filter', filter);

                let newPosts: ExtendedDataCollection<PostDownloadDto, string> | undefined = undefined;
                const actions: ApplicationAction[] = [];
                if (feedSearchProps && feedSearchProps.search && feedSearchProps.search.length > 0) {
                    const res = await ControllerHelper.singleCall({ text: feedSearchProps.search ?? "", filter, token: postsToken ? { token: postsToken, operation: direction ?? "Next" } : undefined, loadingCount }, PostController.SearchPostText3WithDbCursor);
                    newPosts = { elements: res.results ?? [], data: res.forwardToken!, links: [] };
                    dispatch(Actions.setFeedFacets(res.facets));
                    // posts = await ControllerHelper.singleCall({text: feedSearchProps.search ?? "", filter }, PostController.SearchPostText2);
                }
                else {
                    newPosts = trending ?
                        await ControllerHelper.singleCall({ token: { token: postsToken ?? "", operation: direction ?? "Next" }, filter, loadingCount }, PostController.GetPagedForAllGroupsTrendingWithDbCursor) :
                        await ControllerHelper.singleCall({ token: { token: postsToken ?? "", operation: direction ?? "Next" }, filter, loadingCount }, PostController.GetPagedForAllGroupsWithDbCursor);
                    if (!postsToken) {
                        const res = await ControllerHelper.singleCall({ text: "", filter, token: { token: postsToken ?? "", operation: direction ?? "Next" }, loadingCount }, PostController.SearchPostText3WithDbCursor);
                        actions.push(Actions.setFeedFacets(res.facets));
                    }
                }

                if (newPosts) {
                    // console.log("Feed.tsx::201 => token", curT);
                    // console.log("Actions.tsx::589 => onlySet", onlySet);
                    //console.log("Feed.tsx::202 => newPosts", _.map(newPosts.elements, p => p.id));


                    if ((loadingCount ?? 12) === 12 && postsToken)
                        actions.push(PluginActions.addPosts(newPosts.elements, newPosts.data));
                    else
                        actions.push(PluginActions.setPosts(newPosts.elements, newPosts.data));
                    actions.push(Actions.setFeedSearchProps(feedSearchProps));
                    if (work)
                        work(newPosts.data);
                }
                else {
                    actions.push(Actions.setFeedSearchProps(feedSearchProps));
                }
                dispatch(PluginActions.batchActions(actions));


            }
        }
    }
};

export const Actions = {
    setHasFocus(user: boolean): ApplicationAction {
        return { type: "SET_FOCUS", value: user };
    },
    currentUserReceived(user: UserDownloadDto): ApplicationAction {
        return { type: "CURRENT_USER_RECEIVED", value: user };
    },
    requestUpdate(requested: boolean = true): ApplicationAction {
        return { type: "REQUEST_UPDATE_RECEIVED", value: requested };
    },
    setLocationSearch(value?: string): ApplicationAction {
        return { type: "SET_LOCATION_SEARCH", value };
    },
    calculateFilteredAppEntries(): ApplicationAction {
        return { type: "CALCULATE_FILTERED_APP_ENTRIES" };
    },
    setUserTasks(userTasks?: PostsWithToken<UserTaskDownloadDto>): ApplicationAction {
        return { type: "SET_USER_TASKS", value: userTasks };
    },
    setCurrentGroup(group?: GroupDownloadDto): ApplicationAction {
        return { type: "SET_CURRENT_GROUP", value: group };
    },
    setMasterLayoutExtension(masterLayoutRootExtension?: () => JSX.Element): ApplicationAction {
        return { type: "SET_MASTER_LAYOUT_EXTENSION", value: masterLayoutRootExtension };
    },
    removeCurrentUser(): ApplicationAction {
        return { type: "REMOVE_CURRENT_USER" };
    },
    setRouteInformation(route: string, params: RouteParams | undefined, user?: UserDownloadDto, doLogin?: boolean, noLogin?: boolean, search?: string): ApplicationAction {
        return { type: "SET_ROUTE_AND_PARAMS_AND_RENDERER", value: { route, params, user, doLogin, noLogin, search } };
    },
    setContentLanguage(lng?: string): ApplicationAction {
        return { type: "SET_CONTENT_LANGUAGE", value: lng };
    },
    setCMSRightSidebar(value?: boolean): ApplicationAction {
        return { type: "SET_CMS_RIGHT_SIDEBAR", value }
    },
    setSearchResultPos(pos?: number): ApplicationAction {
        return { type: "SET_SEARCH_RESULT_POS", value: pos };
    },
    setSearchResultIds(resultIds?: string[]): ApplicationAction {
        return { type: "SET_SEARCH_RESULT_COUNT", value: resultIds };
    },
    addSearchResultIds(resultIds?: string[]): ApplicationAction {
        return { type: "ADD_SEARCH_RESULT_IDS", value: resultIds };
    },
    updatePost(post: PostDownloadDto): ApplicationAction {
        return { type: "UPDATE_POST", value: post }
    },
    removePost(id: string): ApplicationAction {
        return { type: "REMOVE_POST", value: id }
    },

    setNeedSave(value?: boolean): ApplicationAction {
        return { type: "SET_NEED_SAVE", value: value };
    },
    setCanSave(value?: boolean): ApplicationAction {
        return { type: "SET_CAN_SAVE", value: value };
    },
    setActive(value?: boolean): ApplicationAction {
        return { type: "SET_ACTIVE", value: value };
    },
    setSave(save?: () => void): ApplicationAction {
        return { type: "SET_SAVE", value: save };
    },
    setAdd(add?: () => void): ApplicationAction {
        return { type: "SET_ADD", value: add };
    },
    setCancel(cancel?: () => void): ApplicationAction {
        return { type: "SET_CANCEL", value: cancel };
    },
    setSaveAndCancel(save?: () => void, cancel?: () => void): ApplicationAction {
        return { type: "SET_SAVE_AND_CANCEL", value: { cancel, save } };
    },
    setScrollTo(scrollTo?: (id: string, delay?: number) => void): ApplicationAction {
        return { type: "SET_SCROLLTO", value: scrollTo };
    },
    setAction(action?: () => void): ApplicationAction {
        return { type: "SET_ACTION", value: action };
    },
    setSideContentRenderers(leftContentRenderers: ApplicationState["leftContentRenderers"], rightContentRenderers: ApplicationState["rightContentRenderers"]): ApplicationAction {
        return { type: "SET_SIDE_CONTENT_RENDERERS", leftContentRenderers, rightContentRenderers };
    },
    setWikiGroupId(groupId?: string): ApplicationAction {
        return { type: "SET_WIKI_GROUP_ID", value: groupId };
    },
    setWikiCategories(wikiCategories?: WikiCategoryDownloadDto[]): ApplicationAction {
        return { type: "SET_WIKI_CATEGORIES", value: wikiCategories };
    },

    setWikiCategory(wikiCategory?: WikiCategoryDownloadDto): ApplicationAction {
        return { type: "SET_WIKI_CATEGORY", value: wikiCategory };
    },
    setWikiCategoryId(categoryId?: string): ApplicationAction {
        return { type: "SET_WIKI_CATEGORY_ID", value: categoryId };
    },
    toggleWikiShownReference(id: string): ApplicationAction {
        return { type: "TOGGLE_WIKI_SHOWN_REFERENCES", value: id };
    },
    setWikiReferenceLanguage(lng?: string): ApplicationAction {
        return { type: "SET_WIKI_REFERENCE_LNG", value: lng };
    },


    setTubeCurrentPost(post?: TubePostDownloadDto): ApplicationAction {
        return { type: "SET_TUBE_CURRENT_POST", value: post };
    },
    setTubeCurrentPosts(posts?: TubePostDownloadDto[]): ApplicationAction {
        return { type: "SET_TUBE_CURRENT_POSTS", value: posts };
    },
    setTubeCurrentCategoryStack(currentCategoryStack?: ElementNode<CategoryDownloadDto>[]): ApplicationAction {
        return { type: "SET_TUBE_CURRENT_CATEGORY_STACK", value: currentCategoryStack };
    },
    setTubeCategories(categories?: CategoryDownloadDto[]): ApplicationAction {
        return { type: "SET_TUBE_CATEGORIES", value: categories };
    },
    // setTubeScrollTo(scrollTo?: (id: string) => void) {
    //     return { type: ActionTypes.SET_TUBE_SCROLL_TO, scrollTo };
    // },
    setTubeViewStats(viewStats: ViewStats[]): ApplicationAction {
        return { type: "SET_TUBE_VIEW_STATS", value: viewStats };
    },
    addTubeViewStats(viewStats: ViewStats[]): ApplicationAction {
        return { type: "ADD_TUBE_VIEW_STATS", value: viewStats };
    },
    setTubeSearchReset(onResetSearch?: () => void): ApplicationAction {
        return { type: "SET_TUBE_SEARCH_RESET", value: onResetSearch };
    },
    setTubeFilter(filterPart?: Partial<FetchPostsFilter>): ApplicationAction {
        return { type: "SET_TUBE_FILTER", value: filterPart };
    },
    setTubeViewMode(viewMode?: string): ApplicationAction {
        return { type: "SET_TUBE_VIEW_MODE", value: viewMode };
    },
    setTubeSearch(search?: string): ApplicationAction {
        return { type: "SET_TUBE_SEARCH", value: search };
    },
    setTubeFocusTarget(focusTarget?: string): ApplicationAction {
        return { type: "SET_TUBE_FOCUS_TARGET", value: focusTarget };
    },
    setTubeTableview(tableView?: boolean): ApplicationAction {
        return { type: "SET_TUBE_TABLEVIEW", value: tableView };
    },
    setTubeQuality(quality: "SD" | "HD" | "4K"): ApplicationAction {
        return { type: "SET_TUBE_QUALITY", value: quality };
    },



    setPodcastCurrentPost(post?: PodcastPostDownloadDto): ApplicationAction {
        return { type: "SET_PODCAST_CURRENT_POST", value: post };
    },
    setPodcastCurrentPosts(posts?: PodcastPostDownloadDto[]): ApplicationAction {
        return { type: "SET_PODCAST_CURRENT_POSTS", value: posts };
    },
    setPodcastCurrentCategoryStack(currentCategoryStack?: ElementNode<CategoryDownloadDto>[]): ApplicationAction {
        return { type: "SET_PODCAST_CURRENT_CATEGORY_STACK", value: currentCategoryStack };
    },
    setPodcastCategories(categories?: CategoryDownloadDto[]): ApplicationAction {
        return { type: "SET_PODCAST_CATEGORIES", value: categories };
    },
    // setTubeScrollTo(scrollTo?: (id: string) => void) {
    //     return { type: ActionTypes.SET_TUBE_SCROLL_TO, scrollTo };
    // },
    setPodcastViewStats(viewStats: ViewStats[]): ApplicationAction {
        return { type: "SET_PODCAST_VIEW_STATS", value: viewStats };
    },
    addPodcastViewStats(viewStats: ViewStats[]): ApplicationAction {
        return { type: "ADD_PODCAST_VIEW_STATS", value: viewStats };
    },
    setPodcastSearchReset(onResetSearch?: () => void): ApplicationAction {
        return { type: "SET_PODCAST_SEARCH_RESET", value: onResetSearch };
    },
    setPodcastFilter(filterPart?: Partial<FetchPostsFilter>): ApplicationAction {
        return { type: "SET_PODCAST_FILTER", value: filterPart };
    },
    setPodcastViewMode(viewMode?: string): ApplicationAction {
        return { type: "SET_PODCAST_VIEW_MODE", value: viewMode };
    },
    setPodcastSearch(search?: string): ApplicationAction {
        return { type: "SET_PODCAST_SEARCH", value: search };
    },
    setPodcastFocusTarget(focusTarget?: string): ApplicationAction {
        return { type: "SET_PODCAST_FOCUS_TARGET", value: focusTarget };
    },
    setPodcastTableview(tableView?: boolean): ApplicationAction {
        return { type: "SET_PODCAST_TABLEVIEW", value: tableView };
    },
    setPodcastQuality(quality: "SD" | "HD" | "4K"): ApplicationAction {
        return { type: "SET_PODCAST_QUALITY", value: quality };
    },



    setWikiArticle(wikiArticle?: WikiArticleDownloadDto, editMode?: boolean): ApplicationAction {
        return { type: "SET_WIKI_ARTICLE", value: { wikiArticle, editMode } };
    },
    // setWikiScrollTo(scrollTo?: (id: string) => void) {
    //     return { type: ActionTypes.SET_WIKI_SCROLL_TO, scrollTo };
    // },
    setWikiSearch(search?: string): ApplicationAction {
        return { type: "SET_WIKI_SEARCH", value: search };
    },
    setWikiNewsInEdit(inNewsEdit?: boolean): ApplicationAction {
        return { type: "SET_WIKI_NEWS_IN_EDIT", value: inNewsEdit };
    },
    setWikiFocusTarget(focusTarget?: string): ApplicationAction {
        return { type: "SET_WIKI_FOCUS_TARGET", value: focusTarget };
    },
    setWikiSaveArticle(saveArticle?: (update: boolean) => void): ApplicationAction {
        return { type: "SET_WIKI_SAVE_ARTICLE", value: saveArticle };
    },
    setWikiPlaylistStatesForArticle(playlistStates?: PlaylistStateDownloadDto[]): ApplicationAction {
        return { type: "SET_WIKI_PLS_FOR_ARTICLE", value: playlistStates };
    },
    setWikiArticleBreadcrumbs(breadcrumbs?: WikiCategoryDownloadDto[][]): ApplicationAction {
        return { type: "SET_WIKI_ARTICLE_BREADCRUMBS", value: breadcrumbs };
    },
    setWikiShowIntro(showIntro?: boolean): ApplicationAction {
        return { type: "SET_WIKI_SET_SHOW_INTRO", value: showIntro };
    },
    setWikiSaveable(saveable?: boolean): ApplicationAction {
        return { type: "SET_WIKI_SAVEABLE", value: saveable };
    },
    setWikiNewsClose(onNewsClose: () => void): ApplicationAction {
        return { type: "SET_WIKI_NEWS_CLOSE", value: onNewsClose };
    },
    setWikiNewsDelete(onNewsDelete?: () => void): ApplicationAction {
        return { type: "SET_WIKI_NEWS_DELETE", value: onNewsDelete };
    },
    setWikiNewsSave(onNewsSave?: () => void): ApplicationAction {
        return { type: "SET_WIKI_NEWS_SAVE", value: onNewsSave };
    },
    setPlaylistStates(playlistStates?: PlaylistStateDownloadDto[]): ApplicationAction {
        return { type: "SET_PL_PLAYLIST_STATES", value: playlistStates }
    },
    setPlaylistStatesTopic(playlistStates?: PlaylistStateDownloadDto[], when?: Date): ApplicationAction {
        return { type: "SET_PL_PLAYLIST_STATES_TOPIC", value: playlistStates, when }
    },
    setPlaylistEditMode(editMode?: boolean): ApplicationAction {
        return { type: "SET_PL_EDIT_MODE", value: editMode }
    },
    setPlaylistSave(save?: (save: boolean) => void): ApplicationAction {
        return { type: "SET_PL_SAVE", value: save }
    },
    setPlaylistMissing(missing?: PlaylistStateMissing): ApplicationAction {
        return { type: "SET_PL_MISSING", value: missing };
    },
    setPlaylistRightSidebar(value?: boolean): ApplicationAction {
        return { type: "SET_PLAYLIST_RIGHT_SIDEBAR", value }
    },
    setPlaylistLeftSidebar(value?: boolean): ApplicationAction {
        return { type: "SET_PLAYLIST_LEFT_SIDEBAR", value }
    },
    setPlaylistFilter(value?: PlaylistFilter): ApplicationAction {
        return { type: "SET_PLAYLIST_FILTER", value }
    },
    setPlaylistFilterCount(value?: PlaylistFilterCount): ApplicationAction {
        return { type: "SET_PLAYLIST_FILTER_COUNT", value }
    },
    setPlaylistOrdering(value?: Ordering): ApplicationAction {
        return { type: "SET_PLAYLIST_ORDERING", value }
    },

    setSlideShow(slideShow?: SlideShowDownloadDto): ApplicationAction {
        return { type: "SET_SS_SLIDESHOW", value: slideShow };
    },
    setSlideShowLngSlides(lngSlides?: LngSlides): ApplicationAction {
        return { type: "SET_SS_LNG_SLIDES", value: lngSlides };
    },
    setSlideShowSlide(slide?: SlideDownloadDto): ApplicationAction {
        return { type: "SET_SS_SLIDE", value: slide };
    },
    setSlideShowTemplates(templates?: SlideTemplateDownloadDto[]): ApplicationAction {
        return { type: "SET_SS_TEMPLATES", value: templates };
    },
    setSlideShowSelectTemplate(selectTemplate?: boolean): ApplicationAction {
        return { type: "SET_SS_SELECT_TEMPLATE", value: selectTemplate };
    },
    setSlideShowBackgrounds(backgrounds?: SlideBgOption[]): ApplicationAction {
        return { type: "SET_SS_BACKGROUNDS", value: backgrounds };
    },
    setSlideCopy(copy?: () => void): ApplicationAction {
        return { type: "SET_SS_COPY", value: copy };
    },
    setSlideImportAs(importAs?: () => void): ApplicationAction {
        return { type: "SET_SS_IMPORT_AS", value: importAs };
    },
    setSlideUpdate(updateSlide?: (slide: SlideDownloadDto, slideMedia: { [key: string]: File }) => void): ApplicationAction {
        return { type: "SET_SS_UPDATE_SLIDE", value: updateSlide };
    },


    setBestPost(currentPost?: BestPracticePostDownloadDto): ApplicationAction {
        return { type: "SET_BEST_POST", value: currentPost };
    },
    setBestPostReportImplementation(reportImplementation?: () => void): ApplicationAction {
        return { type: "SET_BEST_REPORT_IMP", value: reportImplementation };
    },
    setBestPostEdit(inEdit?: boolean): ApplicationAction {
        return { type: "SET_BEST_EDIT", value: inEdit };
    },
    setBestPostOrderingType(ordering?: "date" | "trending" | "invisible" | "searched" | "own"): ApplicationAction {
        return { type: "SET_BEST_ORDERING", value: ordering };
    },
    setBestPostFilter(filter?: BestPracticeFilterState): ApplicationAction {
        return { type: "SET_BEST_FILTER", value: filter };
    },
    setBestPosts(posts?: PostsWithToken<BestPracticePostDownloadDto>): ApplicationAction {
        return { type: "SET_BEST_POSTS", value: posts };
    },
    setBestSearchTerm(term?: string): ApplicationAction {
        return { type: "SET_BEST_SEARCH_TERM", value: term };
    },
    setBestCategoriesAndLocations(categories?: CategoryDownloadDto[], locations?: ContentPostContentLocationDownloadDto[]): ApplicationAction {
        return { type: "SET_BEST_CATS_AND_LOCS", value: { categories, locations } };
    },
    setBestOwnPosts(posts?: PostsWithToken<BestPracticePostDownloadDto>): ApplicationAction {
        return { type: "SET_BEST_OWN_POSTS", value: posts };
    },
    // setBestScrollTo(scrollTo?: (id: string) => void) {
    //     return { type: ActionTypes.SET_BEST_SCROLL_TO, scrollTo };
    // },
    setBestFocusTarget(focusTarget?: string): ApplicationAction {
        return { type: "SET_BEST_FOCUS_TARGET", value: focusTarget };
    },
    removeBestPost(id: string): ApplicationAction {
        return { type: "REMOVE_BEST_POST", value: id };
    },
    setBestFacets(facets?: { [key: string]: FacetResult[] }): ApplicationAction {
        return { type: "SET_BEST_FACETS", value: facets };
    },
    setBestRightSidebar(value?: boolean): ApplicationAction {
        return { type: "SET_BEST_RIGHT_SIDEBAR", value }
    },


    setThePageEditMode(editMode?: boolean): ApplicationAction {
        return { type: "SET_THE_PAGE_EDITMODE", value: editMode };
    },
    setThePageDashboardElementTemplates(templates?: DashboardElementTemplateDownloadDto[]): ApplicationAction {
        return { type: "SET_THE_PAGE_DB_TEMPLATES", value: templates };
    },
    setThePageDashboards(dashboards?: DashboardDownloadDto[]): ApplicationAction {
        return { type: "SET_THE_PAGE_DASHBOARDS", value: dashboards };
    },
    setThePageSelectDashboard(dashboard: DashboardDownloadDto): ApplicationAction {
        return { type: "SET_THE_PAGE_SEL_DASHBOARD", value: dashboard };
    },
    setThePageEditLayouts(layouts?: Layouts): ApplicationAction {
        return { type: "SET_THE_PAGE_EDIT_LAYOUTS", value: layouts };
    },
    setThePageEditDasboard(dashboard?: DashboardDownloadDto): ApplicationAction {
        return { type: "SET_THE_PAGE_EDIT_DB", value: dashboard };
    },
    deleteElementFromThePageEditDasboard(element: DashboardElementDownloadDto): ApplicationAction {
        return { type: "DELETE_ELEMENT_FROM_THE_PAGE_EDIT_DASHBOARD", value: element };
    },
    setThePageAddElement(addElement?: boolean): ApplicationAction {
        return { type: "SET_THE_PAGE_ADD_ELEMENT", value: addElement };
    },
    setThePageAddTempElement(mouseEvent: { clientX: number, clientY: number }, template: DashboardElementTemplateDownloadDto): ApplicationAction {
        return { type: "SET_THE_PAGE_ADD_TEMP_ELEMENT", value: { mouseEvent, template } };
    },
    setThePageClearTempElements(): ApplicationAction {
        return { type: "SET_THE_PAGE_CLEAR_TEMP_ELEMENTS" };
    },
    setThePageFinalizeTempElement(template: DashboardElementTemplateDownloadDto): ApplicationAction {
        return { type: "SET_THE_PAGE_FINALIZE_TEMP_ELEMENT", value: template };
    },
    setThePagePALMSFilter(filter?: PALMSFilter): ApplicationAction {
        return { type: "SET_THE_PAGE_PALMS_FILTER", value: filter };
    },
    setThePageDetailsId(id?: string, filter?: PALMSFilter): ApplicationAction {
        return { type: "SET_THE_PAGE_DETAILS_ID", value: { id, filter } };
    },


    setChatAdd(addingChat?: boolean): ApplicationAction {
        return { type: "SET_CHAT_ADD", value: addingChat };
    },
    setChat(chat?: ChatDownloadDto): ApplicationAction {
        return { type: "SET_CHAT", value: chat };
    },
    setChatMembers(members?: UserShortInfoDownloadDto[]): ApplicationAction {
        return { type: "SET_CHAT_MEMBERS", value: members };
    },
    setChatUpdateInfoChat(updateInfoChat?: boolean): ApplicationAction {
        return { type: "SET_CHAT_UPDATE_INFO_CHAT", value: updateInfoChat };
    },
    setChatUpdateInfoChats(updateInfoChats?: boolean): ApplicationAction {
        return { type: "SET_CHAT_UPDATE_INFO_CHATS", value: updateInfoChats };
    },
    setChats(chats?: ChatDownloadDto[]): ApplicationAction {
        return { type: "SET_CHATS", value: chats };
    },
    setChatUpdateMessages(messages?: ChatMessageDownloadDto[]): ApplicationAction {
        return { type: "SET_CHAT_UPDATE_MESSAGES", value: messages };
    },

    setFeedTrending(trending?: boolean): ApplicationAction {
        return { type: "SET_FEED_TRENDING", value: trending };
    },
    setFeedRightSidebar(value?: boolean): ApplicationAction {
        return { type: "SET_FEED_RIGHT_SIDEBAR", value };
    },

    setFeedFacets(facets?: { [key: string]: FacetResult[] }): ApplicationAction {
        return { type: "SET_FEED_FACETS", value: facets };
    },
    setFeedShow(show?: "posts" | "bookmarks"): ApplicationAction {
        return { type: "SET_FEED_SHOW", value: show };
    },
    setFeedSearchProps(feedSearchProps?: FeedSearchProps): ApplicationAction {
        return { type: "SET_FEED_SEARCH_PROPS", value: feedSearchProps };
    },
    setFeedSave(save?: (save?: boolean) => void): ApplicationAction {
        return { type: "SET_FEED_SAVE", value: save };
    },
    setFeedPrevAndNext(prev: (() => void) | undefined, next: (() => void) | undefined): ApplicationAction {
        return { type: "SET_FEED_PREV_AND_NEXT", prev, next };
    },
    setFeedSelectedTab(selectedTab?: EditTab): ApplicationAction {
        return { type: "SET_FEED_SELECTED_TAB", value: selectedTab };
    },


    setContentCurrentPost(post?: ContentPostDownloadDto): ApplicationAction {
        return { type: "SET_CONTENT_CURRENT_POST", value: post };
    },
    setContentCurrentPosts(posts?: ContentPostDownloadDto[]): ApplicationAction {
        return { type: "SET_CONTENT_CURRENT_POSTS", value: posts };
    },
    setContentCurrentCategoryStack(currentCategoryStack?: ElementNode<CategoryDownloadDto>[]): ApplicationAction {
        return { type: "SET_CONTENT_CURRENT_CATEGORY_STACK", value: currentCategoryStack };
    },
    setContentCategories(categories?: CategoryDownloadDto[]): ApplicationAction {
        return { type: "SET_CONTENT_CATEGORIES", value: categories };
    },
    // setContentScrollTo(scrollTo?: (id: string) => void) {
    //     return { type: ActionTypes.SET_CONTENT_SCROLL_TO, scrollTo };
    // },
    setContentViewStats(viewStats: ViewStats[]): ApplicationAction {
        return { type: "SET_CONTENT_VIEW_STATS", value: viewStats };
    },
    addContentViewStats(viewStats: ViewStats[]): ApplicationAction {
        return { type: "ADD_CONTENT_VIEW_STATS", value: viewStats };
    },
    setContentSearchReset(onResetSearch?: () => void): ApplicationAction {
        return { type: "SET_CONTENT_SEARCH_RESET", value: onResetSearch };
    },
    setContentFilter(filterPart?: Partial<FetchPostsFilter>): ApplicationAction {
        return { type: "SET_CONTENT_FILTER", value: filterPart };
    },
    setContentViewMode(viewMode?: "trending" | "date" | "alpha"): ApplicationAction {
        return { type: "SET_CONTENT_VIEW_MODE", value: viewMode };
    },
    setContentFilterTypes(contentFilterTypes?: ContentFilterTypes): ApplicationAction {
        return { type: "SET_CONTENT_FILTER_TYPE", value: contentFilterTypes };
    },
    setContentSearch(search?: string): ApplicationAction {
        return { type: "SET_CONTENT_SEARCH", value: search };
    },

    setQuizCurrentPost(post?: QuizDownloadDto): ApplicationAction {
        return { type: "SET_QUIZ_CURRENT_POST", value: post };
    },
    setQuizCurrentPosts(posts?: QuizDownloadDto[]): ApplicationAction {
        return { type: "SET_QUIZ_CURRENT_POSTS", value: posts };
    },
    setQuizCurrentCategoryStack(currentCategoryStack?: ElementNode<CategoryDownloadDto>[]): ApplicationAction {
        return { type: "SET_QUIZ_CURRENT_CATEGORY_STACK", value: currentCategoryStack };
    },
    setQuizCategories(categories?: CategoryDownloadDto[]): ApplicationAction {
        return { type: "SET_QUIZ_CATEGORIES", value: categories };
    },
    setQuizViewStats(viewStats: ViewStats[]): ApplicationAction {
        return { type: "SET_QUIZ_VIEW_STATS", value: viewStats };
    },
    addQuizViewStats(viewStats: ViewStats[]): ApplicationAction {
        return { type: "ADD_QUIZ_VIEW_STATS", value: viewStats };
    },
    setQuizSearchReset(onResetSearch?: () => void): ApplicationAction {
        return { type: "SET_QUIZ_SEARCH_RESET", value: onResetSearch };
    },
    setQuizFilter(filterPart?: Partial<FetchPostsFilter>): ApplicationAction {
        return { type: "SET_QUIZ_FILTER", value: filterPart };
    },
    setQuizViewMode(viewMode?: "date" | "alpha"): ApplicationAction {
        return { type: "SET_QUIZ_VIEW_MODE", value: viewMode };
    },


    // setGlobalScrollTo(scrollTo?: (id: string) => void) {
    //     return { type: ActionTypes.SET_GLOBAL_SCROLL_TO, scrollTo };
    // },
    setGlobalViewStats(viewStats: ViewStats[]): ApplicationAction {
        return { type: "SET_GLOBAL_VIEW_STATS", value: viewStats };
    },
    addGlobalViewStats(viewStats: ViewStats[]): ApplicationAction {
        return { type: "ADD_GLOBAL_VIEW_STATS", value: viewStats };
    },
    setGlobalSearchSearch(search?: string): ApplicationAction {
        return { type: "SET_GLOBAL_SEARCH", value: search };
    },
    addGlobalSearchResults(toAdd: Array<ItemSearchResult<PostDownloadDto> | WikiSearchResultDto>): ApplicationAction {
        return { type: "ADD_GLOBAL_SEARCH_RESULTS", value: toAdd };
    },
    clearGlobalSearchResults(): ApplicationAction {
        return { type: "CLEAR_GLOBAL_SEARCH_RESULTS" };
    },
    setGlobalSearchLastSearch(last?: LastSearch): ApplicationAction {
        return { type: "SET_GLOBAL_SEARCH_LAST_SEARCH", value: last };
    },
    setGlobalSearchSetFilter(filterPart?: Partial<FetchPostsFilter>): ApplicationAction {
        return { type: "SET_GLOBAL_SEARCH_SET_FILTER", value: filterPart };
    },
    setGlobalSearchSetSystems(systems: SearchSystems): ApplicationAction {
        return { type: "SET_GLOBAL_SEARCH_SET_SYSTEMS", value: systems };
    },
    setCMSLoadingProjectFailed(failed?: boolean): ApplicationAction {
        return { type: "SET_CMS_LOADING_PROJECT_FAILED", value: failed };
    },
    setCMSLoadingFilesFailed(failed?: boolean): ApplicationAction {
        return { type: "SET_CMS_LOADING_FILES_FAILED", value: failed };
    },
    setCMSCurrentProjectAndWorkflows(project: ProjectGroupDownloadDto | undefined, workflows: WorkflowDownloadDto[]): ApplicationAction {
        return { type: "SET_CMS_CURRENT_PROJECT_AND_WFS", value: { project, workflows } };
    },
    setCMSTableView(tableView?: boolean): ApplicationAction {
        return { type: "SET_CMS_TABLE_VIEW", value: tableView };
    },
    setCMSTemplate(template?: ProjectStructureTemplateDownloadDto): ApplicationAction {
        return { type: "SET_CMS_TEMPLATE", value: template };
    },
    setCMSProjects(projects?: ExtendedDataCollection<ProjectGroupDownloadDto, string>): ApplicationAction {
        return { type: "SET_CMS_PROJECTS", value: projects };
    },
    setCMSContentProjects(projects?: ProjectGroupDownloadDto[]): ApplicationAction {
        return { type: "SET_CMS_CONTENT_PROJECTS", value: projects };
    },
    clearCMSSearchResultEntries(): ApplicationAction {
        return { type: "CLEAR_CMS_SEARCH_RESULT_ENTRIES" }
    },
    setCMSSearchResultsFiles(searchResults?: SearchResultsDto<FileEntrySearchResultDto>): ApplicationAction {
        return { type: "SET_CMS_SEARCH_RESULTS_FILES", value: searchResults };
    },
    setCMSSearchResultsProjects(searchResults?: SearchResultsDto<ProjectGroupSearch>): ApplicationAction {
        return { type: "SET_CMS_SEARCH_RESULTS_PROJECTS", value: searchResults };
    },
    setCMSContentSearchResultsFiles(searchResults?: SearchResultsDto<FileEntrySearchResultDto>): ApplicationAction {
        return { type: "SET_CMS_CONTENT_SEARCH_RESULTS_FILES", value: searchResults };
    },
    setCMSContentSearchResultsProjects(searchResults?: SearchResultsDto<ProjectGroupSearch>): ApplicationAction {
        return { type: "SET_CMS_CONTENT_SEARCH_RESULTS_PROJECTS", value: searchResults };
    },

    setCMSFilterTerm(filterTerm?: string): ApplicationAction {
        return { type: "SET_CMS_FILTER_TERM", value: filterTerm };
    },
    setCMSDownloadLink(downloadLink?: string): ApplicationAction {
        return { type: "SET_CMS_DOWNLOADLINK", value: downloadLink };
    },
    setCMSFiles(files?: { [path: string]: FileEntryDownloadDto[] }): ApplicationAction {
        return { type: "SET_CMS_FILES", value: files };
    },
    setCMSTranslationBatches(value?: TranslationBatchDownloadDto[]): ApplicationAction {
        return { type: "SET_CMS_TRANSLATION_BATCHES", value };
    },
    setCMSZipProgresses(value?: ZipUploadInProgress[]): ApplicationAction {
        return { type: "SET_CMS_ZIP_PROGRESS", value };
    },
    // setCMSUpdateFile(file?: FileEntryDownloadDto): ApplicationAction {
    //     return { type: "SET_CMS_UPDATE_FILE", value: file };
    // },
    setCMSLocations(locations?: ContentPostContentLocationDownloadDto[]): ApplicationAction {
        return { type: "SET_CMS_LOCATIONS", value: locations };
    },
    setCMSCategories(categories?: CategoryDownloadDto[]): ApplicationAction {
        return { type: "SET_CMS_CATEGORIES", value: categories };
    },
    setCMSLocation(location?: ContentPostContentLocationDownloadDto): ApplicationAction {
        return { type: "SET_CMS_LOCATION", value: location };
    },
    updateCMSFile(file: FileEntryDownloadDto): ApplicationAction {
        return { type: "UPDATE_CMS_FILE", value: file };
    },
    removeCMSFile(file: FileEntryDownloadDto): ApplicationAction {
        return { type: "REMOVE_CMS_FILE", value: file };
    },
    addCMSFilePosts(id: string, posts?: PostDownloadDto[]): ApplicationAction {
        return { type: "ADD_CMS_FILE_POSTS", value: { id, posts } };
    },
    setCMSShowArchive(showArchive?: boolean): ApplicationAction {
        return { type: "SET_CMS_SHOW_ARCHIVE", value: showArchive };
    },
    setCMSShowUpload(showUpload?: boolean): ApplicationAction {
        return { type: "SET_CMS_SHOW_UPLOAD", value: showUpload };
    },
    setCMSShowAddReference(showAddReference?: boolean): ApplicationAction {
        return { type: "SET_CMS_SHOW_ADD_REFERENCE", value: showAddReference };
    },
    setCMSFileToRename(fileToRename?: FileEntryDownloadDto): ApplicationAction {
        return { type: "SET_CMS_FILE_TO_RENAME", value: fileToRename };
    },
    setCMSFileToMove(fileToMove?: FileEntryDownloadDto[]): ApplicationAction {
        return { type: "SET_CMS_FILE_TO_MOVE", value: fileToMove };
    },
    setCMSFolderToRename(folderToRename?: string): ApplicationAction {
        return { type: "SET_CMS_FOLDER_TO_RENAME", value: folderToRename };
    },
    setCMSShowProjectInfo(showProjectInfo?: boolean): ApplicationAction {
        return { type: "SET_CMS_SHOW_PROJECT_INFO", value: showProjectInfo };
    },
    setCMSShowDownloadInfo(showDownloadInfo?: boolean): ApplicationAction {
        return { type: "SET_CMS_SHOW_DOWNLOAD_INFO", value: showDownloadInfo };
    },
    setCMSWorkflowToGroup(wfTypeIsGroup: boolean): ApplicationAction {
        return { type: "SET_CMS_WORKFLOW_TYPE_IS_GROUP", value: wfTypeIsGroup };
    },
    setCMSShowTasks(showTasks?: boolean): ApplicationAction {
        return { type: "SET_CMS_SHOW_TASKS", value: showTasks };
    },
    setCMSDirectoryAndFileMenuDisabled(value?: boolean): ApplicationAction {
        return { type: "SET_CMS_DIRECTORY_AND_FILE_ACTIONS_DISABLED", value };
    },
    setCMSFilesGridSelectedRows(value: Array<FileEntryDownloadDto | FileEntriesDownloadDto>): ApplicationAction {
        return { type: "SET_CMS_FILES_GRID_SELECTED_ROWS", value };
    },
    setCMSCategoryConfiguration(value?: ConfigurationsWithCategories): ApplicationAction {
        return { type: "SET_CMS_CATEGORY_CONFIGURATION", value }
    },
    setCMSActionResult(actionResult?: ActionResult): ApplicationAction {
        return { type: "SET_CMS_ACTION_RESULT", value: actionResult }
    },
    addCMSProjectsFilesCount(value?: ProjectsFilesCount): ApplicationAction {
        return { type: "ADD_CMS_PROJECTS_FILES_COUNT", value }
    },
    adminSetGroup(group?: GroupDownloadDto): ApplicationAction {
        return { type: "ADMIN_SET_GROUP", value: group };
    },
    adminSetLeftSidebar(value?: boolean): ApplicationAction {
        return { type: "ADMIN_SET_LEFT_SIDEBAR", value };
    },
    adminSetRightSidebar(value?: boolean): ApplicationAction {
        return { type: "ADMIN_SET_RIGHT_SIDEBAR", value };
    },
    setQandAReply(reply?: () => void): ApplicationAction {
        return { type: "SET_QANDA_REPLY", value: reply };
    },
    setQandAEdit(edit?: () => void): ApplicationAction {
        return { type: "SET_QANDA_EDIT", value: edit };
    },
    setQandAEditForum(edit?: () => void): ApplicationAction {
        return { type: "SET_QANDA_EDIT_FORUM", value: edit };
    },
    setShowFilterOnLoadingDataTable(showFilterOnLoadingDataTable?: boolean): ApplicationAction {
        return { type: "SET_SHOW_FILTER_ON_LOADINGDATATABLE", value: showFilterOnLoadingDataTable };
    },
    setShowMobileMenu(showMobileMenu?: boolean): ApplicationAction {
        return { type: "SET_SHOW_MOBILE_MENU", value: showMobileMenu };
    },
    setLogoutConfirmed(logoutConfirmed?: boolean): ApplicationAction {
        return { type: "SET_LOGOUT_CONFIRMED", value: logoutConfirmed };
    },


    setTopInboxMessages(value?: InAppMessageDownloadDto[]): ApplicationAction {
        return { type: "SET_TOP_INBOX_MESSAGES", value }
    },
    setInboxMessages(value?: InAppMessageDownloadDto[]): ApplicationAction {
        return { type: "SET_INBOX_MESSAGES", value }
    },

    setMyHomeShowDetailedPage(showDetailedPage: boolean): ApplicationAction {
        return { type: "SET_MYHOME_SHOWDETAILED_PAGE", value: showDetailedPage }
    },
    setMyHomeSortedBy(sortedBy: SortingBy): ApplicationAction {
        return { type: "SET_MYHOME_SORTED_BY", value: sortedBy }
    },
    setMyHomeSortingAscending(sortAscending: boolean): ApplicationAction {
        return { type: "SET_MYHOME_SORT_ASCENDING", value: sortAscending }
    },
    setMyHomeShowProgressOnTrack(progressOnTrack: boolean): ApplicationAction {
        return { type: "SET_MYHOME_SHOW_PROGRESS_ONTRACK", value: progressOnTrack }
    },
    setMyHomeShowProgressIncomplete(progressIncomplete: boolean): ApplicationAction {
        return { type: "SET_MYHOME_SHOW_PROGRESS_INCOMPLETE", value: progressIncomplete }
    },
    setMyHomeShowProgressOverdue(progressOverdue: boolean): ApplicationAction {
        return { type: "SET_MYHOME_SHOW_PROGRESS_OVERDUE", value: progressOverdue }
    },
    setMyHomeShowProgressCompleted(progressCompleted: boolean): ApplicationAction {
        return { type: "SET_MYHOME_SHOW_PROGRESS_COMPLETED", value: progressCompleted }
    },
    setMyHomeSetLeftSidebar(value?: boolean): ApplicationAction {
        return { type: "SET_MYHOME_SET_LEFT_SIDEBAR", value };
    },
    setMyHomeSetRightSidebar(value?: boolean): ApplicationAction {
        return { type: "SET_MYHOME_SET_RIGHT_SIDEBAR", value };
    },
}



