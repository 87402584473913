import { appThemeManipulation, defineIcons } from 'AppTheme';
import 'cropperjs/dist/cropper.css';
import { defaultTheme } from 'imaginarity-react-ui';
import * as _ from "lodash";
import React from 'react';
import ReactDOM from 'react-dom';
import { getBrowser } from 'services/Helpers/BrowserHelper';
import { cmsTheme } from 'views/CMS/CMSTheme';
import { contentTheme } from 'views/ContentPage/ContentMain';
import { feedTheme } from 'views/Feed/FeedMain';
import { quizTheme } from 'views/Quiz/QuizMain';
import { scormTheme } from "views/Scorm/ScormMain";
import { tubeTheme } from 'views/Tube/TubeMain';
import App from './App';
import { wikiTheme } from "./views/Wiki/WikiTheme";

// export const applicationTheme: Theme = {
//     ...manipulateTheme(defaultTheme, appThemeManipulation), applicationTheming: { tubeTheme, wikiTheme }
// };

const browser = getBrowser();
defaultTheme.browser = browser.name;
defaultTheme.applicationTheming = { tubeTheme, wikiTheme, contentTheme, scormTheme, quizTheme, cmsTheme, feedTheme };
_.merge(defaultTheme, appThemeManipulation(defaultTheme));

defineIcons();

export const applicationTheme = defaultTheme;
if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        exclude: [/GlobalStyle.*/],
        trackAllPureComponents: false,
        logOnDifferentValues: true,

    });
}

window.React = require("react");
window.ReactDOM = require("react-dom");
window._ = require("lodash");
(window as any).styledComponents = require("styled-components");
(window as any).axios = require("axios");
(window as any).redux = require("redux");
//(window as any).imaginarityReactUI = require("imaginarity-react-ui");

export const rootElement = document.getElementById('root');
window.removeEventListener("error", (window as any).reloadOnError, true);
ReactDOM.render(<App />, rootElement);

/*const o1 = new OfflineData(I18NController.GetAllLanguages, "languages");
const o2 = new OfflineMedia("medias");
const test = async () => {
    try {
        await o1.init();
        const x = await o1.storeRequest("std", {});
        console.log(x);
        const y = await o1.getRequest("std");
        console.log(y);


        await o2.init();

        // const m: MediaDownloadDto = {
        //     changed: false,
        //     fileName: "test.png",
        //     id: "",
        //     links: [{
        //         ref: "self",
        //         method: "GET",
        //         uri: "https://cdn.imaginarity.com/images/01EAA2M98QWF169DG6R44ZAGM2?sv=2019-02-02&sr=b&sig=QYoSDEPAG%2F6Ne7IqWNUTVvtMDHUyL6Ylblcpc1Apqas%3D&st=2021-02-22T14%3A20%3A00Z&se=2021-02-22T16%3A00%3A00Z&sp=r"
        //     }],
        //     mediaType: "image/png",
        //     reference: "",
        //     thumbnails: [],
        // }


        const m1: MediaDownloadDto = {
            changed: false,
            fileName: "test.png",
            id: "",
            links: [{
                ref: "self",
                method: "GET",
                uri: "https://storagecollaboration.blob.core.windows.net/ppe-import-test/EN_Opening_Title_Sequence-1080p.mp4?sp=rl&st=2021-02-22T16:15:12Z&se=2021-02-23T16:15:12Z&sv=2020-02-10&sr=b&sig=AtLNXo40C%2F4ieVH6mQ1XupWhzqD8IG1tcqJ99uDVb8I%3D"
            }],
            mediaType: "video/mp4",
            reference: "",
            thumbnails: [],
        }

        // await o2.storeMedia("testmedia", m);
        //await o2.storeMedia("testvideo", m1);

        const mm = o2.getMedia("testmedia");
        //        console.log(mm);

        const mm2 = o2.getMedia("testvideo");
        //        console.log(mm2);

        // o2.storeFromSrc("https://cdn.imaginarity.com/images/01EAA2M98QWF169DG6R44ZAGM2?sv=2019-02-02&sr=b&sig=QYoSDEPAG%2F6Ne7IqWNUTVvtMDHUyL6Ylblcpc1Apqas%3D&st=2021-02-22T14%3A20%3A00Z&se=2021-02-22T16%3A00%3A00Z&sp=r");

        //const imgSrc = await o2.getStoredScr();
        const r = document.getElementById('root');
        if (r) {
            const img = document.createElement("img");
            img.src = mm.links[0].uri;
            img.style.position = "absolute";
            img.style.top = "0px";
            img.style.left = "0px";
            img.style.width = "1280px";
            img.style.height = "320px";

            r.appendChild(img);


            const vid = document.createElement("video");
            vid.src = mm2.links[0].uri;
            vid.style.position = "absolute";
            vid.style.bottom = "0px";
            vid.style.left = "0px";
            vid.style.width = `640px`;
            vid.style.height = `320px`;
            vid.controls = true;
            vid.autoplay = true;
            r.appendChild(vid);


        }
    }
    catch (e) {
        console.log("something went wrong:", e);
    }

}


test();*/