import * as React from 'react';
export interface MyHomeStartUDDProps {
}

const MyHomeStartUDD = (p: MyHomeStartUDDProps) => {

    return (
        <>

            <div style={{ height: 10 }} />
            <h2>User Data Dashboard</h2>

            <div style={{ height: 10 }} />
            <iframe title="test" src="https://porsche-ag.eu10.sapanalytics.cloud/sap/fpa/ui/tenants/8f995/app.html#/analyticapp&/aa/9AD83802102D2F3B1AE05CF7A8446180/?view_id=appBuilding&mode=present&url_api=true" style={{ width: "100%", height: "800px", border: "1px solid #f0f0f0" }}></iframe>

        </>
    );
}
export default MyHomeStartUDD;
