import { BestPracticePostDownloadDto, CategoryDownloadDto, ContentPostDownloadDto, FileEntryDownloadDto, PlaylistContentDownloadDto, PlaylistEdgeTransitionFunction, PlaylistItemStateDownloadDto, PodcastPostDownloadDto, PorscheMomentPostDownloadDto, PostDownloadDto, QuizDownloadDto, TubePostDownloadDto, WikiArticleDownloadDto } from 'collaboration-service';
import * as React from 'react';
import BestPracticePlaylistPostNodeItem from './PlaylistItems/BestPracticePlaylistPostNodeItem';
import ContentPostPlaylistNodeItem from './PlaylistItems/ContentPostPlaylistNodeItem';
import QuizPlaylistNodeItem from './PlaylistItems/QuizPlaylistNodeItem';
import TubePostPlaylistNodeItem from './PlaylistItems/TubePostPlaylistNodeItem';
import WikiArticlePlaylistNodeItem from './PlaylistItems/WikiArticlePlaylistNodeItem';
import { calculateDueInfo, DueToType } from 'services/Helpers';
import PodcastPostPlaylistNodeItem from './PlaylistItems/PodcastPostPlaylistNodeItem';
import PorscheMomentPostPlaylistNodeItem from './PlaylistItems/PorscheMomentPostPlaylistNodeItem';
import FileEntryPlaylistNodeItem from './PlaylistItems/FileEntryPlaylistNodeItem';
import { useAppSelector } from 'services/ApplicationState/ApplicationState';
import PALMSPlaylistNodeItem from './PlaylistItems/PALMSPlaylistNodeItem';
export interface PlaylistItemProps {
    item: PostDownloadDto;
    orderNumber: number;
    state: PlaylistItemStateDownloadDto;
    isMobile: boolean;
    categories?: CategoryDownloadDto[];
    paddedRight?: boolean;
    playlistId?: string;
    playlistContent?: PlaylistContentDownloadDto;
    playlistStateId?: string;
    accent?: string;
}
export interface SePlaylistItemState {
    showFullDescription: boolean;
}
export interface PlaylistDetailNodeItemProps<T extends PostDownloadDto> {
    item: T;
    orderNumber: number;
    state: PlaylistItemStateDownloadDto;
    isMobile: boolean;
    categories?: CategoryDownloadDto[];
    paddedRight?: boolean;
    playlistId?: string;
    accentColor: string;
    playlistContent?: PlaylistContentDownloadDto;
    playlistStateId?: string;
    accent?: string;
    isOptional?: boolean;
    dueToType: DueToType;
    assignmentDate?: Date;
    absoluteDueDate?: Date;
    dueDateRelativeInHours?: number;
    nodeReference?: string;
    transition?: PlaylistEdgeTransitionFunction;
    warning?: boolean;
    nodeIsDone?: boolean;
    loadPlaylistStates?: () => Promise<void>;
}
type UseHidePlaylistNodeItemProps = {
    state: PlaylistDetailNodeItemProps<PostDownloadDto>["state"];
    dueToType: PlaylistDetailNodeItemProps<PostDownloadDto>["dueToType"];
    assignmentDate?: PlaylistDetailNodeItemProps<PostDownloadDto>["assignmentDate"];
    absoluteDueDate?: PlaylistDetailNodeItemProps<PostDownloadDto>["absoluteDueDate"];
    dueDateRelativeInHours?: PlaylistDetailNodeItemProps<PostDownloadDto>["dueDateRelativeInHours"];
    isOptional?: PlaylistDetailNodeItemProps<PostDownloadDto>["isOptional"];
    warning?: PlaylistDetailNodeItemProps<PostDownloadDto>["warning"];
}

export const useHidePlaylistNodeItem = (p: UseHidePlaylistNodeItemProps) => {
    const { state, assignmentDate, dueToType, absoluteDueDate, dueDateRelativeInHours, isOptional, warning } = p;
    const result = calculateDueInfo(dueToType, state.state, assignmentDate, absoluteDueDate, dueDateRelativeInHours, isOptional, warning);
    const filter = useAppSelector(s => s.playlistsState.filter);
    const hideFinished = filter ? !filter['finished'] : false;
    const hideOnTrack = filter ? !filter['ontrack'] : false;
    const hideOvedue = filter ? !filter['overdue'] : false;
    const hideWarning = filter ? !filter['warning'] : false;
    const hideOptional = filter ? !filter['optional'] : false;
    if (hideFinished && result.text === "100%")
        return { hide: true, ...result };
    if (hideOnTrack && result.text === "on Track")
        return { hide: true, ...result };
    if (hideOvedue && result.text === "overdue")
        return { hide: true, ...result };
    if (hideWarning && result.text === "warning")
        return { hide: true, ...result };
    if (hideOptional && result.text === "optional")
        return { hide: true, ...result };
    return { hide: false, ...result };
}

const PlaylistDetailNodeItem = (p: PlaylistDetailNodeItemProps<PostDownloadDto>) => {
    const { playlistContent, item, accent, ...subProps } = p;

    switch (item.type) {
        case "WikiArticle":
            return <WikiArticlePlaylistNodeItem item={item as WikiArticleDownloadDto} {...subProps} />;
        case "TubePost":
            return <TubePostPlaylistNodeItem item={item as TubePostDownloadDto} {...subProps} />;
        case "PodcastPost":
            return <PodcastPostPlaylistNodeItem item={item as PodcastPostDownloadDto} {...subProps} />;
        case "Quiz":
            return <QuizPlaylistNodeItem item={item as QuizDownloadDto} {...subProps} />;
        case "ContentPost":
            return <ContentPostPlaylistNodeItem item={item as ContentPostDownloadDto} {...subProps} />;
        case "BestPracticePost":
            return <BestPracticePlaylistPostNodeItem item={item as BestPracticePostDownloadDto} {...subProps} />;
        case "PorscheMomentPost":
            return <PorscheMomentPostPlaylistNodeItem item={item as PorscheMomentPostDownloadDto} {...subProps} />;
        case "FileEntry":
            return <FileEntryPlaylistNodeItem item={item as FileEntryDownloadDto as any} {...subProps} />
        case "PALMS":
            return <PALMSPlaylistNodeItem item={item as FileEntryDownloadDto as any} content={playlistContent!} {...subProps} />
        default:
            return <div >{item.type}</div>;
    }
}

export default PlaylistDetailNodeItem;