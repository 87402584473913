import { Button, Comment, Divider, RatingSelect } from 'imaginarity-react-ui';
import * as React from 'react';
import { CommentsViewSC as T } from "./CommentsViewSC";
export interface CommentsViewProps {
    typeColor?: string;
    asRating?: boolean;
    likeable?: boolean;
}
export interface CommentsViewState {
    showAddComment: boolean;
}

class CommentsView extends React.Component<CommentsViewProps, CommentsViewState> {
    constructor(props: CommentsViewProps) {
        super(props);

        this.state = {
            showAddComment: true,
        }
    }

    public render() {
        const col = this.props.typeColor ?? "#7f7f7f";
        return (
            <T.NewCommentButtonContainer color={col}>
                <T.NewCommentButtonHeadline>
                    {this.state.showAddComment ?
                        <>
                            {this.props.asRating ? "Beitrag bewerten" : "Kommentare schreiben"}
                        </>
                        :
                        <>
                            {this.props.asRating ? "Ratings" : "Kommentare"}
                            <Button floated="right" kind="transparentButton" icon="add" size="default" onClick={this.showOwnCommentBox} />
                        </>
                    }
                </T.NewCommentButtonHeadline>

                {this.state.showAddComment &&
                    <>
                        {this.props.asRating &&
                            <T.RatingSelectContainer color={col}>
                                <RatingSelect maxStars={5} size={30} />
                            </T.RatingSelectContainer>
                        }
                        <T.NewCommentBox bgColor={col}>
                            <T.NewCommentBoxLeft>
                                <T.CommentTextArea
                                    width="100%"
                                    placeHolder={this.props.asRating ? "Beschreibe Deine Bewertung" : "Geb Deinen Kommentar ein"}
                                    kind="borderless"
                                />
                            </T.NewCommentBoxLeft>
                            <T.NewCommentBoxRight bgColor={col}>
                                <Button kind="transparentButton" icon="times" size="default" onClick={this.closeOwnCommentBox} />
                                <Button kind="transparentButton" icon="check" size="default" onClick={this.showOwnCommentBox} />
                            </T.NewCommentBoxRight>
                        </T.NewCommentBox>
                    </>
                }
                <Divider />
                <Comment
                    user="Markus Moßig"
                    date="vor 3 Tagen"
                    comment="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt animi ullam atque ea! Ab suscipit. Consequuntur, nulla minima. Iste doloribus eligendi nemo."
                    avatar="https://cdn.imaginarity.com/images/01E8KMTHXY233AA05V5NT31J7P?sv=2019-02-02&sr=b&sig=rU0dFq8NNo1sf%2Bnuup4RstD2eTAYR6ptVDGcKyV%2Bccw%3D&st=2020-06-22T08%3A20%3A00Z&se=2020-06-22T10%3A00%3A00Z&sp=r"
                    scheme="light"
                    likeable={this.props.likeable}
                    buttonsNotInline
                />
                <Comment
                    user="Schinken Wurst"
                    date="vor 6 Tagen"
                    comment="Alles Käse"
                    avatar="https://baconmockup.com/60/60/"
                    scheme="light"
                    likeable={this.props.likeable}
                />
            </T.NewCommentButtonContainer>
        );
    }
    private showOwnCommentBox = () => { this.setState({ showAddComment: true }) };
    private closeOwnCommentBox = () => { this.setState({ showAddComment: false }) };
}
export default CommentsView;