import Fuse from 'fuse.js';
import { belowBreakpoint, Button, ButtonKind, getColor, styled, Theme } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { EditorSC as T } from './EditorSC';
import { emojiList, eType } from './EmojiList';
import { ThemeContext } from 'styled-components';

const EmojiButton = styled.div<{ toolbarHeight: number }>`
    height: ${p => p.toolbarHeight}px;
    width: ${p => p.toolbarHeight}px;
    font-size: ${p => p.toolbarHeight === 32 ? "1em" : "1.2em"};
    text-align: center;
    line-height: ${p => p.toolbarHeight}px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    &:hover{
        background: ${p => getColor(p, "@darkerGrey")};
    }
`;

const parseUnicode = (value: string): number[] => {
    return value.split('-').map(str => parseInt(str, 16));
}

export const getEmoji = (value: string): string => {
    return String.fromCodePoint(...parseUnicode(value));
};

export const Emoji = 'emoji';

interface EmojisProps {
    applyFormat: (format: string, value: any) => () => void;
    up: boolean;
    linkButtonsKind?: ButtonKind;
    buttonSize?: "default" | "small";
    maxButtonsInRow?: number;
    maxRows?: number;
}

const Emojis = (p: EmojisProps) => {
    const { linkButtonsKind, applyFormat, buttonSize, up, maxButtonsInRow, maxRows } = p;
    const [selector, setSelector] = React.useState<boolean>(false);
    const [persons, setPersons] = React.useState<Fuse.FuseResult<eType>[]>([]);
    const theme = React.useContext<Theme>(ThemeContext);

    React.useEffect(() => {
        const fuseOptions = {
            shouldSort: true,
            matchAllTokens: true,
            threshold: 0.3,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 3,
            keys: [
                "category"
            ]
        };
        const fuse = new Fuse(emojiList, fuseOptions);
        let p = fuse.search('p');  // p === person
        p.sort(function (a, b) {
            return Number(a.item.emoji_order) - Number(b.item.emoji_order);
        });
        setPersons(p);
    }, []);

    const toggleSelector = () => {
        setSelector(!selector);
    };

    const toolbarHeight = buttonSize === 'small' ? 32 : 40;

    let mbir = maxButtonsInRow ?? 5;
    mbir = persons.length < mbir ? persons?.length : mbir;

    let mr = maxRows ?? 3;
    let rows = Math.round(persons.length / mbir);
    rows = rows > mr ? mr : rows;

    const top = toolbarHeight * (-rows) + toolbarHeight;

    return (
        <T.StyledList
            onMouseEnter={toggleSelector}
            onMouseLeave={toggleSelector}
            toolbarHeight={toolbarHeight}>
            <Button
                icon="smiley smile"
                kind={linkButtonsKind}
                size={buttonSize}
            />
            {selector &&
                <T.StyledSelector
                    top={up ? (top - toolbarHeight) : undefined}
                    width={mbir * (toolbarHeight + 2) + (belowBreakpoint({ theme }, "tablet") ? (-8) : 3)}
                    align="center"
                    toolbarHeight={toolbarHeight}
                    style={{ height: toolbarHeight * rows + 1, maxHeight: toolbarHeight * rows + 1, overflow: "auto" }}
                >
                    {_.map(persons, (p, idx) => {
                        // if (idx > 10)
                        //     return false;
                        return (<T.ColorSelectorContainer key={idx} >
                            <EmojiButton onClick={applyFormat(Emoji, p.item.unicode)} toolbarHeight={toolbarHeight}>
                                {getEmoji(p.item.unicode)}
                            </EmojiButton>
                        </T.ColorSelectorContainer>);
                    })}
                </T.StyledSelector>
            }
        </T.StyledList >
    );
}
export default Emojis;