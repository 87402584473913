import * as React from "react";
import * as _ from "lodash";
import useOnScreen from "imaginarity-react-ui/dist/helpers/useOnScreen";

type UseOnScreenDivProps = { work: () => void, delay?: number, treshold?: number | number[], onlyOnce?: boolean };

const useOnScreenDiv = (p: UseOnScreenDivProps) => {
    const { work, delay, onlyOnce, treshold } = p;

    const { visible: isOnScreen, setRef } = useOnScreen(treshold, onlyOnce);
    const getRef = React.useMemo(() => {
        const toRet: React.LegacyRef<HTMLDivElement> = (r) => {
            setRef(r);
        }
        return toRet;
    }, [setRef]);

    const workMem = React.useMemo(() => {
        return _.debounce(work, delay !== undefined ? delay : 1000);
    }, [delay, work])

    React.useEffect(() => {
        if (isOnScreen)
            workMem();
    },
        [isOnScreen, workMem]);

    //console.log("useOnScreenDiv.tsx::26 => isOnScreen", isOnScreen);
    //console.log("useOnScreenDiv.tsx::27 => ref", ref);

    return <div ref={getRef} style={{ width: 1, height: 1 }} />;
}

export default useOnScreenDiv;