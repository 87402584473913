import * as React from "react";
import { ComponentsSC as T } from "./ComponentsSC";

interface AppStartProps {
    progress: number;
    msg: string;
}

export default function AppStart(props: AppStartProps) {
    return (
        <T.AppStartContainer>
            <div />
            <T.Content>
                <T.Header>PAG Platform</T.Header>
                loading app...
            </T.Content>
            <T.Progress progress={props.progress} />
            <T.ProgressMsg>{props.msg}</T.ProgressMsg>
        </T.AppStartContainer>
    );
}