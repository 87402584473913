import { ContentPostContentDownloadDto } from "collaboration-service";
import { Embed } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "services/ApplicationState/ApplicationState";
import { getMediaLink } from "services/Helpers/MediaHelpers";
import { useImgI18N } from "services/ImgI18N";

interface TubeContentProps {
    namespace?: string;
    translatedContent: ContentPostContentDownloadDto;
    onEnded?: () => void;
    noAutoplay?: boolean;
    onTimeUpdate?: { percentage: number, callback: () => void };
}

export const setVideoDefaultTrackByLng = (vid: HTMLVideoElement | null | undefined, lng: string | undefined) => {
    if (vid && vid.readyState === 4) {
        const tracks = vid.textTracks;

        if (lng) {
            const t = tracks.getTrackById(lng);
            if (t) {
                _.forEach(tracks, tt => {
                    tt.mode = tt.id === t.id ? "showing" : "disabled";
                });
            }
        }
    }
}

const TubeContent = (p: TubeContentProps) => {
    const { translatedContent, onEnded, namespace, noAutoplay, onTimeUpdate } = p;
    const contentLanguage = useSelector((s: ApplicationState) => s.contentLanguage);
    const { t, currentLanguage } = useImgI18N(namespace ?? "tube");
    const vidRef = React.useRef<HTMLVideoElement | null>(null);
    const [, setCallOnTimeUpdate] = React.useState<boolean>(false);

    const { v, id, source } = React.useMemo(() => {
        const v = translatedContent.useContent ? getMediaLink(translatedContent, c => c.content) : translatedContent.link;
        const source: "vimeo" | "youtube" = v?.includes("vimeo") ? "vimeo" : "youtube";
        const split = !translatedContent.useContent && v?.split("/");
        let id = split ? split[split.length - 1] : "";
        if (source === "youtube")
            id = id.length > 0 ? id.substring(id.indexOf("=") + 1) : "";
        return {
            translatedContent,
            v,
            id,
            source,

        };
    }, [translatedContent])

    const rs = vidRef.current?.readyState ?? 0;
    const lng = contentLanguage ?? currentLanguage;

    React.useEffect(() => {
        if (rs === 4)
            setVideoDefaultTrackByLng(vidRef.current, lng);
    }, [vidRef, rs, lng]);

    const tu = (event: React.SyntheticEvent<HTMLVideoElement>) => {
        const v = event.currentTarget;
        if (onTimeUpdate && v.duration) {
            const p = 100 * v.currentTime / v.duration;
            if (onTimeUpdate?.percentage <= p)
                setCallOnTimeUpdate(t => {
                    if (!t)
                        onTimeUpdate.callback();
                    return true;
                });
        }
    };

    React.useEffect(() => {
        setCallOnTimeUpdate(false);
    }, [onTimeUpdate, translatedContent])

    return (
        <React.Fragment>
            {translatedContent.useContent ?
                <video
                    ref={vidRef}
                    controls
                    autoPlay={noAutoplay ? false : true}
                    onTimeUpdate={tu}
                    onEnded={onEnded}
                    playsInline
                    crossOrigin="anonymous"
                    src={v}
                >
                    {_.map(translatedContent.content.subtitles, (s, idx) => {
                        // console.log('s => ', s);
                        // console.log('lngDefinitions => ', lngDefinitions);
                        const l = _.find(s.links, l => l.ref === "self");
                        const label = t(`tube::${s.lng}`)
                        if (l && label) {

                            //console.log('#', idx, label, s.lng, l?.uri);

                            return (
                                <track key={s.reference} src={l?.uri} label={label} id={s.lng} kind="captions" srcLang={s.lng} default={false} />
                            );
                        }
                        return null;
                    })}
                </video>
                :
                <Embed
                    id={id}
                    source={source}
                />
            }
        </React.Fragment>
    )
}

export default TubeContent;