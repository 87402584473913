import { aboveBreakpoint, appendPx, belowBreakpointOrEqual, fadeColor, fromThemeCreator, fromThemeCreators, getColor, lightenColor, styled } from 'imaginarity-react-ui';
import { ContentTheme } from 'views/ContentPage/ContentMain';

const fromTheme = fromThemeCreators<ContentTheme>("contentTheme");
const selectFromTheme = fromThemeCreator<ContentTheme>("contentTheme");
export class ContentSC {
    public static ContentHomeMainContainer = styled.div`
        width:100%;
        text-align: left;
        margin: 24px 0;
        user-select: none;
        padding-bottom: 80px;
`;
    public static ContentHeaderBar = styled.div<{ darkMode: boolean }>`
        width:100%;
        height: 120px;
        //background: linear-gradient(30deg, rgb(1, 6, 20) 50px, rgba(5, 27, 76, 0.7) 20%, rgba(1, 6, 20, 0.7) calc(100% - 300px));
        background: ${p => p.darkMode ?
            "linear-gradient(30deg, rgb(1, 6, 20) 50px, rgba(5, 27, 76, 0.7) 20%, rgba(1, 6, 20, 0.7) calc(100% - 300px))"
            :
            "linear-gradient(30deg, " + lightenColor(p, '@veryLightGrey', 99) + " 50px, " + lightenColor(p, getColor(p, "@mainBackground"), 100) + " 35%, " + lightenColor(p, getColor(p, "@middleLightGrey"), 120) + " calc(100% - 200px))"
        };
    
        margin: 24px 0 10px 0;
        display: grid;
        display: -ms-grid; 
        grid-template-columns: 120px 1fr;
        -ms-grid-columns: 120px 1fr;
        grid-template-rows: 60px 60px;
        -ms-grid-rows:  60px 60px;
        transition: 0.2s all ease-out;
        position: relative;
`;
    public static ContentHeaderBarRightImage = styled.div`
        position: absolute;        
        ${p =>
            belowBreakpointOrEqual(p, "mobile") ? "background-image: none;" :
                // eslint-disable-next-line
                fromTheme.checkAndSet(p, x => x.headerRightImage.url, "background-image: url('${this}');")
        }
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: contain;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        pointer-events:none;
        opacity: ${p =>
            // eslint-disable-next-line
            belowBreakpointOrEqual(p, "tablet") ? 0.1 : fromTheme.checkAndSet(p, x => x.headerRightImage.opacity, "${this}")
        };
        ${p =>
            // eslint-disable-next-line
            fromTheme.checkAndSet(p, x => x.headerRightImage.filter, "filter: ${this};")
        }
`;
    public static ContentHeaderBarImage = styled.div<{ imageUrl: string }>`
        grid-column: 1;
        grid-row-start: 1;
        grid-row-end: 3;
        background-image: url('${p => p.imageUrl}');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        svg{
            fill: rgba(255,255,255,0.2);
            stroke:#0D0D0D;
            filter: drop-shadow( 0px 0px 5px rgba(255, 255, 255, 0.5));
        }
`;
    public static ContentHeaderBarRowTop = styled.div`
        grid-column: 2;
        grid-row-start: 1;
        grid-row-end: 2;
        padding: 5px 10px;
`;
    public static ContentHeaderBarSearch = styled.div<{ width: number }>`
        position: absolute;
        width: ${p => belowBreakpointOrEqual(p, "tablet") ? "calc(100% - 120px)" : appendPx(p.width)};
        height: auto;
        top: 40px; 
        left: ${p => belowBreakpointOrEqual(p, "tablet") ? "120px" : "50%"};
        transform: translateX(${p => belowBreakpointOrEqual(p, "tablet") ? 0 : "-50%"});
        z-index: 100;
`;
    public static ContentHeaderBarRowBottom = styled.div`
        grid-column: 2;
        grid-row-start: 2;
        grid-row-end: 3;
        line-height: 35px;
        font-size: ${p => belowBreakpointOrEqual(p, "tablet") ? 1.1 : 1.5}rem;
        padding: 10px;
        padding-top: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
    `;
    public static ContentPostContainer = styled.div<{ height?: string }>`
        position: relative;
        width: 100%;
        min-height: ${p => belowBreakpointOrEqual(p, "desktop") ? 180 : 230}px;
        padding-bottom: ${p => p.height ? p.height : belowBreakpointOrEqual(p, "mobile") ? " 100%" : belowBreakpointOrEqual(p, "tablet") ? "40%" : belowBreakpointOrEqual(p, "desktop") ? "20%" : "33%"};
        background: ${props => getColor(props, selectFromTheme(props, t => t.postItem.backgroundColor, t => fadeColor(props, getColor(props, "@accentBlack"), 30)))};
        color: ${props => getColor(props, selectFromTheme(props, t => t.postItem.color, t => getColor(props, "@mainBackground")))};
        transition: 0.2s all ease-out;
        cursor: ${p => p.height ? "default" : "pointer"};
        transform-origin: center center;
        border: 1px solid transparent;
        svg{
            stroke: rgba(255,255,255,0.3);
        }
        &:hover{
            transform: scale(${p => p.height ? 1 : 1.0});
            box-shadow: ${p => p.height ? "none" : "0px 0px 40px -15px " + getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent) + ", 0px 6px 12px -6px " + getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)};
            background:rgba(0,0,0,0.5);
            border: 1px ridge ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)};
            svg{
                stroke: rgba(255,255,255,0.8);
        }
        }      
    `;
    public static ContentCategoryContainer = styled(ContentSC.ContentPostContainer)`
        padding-bottom: 100%;
        overflow: hidden;
        color: ${props => getColor(props, selectFromTheme(props, t => t.postItem.color, t => getColor(props, "@mainBackground")))};
        cursor: pointer;
        transform-origin: center center;
        position: relative;
        //background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 70)};
        background: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).tile.backgroundColor)};
        transition: 0.2s all ease-out;
        border-bottom: 2px solid ${p => (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent};
        color: ${p => getColor(p, "@lightGrey")};
        &:hover{
            color: white;
            transform: scale(1.00);
            box-shadow: 0px 0px 60px -20px ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)}, 0px 6px 8px -10px ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)};
            border-bottom: 2px solid ${p => (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent};
            //filter: sepia(100%) hue-rotate(160deg) saturate(180%);
            img{
                transition: 0.2s all ease-out;
                filter: brightness(2);                
            }
        }      

    `;
    public static ContentFeed = styled.div<{ imageUrl: string }>`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: ${p => belowBreakpointOrEqual(p, "mobile") ? " 100%" : belowBreakpointOrEqual(p, "tablet") ? "40%" : belowBreakpointOrEqual(p, "desktop") ? "20%" : "33%"};
        background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 30)};
        background-image: url('${p => p.imageUrl}');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: contain;
    `;

    public static ContentCategoryVideo = styled(ContentSC.ContentFeed)`
        position: absolute;
        top: 0;
        left: 0;
        bottom: ${p => 7 / 16 * 100}%;
        width: 100%;
        //background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 20)};
        background-image: url('${p => p.imageUrl}');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    `;

    public static ContentText = styled.div`
        position: absolute;
        top: ${p => belowBreakpointOrEqual(p, "mobile") ? "56.25%" : 0};
        left: ${p => belowBreakpointOrEqual(p, "mobile") ? " 0%" : belowBreakpointOrEqual(p, "tablet") ? "40%" : belowBreakpointOrEqual(p, "desktop") ? "20%" : "33%"};
        bottom: ${p => belowBreakpointOrEqual(p, "mobile") ? 0 : 7 / 16 * 100 + "%"};
        width: ${p => belowBreakpointOrEqual(p, "mobile") ? " 100%" : belowBreakpointOrEqual(p, "tablet") ? "60%" : belowBreakpointOrEqual(p, "desktop") ? "80%" : "67%"};
        padding: 10px;
    `;
    public static ContentCategoryText = styled.div`
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 5px 10px;
        color: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).tile.color)};
    `;
    public static ContentCategoryImage = styled.div`
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        padding: 5px 10px;
    `;
    public static ContentButtons = styled.div`
        position: absolute;
        bottom: 0;
        left: ${p => belowBreakpointOrEqual(p, "mobile") ? " 100%" : belowBreakpointOrEqual(p, "tablet") ? "40%" : belowBreakpointOrEqual(p, "desktop") ? "20%" : "33%"};
        width: ${p => belowBreakpointOrEqual(p, "mobile") ? " 0%" : belowBreakpointOrEqual(p, "tablet") ? "60%" : belowBreakpointOrEqual(p, "desktop") ? "80%" : "67%"};
        height: 140px;
        background: red;
    `;
    public static ContentTableViewHeader = styled.div`
        width:100%;
        display: grid;
        display: -ms-grid; 
        grid-template-columns: 114px 1fr 32px 32px 32px 32px 32px ;
        -ms-grid-columns: 114px 1fr 32px 32px 32px 32px 32px ;
        grid-template-rows: 32px;
        -ms-grid-rows:  32px;
        text-align: center;
        line-height: 32px;
        font-weight: 600;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        border-bottom: 1px solid ${p => getColor(p, "@darkGrey")};
        margin-top: 10px;
`;
    public static ContentSidebarItem = styled.div<{ height: number, imageUrl: string }>`
        width:100%;
        height: ${p => p.height}px;
        overflow: hidden;
        background: ${props => getColor(props, selectFromTheme(props, t => t.postItem.backgroundColor, t => fadeColor(props, getColor(props, "@accentBlack"), 30)))};
        color: ${props => getColor(props, selectFromTheme(props, t => t.postItem.color, t => getColor(props, "@mainBackground")))};
        cursor: pointer;
        position: relative;
        background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 20)};
        background-image: url('${p => p.imageUrl}');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: 0.2s all ease-out;
        margin-bottom: 10px;
`;
    public static ContentSidebarItemTitleContainer = styled.div<{ height: number }>`
        position: absolute;
        bottom: calc(30px - ${p => p.height}px);
        right: 0;
        left: 0;
        height: ${p => p.height}px;
        background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 80)};
        overflow: hidden;  
        transition: all 0.2s ease;
        &:hover   
            {
            bottom: 0;
            background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 80)};
            *{
                border-color: ${p => getColor(p, "@accent")} !important;
                }
            }
`;
    public static ContentSidebarItemTitleContainerTeaser = styled.div`
        line-height: 29px;
        border-bottom: 1px solid ${p => fadeColor(p, getColor(p, "@accentBlack"), 40)};
        padding: 0 25px 0 10px;
        background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 40)};
        color: ${p => getColor(p, "@mainBackground")};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
`;
    public static ContentSidebarItemTitleContainerTeaserContent = styled.div`
        padding: 10px 25px 10px 10px;
        height: 90px;
        -ms-overflow-style: none;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -ms-box-orient: vertical;
        box-orient: vertical;
        -webkit-line-clamp: 4;
        -moz-line-clamp: 4;
        -ms-line-clamp: 4;
        line-clamp: 4;
`;
    public static ContentPostImageVideoType = styled.div<{ imageUrl?: string }>`
        position: absolute;
        top: 10px;
        left: 10px;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background: ${p => fadeColor(p, getColor(p, "@veryLightGrey"), 30)};
        border: 1px solid ${p => fadeColor(p, getColor(p, "@darkGrey"), 10)};
        background-image: ${p => p.imageUrl ? "url('" + p.imageUrl + "')" : "none"};
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80%;
`;
    public static ContentPostViewedSidebar = styled.div`
        position: absolute;
        bottom: 4px;
        right: 4px;
        width: 22px;
        height: 22px;
        border-radius: 11px;
        background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 60)};
        text-align: center;
        line-height: 12px;
        svg{
            stroke: ${p => getColor(p, "@mainForeground")};
            stroke-width: 4px;
        }
`;
    public static ContentPostDescriptionTitle = styled.div<{ viewed: boolean }>`
        position: absolute;
        top: ${p => belowBreakpointOrEqual(p, "mobile") ? 0 : 10}px;
        left: 10px;
        width: calc(100% - 10px);
        line-height: 25px;
        font-weight: 500;
        font-size: ${p => belowBreakpointOrEqual(p, "mobile") ? "1rem" : "1.25rem"};
        letter-spacing: 0.5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
        //color: ${p => p.viewed ? getColor(p, "@veryLightGrey") : getColor(p, "@mainBackground")};
        color: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).postItem.color)};
        svg{
            stroke: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)};
        }
`;
    public static ContentPostDescriptionViewed = styled.div`
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        line-height: 20px;
        svg{
            stroke: ${p => getColor(p, "@accent")};
            stroke-width: 3px;
        }
    `;

    public static ContentPostContentType = styled.div`
        position: absolute;
        top: ${p => belowBreakpointOrEqual(p, "mobile") ? "20px" : "35px"};
        left: 10px;
        width: calc(100% - 10px);
        line-height: 20px;
        font-size: 0.75rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: 200;
        color: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)};
        -ms-overflow-style: none;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -ms-box-orient: vertical;
        box-orient: vertical;
        -webkit-line-clamp: 1;
        -moz-line-clamp: 1;
        -ms-line-clamp: 1;
        line-clamp: 1;
`;
    public static ContentPostDescriptionText = styled.div`
        position: absolute;
        top: ${p => belowBreakpointOrEqual(p, "mobile") ? "40px" : "60px"};
        left: 10px;
        width: calc(100% - 20px);
        line-height: 16px;
        font-size: 0.75rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: 200;
        color: ${props => fadeColor(props, getColor(props, selectFromTheme(props, t => t.postItem.color, t => getColor(props, "@mainBackground"))), 70)};
        -ms-overflow-style: none;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -ms-box-orient: vertical;
        box-orient: vertical;
        -webkit-line-clamp: ${p => belowBreakpointOrEqual(p, "desktop") ? 2 : 3};
        -moz-line-clamp: ${p => belowBreakpointOrEqual(p, "desktop") ? 2 : 3};
        -ms-line-clamp: ${p => belowBreakpointOrEqual(p, "desktop") ? 2 : 3};
        line-clamp: ${p => belowBreakpointOrEqual(p, "desktop") ? 2 : 3};
`;
    public static ContentPostCategory = styled.div`
        position: absolute;
        top: ${p => belowBreakpointOrEqual(p, "mobile") ? 75 : belowBreakpointOrEqual(p, "desktop") ? 100 : 117}px;
        left: 10px;
        width: calc(100% - 20px);
        line-height: 0.8rem;
        font-size: 0.75rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
`;
    public static ContentPostFile = styled.div`
        position: absolute;
        top: ${p => belowBreakpointOrEqual(p, "mobile") ? "unset" : belowBreakpointOrEqual(p, "desktop") ? "120px" : "140px"};
        bottom: ${p => belowBreakpointOrEqual(p, "mobile") ? "25px" : "unset"};
        left: 10px;
        width: calc(100% - 10px);
        line-height: 1rem;
        font-size: 0.75rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
`;
    public static ContentPostCategoryItem = styled.div`
        display: inline-block;
        margin-right: 13px;
        border-left: 3px solid ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)};
        padding-left: 4px;
`;
    public static ContentPostFooterButton = styled.div`
        display: ${p => belowBreakpointOrEqual(p, "tablet") && aboveBreakpoint(p, "mobile") ? "none" : "block"};
        position: absolute;
        top: ${p => belowBreakpointOrEqual(p, "mobile") ? "unset" : belowBreakpointOrEqual(p, "desktop") ? "120px" : "180px"};
        bottom: ${p => belowBreakpointOrEqual(p, "mobile") ? "5px" : "unset"};
        right: ${p => belowBreakpointOrEqual(p, "mobile") ? "5px" : "10px"};
        transition: 0.2s all linear;
        &:hover{
            *{
                background: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)};
            }
        }
`;
    public static ContentPostFooterInfosTopLeftIcon = styled.div`
        position: absolute;
        top: ${p => belowBreakpointOrEqual(p, "mobile") ? "unset" : belowBreakpointOrEqual(p, "tablet") ? "135px" : belowBreakpointOrEqual(p, "desktop") ? "140px" : "160px"};
        bottom: ${p => belowBreakpointOrEqual(p, "mobile") ? "5px" : "unset"};
        left: 10px;
        width: 100%;
        height: 20px;
        line-height: 20px;
        overflow: hidden;
        font-size: 0.75rem;
        svg{
            margin-top: 1px;
            stroke: ${props => getColor(props, selectFromTheme(props, t => t.postItem.color, t => getColor(props, "@mainBackground")))};
        }
`;
    public static ContentPostFooterInfosTopLeftFigure = styled.div`
        position: absolute;
        top: 160px;
        left: 30px;
        width: 100%;
        height: 20px;
        line-height: 20px;
        overflow: hidden;
        font-size: 0.75rem;
`;
    public static ContentPostFooterInfosTopRightIcon = styled.div`
        position: absolute;
        top: ${p => belowBreakpointOrEqual(p, "mobile") ? "unset" : belowBreakpointOrEqual(p, "desktop") ? "120px" : "180px"};
        bottom: ${p => belowBreakpointOrEqual(p, "tablet") ? "20px" : "unset"};
        left: ${p => belowBreakpointOrEqual(p, "mobile") ? 10 : belowBreakpointOrEqual(p, "desktop") ? 10 : 10}px;
        width: 100%;
        height: 20px;
        line-height: 20px;
        overflow: hidden;
        font-size: 0.75rem;
        svg{
            margin-top: 1px;
            stroke: ${props => getColor(props, selectFromTheme(props, t => t.postItem.color, t => getColor(props, "@mainBackground")))};
        }
`;
    public static ContentPostFooterInfosTopRightFigure = styled.div`
        position: absolute;
        top: 2px;
        left: 122px;
        width: 62px;
        line-height: 20px;  
        height: 20px;
        line-height: 20px;
        overflow: hidden;
        font-size: 0.75rem;
`;
    public static ContentPostFooterInfosBottomFigure = styled.div`
        ${p => belowBreakpointOrEqual(p, "mobile") && "display: none;"}
        position: absolute;
        top: ${p => belowBreakpointOrEqual(p, "mobile") ? "unset" : belowBreakpointOrEqual(p, "tablet") ? "135px" : belowBreakpointOrEqual(p, "desktop") ? "140px" : "200px"};
        bottom: ${p => belowBreakpointOrEqual(p, "mobile") ? "5px" : "unset"};
        left: ${p => belowBreakpointOrEqual(p, "mobile") ? 242 : belowBreakpointOrEqual(p, "desktop") ? 210 : 120}px;
        width: 100%;
        height: 20px;
        line-height: 20px;
        overflow: hidden;
        font-size: 0.75rem;
`;
    public static ContentPostFooterInfosBottom = styled.div`
        position: absolute;
        top: ${p => belowBreakpointOrEqual(p, "mobile") ? "unset" : belowBreakpointOrEqual(p, "tablet") ? "135px" : belowBreakpointOrEqual(p, "desktop") ? "140px" : "200px"};
        bottom: ${p => belowBreakpointOrEqual(p, "mobile") ? "5px" : "unset"};
        left: ${p => belowBreakpointOrEqual(p, "mobile") ? 140 : belowBreakpointOrEqual(p, "desktop") ? 100 : 10}px;
        width: 100%;
        height: 20px;
        overflow: hidden;
        font-size: 0.75rem;
        svg{
            margin-top: 1px;
            stroke: ${props => getColor(props, selectFromTheme(props, t => t.postItem.color, t => getColor(props, "@mainBackground")))};
        }
`;
    public static ContentVideoGrid = styled.div<{ mobileView: boolean, contentWidth: number }>`
        width: ${p => p.mobileView ? p.contentWidth + "px" : "100%"};
        display: grid;
        display: -ms-grid; 
        grid-template-columns: ${p => p.mobileView ? "1fr" : "1fr 220px"};
        -ms-grid-columns: ${p => p.mobileView ? "1fr" : "1fr 220px"};
        grid-template-rows: 45px max-content 10px max-content 10px max-content 10px max-content min-content ${p => p.mobileView && "45px 1fr"};
        -ms-grid-rows:  45px max-content 10px max-content 10px max-content 10px max-content min-content ${p => p.mobileView && "45px 1fr"};
        grid-row-gap: 0px;
        grid-column-gap: ${p => belowBreakpointOrEqual(p, "wide") ? p.mobileView ? 0 : 20 : 80}px;
        transition: 0.2s all ease-out;
`;
    public static ContentVideoGridRight = styled.div<{ mobileView: boolean }>`
        grid-row-start: ${p => p.mobileView ? 11 : 2};
        grid-row-end: ${p => p.mobileView ? "unset" : 10};
        grid-column: ${p => p.mobileView ? 1 : 2};

`;
    public static ContentVideoGridLeftUser = styled.div<{ mobileView: boolean }>`
        grid-row: 4;        
        grid-column: 1;
        display: grid;
        display: -ms-grid; 
        grid-template-columns: 40px 1fr max-content ${p => p.mobileView && "max-content max-content"};
        -ms-grid-columns: 40px 1fr max-content ${p => p.mobileView && "max-content max-content"};
        grid-template-rows: 40px;
        -ms-grid-rows:  40px;
`;
    public static ContentVideoGridLeftDescription = styled.div<{ mobileView: boolean }>`
        grid-row: 6;
        grid-column: 1;
        display: grid;
        display: -ms-grid; 
        grid-template-columns: ${p => p.mobileView ? "1fr 40px" : "40px max-content 1fr 40px"};
        -ms-grid-columns: ${p => p.mobileView ? "1fr 40px" : "40px max-content 1fr 40px"};
        grid-template-rows: ${p => p.mobileView ? "max-content" : "max-content 10px 1fr"};
        -ms-grid-rows:  ${p => p.mobileView ? "max-content" : "max-content 10px 1fr"};
`;
    public static ContentVideoGridLeftComments = styled.div<{ mobileView: boolean }>`
        grid-row: 8;
        grid-column: 1;
        display: grid;
        display: -ms-grid;
        grid-template-columns: ${p => p.mobileView ? "0 1fr" : "40px 1fr"};
        -ms-grid-columns: ${p => p.mobileView ? "0 1fr" : "40px 1fr"};
        grid-template-rows: 40px minmax(160px, 1fr);
        -ms-grid-rows: 40px minmax(160px, 1fr);
`;
    public static ContentVideoGridLeftCommentsCommentsContainer = styled.div`
        grid-row: 1;
        grid-column: 2;
        background: rgba(0,0,0,0.1);
`;
    public static ContentVideoGridLeftCommentsCommentsRatings = styled.div`
        grid-row: 2;
        grid-column: 2;
        border: 1px solid #2B2A2D;
        margin-bottom:30px;
        margin-top:-1px;
`;
    public static ContentVideoGridLeftDescriptionTitle = styled.div`
        grid-row: 1;
        grid-column: 2;
        padding-left: 10px;
`;
    public static ContentVideoGridLeftDescriptionDescription = styled.div<{ mobileView: boolean }>`
        grid-row: 1;
        grid-column: ${p => p.mobileView ? 1 : 3};
        padding: 0 10px;
`;
    public static ContentVideoGridLeftMoreInfo = styled.div<{ mobileView: boolean }>`
        grid-row: ${p => p.mobileView ? 2 : 3};
        grid-column-start: ${p => p.mobileView ? 1 : 2};
        grid-column-end: ${p => p.mobileView ? 3 : 5};
        background: ${p => getColor(p, p.theme.colors.darkerGrey)};
        display: grid;
        display: -ms-grid; 
        margin-top: ${p => p.mobileView ? "10px" : 0};
        grid-template-columns: ${p => p.mobileView ? "max-content 1fr" : "max-content max-content 1fr max-content max-content 1fr max-content max-content"};
        -ms-grid-columns: ${p => p.mobileView ? "max-content 1fr" : "max-content max-content 1fr max-content max-content 1fr max-content max-content"};
        grid-template-rows: ${p => p.mobileView ? "max-content" : "max-content max-content minmax(0, 1fr)"};
        -ms-grid-rows: ${p => p.mobileView ? "max-content" : "max-content max-content minmax(0, 1fr)"};
        transition: 0.2s all ease-out;
`;
    public static ContentVideoGridLeftMoreInfoTitle = styled.div`
        font-weight: 600;
        line-height: 30px;
        padding: 0 10px;
`;
    public static ContentVideoGridLeftMoreInfoContent = styled.div`
        line-height: 30px;
        padding: 0 10px 0 0 ;
        *{
            stroke: #FFFFFF;
            svg{
                margin-top: 1px;
                margin-left: 5px;
                margin-right: 10px;
            }
        }
`;
    public static ContentVideoGridLeftMoreInfoLink = styled.div<{ mobileView: boolean }>`
        grid-row: ${p => p.mobileView ? 7 : 3};
        grid-column-start: ${p => p.mobileView ? 1 : 1};
        grid-column-end: ${p => p.mobileView ? 3 : 9};
        height: 40px;
        display: grid;
        display: -ms-grid; 
        grid-template-columns: 40px 1fr 40px;
        -ms-grid-columns: 40px 1fr 40px;
        grid-template-rows: 36px;
        -ms-grid-rows: 36px;
        overflow: hidden;
`;
    public static ContentVideoGridLeftMoreInfoLinkIcon = styled.div`
        text-align: center;
        line-height: 36px;
        height: 36px;
`;
    public static ContentVideoGridLeftUserAvatar = styled.div`
        grid-row: 1;
        grid-column: 1;
        background: rgba(0,0,0,0.05);
`;
    public static ContentVideoGridLeftUserName = styled.div`
        grid-row: 1;
        grid-column: 2;
        padding-left: 10px;
`;
    public static ContentVideoGridLeftUserNameName = styled.div`
        line-height: 18px;
        font-size: 0.85rem;
        font-weight: 500;
        letter-spacing: 0.5px;
`;
    public static ContentVideoGridLeftUserNameDate = styled.div`
        line-height: 18px;
        font-size: 0.7rem;
`;
    public static ContentVideoGridLeftUserRating = styled.div`
        grid-row: 1;
        grid-column: 3;
        padding-left: 5px;
        line-height: 40px;
`;
    public static ContentVideoGridLeftUserRatingFigure = styled.div`
        grid-row: 1;
        grid-column: 4;
        font-size: 0.7rem;
        line-height: 40px;
        padding-right: 5px;
`;
    public static ContentVideoGridLeftUserButtons = styled.div`
        grid-row: 1;
        grid-column: 5;
        text-align: right;
`;
    public static ContentVideoGridLeftHeaderBar = styled.div`
        grid-row: 1;
        grid-column: 1;
        line-height: 40px;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
        width: 100%;
`;
    public static ContentVideoGridLeftVideo = styled.div`
        grid-row: 2;
        grid-column: 1;
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        video, embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
    }
`;
    public static SectionConatinerTitle = styled.div`
        height: 40px;
        line-height: 40px;
        width: 100%;
        color: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).color)};
        //background: rgba(0,0,0,0.4);
        background: ${p => getColor(p, selectFromTheme(p, t => t.postItem.backgroundColor, t => getColor(p, "@darkerGrey")))};
        font-size: 1.0rem;
        padding-left: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
        border-left: 3px solid ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)};    
        margin-top: 0px;
`;
    public static SectionConatinerTitleButton = styled.div`
    float: right;
    height: 40px;
    width: 40px;
    &:hover{
            *{
                background: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)};
            }
        }   
`;
    public static BookmarkedAction = styled.div`
        padding-top: ${p => belowBreakpointOrEqual(p, "mobile") ? "unset" : belowBreakpointOrEqual(p, "tablet") ? "40%" : belowBreakpointOrEqual(p, "desktop") ? "20%" : "33%"};
        height: ${p => belowBreakpointOrEqual(p, "mobile") ? "100px" : "unset"};
        position: relative;
        width: 100%;
        background: rgba(0,0,0,0.05);
        transition: 0.2s all ease-out;
        cursor: pointer;
        overflow: hidden;
        .BookmarkedActionIcon{
            transition: 0.2s all ease-out;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .BookmarkedActionInfo{ 
            transition: 0.2s all ease-out;
            position: absolute;
            left: 0;
            right:0;
            bottom: ${p => belowBreakpointOrEqual(p, "mobile") ? "0px" : "-50px"};
            height: 50px;
            line-height: 50px;
            text-align: center;
            }
        svg{
            transition: 0.2s all ease-out;
            stroke: ${p => getColor(p, "@darkerGrey")};
        }
        &:hover{
            background: rgba(0,0,0,0.2);
            .BookmarkedActionIcon{
                top: calc(50% - 25px);
                transform: translate(-50%, -50%);
        }
        .BookmarkedActionInfo{ 
            bottom: 0px;
            background: rgba(0,0,0,0.3);
            transition: 0.2s all ease-out;
            }
            svg{
            transition: 0.2s all ease-out;
                stroke: ${p => getColor(p, "@middleLightGrey")};
            }
        }
`;
    public static DetailContainer = styled.div<{ mobileView: boolean }>`
        width: 100%;
        display: grid;
        grid-template-columns: ${p => p.mobileView ? "0 1fr 40px" : "1fr 1fr 40px"};
        grid-template-rows: ${p => p.mobileView ? "1fr" : "40px 12px 1fr 20px 20px 20px 20px max-content"};
`;
    public static DetailContainerImage = styled.div<{ image: string, mobileView: boolean }>`
        width: 100%;
        grid-column-start: ${p => p.mobileView ? 2 : 1};
        grid-column-end: ${p => p.mobileView ? 4 : 2};
        grid-row-start: ${p => p.mobileView ? 2 : 1};
        grid-row-end:  ${p => p.mobileView ? 2 : 8};
        padding-bottom: 56.25%;
        background-image: url(${p => p.image});
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        align-self: start;
        cursor: pointer;
        transition: all 0.2s ease-out;
        border: 1px solid transparent;
        box-shadow: none;
        &:hover{
            box-shadow: ${p => "0px 0px 60px -20px " + getColor(p, "@middleLightGrey") + ", 0px 6px 8px -10px " + getColor(p, "@mainBackground")};
        }
`;
    public static DetailContainerTitle = styled.div<{ mobileView: boolean }>`
        width: 100%;
        grid-column: 2;
        grid-row: 1;
        line-height: 40px;        
        padding-left: ${p => p.mobileView ? 0 : "40px"};
        font-size: 0.8rem;
        color: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)};
        text-transform: uppercase;
`;
    public static DetailContainerType = styled.div<{ mobileView: boolean }>`
        width: 100%;
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row: 2;
        line-height: 12px;
        font-size: 0.8rem;
        padding-left: ${p => p.mobileView ? 0 : "40px"};
`;
    public static DetailContainerDescription = styled.div<{ mobileView: boolean }>`
        width: 100%;
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row: 3;
        font-size: 0.9rem;
        color: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).color)};
        padding: 10px 10px 10px ${p => p.mobileView ? 0 : "40px"};
`;
    public static DetailContainerDescriptionDate = styled.div`
        display: block;
        width: 100%;
        margin-bottom: 10px;
        font-size: 0.75rem;
        color: ${p => fadeColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent, 70)};
`;
    public static DetailContainerFooterMediatype = styled.div<{ mobileView: boolean }>`
        width: 100%;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row: 6;
        line-height: 20px;
        color: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).color)};
        padding-left: ${p => p.mobileView ? 0 : "40px"};
        cursor: pointer;
        transition: all 0.2s ease-out;
        &:hover{
            color: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)}; 
            background: ${p => fadeColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent, 10)}; 
            svg{
                stroke: ${p => getColor(p, "@mainBackground")};
            }
        }
`;
    public static DetailContainerFooterCategories = styled.div<{ mobileView: boolean }>`
        width: 100%;
        grid-column-start: 2;
        grid-column-end: 3;
        /* grid-row: 4; */
        grid-row: 7;
        line-height: 20px;
        color: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).color)};
        padding-left: ${p => p.mobileView ? 0 : "40px"};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
`;
    public static DetailContainerFooterSystem = styled.div<{ mobileView: boolean }>`
        width: 100%;
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row: 5;
        line-height: 20px;
        color: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).color)};
        padding-left: ${p => p.mobileView ? 0 : "40px"};
        padding-right: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
`;
    public static DetailContainerFooterTags = styled.div<{ mobileView: boolean }>`
        width: 100%;
        grid-column-start: 2;
        grid-column-end: 3;
        //grid-row: 7;
        grid-row: 4;
        line-height: 20px;
        color: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).color)};
        padding-left: ${p => p.mobileView ? 0 : "40px"};
        padding-right: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
`;
    public static DetailContainerFooterItem = styled.div`
        display: inline-block;        
        margin-right: 10px;
        margin-top:4px;
        line-height: 12px;
        font-size: 0.75rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
`;
    public static DetailContainerBookmark = styled.div`
        width: 100%;
        grid-column: 3;
        grid-row: 1;
        line-height: 40px;
        background: ${p => getColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent)};
`;
    public static DetailContainerLink = styled.div`
        width: 100%;
        grid-column: 3;
        grid-row-start: 6;
        grid-row-end: 8;
        line-height: 40px;
        background: ${p => fadeColor(p, (p.theme.applicationTheming.contentTheme as ContentTheme).contentAccent, 10)};
        button>div{margin: 0;}
`;
    public static DetailContainerFooterRatings = styled.div<{ mobileView: boolean }>`
        width: calc(100% + ${p => p.mobileView ? 2 : 1}rem);
        margin-left: ${p => p.mobileView ? "-1rem" : 0};
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row: 8;
        padding: 10px 0px 10px ${p => p.mobileView ? 0 : "27px"};
`;
}