export const sanitizedAllowedTagsMediumSafety = ["h1", "h2", "h3", "h4", "u", "p", "a", "ul", "li", "strong", "em", "span", "ol", "br", "b", "i"];
export const sanitizedAllowedTagsHightSafety = ["strong", "em", "span", "br", "h1", "h2", "h3", "h4"];
export const sanitizedAllowedTagsMediumSafetyContent = ["h1", "h2", "h3", "h4", "u", "p", "a", "ul", "li", "strong", "em", "span", "ol", "br", "b", "i"];
export const sanitizedAllowedTagsPreviewInSidebar = ["u", "strong", "em", "span", "b", "i"];
export const sanitizedAllowedTagsPreview = ["h1", "h2", "h3", "h4", "u", "ul", "ol", "li", "strong", "em", "span", "b"];
export const sanitizedAllowedTagsMinimumSafety = ["strong", "em", "span", "b", "i"];
export const sanitizedNothingAllowed = [];

export const sanitizedAllowedAttributesMediumSafety = { i: ["style", "id", "class"], em: ["style", "id", "class"], strong: ["style", "id", "class"], b: ["style", "id", "class"], span: ["style", "id", "class"], a: ["href", "rel", "target", "class", "id"], p: ["style", "id", "class"] };
export const sanitizedAllowedAttributesHighSafety = { span: ["style"] };
export const sanitizedNothingAllowedAttributes = {};


