import { CollaborationServiceSetup, TokenContainer } from "collaboration-service";
import { LinkDto } from "imaginarity-azure";
type MobileHelperEventType = "startup" | "token" | "logout" | "requestLogin" | "login" | "registerPush" | "autoLogin" | "openLink" | "reset";

interface MobileHandlerData {
    type: MobileHelperEventType;
    data?: any;
}

class MobileHelper {
    private static instance: MobileHelper;
    private _appVersion: number = 1;
    private _iOS?: (data: MobileHandlerData) => void;
    private _android?: (data: MobileHandlerData) => void;
    private _loginErrorHandlerVar: (() => void) | undefined;
    private _loginSuccessHandlerVar: ((token: string) => void) | undefined;
    public static getInstance(): MobileHelper {
        if (!this.instance)
            this.instance = new MobileHelper();
        return this.instance;
    }

    private constructor() {
        // register token setter
        (window as any).loginErrorHandler = () => {
            if (this._loginErrorHandlerVar)
                this._loginErrorHandlerVar();
        };
        (window as any).loginSuccessHandler = (token: string) => {
            if (this._loginSuccessHandlerVar)
                this._loginSuccessHandlerVar(token);
        };
        (window as any).setMobileAppVersion = (version: number) => {
            this._appVersion = version;
        }
        this.initForiOs();
        this.initForAndroid();
    }
    public startup() {
        this.send({ type: "startup" });
    }
    public requestLogin() {
        this.send({ type: "requestLogin" });
    }
    public autoLogin() {
        this.send({ type: "autoLogin" });
    }
    public reset() {
        this.send({ type: "reset" });
    }
    public login(login: string, password: string) {
        const data = {
            login,
            password
        };
        this.send({ type: "login", data });
    }
    public logout() {
        this.send({ type: "logout" });
    }

    public get appVersion() {
        return this._appVersion;
    }
    public openLink(uri: string) {
        var link: LinkDto = {
            method: "GET",
            ref: "self",
            uri
        };
        this.send({ type: "openLink", data: link })
    }
    public showInfo(): string {
        return "ios = " + (this._iOS ? " <ok>" : " <not found>") + ", android = " + (this._android ? " <ok>" : " <not found>");
    }
    public mobileClient(): boolean {
        return this._iOS !== undefined || this._android !== undefined;
    }

    public get iOSClient() {
        return this._iOS !== undefined;
    }

    public get androidClient() {
        return this._android === undefined;
    }

    public registerPushNotifications(): void {
        const data: TokenContainer = { token: CollaborationServiceSetup.client.token };
        this.send({ type: "registerPush", data });
    }

    public set loginErrorHandler(handler: (() => void) | undefined) {
        this._loginErrorHandlerVar = handler;
    }
    public set loginSuccessHandler(handler: ((token: string) => void) | undefined) {
        this._loginSuccessHandlerVar = handler;
    }


    private initForiOs() {
        const a: any = (window as any).webkit;
        if (a && a.messageHandlers && a.messageHandlers.handler)
            this._iOS = (data: MobileHandlerData) => a.messageHandlers.handler.postMessage(data);
    }
    private initForAndroid() {
        //
    }
    private send(data: MobileHandlerData) {
        if (this._iOS)
            this._iOS(data);
        if (this._android)
            this._android(data);
    }
}

export default MobileHelper;