import { CMSController, ControllerHelper, FileEntryDownloadDto } from 'collaboration-service';
import MoreButton, { MoreButtonEntryProps } from 'components/MoreButton/MoreButton';
import { getLinkByName, Icon, ImgIcons } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { getFrontEndSettingFromState, setFrontEndSetting, shallowCompare } from 'services/Helpers';
import { hasLink } from 'services/Helpers/LinkHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { useAppTheme } from 'services/useAppTheme';
import { CMSColumnType } from './CMSFilesGrid';
import { CMSSC as T } from './CMSSC';
import CMSTableLegendItem from './CMSTableLegendItem';
import { CMSTheme } from './CMSTheme';

const mapper = (state: ApplicationState) => ({
    currentProject: state.cmsState.currentProject,
    showArchive: state.cmsState.showArchive,
    showUpload: state.cmsState.showUpload,
    showAddReference: state.cmsState.showAddReference,
    currentFiles: state.cmsState.currentProject?.files,
    routeParams: state.params,
    actionsDisabled: state.cmsState.directoryAndFileActionsDisabled,
});

const mapper1 = (state: ApplicationState) => ({
    user: state.user,
    currentProject: state.cmsState.currentProject,
});
interface CMSTableMenuProps {
    columns: CMSColumnType[];
    setColumnOrder: (order: string[]) => void;
    toggleRowSelected: (rowId: string, set?: boolean) => void;
    fSize: number;
    dSize: number;
}
export interface CMSFilesGridColumnDef {
    id: string;
    visible: boolean;
}
interface ColumnSortingProps {
    t: (val: string, data?: { [key: string]: any; }) => string;
    columns: CMSColumnType[];
    setColumnOrder: (order: string[]) => void;
}

const ColumnSorting = (p: ColumnSortingProps) => {
    const { currentProject, user } = useSelector(mapper1, shallowCompare);
    const { columns, t, setColumnOrder } = p;
    const [folderOptions, setFolderOptions] = React.useState<CMSFilesGridColumnDef[]>([]);

    React.useEffect(() => {
        if (currentProject) {
            const initFo: CMSFilesGridColumnDef[] = [
                { id: "name", visible: true },
                { id: "state", visible: true },
                { id: "version", visible: true },
                { id: "changed", visible: true },
                { id: "workflow", visible: true },
                { id: "workflow state", visible: true },
                { id: "size", visible: true },
            ];
            const fo = getFrontEndSettingFromState<CMSFilesGridColumnDef[]>(user, `folderOptions-${currentProject.id}`);
            if (fo === undefined || initFo.length !== fo.length) { // initial values
                setFrontEndSetting(user, `folderOptions-${currentProject.id}`, changer => initFo);
                setFolderOptions(initFo);
            }
            else
                setFolderOptions(fo);
        }
        else
            setFolderOptions([]);
    }, [user, currentProject]);

    React.useEffect(() => {
        _.forEach(folderOptions, fo => {
            const c = _.find(columns, c => c.id === fo.id) as any;
            if (c && c.toggleHidden && c.isVisible !== fo.visible)
                c.toggleHidden();
            setColumnOrder(_.map(folderOptions, f => f.id));
        })
        if (currentProject)
            setFrontEndSetting(user, `folderOptions-${currentProject.id}`, changer => folderOptions);
    }, [folderOptions, currentProject, user, columns, setColumnOrder]);

    const menuItem = React.useMemo(() => (onClick: (() => void) | undefined, icon: ImgIcons, text: string, up: (() => void) | undefined, down: (() => void) | undefined,) => {
        const upOrDown = up !== undefined || down !== undefined;
        return (
            <T.TableGridMoreMenuItemSorting>
                <div onClick={onClick}>
                    <Icon name={icon} style={{ float: "left", marginLeft: 10, marginRight: 10 }} />
                </div>
                <div onClick={onClick}>{t(text)}</div>
                {upOrDown &&
                    <>
                        <div onClick={down}>
                            <Icon name={down ? "chevron down" : "empty"} style={{ float: "right", marginLeft: 10, marginRight: 10 }} color={onClick === undefined ? "@lightGrey" : "@darkerGrey"} />
                        </div>
                        <div onClick={up}>
                            <Icon name={up ? "chevron up" : "empty"} style={{ float: "right", marginLeft: 10, marginRight: 10 }} color={onClick === undefined ? "@lightGrey" : "@darkerGrey"} />
                        </div>
                    </>
                }
            </T.TableGridMoreMenuItemSorting>
        );
    }, [t]);

    const foList = React.useMemo(() => {
        const toggleFolderOption = (name: string) => {
            const tmp = _.clone(folderOptions);
            const idx = _.findIndex(folderOptions, f => f.id === name);
            if (idx >= 0) {
                tmp[idx].visible = !tmp[idx].visible;
                setFolderOptions(tmp);
            }
        };
        const toggle = (name: string) => {
            const f = _.find(columns, c => c.id === name) as any;
            if (f && f.toggleHidden)
                return () => {
                    f.toggleHidden();
                    toggleFolderOption(name);
                };
            else
                return undefined;
        };

        const moveFolderOptions = (idx: number, up?: boolean) => {
            const fo = _.clone(folderOptions);
            const i = up ? -1 : 1;
            const s = fo[idx];
            fo[idx] = fo[idx + i];
            fo[idx + i] = s;
            setFolderOptions(fo);

        };
        const checked = (name: string) => {
            const f = _.find(folderOptions, c => c.id === name);
            return f && f.visible ? "checkbox checked" : "checkbox unchecked";
        };
        const foList = _.map(folderOptions, (fo, i) => {
            const up = i > 0 ? () => {
                moveFolderOptions(i, true);
            } : undefined;
            const down = i < folderOptions.length - 1 ? () => {
                moveFolderOptions(i, false);
            } : undefined;
            return menuItem(toggle(fo.id), checked(fo.id), fo.id, up, down);
        });
        return foList
    }, [folderOptions, menuItem, columns]);

    return (<>{foList}</>);
}

const CMSTableMenu = (p: CMSTableMenuProps) => {
    const { currentProject, showArchive, showUpload, showAddReference, currentFiles, routeParams, actionsDisabled } = useSelector(mapper, shallowCompare);
    const { t } = useImgI18N("cms");
    const { columns, setColumnOrder, toggleRowSelected, dSize, fSize } = p;
    const [colsOpen, setColsOpen] = React.useState(false);

    const dispatch = useAppDispatch();
    const cmsTheme = useAppTheme<CMSTheme>("cmsTheme");
    const routePath = routeParams?.path as string[];
    const path = routePath ? _.join([...routePath], "/") : "";

    const toggleArchive = React.useMemo(() => () => {
        dispatch(Actions.setCMSShowArchive(!showArchive));
    }, [dispatch, showArchive]);

    const selectDirs = React.useMemo(() => (value: boolean) => () => {
        for (let i = 0; i < dSize; i++) {
            toggleRowSelected('' + i, value);
        }
    }, [toggleRowSelected, dSize]);

    const selectFiles = React.useMemo(() => (value: boolean) => () => {
        for (let i = dSize; i < dSize + fSize; i++) {
            toggleRowSelected('' + i, value);
        }
    }, [toggleRowSelected, dSize, fSize]);

    const toggleUpload = React.useMemo(() => () => {
        dispatch(Actions.setCMSShowUpload(!showUpload));
    }, [dispatch, showUpload]);
    const toggleAddReference = React.useMemo(() => () => {
        dispatch(Actions.setCMSShowAddReference(!showAddReference));
    }, [dispatch, showAddReference]);

    const projectGroupId = currentProject?.id;

    const addDirectory = React.useCallback(() => {
        const l = async () => {
            if (projectGroupId && path !== undefined) {
                var res = await ControllerHelper.singleCall({ projectGroupId, path }, CMSController.AddFolder, true);
                dispatch(Actions.setCMSFolderToRename(res));
            }
        };
        l();
    }, [projectGroupId, path, dispatch]);

    const create = getLinkByName(currentProject, "createContent");
    const createDir = hasLink(currentProject, "createDirectory");

    const entries = React.useMemo(() => {
        const toRet: MoreButtonEntryProps<FileEntryDownloadDto>[] = [];

        toRet.push({ onClick: selectDirs(true), icon: "check square", content: t("select folders"), hideOnClick: true });
        toRet.push({ onClick: selectDirs(false), icon: "square", content: t("deselect folders"), hideOnClick: true });
        toRet.push({ onClick: selectFiles(true), icon: "check square", content: t("select files"), hideOnClick: true });
        toRet.push({ onClick: selectFiles(false), icon: "square", content: t("deselect files"), hideOnClick: true });

        toRet.push({ onClick: toggleArchive, icon: "box", content: showArchive ? t("show archived files") : t("hide archived files"), hideOnClick: true });
        if (create)
            toRet.push({ onClick: toggleUpload, icon: "upload", content: t("upload file"), hideOnClick: true });
        if (create)
            toRet.push({ onClick: toggleAddReference, icon: "file reference", content: t("add reference from other file"), hideOnClick: true });
        if (createDir)
            toRet.push({ onClick: addDirectory, icon: "folder plus", content: t("add new folder") });
        if (colsOpen)
            toRet.push({ onClick: () => setColsOpen(!colsOpen), icon: "cog", content: t("close column options") });
        toRet.push({
            onClick: () => setColsOpen(!colsOpen),
            icon: "cog",
            content: t("column options"),
            renderSubComponent: () => <ColumnSorting columns={columns} t={t} setColumnOrder={setColumnOrder} />,
            showSubComponent: colsOpen
        });

        return toRet;
    }, [t, create, showArchive, toggleArchive, toggleUpload, toggleAddReference, colsOpen, columns,
        setColumnOrder, addDirectory, createDir, selectDirs, selectFiles]);



    return (<MoreButton
        ele={currentFiles as FileEntryDownloadDto}
        entries={entries}
        hideMoreButton
        floated='right'
        showFooter={colsOpen && cmsTheme.showCmsTableMenuLegend}
        disabled={actionsDisabled}
        renderFooter={() => <T.TableGridMoreMenuLegend>
            <T.TableGridMoreMenuLegendLeft><CMSTableLegendItem icon="checkbox unchecked" text={t("hide column")} /></T.TableGridMoreMenuLegendLeft>
            <T.TableGridMoreMenuLegendRight><CMSTableLegendItem icon="chevron up" text={t("move column up")} /></T.TableGridMoreMenuLegendRight>
            <T.TableGridMoreMenuLegendLeftChecked><CMSTableLegendItem icon="checkbox checked" text={t("show column")} /></T.TableGridMoreMenuLegendLeftChecked>
            <T.TableGridMoreMenuLegendRight><CMSTableLegendItem icon="chevron down" text={t("move column down")} /></T.TableGridMoreMenuLegendRight>
        </T.TableGridMoreMenuLegend>
        }
    />
    );
}
export default CMSTableMenu;