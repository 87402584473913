import { EventController, EventTypeEnum, EventUploadDto, PostDownloadDto } from "collaboration-service";
import { getBrowser } from "imaginarity-platform-registration";

export function nop() { /**/ }



export function createEvent(eventType: EventTypeEnum) {
    const ev: EventUploadDto = {
        eventType,
    };
    return ev;
}

export function sendEvent(ev: EventUploadDto) {
    EventController.PostEvent({ ev });
}

export function userViewedEvent(post: PostDownloadDto) {
    const ev: EventUploadDto = {
        eventType: "PostViewed",
        group_id: post.group_id,
        payload: {
            "PostId": post.id,
            browser: getBrowser()
        }
    };
    EventController.PostEvent({ ev });
}

export type LinkSource = "search" | "category" | "bookmarks" | "admin" | "community" | "directLink" | "other";
export const linkSourceElements = ["search", "category", "bookmarks", "admin", "community", "directLink", "other"]

export function userVisitedLink(post: PostDownloadDto, source?: LinkSource, searchedFor?: string[]) {
    const ev: EventUploadDto = {
        eventType: "LinkVisited",
        group_id: post.group_id,
        payload: {
            "PostId": post.id,
            "linkSource": source ?? "direktLink",
            browser: getBrowser(),
        }
    };
    if (searchedFor && ev.payload)
        ev.payload.searchedFor = searchedFor;
    EventController.PostEvent({ ev });
}
