import { LanguageSelect } from 'imaginarity-react-ui';
import { rootElement } from 'index';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { languages as lngs } from 'services/Config';
import { shallowCompare } from 'services/Helpers';
import { useImgI18N } from 'services/ImgI18N';
import { ThemeContext } from 'styled-components';
import { createFilledStyles } from 'views/Workflow/State/Operations/CopyToGroupWorkflowTransitionOperation';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage });

interface ContentLanguageSelectProps {
    languages?: string[];
    menuPortalTarget?: HTMLElement | null;
    isDisabled?: boolean;
    onSelect?: (lng: string) => void;
    language?: string;
    noRoot?: boolean;
    color?: string;
    dropUp?: boolean;
    maxMenuHeight?: number;
    menuShouldBlockScroll?: boolean;
}

const ContentLanguageSelect = (p: ContentLanguageSelectProps) => {
    const { onSelect, languages, isDisabled, noRoot, language, color, dropUp, maxMenuHeight, menuShouldBlockScroll } = p
    const dispatch = useAppDispatch();
    const { contentLanguage } = useSelector(mapper, shallowCompare);
    const { currentLanguage, t } = useImgI18N("general");
    const theme = React.useContext(ThemeContext);

    const emptyStyles = React.useMemo(() => createFilledStyles(theme, false, color, [5, 0, 10, 0]), [theme, color]);

    const lng = language ?? (contentLanguage ?? currentLanguage);

    const update = React.useMemo(() => (lng: string) => {

        if (onSelect)
            onSelect(lng);
        else
            dispatch(Actions.setContentLanguage(lng));
    }, [dispatch, onSelect]);
    return (
        <LanguageSelect
            languageList={languages ?? lngs}
            onChange={update as any}
            value={lng}
            translationFunction={t}
            menuPortalTarget={noRoot ? undefined : rootElement}
            isDisabled={isDisabled}
            styles={color !== undefined ? emptyStyles : undefined}
            dropUp={dropUp ?? false}
            maxMenuHeight={maxMenuHeight}
            menuShouldBlockScroll={menuShouldBlockScroll}
        //isMulti={true}
        />
    );
}
export default ContentLanguageSelect;