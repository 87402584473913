import { CategoryDownloadDto, ContentController, ControllerHelper, ControllerMethod } from 'collaboration-service';
import LoadingDataTable from 'components/LoadingDataTable/LoadingDataTable';
import EditPartContainer from 'components/PostEdit/EditPartContainer';
import { ExtendedDataCollection } from "imaginarity-azure";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { gotoNamedRoute } from 'services/Helpers/RoutingHelper';
import { useImgI18N } from 'services/ImgI18N';
import { CMSSC as T } from 'views/CMS/CMSSC';

interface PostTableCategoryLikeProps<CategoryDownloadDto> {
    namespace: string;
    kind: string;
    getterItems?: ControllerMethod<CategoryDownloadDto[], { groupid: string; }>;
    path?: string;
}

const PostTableCategoryLike = (p: PostTableCategoryLikeProps<CategoryDownloadDto>) => {
    const dispatch = useAppDispatch();
    const { namespace, kind, getterItems, path } = p;
    const { t } = useImgI18N(namespace);
    const [/*loading*/, setLoading] = React.useState<boolean>(false);
    const group = useSelector((s: ApplicationState) => s.currentGroup);

    const id = group?.id ?? '';
    const title = t('{{kind}} of group "{{group}}"', { kind: kind, group: group?.name ?? "" });

    const loadTableData = React.useMemo(() => (data: { groupid: string; }, onSuccess?: (result: ExtendedDataCollection<CategoryDownloadDto, string>) => void, onError?: (reason: any) => void) => {
        if (getterItems)
            getterItems(data, s => {
                if (onSuccess)
                    onSuccess({ data: "", elements: s, links: [] });
            });
    }, [getterItems]);

    const canLoad = React.useMemo(() => (l: any) => {
        return l.groupid !== "" && l.groupid !== undefined;
    }, []);

    const onEdit = React.useMemo(() => (data: CategoryDownloadDto) => {
        gotoNamedRoute("admin_edit_category_like", { kind, groupId: data.group_id, id: data.id, path: path ?? '' });
    }, [kind, path]);

    const onDelete = React.useMemo(() => async (data: CategoryDownloadDto) => {
        await ControllerHelper.singleCall({ id: data.id }, ContentController.DeleteContent);
    }, []);

    const add = React.useMemo(() => () => {
        gotoNamedRoute("admin_edit_category_like", { kind, groupId: id, id: 'undefined', path: path ?? '' })
    }, [kind, id, path]);

    React.useEffect(() => {
        dispatch(Actions.setAdd(add));
    }, [dispatch, add]);

    return (
        <div>
            <LoadingDataTable
                namespace={namespace}
                loadData={loadTableData}
                canLoad={canLoad as any}
                onEdit={onEdit}
                onDelete={onDelete}
                onLoadingChange={setLoading}
                loaderData={{
                    groupid: id,
                }}
                columns={[
                    {
                        elementName: "names",
                        width: 1,
                        align: "left",
                        columnCreator: "lngString",
                    },
                    {
                        elementName: "type",
                        width: 1,
                        align: "left",
                        filterFn: "text",
                    },
                    {
                        elementName: "categoryType",
                        width: 1,
                        align: "left",
                        filterFn: "text",
                    },
                ]}
                renderTable={(Table) => (
                    <EditPartContainer icon="tag" title={kind} translatedTitle={title} bold>
                        <Table />
                    </EditPartContainer>
                )}
                renderForEmpty={() => <>
                    <T.DropdownInfo>
                        <T.DropdownInfoText>
                            {t('no data available for group "{{group}} "', { group: group?.name ?? "" })}
                        </T.DropdownInfoText>
                    </T.DropdownInfo>
                </>}
                bottomLineButtons={[
                    {
                        icon: "add",
                        kind: "cancelButton",
                        // iconColor: "inherit",
                        tooltip: { tooltipText: t("add new ") + kind, position: "top" },
                        onClick: add, //gotoNamedRouteMethod("edit_category_like", { kind, groupId: id }),
                        content: t("add new")
                    }
                ]}
            />
        </div>
    );
}
export default PostTableCategoryLike;