
export interface ThemeHeaderContainer {
    headerImageUrl: string;
    headerContentImageUrl: string;
    backgroundRepeat: string;
    backgroundPosition: string;
    backgroundSize: string
}
export interface CMSSelectColors {
    emptyColor: string;
    filledColor: string;
    emptyFadings?: number[];
    filledFadings?: number[];
}
export interface CMSThemeIcons {
    powerPoint: string;
    excel: string;
    word: string;
    pdf: string;
    xml: string;
    png: string;
    jpeg: string;
    zip: string;
    image: string;
    video: string;
    audio: string;
    svg: string;
    text: string;
    contentPost: string;
    tubePost: string;
    quiz: string;
    question: string;
    wikiArticle: string;
    slideShow: string;
    feedPost: string;
    unknown: string;
    podcastPost: string;
}
export interface CMSTheme {
    icons: CMSThemeIcons;
    headerImage: ThemeHeaderContainer;
    showCmsTableMenuLegend: boolean;
    showCmsTableSortingAsTabs: boolean;
    showCmsHomeTableBottomLineButtons: boolean;
    showCmsAdditionalButtonsOnAdminTabs: boolean;
    emptyImageUrl: string;
    select: CMSSelectColors;
    openPreviewOnFilenameClick: boolean;
}

export const cmsTheme: CMSTheme = {
    showCmsTableMenuLegend: false,
    showCmsTableSortingAsTabs: false,
    showCmsHomeTableBottomLineButtons: true,
    showCmsAdditionalButtonsOnAdminTabs: true,
    emptyImageUrl: "https://cdn.imaginarity.com/public/images/pagXreduced.svg",
    openPreviewOnFilenameClick: true,

    select: {
        emptyColor: "@accentBlue",
        filledColor: "@accentLightBlue",
        emptyFadings: [5, 10, 15, 20],
        filledFadings: [5, 10, 15, 20],
    },
    headerImage: {
        headerImageUrl: "https://cdn.imaginarity.com/public/cms/cmsHeaderBG.jpg",
        headerContentImageUrl: "https://cdn.imaginarity.com/public/cms/cms-content-r.jpeg",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
    },
    icons: {
        powerPoint: "https://cdn.imaginarity.com/public/icons/filesNew/pptx.svg",
        excel: "https://cdn.imaginarity.com/public/icons/filesNew/xlsx.svg",
        word: "https://cdn.imaginarity.com/public/icons/filesNew/docx.svg",
        pdf: "https://cdn.imaginarity.com/public/icons/filesNew/pdf.svg",
        xml: "https://cdn.imaginarity.com/public/icons/filesNew/xml.svg",
        png: "https://cdn.imaginarity.com/public/icons/filesNew/png4.svg",
        jpeg: "https://cdn.imaginarity.com/public/icons/filesNew/jpg.svg",
        zip: "https://cdn.imaginarity.com/public/icons/filesNew/zip2.svg",
        image: "https://cdn.imaginarity.com/public/icons/filesNew/image.svg",
        video: "https://cdn.imaginarity.com/public/icons/filesNew/video2.svg",
        audio: "https://cdn.imaginarity.com/public/icons/filesNew/audio.svg",
        svg: "https://cdn.imaginarity.com/public/icons/filesNew/svg.svg",
        text: "https://cdn.imaginarity.com/public/icons/filesNew/text.svg",
        unknown: "https://cdn.imaginarity.com/public/icons/filesNew/blanc.svg",
        // NEW as file icon
        // podcast: "https://cdn.imaginarity.com/public/icons/filesNew/podcast.svg",

        contentPost: "https://cdn.imaginarity.com/public/thepage/content/smwIcon.svg",
        // NEW as module icon
        podcastPost: "https://cdn.imaginarity.com/public/podcast/podcastIcon.svg",
        tubePost: "",
        quiz: "",
        question: "",
        wikiArticle: "",
        slideShow: "",
        feedPost: "",


    },
}
