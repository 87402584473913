import { styled } from "imaginarity-react-ui";

export class HistorySC {
    public static HistoryViewContainer = styled.div`
            width:100%;
            text-align: left;
            margin: 24px 0;
            user-select: none;
            padding-bottom: 80px;
`;
    public static Grid = styled.div`
            display: grid;
            grid-template-columns: 200px, 1fr;
            grid-template-rows: 1fr;
`;
    public static List = styled.div`
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
`;
    public static ListTop = styled.div`
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
`;
    public static ListBottom = styled.div`
            grid-column: 1 / span 1;
            grid-row: 2 / span 1;
`;
    public static Details = styled.div`
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;
`;
}