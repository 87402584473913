import { getColor, Icon, keyframes, styled } from 'imaginarity-react-ui';

const fadeIn = keyframes`
    from {opacity: 0;}
    to {opacity: 1;}
  `;

export class BreadcrumbsSC {
    public static Container = styled.div<{ darkMode: boolean }>`
        width:100%;
        text-align: left;
        height: 40px;
        line-height: 40px;
        margin-bottom: 10px;
        color: ${p => p.darkMode ? getColor(p, "@lightGrey") : getColor(p, "@mainForeground")};
        transition: all 0.2s ease-out;
`;
    public static Home = styled(Icon) <{ darkMode: boolean }>`
        float: left;
        cursor: pointer;
        svg{
            transition: all 0.2s ease-out;
            stroke: ${p => p.darkMode ? getColor(p, "@lightGrey") : getColor(p, "@mainForeground")};
            &:hover{
                stroke: ${p => p.darkMode ? getColor(p, "@mainBackground") : getColor(p, "@accentBlack")};
            }
        }
`;
    public static ParentCategory = styled.div<{ darkMode: boolean, accent?: string }>`
        float: left;
        position: relative;
        margin: 0 5px;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        color: ${p => p.darkMode ? getColor(p, "@lightGrey") : getColor(p, "@mainForeground")};
        &:hover{
            border-bottom: 2px solid ${p => p.accent ? p.accent : getColor(p, "@accent")};
            color: ${p => p.darkMode ? getColor(p, "@mainBackground") : getColor(p, "@accentBlack")};
        }
`;
    public static Chevron = styled(Icon) <{ darkMode: boolean, opened: boolean, hasNeighbourCategories: boolean, accent?: string }>`
        float: left;
        cursor: ${p => p.hasNeighbourCategories ? "pointer" : "default"};
        svg{
            stroke: ${p => p.opened ? p.accent ? getColor(p, p.accent) : getColor(p, "@accent") : p.darkMode ? getColor(p, "@lightGrey") : getColor(p, "@mainForeground")};
            
            transition: all 0.2s ease-out;
            transform-origin: center center;
            transform: rotate(${p => p.opened ? 90 : 0}deg);
            &:hover{
                transform: rotate(${p => p.opened ? 90 : p.hasNeighbourCategories ? 90 : 0}deg);
                stroke: ${p => p.hasNeighbourCategories ? p.darkMode ? getColor(p, "@mainBackground") : getColor(p, "@accentBlack") : p.darkMode ? getColor(p, "@lightGrey") : getColor(p, "@mainForeground")};

            }
        }
`;
    public static ParentItem = styled.div<{ darkMode: boolean }>`
        float: left;
        margin: 0 5px;
        color: ${p => p.darkMode ? getColor(p, "@mainBackground") : getColor(p, "@accentBlack")};
`;
    public static MoreParentCategoriesContainer = styled.div<{ darkMode: boolean }>`
        background: rgba(13, 13, 13, 0.9);
        z-index: 500;
        line-height: 24px;
        position: absolute;
        top: 30px;
        left: -27px;
        width: 200px;
        padding-right: 3px;
        max-height: 250px;
        overflow-y: auto;
        box-shadow: 0 6px 8px -6px #000;
        animation: ${fadeIn} 0.2s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
`;
    public static MoreParentCategoriesItem = styled.div<{ darkMode: boolean, accent?: string }>`
        transition: all 0.2s ease-out;
        cursor: pointer;
        padding: 0px 10px 0px 5px;
        &:hover{
            background: ${p => getColor(p, p.accent ?? "@accent")};
            svg{
                stroke: ${p => p.darkMode ? getColor(p, "@mainBackground") : getColor(p, "@accentBlack")};
            }
        }
        svg{
            stroke: ${p => p.darkMode ? getColor(p, "@lightGrey") : getColor(p, "@mainForeground")};
        }
`;
    public static FolderIcon = styled(Icon) <{ darkMode: boolean }>`
        float: left;
        margin-right: 6px;
        margin-top: 3px;
        svg{
            stroke: ${p => p.darkMode ? getColor(p, "@lightGrey") : getColor(p, "@mainForeground")};
        }
`;
}