import ContentLanguageSelect from 'components/ContentLanguageSelect';
import GroupSelection from 'components/General/GroupSelection';
import InfoBox from 'components/InfoBox/InfoBox';
import SidebarTitleContainer from 'components/Sidebars/SidebarTitleContainer';
import { GroupBase, ImgSelect, OptionProps, components } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import { createCheckWhereIAm } from 'services/Helpers/RoutingHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import styled from 'styled-components';
import { LocalStateEnum } from './PlaylistDetail';

const mapper = (state: ApplicationState) => ({
    ...state.playlistsState,
    route: state.route,
    currentAppEntry: state.currentAppEntry,
    currentAppView: state.currentAppView,
    contentLanguage: state.contentLanguage,
    routeParams: state.params
});

const PlaylistImage = styled.div<{ url: string }>`
    width: 24px;
    height: 24px;
    float: left;
    margin-top: -3px;
    margin-right: 10px;
    background-image: url(${props => props.url});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%; 
`;


interface ListItem {
    state: LocalStateEnum;
    checked: boolean;
}

export interface PlaylistContentInteractionProps extends ImgI18NTranslatedComponentProps, ComponentWithMappedApplicationState<typeof mapper> {
}

export interface PlaylistContentInteractionState {
    ordering: "alpha" | "date";
    playlistStates: ListItem[];
}

class PlaylistContentInteraction extends React.Component<PlaylistContentInteractionProps, PlaylistContentInteractionState> {

    constructor(props: PlaylistContentInteractionProps) {
        super(props);

        this.state = {
            ordering: "date",
            playlistStates: [
                { state: 'Finished', checked: true },
                { state: 'On Track', checked: true },
                { state: 'Overdue', checked: true },
                { state: 'Warning', checked: true },
            ],
        }
    }

    public render() {
        const check = createCheckWhereIAm(this.props.currentAppEntry, this.props.currentAppView);
        const isManagePlaylists = check("playlist_manage");
        const isPlaylistDetail = check("playlist_detail");

        interface ItemsData {
            value: string;
            label: string;
            img: string;
        }

        const playlists = _.map(_.filter(this.props.playlistStatesTopic, p => p.state !== "Finished"), (p, j) => {
            if (!p)
                return undefined;

            return {
                value: p.id,
                label: getTranslated(p.playlist.headlines, this.props.contentLanguage)?.text,
                img: getMediaLink(p.playlist, d => d?.media)
            };
        })

        const Option = (p: OptionProps<ItemsData, false, GroupBase<ItemsData>>) => {
            const { data } = p;
            const imgUrl = data.img ?? "";
            return (
                <components.Option {...p} >
                    <PlaylistImage url={imgUrl} />
                    <div>{data.label}</div>
                </components.Option>
            )
        }

        return (
            <>
                <SidebarTitleContainer icon="language" title={this.props.t("content language")} />
                <ContentLanguageSelect />
                {isManagePlaylists &&
                    <GroupSelection
                        groupTypes={["WIKI"]}
                        title={("group selection")}
                        icon="users"
                    />
                }

                {isPlaylistDetail &&
                    <>
                        <SidebarTitleContainer icon="playlist" title={this.props.t("your playlists")} />
                        <ImgSelect
                            options={playlists as any}
                            onChange={() => undefined}
                            components={{
                                Option: Option,
                            }}
                        />
                    </>
                }

                {isPlaylistDetail &&
                    <>
                        <div style={{ marginTop: 50 }} />
                        <InfoBox content='sidebar not yet functional' icon='exclamation' darkMode noBold type='info' noUppercase />
                    </>
                }
            </>
        );
    }
}

export default translate("playlist")(connect(mapper)(PlaylistContentInteraction));