import { FileEntryDownloadDto, PostDownloadDto } from 'collaboration-service';
import InfoBox from 'components/InfoBox/InfoBox';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Button, Image } from 'imaginarity-react-ui';
import * as React from 'react';
import { ApplicationState, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import { sanitizedNothingAllowed } from 'services/Helpers/SanitizedHelper';
import { getCMSContent, getTranslated } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { usePreviewImageForFileEntry } from 'views/CMS/Helpers/CMSHelpers';
import { documentKindByType } from '../PlaylistItems/FileEntryPlaylistNodeItem';

interface PlaylistEditPreviewElementProps {
    post: PostDownloadDto;
}

const mapper = (s: ApplicationState) => ({
    contentLanguage: s.contentLanguage,
    projects: s.cmsState.projects
})

const PlaylistEditPreviewElement = (p: PlaylistEditPreviewElementProps) => {
    const { contentLanguage } = useAppSelector(mapper, shallowCompare);
    const { post } = p;
    const { t, currentLanguage } = useImgI18N("playlist");
    const language = contentLanguage ?? currentLanguage;
    const contentFE = post?.type === "FileEntry" ? getCMSContent({ fileEntry: post as FileEntryDownloadDto, filePosts: {}, language }) : undefined;
    const eleType = post?.type === "FileEntry" ? documentKindByType(contentFE?.media?.mediaType) : undefined;
    const eleTypeIsAudio = (eleType === "audio" || post.type === "audio");
    const image = getMediaLink(post, p => p.media)?.toString() ?? "";
    const title = getTranslated(post.headlines, contentLanguage)?.text ?? contentFE?.media?.fileName;
    const description = getTranslated(post.descriptions, contentLanguage)?.text ?? "";

    const preview = usePreviewImageForFileEntry(post, language);

    return (
        <>
            {preview === undefined && (image !== "" || title !== "" || description !== "") &&
                <Button tooltip={{
                    tooltipText: "",
                    tooltipContent: <div style={{ textAlign: "left" }}>
                        {post.media && <Image src={image} shadowed />}
                        {title &&
                            <h4><SafeHTML
                                allowedTags={sanitizedNothingAllowed}
                                html={title}
                            /></h4>
                        }
                        {description &&
                            <SafeHTML
                                allowedTags={sanitizedNothingAllowed}
                                html={description}
                            />
                        }
                    </div>
                }}
                    kind="fullTransparentButton"
                    icon={"info"}
                    floated="right"
                />
            }
            {preview !== undefined && (image !== "" || title !== "" || description !== "" || preview !== "") &&
                <Button tooltip={{
                    tooltipText: "",
                    tooltipContent: <div style={{ textAlign: "left" }}>

                        {preview &&
                            <>
                                {eleTypeIsAudio ?

                                    <audio
                                        controlsList="nodownload"
                                        controls={false}
                                        preload="metadata"
                                        autoPlay={true}
                                        crossOrigin="anonymous"
                                        style={{ width: "100%", outline: "none" }}
                                    >
                                        <source src={preview} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                    :
                                    <Image src={preview} shadowed />
                                }
                            </>
                        }

                        {eleTypeIsAudio &&
                            <InfoBox animated content={t('stay hovered for audio preview')} icon='music' noUppercase noBold type='info' />
                        }

                        {title &&
                            <h4><SafeHTML
                                allowedTags={sanitizedNothingAllowed}
                                html={title}
                            /></h4>
                        }
                        {description &&
                            <SafeHTML
                                allowedTags={sanitizedNothingAllowed}
                                html={description}
                            />
                        }
                    </div>
                }}
                    kind="fullTransparentButton"
                    icon="info"
                    floated="right"
                />
            }
        </>
    );
}
export default PlaylistEditPreviewElement;