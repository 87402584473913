import SafeHTML from 'components/SafeHTML/SafeHTML';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { Button, Image, Modal } from 'imaginarity-react-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { QuizType } from './Question';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage });

export interface QuestionInfoProps extends ComponentWithMappedApplicationState<typeof mapper>, ImgI18NTranslatedComponentProps {
    active: boolean;
    onClick?: () => void;
    quizType?: QuizType;
}

class QuestionInfo extends React.PureComponent<QuestionInfoProps> {
    public render() {
        const { t } = this.props;
        if (this.props.quizType === undefined)
            return <></>;
        return (
            <Modal
                active={this.props.active}
                header={this.props.quizType.title}
                footer={<Button kind="primary" icon="times" onClick={this.props.onClick} floated="right" />}
                onDimmerClick={this.props.onClick}
                width="small"
                height="large"
                light
                shadow
                icon="questionark"

            >
                <Image src={this.props.quizType.iconUri} width="55px" floated="left" thumbnail shadowed />
                <div style={{ margin: 0, fontSize: "0.95rem", fontWeight: "bolder", marginBottom: 5 }}>{this.props.t("question type information")}</div>
                <SafeHTML html={t(this.props.quizType.instruction)} />
                {/* <div style={{ fontSize: "0.95rem", fontWeight: "bolder", margin: "5px 0" }}>General Information</div> */}
                {!this.props.quizType.hideEnlargeHint &&
                    <SafeHTML html={this.props.t("To <b>enlarge</b> images, click on it. To <b>close</b>, simply click on the picture again.")} />
                }
                <SafeHTML html={this.props.t("The symbol at the top right shows you how much <b>time</b> you have left for this question. If no number is displayed, there is no time limit.")} />
                <SafeHTML html={this.props.t("When you have finished your answer, click on the <b>next</b> button below.")} />
            </Modal>
        );
    }
}
export default translate("question")(connect(mapper)(QuestionInfo));
