import { BookmarkController, CategoryDownloadDto, ContentController, ControllerHelper, EventController, EventUploadDto, PostController, PostControllerTube, PostInteraction, TubePostDownloadDto, UserCommentDownloadDto, UserCommentsDownloadDto, UserRatingsDownloadDto, ViewStats } from 'collaboration-service';
import CommentsRatingsView from 'components/General/CommentsRatingsView';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { formatDistanceToNow, formatRelative } from 'date-fns';
import locale_de from "date-fns/locale/de";
import locale_el from "date-fns/locale/el";
import locale_en from "date-fns/locale/en-GB";
import locale_enUS from "date-fns/locale/en-US";
import locale_es from "date-fns/locale/es";
import locale_fr from "date-fns/locale/fr";
import locale_it from "date-fns/locale/it";
import locale_jp from "date-fns/locale/ja";
import locale_ko from "date-fns/locale/ko";
import locale_nl from "date-fns/locale/nl";
import locale_pt from "date-fns/locale/pt";
import locale_ru from "date-fns/locale/ru";
import locale_zhCN from "date-fns/locale/zh-CN";
import locale_zhTW from "date-fns/locale/zh-TW";
import { ExtendedDataCollection } from 'imaginarity-azure';
import { Button, Image, Loader, LoaderInline, RatingView, Theme, Tooltip, styled } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { PluginActions } from 'services/ApplicationState/PluginStateHandling';
import { bannerTop } from 'services/Config';
import { getLink, isChangedDateGreaterThanCreatedDateWithoutTime, reducerBatchJob, reducerSetIfChanged, shallowCompare, updateCategoriesAndCurrentStack } from 'services/Helpers';
import { getBrowser } from 'services/Helpers/BrowserHelper';
import ScrollHelper from 'services/Helpers/ScrollHelper';
import { getTranslated, getTranslatedForTube, getTranslatedUserName } from 'services/Helpers/TranslationHelpers';
import History from 'services/History';
import { ImgI18N, useImgI18N } from 'services/ImgI18N';
import PostHubConnection from 'services/signalR/PostHubConnection';
import { useAppTheme, useThemePart } from 'services/useAppTheme';
import { PlaylistSC } from 'views/Playlist/PlaylistSC';
import TubeContent from './TubeContent';
import { TubeTheme } from './TubeMain';
import { TubeSC as T } from './TubeSC';
import TubeVideoInfo from './TubeVideoInfo';



export const lngDefinitions = [
    { label: "Japanese", value: "jp", newCode: "ja-JP", flag: ["jp"], order: 'J', locale: locale_jp, tvalue: "ja", visible: true },
    { label: "Chinese (Simplified)", newCode: "zh-CN", value: "zh-CN", flag: ["cn"], order: 'C', locale: locale_zhCN, tvalue: "zh-Hans", visible: true },
    { label: "Chinese (Traditional)", newCode: "zh-TW", value: "zh-TW", flag: ["tw"], order: 'T', locale: locale_zhTW, tvalue: "zh-Hans", visible: true },
    { label: "Korean", value: "ko", newCode: "ko-KR", flag: ["kr"], order: 'K', locale: locale_ko, tvalue: "ko", visible: true },
    { label: "Portuguese", value: "pt", newCode: "pt-BR", flag: ["pt"], order: 'P', locale: locale_pt, tvalue: "pt-BR", visible: true },
    { label: "English", value: "en", newCode: "en-GB", flag: ["gb"], order: 'A', locale: locale_en, tvalue: "en", visible: true },
    { label: "English", value: "en-US", newCode: "en-US", flag: ["us"], order: 'B', locale: locale_enUS, tvalue: "en", visible: true },
    { label: "English (korean)", newCode: "en-GB", value: "en-kr", flag: ["gb", "kr"], order: 'KR', locale: locale_en, tvalue: "en", visible: true },
    { label: "Spanish", value: "es", newCode: "es-ES", flag: ["es"], order: 'S', locale: locale_es, tvalue: "es", visible: true },
    { label: "French", value: "fr", newCode: "fr-FR", flag: ["fr"], order: 'F', locale: locale_fr, tvalue: "fr", visible: true },
    { label: "Italian", value: "it", newCode: "it-IT", flag: ["it"], order: 'I', locale: locale_it, tvalue: "it", visible: true },
    { label: "Dutch", value: "nl", newCode: "nl-NL", flag: ["nl"], order: 'D', locale: locale_nl, tvalue: "nl", visible: true },
    { label: "Russian", value: "ru", newCode: "ru-RU", flag: ["ru"], order: 'R', locale: locale_ru, tvalue: "ru", visible: true },
    { label: "German", value: "de", newCode: "de-DE", flag: ["de"], order: 'B1', locale: locale_de, tvalue: "de", visible: true },
    { label: "Swiss German", value: "de-CH", newCode: "de-CH", flag: ["de", "ch"], order: 'Sx1', locale: locale_de, tvalue: "de", visible: true },
    { label: "Swiss French", value: "fr-CH", newCode: "fr-CH", flag: ["fr", "ch"], order: 'Sx2', locale: locale_fr, tvalue: "fr", visible: true },
    { label: "Swiss Italian", value: "it-CH", newCode: "it-CH", flag: ["it", "ch"], order: 'Sx3', locale: locale_it, tvalue: "it", visible: true },
    { label: "Greek", value: "gr", flag: ["gr"], order: 'B1', locale: locale_el, tvalue: "el", visible: true },
];



const TubeHomeMainContainer = styled.div`
        width:100%;
        text-align: left;
        margin: 0;
        user-select: none;
        padding-bottom: 80px;
`;
const TubeVideoGrid = styled.div<{ mobileView: boolean, contentWidth: number }>`
    width: 100%;
    max-width: 900px;
    transition: 0.2s all ease-out;
    margin: auto;
`;
const TubeVideoGridLeftHeaderBar = styled.div`
        line-height: 40px;
        font-size: 1rem;
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
        width: 100%;
        position: relative;
        display: grid;
        grid-template-columns: 40px 1fr max-content;
        margin: 20px 0;
        //background: rgba(0,0,0,0.2);
        //padding-left: 20px;
        button>div{
            margin: 0;
        }
`;
const TubeVideoContainer = styled.div`
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        video, embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
    }
`;
const TubeVideoInfoContainer = styled.div<{ mobileView: boolean }>`
        display: grid;
        grid-template-columns: 50px 1fr max-content ${p => p.mobileView && "max-content max-content"};
        grid-template-rows: 40px;
        margin-top: 20px;
`;
const TubeVideoGridLeftDescription = styled.div<{ mobileView: boolean }>`
        grid-row: 6;
        grid-column: 1;
        display: grid;
        grid-template-columns: ${p => p.mobileView ? "1fr 40px" : "50px max-content 1fr 40px"};
        grid-template-rows: ${p => p.mobileView ? "max-content" : "max-content 10px 1fr"};
        margin-bottom: 20px;
`;
const TubeVideoGridLeftCommentsCommentsRatings = styled.div`
        grid-row: 2;
        grid-column: 2;
        margin-bottom:30px;
        margin: -70px -16px 0 -6px ;
`;


const mapper = (state: ApplicationState) => ({
    params: state.params,
    contentLanguage: state.contentLanguage,
    focusTarget: state.tubeState.focusTarget,
    currentPost: state.tubeState.currentPost,
    currentCategoryStack: state.tubeState.currentCategoryStack,
    quality: state.tubeState.quality,
});
export interface TubeVideoPlaylistViewProps {
}

export interface TubeVideoPlaylistViewState {
    categories: CategoryDownloadDto[];
    comments?: UserCommentDownloadDto[];
    ratings?: UserCommentDownloadDto[];
    relativeDate: boolean;
    showMoreInfo: boolean;
    showNextVideos: boolean;
    loading: boolean;
    neighbours: TubePostDownloadDto[];
}


type TubeVideoActions =
    | { type: "batch", value: TubeVideoActions[] }
    | { type: "setCategories", value: CategoryDownloadDto[] }
    | { type: "setComments", value?: UserCommentDownloadDto[] }
    | { type: "setRatings", value?: UserCommentDownloadDto[] }
    | { type: "setRelativeDate", value: boolean }
    | { type: "setShowNextVideos", value: boolean }
    | { type: "setLoading", value: boolean }
    | { type: "setNeighbours", value: TubePostDownloadDto[] }
    ;

const initTubeVideoState = (): TubeVideoPlaylistViewState => ({
    neighbours: [],
    categories: [],
    relativeDate: true,
    showMoreInfo: false,
    showNextVideos: false,
    loading: true,
});

const batch = (value: TubeVideoActions[]): TubeVideoActions => ({ type: "batch", value });
const setComments = (value?: UserCommentDownloadDto[]): TubeVideoActions => ({ type: "setComments", value });
const setRatings = (value?: UserCommentDownloadDto[]): TubeVideoActions => ({ type: "setRatings", value });
const setCategories = (value: CategoryDownloadDto[]): TubeVideoActions => ({ type: "setCategories", value });
const setRelativeDate = (value: boolean): TubeVideoActions => ({ type: "setRelativeDate", value });
const setShowNextVideos = (value: boolean): TubeVideoActions => ({ type: "setShowNextVideos", value });
const setLoading = (value: boolean): TubeVideoActions => ({ type: "setLoading", value });
const setNeighbours = (value: TubePostDownloadDto[]): TubeVideoActions => ({ type: "setNeighbours", value });

const reducer = (state: TubeVideoPlaylistViewState, action: TubeVideoActions): TubeVideoPlaylistViewState => {
    switch (action.type) {
        case "batch":
            return reducerBatchJob(state, action.value, reducer);
        case "setComments":
            return reducerSetIfChanged(state, "comments", action.value);
        case "setRatings":
            return reducerSetIfChanged(state, "ratings", action.value);
        case "setCategories":
            return reducerSetIfChanged(state, "categories", action.value);
        case "setRelativeDate":
            return reducerSetIfChanged(state, "relativeDate", action.value);
        case "setShowNextVideos":
            return reducerSetIfChanged(state, "showNextVideos", action.value);
        case "setLoading":
            return reducerSetIfChanged(state, "loading", action.value);
        case "setNeighbours":
            return reducerSetIfChanged(state, "neighbours", action.value);
    }
}


const themePartGetter = (t: Theme) => ({ width: t.content.width, emptyImageUrl: t.emptyImageUrl, emptyAvatarImageUrl: t.emptyAvatarImageUrl });

const TubeVideoPlaylistView = (p: TubeVideoPlaylistViewProps) => {
    const [state, d] = React.useReducer(reducer, initTubeVideoState());
    const { loading, showNextVideos: snv, relativeDate } = state;
    const { t } = useImgI18N("tube");
    const { params, currentPost, currentCategoryStack, contentLanguage, quality } = useSelector(mapper, shallowCompare);
    const dispatch = useAppDispatch();
    const tubeTheme = useAppTheme<TubeTheme>("tubeTheme");
    const { width, emptyAvatarImageUrl } = useThemePart(themePartGetter);

    const context = params?.context as string;

    const { scrollHelper, pr } = React.useMemo(() => {
        return {
            scrollHelper: new ScrollHelper(),
            pr: PostHubConnection.getInstance().subscribe((post) => {
                dispatch(Actions.setTubeCurrentPost(post as TubePostDownloadDto));
            })
        }
    }, [dispatch]);
    const scrollTo = React.useMemo(() => (id: string) => scrollHelper.scrollTo(id), [scrollHelper]);

    const showNextVideos = context ? false : snv;

    React.useEffect(() => {
        const id = params?.id as string;
        const loadPost = async () => {
            const context = params?.context as string;

            if (!id) {
                dispatch(Actions.setTubeCurrentPost(undefined));
                return;
            }
            d(setLoading(true));
            const post = context ?
                await ControllerHelper.singleCall({ id, context }, PostController.GetSinglePostWithContext) as TubePostDownloadDto :
                await ControllerHelper.singleCall({ id }, PostController.GetSinglePost) as TubePostDownloadDto;
            dispatch(Actions.setTubeCurrentPost(post));
            if (pr)
                pr.signForIds([id]);
            d(setLoading(false));
        }

        scrollHelper.scrollTo("top");
        if (id !== currentPost?.id)
            loadPost();
        if (currentPost)
            dispatch(Actions.setScrollTo(scrollTo));

    }, [dispatch, pr, scrollHelper, params, scrollTo, currentPost]);

    React.useEffect(() => {
        if (currentCategoryStack === undefined)
            updateCategoriesAndCurrentStack(dispatch, "TUBE", "porsche_tube", Actions.setTubeCategories, Actions.setTubeCurrentCategoryStack, params);

    }, [dispatch, currentCategoryStack, params]);

    React.useEffect(() => {
        const loadData = async () => {
            if (!currentPost)
                return;
            const id = currentPost.id;
            const [categories, comments, ratings, vs, neighbours] = await ControllerHelper
                .addCall({ grouptype: "TUBE" }, ContentController.GetCategoriesForAllGroupsByGroupType)
                .addCall({ id }, PostInteraction.GetPostComments)
                .addCall({ id }, PostInteraction.GetPostRatings)
                .addCall({ postIds: [id] }, PostControllerTube.GetPostsViews)
                .addCall({ id: id, token: { token: "" } }, PostControllerTube.GetNeighbors)
                .execute<CategoryDownloadDto[], UserCommentsDownloadDto, UserRatingsDownloadDto, ViewStats[], ExtendedDataCollection<TubePostDownloadDto, string>>();

            //neighbours.sort(this.sortByDate);
            let views = 0;
            let viewed = false;
            if (vs && vs.length > 0 && vs[0].views) {
                views = vs[0].views;
                viewed = vs[0].viewed ?? false;
            }
            (currentPost as TubePostDownloadDto).views = views;
            (currentPost as TubePostDownloadDto).viewed = viewed;
            d(batch([
                setComments(comments.comments.reverse()),
                setRatings(ratings.comments.reverse()),
                setCategories(categories),
                setLoading(false),
                setNeighbours(neighbours.elements),
                setShowNextVideos(false),
            ]))
            const ev: EventUploadDto = {
                eventType: "PostViewed",
                group_id: currentPost.group_id,
                payload: { "PostId": currentPost.id, browser: getBrowser() }
            };
            EventController.PostEvent({ ev });
        };
        loadData();
    }, [currentPost])

    React.useEffect(() => {
        return () => {
            scrollHelper.scrollTo("top");
            dispatch(Actions.setTubeCurrentPost(undefined));
            if (pr)
                pr.unsubscribe();
        }
    }, [dispatch, pr, scrollHelper]);



    const getDateFnsLng = ImgI18N.getInstance().languageLocal();

    const { translatedContent, quals, creationDate, creationDateRelative, changedDate, changedDateRelative, views, avatar } = React.useMemo(() => {
        const translatedContent = getTranslatedForTube(currentPost?.content, contentLanguage, [quality, "HD", "SD", "4K", "", undefined]);
        if (quality !== translatedContent.contentAttribute && (translatedContent.contentAttribute === "HD" || translatedContent.contentAttribute === "SD" || translatedContent.contentAttribute === "4K"))
            dispatch(Actions.setTubeQuality(translatedContent.contentAttribute));
        const quals = _.map(_.filter(currentPost?.content, c => c.lng === translatedContent.lng), m => m.contentAttribute);

        const onBookmark = (isBookmarked: boolean) => _.debounce(async () => {
            if (currentPost) {
                dispatch(Actions.setTubeCurrentPost({ ...currentPost, isBookmarked }));
                const newPost = await ControllerHelper.singleCall({ postid: currentPost.id }, isBookmarked ? BookmarkController.PostBookmark : BookmarkController.RemoveBookmark, true);
                if (newPost)
                    dispatch(Actions.setTubeCurrentPost(newPost as TubePostDownloadDto));
            }
        }, 500);
        const creationDate = formatDistanceToNow(currentPost ? new Date(currentPost.created as Date) : new Date(), { addSuffix: true, locale: getDateFnsLng });
        const creationDateRelative = formatRelative(currentPost ? new Date(currentPost.created as Date) : new Date(), new Date(), { locale: getDateFnsLng });
        const changedDate = formatDistanceToNow(currentPost ? new Date(currentPost.changed as Date) : new Date(), { addSuffix: true, locale: getDateFnsLng });
        const changedDateRelative = formatRelative(currentPost ? new Date(currentPost.changed as Date) : new Date(), new Date(), { locale: getDateFnsLng });
        const actViews = currentPost?.views ?? 0;
        const views = actViews >= 1000 ? (Math.round(actViews / 100) / 10) + "K" : actViews;
        const avatar = getLink(currentPost?.creator?.avatar?.links, "self")?.uri ?? emptyAvatarImageUrl;
        return {
            translatedContent,
            quals,
            onBookmark,
            creationDate,
            creationDateRelative,
            changedDate,
            changedDateRelative,
            views,
            avatar,

        };
    }, [currentPost, contentLanguage, quality, dispatch, emptyAvatarImageUrl, getDateFnsLng])



    const toggleRelativeDate = () => {
        d(setRelativeDate(!relativeDate));
    }


    const finishedVideo = React.useMemo(() => () => {
        if (currentPost) {
            const ev: EventUploadDto = {
                eventType: "Finished",
                group_id: currentPost.group_id,
                payload: {
                    "PostId": currentPost.id,
                }
            };
            ControllerHelper.singleCall({ ev, createRestriction: { checkGroupId: true, checkPayload: ["PostId"], checkUserId: true, maxAgeInSeconds: 10 } }, EventController.PostEventWithRestriction);
        }
    }, [currentPost])

    React.useEffect(() => {
        if (!translatedContent.useContent)
            finishedVideo();
    }, [translatedContent, finishedVideo])


    const toggleNextVideos = () => {
        d(setShowNextVideos(!showNextVideos));
    };


    const goBack = () => {
        History.goBack();
    }

    const changeQuality = (newQual: "HD" | "SD" | "4K") => () => {
        const load = async () => {
            if (currentPost) {
                const post = await ControllerHelper.singleCall({ id: currentPost?.id }, PostController.GetSinglePost);
                dispatch(PluginActions.batchActions([
                    Actions.setTubeCurrentPost(post as TubePostDownloadDto),
                    Actions.setTubeQuality(newQual),
                ]))
            }
        };
        load();
    }
    const update = React.useMemo(() => ({ percentage: 90, callback: finishedVideo }), [finishedVideo]);

    if (!currentPost)
        return <Loader active size="small" infoText={t("loading")} light />;


    const contentWidth = width ?? 400;
    const mobileView = contentWidth < 550;
    const showChangedDate = isChangedDateGreaterThanCreatedDateWithoutTime(currentPost.created, currentPost.changed);
    const banner = bannerTop.playlist;


    return (
        <>
            <div style={{ height: 0 }} ref={scrollHelper.getRef("top")} />
            <TubeHomeMainContainer>
                <PlaylistSC.BannerContainer imageUrl={banner} />

                {loading ?
                    <LoaderInline
                        infoText="loading"
                        animationColor={"@accent"}
                        backgroundColor={"rgba(0,0,0,0.1)"}
                        textColor={"@lightGrey"}
                        active
                    />
                    :
                    <TubeVideoGrid mobileView={mobileView} contentWidth={contentWidth}>
                        <TubeVideoGridLeftHeaderBar id="videoWidth">
                            <Button kind="fullTransparentButton" icon='video player' disabled iconColor='color' />
                            <SafeHTML html={getTranslated(currentPost.headlines).text} allowedTags={[]} allowedAttributes={{}} />
                            <Button
                                kind="transparentButton"
                                icon="chevron left"
                                floated="right"
                                onClick={goBack}
                                content={t("back")}
                            />
                        </TubeVideoGridLeftHeaderBar>

                        <TubeVideoContainer>
                            <div style={{ height: 0, marginTop: -10 }} ref={scrollHelper.getRef("video")} />
                            <TubeContent
                                translatedContent={translatedContent}
                                namespace="tube"
                                onEnded={toggleNextVideos}
                                onTimeUpdate={update}
                            />
                        </TubeVideoContainer>


                        <TubeVideoInfoContainer mobileView={mobileView}>
                            <div style={{ height: 0, marginTop: -10 }} ref={scrollHelper.getRef("info")} />
                            <T.TubeVideoGridLeftUserAvatar>
                                <Image src={avatar} rounded width="40px" />
                            </T.TubeVideoGridLeftUserAvatar>
                            <T.TubeVideoGridLeftUserName>
                                <T.TubeVideoGridLeftUserNameName>
                                    {getTranslatedUserName(currentPost.creator)}
                                </T.TubeVideoGridLeftUserNameName>
                                <T.TubeVideoGridLeftUserNameDate onClick={toggleRelativeDate}>
                                    {relativeDate ? creationDateRelative : creationDate}
                                    {showChangedDate &&
                                        <T.TubeVideoGridLeftUserChangedBy>
                                            {t("changed by {{name}} on {{date}}", { name: getTranslatedUserName(currentPost.changedBy), date: relativeDate ? changedDateRelative : changedDate })}
                                        </T.TubeVideoGridLeftUserChangedBy>
                                    }
                                </T.TubeVideoGridLeftUserNameDate>
                            </T.TubeVideoGridLeftUserName>
                            <T.TubeVideoGridLeftUserRating style={{ paddingTop: 2 }}>
                                <Tooltip tooltipText={currentPost.ratingCount === 0 ? t("this video has not been rated yet") : t("this video has already been rated {{count}} times", { count: currentPost.ratingCount })} position='top'>
                                    <RatingView maxStars={5} rating={currentPost.rating} size={20} />
                                </Tooltip>
                            </T.TubeVideoGridLeftUserRating>
                            {!mobileView &&
                                <T.TubeVideoGridLeftUserButtons>
                                    <Button
                                        icon="eye open"
                                        kind={tubeTheme.darkMode ? "transparentButtonDark" : "transparentButton"}
                                        tooltip={{ tooltipText: t("this video has already been viewed {{count}} times", { count: views }), position: "top" }}
                                        floated="right"
                                        disabled
                                        index={views}
                                        cursor="help"
                                    />
                                    {_.findIndex(quals, q => q === "4K") >= 0 &&
                                        <Button
                                            icon="monitor text"
                                            active={quality === "4K"}
                                            kind={quality === "4K" ? "primary" : "transparentButton"}
                                            tooltip={{ tooltipText: t("view the video in 4k quality"), position: "top" }}
                                            floated="right"
                                            iconText='4K'
                                            onClick={changeQuality("4K")}
                                        />
                                    }
                                    {_.findIndex(quals, q => q === "HD") >= 0 &&
                                        <Button
                                            icon="monitor text"
                                            active={quality === "HD"}
                                            kind={quality === "HD" ? "primary" : "transparentButton"}
                                            tooltip={{ tooltipText: t("view the video in HD quality"), position: "top" }}
                                            floated="right"
                                            iconText='HD'
                                            onClick={changeQuality("HD")}
                                        />
                                    }
                                    {_.findIndex(quals, q => q === "SD") >= 0 &&
                                        <Button
                                            icon="monitor text"
                                            active={quality === "SD"}
                                            kind={quality === "SD" ? "primary" : "transparentButton"}
                                            tooltip={{ tooltipText: t("view the video in SD quality"), position: "top" }}
                                            floated="right"
                                            iconText='SD'
                                            onClick={changeQuality("SD")}
                                        />
                                    }


                                    <Button
                                        icon="comment"
                                        kind={tubeTheme.darkMode ? "transparentButtonDark" : "transparentButton"}
                                        tooltip={{ tooltipText: currentPost.commentCount === 0 ? t("this video has not been commented yet") : t("{{count}} users have already commented on this video", { count: currentPost.commentCount }), position: "top" }}
                                        floated="right"
                                    />
                                </T.TubeVideoGridLeftUserButtons>
                            }
                        </TubeVideoInfoContainer>


                        <TubeVideoGridLeftDescription mobileView={mobileView}>
                            {!mobileView &&
                                <T.TubeVideoGridLeftDescriptionTitle>{t("description:")}</T.TubeVideoGridLeftDescriptionTitle>
                            }
                            <T.TubeVideoGridLeftDescriptionDescription mobileView={mobileView} style={{ marginBottom: 10 }}>
                                <SafeHTML html={getTranslated(currentPost.descriptions, contentLanguage)?.text ?? ""} />
                            </T.TubeVideoGridLeftDescriptionDescription>

                            <TubeVideoInfo mobileView={mobileView} post={currentPost} />
                        </TubeVideoGridLeftDescription>
                        <T.TubeVideoGridLeftComments
                            style={{ marginTop: 0, gridTemplateRows: "40px max-content" }}
                            mobileView={mobileView}
                        >
                            <div style={{ height: 0, marginTop: -10 }} ref={scrollHelper.getRef("comments")} />

                            <TubeVideoGridLeftCommentsCommentsRatings ref={scrollHelper.getRef("ratings")}>
                                <CommentsRatingsView
                                    post={currentPost}
                                    noLeftBorder
                                    getIncoming={PostInteraction.GetPostComments}
                                    addCommentRating={PostInteraction.AddPostComment}
                                    onDeleteComment={PostInteraction.DeletePostComment}
                                    onSubmitComment={PostInteraction.UpdatePostComment}
                                    darkMode={tubeTheme.darkMode}
                                    inline={!mobileView}
                                    noPosting
                                />
                                <div style={{ marginBottom: -38 }} />
                                <CommentsRatingsView
                                    post={currentPost}
                                    noLeftBorder
                                    asRating
                                    getIncoming={PostInteraction.GetPostRatings}
                                    addCommentRating={PostInteraction.AddPostRating}
                                    darkMode={tubeTheme.darkMode}
                                    inline={!mobileView}
                                    noPosting
                                />
                            </TubeVideoGridLeftCommentsCommentsRatings>
                        </T.TubeVideoGridLeftComments>
                    </TubeVideoGrid>
                }
            </TubeHomeMainContainer>
        </>
    );



}

export default TubeVideoPlaylistView; 