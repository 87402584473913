import * as React from 'react';
import { ApplicationState, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import DataPrivacyContentDe from './DataPrivacyContentDe';
import DataPrivacyContentEn from './DataPrivacyContentEn';

const mapper = (s: ApplicationState) => ({
    contentLanguage: s.contentLanguage,
});

interface DataPrivacyProps {
}

const DataPrivacy = (p: DataPrivacyProps) => {
    const { contentLanguage } = useAppSelector(mapper, shallowCompare);


    return (
        <>

            {contentLanguage === "de-DE" ? <DataPrivacyContentDe /> : <DataPrivacyContentEn />}
        </>
    );
}
export default DataPrivacy;