import * as React from "react";
//import { useSelector } from "react-redux";
import { useAppDispatch } from "services/ApplicationState/ApplicationState";
import { Actions } from "./ApplicationState/Actions";


interface HandleAppFocusProps {

}

const HandleAppFocus = (p: HandleAppFocusProps) => {
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        const onFocus = () => {
            dispatch(Actions.setHasFocus(true));
        };
        const onBlur = () => {
            dispatch(Actions.setHasFocus(false));
        };
        window.onfocus = onFocus;
        window.onblur = onBlur;
        window.focus();
    }, [dispatch])

    return (
        <div />
    )
}

export default HandleAppFocus;