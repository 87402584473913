import { ControllerHelper, WikiController, WikiSearchResultDto } from 'collaboration-service';
import AutocompleteSearch from 'components/AutocompleteSearch/AutocompleteSearch';
import { ExtendedDataCollection } from 'imaginarity-azure';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { FormElement, Icon, ImgThemeConsumer, Input, LoaderInline } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { ChangeEvent, InputProps, SuggestionsFetchRequestedParams } from 'react-autosuggest';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { WikiSC as T } from './WikiSC';

const mapper = (state: ApplicationState) => ({ i18n: state.i18n });

export interface WikiSearchProps extends ImgI18NTranslatedComponentProps, ComponentWithMappedApplicationState<typeof mapper> {
    groupId?: string;
    searchText: string;
    postType: string;
    lng?: string;
    onSearched?: (posts?: ExtendedDataCollection<WikiSearchResultDto, string>) => void;
    onSearchStarted?: (term: string) => void;
    inlineLoader?: boolean;
    dark?: boolean;
}

export interface WikiSearchState {
    search: string;
    suggestions: string[];
    searchActive: boolean;
}



class WikiSearch extends React.Component<WikiSearchProps, WikiSearchState> {
    constructor(props: WikiSearchProps) {
        super(props);

        this.state = {
            search: "",
            suggestions: [],
            searchActive: false
        }
    }

    public render() {
        return (
            <ImgThemeConsumer>
                {theme =>
                    <AutocompleteSearch
                        placeholder={this.props.searchText}
                        search={this.state.search}
                        searchChanged={this.searchChanged}
                        suggestions={this.state.suggestions}
                        onEnterPressed={this.enterPressed}
                        onSuggestionsFetchRequested={this.suggestionFetchRequested}
                        onSuggestionsClearRequested={this.suggestionsClearRequested}
                        onSuggestionSelected={this.suggestionSelected}
                        renderInputComponent={this.renderInputComponent(`0`)}
                        theme={{
                            container: { position: "relative" },
                            suggestionsContainer:
                            {
                                display: "none",
                                width: "calc(100% - 48px)",
                                marginLeft: 39,
                            },
                            suggestionsContainerOpen: {
                                background: theme.colors.mainBackground,
                                backgroundColor: theme.colors.mainBackground,
                                display: "block",
                                position: "absolute",
                                top: 45,
                                border: "1px solid " + theme.colors.accent,
                                fontSize: "1em",
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                                zIndex: 3000
                            },
                            suggestionsList: {
                                margin: 0,
                                padding: 0,
                                listStyleType: "none",
                                paddingTop: 0,
                                paddingBottom: 0
                            },
                            suggestion: {
                                paddingTop: 10,
                                paddingBottom: 10,
                                paddingLeft: 10,
                                cursor: "pointer"
                            },
                            suggestionHighlighted: {
                                background: theme.colors.accent,
                                backgroundColor: theme.colors.accent,
                                color: theme.colors.mainBackground
                            }
                        }}
                    />}</ImgThemeConsumer>
        );
    }

    private renderInputComponent = (marginTop: string) => (inputProps: InputProps<string>) => {
        const { onChange, onBlur, width, size, ref, ...rest } = inputProps as any;
        const imgOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            onChange(event, { newValue: event.target.value, method: "type" })
        }
        return (
            <div style={{ marginTop }}>
                {/* {!this.props.dark &&
                    <div style={{ float: "left", width: 25, marginLeft: 10, marginTop: 5 }}>
                        <Icon name="search" color={"@mainBackground"} />
                    </div>
                } */}
                <div style={{ width: this.props.dark ? "100%" : "calc(100% - 30px)", float: "right", marginRight: -5 }}>
                    <FormElement
                        element={Input}
                        width="calc(100% - 10px)"
                        onChange={imgOnChange}
                        kind="default"
                        icon="search"
                        noMargin
                        innerRef={ref}
                        {...(rest as any)}
                    />
                    {this.state.search !== "" &&
                        <T.ResetSearchIcon onClick={this.clearInput}>
                            <Icon name="times" color={"@mainForeground"} />
                        </T.ResetSearchIcon>
                    }
                    {this.props.inlineLoader &&
                        <div style={{ width: "calc(100% - 18px)", marginLeft: 4 }}>
                            <LoaderInline
                                active={this.state.searchActive}
                                infoText={this.props.t("searching for {{word}}", { word: this.state.search })}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
    private clearInput = () => {
        this.setState({ search: "" });
        if (this.props.onSearched)
            this.props.onSearched(undefined);
    };

    private suggestionSelected = (e: any, data: { suggestion: string }) => {
        const prefixEnd = this.state.search.lastIndexOf(" ");
        let prefix = "";
        if (prefixEnd >= 0)
            prefix = this.state.search.slice(0, prefixEnd) + " ";
        this.setState({ searchActive: true });

        this.search(`${prefix}${data.suggestion}`);
    }

    private search = _.debounce(async (text: string) => {
        let lng = this.props.i18n.currentLanguage;
        if (this.props.lng)
            lng = this.props.lng;
        if (this.props.onSearchStarted)
            this.props.onSearchStarted(text);

        const searchResults = await ControllerHelper.promise({ text, lng, filter: this.props.groupId ? { onlyInGroups: [this.props.groupId] } : undefined }, WikiController.Search);
        searchResults.data = text;

        if (this.props.onSearched)
            this.props.onSearched(searchResults);

        this.setState({ searchActive: false });

    }, 50);

    private searchChanged = (event: React.FormEvent<any>, params: ChangeEvent) => {
        const text = params.newValue ? params.newValue : "";
        this.setState({ search: text });
    }

    private suggestionFetchRequested = (request: SuggestionsFetchRequestedParams) => {
        if (request.reason === "input-changed" || request.reason === "input-focused") {
            let terms = request.value.split(" ");
            terms = _.filter(terms, t => t.length > 0);
            const lastTerm = _.last(terms);
            if (lastTerm) {
                let lng = this.props.i18n.currentLanguage;
                if (this.props.lng)
                    lng = this.props.lng;
                WikiController.SuggestTerm({ term: lastTerm, lng, groupIds: this.props.groupId ? [this.props.groupId] : undefined }, suggestions => {
                    this.setState({ suggestions });
                });
            }
        }
    }

    private enterPressed = () => {

        if (this.state.search.length > 0) {
            this.setState({ searchActive: true });
            this.search(this.state.search);
        }
        else {
            if (this.props.onSearched)
                this.props.onSearched(undefined);   // signal to reload as usual
        }

    }

    private suggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
        if (this.props.onSearched)
            this.props.onSearched(undefined);   // signal to reload as usual
    }
}
export default connect(mapper)(translate("playlist")(WikiSearch));