import { Theme } from "imaginarity-react-ui";
import * as React from 'react';
import { ThemeContext } from 'styled-components';
import { shallowCompare } from './Helpers';
export { useBelowBreakpoint, useAboveBreakpoint, useAboveBreakpointOrEqual, useBelowBreakpointOrEqual, useBreakpointCheck, useBreakpointChecks, useThemePart } from "imaginarity-react-ui";

export function useAppTheme<T extends any>(name: string) {
    const theme = React.useContext<Theme>(ThemeContext);
    const at = theme.applicationTheming;
    const [result, setResult] = React.useState<T>(at[name] as T);
    const val = at[name] as T;
    if (!shallowCompare(val, result)) {
        setResult(val);
        return val;
    }
    return result;
}

export function useAppThemes<A extends any = any, B extends any = any, C extends any = any, D extends any = any>(name1: string, name2: string, name3?: string, name4?: string): [A, B, C, D] {
    const theme = React.useContext<Theme>(ThemeContext);
    const at = theme.applicationTheming;
    const toRet: [A, B, C, D] = React.useMemo(() => [
        at[name1] as A,
        at[name2] as B,
        at[name3 ?? ""] as C,
        at[name4 ?? ""] as D
    ], [at, name1, name2, name3, name4]);
    return toRet;
}

