import { belowBreakpointOrEqual, Button, ButtonKind, getColor, Icon, keyframes, Theme } from 'imaginarity-react-ui';
import * as React from 'react';
import { useImgI18N } from 'services/ImgI18N';
import useCopyToClipboard from 'services/useCopyToClipboard';
import styled, { ThemeContext } from 'styled-components';

const slideIn = keyframes`
    0% {transform: translateY(-20px); filter: blur(1px);opacity: 0;}
    10% {transform: translateY(0); filter: blur(0px);opacity: 1;}
    90% {transform: translateY(0); filter: blur(0px);opacity: 1;}
    100% {transform: translateY(20px); filter: blur(1px);opacity: 0;}
  `;
const Container = styled.div`
    button>div{margin: 0;}
    transition: all 0.2s ease-out;
`;
const IconContainer = styled.span<{ linkCopied: boolean }>` 
    cursor: ${p => p.linkCopied ? "default" : "pointer"};
    transition: all 0.2s ease-out;
    margin-left: 5px;    
    position: relative;
    `;
const IconHint = styled.div<{ linkCopied: boolean }>`
        display: inline-block;
        width: auto;
        font-size: 0.9rem;
        color: ${p => getColor(p, "@accentGreen")};
        transition: all 0.2s ease-out;
        font-weight: normal;
        padding: 0 10px;
        svg{
            stroke: ${p => p.linkCopied ? getColor(p, "@accentGreen") : getColor(p, "@lightGrey")};
        }
        &:hover{
            svg{
                stroke: ${p => p.linkCopied ? getColor(p, "@accentGreen") : getColor(p, "@accent")};
            }  
        }
    `;
const BottomHint = styled.div`
    position: absolute;
    top: -5px;
    left: 5px;
    height: 36px;
    min-width: 200px;
    color: ${p => getColor(p, "@accentGreen")};
    line-height: 36px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: normal;
    animation:  ${slideIn} 4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
`;

interface CopyToClipboardProps {
    text: string;
    type: "icon" | "button";
    buttonKind?: ButtonKind;
    buttonFloat?: "left" | "right";
    copyHint?: string;
    copiedHint?: string;
}

const CopyToClipboard = (p: CopyToClipboardProps) => {
    const { text, type, buttonFloat: float, buttonKind, copiedHint, copyHint } = p;
    const { t } = useImgI18N('general');
    const theme = React.useContext<Theme>(ThemeContext);
    const { linkCopied, copyLinkToClipboard, setLinkCopied } = useCopyToClipboard(text);

    return (
        <>
            {type === "icon" ?
                <IconContainer
                    title={copyHint ?? t("copy to clipboard ")}
                    linkCopied={linkCopied}
                >
                    <IconHint linkCopied={linkCopied} onClick={copyLinkToClipboard}>
                        <Icon name={linkCopied ? "empty" : "copy"} size={"14px"} />
                    </IconHint>
                    {linkCopied &&
                        <BottomHint onClick={() => setLinkCopied(false)}>
                            <Icon name="check" style={{ float: "left" }} color="@accentGreen" />
                            {copiedHint ?? t("copied to clipboard")}
                        </BottomHint>
                    }
                </IconContainer>
                :
                <Container style={{ marginLeft: linkCopied ? 0 : belowBreakpointOrEqual({ theme }, "tablet") ? -20 : -40, paddingRight: linkCopied ? 10 : 0, float: float }}>
                    <Button
                        icon="copy"
                        kind={linkCopied ? "sendButton" : buttonKind ?? "transparentButton"}
                        onClick={copyLinkToClipboard}
                        floated={float}
                        tooltip={copyHint ? { tooltipText: copyHint } : undefined}
                        content={linkCopied ? t("copied") : undefined}
                    />
                </Container>
            }

        </>
    );
}
export default CopyToClipboard;