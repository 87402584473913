import { NewsPostDownloadDto } from 'collaboration-service';
import { Card, CreatedText, Headline, ImageContainer, NPCreated, PostRibbon, SPContent, SPHeader, TextContainer } from 'components/Posts/PostClasses';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { formatDistanceToNow } from 'date-fns';
import { Icon, Image } from 'imaginarity-react-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { PostProps } from 'services/ApplicationState/ApplicationComponentsFactory';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { getLink } from 'services/Helpers';
import { sanitizedAllowedAttributesHighSafety, sanitizedAllowedTagsPreview } from 'services/Helpers/SanitizedHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { ImgI18N, ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage });
export interface NewsPostProps extends PostProps, ImgI18NTranslatedComponentProps, ComponentWithMappedApplicationState<typeof mapper> {
}
export interface NewsPostState {
}

class NewsPost extends React.Component<NewsPostProps, NewsPostState> {
    constructor(props: NewsPostProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        const post = this.props.post as NewsPostDownloadDto;
        const { t, contentLanguage } = this.props;
        const uri = getLink(post.media?.links, "self")?.uri;
        const headline = getTranslated(post.headlines, contentLanguage).text ?? "";
        const desc = getTranslated(post.descriptions, this.props.contentLanguage).text ?? "";
        const created = formatDistanceToNow(
            new Date(post.created),
            { addSuffix: true, locale: ImgI18N.getInstance().languageLocal(contentLanguage) }
        )
        return (
            <Card>
                <PostRibbon gradientColor1="#658E11" gradientColor2="#8CB527"><span>{t("SMW")}</span> </PostRibbon>
                <SPContent>
                    <Headline>
                        <Icon marginTop={6} name="globe" />
                        <SPHeader>{headline}</SPHeader>
                    </Headline>
                    <NPCreated>
                        <Icon marginTop={1} name="clock" color="@lightGrey" />
                        <CreatedText>
                            {created}
                        </CreatedText>
                    </NPCreated>
                    {uri &&
                        <ImageContainer>
                            <a href={uri}>
                                <Image src={uri} width="130px" mediaType={post.media.mediaType} />
                            </a>
                        </ImageContainer>
                    }
                    <TextContainer>
                        <SafeHTML
                            allowedTags={sanitizedAllowedTagsPreview}
                            allowedAttributes={sanitizedAllowedAttributesHighSafety}
                            html={desc}
                        />
                    </TextContainer>
                </SPContent>
            </Card >
        );
    }
}

export default translate("feed")(connect(mapper)(NewsPost));
