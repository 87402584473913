import { Button, ButtonKind, getColor, Icon, ImgIcons, lightenColor, styled } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as PopperJS from "popper.js";
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { CurrentSettings } from 'services/Config';

const mapper = (state: ApplicationState) => ({});

interface SidebarButtonItem {
    icon?: ImgIcons;
    kind?: ButtonKind;
    selected?: boolean;
    active?: boolean;
    onClick?: () => void;
    tooltipText?: string;
    tooltipPosition?: PopperJS.Placement;
    disabled?: boolean;
    noArrow?: boolean;
    iconColor?: string;
    hidden?: boolean;
}

interface SidebarButtonProps extends ComponentWithMappedApplicationState<typeof mapper> {
    title: string;
    button: SidebarButtonItem[];
    noContainerClick?: boolean;
    uppercased?: boolean;
    noAnimation?: boolean;
    warning?: boolean;
    selected?: boolean;
    children?: JSX.Element;
    transparent?: boolean;
    bold?: boolean;
    icon?: ImgIcons;
}
// p.transparent ? "transparent" : 
const Container = styled.div<{ selected: boolean, noClick: boolean, buttonCount: number, noAnimation: boolean, warning: boolean, selectedContainer: boolean, transparent: boolean }>`
    position: relative;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: ${p => p.transparent ? p.selectedContainer ? lightenColor(p, p.theme.backgroundColor, 90) : "transparent" : p.selectedContainer ? lightenColor(p, p.theme.backgroundColor, 75) : p.warning ? getColor(p, p.theme.colors.darkGrey) : p.selected ? lightenColor(p, p.theme.backgroundColor, 95) : getColor(p, p.theme.backgroundColor)};
    
    border-left: 1px solid ${p => p.selectedContainer ? getColor(p, p.theme.colors.accent) : p.selected ? getColor(p, p.theme.colors.accent) : p.transparent ? "transparent" : lightenColor(p, p.theme.backgroundColor, 75)};
    
    //border-left-width: ${p => p.selectedContainer ? 1 : p.noAnimation ? 0 : 1}px;
    //border-left-style: solid;
    //border-image: linear-gradient(to bottom, transparent 0%,transparent 20%, ${p => lightenColor(p, p.theme.backgroundColor, 85)} 21%, ${p => lightenColor(p, p.theme.backgroundColor, 85)} 79%, transparent 80%,transparent 100%);
    //border-image-slice: 1;

    padding-left: ${p => p.selectedContainer ? 10 : p.selected ? 10 : p.noAnimation ? 0 : 5}px;
    color: ${p => p.warning ? getColor(p, p.theme.colors.mainBackground) : lightenColor(p, p.theme.color, 95)};
    display: grid;
    /* grid-template-columns: 1fr ${p => p.buttonCount * 40}px; */
    grid-template-columns: 1fr max-content max-content;
    transition: all 0.2s ease-out;
    cursor: ${p => p.selected ? "default" : p.noClick ? "default" : "pointer"};

    svg{
        stroke: ${p => p.warning ? getColor(p, p.theme.colors.mainBackground) : lightenColor(p, p.theme.color, 95)};
    }
    &:hover{
        background: ${p => p.selectedContainer ? p.transparent ? lightenColor(p, p.theme.backgroundColor, 90) : lightenColor(p, p.theme.backgroundColor, 50) : p.noAnimation ? p.warning ? getColor(p, p.theme.colors.darkGrey) : getColor(p, p.theme.backgroundColor) : p.warning ? lightenColor(p, p.theme.colors.accent, 55) : p.noClick ? lightenColor(p, p.theme.backgroundColor, 90) : lightenColor(p, p.theme.backgroundColor, 95)};
        padding-left: ${p => p.selectedContainer ? 15 : p.noAnimation ? 0 : 10}px;
        color: ${p => p.warning ? getColor(p, p.theme.colors.mainBackground) : lightenColor(p, p.theme.color, 100)};
        border-left: ${p => p.noAnimation ? 1 : 1}px solid ${p => p.noAnimation ? getColor(p, p.theme.backgroundColor) : p.warning ? lightenColor(p, p.theme.colors.darkGrey, 85) : getColor(p, p.theme.colors.accent)};
        border-image: unset;
        svg{
            stroke: ${p => p.warning ? getColor(p, p.theme.colors.mainBackground) : lightenColor(p, p.theme.color, 100)};
        }
    }
`;
const TitleContainer = styled.div<{ noClick: boolean, uppercased?: boolean, bold?: boolean }>`
    width: 100%;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;  
    text-transform: ${p => p.uppercased ? "uppercase" : "none"};
    font-size: 0.85rem;
    user-select: ${p => p.noClick ? "none" : "auto"};
    font-weight: ${p => p.bold ? "bolder" : "normal"};
`;

const ButtonContainer = styled.div<{ selected: boolean, warning: boolean, iconColor?: string, hidden?: boolean }>`
    display: ${p => p.hidden ? "none" : "block"};
    transition: all 0.2s ease-out;
    background: ${p => p.warning ? lightenColor(p, p.theme.colors.darkGrey, 85) : p.selected ? lightenColor(p, p.theme.backgroundColor, 88) : "transparent"};
    float: right;
    width: 40px;
    height: 40px;
    svg{
        stroke: ${p => p.iconColor ? getColor(p, p.iconColor) : p.warning ? getColor(p, p.theme.colors.mainBackground) : lightenColor(p, p.theme.color, 95)};
    }
    &:hover{
        background: ${p => p.warning ? lightenColor(p, p.theme.colors.darkGrey, 80) : p.selected ? lightenColor(p, p.theme.backgroundColor, 88) : lightenColor(p, p.theme.backgroundColor, 88)};
        }
`;

const SidebarButton = (p: SidebarButtonProps) => {

    return (
        <Container
            selected={p.noContainerClick ? false : p.button[0].selected ?? false}
            onClick={p.noContainerClick ? undefined : p.button[0].onClick}
            noClick={p.noContainerClick ?? false}
            buttonCount={p.button.length}
            noAnimation={p.noAnimation ?? false}
            warning={p.warning ?? false}
            selectedContainer={p.selected ?? false}
            transparent={p.transparent ?? false}
        >
            <TitleContainer uppercased={p.uppercased ?? false} noClick={p.noContainerClick ?? false} bold={p.bold}>
                {p.icon &&
                    <Icon name={p.icon} style={{ float: "left", marginRight: 10 }} />
                }
                {p.title}
            </TitleContainer>
            <div style={{ width: (p.button.length * 40), textAlign: "right", transform: `translateX(${p.children ? 50 : 80})` }}>
                {_.map(p.button, (b, i) => {
                    return (
                        <ButtonContainer selected={b.selected ?? false} warning={p.warning ?? false} iconColor={b.iconColor} hidden={b.hidden} key={i}>
                            <Button
                                kind={b.kind ?? "fullTransparentButton"}
                                icon={b.icon}
                                onClick={b.onClick}
                                disabled={b.disabled ?? false}
                                active={b.active ?? false}
                                tooltip={b.tooltipText ? {
                                    tooltipText: b.tooltipText,
                                    position: b.tooltipPosition ?? "auto",
                                    notInline: false,
                                    noMargin: true,
                                    noArrow: b.noArrow ?? false
                                } : undefined}
                            />
                        </ButtonContainer>
                    );
                })}
            </div>
            {CurrentSettings.showGlobalSearchSwitchFilter && p.children ?
                <div style={{ height: 26, marginTop: 7, textAlign: "right", padding: "0 6px" }}>
                    {p.children}
                </div>
                :
                <div />
            }
        </Container>
    );
}
export default connect(mapper)(SidebarButton);