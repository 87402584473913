import { ContentController } from 'collaboration-service';
import GroupSelection from 'components/General/GroupSelection';
import { EditorsSC as U } from 'components/PostEdit/Editors/EditorsSC';
import EditPartContainer from 'components/PostEdit/EditPartContainer';
import { Icon, Label, LinkTo } from 'imaginarity-react-ui';
import * as React from 'react';
import { AppRouteNames, ViewRouteNames } from 'services/AppEntryDefinitions';
import { gotoNamedRoute } from 'services/Helpers/RoutingHelper';
import { useImgI18N } from 'services/ImgI18N';
import { CMSSC as T } from 'views/CMS/CMSSC';
import { WorkflowSC as W } from 'views/Workflow/WorkflowSC';
import PostTableCategoryLike from './PostTableCategoryLike';

export enum CategoryLikeTypes {
    // Category = "Categories", //Category = "Category",
    // Location = "Locations", //Location = "ContentPostContentLocation",    
    Category = "Category",
    Location = "ContentPostContentLocation",
    MediaType = "ContentPostMediaType",
    ContentType = "ContentPostContentType"
};

interface ManageCategoryLikeProps {
    namespace?: string;
    kind?: CategoryLikeTypes;
    homeRoute?: ViewRouteNames | AppRouteNames;
    groupType?: string[];
}

const ManageCategoryLike = (p: ManageCategoryLikeProps) => {
    const { namespace, kind, homeRoute, groupType } = p;
    const ns = namespace ?? "admin";
    const { t } = useImgI18N(ns);

    const currentKind = kind ?? CategoryLikeTypes.Category;
    let path = '';
    let getterItems = undefined;
    if (currentKind === CategoryLikeTypes.Category) {
        getterItems = ContentController.GetCategories;
        path = 'cms_categories';
    }
    else if (currentKind === CategoryLikeTypes.Location) {
        getterItems = ContentController.GetContentPostContentLocations;
        path = 'cms_locations';
    }
    else if (currentKind === CategoryLikeTypes.MediaType) {
        getterItems = ContentController.GetContentPostMediaTypes;
        path = 'cms_medias';
    }
    else if (currentKind === CategoryLikeTypes.ContentType) {
        getterItems = ContentController.GetContentPostContentType;
        path = 'cms_contents';
    }

    if (getterItems === undefined) {
        return (<div>not implemented yet</div>);
    }

    return (
        <U.LanguageStringEditorSC.container hidden={false} style={{ marginTop: 10 }}>
            {homeRoute &&
                <T.Breadcrumb style={{ marginBottom: -10 }}>
                    <LinkTo
                        noAnimation
                        icon="home"
                        noBoldOnHover
                        hoverColor="@accent"
                        onClick={() => gotoNamedRoute(homeRoute)}
                    />
                    <LinkTo
                        noBoldOnHover
                        icon="chevron right"
                        noAnimation
                        linkText={homeRoute}
                        onClick={() => gotoNamedRoute(homeRoute)}
                    />
                    <T.BreadcrumbActiveSite>
                        <LinkTo
                            noBoldOnHover
                            icon="chevron right"
                            noAnimation
                            linkText={t("manage {{path}}", { path: path })}
                            noLink
                            boldText
                        />
                    </T.BreadcrumbActiveSite>
                </T.Breadcrumb>
            }

            <div style={{ width: "100%", margin: 0 }}>
                <EditPartContainer icon="info" title='' translatedTitle={t("manage {{path}}", { path: path })} bold>
                    <W.EG style={{ gridTemplateColumns: "180px 1fr" }}>
                        <W.D>
                            <Icon name="tag" marginTop={6} style={{ float: "left", marginRight: 5 }} />
                            <Label content={t("select group")} bold />
                        </W.D>
                        <GroupSelection
                            groupTypes={groupType}
                            inAdminGroups
                        />
                    </W.EG>
                </EditPartContainer>
                <div style={{ marginTop: -20 }} />
                <PostTableCategoryLike
                    namespace={ns}
                    kind={currentKind}
                    getterItems={getterItems}
                    path={homeRoute + '/' + path} />
            </div>
        </U.LanguageStringEditorSC.container>
    );
}
export default ManageCategoryLike;