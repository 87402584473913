import * as React from 'react';
import SanitizedHTML from 'react-sanitized-html';
import { sanitizedAllowedAttributesMediumSafety, sanitizedAllowedTagsMediumSafety } from 'services/Helpers/SanitizedHelper';

export interface SafeHTMLProps {
    html: string;
    allowedTags?: string[];
    allowedAttributes?: { [tag: string]: string[] };
}

export default class SafeHTML extends React.PureComponent<SafeHTMLProps> {
    public render() {
        const { html, allowedTags, allowedAttributes } = this.props;
        return (
            <SanitizedHTML
                allowedTags={allowedTags ?? sanitizedAllowedTagsMediumSafety}
                allowedAttributes={allowedAttributes ?? sanitizedAllowedAttributesMediumSafety}
                html={html}
            />
        );
    }
}
