import { slideInLeft } from 'components/User/UserSC';
import { appendPx, fadeColor, getButtonSizes, getColor, lightenColor, styled } from 'imaginarity-react-ui';

export const columnActionWidth: number = 81;
export const columnFlagWidth: number = 190;

export class LoadingDataTableSC {
    public static Header = styled.div<{ open?: boolean, bold?: boolean, noHoverEffect?: boolean }>`
        width:100%;
        text-align: left;
        height: ${p => appendPx(getButtonSizes(p.theme, "default").buttonHeight)};
        line-height: ${p => appendPx(getButtonSizes(p.theme, "default").buttonHeight)};
        background: ${p => getColor(p, "@veryLightGrey")};
        color: ${p => p.open ? getColor(p, "@darkerGrey") : getColor(p, "@mainForeground")};
        border-left: 3px solid ${p => getColor(p, "@accent")};
        padding-left: 10px;
        transition: all 0.2s ease-out;
        font-size: 1em;
        font-weight: ${p => p.bold ? "bolder" : 500};
        &:hover{
            background: ${p => p.noHoverEffect ? getColor(p, "@veryLightGrey") : lightenColor(p, "@veryLightGrey", 98)};
        }
`;
    public static LanguageStringEditorSC = {
        container: styled.div<{ hidden?: boolean }>`
            transition: all 0.2s ease-out;
            width: 100%;
            margin-top: -5px;
            ${p => p.hidden ? "display: none;" : ""}
    `,
        table: styled.table`
            width: 100%;
            margin-top: 5px;
            transition: all 0.2s ease-out;
            margin-bottom: 0px;
            ${p => { const b = p.theme.loadingDataTable?.borders?.left; return b ? `border-left: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            ${p => { const b = p.theme.loadingDataTable?.borders?.right; return b ? `border-right: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            ${p => { const b = p.theme.loadingDataTable?.borders?.top; return b ? `border-top: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            ${p => { const b = p.theme.loadingDataTable?.borders?.bottom; return b ? `border-bottom: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
    `,
        th: styled.th`
            //display: table-cell;
            //background-color: ${p => getColor(p, p.theme?.loadingDataTable?.header?.backgroundColor ?? "transparent")};
            color: ${p => getColor(p, "@accentBlack")};
            line-height: 40px;
            text-align: left;
            padding-left: 10px;
            font-weight: bolder;
            overflow: hidden;
            height: 80px;
            font-size: 1em;
            &:first-child{
                ${p => { const b = p.theme.loadingDataTable?.header?.firstChildBorders?.left; return b ? `border-left: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
                ${p => { const b = p.theme.loadingDataTable?.header?.firstChildBorders?.right; return b ? `border-right: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
                ${p => { const b = p.theme.loadingDataTable?.header?.firstChildBorders?.top; return b ? `border-top: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
                ${p => { const b = p.theme.loadingDataTable?.header?.firstChildBorders?.bottom; return b ? `border-bottom: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            }
            &:last-child{
                ${p => { const b = p.theme.loadingDataTable?.header?.lastChildBorders?.left; return b ? `border-left: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
                ${p => { const b = p.theme.loadingDataTable?.header?.lastChildBorders?.right; return b ? `border-right: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
                ${p => { const b = p.theme.loadingDataTable?.header?.lastChildBorders?.top; return b ? `border-top: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
                ${p => { const b = p.theme.loadingDataTable?.header?.lastChildBorders?.bottom; return b ? `border-bottom: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            }
            ${p => { const b = p.theme.loadingDataTable?.header?.cellBorders?.left; return b ? `border-left: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            ${p => { const b = p.theme.loadingDataTable?.header?.cellBorders?.right; return b ? `border-right: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            ${p => { const b = p.theme.loadingDataTable?.header?.cellBorders?.top; return b ? `border-top: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            ${p => { const b = p.theme.loadingDataTable?.header?.cellBorders?.bottom; return b ? `border-bottom: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
    `,
        thC: styled.div`
            display: grid;
            grid-template-rows: 40px 60px;
    `,
        thFilterContainer: styled.div`
            height: 40px;
            width: 100%;
            position: relative;
            font-weight: normal;
    `,
        thSortC: styled.div<{ noSorting: boolean }>`
            height: 40px;
            width: 100%;
            display: grid;
            grid-template-columns: max-content 1fr;
            column-gap: 10px;
            cursor: ${p => p.noSorting ? "default" : "pointer"};
            user-select: none;
            position: relative;
    `,
        trHead: styled.tr<{ header?: boolean, filterOpen: boolean }>`
            position: relative;
            width: auto;
            height: ${p => p.header ? "90" : "40"}px;
            line-height: 40px;
            background-color: ${p => p.filterOpen ? lightenColor(p, p.theme?.loadingDataTable?.header?.backgroundColor ?? "@mainBackground", 97) : getColor(p, p.theme?.loadingDataTable?.header?.backgroundColor ?? "@mainBackground")};
            transition: all 0.2s ease-out;
    `,

        tr: styled.tr<{ selected: boolean, header?: boolean }>`
    //display: table-row;
    width: auto;
    height: ${p => p.header ? "90" : "40"}px;
    line-height: 40px;
    border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
    background-color: ${p => p.selected ? (getColor(p, p.theme?.loadingDataTable?.cell?.backgroundColorSelected ?? "fade('@accentBlue', 5%)")) : getColor(p, p.theme?.loadingDataTable?.cell?.backgroundColor ?? "@mainBackground")};
    
    ${p => p.theme.loadingDataTable?.cell?.alternateTableRowColor &&
                `&:nth-child(odd){ background-color: ${p.selected ? (getColor(p, p.theme?.loadingDataTable?.cell?.backgroundColorSelected ?? "fade('@accentBlue', 5%)")) : lightenColor(p, p.theme?.loadingDataTable?.cell?.backgroundColor ?? "@mainBackground", 98)};}`
            }
    transition: all 0.2s ease-out;
    margin-bottom: 10px;
    &:hover{
        background: ${p => p.header ? "red" : p.selected ? (getColor(p, p.theme?.loadingDataTable?.cell?.backgroundColorSelected ?? "fade('@accentBlue', 5%)")) : lightenColor(p, p.theme?.loadingDataTable?.cell?.backgroundColor ?? "@mainBackground", 96)};
        ${p => p.theme.loadingDataTable?.cell?.alternateTableRowColor &&
                `&:nth-child(odd){ background-color: ${p.selected ? (getColor(p, p.theme?.loadingDataTable?.cell?.backgroundColorSelected ?? "fade('@accentBlue', 5%)")) : lightenColor(p, p.theme?.loadingDataTable?.cell?.backgroundColor ?? "@mainBackground", 94)};}`
            }
    }
`,
        td: styled.td`
            &:first-child{
                ${p => { const b = p.theme.loadingDataTable?.cell?.firstChildBorders?.left; return b ? `border-left: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
                ${p => { const b = p.theme.loadingDataTable?.cell?.firstChildBorders?.right; return b ? `border-right: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
                ${p => { const b = p.theme.loadingDataTable?.cell?.firstChildBorders?.top; return b ? `border-top: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
                ${p => { const b = p.theme.loadingDataTable?.cell?.firstChildBorders?.bottom; return b ? `border-bottom: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            }
            &:last-child{
                ${p => { const b = p.theme.loadingDataTable?.cell?.lastChildBorders?.left; return b ? `border-left: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
                ${p => { const b = p.theme.loadingDataTable?.cell?.lastChildBorders?.right; return b ? `border-right: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
                ${p => { const b = p.theme.loadingDataTable?.cell?.lastChildBorders?.top; return b ? `border-top: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
                ${p => { const b = p.theme.loadingDataTable?.cell?.lastChildBorders?.bottom; return b ? `border-bottom: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            }
            ${p => { const b = p.theme.loadingDataTable?.cell?.cellBorders?.left; return b ? `border-left: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            ${p => { const b = p.theme.loadingDataTable?.cell?.cellBorders?.right; return b ? `border-right: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            ${p => { const b = p.theme.loadingDataTable?.cell?.cellBorders?.top; return b ? `border-top: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            ${p => { const b = p.theme.loadingDataTable?.cell?.cellBorders?.bottom; return b ? `border-bottom: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            //background-color: ${p => getColor(p, p.theme?.loadingDataTable?.cell?.backgroundColor ?? "transparent")};
            line-height: 40px;
            height: 40px;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding: 0 2px 0 10px;
            span{
                margin:0;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            button>div{margin:0;}
            
    `,
        subtd: styled.td`

            //display: table-cell;
            column-span: 100;
            line-height: 40px;
            width: 100%;
            //border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
            //border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
            //border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
            ${p => { const b = p.theme.loadingDataTable?.cell?.cellBorders?.bottom; return b ? `border-left: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            ${p => { const b = p.theme.loadingDataTable?.cell?.cellBorders?.bottom; return b ? `border-right: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            ${p => { const b = p.theme.loadingDataTable?.cell?.cellBorders?.bottom; return b ? `border-bottom: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
            
            padding: 0 2px 0 10px;
            margin-bottom: 10px;
            //background: ${p => getColor(p, "lighten('@mainBackground', 98%)")};
            color: ${p => getColor(p, "@accentBlack")};
            span{
                margin:0;
                width: 100%;
            }
            button>div{margin:0;}
            &:hover{
                //background: ${p => getColor(p, "lighten('@mainBackground', 98%)")};
            }
    `,
        tbody: styled.tbody`
    `,
        thead: styled.thead`
    `,
        rowGrid: styled.div<{ cols: string }>`
            display: grid;
            display: -ms-grid;

        width: 100%;
            grid-template-columns: ${p => p.cols};
            -ms-grid-columns: ${p => p.cols};
            border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    `,
        rowGridCol: styled.div<{ col: number }>`
            grid-column: ${p => p.col + 1};
            -ms-grid-column: ${p => p.col + 1};
            padding: 1px;
            line-height: 40px;
            height: 40px;
    `,
        resizer: styled.div<{ resizing?: boolean }>`
        right: 0;
        background-color: blue;
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        ${p => p.resizing ? "background: red" : ""};
    `,

        cellProps: (props: any, { cell }: { cell: any }) => LoadingDataTableSC.LanguageStringEditorSC.getStyles(props, cell.column.align, cell.column.padding),

        getStyles: (props: any, align = 'left', padding = undefined) => [
            props,
            {
                style: {
                    justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
                    alignItems: 'flex-start',
                    display: 'flex',
                    padding: padding,
                },
            },
        ]
    };

    public static Grid = styled.div`
        display: grid;
        width: 100%;
        grid-template-columns: 1fr max-content;
        line-height: 40px;
        box-shadow: ${p => getColor(p, p.theme?.loadingDataTable?.footer?.boxShadow ?? "none")};
        button>div{
            margin: 0;
        }
`;
    public static BottomLine = styled.div`
        position: absolute;
        bottom: 0px;
        left: -10px;
        right: -10px;
        height: 1px;
        background: ${p => getColor(p, p.theme.loadingDataTable?.header?.cellBorders.bottom?.color ?? p.theme.colors.middleLightGrey)};
`;
    public static GridLeft = styled.div`
        width: 100%;
        background-color: ${p => getColor(p, p.theme?.loadingDataTable?.footer?.backgroundColor ?? "transparent")};
        ${p => { const b = p.theme.loadingDataTable?.footer?.firstChildBorders?.left; return b ? `border-left: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
        ${p => { const b = p.theme.loadingDataTable?.footer?.firstChildBorders?.right; return b ? `border-right: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
        ${p => { const b = p.theme.loadingDataTable?.footer?.firstChildBorders?.top; return b ? `border-top: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
        ${p => { const b = p.theme.loadingDataTable?.footer?.firstChildBorders?.bottom; return b ? `border-bottom: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
`;
    public static GridRight = styled.div`
        background-color: ${p => getColor(p, p.theme?.loadingDataTable?.footer?.backgroundColor ?? "transparent")};
        ${p => { const b = p.theme.loadingDataTable?.footer?.lastChildBorders?.left; return b ? `border-left: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
        ${p => { const b = p.theme.loadingDataTable?.footer?.lastChildBorders?.right; return b ? `border-right: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
        ${p => { const b = p.theme.loadingDataTable?.footer?.lastChildBorders?.top; return b ? `border-top: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
        ${p => { const b = p.theme.loadingDataTable?.footer?.lastChildBorders?.bottom; return b ? `border-bottom: ${b.thickness}px solid ${getColor(p, b.color)};` : "" }}
`;
    public static Styles = styled.div`
        max-width: 100%;
        div{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    p {
        line-height: 16px;
        margin-top: 12px; 
    }    
`;
    public static CellContainer = styled.div`
        width: calc(100% - 40px);
        float: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
`;
    public static Container = styled.div`
        padding-top: 20px;
        padding-bottom: 150px;
`;
    public static ELCContainer = styled.div`
        display: grid;
        width: 100%;
        grid-template-columns: 160px 1fr;
        line-height: 40px;
`;
    public static ContainerContent = styled.div`
        /* padding: 0px;
        border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
        border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
        border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
        transition: all 0.2s ease-out;
        &:hover{
            border-left: 1px solid ${p => getColor(p, "@lightGrey")};
            border-bottom: 1px solid ${p => getColor(p, "@lightGrey")};
            border-right: 1px solid ${p => getColor(p, "@lightGrey")};
            background: ${p => getColor(p, "@veryLightGrey")};
        } */
        padding-top: 5px;
        padding-bottom: 15px;
`;
    public static ContainerBottom = styled.div`
        height: 40px;
        width: 100%;
        //background: ${p => getColor(p, "@veryLightGrey")};
        margin: 10px 0px 10px 0px;
        button>div{
            margin: 0;
        }
`;
    public static G = styled.div`
        display: grid;
        grid-template-columns: 40px 1fr 4fr;
        border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")}; 
        &:hover{
            background: ${p => getColor(p, "@veryLightGrey")}; 
        }
`;
    public static G1 = styled.div`
        display: grid;
        grid-template-columns: 1fr 40px max-content;
        border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")}; 
        &:hover{
            background: ${p => getColor(p, "@veryLightGrey")}; 
        }
`;
    public static D = styled.div`
        line-height: 40px;
        padding: 1px;
        height: 40px;
        button>div{margin: 0;}
`;
    public static L = styled.div`
        line-height: 40px;
        padding: 1px;
        padding-left: 10px;
        height: 40px;
`;
    public static ETDGrid = styled.div`
        display: grid;
        grid-template-columns: 190px 190px;
`;

    public static TableInfo = styled.div`
        float: left;
        line-height: 12px;
        font-size: 0.7rem;
        padding-top: 8px;
        padding-left: 10px;
        color: ${p => getColor(p, "@lightGrey")}; 
        padding-right: 20px;
        text-transform: uppercase;
`;

    public static ContentTypeContainer = styled.div<{ hoverable: boolean }>`
        cursor: ${p => p.hoverable ? "pointer" : "default"};
        display: grid;
        width: calc(100% - 20px);
        grid-template-columns: 1fr 20px;
        line-height: 20px;
        transition: all 0.2s ease-out;
        &:hover{
            color: ${p => p.hoverable ? getColor(p, "@accent") : "inherit"}; 
            svg{
                stroke: ${p => p.hoverable ? getColor(p, "@accent") : "inherit"}; 
            }
        }
`;

    public static SubTitle = styled.div`
    display: inline-block;
    margin-left: 10px;
    font-size: 0.9em;
    color: ${p => lightenColor(p, "@veryLightGrey", 40)};
`;

    public static FileIconContainer = styled.div<{ showInfo: boolean }>`
    float: left;
    width: 40px;
    height: 40px;
    margin-left: ${p => p.showInfo ? -10 : -13}px;
    margin-right: ${p => p.showInfo ? -1 : 3}px;
    position: relative;
    background: ${p => p.showInfo ? "transparent" : lightenColor(p, "@veryLightGrey", 97)};
`;
    public static FileIconContainerTrans = styled.div`
    position: absolute;
    top: -4px;
    left: 3px;
    &>div{
        width: 32px;
        height: 32px;
    }
`;
    public static BtnC = styled.div`
    width: 40px;
    height: 80px;
    float: left;
    margin-left: -10px;
    margin-top: 0px;
    overflow: hidden;
    button>div{margin:0;}
`;

    public static SecondaryButtonsContainer = styled.div`
    box-shadow: 0 6px 8px -6px ${p => getColor(p, "@accentBlack")}, 0 0 20px -6px ${p => getColor(p, "@lightGrey")};
    background: transparent;
    width: 250px;
`;
    public static SecondaryButtonIconContainer = styled.div`
        position: relative;
        width: 100%;
        height: 100%;
    `;

    public static SecondaryButtonContainer = styled.div<{ disabled?: boolean, showSubComponent?: boolean, danger?: boolean }>`
        display: grid;
        cursor: pointer;
        ${p => {
            const h = appendPx(getButtonSizes(p.theme).buttonHeight)
            return `height: ${h}; grid-template-columns: ${h} 1fr;`
        }};
        background-color: ${p => p.showSubComponent ? getColor(p, "@veryLightGrey") : getColor(p, "backgroundColor")};
        width: 100%;
        transition: all 0.2s ease-out;
        &:hover{
            background-color: ${p => p.danger ? getColor(p, "@accentRed") : p.showSubComponent ? lightenColor(p, "@veryLightGrey", 97) : getColor(p, p.disabled ? "backgroundColor" : "@veryLightGrey")};
            svg{
                stroke: ${p => p.danger ? getColor(p, "@mainBackground") : "inherit"};
            }
            button{
                background-color: ${p => p.showSubComponent ? getColor(p, "@veryLightGrey") : getColor(p, p.disabled ? "backgroundColor" : "@veryLightGrey")};
            }
        }
    `;
    public static SecondaryButtonSortContainer = styled.div<{ disabled?: boolean, showSubComponent?: boolean }>`
        display: grid;
        cursor: pointer;
        ${p => {
            const h = appendPx(getButtonSizes(p.theme).buttonHeight)
            return `height: ${h}; grid-template-columns: ${h} 1fr 40px 40px;`
        }};
        background-color: ${p => p.showSubComponent ? getColor(p, "@veryLightGrey") : getColor(p, "backgroundColor")};
        width: 100%;
        &:hover{
            background-color: ${p => p.showSubComponent ? lightenColor(p, "@veryLightGrey", 97) : getColor(p, p.disabled ? "backgroundColor" : "@veryLightGrey")};
            button{
                background-color: ${p => p.showSubComponent ? getColor(p, "@veryLightGrey") : getColor(p, p.disabled ? "backgroundColor" : "@veryLightGrey")};
            }
        }
`;
    public static SubComponentContainer = styled.div`
        width: 100%;
        border-top: ${p => `1px solid ${getColor(p, "@lightGrey")}`};
        border-bottom: ${p => `1px solid ${getColor(p, "@lightGrey")}`};
    `;

    public static Outer = styled.div<{ expandedTable?: boolean }>`
    padding-bottom: 20px;
    ${p => p.expandedTable && "overflow-x: auto;"};
    margin-left: -1px;
    position: relative;
    button>div{margin: 0;}
    ::-webkit-scrollbar {
        height: 5px !important;       
    }
`;
    public static C = styled.div`
    height: 40px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
`;
    public static S = styled.div<{ found: boolean }>`
    float: left;
    margin-right: 3px;
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    padding: 0 3px;
    margin-top: 6px;
    height: 28px;
    line-height: 28px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;  
    font-size: 0.8em;
    ${p => p.found ? "background-color:" + fadeColor(p, "@accentGreen", 15) + ";" : ""}
`;
    public static FilterIconContainer = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
`;

    public static ClearContainer = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    svg{
        fill: ${p => fadeColor(p, "@darkGrey", 60)};
        stroke-width: 0;
        &:hover{
            fill: ${p => getColor(p, "@accent")};
        }
    }
    `;

    public static InputContainer = styled.div`
    position: relative;
    top: 0px;
    left: 0;
    height: 40px;
    `;


    public static SelectionLost = styled.div`
    float: left;
    padding-left: 10px;
    animation: ${slideInLeft} 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both 0.5s;
    color: ${p => getColor(p, "@accentRed")};
    svg{
        stroke: ${p => getColor(p, "@accentRed")};
    }
    `;
}