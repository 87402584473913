import { CategoryDownloadDto } from 'collaboration-service';
import { ElementNode } from 'collaboration-service/dist/dtos/ElementNode';
import { ImgIcons } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { ViewRouteNames } from 'services/AppEntryDefinitions';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { sortCategories } from 'services/Helpers';
import { gotoNamedRoute } from 'services/Helpers/RoutingHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { BreadcrumbsSC as T } from './BreadcrumbsSC';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage, params: state.params });
export interface BreadcrumbCategoryProps extends ComponentWithMappedApplicationState<typeof mapper> {
    node: ElementNode<CategoryDownloadDto>;
    darkMode: boolean;
    spacerIcon: ImgIcons;
    routeIndex: number;
    categoryRouteName: ViewRouteNames;
    accent?: string;
}

const BreadcrumbCategory = (p: BreadcrumbCategoryProps) => {
    const [opened, setOpened] = React.useState<boolean>(false);
    const hasNeighbourCategories = p.node.children !== undefined ?? false;

    const click = (catid: string) => () => {
        let cats = {};
        switch (p.routeIndex) {
            case 0:
                cats = { catid };
                break;
            case 1:
                cats = { catid: p.params?.catid, catid1: catid };
                break;
            case 2:
                cats = { catid: p.params?.catid, catid1: p.params?.catid1, catid2: catid };
                break;
            case 3:
                cats = { catid: p.params?.catid, catid1: p.params?.catid1, catid2: p.params?.catid2, catid3: catid };
                break;
            case 4:
                cats = { catid: p.params?.catid, catid1: p.params?.catid1, catid2: p.params?.catid2, catid3: p.params?.catid3, catid4: catid };
                break;
            case 5:
                cats = { catid: p.params?.catid, catid1: p.params?.catid1, catid2: p.params?.catid2, catid3: p.params?.catid3, catid4: p.params?.catid4, catid5: catid };
                break;
        }

        gotoNamedRoute(p.categoryRouteName, cats);
    }

    return (
        <>
            <span onClick={() => setOpened(true)} >
                <T.Chevron darkMode={p.darkMode} name={p.spacerIcon} opened={opened} hasNeighbourCategories={hasNeighbourCategories} accent={p.accent} />
            </span>

            <T.ParentCategory darkMode={p.darkMode} onMouseLeave={() => setOpened(false)} accent={p.accent}>
                <div onClick={click(p.node.node!.id)}>
                    {getTranslated(p.node.node!.names, p.contentLanguage)?.text}
                </div>
                {hasNeighbourCategories && opened &&
                    <T.MoreParentCategoriesContainer
                        darkMode={p.darkMode}
                        onMouseLeave={() => setOpened(false)}
                        onClick={() => setOpened(false)}
                    >
                        {_.map(sortCategories(p.node.children), c => {
                            return (
                                <T.MoreParentCategoriesItem darkMode={p.darkMode} key={c.id} onClick={click(c.id)} accent={p.accent}>
                                    <T.FolderIcon darkMode={p.darkMode} name="folder" size="16px" />
                                    {getTranslated(c.names, p.contentLanguage)?.text}
                                </T.MoreParentCategoriesItem>
                            );
                        })}
                    </T.MoreParentCategoriesContainer>
                }
            </T.ParentCategory>


        </>
    );
}
export default connect(mapper)(BreadcrumbCategory);
