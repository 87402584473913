import { CategoryConfigurationDownloadDto, CategoryDownloadDto, ConfigurationsWithCategories, ConfigurationWithCategories, ContentController, ControllerHelper } from "collaboration-service";
import { FacetResult } from "collaboration-service/dist/dtos/FacetResult";
import { Button, ImgOnChangeValue, ImgSelectProps, styled } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from "react";
import { reducerBatchJob, reducerSetIfChanged, reducerUpdater } from "services/Helpers";
import { getTranslated } from "services/Helpers/TranslationHelpers";
import { useImgI18N } from "services/ImgI18N";
import { useAppTheme } from "services/useAppTheme";
import { ThemeContext } from "styled-components";
import { CMSTheme } from "views/CMS/CMSTheme";
import { createFilledStyles } from "views/Workflow/State/Operations/CopyToGroupWorkflowTransitionOperation";
import CategorySelectionExtended, { CategorySelectionExtendedProps } from "./CategorySelectionExtended";
export type CategoryTypeSelectionError = { msg: string, data: any };
export type CategoryTypeSelectionValidationResult = { [key: string]: CategoryTypeSelectionError | undefined };

interface useCategoryTypeSelectionProps {
    grouptype: string;
    type: "filter" | "normal";
    onCategorySelected?: (categoryType: string, selected: CategoryDownloadDto[], ok: boolean) => void;
    onError?: (error: CategoryTypeSelectionError) => void;
    onValidation?: (results: CategoryTypeSelectionValidationResult) => void;
    selectProps?: Omit<ImgSelectProps<CategoryDownloadDto, true>, "onChange" | "isMulti" | "isClearable">;
    selectedCategories?: string[] | CategoryDownloadDto[];
    filter?: CategoryDownloadDto[];
    categoryConfiguration?: ConfigurationsWithCategories;
    counts?: FacetResult[];
    lng?: string;
}
export interface UCTSRenderItem {
    Select: JSX.Element;
    configuration: CategoryConfigurationDownloadDto;
    categories: CategoryDownloadDto[];
}

export interface UCTSState {
    selections: { [key: string]: CategoryDownloadDto[] }
    loadedConfigurations?: ConfigurationsWithCategories;
    configurations?: ConfigurationsWithCategories;
    selectedCategories?: CategoryDownloadDto[];
    validation: CategoryTypeSelectionValidationResult;
    toRender?: UCTSRenderItem[];
    givenCategories?: string[] | CategoryDownloadDto[];
}

type UCTSActions =
    | { type: "batch", value: UCTSActions[] }
    | { type: "set_selections", value: { [key: string]: CategoryDownloadDto[] } }
    | { type: "manip_selections", value: (selections: { [key: string]: CategoryDownloadDto[] }) => { [key: string]: CategoryDownloadDto[] } }
    | { type: "set_loaded_configurations", value?: ConfigurationsWithCategories }
    | { type: "set_configurations", value?: ConfigurationsWithCategories }
    | { type: "set_selected_categories", value?: CategoryDownloadDto[] }
    | { type: "set_validation", value: CategoryTypeSelectionValidationResult }
    | { type: "set_to_render", value?: UCTSRenderItem[] }
    | { type: "set_given_categories", value?: string[] | CategoryDownloadDto[] }
    ;

const initState = (): UCTSState => ({
    selections: {},
    validation: {},
});

const isEqualSelections = (left: { [key: string]: CategoryDownloadDto[] }, right: { [key: string]: CategoryDownloadDto[] }, state: UCTSState) => {
    const keys = _.map(state.configurations?.configurations, c => c.configuration.categoryType);
    for (let si = 0; si < keys.length; si++) {
        const key = keys[si];
        if (!_.isEqual(left[key], right[key])) {
            return false;
        }
    }
    return true;
}

const reducer = (state: UCTSState, action: UCTSActions, silent?: boolean): UCTSState => {
    // if (!silent)
    //     console.log("------------> reducer => action.type", action.type, " value => ", action.value);
    switch (action.type) {
        case "batch":
            return reducerBatchJob(state, action.value, reducer);
        case "set_selections": {
            if (!isEqualSelections(state.selections, action.value, state)) {
                const selCats: CategoryDownloadDto[] = [];
                _.forEach(action.value, val => selCats.push(...val));
                const s = reducer(state, { type: "set_selected_categories", value: _.uniqBy(selCats, c => c.id) });
                return {
                    ...s,
                    selections: action.value
                }
            }
            //          console.log("leave it!");
            return state;
        }
        case "manip_selections":
            return reducerUpdater(state, "selections", action.value, (newS, newSel) => {
                const selCats: CategoryDownloadDto[] = [];
                _.forEach(newSel, val => selCats.push(...val));
                return reducer(newS, { type: "set_selected_categories", value: _.uniqBy(selCats, c => c.id) });
            });
        case "set_configurations":
            return reducerSetIfChanged(state, "configurations", action.value);
        case "set_loaded_configurations":
            return reducerSetIfChanged(state, "loadedConfigurations", action.value);
        case "set_selected_categories":
            return reducerSetIfChanged(state, "selectedCategories", action.value);
        case "set_validation":
            return reducerSetIfChanged(state, "validation", action.value);
        case "set_to_render":
            return reducerSetIfChanged(state, "toRender", action.value);
        case "set_given_categories":
            return reducerSetIfChanged(state, "givenCategories", action.value);
    }
}

//const empty: CategoryDownloadDto[] = [];
type InnerSelectProps<IsMulti extends boolean> = CategorySelectionExtendedProps<IsMulti> & { selectAllHelper: boolean, type: useCategoryTypeSelectionProps["type"] };

const C = styled.div<{ selectAllHelper: boolean }>`
    //display: grid;
    //width: 100%;
    //grid-template-columns: ${p => p.selectAllHelper ? "1fr max-content" : "1fr"};
    width: ${p => p.selectAllHelper ? "calc(100% - 42px)" : "100%"};
    max-height: 240px;
    overflow: auto;
    float: left;
    &>button{ margin: 0; }
`;

const InnerSelect = <IsMulti extends boolean = true>(p: InnerSelectProps<IsMulti>) => {
    const { selectAllHelper, categories, value, onCategoryChanged, type, isMulti, ...rest } = p;
    const { t } = useImgI18N("cms");
    const [allSelected, setAllSelected] = React.useState<boolean>(false);

    const chg = React.useMemo(() => (value: ImgOnChangeValue<CategoryDownloadDto, IsMulti, false>) => {
        onCategoryChanged(value);
    },
        [onCategoryChanged]);
    const selAll = React.useMemo(() => () => {
        // if (isMulti)
        //     onCategoryChanged(categories as any);
        if (isMulti) {
            onCategoryChanged(categories as any);
            setAllSelected(true);
        }
    }, [onCategoryChanged, categories, isMulti]);

    const selNothing = React.useMemo(() => () => {
        if (isMulti && categories) {
            onCategoryChanged(null as any);
            setAllSelected(false);
        }
    }, [onCategoryChanged, categories, isMulti]);

    return (
        <div>
            <C selectAllHelper={isMulti && selectAllHelper && type === "normal"}>
                <CategorySelectionExtended
                    onCategoryChanged={chg}
                    categories={categories}
                    value={value}
                    isMulti={isMulti}
                    {...rest}
                />
            </C>
            {isMulti && selectAllHelper && type === "normal" &&
                <Button
                    // icon="select all"
                    // tooltip={{ tooltipText: t("select all") }}
                    // onClick={selAll}
                    icon={allSelected ? "times" : "select all"}
                    kind="cancelButton"
                    active={allSelected}
                    tooltip={{ tooltipText: allSelected ? t("deselect all") : t("select all") }}
                    onClick={allSelected ? selNothing : selAll}
                    floated="right"
                />
            }
        </div>
    )
}

const useCategoryTypeSelection = (p: useCategoryTypeSelectionProps) => {
    const { grouptype, type, onCategorySelected, selectProps, onError, selectedCategories: paramSelectedCategories, filter, categoryConfiguration, lng, counts } = p;

    // React.useEffect(() => console.log("useCategoryTypeSelection.tsx::180 **Changed** => paramSelectedCategories", paramSelectedCategories), [paramSelectedCategories]);
    // React.useEffect(() => console.log("useCategoryTypeSelection.tsx::181 **Changed** => filter", filter), [filter]);
    // React.useEffect(() => console.log("useCategoryTypeSelection.tsx::182 **Changed** => categoryConfiguration", categoryConfiguration), [categoryConfiguration]);

    //const {t, currentLanguage, currentNamespace, changeLanguage, createT, imgI18NUpdateCounter } = useImgI18N("general");
    //const {} = useSelector(mapper, shallowCompare);

    const [state, dp] = React.useReducer(reducer, initState());

    const { configurations, selections, selectedCategories, validation, toRender, givenCategories, loadedConfigurations } = state;

    // React.useEffect(() => console.log("useCategoryTypeSelection.tsx::201 **Changed** => paramSelectedCategories", paramSelectedCategories), [paramSelectedCategories]);
    // React.useEffect(() => console.log("useCategoryTypeSelection.tsx::202 **Changed** => dp", dp), [dp]);

    React.useEffect(() => {
        dp({ type: "set_given_categories", value: paramSelectedCategories });
    }, [dp, paramSelectedCategories])

    React.useEffect(() => {
        const load = async () => {
            const data = categoryConfiguration ?? await ControllerHelper.singleCall({ grouptype }, ContentController.GetCategoriesForAllGroupsByGroupTypeWithConfigurations)
            dp({ type: "set_loaded_configurations", value: data });
        }
        load();
    }, [grouptype, categoryConfiguration]);

    React.useEffect(() => {
        if (loadedConfigurations) {
            const toSet = _.cloneDeep(loadedConfigurations);
            // console.log("useCategoryTypeSelection.tsx::202 => filter", filter);
            // console.log("useCategoryTypeSelection.tsx::203 => loadedConfigurations", loadedConfigurations);


            if (filter !== undefined)
                _.forEach(toSet.configurations, conf => {
                    conf.categories = _.filter(conf.categories, cat => _.findIndex(filter, filterCat => filterCat.id === cat.id) >= 0);
                });
            toSet.configurations = _.filter(toSet.configurations, conf => conf.categories.length > 0);
            dp({ type: "set_configurations", value: toSet });
        }
    }, [loadedConfigurations, filter]);

    const onSelect = React.useMemo(() => (config: CategoryConfigurationDownloadDto) => (value: CategoryDownloadDto | CategoryDownloadDto[]) => {
        //        console.log("useCategoryTypeSelection.tsx::37 => value", value);
        const val = value === null ? [] : (_.isArray(value) ? value : [value]);
        const max = type === "filter" ? config.filterCountMax : config.selectCountMax;
        const min = type === "filter" ? 0 : config.selectCountMin;
        if (val.length > max) {
            if (onError)
                onError({ msg: `to many elements selected for {{type}} - maximum number of selections is {{max}}`, data: { type: getTranslated(config.names)?.text ?? "", max } });
            //            console.log("useCategoryTypeSelection.tsx::43 => to many!");
        }
        else {
            dp({
                type: "manip_selections", value: s => {

                    const toRet = { ...s };
                    toRet[config.categoryType] = val;
                    //                    console.log("useCategoryTypeSelection.tsx::49 => setting it to", toRet);
                    return toRet;
                }
            });
            if (onCategorySelected)
                onCategorySelected(config.categoryType, val, val.length >= min);
        }

    }, [onCategorySelected, type, onError, dp])
    // React.useEffect(() => {
    //     console.log("givenCategories => ", JSON.stringify(givenCategories));
    // }, [givenCategories]);
    // React.useEffect(() => {
    //     console.log("configurations");
    // }, [configurations]);
    // React.useEffect(() => {
    //     console.log("dp");
    // }, [dp]);
    React.useEffect(() => {
        // console.log("useCategoryTypeSelection.tsx::62 => givenSelectedCategories", givenCategories);
        // console.log("useCategoryTypeSelection.tsx::63 => configurations", configurations);


        if (givenCategories && configurations) {
            // console.log("useCategoryTypeSelection.tsx::169 => goOn");

            const newSel: { [key: string]: CategoryDownloadDto[] } = {};
            const f = givenCategories[0];
            if (f) {
                let a: string[] = [];
                if (typeof f === "string") {
                    //console.log("type is string")
                    a = givenCategories as string[];
                }
                else {
                    //console.log("type is CategoryDownloadDto")
                    a = _.map(givenCategories as CategoryDownloadDto[], c => c.id);
                }
                _.forEach(a, cid => {
                    let conf: ConfigurationWithCategories | undefined;
                    let cat: CategoryDownloadDto | undefined;
                    const cA = configurations?.configurations ?? [];
                    for (let ci = 0; ci < cA.length; ci++) {
                        cat = _.find(cA[ci].categories, cc => cc.id === cid)
                        if (cat !== undefined) {
                            conf = cA[ci];
                            break;
                        }
                    }
                    if (conf && cat) {
                        if (!newSel[conf.configuration.categoryType])
                            newSel[conf.configuration.categoryType] = [];
                        newSel[conf.configuration.categoryType].push(cat);
                    }
                });
                //console.log("useCategoryTypeSelection.tsx::86 => newSel", newSel);
            }

            //console.log("useCategoryTypeSelection.tsx::188 => update!");

            dp({ type: "set_selections", value: newSel });

        }
        else
            dp({ type: "set_selections", value: {} });
    }, [givenCategories, configurations, dp]);

    const theme = React.useContext(ThemeContext);
    const cmsTheme = useAppTheme<CMSTheme>("cmsTheme");
    const emptyStyles = React.useMemo(() => createFilledStyles(theme, false, cmsTheme.select.emptyColor, cmsTheme.select.emptyFadings), [theme, cmsTheme.select.emptyColor, cmsTheme.select.emptyFadings]);
    const filledStyles = React.useMemo(() => createFilledStyles(theme, false, cmsTheme.select.filledColor, cmsTheme.select.filledFadings), [theme, cmsTheme.select.filledColor, cmsTheme.select.filledFadings]);

    React.useEffect(() => {
        const validation: CategoryTypeSelectionValidationResult = {};

        _.forEach(configurations?.configurations, conf => {
            const key = conf.configuration.categoryType;
            const val = selections[key] ?? [];
            if (conf) {
                const min = type === "filter" ? 0 : conf.configuration.selectCountMin;
                if (val.length < min) {
                    validation[key] = { msg: "not enough values selected for {{type}} - minimum number is {{min}}", data: { type: getTranslated(conf.configuration.names)?.text ?? "", min } };
                }
            }
        })

        const toRender = _.map(configurations?.configurations, c => {
            const catsWithNoCounts = c.configuration.sortByOrder ? c.categories : _.sortBy(c.categories, cat => getTranslated(cat.names)?.text);
            const cats = _.map(catsWithNoCounts, c => ({ ...c, count: _.find(counts, ct => ct.data === c.id)?.count ?? 0 }));
            const val = selections[c.configuration.categoryType] ?? [];
            //console.log(`${c.configuration.categoryType} => `, val)

            return ({
                ...c, Select:
                    <InnerSelect
                        categories={cats}

                        value={val}
                        isMulti={type === "filter" ? c.configuration.filterCountMax > 1 : c.configuration.selectCountMax > 1}
                        onCategoryChanged={onSelect(c.configuration)}
                        key={c.configuration.categoryType}
                        isClearable={type === "filter" ? true : false}
                        styles={val.length > 0 ? filledStyles : emptyStyles}
                        selectAllHelper={c.configuration.selectAllHelper}
                        type={type}
                        lng={lng}
                        {...selectProps as any}
                    />
            })
        });
        const actions: UCTSActions[] = [
            { type: "set_validation", value: validation },
            { type: "set_to_render", value: toRender },
        ];

        dp({ type: "batch", value: actions });
    },
        [configurations, type, onSelect, selectProps, selections, filledStyles, emptyStyles, lng, counts]);

    // console.log("useCategoryTypeSelection.tsx::250 => state", state);

    const activeConfigurations = React.useMemo(() => {
        const toRet: Array<{ configuration: CategoryConfigurationDownloadDto, categories: CategoryDownloadDto[] }> = [];
        _.forEach(selections, (categories, key) => {
            const configuration = _.find(configurations?.configurations, conf => conf.configuration.categoryType === key)?.configuration;
            if (configuration)
                toRet.push({ categories, configuration });
        })
        return toRet;
    }
        , [selections, configurations]);


    // console.log("useCategoryTypeSelection.tsx::354 => state", state);


    return {
        toRender,
        configurations,
        activeConfigurations,
        validation,
        selectedCategories,
    };

}

export default useCategoryTypeSelection;