import * as React from 'react';
import { QuizSC as T } from "./QuizSC";

export interface QuizFooterInfoProps {
    text: string;
    warning?: boolean;
    delay?: number;
    duration?: number;
}

export default class QuizFooterInfo extends React.PureComponent<QuizFooterInfoProps> {
    public render() {
        return (
            <T.FooterInfo
                warning={this.props.warning ?? false}
                delay={this.props.delay ?? 0}
                duration={this.props.duration ?? 5}
            >
                {this.props.text}
            </T.FooterInfo>
        );
    }
}

