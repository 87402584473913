import { belowBreakpointOrEqual, Icon, IconProps, styled, Theme } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { StyledComponent } from 'styled-components';
import { ICInputAlternative, MissingElementAlternative } from 'views/CustomSC';

interface MissingInputProps {
    title: string;
    missing: string[];
    container?: StyledComponent<"div", Theme, {}, never>;
    titleComponent?: JSX.Element;
    iconProps?: Partial<IconProps>;
    messagePadding?: string;
}


const InnerContainer = styled.div`
    display: grid;
    grid-template-columns: ${p => belowBreakpointOrEqual(p, "tablet") ? "1fr" : "200px 1fr"};
    grid-template-rows: min-content;
    margin-bottom: 10px;
`;
const ICTitle = styled.div`
    font-weight: bolder;
    line-height:40px;
    padding-left: 0px;
`;

const MissingInput = (p: MissingInputProps) => {
    const { missing, title, container, titleComponent, iconProps, messagePadding } = p;
    if (missing.length === 0)
        return <div />;
    const I = container ? container : InnerContainer;
    return (
        <I>
            {titleComponent ? titleComponent : <ICTitle><Icon name="exclamation triangle" style={{ float: "left", marginRight: 5 }} />{title}:</ICTitle>}
            <ICInputAlternative style={{ padding: messagePadding }}>
                {_.map(missing, (mes, i) => {
                    return (
                        <MissingElementAlternative key={i} >
                            <Icon name="info" style={{ float: "left", marginRight: 5, marginTop: 3 }} {...iconProps} />
                            {mes}
                        </MissingElementAlternative>
                    );
                })}
            </ICInputAlternative>
        </I>
    );
}
export default MissingInput;