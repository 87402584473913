import { fadeColor, getColor, styled } from 'imaginarity-react-ui';
import { keyframes } from 'styled-components';

// const HardPlacedDiv = styled.div.attrs<{ posX: number, posY: number, offsetX: number, offsetY: number }>(p =>
// ({
//     style: {
//         left: `${p.offsetX !== undefined ? `calc(${p.posX}% + ${p.offsetX + 0}px)` : `calc(${p.posX}% + 0px)`}`,
//         top: `${p.offsetY !== undefined ? `calc(${p.posY}% + ${p.offsetY + 0}px)` : `calc(${p.posY}% + 0px)`}`
//     }
// })) <{ posX: number, posY: number, offsetX: number, offsetY: number }>`
//     position: absolute;
// `;

export class WikiHotSpotSC {

    public static fadeIn = keyframes`
        from { opacity: 0; }
        to { opacity: 1; }
    `;

    public static SlideInLeft = keyframes`
        from {
            transform: translateX(-500px) scaleX(2.5) scaleY(0.2);
            transform-origin: 100% 50%;
            filter: blur(40px);
            opacity: 0;
        }
        to {
            transform: translateX(0) scaleY(1) scaleX(1);
            transform-origin: 50% 50%;
            filter: blur(0);
            opacity: 1;
        }
    `;

    public static SlideInRight = keyframes`
        from {
            transform: translateX(500px) scaleX(2.5) scaleY(0.2);
            transform-origin: 100% 50%;
            filter: blur(40px);
            opacity: 0;
        }
        to {
            transform: translateX(0) scaleY(1) scaleX(1);
            transform-origin: 50% 50%;
            filter: blur(0);
            opacity: 1;
        }
    `;
    public static Index = styled.div.attrs<{ size: number, posX: number, posY: number, infoSet: boolean, offsetX: number, offsetY: number }>(p =>
    ({
        style: {
            left: `${p.offsetX !== undefined ? `calc(${p.posX}% + ${p.offsetX + p.size}px)` : `calc(${p.posX}% + ${p.size}px)`}`,
            top: `${p.offsetY !== undefined ? `calc(${p.posY}% + ${p.offsetY + 0}px)` : `calc(${p.posY}% + 0px)`}`
        }
    })) <{ size: number, posX: number, posY: number, infoSet: boolean, offsetX: number, offsetY: number }>`
            position: absolute;
        font-weight: bolder;
        color:${p => p.infoSet ? getColor(p, p.theme.colors.accent) : getColor(p, p.theme.colors.mainForeground)};
        z-index: 5;
    `;

    public static HotSpot = styled.div.attrs<{ selected: boolean, aspect: number, delay: number, active: boolean, posX: number, posY: number, infoSet: boolean, offsetX: number, offsetY: number, moving?: boolean }>(p =>
    ({
        style: {
            left: `${p.offsetX !== undefined ? `calc(${p.posX}% + ${p.offsetX + 0}px)` : `calc(${p.posX}% + 0px)`}`,
            top: `${p.offsetY !== undefined ? `calc(${p.posY}% + ${p.offsetY + 0}px)` : `calc(${p.posY}% + 0px)`}`,
            transition: `${p.moving ? "unset" : "0.1s all ease-out"}`,
        }
    })) <{ selected: boolean, size: number, delay: number, active: boolean, posX: number, posY: number, infoSet: boolean, offsetX: number, offsetY: number, moving?: boolean }>`
        position: absolute;
        width: ${p => p.size}px;
        height: ${p => p.size}px;
        background: ${p => p.selected ? fadeColor(p, getColor(p, p.theme.colors.accent), 20) : fadeColor(p, getColor(p, p.theme.colors.mainBackground), 70)};
        border: 2px solid ${p => p.infoSet ? p.selected ? getColor(p, p.theme.colors.darkGrey) : getColor(p, p.theme.colors.accent) : getColor(p, p.theme.colors.darkGrey)};
        border-radius: ${p => p.infoSet ? "50%" : "3px"};
        opacity: ${p => p.infoSet ? 1.0 : 0.8};
        box-shadow: 0 4px 4px -4px black;
        transform-origin: center;
        padding: 1px;
        cursor: help;
        z-index: ${p => p.active ? 5 : 2};
        padding: 1px;
        box-shadow: 0 0 0 0 black;

        &:hover{
            border: 2px solid ${p => getColor(p, p.theme.colors.accent)};
            background:${p => fadeColor(p, getColor(p, p.theme.colors.mainBackground), 5)};
            ${p => p.moving ? "" : "transform: scale(1.8);"}
            padding: 0px;
            box-shadow: 0 4px 4px -4px black ;
        }
    `;

    public static InfoContainer = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${p => fadeColor(p, getColor(p, p.theme.colors.mainBackground), 50)};
        z-index:4;
        -webkit-animation: ${WikiHotSpotSC.fadeIn} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: ${WikiHotSpotSC.fadeIn} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    `;

    public static HotSpotDot = styled.div.attrs<{ posX: number, posY: number, offsetX: number, offsetY: number }>(p =>
    ({
        style: {
            left: `${p.offsetX !== undefined ? `calc(${p.posX}% + ${p.offsetX + 4}px)` : `calc(${p.posX}% + 4px)`}`,
            top: `${p.offsetY !== undefined ? `calc(${p.posY}% + ${p.offsetY + 4}px)` : `calc(${p.posY}% + 4px)`}`
        }
    })) <{ posX: number, posY: number, offsetX: number, offsetY: number }>`
            position: absolute;
        width:8px;
        height: 8px;
        background: ${p => getColor(p, p.theme.colors.accent)};
        border-radius: 4px;
        z-index:10000;
        pointer-events: none;
    `;

    public static SanitizeContainer = styled.div`
        h1, h2, h3, h4 {
            font-size: 1em;
            line-height: 1.2em;
            font-weight: bolder;
        } 
        .pag-link-button{
            color: ${p => getColor(p, p.theme.colors.mainBackground)};
            background: ${p => getColor(p, p.theme.colors.darkGrey)};
            padding: 0 10px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            margin: 0 0 10px 0 ;
            width: 100%;
            cursor: pointer;
            display: block;
            transition: all 0.2s ease;
            &:hover{
                color: ${p => getColor(p, p.theme.colors.mainBackground)};
                background: ${p => getColor(p, p.theme.colors.accent)};
            }
        }
    `;

    public static InfoBox = styled.div<{ posX: number, posY: number, maxHeight: number, offsetX: number, offsetY: number }>`
        position: absolute;
        padding: 10px;
        border: 2px solid ${p => getColor(p, p.theme.colors.accent)};
        ${p => p.posX < 50 ? `left: calc(${p.posX}% - ${p.offsetX + 7}px); right: unset;` : `left: unset; right: calc(100% - ${p.posX}% - 21px + ${p.offsetX}px);`}
        top: calc(${p => p.posY}% + ${p => p.offsetY + 25}px);
        background:${p => fadeColor(p, getColor(p, p.theme.colors.mainBackground), 95)};
        box-shadow: 0 6px 6px -6px black;
        z-index: 15;
        min-width: 100px;
        //max-width: ${p => p.posX > 50 ? "calc(" + p.posX + "% - 30px)" : "calc(100% - " + p.posX + "% - 30px)"};
        max-width: 50%;
        max-height: ${p => p.maxHeight}px;
        overflow-y: auto;
        -webkit-animation: ${WikiHotSpotSC.fadeIn} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: ${WikiHotSpotSC.fadeIn} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        backdrop-filter: blur(10px);
        p{
            margin: 0;
        }
    `;

    public static Arrow = styled.div.attrs<{ posX: number, posY: number, offsetX: number, offsetY: number }>(p =>
    ({
        style: {
            left: `${p.offsetX !== undefined ? `calc(${p.posX}% + ${p.offsetX + 7}px)` : `calc(${p.posX}% + 7px)`}`,
            top: `${p.offsetY !== undefined ? `calc(${p.posY}% + ${p.offsetY + 15}px)` : `calc(${p.posY}% + 15px)`}`
        }
    })) <{ posX: number, posY: number, offsetX: number, offsetY: number }>`
        position: absolute;
        display: block;
        background:${p => getColor(p, p.theme.colors.accent)};
        width: 2px;
        height: 18px;
        z-index:30;
    `;
};
