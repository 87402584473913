import { ControllerHelper, ControllerMethod, PostDownloadDto, UserCommentDownloadDto, UserCommentsDownloadDto, UserRatingsDownloadDto } from "collaboration-service";
import { formatDistanceToNow, formatRelative } from "date-fns";
import { Button, Comment, Icon, Loader, RatingSelect } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "services/ApplicationState/ApplicationState";
import { splitTextOnlyLastLink } from "services/Helpers";
import { mayDelete, mayUpdate } from "services/Helpers/LinkHelpers";
import { getMediaLink } from "services/Helpers/MediaHelpers";
import { getTranslatedUserName } from "services/Helpers/TranslationHelpers";
import { ImgI18N, useImgI18N } from "services/ImgI18N";
import { useBelowBreakpoint, useThemePart } from "services/useAppTheme";
import { GeneralSC as T } from "./GeneralSC";
import UrlComponent from "./UrlComponent";

export interface CommentsRatingsViewProps<T> {
    namespace?: string;
    asRating?: boolean;
    post: PostDownloadDto;
    noLeftBorder?: boolean;
    getIncoming: ControllerMethod<T, { id: string }>;
    addCommentRating?: ControllerMethod<T, { id: string, comment: { comment: string, rating: number } }>;
    onDeleteComment?: ControllerMethod<T, { id: string, commentToDelete: UserCommentDownloadDto }>;
    onSubmitComment?: ControllerMethod<T, { id: string, commentToPatch: UserCommentDownloadDto }>;
    autoFocus?: boolean;
    onCountChanged?: (count: number, rating?: number) => void;
    setFocusFunction?: (fct: () => void) => void;
    darkMode?: boolean;
    inline?: boolean;
    accent?: string;
    commentsOptional?: boolean;
    padding?: string;
    trackAdd?: () => void;
    noPosting?: boolean;
    filledOut?: boolean;
    filledColor?: string;
    inputOnly?: boolean;
    postsOnly?: boolean;
}

const CommentsRatingsView = <T extends UserRatingsDownloadDto | UserCommentsDownloadDto>(p: CommentsRatingsViewProps<T>) => {
    const { onCountChanged, post, getIncoming, onDeleteComment, onSubmitComment, addCommentRating, noPosting, filledOut, filledColor, inputOnly,
        commentsOptional, asRating, darkMode, accent, inline, noLeftBorder, namespace, setFocusFunction, padding, trackAdd, autoFocus, postsOnly } = p;

    const [incoming, setIncoming] = React.useState<T>();
    const [rating, setRating] = React.useState(0);
    const [comment, setComment] = React.useState("");
    const user = useSelector((s: ApplicationState) => s.user);
    const [loading, setLoading] = React.useState<boolean>(false);

    const { t } = useImgI18N(namespace ?? "quiz");

    const emptyAvatarImageUrl = useThemePart(t => t.emptyAvatarImageUrl);
    const sIncoming = React.useCallback((inc?: T, currentRating?: number) => {
        if (inc)
            inc.comments = _.orderBy(inc.comments, pp => new Date(pp.created), "desc");
        setIncoming(inc);
        //setComment(""); // that was #9655, sorry
        setRating(0);
        if (onCountChanged)
            onCountChanged(inc?.comments.length ?? 0, currentRating);
    }, [onCountChanged]);

    React.useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const inc = await ControllerHelper.singleCall({ id: post.id }, getIncoming);
            sIncoming(inc);
            setLoading(false);
        }
        getData();
    }, [post, getIncoming, sIncoming])

    const mayPost = mayUpdate(incoming);
    const isMobile = useBelowBreakpoint("tablet");
    const [dateRelative] = React.useState(true);
    const getDateFnsLng = ImgI18N.getInstance().languageLocal();
    const disabled = asRating ? (rating === 0 || (!commentsOptional && comment.length === 0)) : comment.length === 0;

    const onDel = React.useMemo(() => (commentToDelete: UserCommentDownloadDto) => async () => {
        if (onDeleteComment) {
            if (incoming) {
                const inc = _.clone(incoming);
                const toDel = _.findIndex(inc.comments, c => c.created === commentToDelete.created && c.user.id === commentToDelete.user.id);
                if (toDel >= 0) {
                    inc.comments.splice(toDel, 1);
                    sIncoming(inc);
                }
            }
            const res = await ControllerHelper.singleCall({ id: post.id, commentToDelete }, onDeleteComment, true);
            if (res)
                sIncoming(res);
        }
    }, [onDeleteComment, post, incoming, sIncoming]);

    const onSubmit = React.useMemo(() => (comment: UserCommentDownloadDto) => async (text: string) => {
        if (onSubmitComment && (comment || commentsOptional)) {
            if ((comment || commentsOptional) && (!asRating || rating > 0)) {
                if (incoming) {
                    const inc = _.clone(incoming);
                    const toChange = _.findIndex(inc.comments, c => c.created === comment.created && c.user.id === comment.user.id);
                    if (toChange >= 0) {
                        inc.comments[toChange].comment = text;
                        sIncoming(inc);
                    }
                }
                const res = await ControllerHelper.singleCall({ id: post.id, commentToPatch: { ...comment, comment: text } }, onSubmitComment, true);
                if (res)
                    sIncoming(res);
            }
        }
    }, [onSubmitComment, incoming, sIncoming, commentsOptional, post, asRating, rating]);

    const add = React.useMemo(() => async () => {
        if (addCommentRating) {
            if ((comment || commentsOptional) && (!asRating || rating > 0)) {
                if (incoming && user) {
                    const inc = _.clone(incoming);
                    inc.comments.push({ comment, rating, created: new Date(), user: user, links: [], mentionedUsers: [] });
                    sIncoming(inc, rating);
                }
                const res = await ControllerHelper.singleCall({ id: post.id, comment: { comment, rating } }, addCommentRating, true);
                if (res)
                    sIncoming(res);
            }
        }
        if (trackAdd)
            trackAdd();

        setComment("");
    }, [commentsOptional, post, addCommentRating, user, asRating, comment, incoming, sIncoming, rating, trackAdd]);

    const col = darkMode ? accent ? accent : "@mainForeground" : "@middleLightGrey";
    const isInline = inline === true;
    return (
        <T.CommentButtonContainer noLeftBorder={noLeftBorder ?? false} col={col} padding={padding ?? "1rem"} >
            <Loader active={loading} light size="small" infoText={t("loading comments")} />
            {postsOnly !== true &&
                <>
                    {asRating && mayPost && !noPosting &&
                        <div>
                            <T.RatingSelectContainer color={accent}>
                                <RatingSelect maxStars={5} vote={setRating} size={30} filledOut={true} filledColor={accent ?? "@accent"} />
                                {rating === 0 &&
                                    <T.ErrorMessage accent={accent}>
                                        {!isMobile &&
                                            t("rating is missing")
                                        }
                                        <T.ErrorMessageIcon accent={accent}>
                                            <Icon name="exclamation circle" size="24px" marginTop={0} />
                                        </T.ErrorMessageIcon>
                                    </T.ErrorMessage>
                                }
                            </T.RatingSelectContainer>
                            <T.CommentBox bgColor={col}>
                                <T.CommentBoxLeft>
                                    <T.CommentTextArea
                                        width="100%"
                                        value={comment}
                                        kind={darkMode ? "borderlessDark" : "borderless"}
                                        onChange={(ev) => setComment(ev?.target?.value)}
                                        setFocusFunction={setFocusFunction}
                                        darkMode={darkMode}
                                        color={accent}
                                        autoFocus={autoFocus}
                                    />
                                </T.CommentBoxLeft>
                                <T.CommentBoxRight bgColor={col}>
                                    <Button
                                        // kind={darkMode ? "transparentButtonDark" : "transparentButton"} 
                                        icon="times"
                                        size="default"
                                        onClick={() => setComment("")}

                                        kind={darkMode ? "transparentButtonDark" : "cancelButton"}
                                        disabled={comment === ""}
                                    />

                                    <Button
                                        kind={disabled ? darkMode ? "transparentButtonDark" : "primary" : accent ? "transparentButtonDark" : "primary"}
                                        disabled={disabled}
                                        icon={disabled ? "check" : "check"}
                                        size="default"
                                        onClick={disabled ? undefined : add}
                                    // iconColor={disabled ? "transparent" : (accent ?? undefined)}
                                    />
                                </T.CommentBoxRight>
                            </T.CommentBox>
                        </div>
                    }
                    {!asRating && mayPost && !noPosting &&
                        <T.CommentBox bgColor={col} style={{ height: inputOnly ? 100 : 82, borderWidth: inputOnly ? 0 : 1 }}>
                            <T.CommentBoxLeft style={{ width: inputOnly ? "calc(100% - 50px)" : "calc(100% - 40px)", padding: inputOnly ? 9 : 0 }}>
                                <T.CommentTextArea
                                    width="100%"
                                    value={comment}
                                    kind={darkMode ? "borderlessDark" : "borderless"}
                                    onChange={(ev) => setComment(ev?.target?.value ?? "")}
                                    setFocusFunction={setFocusFunction}
                                    darkMode={darkMode}
                                    autoFocus={true}
                                    placeHolder="your comment"
                                    inputOnly={inputOnly}
                                />
                            </T.CommentBoxLeft>
                            <T.CommentBoxRight bgColor={col} style={{ width: inputOnly ? 50 : 40, height: inputOnly ? 100 : 80, margin: inputOnly ? 0 : 0 }} scaled={inputOnly}>
                                <Button
                                    kind={darkMode ? "transparentButtonDark" : (isMobile ? "secondary" : "cancelButton")}
                                    disabled={comment === ""}
                                    icon={isMobile ? "delete" : "times"}
                                    size={inputOnly ? "large" : "default"}
                                    onClick={() => setComment("")}
                                />
                                <Button
                                    kind={disabled ? darkMode ? "transparentButtonDark" : "primary" : accent ? "transparentButtonDark" : "primary"}
                                    disabled={disabled}
                                    icon={disabled ? "check" : "check"}
                                    size={inputOnly ? "large" : "default"}
                                    onClick={disabled ? undefined : add}
                                />
                            </T.CommentBoxRight>
                        </T.CommentBox>
                    }
                </>
            }

            {incoming !== undefined && !inputOnly &&
                _.map(incoming?.comments, (comment, i) => {
                    const mayCDel = mayDelete(comment);
                    const mayCUpdate = mayUpdate(comment);
                    const postDate = dateRelative ? formatRelative(new Date(comment.created as Date), new Date(), { locale: getDateFnsLng }) : formatDistanceToNow(new Date(comment.created as Date), { addSuffix: true, locale: getDateFnsLng });
                    const rowColor = i % 2 === 1;
                    if (asRating) {
                        return <T.CommentElement rowColor={rowColor} key={i} noPosting={noPosting}>
                            <Comment
                                user={getTranslatedUserName(comment.user)}
                                date={postDate}
                                comment={comment.comment}
                                avatar={getMediaLink(comment.user, u => u.avatar) ?? emptyAvatarImageUrl}
                                scheme={darkMode ? "dark" : "light"}
                                maxStars={5}
                                rating={comment.rating}
                                inline={isInline}
                                accent={filledColor ?? accent}
                                filledColor={filledColor}
                                filledOut={filledOut}
                            />
                            {comment.comment.includes("https://") &&
                                <T.CommentBoxUrlComp isInline={isInline}>
                                    {splitTextOnlyLastLink(comment.comment, UrlComponent)}
                                </T.CommentBoxUrlComp>
                            }
                        </T.CommentElement>;
                    }
                    else {
                        return <T.CommentElement rowColor={rowColor} key={i} noPosting={noPosting}>
                            <Comment
                                user={getTranslatedUserName(comment.user)}
                                date={postDate}
                                comment={comment.comment}
                                avatar={getMediaLink(comment.user, u => u?.avatar) ?? emptyAvatarImageUrl}
                                scheme={darkMode ? "dark" : "light"}
                                onDelete={mayCDel && onDeleteComment ? onDel(comment) : undefined}
                                onSubmitEdit={mayCUpdate && onSubmitComment ? onSubmit(comment) : undefined}
                                inline={isInline}
                                accent={filledColor ?? accent}
                                filledColor={filledColor}
                                noEdit={noPosting}
                            />
                            {comment.comment.includes("https://") &&
                                <T.CommentBoxUrlComp isInline={isInline}>
                                    {splitTextOnlyLastLink(comment.comment, UrlComponent)}
                                </T.CommentBoxUrlComp>
                            }
                        </T.CommentElement>;
                    }
                })
            }
        </T.CommentButtonContainer >
    );
}
export default CommentsRatingsView;