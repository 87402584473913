import { ControllerHelper, PodcastPostDownloadDto, PostControllerTube } from 'collaboration-service';
import InfoBox from 'components/InfoBox/InfoBox';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Icon, LoaderInline } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import { gotoNamedRoute, tubeRoute } from 'services/Helpers/RoutingHelper';
import ScrollHelper from 'services/Helpers/ScrollHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { PodcastSC as T } from './PodcastSC';
import { podcastTheme } from './PodcastMain';

const mapper = (state: ApplicationState) => ({ podcastState: state.podcastState, contentLanguage: state.contentLanguage, });

export interface PodcastAudioNeighboursProps extends ImgI18NTranslatedComponentProps, ComponentWithMappedApplicationState<typeof mapper> {
    id: string;
}

export interface PodcastAudioNeighboursState {
    neighbours: PodcastPostDownloadDto[];
    token: string;
    loading: boolean;
}

class PodcastAudioNeighbours extends React.Component<PodcastAudioNeighboursProps, PodcastAudioNeighboursState> {
    private scrollHelper: ScrollHelper = new ScrollHelper();

    constructor(props: PodcastAudioNeighboursProps) {
        super(props);

        this.state = {
            neighbours: [],
            token: "",
            loading: true,
        }
    }

    async componentDidMount() {
        await this.load("");
    }

    async componentDidUpdate(prevProps: PodcastAudioNeighboursProps, prevState: PodcastAudioNeighboursState) {
        if (this.props.id !== prevProps.id) {
            this.setState({ token: "" });
            await this.load("");
        }
    }

    public render() {
        const { neighbours, loading } = this.state;
        const { contentLanguage } = this.props;

        return (
            <div ref={this.scrollHelper.setOnScrollOnParent(this.executeOnScroll)}>
                {loading &&
                    <LoaderInline
                        infoText="loading"
                        animationColor={podcastTheme.podcastAccent}
                        backgroundColor={"rgba(0,0,0,0.1)"}
                        textColor={podcastTheme.podcastAccent}
                        active
                    />}
                <>
                    {neighbours.length > 0 &&
                        _.map(neighbours, t => {
                            const viewed = _.find(this.props.podcastState.viewStats, vs => vs.postId === t.id)?.viewed ?? false;
                            const thumb = (t.media?.thumbnails ?? [])[0] ?? t.media;
                            const imgUrl = getMediaLink(thumb, t => t) ?? podcastTheme.noPreviewPodcastImage;

                            return (
                                <T.PodcastSidebarItem
                                    key={t.id}
                                    id="postWidth"
                                    imageUrl={imgUrl}
                                    onClick={this.openVideo(t)}
                                >
                                    <T.PodcastSidebarItemTitleContainer height={124}>
                                        <T.PodcastSidebarItemTitleContainerTeaser>
                                            <SafeHTML html={getTranslated(t.headlines, contentLanguage)?.text ?? ""} allowedAttributes={{}} allowedTags={[]} />
                                        </T.PodcastSidebarItemTitleContainerTeaser>
                                        <T.PodcastSidebarItemTitleContainerTeaserContent >
                                            <SafeHTML html={getTranslated(t.descriptions, contentLanguage)?.text ?? ""} allowedAttributes={{}} allowedTags={[]} />
                                        </T.PodcastSidebarItemTitleContainerTeaserContent>
                                    </T.PodcastSidebarItemTitleContainer>
                                    {viewed &&
                                        <T.PodcastPostViewedSidebar>
                                            <Icon name="check" size="16px" marginTop={3} />
                                        </T.PodcastPostViewedSidebar>
                                    }
                                </T.PodcastSidebarItem>
                            );
                        })}

                    {neighbours.length === 0 && !loading &&
                        <InfoBox
                            darkMode
                            content={this.props.t("no more podcasts available in this category")}
                            type="default"
                            icon="info"

                        />
                    }
                </>
            </div>
        );
    }
    private load = async (token: string) => {
        const nn = await ControllerHelper.singleCall({ id: this.props.id, token: { token } }, PostControllerTube.GetNeighborsPodcast);
        const neighbours = _.clone(this.state.neighbours);
        neighbours.push(...nn.elements);
        this.setState({ neighbours, token: nn.data, loading: false });
        await this.getViews(nn.elements);
    };
    private openVideo = (t: PodcastPostDownloadDto) => () => {
        gotoNamedRoute("podcast_audio_details", tubeRoute(t.id));
        this.setState({ loading: true, neighbours: [] });
    }
    private getViews = async (newPosts: PodcastPostDownloadDto[]) => {
        const ids = _.map(newPosts, p => p.id);
        const views = await ControllerHelper.singleCall({ postIds: ids }, PostControllerTube.GetPodcastPostsViews);
        this.props.dispatch(Actions.addPodcastViewStats(views));
    };
    private executeOnScroll = this.scrollHelper.executeOnScroll(40,
        async () => {
            await this.load(this.state.token);
        },
        () => this.state.token !== null && this.state.token !== undefined && this.state.token !== "");
}

export default translate("podcast")(connect(mapper)(PodcastAudioNeighbours));