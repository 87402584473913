import { CMSController, ControllerHelper, FileEntriesDownloadDto, FileEntryDownloadDto } from 'collaboration-service';
import MoreButton, { MoreButtonEntryProps, MoreMenuButton } from 'components/MoreButton/MoreButton';
import { Button, Icon, ImgIcons, Theme, fadeColor, getColor, lightenColor, styled } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { PluginActions } from 'services/ApplicationState/PluginStateHandling';
import { getLink, shallowCompare } from 'services/Helpers';
import { createFullNamedRoute, gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { useImgI18N } from 'services/ImgI18N';
import useCopyToClipboard from 'services/useCopyToClipboard';
import { ThemeContext } from 'styled-components';
import { cmsConfig } from './CMSConfig';
import { CMSFilesGridEventType } from './CMSFilesGrid';
import { CMSSC as T } from './CMSSC';


const mapper = (state: ApplicationState) => ({
    routeParams: state.params,
    currentProject: state.cmsState.currentProject,
    actionsDisabled: state.cmsState.directoryAndFileActionsDisabled,
    selectedFiles: state.cmsState.selectedFiles,
    selectedDirs: state.cmsState.selectedDirs,
});

interface CMSDirectoryMenuProps {
    current: FileEntriesDownloadDto;
    selected?: boolean;
    selectRow?: (set?: boolean) => void;
    onLinkCopied?: (event: CMSFilesGridEventType) => void;
}
const EC = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr;
    background: ${p => getColor(p, "@alertInfoError")};
    color: ${p => lightenColor(p, "@accent", 70)};
`;

const I = styled.div`
    text-align: center;
    background: ${p => fadeColor(p, "@accentBlack", 5)};
    padding-top: 8px;
    svg{
        stroke:${p => lightenColor(p, "@accent", 70)};
    }
`;
const E = styled.div`
    line-height: 20px;
    padding: 10px 10px 10px 10px;
`;

const CMSDirectoryMenu = (p: CMSDirectoryMenuProps) => {
    const { current, selectRow, selected, onLinkCopied } = p;
    const { routeParams, currentProject, actionsDisabled, selectedDirs, selectedFiles } = useSelector(mapper, shallowCompare);
    const dispatch = useAppDispatch();
    const id = routeParams?.id as string;
    const path = routeParams?.path as string[];
    const { t } = useImgI18N("cms");
    const [error, setError] = React.useState<string>();
    const [errorParent, setErrorParent] = React.useState<HTMLElement | null | undefined>();
    const fName = p.current.name
    const folderPath = path ? _.join([...path, fName], "/") : (fName ?? "");
    const [resetDeleteConfirmed, setResetDeleteConfirmed] = React.useState(false);
    const openFolder = gotoNamedRouteMethod("cms_dir", { id: id, path: folderPath });
    const theme = React.useContext<Theme>(ThemeContext);

    const url = createFullNamedRoute("cms_dir", { id: id, path: folderPath });
    const { copyLinkToClipboard, linkCopied } = useCopyToClipboard(url);

    React.useEffect(() => {
        if (onLinkCopied)
            onLinkCopied({ type: "directoryLinkCopied", copied: linkCopied, path: folderPath });
    }, [linkCopied, onLinkCopied, folderPath])

    const { fileSelected, multiSelected } = React.useMemo(() => {
        const fileSelected = selectedFiles.length > 0;
        return {
            multiSelected: selectedDirs.length + selectedFiles.length > 0 && !selected,
            fileSelected,
        }
    }, [selectedDirs, selectedFiles, selected])


    const onHide = React.useCallback(() => {
        setResetDeleteConfirmed(false);
        setError(undefined);
    }, []);

    const delFolder = React.useCallback(() => {
        const l = async () => {
            const toRemove = selected ? selectedDirs : [current, ...selectedDirs];

            const ps = _.map(toRemove, async rm => {
                const fName = rm.name
                const folderPath = path ? _.join([...path, fName], "/") : (fName ?? "");
                const res = await ControllerHelper.singleCall({ projectGroupId: id, path: folderPath }, CMSController.DeleteFolder, true);

                const result = res !== undefined;
                const action = result ? 'deletion succeeded' : 'deletion failed';
                dispatch(PluginActions.batchActions([
                    Actions.setCMSActionResult({ action, result }),
                    Actions.setActive(undefined),
                ]));

                if (res)
                    return true;
                return false;
            })
            const ok = await Promise.all(ps);
            if (_.find(ok, o => !o))
                setError(t("folder not empty, cannot delete it."));
            else
                onHide();
        }

        dispatch(Actions.setActive(true));
        l();
    }, [id, t, onHide, current, path, selected, selectedDirs, dispatch]);
    const renameFolder = React.useCallback(() => {
        dispatch(PluginActions.batchActions([
            Actions.setCMSFolderToRename(fName),
            Actions.setCMSDirectoryAndFileMenuDisabled(true)]));
    }, [fName, dispatch]);
    const openFolderIcon = "folder open";
    const openFolderContent = t("open folder");

    const select = React.useCallback(() => {
        if (selectRow)
            selectRow();
    }, [selectRow]);

    const entries = React.useMemo(() => {


        const mayRenameDir = getLink(currentProject, "renameDirectory");
        const mayDeleteDir = getLink(currentProject, "deleteDirectory");

        const toRet: MoreButtonEntryProps<FileEntryDownloadDto>[] = [];
        if (!fileSelected) {
            toRet.push({ onClick: select, icon: selected ? "square" : "check square", content: selected ? t("deselect entry") : t("select entry"), hideOnClick: true })
            if (selectedDirs.length <= 1) {
                toRet.push(
                    {
                        onClick: openFolder,
                        icon: openFolderIcon,
                        content: openFolderContent,
                        hideOnClick: true,
                    }
                );

                if (mayRenameDir)
                    toRet.push({
                        onClick: renameFolder,
                        icon: "pencil",
                        content: t("rename directory"),
                        hideOnClick: true,
                    })
            }


            if (mayDeleteDir)
                toRet.push({
                    onClick: () => setResetDeleteConfirmed(true),
                    icon: "folder minus",
                    content: t("delete directory"),
                    hideOnClick: false,
                    renderSubComponent: (ele, hide) => (
                        <>
                            <MoreMenuButton showSubComponent icon="times" onClick={hide} content={t("cancel deletion")} ele={ele} key={"cancel"} />
                            {(errorParent && error !== undefined) ?
                                <EC>
                                    <I><Icon name="exclamation triangle" /></I>
                                    <E>
                                        {error}
                                    </E>
                                </EC>
                                :
                                <MoreMenuButton showSubComponent icon="check" onClick={() => { delFolder(); }} content={t("yes, delete directory")} ele={ele} key={"yes"} />
                            }
                        </>
                    ),
                    showSubComponent: resetDeleteConfirmed
                })
        }
        if (url && selectedDirs.length <= 1 && selectedFiles.length === 0) {
            toRet.push({
                onClick: copyLinkToClipboard,
                icon: "link",
                content: "create link to directory",
                hideOnClick: true,
            })
        }
        return toRet;
    }, [openFolder, openFolderIcon, openFolderContent, currentProject, t, delFolder, copyLinkToClipboard,
        renameFolder, resetDeleteConfirmed, error, errorParent, fileSelected, select, selected, selectedFiles.length,
        selectedDirs, url]);

    const calcRef = React.useCallback((ref: HTMLDivElement | null) => {
        let cur: HTMLElement | null = ref;
        while (cur !== undefined && cur !== null && !cur.classList.contains("contentScroll"))
            cur = cur.parentElement
        setErrorParent(cur);
    }, []);


    return (<T.DmContainer ref={calcRef}>
        <>
            {entries.length > 0 && !actionsDisabled ?
                <>
                    {multiSelected ?
                        // <NoMoreButton>
                        //     <Button icon="checkbox unchecked" iconColor={cmsConfig.multiSelectMoreButtonIconColor} size="small" kind={"fullTransparentButton"} tooltip={{ tooltipText: t("select to apply common for operations") }} onClick={select} />
                        // </NoMoreButton>
                        <div />
                        :
                        <>
                            {entries.length > 1 || entries[0].icon !== "folder open" ? <MoreButton
                                ele={current as FileEntryDownloadDto}
                                entries={entries}
                                hideMoreButton
                                floated='right'
                                onHide={onHide}
                                disabled={actionsDisabled}
                            />
                                :
                                <Button
                                    floated='right'
                                    icon={openFolderIcon}
                                    onClick={openFolder}
                                    kind="transparentButton"
                                    tooltip={{ tooltipText: openFolderContent }}
                                    disabled={actionsDisabled}
                                />
                            }
                        </>
                    }
                </>
                :
                <Button cursor='default' icon={cmsConfig.disabledMoreButtonIcon as ImgIcons} iconColor={fadeColor({ theme }, cmsConfig.disabledMoreButtonIconColor, cmsConfig.disabledMoreButtonIconColorAlpha)} kind={"fullTransparentButton"} disabled tooltip={{ tooltipText: t("current selection avoids any operation for this entry"), position: "left" }} />
            }
        </>
        {/* {errorParent &&
            <Alert parent={errorParent} kind={"error"} active={error !== undefined} onClick={() => setError(undefined)}>
                <EC>
                    <Icon name="exclamation triangle" size="30px" color="color" />
                    <E>
                        {error}
                    </E>
                </EC>
            </Alert>
        } */}

    </T.DmContainer>
    );
}
export default CMSDirectoryMenu;