import { ThemeColor } from 'imaginarity-react-ui';
interface TubeTile extends ThemeColor {

}

export interface PostSettings {
    backgroundColor: string
    color: string;
}
export interface FilteredImage {
    opacity?: number;
    filter?: string;
    url: string;
}
export interface TubeTheme extends ThemeColor {
    tile: TubeTile;
    headerRightImage: FilteredImage;
    postItem: PostSettings;
    showTubeTableView: boolean;
    showUploadForm: boolean;
    homeHeaderTitle: string;
    homeImage: string;
    vimeoLogoLink: string;
    youtubeLogoLink: string;
    noCategoryImage: string;
    tubeAccent: string;
    darkMode: boolean;
    showTooltips: boolean;
    showInfo: boolean;
    loadCount: boolean,
    showNewTileAppearance: boolean;
}
export interface TubeSettings {

}

export const tubeTheme: TubeTheme = {
    loadCount: true,
    showNewTileAppearance: true, //new display for video tiles, desktop and mobile view
    backgroundColor: "lighten('@darkerGrey', 77%)", //backgroundcolor
    color: "@veryLightGrey", //fontColor
    darkMode: true,  //true if dark backgroundcolor
    tile: {
        backgroundColor: "fade('@accentBlack', 70%)", //categorytile backgroundcolor
        color: "@veryLightGrey", //categorytile fontColor
    },
    headerRightImage: { //banner right image
        opacity: 0.7,
        filter: "grayscale(0.5)",
        url: "https://cdn.imaginarity.com/public/projectImages/imgTubeHeaderRight.png"
    },
    postItem: {
        backgroundColor: "fade('@accentBlack', 30%)", //video pane backgroundcolor
        color: "@veryLightGrey", //video pane fontColor
    },
    showTubeTableView: true, //true if you want activate tableview
    showUploadForm: false, //true if you want activate uploadform on sidebar (currently not implemented)
    homeHeaderTitle: "imaginarity tube", //tube banner title
    homeImage: "https://cdn.imaginarity.com/public/projectImages/imgTubeHeaderImage.svg", //tube banner left image (squared)
    vimeoLogoLink: "https://cdn.imaginarity.com/public/tube/logovimeo.svg", //vimeo logo icon
    youtubeLogoLink: "https://cdn.imaginarity.com/public/tube/logoyoutube.svg", //youtube logo icon
    tubeAccent: "#1E70BF", // accent color
    noCategoryImage: "https://cdn.imaginarity.com/public/projectImages/imgNoImageAvailable.svg", //alternative image if no image is set
    showTooltips: true,
    showInfo: true,
}
