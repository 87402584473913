import * as React from "react";
//import { useSelector } from "react-redux";
import useCategoryTypeSelection from "./useCategoryTypeSelection";
//import { shallowCompare } from "services/Helpers";
//import { useImgI18N } from "services/ImgI18N";
import MissingInput from "components/MissingInput";
import { Button, Checkbox, styled, useThemePart } from "imaginarity-react-ui";
import * as _ from "lodash";
import { getTranslated } from "services/Helpers/TranslationHelpers";
import { useImgI18N } from "services/ImgI18N";

interface TestUCTSProps {

}

const G = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
`;

const H = styled.div``;

const TestUCTS = (p: TestUCTSProps) => {
    //const {} = p;
    const { t } = useImgI18N("cms");
    //const {} = useSelector(mapper, shallowCompare);
    const [error, setError] = React.useState<{ msg: string, data: any }>();
    const [cats, setCats] = React.useState<string[]>(['307f5db7-b699-4d7d-bebe-0336974e9efd', '7a2be3fa-fd29-4452-b772-58805d4a64d3', 'bcd6d55e-0176-4c40-b46c-7c1c6db38251', '87bac7bf-972b-4fb3-bc3b-3003245848b0', '2504f9f2-46ef-4b36-ad47-ac06a6f2c063']);
    const [type, setType] = React.useState<"normal" | "filter">("normal");
    //    console.log("TestUCTS.tsx::31 => cats", cats);
    const themeColor = useThemePart(t => t.colors);

    const counts = React.useMemo(() => [
        {
            data:
                "60779e0d-45ed-4cc8-8fea-4ef5ff5c59e4",
            count: 17
        },
        {
            data: '2195b401-f605-4368-860f-ca10de7dc2b8',
            count: 99
        },
        {
            data: '3a8a2f3d-d12e-4c41-9386-386abcff4ed0',
            count: 188
        },
        {
            data: '6407c83a-13a2-4c14-88b8-31f41cb1029c',
            count: 33
        }
    ], []);

    const { toRender, validation, selectedCategories } =
        useCategoryTypeSelection({
            grouptype: "CMS",
            type,
            counts,
            // only if you want to exactly see every single change and if it was ok
            //onCategorySelected: catSel,
            onError: setError,
            selectedCategories: cats,
            //filter
        });

    const setCatsTest1 = () => {
        console.log("set some test cats to see what happens if value changes ;)")
        setCats(['307f5db7-b699-4d7d-bebe-0336974e9efd', '7a2be3fa-fd29-4452-b772-58805d4a64d3', 'bcd6d55e-0176-4c40-b46c-7c1c6db38251']);
    }
    const setCatsTest2 = () => {
        console.log("set some test cats to see what happens if value changes ;)")
        setCats([]);
    }
    const missing: string[] = [];
    _.forEach(validation, v => {
        if (v)
            missing.push(t(v.msg, v.data));
    });

    React.useEffect(() => {
        setCats(_.map(selectedCategories, s => s.id));
    }, [selectedCategories]);

    console.log("TestUCTS.tsx::71 => selectedCategories", cats);


    return (
        <div>
            <Checkbox
                label={type}
                onClick={() => setType(s => s === "normal" ? "filter" : "normal")}
                selected={type === "filter"}
            />
            <G>
                {_.map(toRender, r => (
                    <React.Fragment key={r.configuration.categoryType}>
                        <H>{getTranslated(r.configuration.names)?.text ?? ""}</H>
                        {r.Select}
                    </React.Fragment>
                ))}
            </G>
            <div style={{ color: themeColor.accent }}>
                {error && t(error.msg, error.data)}
            </div>
            <MissingInput
                title="missing input"
                missing={missing}
            />
            <div style={{ marginBottom: 5 }}>
                <Button icon="edit" content="little value test (3 cats)" onClick={setCatsTest1} />
            </div>
            <div style={{ marginBottom: 5 }}>
                <Button icon="edit" content="little value test (0 cats)" onClick={setCatsTest2} />
            </div>
            <div>{_.join(_.map(selectedCategories, s => getTranslated(s.names)?.text ?? ""), ", ")}</div>
        </div>
    )
}

export default TestUCTS;