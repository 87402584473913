import { ControllerHelper, HistoryController, HistoryEntryDownloadDto, TranslationController, TranslationStateDownloadDto } from 'collaboration-service';
import { formatRelative } from 'date-fns/esm';
import { DiffElement, LinkDto } from "imaginarity-azure";
import { Button, ButtonGroup, Icon, Loader, Theme } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { CellProps, Column, Row, useRowSelect, useTable } from 'react-table';
import { ThisAppEntry } from 'services/AppEntryDefinitions';
import { useAppSelector } from 'services/ApplicationState/ApplicationState';
import { getTranslatedUserName } from 'services/Helpers/TranslationHelpers';
import { ThemeContext } from 'styled-components';
import { HistorySC as H } from './HistorySC';

export interface IHistoryViewProps {
}

export const HistoryViewAppEntry: ThisAppEntry = {
    contentRenderer: () => <HistoryViewExport />,
    icon: "clock",
    name: "history_view",
    routeName: "wiki_history_view",
    title: "history::historyViewTitle",
    route: "history/:dataId",
    hidden: true,
    left: {
        hidden: false,
    },
    right: {
        hidden: false,
    }
}

function HistoryView(props: IHistoryViewProps) {
    const params = useAppSelector(s => s.params);
    const dataId = params ? params["dataId"] as string : undefined;

    const [entries, setEntries] = React.useState<HistoryEntryDownloadDto[]>([]);
    const [translationState, setTranslationState] = React.useState<TranslationStateDownloadDto>();
    const [diff, setDiff] = React.useState<DiffElement[]>();
    const [downloadLink, setDownloadLink] = React.useState<LinkDto>();
    const [loading, setLoading] = React.useState(false);

    const startUp = React.useMemo(() => async () => {
        if (dataId) {
            const translationState = await ControllerHelper.singleCall({ id: dataId }, TranslationController.GetPostTranslationState, true);
            setTranslationState(translationState);
            const entries = await ControllerHelper.singleCall({ id: dataId }, HistoryController.GetHistoryEntries);
            setEntries(entries);
        }
    }, [setEntries, dataId]);

    React.useEffect(() => {
        startUp();
    }, [dataId, startUp]);

    const setToHistoryEntry = React.useMemo(() => (entryId: string) => async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        if (dataId) {
            try {
                await ControllerHelper.singleCall({ id: dataId, entryId }, HistoryController.SetCurrentHistoryEntry);
                const entriesCopy = _.clone(entries);
                const oldIndex = _.findIndex(entriesCopy, e => e.isCurrentEntry);
                entriesCopy[oldIndex] = {
                    ...entriesCopy[oldIndex],
                    isCurrentEntry: false
                }
                const index = _.findIndex(entriesCopy, e => e.id === entryId);
                entriesCopy[index] = {
                    ...entriesCopy[index],
                    isCurrentEntry: true
                }
                setEntries(entriesCopy);
            } catch (e) {

            }
        }
    }, [dataId, entries]);

    const prepareDownload = async () => {
        if (dataId) {
            setLoading(true);
            var link = await ControllerHelper.singleCall({ id: dataId, baseLanguage: "de-DE" }, TranslationController.DownloadPostTranslationXliff);
            setDownloadLink(link);
            const translationState = await ControllerHelper.singleCall({ id: dataId }, TranslationController.GetPostTranslationState, true);
            setTranslationState(translationState);
            setEntries(entries);
            setLoading(false);
        }
    }

    const getTranslationState = React.useCallback((id: string) => {
        console.log(translationState);
        console.log(id);
        return translationState ? (translationState.baseHistoryEntryId === id ? "Translation base" : (translationState.inTranslationHistoryEntryId === id ? "In Translation" : "")) : "";
    }, [translationState]);

    const columns = React.useMemo(() => [
        {
            id: "0",
            Header: "Current",
            accessor: "isCurrentEntry",
            Cell: (x: CellProps<HistoryEntryDownloadDto, boolean>) => x.value ? <Icon name="check" /> : <Icon name="empty" />
        },
        {
            id: "1",
            Header: "Set",
            accessor: (v) => v,
            Cell: (x: CellProps<HistoryEntryDownloadDto, HistoryEntryDownloadDto>) => <Button disabled={x.value.isCurrentEntry} kind="secondary" icon="check circle" onClick={setToHistoryEntry(x.value.id)} />
        },
        {
            id: "2",
            Header: "Version",
            accessor: "version"
        },
        {
            id: "3",
            Header: "Time",
            accessor: (v) => formatRelative(new Date(v.timeStamp), new Date())
        },
        {
            id: "4",
            Header: "Message",
            accessor: "message"
        },
        {
            id: "5",
            Header: "User",
            accessor: (v) => getTranslatedUserName(v.changedBy)
        },
        {
            id: "6",
            Header: "Translation",
            accessor: (v) => v,
            Cell: (x: CellProps<HistoryEntryDownloadDto, HistoryEntryDownloadDto>) => getTranslationState(x.value.id)

        }

    ] as Column<HistoryEntryDownloadDto>[], [getTranslationState, setToHistoryEntry]);

    const tableInstance = useTable({ columns, data: entries }, useRowSelect);

    const theme = React.useContext(ThemeContext) as Theme;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        toggleAllRowsSelected,
    } = tableInstance;

    const toggleRowSelected = (row: Row<HistoryEntryDownloadDto>) => async () => {
        if (!row.isSelected !== undefined) {
            toggleAllRowsSelected(false);
            row.toggleRowSelected();
            var diff = await ControllerHelper.singleCall({ entryId: row.original.id }, HistoryController.GetDiffToPreviousEntry);
            setDiff(diff);
        }
    };

    return (
        <H.HistoryViewContainer>
            <Loader active={loading} light />
            <H.Grid>
                <H.List>
                    <H.ListTop>
                        <table {...getTableProps() as any}>
                            <thead>
                                {
                                    headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps() as any}>
                                            {
                                                headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps() as any}>
                                                        {column.render('Header')}
                                                    </th>
                                                ))}
                                        </tr>
                                    ))}
                            </thead>
                            <tbody {...getTableBodyProps() as any}>
                                {
                                    rows.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps() as any} onClick={toggleRowSelected(row)} style={{ background: row.isSelected ? theme.colors.accent : theme.colors.mainBackground }} on>
                                                {
                                                    row.cells.map(cell => {
                                                        return (
                                                            <td {...cell.getCellProps() as any}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </H.ListTop>
                    <H.ListBottom>
                        <p>Translation State: {translationState ? translationState.state : "Not translated"}</p>
                        <ButtonGroup>
                            <Button kind="primary" content={translationState?.state === 'InTranslation' ? "Download again" : "Prepare download"} onClick={prepareDownload} disabled={downloadLink !== undefined} />
                            {downloadLink && <a href={downloadLink.uri} ><Button kind="primary" content="Download" /></a>}
                        </ButtonGroup>
                    </H.ListBottom>
                </H.List>
                <H.Details>
                    {diff && _.map(diff, s =>
                        <div>
                            <p>id: {s.id}<br />
                                name: {s.name}<br />
                                type: {s.changeType}<br />
                            </p>
                            {_.map(s.children, c =>
                                <div>
                                    <p>id: {c.id}<br />
                                        name: {c.name}<br />
                                        type: {c.changeType}<br />
                                    </p>
                                    {_.map(s.children, cc =>
                                        <div>
                                            <p>id: {cc.id}<br />
                                                name: {cc.name}<br />
                                                type: {cc.changeType}<br />
                                            </p>

                                        </div>
                                    )}
                                </div>
                            )}
                        </div>)}
                </H.Details>
            </H.Grid>
        </H.HistoryViewContainer>
    );
}
const HistoryViewExport = HistoryView;