import { Loader } from 'imaginarity-react-ui';
import * as React from 'react';
import { checkForCredentials } from 'services/Helpers/ApplicationStateHelpers';

export interface IGoToExternalProps {
}

export function GoToExternal(props: IGoToExternalProps) {
    const init = async () => {
        const tokenRes = await checkForCredentials();
        var token = tokenRes.token;
        const url = `https://login.imaginarity.com/token/${token}?appId=b4555ed1-7dff-4c97-aab7-4fbc1d53eab6&route=/itrainer`
        window.location.href = url;
    }
    React.useEffect(() => {
        init();
    }, []);
    return (
        <Loader active light />
    );
}
