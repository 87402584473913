import { WikiMediaOverlayElementDownloadDto, WikiMediaOverlayElementsDownloadDto } from 'collaboration-service';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { getUriFromLinkByName, Image } from 'imaginarity-react-ui';
import * as React from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { getTranslatedExact } from 'services/Helpers/TranslationHelpers';
import { ImgI18N } from 'services/ImgI18N';
import { WikiHotSpotSC as T } from "./WikiHotSpotSC";

export interface WikiHotSpotProps {
    element: WikiMediaOverlayElementDownloadDto;
    mediaOverlay?: WikiMediaOverlayElementsDownloadDto[];
    delay: number;
    imgHeight: number;
    imgWidth: number;
    index?: number;
    offsetX?: number;
    offsetY?: number;
    width: number;
    selected?: boolean;
    editMode?: boolean;
    editHotspot?: (show: boolean) => void;
    onMoved?: (x: number, y: number) => void;
}

export interface WikiHotSpotState {
    showInfoBox: boolean;
}

interface WikiInnerHotSpotProps {
    element: WikiMediaOverlayElementDownloadDto;
    mediaOverlay?: WikiMediaOverlayElementsDownloadDto[];
    size: number;
    offsetX?: number;
    offsetY?: number;
    maxHeight: number;
    showInfoBox: boolean;
    toggleInfoBox: () => void;
    delay: number;
    selected?: boolean;
    index?: number;
    editMode?: boolean;
    onMoved?: (x: number, y: number) => void;
}

const WikiInnerHotSpot = React.memo((p: WikiInnerHotSpotProps) => {
    const e = p.element;
    const ox = p.offsetX ?? 0;
    const oy = p.offsetY ?? 0;
    const maxHeight = p.maxHeight;
    const onDrag = (e: DraggableEvent, data: DraggableData) => {
    }
    const onStop = (e: DraggableEvent, data: DraggableData) => {
        if (data.x === 0 && data.y === 0)
            p.toggleInfoBox();
        else {
            if (p.onMoved)
                p.onMoved(data.x, data.y);
        }
    }
    const body = document.body;
    let m = getUriFromLinkByName(p.element.media);
    if (m === undefined && p.index !== undefined) { // not found in this language
        const baseLng = ImgI18N.getInstance().getConfig().baseLanguage;
        const overlayData = getTranslatedExact(p.mediaOverlay, baseLng);    // first try fallback to base language
        if (overlayData && overlayData.elements[p.index]) {
            m = getUriFromLinkByName(overlayData.elements[p.index].media);
        }

        if (m === undefined && p.mediaOverlay && p.mediaOverlay.length > 0) // second try fallback to first element
            if (p.mediaOverlay[0].elements.length > p.index)
                m = getUriFromLinkByName(p.mediaOverlay[0].elements[p.index].media);

    }

    return (
        <>
            {p.editMode && !p.selected ?
                <Draggable
                    onStart={onDrag}
                    onDrag={onDrag}
                    onStop={onStop}
                    position={{ x: 0, y: 0 }}
                    offsetParent={body}
                >
                    <T.HotSpot
                        size={p.size}
                        posX={e.positionX}
                        posY={e.positionY}
                        active={p.showInfoBox}
                        delay={p.delay + 0.5}
                        infoSet={e.content.length !== 0}
                        offsetX={ox}
                        offsetY={oy}
                        selected={p.selected ?? false}
                        moving={true}
                    >
                        {p.editMode && <T.Index
                            posX={e.positionX}
                            posY={e.positionY + 1}
                            infoSet={e.content.length !== 0}
                            offsetX={ox}
                            offsetY={oy}
                            size={p.size}
                        >
                            {p.index ?? ""}
                        </T.Index>}
                    </T.HotSpot>
                </Draggable>
                :
                <T.HotSpot
                    size={p.size}
                    posX={e.positionX}
                    posY={e.positionY}
                    onClick={p.toggleInfoBox}
                    active={p.showInfoBox}
                    delay={p.delay + 0.5}
                    infoSet={e.content.length !== 0}
                    offsetX={ox}
                    offsetY={oy}
                    selected={p.selected ?? false}
                >
                    {p.editMode && <T.Index
                        posX={e.positionX}
                        posY={e.positionY + 1}
                        infoSet={e.content.length !== 0}
                        offsetX={ox}
                        offsetY={oy}
                        size={p.size}
                    >
                        {p.index ?? ""}
                    </T.Index>}
                </T.HotSpot>
            }
            {p.showInfoBox &&
                <>
                    <T.InfoContainer
                        onClick={p.toggleInfoBox}
                    />
                    <T.HotSpotDot
                        posX={e.positionX}
                        posY={e.positionY}
                        onClick={p.toggleInfoBox}
                        offsetX={ox}
                        offsetY={oy}
                    />
                    <T.InfoBox
                        maxHeight={maxHeight}
                        posX={e.positionX}
                        posY={e.positionY}
                        onClick={p.toggleInfoBox}
                        offsetX={ox}
                        offsetY={oy}
                    >
                        {m && <Image src={m} />}
                        <T.SanitizeContainer>
                            <SafeHTML html={e.content} />
                            {/* {this.props.editMode &&
                                <>
                                    <Divider color="#ccc" />
                                    <Button kind="cancelButton" icon="edit" content="edit" floated="left" onClick={this.props.editHotspot} />
                                </>
                            } */}
                        </T.SanitizeContainer>
                    </T.InfoBox>
                    <T.Arrow
                        posX={e.positionX}
                        posY={e.positionY}
                        offsetX={ox}
                        offsetY={oy}
                    />
                </>
            }
        </>
    );
});

const WikiHotSpot = (p: WikiHotSpotProps) => {
    const [showInfoBox, setShowInfoBox] = React.useState(false);
    //const maxHeight = ((100 - p.element.positionY) / 100) * p.imgHeight + 20;
    const maxHeight = ((100 - p.element.positionY) / 100) * (p.imgHeight / 2) + 100;

    const size = 16;
    const { editHotspot } = p;
    const toggleInfoBox = React.useMemo(() => () => {
        setShowInfoBox(!showInfoBox);
        if (editHotspot)
            editHotspot(!showInfoBox);
    }, [showInfoBox, editHotspot]);

    return (
        <WikiInnerHotSpot
            size={size}
            element={p.element}
            mediaOverlay={p.mediaOverlay}
            maxHeight={maxHeight}
            delay={p.delay}
            showInfoBox={showInfoBox}
            toggleInfoBox={toggleInfoBox}
            index={p.index}
            selected={p.selected}
            offsetX={p.offsetX}
            offsetY={p.offsetY}
            editMode={p.editMode}
            onMoved={p.onMoved}
        />
    )
}
export default React.memo(WikiHotSpot);

