import CloseArea from "components/CloseArea";
import { LoadingDataTableSpecialSecondaryButtonProps } from "components/LoadingDataTable/LoadingDataTable";
import { LoadingDataTableSC as T } from 'components/LoadingDataTable/LoadingDataTableSC';
import { Button, ButtonProps, getColor, Icon, styled } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from 'react';
import { hasLink } from "services/Helpers/LinkHelpers";
import { useThemePart } from "services/useAppTheme";
import usePortal, { PortalEdges } from "services/usePortal";

const MoreButtonsContainer = styled.div`
    position: relative;
    z-index: 2147483636;
    width: 100%;
    //border: 1px solid ${p => getColor(p, "@middleLightGrey")};
`;


// const mCalcPos = (r: DOMRect) => ({
//     left: r.left + 40,
//     top: r.top
// });


export type MoreButtonEntryProps<ElementType> = LoadingDataTableSpecialSecondaryButtonProps<ElementType> & {
    renderIcon?: (ele: ElementType, disabled?: boolean) => JSX.Element,
    renderSubComponent?: (ele: ElementType, hide: () => void) => JSX.Element,
    showSubComponent?: boolean,
    hideOnClick?: boolean;
    danger?: boolean;
};

type MoreButtonProps<ElementType> = Omit<ButtonProps, "onClick" | "setInstance"> & { entries: MoreButtonEntryProps<ElementType>[] }
    & {
        ele: ElementType,
        calcPos?: (rect: DOMRect) => PortalEdges,
        showFooter?: boolean,
        renderFooter?: (ele: ElementType) => JSX.Element,
        containerStyle?: React.CSSProperties,
        hideMoreButton?: boolean,
        stayOpenOnLeave?: boolean,
        onHide?: () => void;
    };

export const MoreMenuButton = <ElementType extends any>(p: MoreButtonEntryProps<ElementType> & { ele: ElementType }) => {
    const { danger, ele, availableForLink, disabled, onClick, iconColor, showSubComponent, renderSubComponent, ...rest } = p;
    const available = availableForLink !== undefined ? hasLink(ele as any, availableForLink) : true;
    const calcDisabled = disabled || !available;

    const c = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(ele);
    }

    return (
        <T.SecondaryButtonContainer disabled={calcDisabled} showSubComponent={showSubComponent} danger={danger}>
            {p.renderIcon ?
                p.renderIcon(ele, calcDisabled)
                :
                <T.SecondaryButtonIconContainer onClick={calcDisabled ? undefined : c}>
                    <Icon style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        margin: 0
                    }}
                        name={p.icon ?? "empty"}
                        color={calcDisabled ? "@middleLightGrey" : "color"} />
                </T.SecondaryButtonIconContainer>
            }
            <Button
                kind="tertiary_menu"
                fluid
                floated="left"
                onClick={c}
                {...rest}
                disabled={calcDisabled}
                iconColor={available ? iconColor : undefined}
                icon={undefined}
            />
        </T.SecondaryButtonContainer>
    );
}

export const MoreMenuSortButton = <ElementType extends any>(p: MoreButtonEntryProps<ElementType> & { ele: ElementType }) => {
    const { ele, availableForLink, disabled, onClick, iconColor, showSubComponent, renderSubComponent, ...rest } = p;
    const available = availableForLink !== undefined ? hasLink(ele as any, availableForLink) : true;
    const calcDisabled = disabled || !available;

    const c = () => {
        onClick(ele);
    }


    return (
        <T.SecondaryButtonSortContainer disabled={calcDisabled} showSubComponent={showSubComponent}>
            {p.renderIcon ?
                p.renderIcon(ele, calcDisabled)
                :
                <T.SecondaryButtonIconContainer onClick={calcDisabled ? undefined : c}>
                    <Icon style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        margin: 0
                    }}
                        name={true ? "checkbox checked" : "checkbox checked"}
                    />
                </T.SecondaryButtonIconContainer>}
            <Button
                kind="tertiary_menu"
                fluid
                floated="left"
                onClick={c}
                {...rest}
                disabled={calcDisabled}
                iconColor={available ? iconColor : undefined}
                icon={undefined}
            />
            <div><Button kind="tertiary_menu" icon="chevron up" /></div>
            <div><Button kind="tertiary_menu" icon="chevron down" /></div>
        </T.SecondaryButtonSortContainer>
    );
}

const MoreButton = <ElementType extends object = any>(p: MoreButtonProps<ElementType>) => {
    const { entries, ele, calcPos, showFooter, renderFooter, containerStyle, hideMoreButton, stayOpenOnLeave, onHide, ...buttonProps } = p;
    const [open, setOpen] = React.useState<boolean>(false);
    const screenHeight = useThemePart(x => x.content.height + 60);
    const screenWidth = useThemePart(x => x.content.width);
    const rect = useThemePart(x => x.content.rect);
    const containerWidth = +(containerStyle?.width ?? 250);
    const mCalcPos = React.useMemo(() => (r: DOMRect) => {
        const toUpper = rect ? r.y > (rect.top + rect.height * 0.5) : r.y / screenHeight < 0.5;
        const toLeft = rect ? r.x > (rect.left + rect.width * 0.5) : r.x / screenWidth < 0.5;
        const h = rect ? rect.height + rect.top : screenHeight;
        // console.log("--------------------------------------------------");
        // console.log("MoreButton.tsx::134 => r", r);
        // console.log("MoreButton.tsx::138 => rect", rect);
        // console.log(`MoreButton.tsx::134 => screenWidth = ${screenWidth}, screenHeight = ${screenHeight}`);
        // console.log(`MoreButton.tsx::134 => toUpper = ${toUpper}, toLeft = ${toLeft}`);
        let toRet: PortalEdges = {
            left: toLeft ? r.left - containerWidth : r.left + 40,
            bottom: toUpper ? h - r.top - 40 : undefined,
            top: toUpper ? undefined : r.top
        };
        // console.log("MoreButton.tsx::174 => toRet", toRet);
        return toRet;

    }, [screenHeight, screenWidth, containerWidth, rect])
    const { setInstance, renderPortal } = usePortal({ calcPos: calcPos ?? mCalcPos });

    const toggle = React.useMemo(() => (e: React.MouseEvent<HTMLButtonElement>) => {
        // console.log("@more button toggle!");
        e.preventDefault();
        e.stopPropagation();
        setOpen(o => !o);
    }, [setOpen]);
    const hide = React.useMemo(() => (e?: React.MouseEvent<HTMLElement>) => {
        // console.log("@more button hide!");
        if (e?.preventDefault && e?.stopPropagation) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (onHide)
            onHide();
        setOpen(o => false);
    }, [setOpen, onHide]);

    const entryElements = React.useMemo(() => {
        const entryElements: JSX.Element[] = [];
        _.forEach(entries, (b, i) => {
            const c = (ele: ElementType) => {
                if (b.hideOnClick)
                    hide();
                if (b.onClick)
                    b.onClick(ele);
            }
            const { showSubComponent, renderSubComponent } = b;
            if (showSubComponent && renderSubComponent !== undefined) {
                entryElements.push(<T.SubComponentContainer key={i}>
                    {renderSubComponent(ele, hide)}
                </T.SubComponentContainer>
                );
            }
            else
                entryElements.push(<MoreMenuButton {...b} ele={ele} key={i} onClick={c} />)
        })
        return entryElements;
    }, [ele, entries, hide]);



    const Footer = React.useMemo(() => () => renderFooter ? renderFooter(ele) : <div />, [renderFooter, ele]);

    return (
        <>
            <Button
                icon={open ? "more close" : "more"}
                kind={open ? "cancelButton" : "transparentButton"}
                floated="right"
                onClick={toggle}
                setInstance={setInstance}
                active={open}
                {...buttonProps}
            />
            {open && renderPortal(<T.SecondaryButtonsContainer style={containerStyle} >
                <CloseArea onClick={hide} />
                <MoreButtonsContainer onMouseLeave={stayOpenOnLeave ? undefined : hide} >
                    {!hideMoreButton &&
                        <Button
                            icon={"more"}
                            iconColor={"@accent"}
                            kind={"halfTransparentButton"}
                            onClick={toggle}
                        />
                    }
                    {entryElements}
                    {showFooter && <Footer />}
                </MoreButtonsContainer>
            </T.SecondaryButtonsContainer>

            )}
        </>
    )
}


export default MoreButton;