import { EventTypeEnum } from "collaboration-service";
import * as React from "react";
import HubConnection from "./HubConnection";

export interface UseVisibleElementsServiceProps {
    userId: string;
    groupId: string;
    contextName: string;
    contextValue: string;
    eventType?: EventTypeEnum;
}


export const useVisibleElementsService = (p: UseVisibleElementsServiceProps) => {
    const { contextName, contextValue, groupId, userId, eventType } = p;
    const hubName = "visibleElementsHub";
    const [hubConnection,] = React.useState<HubConnection>(new HubConnection());

    const {
        startInternal,
        stop,
        pause,
        play,
        setVisible,
    } = React.useMemo(() => {
        const stop = () => {
            hubConnection.invoke(hubName, "stop", new Date());
        };
        const startInternal = (userId: string, groupId: string, contextName: string, contextValue: string, eventType: EventTypeEnum = "Undefined") => {
            hubConnection.invoke(hubName, "start", userId, groupId, contextName, contextValue, eventType, new Date());
        }
        const pause = () => {
            hubConnection.invoke(hubName, "pause", new Date());
        }
        const play = () => {
            hubConnection.invoke(hubName, "play", new Date());
        }
        const setVisible = (name: string, ids: string[], visible: boolean) => {
            hubConnection.invoke(hubName, "setVisible", name, ids, visible, new Date());
        }
        hubConnection.register(hubName, "doSomething", (v: any) => { } /*console.log("useVisibleElementsHubService.tsx::41 => v", v) */);
        return {
            startInternal,
            stop,
            pause,
            play,
            setVisible,
        };
    }, [hubConnection]);

    const start = React.useMemo(() => {
        return () => {
            startInternal(userId, groupId, contextName, contextValue, eventType ?? "Undefined");
        }
    }, [userId, groupId, contextName, contextValue, eventType, startInternal]);

    return {
        start,
        stop,
        pause,
        play,
        setVisible,
    }
}
