import { Button, Icon, ImgIcons } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { PodcastSC as T } from './PodcastSC';

export interface MoreButtons {
    icon: ImgIcons;
    onClick?: () => void;
}

export interface PodcastHeaderProps {
    categoryImage: string;
    categoryName: string;
    back?: () => void;
    additionalButton?: MoreButtons[];
    asSearchResult?: boolean;
    children?: JSX.Element | JSX.Element[] | string;
    width?: number;
    lightMode?: boolean;
}

export default class PodcastHeader extends React.PureComponent<PodcastHeaderProps> {

    public render() {
        return (
            <T.PodcastHeaderBar
                lightMode={this.props.lightMode ?? false}
            >
                <T.PodcastHeaderBarRightImage />
                <T.PodcastHeaderBarImage imageUrl={this.props.categoryImage} >
                    {this.props.asSearchResult &&
                        <Icon name="search" size="60px" style={{ position: "absolute", bottom: -5, right: 5 }} />
                    }
                </T.PodcastHeaderBarImage>
                <T.PodcastHeaderBarRowBottom>
                    {this.props.categoryName}
                </T.PodcastHeaderBarRowBottom>
                <T.PodcastHeaderBarRowTop>
                    {this.props.back &&
                        <Button kind="halfTransparentButtonDark" icon="chevron left" onClick={this.props.back} />
                    }
                    {this.props.additionalButton &&
                        _.map(this.props.additionalButton, (a, i) => {
                            return (
                                <Button kind="halfTransparentButtonDark" icon={a.icon} key={i} onClick={a.onClick} />
                            );
                        })
                    }
                </T.PodcastHeaderBarRowTop>
                <T.PodcastHeaderBarSearch width={this.props.width ?? 250}>
                    {this.props.children}
                </T.PodcastHeaderBarSearch>
            </T.PodcastHeaderBar>
        );
    }
}
