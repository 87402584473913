import { ThemeColor } from "imaginarity-react-ui";

export interface FeedSettings {
    barsBackgroundColor: string;
    contentBackgroundColor: string;
    barsColor: string;
    contentColor: string;
    darkMode: boolean;
    maxWidth: string;
}
export interface FeedTheme extends ThemeColor {
    post: FeedSettings;
    imageThankYou: string;
    luxuryRibbonUrl: string;
    momentsName: string;
}


export const feedTheme: FeedTheme = {
    backgroundColor: "#fff",
    // backgroundColor: "linear-gradient(120deg, #fff 20%, rgb(240, 239, 247), #fff 80%)",
    color: "@darkerGrey",
    imageThankYou: "https://cdn.imaginarity.com/public/excite/excite.jpg",
    luxuryRibbonUrl: "https://cdn.imaginarity.com/public/images/luxuryRibbon.png",
    momentsName: "Moments",
    post: {
        barsBackgroundColor: "#cfcfcf",
        contentBackgroundColor: "#bfbfbf",
        barsColor: "#00ff00",
        contentColor: "#0000ff",
        // maxWidth: "640px",
        maxWidth: "540px",
        darkMode: false,
    }
}