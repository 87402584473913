import { appendPx, belowBreakpointOrEqual, ButtonSizes, fadeColor, getButtonSizes, getColor, lightenColor, styled } from "imaginarity-react-ui";
import _ from "lodash";
import { keyframes } from "styled-components";

export namespace WorkflowSC {
    export const StateContainer = styled.div`
    margin-top: 10px;
    padding-top: 17px;
    margin-bottom: 20px;
    overflow-x: auto;
    height: 180px;
    border-top: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    ::-webkit-scrollbar {
      height: 5px !important;
    }
  `;
    export const StateContainerTitle = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr max-content;
    margin-top: -10px;
    margin-left: -10px;
    width: calc(100% + 20px);
    background: ${(p) => getColor(p, "@veryLightGrey")};
    border-bottom :1px solid ${(p) => getColor(p, "@middleLightGrey")};
    font-weight: bolder;
    button>div{margin: 0;}
`;
    export const StateContainerTitleContainer = styled.div`
    padding-right: 5px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 40px;
`;
    export const StateContainerTitleInfo = styled.span`
    padding-left: 5px;
    font-weight: normal;
        &:after{
            content: ': ';
        }
`;
    export const StateContainerInfo = styled.div`
    color: ${(p) => getColor(p, "@lightGrey")};
    font-size: 0.85em;
    line-height: 14px;
    margin-top: -10px;
  `;
    export const StateGrid = styled.div<{ columns: number }>`
    display: grid;
    grid-template-columns: 50px ${(p) =>
            _.times(p.columns, (i) => "300px 50px ")};
    button > div {
      margin: 0;
    }
  `;
    export const IconContainer = styled.div`
    height: 100%;
    padding-top: 50px;
    text-align: center;
  `;

    export const UserGrid = styled.div<{ isSmallDevice: boolean }>`
    display: grid;
    grid-template-columns: ${p => p.isSmallDevice ? "1fr" : "1fr 1fr"};
    //grid-template-rows: 210px;
    border: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    padding: 10px;
    column-gap: 70px;
    row-gap: 20px;
  `;
    export const ButtonNoMargin = styled.span`
    button > div {
      margin: 0;
    }
  `;
    export const UserTile = styled.div`
    background-color: ${(p) => getColor(p, "@veryLightGrey")};
    width: 100%;
    min-height: 40px;
    max-height: 510px;
    background-color: ${(p) => getColor(p, "@mainBackground")};
    display: grid;
    grid-template-rows: 40px 1fr;
    border: 1px solid ${(p) => getColor(p, "@middleLightGrey")};
    box-shadow: 0 6px 8px -6px ${(p) => getColor(p, "@middleLightGrey")};
  `;
    export const PaddedAndBordered = styled.div`
    border: 1px solid ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
    padding: 10px;
    //padding-bottom: 0;
  `;
    export const EG = styled.div`
    display: grid;
    grid-template-columns: 180px 1fr 70px 180px 1fr;
    transition: all 0.2s ease-out;
  `;
    export const EGSI = styled(EG)`
    grid-template-columns: ${p => belowBreakpointOrEqual(p, "tablet") ? "1fr" : "1fr 10px 1fr"};
    &:hover{
        background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
    }
  `;
    export const D = styled.div`
    line-height: 40px;
    button > div {
      margin: 0;
    }
  `;
    export const Q = styled.div`
    line-height: 20px;
    padding: 10px 0;
    button > div {
      margin: 0;
    }
  `;
    export const DX = styled.div`
    grid-column-start: 4;
    grid-column-end: 6;
    line-height: 40px;
    button > div {
      margin: 0;
    }
  `;
    export const SelType = styled.div`
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    column-gap: 5px;
  `;
    export const InnerEPC = styled.div`
    width: 100%;
  `;
    export const C = styled.div<{ height: number }>`
    grid-column-start: 1;
    grid-column-end: 3;
        display: ${p => p.height === 0 ? "none" : "block"};
        height: ${p => p.height}px;
        max-height: 162px;
        border: 1px solid ${p => getColor(p, "@middleLightGrey")};
        overflow-y: auto;
        transition: height 0.2s ease-out;
`;
    export const WFCsel = styled.div`    
    grid-column-start: 1;
    grid-column-end: 3;
    margin-bottom: 10px;
`;
    export const WFC = styled.div`
    margin: 0 0 10px 0px; 
    overflow-x: auto;
    position: relative;
    grid-column-start:1;
    grid-column-end: 3;
`;
    const entrance = keyframes`
    from {background: darkred; color: white; transform: translateX(-20px)}
    to {background: white; color: black; transform: translateX(0px)}
  `;
    export const WF = styled.div<{ animated: boolean }>`
        display: grid;
        grid-template-columns: 40px max-content 1fr max-content;
        line-height: 40px;
        animation: ${p => p.animated ? entrance : "none"} 0.2s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
        transition: all 0.2s ease-out;
`;
    export const WFRow = styled.div`
        display: contents;
        background-color: ${props => getColor(props, props.theme.colors.mainBackground)};
        &:hover > div{
            background-color: ${props => getColor(props, props.theme.colors.veryLightGrey)};
        }
`;
    export const WFT = styled.div`
  //padding-top: 5px;
`;
    export const GR = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  column-gap: 10px;
`;

    export const BC = styled.div`
  margin-bottom: 10px;
  grid-column-start: 1;
  grid-column-end: 3;
`;
    export const BCInfo = styled.span`
        float: left;
        margin-top: 20px;
        line-height: 20px;
        font-weight: bolder;
`;
    export const BCWarning = styled.span`
        float: left;
        margin-top: 20px;
        line-height: 20px;
        //font-weight: bolder;
        color: ${props => getColor(props, props.theme.colors.accentRed)};
`;

    export const Ews = styled.div`
`;
    export const EwsGrid = styled.div<{ isSmallDevice: boolean }>`
    display: grid;
    grid-template-columns: ${p => p.isSmallDevice ? "1fr" : "max-content 1fr"};
    margin-top: 10px;
`;

    export const EwsList = styled.div`
    grid-column: 1;
    line-height: 40px;
    padding: 0px;
`;

    export const EwsEdit = styled.div<{ selected: boolean }>`
    grid-column: ${p => belowBreakpointOrEqual(p, "tablet") ? 1 : 2};
    margin-top: ${p => belowBreakpointOrEqual(p, "tablet") ? 10 : 0}px;
    border: 1px solid ${p => p.selected ? getColor(p, "@lightGrey") : getColor(p, "@middleLightGrey")};
    //padding: 10px;
    background: ${p => getColor(p, "@mainBackground")};
    transition: all 0.2s ease-out;
    z-index:1;
    //overflow-x: auto;
`;
    export const EwsEditTitle = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
    background:${p => lightenColor(p, "@mainBackground", 95)};
    border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
    color:${p => getColor(p, "@darkGrey")};
    font-weight: bolder;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; 
    
`;
    export const EwsOPG = styled.div`
    display: grid;
    grid-template-columns: ${p => belowBreakpointOrEqual(p, "tablet") ? "1fr 0px" : "1fr 20px"};
`;

    export const EwsOP = styled.div<{ selected: boolean }>`
    padding: 0 10px;
    border: 1px solid ${p => p.selected ? getColor(p, "@lightGrey") : getColor(p, "@middleLightGrey")};
    color: ${p => p.selected ? getColor(p, "@darkGrey") : getColor(p, "@darkGrey")} !important;
    background: ${p => p.selected ? lightenColor(p, "@mainBackground", 95) : getColor(p, "@mainBackground")} !important;
    cursor: ${p => p.selected ? "default" : "pointer"};
    transition: all 0.2s ease-out;
    box-shadow: 0 4px 8px -6px ${p => p.selected ? getColor(p, "@mainForeground") : "#fff"} !important;
    animation: ${entrance} 0.6s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;  
    svg{
        stroke: ${p => p.selected ? getColor(p, "@darkGrey") : getColor(p, "@darkGrey")} !important;
    }
    &:hover{
        border-color:  ${p => p.selected ? getColor(p, "@lightGrey") : getColor(p, "@darkerGrey")};
        background:  ${p => p.selected ? lightenColor(p, "@mainBackground", 90) : getColor(p, "@veryLightGrey")} !important;
        color: ${p => p.selected ? getColor(p, "@darkerGrey") : getColor(p, "@mainForeground")} !important;
        svg{
            stroke: ${p => p.selected ? getColor(p, "@darkerGrey") : getColor(p, "@darkerGrey")} !important;
        }
    }
`;
    export const EwsSpacerLine = styled.div`
    width: 100%;
    height: 21px;
    color: ${p => getColor(p, "@lightGrey")};
    border-bottom: 1px solid ${p => getColor(p, "@lightGrey")};
`;
    export const EwsSpacerLineDot = styled.div`
            font-size:12px;
            &:before{
                content: '▣';
                transform: translate(-5.5px, 0.5px);
                float: left;
            }
            &:after{
                content: '▣';
                transform: translate(4.5px, 0.5px);
                float: right;
                z-index: 2;
            }
`;
    export const EwsSpacerContainer = styled.div`
    width: 100%;
    display: block;
    height: 40px;
`;
    export const EWTOGridCopy = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    line-height: 40px;
    padding-left: 15px;
    column-gap: 10px;
`;
    export const EWTOGrid = styled.div`
    display: grid;
    grid-template-columns: minmax(80px, max-content) 1fr;
    line-height: 40px;
`;
    export const EWTOGridLabel = styled.div<{ centered?: boolean }>`
    align-self: ${p => p.centered ? "center" : "unset"};
`;
    export const EWTOGridInp = styled.div<{ centered?: boolean }>`
    padding-left: 10px;
    margin-bottom: 3px;
    align-self: ${p => p.centered ? "center" : "unset"};
`;

    export const User = styled.div<{ buttonSize?: ButtonSizes }>`
    display: grid;
    grid-template-columns: ${p => p.buttonSize ? appendPx(getButtonSizes(p.theme, p.buttonSize).buttonHeight) : "40px "} 150px 25px 1fr 25px;
    grid-template-rows: ${p => p.buttonSize ? appendPx(getButtonSizes(p.theme, p.buttonSize).buttonHeight) : "24px "};
    width: 100%;
    margin: 1px 0;
    &:hover{
        background: ${p => getColor(p, p.theme.colors.veryLightGrey)}; 
    }
`;
    export const DeclineInfo = styled.div`
    grid-column-start: 2;
    grid-column-end: 5;
    color: ${p => lightenColor(p, p.theme.colors.mainForeground, 220)}; 
    padding-left: 5px;
    font-size: 0.9em;
    margin-top: -5px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
    export const UserItem = styled.div<{ buttonSize?: ButtonSizes }>`
    width: 100%;
    padding-left: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 24px;
    transition: all 0.2s ease-out;
    ${p => p.buttonSize ? "line-height: " + appendPx(getButtonSizes(p.theme, p.buttonSize).buttonHeight) + ";" : ""}
    a{
        cursor: pointer;
        color: ${p => getColor(p, "@lightGrey")};
        &:hover{
            color: ${p => getColor(p, "@accent")};
            svg{
                stroke: ${p => getColor(p, "@accent")}; 
            }
        }
    }
`;
    export const UserAvatar = styled.img.attrs<{ imgSrc?: string }>(p => ({
        src: p.imgSrc ?? p.theme.emptyAvatarImageUrl
    })) <{ imgSrc?: string }>`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-top: 2px;
    float: left;
    margin-right: 10px;
    margin-left: 2px;
`;
    export const WsC = styled.div<{ current: boolean }>`
    position: relative;
    width: 100%;
    box-shadow: 0 6px 8px -6px ${p => p.current ? getColor(p, "@accentBlack") : getColor(p, "@middleLightGrey")};
    transition: all 0.2s ease-out;
`;
    export const WsSC = styled.div<{ current: boolean, approve: boolean }>`
    width: 100%;
    height: 145px;
    background-color: ${p => getColor(p, "@mainBackground")};
    border: ${p => p.current ? 2 : 1}px solid ${p => p.current ? getColor(p, "@darkerGrey") : getColor(p, "@middleLightGrey")};   
    display: grid;
    //grid-template-rows: 40px 1fr ${p => p.approve && "41px"};
    grid-template-rows: 40px 1fr max-content;
    transition: all 0.2s ease-out;
`;
    export const WsTitle = styled.div<{ current: boolean, noPaddingRight?: boolean }>`
    grid-row: 1;
    padding-right: ${p => p.noPaddingRight ? 0 : 5}px;
    font-size: 1.03em;
    font-weight: ${p => p.current ? "bolder" : "normal"};
    height: 40px;
    line-height: 40px;
    width: 100%; 
    background-color: ${p => getColor(p, "@veryLightGrey")};
    border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};   
    color: ${p => p.current ? getColor(p, "@darkerGrey") : lightenColor(p, "@mainForeground", 80)};   
    padding-left: 10px;   
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transition: all 0.2s ease-out;
`;
    export const WsBottomContainer = styled.div`
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 40px 40px;
`;
    export const WsBottomContainerPos = styled.div`
    position: relative;
`;
    export const WsBottom = styled.div<{ error?: boolean }>`
    grid-row: 3;
    width: 100%;
    border-top: 1px solid ${p => p.error === true ? getColor(p, "@accentRed") : getColor(p, "@middleLightGrey")};  
    button>div{margin:0;} 
    input{color: ${p => getColor(p, "@mainForeground")};  }
    background-color: ${p => p.error === true ? fadeColor(p, "@accentRed", 10) : getColor(p, "@veryLightGrey")};
`;
    export const WsContent = styled.div`
    grid-row:2;
    width: 100%;
    padding: 9.5px 10px;
    font-size: 0.8rem;
    overflow: auto;
    background: ${p => lightenColor(p, "@veryLightGrey", 100)};  
    //border-top: 1px solid ${p => getColor(p, "@middleLightGrey")};   
`;
    export const WsWorkflowStateStepIconItemContainer = styled.div`
    float: left;
    margin-left: -3px;
    padding-right: 5px;
`;
    export const WorkflowStepIconContainer = styled.div<{ color: string }>`
    float: left;
    margin-top: 2px;
    margin-left: 2px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    svg{
        fill: ${p => fadeColor(p, p.color, 10)};   
    }
    svg:hover{
        stroke: ${p => getColor(p, "@mainForeground")};   
    }
`;
    export const NoTooltip = styled.div`
    float: left;
    margin: 2px;
`;

    export const WsuC = styled.div`
`;
    export const WsuRC = styled.div<{ all: boolean }>`
    padding: 10px;
    display: grid;
    column-gap: 10px;
    row-gap: 5px;
    grid-template-columns: 140px 1fr;
    ${p => p.all ? "pointer-events: none;" : ""}
    ${p => p.all ? "opacity: 0.3;" : ""}
`;
    export const WsuSUC = styled.div`
`;
    export const WsuT = styled.div`
    line-height: 40px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
    export const WsuAllC = styled.div`
    padding: 10px;
    padding-bottom: 0;
    margin-bottom: -10px;
    display: grid;
    column-gap: 10px;
    row-gap: 5px;
    grid-template-columns: 140px 1fr;
`;
    export const WsuWUEcontainer = styled.div`
    grid-column-start: 1;
    grid-column-end: 3;
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    padding: 5px;
    margin-top: -6px;
`;
    export const TableGridMoreMenu = styled.div<{ left?: boolean }>`
    position: absolute;    
    top: 0px;
    ${p => p.left ? "left" : "right"}: 40px;
    z-index: 50;
    background: ${p => getColor(p, p.theme.colors.mainBackground)};
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    box-shadow: 0 6px 8px -6px ${p => getColor(p, p.theme.colors.mainForeground)};
    z-index: 2147483637;
    font-size: 0.9rem;
`;
    export const TableGridMoreMenuItem = styled.div<{ disabled?: boolean, rightButtons?: boolean, warning?: boolean }>`
    display: grid;
    grid-template-columns: 39px 310px ${p => p.rightButtons ? " 40px 40px" : ""};
    grid-template-rows: max-content;
    padding: 10px 0;
    line-height: 20px;
    font-weight: normal;
    transition: all 0.2s ease-out;
    color: ${p => p.warning ? lightenColor(p, p.theme.colors.accentRed, 90) : p.disabled ? getColor(p, p.theme.colors.lightGrey) : getColor(p, p.theme.colors.darkerGrey)};
    background: ${p => p.warning ? getColor(p, p.theme.colors.veryLightGrey) : p.disabled ? "inherit" : getColor(p, p.theme.colors.mainBackground)};
    cursor: ${p => p.disabled ? "default" : "pointer"};
    svg{
        stroke: ${p => p.warning ? lightenColor(p, p.theme.colors.accentRed, 90) : p.disabled ? getColor(p, p.theme.colors.lightGrey) : getColor(p, p.theme.colors.darkerGrey)};
    }
    &:hover{
        background: ${p => p.warning ? lightenColor(p, p.theme.colors.veryLightGrey, 98) : p.disabled ? "inherit" : getColor(p, p.theme.colors.veryLightGrey)};
        svg{
            stroke: ${p => p.warning ? lightenColor(p, p.theme.colors.accentRed, 90) : p.disabled ? getColor(p, p.theme.colors.lightGrey) : getColor(p, p.theme.colors.darkerGrey)};
        }   
    }
`;
    export const AomContainer = styled.div`
`;
    export const AomIC = styled.div<{ x: number, y: number }>`
    position: fixed;
    left: ${p => p.x}px;
    top: ${p => p.y}px;
`;
    export const MaxInfo = styled.div`
    position: absolute;
    font-size: 0.68em;
    text-align: right;
    right: 2px;
    bottom: 8px;
    color: ${p => getColor(p, p.theme.colors.lightGrey)};
`;
}
