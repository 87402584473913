import ContentLanguageSelect from 'components/ContentLanguageSelect';
import SidebarTitleContainer from 'components/Sidebars/SidebarTitleContainer';
import * as React from 'react';
import { useImgI18N } from 'services/ImgI18N';


interface DataPrivacySidebarProps {
}

const DataPrivacySidebar = (p: DataPrivacySidebarProps) => {
    const { t } = useImgI18N('general');
    return (
        <>
            <SidebarTitleContainer icon="language" title={t("content language")} />
            <ContentLanguageSelect />
        </>
    );
}
export default DataPrivacySidebar;