import AdminGroupSelection from 'AdminGroupSelection';
import { ContentController, ContentPostContentLocationDownloadDto, ControllerHelper, CopyToGroupWorkflowTransitionOperationUploadDto, GroupDownloadDto, PostController } from 'collaboration-service';
import { Checkbox, customStyles, fadeColor, LoaderInline, Theme, useBelowBreakpoint } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { getGroupById } from 'services/StoreDependantHelpers';
import { ThemeContext } from 'styled-components';
import { WorkflowSC as T } from 'views/Workflow/WorkflowSC';
import { RenderWorkflowTransitionOperationSettingsProps } from '../EditWorkflowStateOperations';

export const createFilledStyles = (theme: Theme, dropUp?: boolean, color?: string, fadings?: number[]) => {
    const s = customStyles(theme, dropUp);
    return {
        ...s,
        control: (provided: any, state: any): any => ({
            ...s.control(provided, state),
            backgroundColor: fadeColor({ theme }, color ?? "@accentGreen", (fadings ?? [])[0] ?? 10),
            borderColor: fadeColor({ theme: theme }, color ?? "@accentGreen", (fadings ?? [])[1] ?? 10),
            "&:hover": {
                backgroundColor: fadeColor({ theme: theme }, color ?? "@accentGreen", (fadings ?? [])[2] ?? 5),
                borderColor: fadeColor({ theme: theme }, color ?? "@accentGreen", (fadings ?? [])[3] ?? 10),
            }
        }),
    }
};


const CopyToGroupWorkflowTransitionOperation = (p: RenderWorkflowTransitionOperationSettingsProps) => {
    const { namespace, op, onChange, groupId } = p;
    const { contentLanguage, adminGroups } = useSelector((s: ApplicationState) => ({ contentLanguage: s.contentLanguage, adminGroups: s.adminGroups }), shallowCompare);
    const { t } = useImgI18N(namespace ?? "workflow");
    const typedOp = React.useMemo(() => op as CopyToGroupWorkflowTransitionOperationUploadDto, [op]);
    const [locs, setLocs] = React.useState<ContentPostContentLocationDownloadDto[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        const load = async () => {
            const post = await ControllerHelper.singleCall({ id: groupId }, PostController.GetSinglePost, true);
            let gid = groupId;
            //console.log('post => ', post);
            if (post?.type === "ProjectGroup") {
                gid = post.group_id;
            }
            const locs = await ControllerHelper.singleCall({ groupid: gid }, ContentController.GetContentPostContentLocations);
            setLocs(locs);
            setIsLoading(false);
        }
        setIsLoading(true);
        load();
    }, [groupId]);

    const chg = React.useMemo(() => (op: Partial<CopyToGroupWorkflowTransitionOperationUploadDto>) => {
        onChange({ ...typedOp, ...op });
    }, [typedOp, onChange]);

    const theme = React.useContext(ThemeContext);
    // const isSmallDevice = theme.content.width < 700;
    const isSmallDevice = useBelowBreakpoint("tablet");
    const filledStyles = React.useMemo(() => createFilledStyles(theme, false), [theme]);

    if (isLoading)
        return <LoaderInline active />


    return (
        <T.EWTOGrid>

            <T.EWTOGridLabel >{t("lock after copy")}</T.EWTOGridLabel>
            <T.EWTOGridInp><Checkbox selected={typedOp.lockCopy} label={typedOp.lockCopy ? t("copy is locked") : t("copy is editable")} onClick={(val) => chg({ lockCopy: !val })} /></T.EWTOGridInp>
            <T.EWTOGridLabel >{t("hidden after copy")}</T.EWTOGridLabel>
            <T.EWTOGridInp><Checkbox selected={typedOp.hiddenCopy} label={typedOp.hiddenCopy ? t("copy is hidden") : t("copy is visible")} onClick={(val) => chg({ hiddenCopy: !val })} /></T.EWTOGridInp>

            <T.EWTOGridLabel >{t("copy settings")}</T.EWTOGridLabel>
            <T.EWTOGridLabel style={{ gridColumnStart: isSmallDevice ? 1 : 2, gridColumnEnd: 3 }}>
                <T.EWTOGridCopy style={{ paddingLeft: 0 }}>
                    <T.EWTOGridLabel style={{ fontWeight: 900 }}>{t("for location")}</T.EWTOGridLabel>
                    <T.EWTOGridInp style={{ fontWeight: 900 }}>{t("copy to target group")}</T.EWTOGridInp>
                    {
                        _.map(locs, l => {
                            const group = (sel: GroupDownloadDto | null) => {
                                const tg = _.clone(typedOp.targetGroupIdForContentLocationId) ?? {};
                                if (sel === null || sel === undefined) {
                                    delete tg[l.id];
                                    chg({ targetGroupIdForContentLocationId: tg });
                                } else {
                                    tg[l.id] = sel.id;
                                    chg({ targetGroupIdForContentLocationId: tg });
                                }
                            }

                            const g = typedOp.targetGroupIdForContentLocationId && typedOp.targetGroupIdForContentLocationId[l.id] ? getGroupById(typedOp.targetGroupIdForContentLocationId[l.id], adminGroups) : undefined;
                            console.log("CopyToGroupWorkflowTransitionOperation.tsx::91 => group", g);
                            // console.log('l.names[0].text => ', l.names[0].text);
                            // if (typedOp.targetGroupIdForContentLocationId)
                            //     console.log('typedOp.targetGroupIdForContentLocationId[l.id] => ', typedOp.targetGroupIdForContentLocationId[l.id]);
                            // console.log('g => ', g);
                            return (
                                <React.Fragment key={l.id}>
                                    <T.EWTOGridInp style={{ lineHeight: "40px", paddingLeft: 0, fontWeight: g ? 900 : 300 }}>{getTranslated(l.names, contentLanguage)?.text}</T.EWTOGridInp>
                                    <T.EWTOGridInp style={{ lineHeight: "0px" }}>
                                        <AdminGroupSelection
                                            styles={g === undefined ? customStyles(theme, false) : filledStyles}
                                            value={g}
                                            showTypes
                                            filterTypes={["CMS-OUTPUT"]}
                                            onGroupSelected={group}
                                            isClearable />
                                    </T.EWTOGridInp>
                                </React.Fragment>
                            )
                        })
                    }

                </T.EWTOGridCopy>
            </T.EWTOGridLabel>


            {/* <div style={{ gridColumnStart: 1, gridColumnEnd: 3, marginLeft: -5, marginRight: -5 }}>
                <Divider color="@lightGrey" />
            </div> */}
            {/* <Button icon="add square" content="add copy definition" onClick={addCopyDef}/> */}

        </T.EWTOGrid>
    );
}
export default CopyToGroupWorkflowTransitionOperation;
