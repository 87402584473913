import { fadeIn } from 'components/Wiki/WikiSC';
import { appendPx, getColor, lightenColor, styled } from 'imaginarity-react-ui';
export class EditorSC {
    public static VerticalSpacerSmall = styled.div`
    display: inline-block;
    width: 1px;
    background: ${p => getColor(p, "@middleLightGrey")};
`;
    public static VerticalSpacerLarge = styled(EditorSC.VerticalSpacerSmall)`
    width: 5px;
`;
    public static ToolbarContainer = styled.div<{ toolbarHeight: number; toolbarBackground: string; toolbarFontColor: string }>`
    width: 100%;
    background: ${p => p.toolbarBackground};
    color: ${p => p.toolbarFontColor};
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-bottom: none;
    position: relative;
    min-height: ${p => p.toolbarHeight + 1}px;
`;

    public static StyledList = styled.div<{ toolbarHeight: number }>`
    display: inline-block;
    line-height: ${p => p.toolbarHeight}px; 
    width: ${p => p.toolbarHeight}px; 
    height: ${p => p.toolbarHeight}px; 
    text-align: center;
    position: relative;
    span svg{
        stroke-width: 2.8px;
    }
`;
    public static StyledListColor = styled(EditorSC.StyledList)`
    span svg{
        stroke: none;
    }
    `;
    public static PpeLinkContainer = styled.div<{ toolbarHeight: number; }>`
        position: absolute;
        top: 0;
        left: 0;
        text-transform: uppercase;
        font-weight: bolder;
        padding-left: 13px;
        width: calc(100% - 2 * ${p => p.toolbarHeight}px);
        height: ${p => p.toolbarHeight + 1}px;
        line-height: ${p => p.toolbarHeight}px;
`;
    public static PpeLinkContainerClose = styled.div<{ toolbarHeight: number }>`
        position: absolute;
        top: 0;
        right: 0;
        width: calc(2 * ${p => p.toolbarHeight}px);
`;


    public static WikiArticleSelectionContainer = styled.div<{ toolbarHeight: number }>`
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1001;
    top: ${p => p.toolbarHeight + 1}px;
    background: #FFF;
`;
    public static ColorSelectorContainer = styled.div`
    display: inline-block;
    max-height: 40px;
    margin-bottom: -15px;
    overflow: hidden;

`;
    public static StyledSelector = styled.div<{ width: number, align: string; toolbarHeight: number, top?: number }>`
    position: absolute;
    top: ${p => p?.top ?? p.toolbarHeight}px;
    left: 0;
    width: ${p => p.width}px;
    z-index: 100;
    text-align: left;
    background: ${p => p.theme.colors.veryLightGrey};
    border-top: 1px solid ${p => getColor(p, "@middleLightGrey")};
    box-shadow: 0 5px 6px -5px #000;
    button{
        text-align: ${p => p.align} !important;
    }
`;
    public static StyledLink = styled.div<{ top?: number, left?: number }>`
    position: absolute;
    top: ${p => p.top ? p.top + 65 : 0}px;
    left: 15px;
    background-color:  ${p => getColor(p, "@veryLightGrey")};
    border: 1px solid ${p => getColor(p, "@lightGrey")};
    z-index: 1001;
    width: calc(100% - 30px);
    height: 52px;
    box-shadow: 0 6px 8px -6px #000000;
    max-height: 200px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 5px;
    animation: ${fadeIn} 0.2s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
`;
    public static EditorContainer = styled.div<{ height?: number, notFixed: boolean, hidden?: boolean }>`
    ${p => p.hidden ? "display: none;" : ""}
    position: relative;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: visible;
    &>div{
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .ql-editor {
        resize: vertical;
        font-family: ${p => p.theme.editor.containerFontFamily};
        line-height: ${p => p.theme.editor.lineHeight}em;
        height: ${p => p.notFixed ? "auto" : appendPx(p.height ?? p.theme.editor.height)};
        padding: ${p => p.theme.editor.padding};
        font-size: ${p => p.theme.editor.fontSize};
        background: ${p => p.theme.colors.mainBackground};
        -webkit-user-select: text;
        user-select: text;
        white-space: pre-wrap !important;
        width: 100%;
        p{
            margin: ${p => p.theme.editor.p.margin} ;
        }
    }
    .ppe-link { 
        color: ${p => getColor(p, "@accent")};
        font-weight: 900;
    }
    .https-mailto { 
        color: ${p => getColor(p, "@accentBlue")};
        font-weight: 900;
    }
    .ql-editor.inactive {
        border-top: 1px solid${p => p.theme.colors.middleLightGrey};
        background-color:${p => p.theme.colors.mainBackground};
        background-size: 100px;
        background-position-x: calc(100% - 200px);
        background-position-y: center;
        transition: 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.01s both;
        transition-property: background-position, box-shadow;
        min-height: ${p => appendPx(p.height ?? p.theme.editor.height)};
    }
    .ql-toolbar {
        background: ${p => getColor(p, p.theme.editor.toolbarColor.backgroundColor)};
        color: ${p => getColor(p, p.theme.editor.toolbarColor.color)};
        border: ${p => p.theme.editor.toolbarBorder};
        padding: ${p => p.theme.editor.toolbarPadding};
        font-family: ${p => p.theme.editor.toolbarFontFamily};
        border-bottom: none;

    }
    .ql-snow.ql-toolbar button {
        background: ${p => getColor(p, p.theme.editor.toolbarButton.color.backgroundColor)};
        color: ${p => getColor(p, p.theme.editor.toolbarButton.color.color)};
        border: ${p => p.theme.editor.toolbarButton?.border};
        padding: ${p => p.theme.editor.toolbarButton?.padding};
        height: ${p => p.theme.editor.toolbarButton?.height};
        width: ${p => p.theme.editor.toolbarButton?.width};
        &:hover{
            background: ${p => lightenColor(p, getColor(p, p.theme.editor.toolbarButton.color.backgroundColor), 95)};
        }
    }
    .ql-container
    {
        background: ${p => getColor(p, p.theme.editor.containerColor.backgroundColor)};
        color: ${p => getColor(p, p.theme.editor.containerColor.color)};
        font-family: ${p => p.theme.editor.containerFontFamily};
    }
    .ql-editor ol, .ql-editor ul {
        padding-left: ${p => p.theme.editor.listPaddingLeft};
        font-size: ${p => p.theme.editor.listFontSize};
        font-weight: ${p => p.theme.editor.listFontWeight};
    }
    .ql-editor h1 {
        font-size: ${p => p.theme.editor.h1.fontSize};
        line-height: ${p => p.theme.editor.h1.lineHeight}em;
    }
    .ql-editor h2 {
        font-size: ${p => p.theme.editor.h2.fontSize};
        line-height: ${p => p.theme.editor.h2.lineHeight}em;
    }
    .ql-editor h3 {
        font-size: ${p => p.theme.editor.h3.fontSize};
        line-height: ${p => p.theme.editor.h3.lineHeight}em;
    }
    .ql-editor h4 {
        font-size: ${p => p.theme.editor.h4.fontSize};
        line-height: ${p => p.theme.editor.h4.lineHeight}em;
    }
    .ql-editor h5 {
        font-size: ${p => p.theme.editor.h5.fontSize};
        line-height: ${p => p.theme.editor.h5.lineHeight}em;
    }
    .ql-editor h6 {
        font-size: ${p => p.theme.editor.h6.fontSize};
        line-height: ${p => p.theme.editor.h6.lineHeight}em;
    }
    .ql-editor p {
        line-height: ${p => p.theme.editor.p.lineHeight}em;
    }
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
        outline: none;
        input{
            outline: none;
            border-radius:0;
        }
    }
    .ql-snow .ql-tooltip.ql-editing input[type=text]{
        outline: none;
        input{
            outline: none;
            border-radius:0;
        }
    }
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
        width: fit-content;
    }
`;
    public static EditorToolbarContainer = styled.div<{ hidden?: boolean }>`
    width: 100%;
    ${p => p.hidden ? "display: none;" : ""}
`;
    public static PreviewContainer = styled.div<{ hidden?: boolean }>`
    width: 100%;
    ${p => p.hidden ? "display: none;" : ""}
    animation: ${fadeIn} 0.2s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
`;
    public static PCHeader = styled.div<{ toolbarHeight: string; toolbarBackground: string; toolbarFontColor: string }>`
    font-weight: bolder;
    text-transform: uppercase;
    background: ${p => p.toolbarBackground};
    color: ${p => p.toolbarFontColor};
    height: ${p => p.toolbarHeight === "small" ? 33 : 41}px;
    line-height: ${p => p.toolbarHeight === "small" ? 32 : 40}px;
    padding-left: 20px;
    border-top: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
`;
    public static PCContent = styled.div`
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
    background-color: #FFF;
    box-shadow: 0 6px 8px -6px #000;
    min-height: calc(${p => p.theme.editor.height} + 40px);
    padding: 20px;
    z-index: 100;
`;
    public static TableContainer = styled.div`
    table{
        width: 100%;
        margin: 0;
        padding: 0;
    }
    tr:hover{
        background: ${p => getColor(p, "@veryLightGrey")};
    }
    th{
        line-height: 30px;
        border-top: 1px solid ${p => getColor(p, "@middleLightGrey")};
        border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
        background: ${p => lightenColor(p, getColor(p, "@middleLightGrey"), 114)};
        margin: 0;
        padding: 0 10px;
        text-transform: uppercase;
        text-align: left;
        font-size: 0.8rem;
    }
    td{
        border-bottom: 1px solid ${p => getColor(p, "@veryLightGrey")};
        margin: 0;
        padding: 5px 10px;
        vertical-align: top;
        font-size: 0.8rem;
    }
    .darkline{
        border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
    }
`;

    public static UserListPopup = styled.div<{ top?: number, hasUsers: boolean }>`
    position: absolute;
    top: ${p => p.top ? p.top + 28 : 0}px;
    left: ${p => p.theme.editor.padding};
    width: calc(100% - ${p => p.theme.editor.padding} - ${p => p.theme.editor.padding}) !important;
    background-color:  ${p => getColor(p, "@veryLightGrey")};
    border:  1px solid ${p => getColor(p, "@middleLightGrey")};
    z-index: 2147483637;
    box-shadow: 0 6px 8px -6px #000;
    overflow-y: hidden;
    padding: 5px;
    height: ${p => p.hasUsers ? "auto" : "52px"};
    max-height: 200px;
    animation: ${fadeIn} 0.2s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
`;
    public static UserListPopupContainer = styled.div`
    height: auto;
    max-height: 130px;
    overflow-y: auto;
    border-top: 1px solid ${p => getColor(p, "@middleLightGrey")};
    width: calc(100% + ${p => p.theme.editor.padding} + ${p => p.theme.editor.padding}) !important;
    margin-left: -${p => p.theme.editor.padding};
    padding: ${p => p.theme.editor.padding};
    margin-top: -9px;
    padding-top: 0;
`;
    public static UserListed = styled.div`
    z-index: 1000;
    background: ${p => getColor(p, "@veryLightGrey")};
    color: ${p => getColor(p, "@darkerGrey")};
    width: calc(100% - 5px);
    height: 32px;
    line-height: 20px;
    padding: 6px 10px;
    cursor: pointer;
    &:hover {
        background: ${p => getColor(p, "@accent")};
        color: ${p => getColor(p, "@mainBackground")};
    }
`;
}