import { Button, ButtonKind, getColor, Icon, ImgIcons, styled } from 'imaginarity-react-ui';
import * as React from 'react';
import { useImgI18N } from 'services/ImgI18N';
import { EditorsSC as T } from './Editors/EditorsSC';
import * as _ from "lodash";
import { FileEntryDownloadDto } from 'collaboration-service';
import CMSFileEntryAppIcon from 'views/CMS/CMSFileEntryAppIcon';

interface EditPartContainerProps {
    title: string;
    subTitle?: string;
    translatedTitle?: string;
    icon?: ImgIcons;
    children: JSX.Element;
    isOpen?: (name: string) => boolean;
    toggleOpen?: (name: string) => () => void;
    register?: (name: string, closed?: boolean) => void;
    registerClosed?: boolean;
    namespace?: string;
    file?: FileEntryDownloadDto;
    showInfo?: boolean;
    bold?: boolean;
    noHoverEffect?: boolean;
    isError?: boolean;
    RenderMissing?: JSX.Element;
    inactiveButtonKind?: ButtonKind;
    relative?: boolean;
}

export const useEditPartContainer = () => {
    const [openEditors, setOpenEditors] = React.useState<{ [key: string]: boolean }>({});

    const toggleOpen = React.useMemo(() => (name: string) => () => {
        setOpenEditors(ce => {
            // console.log('ce => ', ce);
            const newCe = _.clone(ce);
            if (newCe[name])
                newCe[name] = false;
            else
                newCe[name] = true;
            return newCe;
        })
    }, []);

    const register = React.useMemo(() => (name: string, closed?: boolean) => {
        setOpenEditors(ce => {
            // console.log('ce => ', ce);
            const newCe = _.clone(ce);
            // console.log('newCe => ', newCe);
            newCe[name] = closed ? false : true;
            return newCe;
        });
    }, [])

    const isOpen = React.useMemo(() => (name: string) => {
        return openEditors[name];
    }, [openEditors]);
    return {
        toggleOpen,
        register,
        isOpen,
        openEditors
    }
}

const TC = styled.div<{ error?: boolean }>`
    float: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 80px);
    ${p => p.error ? `color: ${getColor(p, "@accentRed")};` : ""}
`;


const EditPartContainer = (p: EditPartContainerProps) => {
    const { noHoverEffect, bold, showInfo, file, title, icon, children, toggleOpen, isOpen, register, registerClosed, namespace, translatedTitle, subTitle, RenderMissing, isError, inactiveButtonKind, relative } = p;
    const { t } = useImgI18N(namespace ?? "admin");
    React.useEffect(() => {
        if (register)
            register(title, registerClosed);
    }, [register, registerClosed, title]);
    const open = isOpen ? isOpen(title) : true;
    return (
        <div style={{ display: "block", width: "100%", position: relative ? "relative" : undefined }}>
            <T.Header
                style={{
                    marginTop: 20,
                    cursor: isOpen && toggleOpen ? "pointer" : "unset",
                    display: "block",
                    width: "100%"
                }}
                onClick={toggleOpen ? toggleOpen(title) : undefined}
                open={open}
                bold={bold}
                noHoverEffect={noHoverEffect ?? false}
            >
                {icon && !file &&
                    <Icon name={icon} style={{ float: "left", marginRight: 10 }} color={isError ? "@accentRed" : undefined} />
                }
                {file &&
                    <T.FileIconContainer showInfo={showInfo ?? false}>
                        <T.FileIconContainerTrans>
                            <CMSFileEntryAppIcon fileEntry={file} />
                        </T.FileIconContainerTrans>
                    </T.FileIconContainer>
                }
                <TC error={isError}
                    title={
                        RenderMissing !== undefined
                            ? undefined
                            :
                            translatedTitle ?
                                `${translatedTitle} ${subTitle ?? ""}`
                                : t(title)}
                >
                    {translatedTitle ?? t(title)}
                    {subTitle &&
                        <T.SubTitle>
                            <Icon name="chevron right" style={{ float: "left" }} />
                            {subTitle}
                        </T.SubTitle>
                    }
                    {RenderMissing && RenderMissing}
                </TC>
                {isOpen && toggleOpen &&
                    <Button
                        floated="right"
                        icon={open ? "chevron up" : "chevron down"}
                        kind={open ? "transparentButton" : inactiveButtonKind ?? "primary"}
                        size="default"
                    />
                }
            </T.Header>
            <div style={{ display: open ? "block" : "none" }}>
                <T.ContainerContent>
                    {children}
                </T.ContainerContent>
            </div>
            <div />
        </div >

    );
}
export default EditPartContainer;