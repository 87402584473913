import { ControllerHelper, GameController, GameDownloadDto, PlaylistEdgeTransitionFunction, PlaylistItemStateDownloadDto, QuizController, QuizDownloadDto } from 'collaboration-service';
import { Dimmer, Icon, Loader } from 'imaginarity-react-ui';
import * as React from 'react';
import { ApplicationState, useAppSelector } from "services/ApplicationState/ApplicationState";
import { DueInfo, shallowCompare } from 'services/Helpers';
import GameEngine from 'views/Quiz/GameEngine';
import { PlaylistDetailSC as T } from "../../PlaylistDetailSC";
import { useImgI18N } from 'services/ImgI18N';

const mapper = (s: ApplicationState) => ({
    currentGroup: s.currentGroup,
});

interface PlaylistQuizDimmerProps {
    id: string;
    item: QuizDownloadDto;
    link: string;
    result: DueInfo;
    playlistId: string;
    state: PlaylistItemStateDownloadDto;
    isOptional: boolean;
    transition: PlaylistEdgeTransitionFunction | undefined;
    elementIsReference: boolean;
    nodeIsDone: boolean;
    warning: boolean;
    closeDimmer: () => void;
    playlistStateId: string;
}

const PlaylistQuizDimmer = (p: PlaylistQuizDimmerProps) => {
    const { currentGroup } = useAppSelector(mapper, shallowCompare);
    const { id, item, closeDimmer, playlistStateId } = p;
    const [data, setData] = React.useState<{ game: GameDownloadDto, quiz: QuizDownloadDto }>();
    const { t } = useImgI18N("general");

    React.useEffect(() => {
        if (id) {
            GameController.SearchGames({ filter: { ids: [id] } },
                (games) => {
                    if (games.length > 0) {
                        const game = games[0];
                        if (currentGroup) {
                            QuizController.GetSingle({ id: game.id },
                                (quiz) => {
                                    setData({ game, quiz });
                                },
                                (error) => { console.log("Could not fetch game", error); });
                        }
                    }
                    else {
                        const getData = async () => {
                            if (id && currentGroup) {
                                const [game, quiz] = await ControllerHelper
                                    .addCall({ gameRequest: { quizId: id, gameMode: "Single", context: playlistStateId } }, GameController.CreateGame, true)
                                    .addCall({ id }, QuizController.GetSingle, true)
                                    .execute<GameDownloadDto, QuizDownloadDto>();
                                setData({ game, quiz });
                            }
                        };
                        getData();
                    }
                }
            );
        }
        else {
            setData(undefined);
        }
    }, [id, currentGroup, playlistStateId]);


    return (
        <Dimmer active >
            <T.DimmerContainerQuiz>
                {(data && data.game !== undefined) ?
                    <>
                        <GameEngine
                            game={data.game}
                            quiz={item}
                            closeModal={closeDimmer}
                            isPpeQuiz
                            isFromPlaylist={true}
                        />
                        {/* <T.DimmerInfoContainerQuizMarkbutton>
                            <MarkAsReadButton
                                playlistId={playlistId}
                                item={item}
                                isFinished={state.state === "Finished"}
                            />
                        </T.DimmerInfoContainerQuizMarkbutton> */}
                    </>
                    :
                    <Loader active size='small' onClick={closeDimmer} infoText={t("loading")} infoColor='#fff' />
                }
                <T.DimmerInfoContainerClose onClick={closeDimmer} >
                    <Icon name="times" size={27} marginTop={3} />
                </T.DimmerInfoContainerClose>
            </T.DimmerContainerQuiz>
        </Dimmer>
    );
}
export default PlaylistQuizDimmer;