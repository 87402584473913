import { belowBreakpointOrEqual, Divider, fadeColor, getColor, styled } from 'imaginarity-react-ui';
import * as React from 'react';
import { AppEntry } from 'services/ApplicationState/MasterLayoutRouter';
import { moduleBackgrounds } from 'services/Config';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';

export const FallbackAppEntry: AppEntry = {
    contentRenderer: () => <FallbackElement />,
    icon: "user",
    name: "fallback",
    title: "general::fallback",
    route: "fallback",
    noLogin: true,
    hidden: true,
    left: {
        hidden: true,
    },
    right: {
        hidden: true,
    }
}

const Container = styled.div<{ backgroundUrl: string, backgroundSize: string, backgroundPosition: string }>`
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100vw;
    max-width: 1920px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    background-image: url('${p => p.backgroundUrl}');
    background-repeat: no-repeat;
    background-position: ${p => p.backgroundPosition};
    background-size: ${p => p.backgroundSize};
    z-index: 2147483637;
`;
const Info = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: ${p => belowBreakpointOrEqual(p, "mobile") ? "100%" : "320px"};
    font-size: ${p => belowBreakpointOrEqual(p, "mobile") ? 1.1 : 1.1}rem;
    line-height: ${p => belowBreakpointOrEqual(p, "mobile") ? 1.4 : 1.4}rem;
    height: 100%;
    color: ${p => getColor(p, "@darkerGrey")};
    padding: 40px;
    background-image: linear-gradient(${p => getColor(p, "@mainBackground")}, ${p => fadeColor(p, getColor(p, "@veryLightGrey"), 70)});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
`;


interface FallbackProps extends ImgI18NTranslatedComponentProps {
}
const Fallback = (p: FallbackProps) => {

    const backgroundUrl = moduleBackgrounds.fallBackBackground;
    const backgroundSize = "cover";
    const backgroundPosition = "center center";
    const rl = () => {
        setTimeout(() => {
            console.log("reload...");
            window.location.href = window.location.href.split('#')[0];
            rl();
        }, 5000);
    };
    rl();
    return (
        <Container backgroundUrl={backgroundUrl} backgroundSize={backgroundSize} backgroundPosition={backgroundPosition}>
            <Info>
                {p.t("this page is currently in maintenance mode, please be patient and come back later. thank you!")}
                <Divider color="transparent" />
            </Info>
        </Container>
    );
}
const FallbackElement = translate("fallback")(Fallback);