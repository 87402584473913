import { ControllerHelper, EventController, EventUploadDto, PostController, TubePostDownloadDto } from 'collaboration-service';
import { Loader, LoaderInline, styled } from 'imaginarity-react-ui';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import { getTranslatedForTube } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import PodcastContent from 'views/Podcast/PodcastContent';
import TubeContent from 'views/Tube/TubeContent';


const TubeVideoContainer = styled.div`
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        video, embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
    }
`;



const mapper = (state: ApplicationState) => ({
    params: state.params,
    contentLanguage: state.contentLanguage,
    focusTarget: state.tubeState.focusTarget,
    currentCategoryStack: state.tubeState.currentCategoryStack,
    quality: state.tubeState.quality,
});
export interface PreviewTubePodcastProps {
    playlistStateId?: string;
    id: string;
    isPodcast?: boolean;
}


const PreviewTubePodcast = (p: PreviewTubePodcastProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currentPost, setCurrentPost] = React.useState<TubePostDownloadDto>();
    const { t } = useImgI18N("tube");
    const { contentLanguage, quality } = useSelector(mapper, shallowCompare);

    const context = p.playlistStateId;

    React.useEffect(() => {
        const id = p.id;
        const loadPost = async () => {
            if (!id) {
                setCurrentPost(undefined);
                return;
            }
            setLoading(true);
            const post = context ?
                await ControllerHelper.singleCall({ id, context }, PostController.GetSinglePostWithContext) as TubePostDownloadDto :
                await ControllerHelper.singleCall({ id }, PostController.GetSinglePost) as TubePostDownloadDto;
            setCurrentPost(post);
            setLoading(false);
        }
        if (id !== currentPost?.id)
            loadPost();

    }, [currentPost, context, p.id]);

    const translatedContent = React.useMemo(() => {
        const translatedContent = getTranslatedForTube(currentPost?.content, contentLanguage, [quality, "HD", "SD", "4K", "", undefined]);
        return translatedContent;
    }, [currentPost, contentLanguage, quality])

    const finishedVideo = React.useMemo(() => () => {
        if (currentPost) {
            const ev: EventUploadDto = {
                eventType: "Finished",
                group_id: currentPost.group_id,
                payload: {
                    "PostId": currentPost.id,
                }
            };
            ControllerHelper.singleCall({ ev, createRestriction: { checkGroupId: true, checkPayload: ["PostId"], checkUserId: true, maxAgeInSeconds: 10 } }, EventController.PostEventWithRestriction);
        }
    }, [currentPost])

    React.useEffect(() => {
        if (!translatedContent.useContent)
            finishedVideo();
    }, [translatedContent, finishedVideo])


    const update = React.useMemo(() => ({ percentage: 90, callback: finishedVideo }), [finishedVideo]);

    if (!currentPost)
        return <Loader active size="small" infoText={t("loading")} light />;

    return (
        <>
            {loading ?
                <LoaderInline
                    infoText="loading"
                    animationColor={"@accent"}
                    backgroundColor={"rgba(0,0,0,0.1)"}
                    textColor={"@lightGrey"}
                    active
                />
                :
                <TubeVideoContainer>
                    <div style={{ height: 0, marginTop: -10 }} />
                    {p.isPodcast ?
                        <PodcastContent
                            src={getMediaLink(translatedContent, c => c.content) ?? ""}
                            namespace="podcast"
                            onTimeUpdate={update}
                            currentPost={currentPost}
                            mobileView={false}
                        />
                        :
                        <TubeContent
                            translatedContent={translatedContent}
                            namespace="tube"
                            onTimeUpdate={update}
                            noAutoplay
                        />
                    }
                </TubeVideoContainer>
            }
        </>
    );



}

export default PreviewTubePodcast; 