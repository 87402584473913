import { getColor, styled, keyframes, belowBreakpoint, belowBreakpointOrEqual } from "imaginarity-react-ui";



export class TabsBarSC {

    public static flyToRight = keyframes`
    from { opacity:1; transform: translateX(-100%); }
    to { opacity:1; transform: translateX(0); }
`;
    public static flyToLeft = keyframes`
    from { opacity:1; transform: translateX(100%); }
    to { opacity:1; transform: translateX(0); }
`;
    public static flyToBottom = keyframes`
    from { opacity:0; transform: translateY(100%) scaleY(0); }
    to { opacity:1; transform: translateX(0) scaleY(1);}
`;
    public static Container = styled.div<{ tabsLength: number, shadowed?: boolean }>`
    padding-top: ${p => p.shadowed ? 5 : 0}px;
    width: ${p => belowBreakpoint(p, "tablet") ? "calc(100% - 10px)" : "100%"};
    /* height: 40px; */
    display: grid;
    grid-template-columns: ${p => belowBreakpoint(p, "tablet") ? "max-content  1fr" : `repeat(${p.tabsLength}, max-content) 1fr`} ;
    grid-template-rows: max-content;
    background: ${p => getColor(p, p.theme?.tabsBar?.tabsBarBackgroundColor ?? "@veryLightGrey")};
    transition: all 0.2s ease-out;
    margin-left: ${p => belowBreakpoint(p, "tablet") ? 5 : 0}px;
    margin-right: ${p => belowBreakpoint(p, "tablet") ? 5 : 0}px;
    button>div{margin: 0;}
`;
    public static Tab = styled.div<{ active?: boolean, checked?: boolean, activeBold?: boolean, shadowed?: boolean }>`
    position: relative;
    line-height: 40px;
    padding: 0 15px;
    user-select: none;
    font-weight: ${p => (p.activeBold && p.active) ? "bolder" : "normal"};
    cursor: ${p => p.active === true ? "default" : "pointer"};
    color: ${p => p.active === true ? getColor(p, p.theme?.tabsBar?.fontColorActive ?? "@darkerGrey") : getColor(p, p.theme?.tabsBar?.fontColor ?? "@lightGrey")};
    background: ${p => p.active === true ? getColor(p, p.theme?.tabsBar?.tabBackgroundActive ?? "@mainBackground") : getColor(p, p.theme?.tabsBar?.tabBackground ?? "@mainBackground")};
    border-bottom: ${p => p.active ? 0 : 1}px solid ${p => p.active ? "transparent" : getColor(p, p.theme?.tabsBar?.tabsBorderColor ?? "@middleLightGrey")};
    border-top: ${p => p.active ? 1 : 0}px solid ${p => p.active ? getColor(p, p.theme?.tabsBar?.tabsBorderColor ?? "@middleLightGrey") : "transparent"};
    border-left: ${p => p.active ? 1 : 0}px solid ${p => p.active ? getColor(p, p.theme?.tabsBar?.tabsBorderColor ?? "@middleLightGrey") : "transparent"};
    border-right: ${p => p.active ? 1 : 0}px solid ${p => p.active ? getColor(p, p.theme?.tabsBar?.tabsBorderColor ?? "@middleLightGrey") : "transparent"};
    box-shadow: ${p => p.active && p.shadowed ? `0 -3px 4px -5px ${getColor(p, "@accentBlack")}` : "none"};
    font-size: ${p => belowBreakpointOrEqual(p, "tablet") ? 0.9 : 1}em;
    &:hover{
        color: ${p => p.checked === true ? getColor(p, p.theme.colors.accentGreen) : p.active === true ? getColor(p, p.theme?.tabsBar?.fontColorActive ?? "@darkerGrey") : getColor(p, p.theme?.tabsBar?.fontColorHover ?? "@accentBlack")};
    }
`;
    public static Rest = styled.div`
    border-bottom: 1px solid ${p => getColor(p, p.theme?.tabsBar?.tabsBorderColor ?? "@middleLightGrey")};
`;
    public static Underline = styled.div<{ hasIcon: boolean, direction: "bottom" | "right" | "left", checked?: boolean }>`
    position: absolute;
    left: ${p => p.hasIcon ? 41 : 13}px;
    right: 13px;
    height: 3px;
    background: ${p => p.checked === true ? getColor(p, p.theme.colors.accentGreen) : getColor(p, p.theme?.tabsBar?.underlineBarColor ?? "@accent")};
    top: 36px;
	animation: ${p => p.theme?.tabsBar?.underlineBarAnimated ? (p.direction === "bottom" ? this.flyToBottom : p.direction === "right" ? this.flyToRight : this.flyToLeft) : "none"} 0.7s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
`;
}