import { ControllerHelper, EventController, EventUploadDto, FileEntryDownloadDto, PostController, PostControllerTube } from 'collaboration-service';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { formatDistanceToNow, formatRelative } from 'date-fns';
import { Button, Image, Loader, LoaderInline, Theme, getLinkByName } from 'imaginarity-react-ui';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { bannerTop } from 'services/Config';
import { getLink, isChangedDateGreaterThanCreatedDateWithoutTime, shallowCompare } from 'services/Helpers';
import { getBrowser } from 'services/Helpers/BrowserHelper';
import ScrollHelper from 'services/Helpers/ScrollHelper';
import { getCMSContent, getTranslated, getTranslatedUserName } from 'services/Helpers/TranslationHelpers';
import History from 'services/History';
import { ImgI18N, useImgI18N } from 'services/ImgI18N';
import { useThemePart } from 'services/useAppTheme';
import { FileEntryPlaylistItemContainer, FileEntryPlaylistItemGrid, FileEntryPlaylistItemGridLeftDescription, FileEntryPlaylistItemGridLeftHeaderBar, FileEntryPlaylistItemInfoContainer } from 'views/Playlist/PlaylistItems/FileEntryPlaylistItem';
import { PlaylistSC } from 'views/Playlist/PlaylistSC';
import { TubeSC as T } from 'views/Tube/TubeSC';

const mapper = (state: ApplicationState) => ({
    params: state.params,
    contentLanguage: state.contentLanguage,
    focusTarget: state.tubeState.focusTarget,
});
export interface VideoFileEntryPlaylistViewProps {
}

export interface ImageFileEntryPlaylistViewState {
    relativeDate: boolean;
    showMoreInfo: boolean;
    loading: boolean;
}


const themePartGetter = (t: Theme) => ({ width: t.content.width, emptyImageUrl: t.emptyImageUrl, emptyAvatarImageUrl: t.emptyAvatarImageUrl });

const PDFFileEntryPlaylistView = (p: VideoFileEntryPlaylistViewProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [relativeDate, setRelativeDate] = React.useState<boolean>(false);
    const { t, currentLanguage } = useImgI18N("playlist");
    const { params, contentLanguage } = useSelector(mapper, shallowCompare);
    const language = contentLanguage ?? currentLanguage;
    const dispatch = useAppDispatch();
    const { width, emptyAvatarImageUrl } = useThemePart(themePartGetter);
    const [fileEntry, setFileEntry] = React.useState<FileEntryDownloadDto>();
    const [fEviews, setFEViews] = React.useState<number>(0);
    const [, setViewed] = React.useState<boolean>(false);

    const context = params?.context as string;
    const id = params?.id as string;
    const scrollHelper = React.useMemo(() => new ScrollHelper(), []);
    const scrollTo = React.useMemo(() => (id: string) => scrollHelper.scrollTo(id), [scrollHelper]);

    React.useEffect(() => {
        const loadPost = async () => {
            if (!id) {
                setFileEntry(undefined);
                return;
            }
            setLoading(true);
            const post = context ?
                await ControllerHelper.singleCall({ id, context }, PostController.GetSinglePostWithContext) as FileEntryDownloadDto :
                await ControllerHelper.singleCall({ id }, PostController.GetSinglePost) as FileEntryDownloadDto;
            setFileEntry(post);
            setLoading(false);
        }

        scrollHelper.scrollTo("top");
        if (id !== fileEntry?.id)
            loadPost();
        if (fileEntry)
            dispatch(Actions.setScrollTo(scrollTo));

    }, [scrollHelper, context, scrollTo, fileEntry, id, dispatch]);

    React.useEffect(() => {
        const loadData = async () => {
            if (!fileEntry)
                return;
            const id = fileEntry.id;
            const vs = await ControllerHelper
                .singleCall({ postIds: [id] }, PostControllerTube.GetPostsViews);

            //neighbours.sort(this.sortByDate);
            let views = 0;
            let viewed = false;
            if (vs && vs.length > 0 && vs[0].views) {
                views = vs[0].views;
                viewed = vs[0].viewed ?? false;
            }
            setFEViews(views);
            setViewed(viewed);
            setLoading(false);
            const ev: EventUploadDto = {
                eventType: "PostViewed",
                group_id: fileEntry.group_id,
                payload: { "PostId": fileEntry.id, browser: getBrowser() }
            };
            EventController.PostEvent({ ev });
        };
        loadData();
    }, [fileEntry])

    React.useEffect(() => {
        return () => {
            scrollHelper.scrollTo("top");
        }
    }, [dispatch, scrollHelper]);



    const getDateFnsLng = ImgI18N.getInstance().languageLocal();


    const { translatedContent, creationDate, creationDateRelative, changedDate, changedDateRelative, /*views,*/ avatar } = React.useMemo(() => {
        const translatedContent = getCMSContent({ fileEntry, filePosts: {}, language });
        const creationDate = formatDistanceToNow(fileEntry ? new Date(fileEntry.created as Date) : new Date(), { addSuffix: true, locale: getDateFnsLng });
        const creationDateRelative = formatRelative(fileEntry ? new Date(fileEntry.created as Date) : new Date(), new Date(), { locale: getDateFnsLng });
        const changedDate = formatDistanceToNow(fileEntry ? new Date(fileEntry.changed as Date) : new Date(), { addSuffix: true, locale: getDateFnsLng });
        const changedDateRelative = formatRelative(fileEntry ? new Date(fileEntry.changed as Date) : new Date(), new Date(), { locale: getDateFnsLng });
        const views = fEviews >= 1000 ? (Math.round(fEviews / 100) / 10) + "K" : fEviews;
        const avatar = getLink(fileEntry?.creator?.avatar?.links, "self")?.uri ?? emptyAvatarImageUrl;
        return {
            translatedContent,
            creationDate,
            creationDateRelative,
            changedDate,
            changedDateRelative,
            views,
            avatar,

        };
    }, [fileEntry, emptyAvatarImageUrl, getDateFnsLng, language, fEviews])

    const src = getLinkByName(translatedContent?.media, "self")?.uri ?? "";

    const toggleRelativeDate = () => {
        setRelativeDate(!relativeDate);
    }

    const goBack = () => {
        History.goBack();
    }

    if (!fileEntry)
        return <Loader active size="small" infoText={t("loading")} light />;


    const contentWidth = width ?? 400;
    const mobileView = contentWidth < 550;
    const showChangedDate = isChangedDateGreaterThanCreatedDateWithoutTime(fileEntry.created, fileEntry.changed);
    const banner = bannerTop.playlist;


    return (
        <>
            <div style={{ height: 0 }} ref={scrollHelper.getRef("top")} />
            <FileEntryPlaylistItemContainer>
                <PlaylistSC.BannerContainer imageUrl={banner} />

                {loading ?
                    <LoaderInline
                        infoText="loading"
                        animationColor={"@accent"}
                        backgroundColor={"rgba(0,0,0,0.1)"}
                        textColor={"@lightGrey"}
                        active
                    />
                    :
                    <FileEntryPlaylistItemGrid mobileView={mobileView} contentWidth={contentWidth} >
                        <FileEntryPlaylistItemGridLeftHeaderBar id="videoWidth">
                            <Button kind="fullTransparentButton" icon='image' disabled iconColor='color' />
                            <SafeHTML html={getTranslated(fileEntry.headlines).text} allowedTags={[]} allowedAttributes={{}} />
                            <Button
                                kind="transparentButton"
                                icon="chevron left"
                                floated="right"
                                onClick={goBack}
                                content={t("back")}
                            />
                        </FileEntryPlaylistItemGridLeftHeaderBar>

                        <FileEntryPlaylistItemContainer style={{ border: "1px solid black" }}>
                            <div style={{ height: 0, marginTop: -10 }} ref={scrollHelper.getRef("video")} />
                            <embed src={src} />
                        </FileEntryPlaylistItemContainer>


                        <FileEntryPlaylistItemInfoContainer mobileView={mobileView}>
                            <div style={{ height: 0, marginTop: -10 }} ref={scrollHelper.getRef("info")} />
                            <T.TubeVideoGridLeftUserAvatar>
                                <Image src={avatar} rounded width="40px" />
                            </T.TubeVideoGridLeftUserAvatar>
                            <T.TubeVideoGridLeftUserName>
                                <T.TubeVideoGridLeftUserNameName>
                                    {getTranslatedUserName(fileEntry.creator)}
                                </T.TubeVideoGridLeftUserNameName>
                                <T.TubeVideoGridLeftUserNameDate onClick={toggleRelativeDate}>
                                    {relativeDate ? creationDateRelative : creationDate}
                                    {showChangedDate &&
                                        <T.TubeVideoGridLeftUserChangedBy>
                                            {t("changed by {{name}} on {{date}}", { name: getTranslatedUserName(fileEntry.changedBy), date: relativeDate ? changedDateRelative : changedDate })}
                                        </T.TubeVideoGridLeftUserChangedBy>
                                    }
                                </T.TubeVideoGridLeftUserNameDate>
                            </T.TubeVideoGridLeftUserName>
                            <T.TubeVideoGridLeftUserRating style={{ paddingTop: 2 }}>
                                {/* <Tooltip tooltipText={fileEntry.ratingCount === 0 ? t("this video has not been rated yet") : t("this video has already been rated {{count}} times", { count: fileEntry.ratingCount })} position='top'>
                                    <RatingView maxStars={5} rating={fileEntry.rating} size={20} />
                                </Tooltip> */}
                            </T.TubeVideoGridLeftUserRating>
                        </FileEntryPlaylistItemInfoContainer>


                        <FileEntryPlaylistItemGridLeftDescription mobileView={mobileView}>
                            {!mobileView &&
                                <T.TubeVideoGridLeftDescriptionTitle>{t("description:")}</T.TubeVideoGridLeftDescriptionTitle>
                            }
                            <T.TubeVideoGridLeftDescriptionDescription mobileView={mobileView} style={{ marginBottom: 10 }}>
                                <SafeHTML html={getTranslated(fileEntry.descriptions, contentLanguage)?.text ?? ""} />
                            </T.TubeVideoGridLeftDescriptionDescription>

                            {/* <TubeVideoInfo mobileView={mobileView} post={fileEntry} /> */}
                        </FileEntryPlaylistItemGridLeftDescription>
                    </FileEntryPlaylistItemGrid>
                }
            </FileEntryPlaylistItemContainer>
        </>
    );



}

export default PDFFileEntryPlaylistView; 