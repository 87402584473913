import { MarkInPictureQuestionDownloadDto } from 'collaboration-service';
import * as React from 'react';
import { QuizSC as T } from '../QuizSC';
import ImageWithOverlay from './ImageWithOverlay';
import { QuestionImplementationProps } from './Question';
class MarkInPictureQuestion extends React.Component<QuestionImplementationProps> {

    public render() {
        const data = this.props.question as MarkInPictureQuestionDownloadDto;
        return (
            <>
                <T.Image>
                    {(data.media?.links?.length ?? 0) > 0 &&
                        <ImageWithOverlay {...this.props} />
                    }
                </T.Image>
                <T.Functions>

                </T.Functions>
            </>
        );
    }

}

export default MarkInPictureQuestion;