import { styled } from "imaginarity-react-ui";
import * as React from "react";
import { createPortal } from "react-dom";

export interface PortalEdges {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
}
interface usePortalProps {
    calcPos?: (rect: DOMRect) => PortalEdges;
    portalId?: string;
}

export const Container = styled.div<PortalEdges>`
        position: fixed;
        left: ${p => p.left ? `${p.left}px` : "unset"};
        top: ${p => p.top ? `${p.top}px` : "unset"};
        bottom: ${p => p.bottom ? `${p.bottom}px` : "unset"};
        right: ${p => p.right ? `${p.right}px` : "unset"};
        z-index: 2147483635;
    `;

const stdCalcPos = (r: DOMRect) => ({ left: r.left + r.width, top: r.top + 40 });

const usePortal = (p: usePortalProps) => {
    const { calcPos, portalId } = p;
    const [instance, setInstance] = React.useState<HTMLElement | null>();

    const main = React.useMemo(() => document.getElementById(portalId ?? "root"), [portalId]);
    const cp = calcPos ? calcPos : stdCalcPos;

    const renderPortal = React.useMemo(() => (children: JSX.Element) => {
        if (instance && main) {
            const p = cp(instance.getClientRects()[0])
            return createPortal(
                <Container {...p}>
                    {children}
                </Container>
                , main)
        }
        return <div />
    }, [instance, main, cp]);

    const setPortalRef = React.useMemo(() => (instance: HTMLElement | null) => {
        setInstance(instance);
    }, []);

    return {
        renderPortal,
        setPortalRef,
        setInstance,
    }
}

export default usePortal;