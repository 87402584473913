import { Icon } from "imaginarity-react-ui";
import _ from "lodash";
import * as React from 'react';
import { Table as TableResponsive, Tbody, Td, Th, Tr } from 'react-super-responsive-table';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { Httpsmailto, PPElink } from "./EditorBlots";

export interface EditorToolbarHelpProps extends ImgI18NTranslatedComponentProps {
    formats?: string[];
}

class EditorToolbarHelp extends React.PureComponent<EditorToolbarHelpProps> {

    public render() {
        return (
            <TableResponsive cellSpacing={0}>
                <Tbody>
                    <Tr>
                        <Th colSpan={2}><b>{this.props.t("type")}</b></Th>
                        <Th><b>{this.props.t("description")}</b></Th>
                    </Tr>
                    {_.find(this.props.formats, f => f === '#') &&
                        <Tr>
                            <Td width={30}><Icon name="hashtag" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                            <Td width={150}>{this.props.t("tag word")}</Td>
                            <Td>{this.props.t("type the # sign on your keyboard, and write the tag. either select an existing tag or create a new one. confirm your entry with return.")} </Td>
                        </Tr>
                    }
                    {_.find(this.props.formats, f => f === '@') &&
                        <Tr>
                            <Td className="darkline"><Icon name="at sign" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                            <Td className="darkline">{this.props.t("mention user")}</Td>
                            <Td className="darkline">{this.props.t("type the @ sign on your keyboard, and write the name of the user you want to mention. Choose the name from the list of suggestions.")} </Td>
                        </Tr>
                    }
                    {_.find(this.props.formats, f => f === 'header') &&
                        <Tr>
                            <Td><Icon name="editor text size" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                            <Td>{this.props.t("font size")}</Td>
                            <Td>{this.props.t("the font sizes always refer to a whole paragraph")} </Td>
                        </Tr>
                    }
                    {_.find(this.props.formats, f => f === 'list') &&
                        <Tr>
                            <Td><Icon name="editor list bullet" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                            <Td>{this.props.t("bullet list")}</Td>
                            <Td>{this.props.t("the bullet list points always refer to a whole paragraph")} </Td>
                        </Tr>
                    }
                    {_.find(this.props.formats, f => f === 'list') &&
                        <Tr>
                            <Td><Icon name="editor list ordered" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                            <Td>{this.props.t("ordered list")}</Td>
                            <Td>{this.props.t("the ordered list points always refer to a whole paragraph")} </Td>
                        </Tr>
                    }
                    {_.find(this.props.formats, f => f === 'bold') &&
                        <Tr>
                            <Td><Icon name="editor text bold" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                            <Td>{this.props.t("bold text")}</Td>
                            <Td>{this.props.t("to display a word or text passage in bold, select the corresponding area and click the button.")} </Td>
                        </Tr>
                    }
                    {_.find(this.props.formats, f => f === 'italic') &&
                        <Tr>
                            <Td><Icon name="editor text italic" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                            <Td>{this.props.t("italic text")}</Td>
                            <Td>{this.props.t("to display a word or text passage in italic, select the corresponding area and click the button.")} </Td>
                        </Tr>
                    }
                    {_.find(this.props.formats, f => f === 'underline') &&
                        <Tr>
                            <Td><Icon name="editor text underline" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                            <Td>{this.props.t("underlined text")}</Td>
                            <Td>{this.props.t("to display an underlined word or text passage, select the corresponding area and click the button.")} </Td>
                        </Tr>
                    }
                    {_.find(this.props.formats, f => f === 'color') &&
                        <Tr>
                            <Td><Icon name="editor text color" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                            <Td>{this.props.t("colored text")}</Td>
                            <Td>{this.props.t("to display a colored word or text passage, select the corresponding area, click the button and choose the text color you want.")} </Td>
                        </Tr>
                    }
                    {_.find(this.props.formats, f => f === 'background') &&
                        <Tr>
                            <Td><Icon name="editor text background" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                            <Td>{this.props.t("background color")}</Td>
                            <Td>{this.props.t("to display a word or text passage with background color, select the corresponding area, click the button and choose the text background color you want.")} </Td>
                        </Tr>
                    }
                    {_.find(this.props.formats, f => f === 'emoji') &&
                        <Tr>
                            <Td><Icon name="smiley smile" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                            <Td>{this.props.t("smiley")}</Td>
                            <Td>{this.props.t("to insert an emoji, place the cursor at the position and click on the smiley symbol and select the desired emoji")} </Td>
                        </Tr>
                    }
                    {_.find(this.props.formats, f => f === Httpsmailto) &&
                        <Tr>
                            <Td><Icon name="link external" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                            <Td>{this.props.t("link")}</Td>
                            <Td>{this.props.t("to include an external link in the text, select the area to be linked, click on the link button and enter the web address (with https://) in the dialogue that opens. confirm by clicking on the Save button.")} </Td>
                        </Tr>
                    }
                    {_.find(this.props.formats, f => f === PPElink) &&
                        <Tr>
                            <Td className="darkline"><Icon name="link internal" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                            <Td className="darkline">{this.props.t("ppe link")}</Td>
                            <Td className="darkline">{this.props.t("to include a ppe link in the text, select the area to be linked, click on the ppe link button and first select the ppe category / subcategory in the window that opens. now click on the article you want to link to.")} </Td>
                        </Tr>
                    }
                    <Tr>
                        <Td><Icon name="questionark" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                        <Td>{this.props.t("help")}</Td>
                        <Td>{this.props.t("help page for the editor (this window)")} </Td>
                    </Tr>
                    <Tr>
                        <Td><Icon name="eye open" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                        <Td>{this.props.t("preview")}</Td>
                        <Td>{this.props.t("actual preview of your text.")} </Td>
                    </Tr>
                    <Tr>
                        <Td><Icon name="expand" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                        <Td>{this.props.t("expand view")}</Td>
                        <Td>{this.props.t("if you have a long text, you can enlarge the view area so that you see the text in full length.")} </Td>
                    </Tr>
                    <Tr>
                        <Td className="darkline"><Icon name="decrease" style={{ float: "left" }} color="#000" marginTop={0} /></Td>
                        <Td className="darkline">{this.props.t("decrease view")}</Td>
                        <Td className="darkline">{this.props.t("set the height of the editor window back to the normal height.")} </Td>
                    </Tr>
                </Tbody>
            </TableResponsive>
        );
    }
}
export default translate("editor")(EditorToolbarHelp);