import { Icon, LottieIcon, Theme, belowBreakpoint, useThemePart } from 'imaginarity-react-ui';
import React from 'react';
import { ThemeContext } from 'styled-components';
import { podcastTheme } from './PodcastMain';
import { PodcastSC as T } from './PodcastSC';
import { useImgI18N } from 'services/ImgI18N';

function usePersistentAudioState() {
    const [progress, setProgress] = React.useState(0);
    const [currentTime, setCurrentTime] = React.useState(0);
    return { progress, setProgress, currentTime, setCurrentTime };
}

export const formatPodcastTime = (time: number) => {
    if (time < 0) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
interface PodcastPlayerProps {
    src: string;
    imgUrl?: string;
    onEnded?: () => void;
    onTimeUpdate?: { percentage: number, callback: () => void };
    stopAnimations?: boolean;
    mini?: boolean;
}

const PodcastPlayer = React.memo((p: PodcastPlayerProps) => {
    const { progress, setProgress, currentTime, setCurrentTime } = usePersistentAudioState();
    const { src, imgUrl, onEnded, onTimeUpdate, stopAnimations } = p;
    const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
    const [showRateInfo, setShowRateInfo] = React.useState<boolean>(false);
    const [showVolumeInfo, setShowVolumeInfo] = React.useState<boolean>(false);
    const [duration, setDuration] = React.useState<number>(0);
    const [volume, setVolume] = React.useState<number>(1);
    const [playbackRate, setPlaybackRate] = React.useState<number>(1);
    const audioRef = React.useRef<HTMLAudioElement | null>(null);
    const theme = React.useContext<Theme>(ThemeContext);
    const isMobile = belowBreakpoint({ theme }, "tablet");
    const [, setCallOnTimeUpdate] = React.useState<boolean>(false);
    const cWidth = useThemePart(t => t.content.width);
    const { t } = useImgI18N("podcast");
    const mini = p.mini ?? isMobile;

    React.useEffect(() => {
        const handleStopAudio = () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                setIsPlaying(false);
            }
        };

        window.addEventListener('stopAllAudio', handleStopAudio);

        return () => {
            window.removeEventListener('stopAllAudio', handleStopAudio);
        };
    }, []);



    React.useEffect(() => {
        const resetAndPrepareAudio = () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                setIsPlaying(false);
                setProgress(0);
                setCurrentTime(0);
            }
        };
        resetAndPrepareAudio();
        const audio = new Audio(src);
        audioRef.current = audio;
        const setAudioData = () => {
            setDuration(audio.duration);
            setCurrentTime(audio.currentTime);
        };


        const updateProgress = () => {
            setCurrentTime(audio.currentTime);
            const calculatedProgress = (audio.currentTime / audio.duration) * 100 || 0;
            setProgress(calculatedProgress);
            if (calculatedProgress === 100 && onEnded) {
                onEnded();
            }

            if (onTimeUpdate && audio.duration) {
                const p = 100 * audio.currentTime / audio.duration;
                if (onTimeUpdate?.percentage <= p)
                    setCallOnTimeUpdate(t => {
                        if (!t)
                            onTimeUpdate.callback();
                        return true;
                    });
            }
        };

        const handleAudioEnded = () => {
            setIsPlaying(false);
            if (onEnded) {
                onEnded();
            }
        };


        audio.addEventListener('loadedmetadata', setAudioData);
        audio.addEventListener('timeupdate', updateProgress);
        audio.addEventListener('ended', handleAudioEnded);

        return () => {
            audio.removeEventListener('loadedmetadata', setAudioData);
            audio.removeEventListener('timeupdate', updateProgress);
            audio.removeEventListener('ended', handleAudioEnded);

            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                setIsPlaying(false);
            }

        };
    }, [src, onEnded, onTimeUpdate, setCurrentTime, setProgress]);

    React.useEffect(() => {
        const audio = audioRef.current;
        if (audio) {
            if (isPlaying) {
                audio.play().catch((error) => console.error("Error playing audio:", error));
            } else {
                audio.pause();
            }
        }
    }, [isPlaying]);

    React.useEffect(() => {
        const volumeRangeElement = document.getElementById('volumeRange') as HTMLInputElement;
        const handleWheel = (e: WheelEvent) => {
            e.preventDefault();
            const direction = e.deltaY < 0 ? 0.05 : -0.05;
            let newVolume = Math.min(Math.max(0, volume + direction), 1);
            setVolume(newVolume);
        };
        if (volumeRangeElement) {
            volumeRangeElement.addEventListener('wheel', handleWheel, { passive: false });
        }
        if (audioRef.current) {
            audioRef.current.volume = volume;
        }
        return () => {
            if (volumeRangeElement) {
                volumeRangeElement.removeEventListener('wheel', handleWheel);
            }
        };
    }, [volume]);

    React.useEffect(() => {
        const handleWheel = (e: WheelEvent) => {
            e.preventDefault();
            const direction = e.deltaY < 0 ? 0.1 : -0.1;
            let newPlaybackRate = Math.min(Math.max(0.2, playbackRate + direction), 2.5);
            setPlaybackRate(newPlaybackRate);
        };
        const speedRangeElement = document.getElementById('speedRange');
        if (speedRangeElement) {
            speedRangeElement.addEventListener('wheel', handleWheel, { passive: false });
        }
        if (audioRef.current) {
            audioRef.current.playbackRate = playbackRate;
        }
        return () => {
            if (speedRangeElement) {
                speedRangeElement.removeEventListener('wheel', handleWheel);
            }
        };
    }, [playbackRate, setPlaybackRate]);

    const skipTime = (seconds: number) => {
        const newTime = Math.min(Math.max(0, currentTime + seconds), duration);
        if (audioRef.current) {
            audioRef.current.currentTime = newTime;
        }
    };
    const getSkipDuration = () => {
        if (duration > 300) return 60;
        if (duration > 60) return 15;
        if (duration > 15) return 5;
        return 0;
    };
    const showSkipButtons = duration > 15;
    const skipDuration = getSkipDuration();

    const handleProgressClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
        const rect = target.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        if (audioRef.current) {
            const newTime = (offsetX / target.offsetWidth) * audioRef.current.duration;
            audioRef.current.currentTime = newTime;
        }
    };

    const togglePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    React.useEffect(() => {
        setCallOnTimeUpdate(false);
    }, [onTimeUpdate, src])

    const altBgImage = React.useMemo(() => {
        return imgUrl ?? podcastTheme.momentsAudioBackgroundImage;
    }, [imgUrl]);

    return (
        <div>
            <T.PCOuterContainer bgUrl={isPlaying && p.mini ? "" : altBgImage} mini={mini ?? false}>
                {isPlaying && p.mini && <T.PCOuterContainerBG bgUrl={podcastTheme.headerRightImage.url} />}
                {(stopAnimations === false || p.mini) &&
                    <T.WaveContainer mini={mini ?? false}>
                        <LottieIcon
                            loop
                            url={p.mini ? "./images/lottiIcons/playingSoundRed.json" : (isMobile ? "./images/lottiIcons/playingSoundCyan.json" : "./images/lottiIcons/wave.json")}
                            autoplay
                            speed={mini ? 1 : 0.1}
                            direction={mini ? 1 : -1}
                            height={isPlaying ? mini ? "100%" : undefined : 0.1}
                        />
                    </T.WaveContainer>
                }
                {!mini &&
                    <T.IconContainer playing={isPlaying} onClick={togglePlayPause}>
                        <Icon name={isPlaying ? "play circle" : "pause circle"} size={"15vw"} />
                    </T.IconContainer>
                }
                <T.PodcastContainer mini={mini ?? false} style={{ bottom: mini ? 5 : 0 }}>
                    {showSkipButtons &&
                        <T.PCButton onClick={() => skipTime(-skipDuration)} xOffset={isMobile ? -60 : -80} scale={0.8} mini={mini ?? false} style={{ background: isPlaying ? "rgba(0,0,0,0.4)" : "rgba(255,255,255,0.08)" }}>
                            {t(`- ${skipDuration}s`)}
                        </T.PCButton>
                    }
                    <T.PCButton onClick={togglePlayPause} xOffset={0} scale={1} mini={mini ?? false} style={{ background: isPlaying ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.12)" }}>
                        <T.PlaybuttonText isPlaying={isPlaying} mini={p.mini ?? false}>
                            {isPlaying ? t("pause") : t("play")}
                        </T.PlaybuttonText>
                    </T.PCButton>

                    {showSkipButtons &&

                        <T.PCButton onClick={() => skipTime(skipDuration)} xOffset={isMobile ? 60 : 80} scale={0.8} mini={mini ?? false} style={{ background: isPlaying ? "rgba(0,0,0,0.4)" : "rgba(255,255,255,0.12)" }}>
                            {t(`+ ${skipDuration}s`)}
                        </T.PCButton>
                    }
                    {!mini &&
                        <T.PCRange pos="left" style={{ width: isMobile ? 80 : "17%" }} mini={mini ?? false} onMouseEnter={() => setShowRateInfo(true)} onMouseLeave={() => setShowRateInfo(false)}
                            onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
                                e.preventDefault();
                                const direction = e.deltaY < 0 ? 0.1 : -0.1;
                                let newPlaybackRate = Math.min(Math.max(0.2, playbackRate + direction), 2.5);
                                setPlaybackRate(newPlaybackRate);
                            }}
                        >
                            {!mini &&
                                <T.RangeTitleInfo pos='right' showRateInfo={showRateInfo}>
                                    {showRateInfo ?
                                        <>{playbackRate === 1 ? t("change playback speed") : t("double-click slider to reset")}</>
                                        :
                                        t("speed {{percent}}%", { percent: Math.round(playbackRate * 100) })
                                    }
                                </T.RangeTitleInfo>
                            }
                            <input id="speedRange" type="range" min="0.2" max="2.5" step="0.1" value={playbackRate} onChange={e => setPlaybackRate(parseFloat(e.target.value))} onDoubleClick={() => setPlaybackRate(1)} style={{ zIndex: 2 }} />
                        </T.PCRange>
                    }
                    {!mini &&
                        <T.PCRange pos="right" style={{ width: isMobile ? 80 : "17%" }} mini={mini ?? false} onMouseEnter={() => setShowVolumeInfo(true)} onMouseLeave={() => setShowVolumeInfo(false)}
                            onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
                                e.preventDefault();
                                const direction = e.deltaY < 0 ? 0.05 : -0.05;
                                let newVolume = Math.min(Math.max(0, volume + direction), 1);
                                setVolume(newVolume);
                            }}
                        >
                            {!mini &&
                                <T.RangeTitleInfo pos='left' showRateInfo={showVolumeInfo}>
                                    {showVolumeInfo ?
                                        <>{volume === 1 ? t("double-click slider to mute") : t("double-click slider for 100%")}</>
                                        :
                                        t("volume {{percent}}%", { percent: Math.round(volume * 100) })
                                    }
                                </T.RangeTitleInfo>
                            }
                            <input
                                id="volumeRange"
                                type="range"
                                min="0"
                                max="1"
                                step="0.05"
                                value={volume}
                                onChange={e => setVolume(parseFloat(e.target.value))}
                                onDoubleClick={() => setVolume(volume === 1 ? 0 : 1)}
                                style={{ zIndex: 2 }}
                            />
                        </T.PCRange>
                    }
                    <T.ProgressContainer mini={mini ?? false}>
                        <T.Progress style={{ marginTop: mini ? 22 : 0 }} className={p.mini ? "isMini" : undefined}>
                            <T.ProgressLine onClick={handleProgressClick} />
                            <T.ProgressBar width={progress} mini={p.mini ?? false} style={{ width: progress + "%" }} />

                            {(cWidth * progress / 100) > 15 && (stopAnimations === false || p.mini) &&
                                <T.ProgressDot left={progress} isPlaying={stopAnimations === false && isPlaying} mini={p.mini ?? false}>
                                    {!p.mini &&
                                        <LottieIcon
                                            loop
                                            url="./images/lottiIcons/sound.json"
                                            autoplay
                                            width={isPlaying ? 30 : 0.1}
                                            height={isPlaying ? 30 : 0.1}
                                            speed={0.5}
                                        />
                                    }
                                </T.ProgressDot>
                            }
                        </T.Progress>
                        <T.TimeDisplay pos='left' style={{ top: mini ? 70 : 50 }}>
                            {formatPodcastTime(currentTime)}
                        </T.TimeDisplay>
                        <T.TimeDisplay pos='right' style={{ top: mini ? 70 : 50 }}>
                            -{formatPodcastTime(duration - currentTime)}
                        </T.TimeDisplay>
                    </T.ProgressContainer>
                    {!mini &&
                        <T.RateFigure shown={showRateInfo}>{t("playback rate")}<br /><span><b>{Math.round(playbackRate * 100)}%</b></span></T.RateFigure>
                    }
                    {!mini &&
                        <T.RateFigure shown={showVolumeInfo}>
                            {t("volume")}<br /><span><b>{Math.round(volume * 100)}%</b></span>
                        </T.RateFigure>
                    }
                </T.PodcastContainer>
            </T.PCOuterContainer>
        </div >
    );
});

export default PodcastPlayer;