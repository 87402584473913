import { getColor, styled } from 'imaginarity-react-ui';

export namespace DateTimeFnsSC {
    export const Grid = styled.div<{ fluid: boolean, textColor?: string, separator: boolean, noTime: boolean }>`
        width: ${p => p.fluid ? "100%" : "auto"};
        display: grid;
        grid-template-columns: ${p => p.fluid ? "1fr" : "max-content"} ${p => p.noTime ? 0 : "max-content"};
        column-gap: ${p => (p.fluid || p.separator ? "0" : "5px")};
`;
    export const GridDate = styled.div<{ textColor?: string }>`
        color: ${p =>
            p.textColor ? getColor(p, p.textColor) : getColor(p, "@mainForeground")};
`;
    export const GridTime = styled.div<{ textColor?: string }>`
        color: ${p =>
            p.textColor ? getColor(p, p.textColor) : getColor(p, "@mainForeground")};
`;
    export const Separator = styled.span`
        padding: 0 1px 0 2px;
`;
}