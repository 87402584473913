import { getColor, Icon } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { useBelowBreakpointOrEqual, useThemePart } from 'services/useAppTheme';
import styled from 'styled-components';

const Container = styled.div<{ figures: number, float?: "left" | "right" }>`
    display: grid;
    grid-template-columns: 24px repeat(${p => p.figures}, minmax(24px, max-content)) 24px;
    grid-template-rows: 36px;
    column-gap: 3px;
    user-select: none;
    ${p => p.float && `float: ${p.float};`}
`;
const Cell = styled.div<{ active?: boolean, disabled?: boolean }>`
    padding: 2px 0px 0px 0px;
    height: 36px;
    font-size: 1em;
    border-bottom: 3px solid ${p => p.active ? getColor(p, "@accent") : "transparent"};
    color: ${p => getColor(p, "@darkerGrey")};
    line-height: 36px;
    text-align: center;
    cursor: ${p => (p.active || p.disabled) ? "default" : "pointer"};
    &:hover{
        color: ${p => p.active ? getColor(p, "@darkerGrey") : getColor(p, "@accentRed")};
    }
`;
const CellIcon = styled.div<{ disabled?: boolean }>`
    padding: 2px 0px 0px 0px;
    height: 36px;
    font-size: 1em;
    border-bottom: 3px solid transparent;
    line-height: 36px;
    text-align: center;
    cursor: ${p => p.disabled ? "default" : "pointer"};
    svg{
        stroke: ${p => p.disabled ? getColor(p, "@middleLightGrey") : getColor(p, "@darkerGrey")};
        //transform: scale(1.2);
    }
    &:hover{
        svg{
            stroke: ${p => p.disabled ? getColor(p, "@middleLightGrey") : getColor(p, "@accent")};
        }
    }
`;

interface PaginationProps {
    count: number;
    initialIndex: number;
    paginate?: (index: number) => void;
    float?: "left" | "right";
    onClick?: () => void;   // will NOT be called on disabled buttons or current position
}

const Pagination = (p: PaginationProps) => {
    const { count, initialIndex, paginate, float, onClick: click } = p;
    const [array, setArray] = React.useState<number[]>([]);
    const [long, setLong] = React.useState<boolean>(true);
    const [index, setIndex] = React.useState<number>(initialIndex);
    const isMobile = useBelowBreakpointOrEqual("tablet");
    const pagination = useThemePart(t => t.pagination);
    const calcRange = isMobile ? (pagination?.mobileRange ?? 5) : (pagination?.desktopRange ?? 7);   // 5 cells or 7 cells
    const range = calcRange <= 4 ? 3 : calcRange as number;
    const [figures, setFigures] = React.useState<number>(range);
    const low = range - 2;
    const max = count - low;
    const minimizedPagination = range === 3;

    React.useEffect(() => {
        if (paginate)
            paginate(index);
    }, [paginate, index]);

    React.useEffect(() => {
        if (count < 1) {
            setFigures(1);
            setLong(false);
        }
        else if (count <= range) {
            setFigures(count);
            setLong(false);
        }
        else {
            setFigures(range);
            setLong(true);
        }
    }, [count, range]);

    React.useEffect(() => {
        let arr = new Array<number>();
        arr.push(1);
        if (count > 0) {
            for (let i = 1; i < count; i++) {
                arr.push(i + 1);
            }
        }
        setArray(arr);
    }, [count]);

    const onClick = React.useMemo(() => (ind: number) => {
        setIndex(ind - 1);
        if (click && (ind - 1 !== index))
            click();
    }, [setIndex, click, index]);

    const onClickNext = React.useMemo(() => () => {
        if (index < count - 1)
            setIndex(index + 1);
        if (click && !(index + 1 >= count))
            click();
    }, [index, count, click]);

    const onClickPrevious = React.useMemo(() => () => {
        if (index > 0)
            setIndex(index - 1);
        if (click && index !== 0)
            click();
    }, [index, click]);
    return (
        <Container figures={minimizedPagination ? 1 : figures} float={float}>
            {minimizedPagination ?
                <>
                    <CellIcon onClick={onClickPrevious} disabled={index === 0} >
                        <Icon name="chevron left" />
                    </CellIcon>
                    <Cell active={true}>{index + 1}</Cell>
                    <CellIcon onClick={onClickNext} disabled={index + 1 >= count}>
                        <Icon name="chevron right" />
                    </CellIcon>
                </>
                :
                <>
                    <CellIcon onClick={onClickPrevious} disabled={index === 0} >
                        <Icon name="chevron left" />
                    </CellIcon>
                    {!long && _.map(array.slice(0, low + 2), i => {
                        return (<Cell key={i} active={i === index + 1} onClick={() => onClick(i)} >{i}</Cell>);
                    })
                    }
                    {long && index + 1 < low &&
                        <>
                            {_.map(array.slice(0, low), i => {
                                return (<Cell key={i} active={i === index + 1} onClick={() => onClick(i)} >{i}</Cell>);
                            })}
                            <Cell disabled>...</Cell>
                            <Cell onClick={() => onClick(count)} >{count}</Cell>
                        </>
                    }
                    {long && index + 1 >= low && index - 1 < max &&
                        <>
                            <Cell onClick={() => onClick(1)} >{1}</Cell>
                            <Cell disabled>...</Cell>
                            {_.map(array.slice(index - ((range - 4) / 2 - 1), index + ((range - 2) / 2)), i => {
                                return (<Cell key={i} active={i === index + 1} onClick={() => onClick(i)} >{i}</Cell>);
                            })}
                            <Cell disabled>...</Cell>
                            <Cell onClick={() => onClick(count)} >{count}</Cell>
                        </>
                    }
                    {long && index - 1 >= max &&
                        <>
                            <Cell onClick={() => onClick(1)} >{1}</Cell>
                            <Cell disabled>...</Cell>
                            {_.map(array.slice(max, count), i => {
                                return (<Cell key={i} active={i === index + 1} onClick={() => onClick(i)} >{i}</Cell>);
                            })}
                        </>
                    }
                    <CellIcon onClick={onClickNext} disabled={index + 1 >= count}>
                        <Icon name="chevron right" />
                    </CellIcon>
                </>
            }
        </Container>
    );
}
export default Pagination;