import { CategoryDownloadDto, ContentController, ControllerHelper } from 'collaboration-service';
import { ImgOnChangeValue, ImgSelect, ImgSelectProps } from 'imaginarity-react-ui';
import { rootElement } from 'index';
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
export interface CategorySelectionProps<IsMulti extends boolean> extends Omit<ImgSelectProps<CategoryDownloadDto, IsMulti>, "onChange" | "isMulti"> {
    onCategoryChanged: (value: ImgOnChangeValue<CategoryDownloadDto, IsMulti, false>) => void;
    categories?: CategoryDownloadDto[];
    groupId?: string;
    groupType?: string;
    isMulti: IsMulti;
    categoryType?: string;
    menuPortal?: HTMLElement | null;
    lng?: string;
}

const CategorySelection = <IsMulti extends boolean = true>(p: CategorySelectionProps<IsMulti>) => {
    const { categories, groupId, groupType, isMulti, onCategoryChanged, value, categoryType, menuPortal, lng, ...rest } = p;
    const [options, setOptions] = React.useState<CategoryDownloadDto[]>([]);
    const contentLanguage = useSelector((s: ApplicationState) => s.contentLanguage);
    React.useEffect(() => {
        const load = async () => {
            if (categories) {
                //console.log("using supplied cats");
                setOptions(categories);
            }
            else {
                if (categoryType) {
                    if (groupId) {
                        //console.log("loading cats from group");
                        const cats = await ControllerHelper.singleCall({ groupid: groupId, categoryType }, ContentController.GetCategoriesForCategoryTypeWithConfigurations, true);
                        setOptions(cats?.configurations[0]?.categories ?? []);
                    }
                    else {
                        //console.log("loading cats with grouptype");
                        const cats = await ControllerHelper.singleCall({ grouptype: groupType, categoryType }, ContentController.GetCategoriesForAllGroupsByGroupTypeForCategoryTypeWithConfigurations, true);
                        setOptions(cats?.configurations[0]?.categories ?? []);
                    }
                }
                else {
                    if (groupId) {
                        //console.log("loading cats from group");
                        const cats = await ControllerHelper.singleCall({ groupid: groupId }, ContentController.GetCategories, true);
                        setOptions(cats ?? []);
                    }
                    else {
                        //console.log("loading cats with grouptype");
                        const cats = await ControllerHelper.singleCall({ grouptype: groupType }, ContentController.GetCategoriesForAllGroupsByGroupType, true);
                        setOptions(cats ?? []);
                    }
                }
            }
        }
        load();
    }, [groupId, categories, groupType, categoryType]);
    const val = React.useMemo(() => _.isArray(value) ? _.filter(options, o => _.find(value, v => v.id === o.id) !== undefined) : _.find(options, o => o.id === (value as any)?.id), [value, options]);

    if (!options)
        return <div />;
    return (
        <ImgSelect
            onChange={onCategoryChanged}
            options={options}
            value={val}
            getOptionLabel={o => getTranslated(o.names, lng ?? contentLanguage).text}
            getOptionValue={o => o.id}
            isMulti={isMulti}
            menuPortalTarget={menuPortal !== undefined ? menuPortal : rootElement}
            {...rest}
        />
    );
}
export default CategorySelection;