import * as React from "react";
import { ApplicationState } from "services/ApplicationState/ApplicationState";
import * as _ from "lodash";
import { connect } from "react-redux";
import { ComponentWithMappedApplicationState } from "services/ApplicationState/HelperInterfaces";

const mapper = (state: ApplicationState) => ({ views: state.tubeState.viewStats });

interface TubeViewsProps extends ComponentWithMappedApplicationState<typeof mapper> {
    id: string;
    abbreviate?: boolean;
}
function abbreviateViews(vn: number) {
    if (vn >= 1000)
        return (vn / 1000).toFixed(1) + "k";
    else
        return vn;
}
const TubeViews = (p: TubeViewsProps) => {
    const v = _.find(p.views, v => v.postId === p.id);
    const views = v ? v.views : 0;

    return (
        <div>{p.abbreviate ? abbreviateViews(views ?? 0) : views}</div>
    );
}
export default connect(mapper)(TubeViews);