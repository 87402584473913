import { MediaDownloadDto, PodcastPostDownloadDto } from 'collaboration-service';
import { Button, Icon, Theme, belowBreakpointOrEqual } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import { gotoNamedRoute, tubeRoute } from 'services/Helpers/RoutingHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { useThemePart } from 'services/useAppTheme';
import { ThemeContext } from 'styled-components';
import { PodcastSC as T } from 'views/Podcast/PodcastSC';
import PodcastViews from './PodcastViews';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage, tubeState: state.tubeState });

export interface PodcastFeedVideoTableProps {
    post: PodcastPostDownloadDto;
    open?: () => void;
    currentCategoryId: string;
    odd: boolean;
}

export interface PodcastFeedVideoTableState {
    showInfo: boolean;
    showIndicators: boolean;
    openRow: boolean;
}

const PodcastFeedVideoTable = (p: PodcastFeedVideoTableProps) => {
    const [openRow, setOpenRow] = React.useState(false);
    const { tubeState, contentLanguage } = useSelector(mapper, shallowCompare);
    const dispatch = useAppDispatch();
    const missingPreviewImageLink = useThemePart(x => x.missingPreviewImageLink);
    const theme = React.useContext<Theme>(ThemeContext);
    const { post, odd } = p;
    const translatedContent = getTranslated(post.content);
    const t = translatedContent.thumbnails ? translatedContent.thumbnails[0] : {} as MediaDownloadDto;
    const headline = getTranslated(post.headlines, contentLanguage)?.text ?? "";
    const viewed = _.find(tubeState.viewStats, vs => vs.postId === post.id)?.viewed ?? false;
    const rating = Math.round(post.rating * 10) / 10;
    const ratingFixed = rating.toFixed(1);

    const openAudio = () => {
        dispatch(Actions.setPodcastCurrentPost(post));
        gotoNamedRoute("podcast_audio_details", tubeRoute(post.id));
    }
    const toggleShowMore = () => {
        setOpenRow(!openRow);
    }
    return (
        <T.PodcastTableViewRow odd={odd} onClick={!belowBreakpointOrEqual({ theme }, "tablet") ? openAudio : toggleShowMore} >
            <T.PodcastPostTableThumb imageUrl={getMediaLink(t, t => t) ?? missingPreviewImageLink} />
            <T.PodcastPostTableTitle>
                <Icon
                    name={viewed ? "eye open" : "eye close"}
                    color={viewed ? "rgba(255,255,255,0.4)" : "@accentRed"}
                    marginTop={4}
                    style={{ float: "left", marginRight: 10 }}
                />
                {headline}
            </T.PodcastPostTableTitle>
            {openRow &&
                <>
                    <T.PodcastPostTableSource>
                        <Icon name="excite" color="rgba(0,0,0,0.4)" marginTop={5} style={{ marginLeft: -5 }} />
                    </T.PodcastPostTableSource>
                    <T.PodcastPostTableViews>
                        <PodcastViews id={post.id} abbreviate />
                    </T.PodcastPostTableViews>
                    <T.PodcastPostTableRating>
                        {rating > 0 ? ratingFixed : <Icon name="times" color="rgba(0,0,0,0.4)" marginTop={5} />}
                    </T.PodcastPostTableRating>
                    <T.PodcastPostTableComment>
                        {post.commentCount > 0 ? post.commentCount : <Icon name="times" color="rgba(0,0,0,0.4)" marginTop={5} />}
                    </T.PodcastPostTableComment>
                    <T.PodcastPostTableButton>
                        <Button kind={belowBreakpointOrEqual({ theme }, "tablet") ? "primary" : "transparentButtonDark"} icon="play" onClick={openAudio} />
                    </T.PodcastPostTableButton>
                </>
            }
            {belowBreakpointOrEqual({ theme }, "tablet") &&
                <T.PodcastPostTableMore>
                    <Button active={openRow} kind="transparentButtonDark" icon="more" onClick={toggleShowMore} />
                </T.PodcastPostTableMore>
            }
        </T.PodcastTableViewRow>
    );
}

export default PodcastFeedVideoTable;