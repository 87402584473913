import { UserShortInfoDownloadDto } from 'collaboration-service';
import UserSearch from 'components/UserSearch/UserSearch';
import * as React from 'react';
import { createFetchPostFilter, FeedSearchProps } from './FeedFilter';
import { ApplicationState, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';

const mapper = (state: ApplicationState) => ({
    feedSearchProps: state.feedState.searchProps,
});


interface FeedSearchByUserProps {
    getUserSelected: (user: UserShortInfoDownloadDto) => void;  // is needed for reseting
    startSearch?: (f: FeedSearchProps, from: string) => void;
    user?: UserShortInfoDownloadDto;
    maxMenuHeight?: number | undefined;
    menuShouldScrollIntoView?: boolean | undefined
}

const FeedSearchByUser = (p: FeedSearchByUserProps) => {
    const { getUserSelected, startSearch: sSearch, user, maxMenuHeight, menuShouldScrollIntoView } = p;
    const { feedSearchProps } = useAppSelector(mapper, shallowCompare);

    const startSearch = React.useMemo(() => async (user?: UserShortInfoDownloadDto) => {
        if (sSearch) {
            const filter = feedSearchProps?.filter ?? createFetchPostFilter(undefined, undefined, undefined, undefined, undefined, user, undefined, null, null, null, undefined);
            sSearch({ ...feedSearchProps, filter: { ...filter, linkedUserId: user?.id ?? "" } }, "FeedSearchByUser::startSearch");
        }
    }, [sSearch, feedSearchProps]);

    const onUsersSelected = React.useMemo(() => (users: UserShortInfoDownloadDto[]) => {
        const selected = users[users.length - 1];
        startSearch(selected);
        getUserSelected(selected);
    }, [startSearch, getUserSelected]);

    return (
        <UserSearch
            users={user ? [user] : []}
            usersSelected={onUsersSelected}
            isMulti={false}
            showAvatar
            menuShouldBlockScroll
            isClearable
            maxMenuHeight={maxMenuHeight}
            menuShouldScrollIntoView={menuShouldScrollIntoView}
        />
    );
}
export default FeedSearchByUser;