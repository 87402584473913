import { CellContext, Column, ColumnDef, ColumnFiltersState, FilterFn, flexRender, getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, HeaderContext, OnChangeFn, Row, RowSelectionState, SortingState, Table, Updater, useReactTable } from "@tanstack/react-table";
import { CategoryDownloadDto, ControllerHelper, ControllerMethod, LanguageStringDownloadDto } from "collaboration-service";
import CategorySelection from "components/CategorySelection/CategorySelection";
import MoreButton from "components/MoreButton/MoreButton";
import Pagination from 'components/Pagination/Pagination';
import SafeHTML from "components/SafeHTML/SafeHTML";
import { EditorSC } from "Dummies/TestImpl/EditorSC";
import { DataClassBase, ExtendedDataCollection, LinkType } from "imaginarity-azure";
import { belowBreakpointOrEqual, Button, ButtonProps, fadeColor, Icon, ImgSelect, Input, LoaderInline, Theme } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from "react-redux";
import { Actions } from "services/ApplicationState/Actions";
import { ApplicationState, useAppDispatch } from "services/ApplicationState/ApplicationState";
import { PluginActions } from "services/ApplicationState/PluginStateHandling";
import { hasLink } from "services/Helpers/LinkHelpers";
import { sanitizedNothingAllowed, sanitizedNothingAllowedAttributes } from "services/Helpers/SanitizedHelper";
import { getTranslated } from "services/Helpers/TranslationHelpers";
import { useImgI18N } from "services/ImgI18N";
import { useThemePart } from "services/useAppTheme";
import { ThemeContext } from "styled-components";
import { LoadingDataTableSC as T } from './LoadingDataTableSC';
import { LoadingDataTableActions, loadingDataTableInitState, loadingDataTableLastRowClickTime, loadingDataTableReducer, LoadingDataTableState } from "./LoadingDataTableState";



export type LoadingDataTableMinimalElementType = Pick<DataClassBase, "id">;
export interface LoadingDataTableSpecialButtonProps<ElementType> extends Omit<ButtonProps, "onClick" | "size" | "floated"> {
    availableForLink?: LinkType;
    onClick: (data: ElementType) => void;
    getProps?: (data: ElementType) => Partial<Omit<ButtonProps, "onClick" | "size" | "floated">>;
}
export interface LoadingDataTableSpecialSecondaryButtonProps<ElementType> extends Omit<LoadingDataTableSpecialButtonProps<ElementType>, "fluid"> {
}

const getValueFromDataElement = <ElementType extends LoadingDataTableMinimalElementType>(ele: ElementType, def: { accessorKey?: string, accessorFn?: (ele: ElementType) => any }) => {
    if (def.accessorKey)
        return (ele as any)[def.accessorKey];
    return def.accessorFn ? def.accessorFn(ele) : undefined
}
type GetValueMethod = typeof getValueFromDataElement;

export type FilterRowFn<ElementType extends LoadingDataTableMinimalElementType> = (row: Row<ElementType>, columnId: string, filterValue: any) => boolean
export type FilterInputComponent<ElementType extends LoadingDataTableMinimalElementType> = React.FunctionComponent<FilterProps<ElementType> & { data: ElementType[], getValue: GetValueMethod }>;
export interface ColumnFilterDefinition<ElementType extends LoadingDataTableMinimalElementType> {
    FilterInput: FilterInputComponent<ElementType>;
    filterRow: FilterRowFn<ElementType>;
}
export interface ColumnDefintion<ElementType extends LoadingDataTableMinimalElementType> {
    id?: string;
    columnCreator?: ColumnCreators | ColumnCreator<ElementType>;
    elementName: keyof ElementType;
    cell?: (cell: CellContext<ElementType, any>) => JSX.Element;
    header?: string | ((header: HeaderContext<ElementType, any>) => JSX.Element);
    width?: number;
    absWidth?: number;
    align?: "left" | "right" | "center";
    padding?: number;
    accessor?: (element: ElementType) => any;
    filterFn?: string | ColumnFilterDefinition<ElementType>;
    noSort?: boolean;
}
export interface PagingHandler {
    getCanNextPage: () => boolean;
    getCanPrevPage: () => boolean;
    getPageCount: () => number;
    setPageIndex: (updater: Updater<number>) => Promise<void>;
    getPageIndex: () => number;
    setPageSize?: (updater: Updater<number>) => Promise<void>;
    getPageSize?: () => number;
}

export interface LoadingDataTableProps<ElementType extends LoadingDataTableMinimalElementType, GetterDataType extends object, SecondDataType extends any> {
    namespace?: string;
    columns: ColumnDefintion<ElementType>[];
    loadData: ControllerMethod<ExtendedDataCollection<ElementType, SecondDataType>, GetterDataType> | ElementType[];
    loaderData: GetterDataType;
    onLoadingChange?: (loading: boolean) => void;
    renderForEmpty?: () => JSX.Element;
    onEdit?: (data: ElementType) => void;
    onHoverRow?: (data?: ElementType) => void;
    onDelete?: (data: ElementType) => void;
    onToggleVisibility?: (data: ElementType) => void;
    renderTable?: (table: () => JSX.Element) => JSX.Element;
    canLoad?: (loaderData: GetterDataType) => boolean;
    actionButtons?: LoadingDataTableSpecialButtonProps<ElementType>[];
    secondaryActionButtons?: LoadingDataTableSpecialSecondaryButtonProps<ElementType>[];
    onElementsLoaded?: (elements: ElementType[]) => void;
    catchUpdateMethods?: (updateMethods: {
        removeElement: (element: ElementType) => void,
        updateElement: (element: ElementType) => void;
        addElement: (element: ElementType) => void;
    }) => void;
    bottomLineButtons?: ButtonProps[];
    mayDelete?: boolean;
    mayDeleteType?: string;
    mayEdit?: boolean;
    mayChangeVisibility?: boolean;
    renderSubComponent?: (data: ElementType) => JSX.Element;
    noFilter?: boolean;
    noFilterClose?: boolean;
    noSorting?: boolean;
    onDoubleClick?: (data: ElementType) => void;
    initPageSize?: number;
    onSelectChanged?: (data: ElementType, selected: boolean) => void;
    onSelectionChanged?: (selectedItems: ElementType[]) => void;
    multiSelect?: boolean;
    ownPaging?: PagingHandler;
    leftBottomLine?: JSX.Element;
    rightBottomLine?: JSX.Element;
    disabled?: boolean;
    changeSelectionHandler?: (handler: (selectedItems: Updater<ElementType[]>) => void) => void;
    hideMoreLessEnties?: boolean;
    widthScaling?: number;
}

type ColumnCreators = "lngString" | "categories";

type ColumnCreator<ElementType extends LoadingDataTableMinimalElementType> = (
    def: ColumnDefintion<ElementType>,
    width: number,
    f: number,
    t: (val: string, data?: { [key: string]: any; }) => string,
    lng: string
) => Column<ElementType> & { padding?: number, align?: "left" | "right" | "center" };

// OWN FILTERFUNCTIONS - EXAMPLE:
/*
const RightsFilterInput: FilterInputComponent<DataType> = (p) => {
    const { column, data, getValue } = p;
    const rights = (column.getFilterValue() ?? []) as Array<{ label: string, value: Rights }>;
    const def = column.columnDef as any;

    const rightOptions = React.useMemo(() => {
        const c = _.sortBy(_.uniqBy(_.flatMap(data, d => getValue(d, def)), c => c), s => s);
        return _.map(c, r => ({ label: r as string, value: r as any as Rights }));
    }, [data, def, getValue]);

    return (
        <div style={{ position: "absolute", top: 0, width: "100%", lineHeight: 1 }}>
            <ImgSelect
                options={rightOptions}
                value={rights}
                isMulti={true}
                onChange={column.setFilterValue}
                menuPortalTarget={document.getElementById("root")}
            />
        </div>
    );
}
const filterRightsRow: FilterRowFn<DataType> = (row, columnId, filterValue) => {
    const curVal = row.getValue<Rights[]>(columnId);
    const fv = filterValue as Array<{ label: string, value: Rights }>;
    const val = _.map(fv, v => v.value);
    const res = val === undefined ? [] : _.difference(val, curVal);
    return res.length === 0;
}
*/


const TextFilter = <ElementType extends LoadingDataTableMinimalElementType>(row: Row<ElementType>, columnId: string, filterValue: any) => {
    const val = row.getValue(columnId) as string ?? "";
    return val.toLocaleLowerCase().includes((filterValue as string).toLocaleLowerCase());
}

const StringArrayFilter = <ElementType extends LoadingDataTableMinimalElementType>(row: Row<ElementType>, columnId: string, filterValue: any) => {
    const curVal = row.getValue<string[]>(columnId);
    const fv = filterValue as Array<{ label: string, value: string }>;
    const val = _.map(fv, v => v.value);
    const res = val === undefined ? [] : _.difference(val, curVal);
    return res.length === 0;
}

const CategoryFilter = <ElementType extends LoadingDataTableMinimalElementType>(row: Row<ElementType>, columnId: string, filterValue: any) => {
    const val = row.getValue(columnId) as CategoryDownloadDto[];
    const fVal = filterValue as CategoryDownloadDto[];
    for (let i = 0; i < fVal.length; i++) {
        const f = fVal[i];
        if (_.findIndex(val, v => f.id === v.id) < 0)
            return false;
    }
    return true;
}

interface FilterProps<ElementType extends LoadingDataTableMinimalElementType> {
    column: Column<ElementType, any>;
    table: Table<ElementType>;
}
const TextFilterInput = <ElementType extends LoadingDataTableMinimalElementType>(p: FilterProps<ElementType>) => {
    const { column } = p;
    const { t } = useImgI18N("general");
    return (
        <T.InputContainer className="test">
            <Input
                value={String(column.getFilterValue() ?? "")}
                change={column.setFilterValue}
                debounce={250}
                placeHolder={t("filter...")}
            />
            {column.getFilterValue() !== undefined ?
                <T.ClearContainer onClick={() => column.setFilterValue(undefined)}>
                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" /></svg>
                </T.ClearContainer>
                :
                <T.FilterIconContainer>
                    <Icon name="filter" marginTop={0} style={{ float: "right" }} color={"@middleLightGrey"} />
                </T.FilterIconContainer>

            }
        </T.InputContainer>
    )
}
const CategoryFilterInput = <ElementType extends LoadingDataTableMinimalElementType>(p: FilterProps<ElementType> & { data: ElementType[] }) => {
    const { column, data } = p;
    const cats = (column.getFilterValue() ?? []) as CategoryDownloadDto[];
    const def = column.columnDef as any;
    const catOptions = React.useMemo(() => {
        const c = _.uniqBy(_.flatMap(data, d => (def).accessorFn(d)), c => c.id);
        return c;
    }, [data, def]);

    return (
        <div style={{ position: "absolute", top: 0, width: "100%", lineHeight: 1 }}>
            <CategorySelection
                categories={catOptions}
                value={cats}
                isMulti={true}
                onCategoryChanged={column.setFilterValue}
                menuPortalTarget={document.getElementById("root")}
            />
        </div>
    );
}

const StringArrayFilterInput = <ElementType extends LoadingDataTableMinimalElementType>(p: FilterProps<ElementType> & { data: ElementType[] }) => {
    const { column, data } = p;
    const strings = (column.getFilterValue() ?? []) as Array<{ label: string, value: string }>;
    const def = column.columnDef as any;

    const stringOptions = React.useMemo(() => {
        const c = _.sortBy(_.uniqBy(_.flatMap(data, d => getValueFromDataElement(d, def)), c => c), s => s);
        return _.map(c, r => ({ label: r as string, value: r as any as string }));
    }, [data, def]);

    return (
        <div style={{ position: "absolute", top: 0, width: "100%", lineHeight: 1 }}>
            <ImgSelect
                options={stringOptions}
                value={strings}
                isMulti={true}
                onChange={column.setFilterValue}
                menuPortalTarget={document.getElementById("root")}
                isClearable
            />
        </div>
    );
}

const standardColumn = <ElementType extends LoadingDataTableMinimalElementType>(def: ColumnDefintion<ElementType>, width: number, f: number, t: (val: string, data?: { [key: string]: any; }) => string, lng: string): ColumnDef<ElementType> & { padding?: number, align?: "left" | "right" | "center" } => {
    const filterFn = typeof def.filterFn === "string" ? def.filterFn : `filter-${def.elementName.toString()}`;
    const toRet: ColumnDef<ElementType> & { padding?: number, align?: "left" | "right" | "center", sortBy?: string } = {
        id: def.id ?? def.elementName.toString(),
        header: def.header ? (typeof def.header === "string" ? t(def.header) : def.header) : t(def.elementName.toString()),
        sortBy: t(typeof def.header === "string" ? def.header : def.elementName.toString()),
        //Header: t(def.header ?? def.elementName.toString()),
        width: def.absWidth !== undefined ? def.absWidth + 2 : f * width * (def.width ?? 1),
        align: def.align,
        padding: def.padding,
        enableColumnFilter: def.filterFn !== undefined,
        filterFn,
        enableSorting: def.noSort ? false : true,
    } as any;
    if (def.accessor)
        (toRet as any).accessorFn = def.accessor;
    else
        (toRet as any).accessorKey = def.elementName.toString();
    if (def.cell)
        toRet.cell = def.cell;
    return toRet;
}

const createColumn = <ElementType extends LoadingDataTableMinimalElementType>(def: ColumnDefintion<ElementType>, width: number, f: number, t: (val: string, data?: { [key: string]: any; }) => string, lng: string): ColumnDef<ElementType> & { padding?: number, align?: "left" | "right" | "center" } => {
    const toRet = standardColumn(def, width, f, t, lng);
    if (!def.columnCreator) {
        return toRet;
    }
    if (typeof def.columnCreator === "function") {
        return {
            ...toRet,
            ...def.columnCreator(def, width, f, t, lng)
        };
    }
    switch (def.columnCreator) {
        case "lngString":
            return {
                align: "left",
                ...toRet,
                filterFn: "text",
                accessorFn: (c: ElementType) => getTranslated(c[def.elementName] as any as LanguageStringDownloadDto[], lng)?.text ?? "",
                cell: (p: CellContext<ElementType, string>) => {
                    return (
                        <EditorSC.EditorContainer
                            notFixed
                            title={p.cell.getValue()}
                        >
                            <SafeHTML
                                allowedTags={sanitizedNothingAllowed}
                                allowedAttributes={sanitizedNothingAllowedAttributes}
                                html={p.cell.getValue()}
                            />
                        </EditorSC.EditorContainer>
                    )
                },
                enableColumnFilter: true,
            } as any;
        case "categories":
            return {
                ...toRet,
                filterFn: "category",
                accessorFn: (c: ElementType) => c[def.elementName],
                cell: (p: CellContext<ElementType, CategoryDownloadDto[]>) => {
                    const filter = p.cell.column.getFilterValue() as CategoryDownloadDto[];
                    return (
                        <T.C>
                            {_.map((p.cell.getValue()), (c, i) => {
                                const found = _.findIndex(filter, f => f.id === c.id) >= 0;
                                return (
                                    <T.S key={c.id} title={getTranslated(c.names, lng)?.text ?? ""} found={found}>
                                        {getTranslated(c.names, lng)?.text ?? ""}
                                    </T.S>
                                )
                            })
                            }
                        </T.C>
                    )
                },
                enableColumnFilter: true,
            } as any;
    }
};

interface ActionCellProps<ElementType extends LoadingDataTableMinimalElementType> {
    cell: CellContext<ElementType, any>
    mayDelete?: boolean;
    mayDeleteType?: string;
    mayEditProps?: boolean;
    mayChangeVisibility?: boolean;
    removeElement: (ele: ElementType) => void;
    updateElement: (ele: ElementType) => void;
    onEdit?: (ele: ElementType) => void;
    onToggleVisibility?: (ele: ElementType) => void;
    onDelete?: (ele: ElementType) => void;
    renderSubComponent?: (data: ElementType) => JSX.Element;
    buttons?: LoadingDataTableSpecialButtonProps<ElementType>[];
    secondaryButtons?: LoadingDataTableSpecialSecondaryButtonProps<ElementType>[];
    t: (val: string, data?: {
        [key: string]: any;
    } | undefined) => string;
}

const ActionCell = <ElementType extends LoadingDataTableMinimalElementType>(p: ActionCellProps<ElementType>) => {
    const { cell, mayDelete, mayDeleteType, mayEditProps, mayChangeVisibility, removeElement,
        updateElement, renderSubComponent, onEdit, onDelete, onToggleVisibility,
        buttons, secondaryButtons, t } = p;
    const actions: JSX.Element[] = [];
    const [confirmDel, setConfirmDel] = React.useState<boolean>(false);
    const ele = cell.row.original;
    const mayDel = mayDelete !== undefined ? mayDelete : hasLink(ele as any, "delete");
    const mayEdit = mayEditProps !== undefined ? mayEditProps : hasLink(ele as any, "update");
    const mayVis = mayChangeVisibility !== undefined ? mayChangeVisibility : hasLink(ele as any, "changeVisibility");

    if (renderSubComponent) {
        const exp = cell.row.getIsExpanded();
        actions.push(
            confirmDel ?
                <Button
                    icon={exp ? "chevron up" : "chevron down"}
                    floated="right"
                    kind="fullTransparentButton"
                    key={cell.row.id + "c"}
                    iconColor={"@middleLightGrey"}
                    disabled
                />
                :
                <Button
                    icon={exp ? "chevron up" : "chevron down"}
                    active={exp}
                    floated="right"
                    kind="transparentButton"
                    onClick={cell.row.getToggleExpandedHandler()}
                    key={cell.row.id + "d"}
                />
        )
    }

    if (secondaryButtons && secondaryButtons.length > 0) {
        const entries = _.map(secondaryButtons, sb => {
            const { getProps, ...rest } = sb;
            if (getProps) {
                return { ...rest, ...getProps(ele) };
            }
            return sb;
        })
        actions.push(<MoreButton
            key={`mb-${ele.id}`}
            ele={ele}
            hideMoreButton
            entries={entries}
        />)
    }

    const del = () => {
        removeElement(ele);
        if (onDelete)
            onDelete(ele);
    }
    const edit = () => {
        if (onEdit)
            onEdit(ele);
    }
    const toggleVisible = () => {
        updateElement({ ...ele, hidden: !(ele as any).hidden });
        if (onToggleVisibility)
            onToggleVisibility(ele);
    }

    if (confirmDel) {
        actions.push(<Button
            key={"dont_del"}
            floated="right"
            icon={"times"}
            kind={"cancelButton"}
            onClick={() => setConfirmDel(false)}
            tooltip={{
                tooltipText: t("cancel deletion"),
                position: "bottom"
            }}
        />,
            <Button
                key={"really_del"}
                floated={"left"}
                icon={"delete"}
                kind={"primary"}
                onClick={del}
                tooltip={{
                    // tooltipText: t("yes, delete post"),
                    tooltipText: t("yes, delete {{type}}", { type: mayDeleteType ?? "post" }),

                    position: "top"
                }}
            />)
    } else {
        if (onDelete)
            actions.push(<Button
                key={"del"}
                floated="right"
                icon={"delete"}
                kind={mayDel ? "transparentButton" : "fullTransparentButton"}
                iconColor={mayDel ? "@accentRed" : "@lightGrey"}
                onClick={() => setConfirmDel(true)}
                disabled={!mayDel}
                tooltip={{
                    tooltipText: t(mayDel ? t("delete {{type}}", { type: mayDeleteType ?? "post" }) : t("you may not delete from the selected group")),
                    position: "bottom"
                }}
            />)
        if (onEdit)
            actions.push(<Button
                key={"edit"}
                floated={"left"}
                icon={"edit"}
                kind={mayEdit ? "transparentButton" : "fullTransparentButton"}
                iconColor={mayEdit ? "@accentBlue" : "@lightGrey"}
                onClick={edit}
                disabled={!mayEdit}
                tooltip={{
                    tooltipText: t(mayEdit ? t("edit entry") : t("you are not allowed to edit anything from the selected group")),
                    position: "bottom"
                }}
            />)
        if (onToggleVisibility)
            actions.push(<Button
                key={"toggleVis"}
                floated="left"
                icon={(ele as any).hidden ? "eye close" : "eye open"}
                kind="transparentButton"
                iconColor={(ele as any).hidden ? "@lightGrey" : "@accentPurple"}
                disabled={!mayVis}
                tooltip={{
                    tooltipText: (ele as any).hidden ? t("show post") : t("hide post"),
                    position: "bottom"
                }}
                onClick={toggleVisible}
            />)

        _.forEach(buttons, (b, i) => {
            const { availableForLink, disabled, onClick, getProps, ...rest } = b;
            const available = availableForLink !== undefined ? hasLink(ele as any, availableForLink) : true;
            const c = () => {
                onClick(ele);
            }
            const props = getProps ? { ...rest, ...getProps(ele) } : rest;
            actions.push(<Button
                key={`ab-${i}`}
                floated="left"
                kind="transparentButton"
                onClick={c}
                {...props}
                disabled={disabled || !available}
                iconColor={available ? props.iconColor : undefined}
            />)
        })
    }
    return <div style={{ width: "100%", position: "relative" }}>
        {actions}
    </div>;
}

const LoadingDataTable = <ElementType extends LoadingDataTableMinimalElementType, GetterDataType extends object, SecondDataType extends any>(p: LoadingDataTableProps<ElementType, GetterDataType, SecondDataType>) => {

    type ColumnType = ColumnDef<ElementType> & { padding?: number, align?: "left" | "right" | "center" };
    type CellPropsType = CellContext<ElementType, any>

    const { namespace, columns: givenColumns,
        loadData, loaderData: givenLoaderData,
        onLoadingChange, renderForEmpty, onEdit,
        renderTable, canLoad, onDelete, onToggleVisibility,
        actionButtons, catchUpdateMethods, bottomLineButtons,
        mayChangeVisibility, mayDelete, mayDeleteType, mayEdit: mayEditProps, noFilterClose,
        renderSubComponent, onHoverRow, noFilter: givenNoFilter, noSorting: givenNoSorting,
        onDoubleClick, secondaryActionButtons, initPageSize, disabled,
        onSelectChanged, onSelectionChanged, multiSelect, ownPaging, leftBottomLine, rightBottomLine,
        changeSelectionHandler, hideMoreLessEnties,
        widthScaling } = p;

    const dispatch = useAppDispatch();
    const showFilters = useSelector((s: ApplicationState) => s.loadingDataTableState?.showFilterOnLoadingDataTable);

    const toggleFilter = React.useMemo(() => () => {
        dispatch(PluginActions.batchActions([Actions.setShowFilterOnLoadingDataTable(!showFilters)]));
    }, [dispatch, showFilters]);

    const { noFilter, noSorting } = React.useMemo(() => ({
        noFilter: givenNoFilter ? true : showFilters ? false : givenNoFilter,
        noSorting: givenNoSorting,
    }), [givenNoFilter, showFilters, givenNoSorting]);

    const { t, currentLanguage } = useImgI18N(namespace ?? "admin");
    const theme = React.useContext<Theme>(ThemeContext);
    const isMobile = belowBreakpointOrEqual({ theme }, "mobile");
    const cWidth = useThemePart((t: Theme) => t.content.width);
    const contentLanguage = useSelector((s: ApplicationState) => s.contentLanguage);
    const reducer = React.useMemo(() => (state: LoadingDataTableState<ElementType, GetterDataType>, action: LoadingDataTableActions<ElementType, GetterDataType>) => loadingDataTableReducer(state, action), []);
    const [state, dp] = React.useReducer(reducer, loadingDataTableInitState<ElementType, GetterDataType>());
    const { columnFilters, columns, data, expandedTable, loading, rowSelection, buttons, loaderData, sorting, secondaryButtons } = state;

    const width = (expandedTable ? cWidth * 2 : (isMobile ? cWidth - 10 : cWidth + 6)) * (widthScaling ?? 1.0);

    React.useEffect(() => {
        dp({ type: "set_multi_select", value: multiSelect });
    }, [multiSelect]);

    React.useEffect(() => {
        dp({
            type: "batch", value: [
                { type: "set_columns", value: givenColumns },
                { type: "set_loaderData", value: givenLoaderData },
                { type: "set_buttons", value: actionButtons },
                { type: "set_secondary_buttons", value: secondaryActionButtons }
            ]
        });
    }, [givenColumns, givenLoaderData, actionButtons, secondaryActionButtons, dp]);

    const lng = contentLanguage ?? currentLanguage;
    const removeElement = React.useMemo(() => (ele: ElementType) => {
        dp({ type: "remove_element", value: ele });
    }, [dp]);

    const updateElement = React.useMemo(() => (ele: ElementType) => {
        dp({ type: "update_element", value: ele });
    }, [dp]);

    const addElement = React.useMemo(() => (ele: ElementType) => {
        dp({ type: "add_element", value: ele });
    }, [dp]);

    React.useEffect(() => {
        if (catchUpdateMethods)
            catchUpdateMethods({ removeElement, updateElement, addElement });
    }, [addElement, updateElement, removeElement, catchUpdateMethods]);

    React.useEffect(() => {
        if (loaderData && (canLoad === undefined || canLoad(loaderData))) {
            if (!loadData || _.isArray(loadData)) {
                dp({ type: "set_data", value: loadData ?? [] });
            }
            else {
                const load = async () => {
                    const actions: LoadingDataTableActions<ElementType, GetterDataType>[] = [
                        { type: "set_loading", value: false }
                    ];
                    if (loaderData) {
                        const result = await ControllerHelper.singleCall({ ...loaderData }, loadData, true);
                        if (result) {
                            actions.push({ type: "set_data", value: result.elements });
                            //setToken(result.data);
                        }
                        else {
                            actions.push({ type: "set_data", value: [] });
                            //setToken(undefined);
                        }
                        if (onLoadingChange)
                            onLoadingChange(false);
                    }
                    dp({ type: "batch", value: actions });
                }
                if (onLoadingChange)
                    onLoadingChange(true);
                dp({ type: "set_loading", value: true });
                load();
            }
        }
    }, [loadData, loaderData, onLoadingChange, canLoad, initPageSize]);

    const cols: ColumnType[] = React.useMemo(() => {
        const buttonCoX = (buttons?.length ?? 0) + (onEdit ? 1 : 0) + (onDelete ? 1 : 0) + (onToggleVisibility ? 1 : 0) + (renderSubComponent !== undefined ? 1 : 0) as number;
        const buttonCoY = secondaryActionButtons && secondaryActionButtons.length > 0 ? 1 : 0;
        const buttonCo = buttonCoX + buttonCoY;
        const actionsWidth = (buttonCo > 0 ? buttonCo * 40 + buttonCoX : 0) + 1;
        const absWidth = _.reduce(columns, (prev, cur) => (cur.absWidth ?? 0) + prev + 1, 0);
        const f = (100.0 / _.reduce(columns, (prev, cur) => (cur.width ?? (cur.absWidth === undefined ? 1 : 0)) + prev, 0)) * 0.01;
        const actions: ColumnType & { width: number } = {
            id: "actions",
            header: () => (
                <T.BtnC>
                    <Button
                        kind="transparentButton"
                        icon={expandedTable ? "decrease" : "expand"}
                        onClick={() => dp({ type: "toggle_expandedTable" })}
                        tooltip={{ tooltipText: expandedTable ? t("decrease table") : t("expand table"), position: expandedTable ? "bottom" : "right", offsetX: expandedTable ? -15 : 0 }}
                    />
                </T.BtnC>
            ),
            align: "left",
            padding: 0,
            width: actionsWidth,
            cell: (cell: CellPropsType) => (<ActionCell
                cell={cell}
                removeElement={removeElement}
                updateElement={updateElement}
                buttons={buttons}
                secondaryButtons={secondaryButtons}
                mayChangeVisibility={mayChangeVisibility}
                mayDelete={mayDelete}
                mayDeleteType={mayDeleteType}
                mayEditProps={mayEditProps}
                onDelete={onDelete}
                onEdit={onEdit}
                onToggleVisibility={onToggleVisibility}
                renderSubComponent={renderSubComponent}
                t={t}
            />
            )
        };
        return [
            actions,
            ...(_.map(columns, c => {
                const tr = createColumn(c, f, width - absWidth - actionsWidth, t, lng);
                return tr;
            }))
        ]
    }, [columns, t, width, lng, onDelete, onEdit, secondaryButtons, secondaryActionButtons,
        onToggleVisibility, removeElement, updateElement, buttons, mayDelete, mayDeleteType,
        mayChangeVisibility, mayEditProps, renderSubComponent, expandedTable,
        dp]);

    const filterFns = React.useMemo(() => {
        //
        // const toRet: Record<string, FilterFn<any>> = {
        //
        const toRet: Record<string, FilterFn<ElementType>> = {
            text: TextFilter,
            category: CategoryFilter,
            stringArray: StringArrayFilter,
        };
        _.forEach(columns, def => {
            if (def.filterFn && typeof def.filterFn !== "string") {
                const fDef = def.filterFn as ColumnFilterDefinition<ElementType>;
                toRet[`filter-${def.elementName.toString()}`] = fDef.filterRow;
            }
        })
        return toRet;
    }, [columns]);

    const columnSizing = React.useMemo(() => {
        const toRet: { [key: string]: any } = {};
        let sum = 0;
        _.forEach(cols, c => {
            const w = Math.floor((c as any).width - 0.5) + (belowBreakpointOrEqual({ theme }, "mobile") ? Math.floor(10 / cols.length) : 0);
            const n = c.id ?? "";
            toRet[n] = w;
            toRet["left-" + n] = sum - 1;
            sum += w;
        });
        toRet["total-size"] = sum;
        return toRet;
    }, [cols, theme])

    const getRowCanExpand = React.useMemo(() => () => renderSubComponent !== undefined, [renderSubComponent]);

    const cSCHandler = React.useMemo(() => (updater: Updater<ElementType[]>) => {
        dp({
            type: "update_rowSelection", value: (curVal) => {
                const selected: ElementType[] = [];
                _.forEach(curVal, (v, k) => {
                    if (v) {
                        const idx = parseInt(k);
                        selected.push(data[idx]);
                    }
                });
                const newSelected = typeof updater === "function" ? updater(selected) : updater;
                const toSet: { [key: string]: boolean } = {};
                _.forEach(newSelected, (s) => {
                    const idx = _.findIndex(data, d => d.id === s.id);
                    if (idx >= 0)
                        toSet[`${idx}`] = true;
                });
                return toSet;
            }
        });
    }, [dp, data]);

    React.useEffect(() => {
        if (changeSelectionHandler)
            changeSelectionHandler(cSCHandler);
    }, [changeSelectionHandler, cSCHandler])

    const { setColumnFilters, setRowSelection, setSorting } = React.useMemo(() => {
        const setColumnFilters: OnChangeFn<ColumnFiltersState> = (value) => dp({ type: "update_columnFilters", value });
        const setRowSelection: OnChangeFn<RowSelectionState> = (value) => dp({ type: "update_rowSelection", value });
        const setSorting: OnChangeFn<SortingState> = (value) => dp({ type: "update_sorting", value });
        return { setColumnFilters, setRowSelection, setSorting }
    }, [dp]);

    const table = useReactTable<ElementType>({
        columns: cols, data, columnResizeMode: "onChange", enableColumnResizing: true,
        onColumnFiltersChange: setColumnFilters,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getRowCanExpand,
        enableMultiRowSelection: true,
        filterFns,
        state: {
            columnFilters, sorting, rowSelection
        },
        initialState: {
            rowSelection,
            pagination: { pageSize: initPageSize ?? 10 },
            columnSizing,
            columnFilters
        }
    });



    const selectedRows = rowSelection;
    React.useEffect(() => {
        if (onSelectionChanged) {
            const toRet: ElementType[] = [];
            _.forEach(selectedRows, (s, idx) => {
                toRet.push(data[Number.parseInt(idx)]);
            });
            onSelectionChanged(toRet);
        }
    }, [selectedRows, data, onSelectionChanged]);

    const pageSize = table.getState().pagination.pageSize;
    const pageIndex = table.getState().pagination.pageIndex;
    const getCanNextPage = table.getCanNextPage;
    const getCanPrevPage = table.getCanPreviousPage;
    const getPageCount = table.getPageCount;
    const setPageSize = table.setPageSize;
    const setPageIndex = table.setPageIndex;

    const paging = React.useMemo(() => {
        const tablePaging: PagingHandler = {
            getCanNextPage: getCanNextPage,
            getCanPrevPage: getCanPrevPage,
            getPageCount: getPageCount,
            setPageIndex: async (idx) => setPageIndex(idx),
            setPageSize: async (up) => setPageSize(up),
            getPageIndex: () => pageIndex,
            getPageSize: () => pageSize,
        }
        const toRet: PagingHandler = ownPaging ? {
            ...ownPaging,
            setPageSize: ownPaging.setPageSize ? async (updater: Updater<number>) => {
                if (ownPaging.setPageSize) {
                    const val = typeof updater === "function" ? updater(ownPaging.getPageSize!()) : updater;
                    console.log("LoadingDataTable.tsx::734 => getPageSize", pageSize);
                    console.log("LoadingDataTable.tsx::735 => val", val);

                    await ownPaging.setPageSize(val);
                    setPageSize(val);
                }
            } : undefined
        } : tablePaging;
        return toRet;
    }, [ownPaging, pageSize, pageIndex, getCanNextPage, getCanPrevPage, getPageCount, setPageSize, setPageIndex]);

    //console.log(table.getSelectedRowModel());
    // test

    const rowSelectionLength = Object.keys(table.getState().rowSelection).length;
    const [showSelectionLost, setShowSelectionLost] = React.useState<boolean>(false);

    const clickPaginate = React.useMemo(() => () => {
        setShowSelectionLost(rowSelectionLength > 0);
    }, [rowSelectionLength, setShowSelectionLost]);

    const Table = React.useMemo(() => () => (
        <T.Outer expandedTable={expandedTable}>
            <T.LanguageStringEditorSC.table
                style={{
                    width: columnSizing["total-size"],
                    // width: "100%",
                    zIndex: 1,
                    pointerEvents: disabled ? "none" : undefined,
                }}
                cellPadding={0}
                cellSpacing={0}
            >
                <T.LanguageStringEditorSC.thead>
                    {// Loop over the header rows
                        table.getHeaderGroups().map(headerGroup => (
                            // Apply the header row props
                            <T.LanguageStringEditorSC.trHead header={!noFilter && showFilters} filterOpen={showFilters ?? false} key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    const defCol = _.find(columns, c => c.elementName.toString() === header.column.id);
                                    const FilterInput = defCol && typeof defCol.filterFn !== "string" ? defCol.filterFn?.FilterInput : undefined;

                                    return (
                                        <T.LanguageStringEditorSC.th key={header.id}
                                            style={{
                                                width: columnSizing[header.id ?? ""],
                                                left: columnSizing["left-" + (header.id ?? "")],
                                                maxWidth: columnSizing[header.id ?? ""],
                                                position: "absolute",
                                                height: noFilter && showFilters ? 40 : 90,
                                            }}
                                        >
                                            <T.LanguageStringEditorSC.thC>
                                                <T.LanguageStringEditorSC.thSortC
                                                    onClick={noSorting ? undefined : header.column.getToggleSortingHandler()}
                                                    noSorting={noSorting || !header.column.columnDef.enableSorting}
                                                    title={(noSorting || !header.column.columnDef.enableSorting) ? t("no sorting possible") : t("sort by {{header}}", { header: (header.column.columnDef as any).sortBy })}
                                                >
                                                    <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                                                    <div>
                                                        {{
                                                            asc: <Icon name="arrow down" color="@accentBlack" />,
                                                            desc: <Icon name="arrow up" color="@accentBlack" />,
                                                        }[header.column.getIsSorted() as string] ?? <Icon name="empty" />}
                                                    </div>
                                                    {!showFilters && <T.BottomLine />}
                                                </T.LanguageStringEditorSC.thSortC>
                                                {!noFilter && showFilters && header.column.getCanFilter() &&
                                                    <T.LanguageStringEditorSC.thFilterContainer>
                                                        {header.column.columnDef.filterFn as any === "text" && <TextFilterInput column={header.column} table={table} />}
                                                        {header.column.columnDef.filterFn as any === "category" && <CategoryFilterInput column={header.column} table={table} data={data} />}
                                                        {header.column.columnDef.filterFn as any === "stringArray" && <StringArrayFilterInput column={header.column} table={table} data={data} />}
                                                        {FilterInput && <FilterInput column={header.column} table={table} data={data} getValue={getValueFromDataElement} />}
                                                    </T.LanguageStringEditorSC.thFilterContainer>
                                                }
                                            </T.LanguageStringEditorSC.thC>
                                        </T.LanguageStringEditorSC.th>
                                    )
                                })}
                            </T.LanguageStringEditorSC.trHead>
                        ))}
                </T.LanguageStringEditorSC.thead>

                {/* Apply the table body props */}
                {true && <T.LanguageStringEditorSC.tbody >

                    {// Loop over the table rows
                        table.getRowModel().rows.map((row, i) => {
                            const isSelected = row.getIsSelected();
                            const onChg = (event: any) => {
                                if (isSelected && loadingDataTableLastRowClickTime?.id === `${i}`) {
                                    const deltaT = (new Date().getTime()) - (loadingDataTableLastRowClickTime?.time ?? 0);
                                    if (deltaT <= 500) {
                                        if (onDoubleClick)
                                            onDoubleClick(row.original);
                                        else {
                                            if (onEdit)
                                                onEdit(row.original);
                                        }
                                    }
                                    else {
                                        if (onSelectChanged)
                                            onSelectChanged(row.original, false);
                                    }
                                }
                                else {
                                    if (onSelectChanged)
                                        onSelectChanged(row.original, true);
                                }
                                row.getToggleSelectedHandler()(event);
                            }

                            const onEnter = () => {
                                if (onHoverRow) {
                                    onHoverRow(row.original);
                                }
                            }
                            const onLeave = () => {
                                if (onHoverRow) {
                                    onHoverRow(undefined);
                                }
                            }
                            if (disabled && !isSelected)
                                return null;
                            // Apply the row props
                            return (
                                <React.Fragment key={row.id + "x"}>
                                    <T.LanguageStringEditorSC.tr selected={isSelected} onMouseLeave={onLeave} onMouseEnter={onEnter} key={row.id} style={{
                                        position: "relative",
                                        backgroundColor: (isSelected && showSelectionLost) ? fadeColor({ theme }, "@accentRed", 8) : undefined,
                                    }}>
                                        {// Loop over the rows cells
                                            row.getVisibleCells().map(cell => {
                                                const padding = (cell.column.columnDef as any).padding;
                                                const textAlign = (cell.column.columnDef as any).align;
                                                if ((cell.column.id === "headlines" || cell.column.id === "descriptions") && cell.column.columnDef.cell === undefined)
                                                    return (
                                                        <T.LanguageStringEditorSC.td key={cell.id} onClick={onChg} style={{
                                                            width: columnSizing[cell.column.columnDef.id ?? ""],
                                                            left: columnSizing["left-" + (cell.column.columnDef.id ?? "")],
                                                            maxWidth: columnSizing[cell.column.columnDef.id ?? ""],
                                                            textAlign,
                                                            padding,
                                                            position: "absolute",
                                                            cursor: "pointer"
                                                        }}
                                                        >
                                                            <T.Styles>
                                                                <SafeHTML html={cell.getValue() as string} allowedTags={sanitizedNothingAllowed} allowedAttributes={sanitizedNothingAllowedAttributes} />
                                                            </T.Styles>
                                                        </T.LanguageStringEditorSC.td>

                                                    )
                                                else
                                                    return (
                                                        <T.LanguageStringEditorSC.td key={cell.id} onClick={cell.column.id !== "actions" ? onChg : undefined} style={{
                                                            width: columnSizing[cell.column.columnDef.id ?? ""],
                                                            left: columnSizing["left-" + (cell.column.columnDef.id ?? "")],
                                                            maxWidth: columnSizing[cell.column.columnDef.id ?? ""],
                                                            padding,
                                                            textAlign,
                                                            position: "absolute",
                                                            cursor: "pointer",
                                                        }}
                                                        >
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </T.LanguageStringEditorSC.td>
                                                    )
                                            })}
                                    </T.LanguageStringEditorSC.tr>

                                    {row.getIsExpanded() && renderSubComponent &&
                                        <T.LanguageStringEditorSC.tr key={row.id + "_sub"} selected={isSelected} style={{ height: "auto", position: "relative", width: columnSizing["total-size"] }}>
                                            <T.LanguageStringEditorSC.subtd colSpan={100} style={{
                                                lineHeight: "20px", padding: "10px 2px 10px 20px",
                                                width: columnSizing["total-size"],
                                            }}>
                                                {renderSubComponent(row.original)}
                                            </T.LanguageStringEditorSC.subtd>
                                        </T.LanguageStringEditorSC.tr>
                                    }
                                </React.Fragment>
                            )
                        })}
                </T.LanguageStringEditorSC.tbody>}
            </T.LanguageStringEditorSC.table>
            <T.Grid style={{ width: columnSizing["total-size"], display: disabled ? "none" : undefined }}>
                <T.GridLeft>
                    {leftBottomLine}
                    {paging.getPageSize !== undefined && paging.setPageSize !== undefined && !hideMoreLessEnties &&
                        <Button
                            floated='left'
                            icon="table minus row"
                            onClick={() => paging.setPageSize!(paging.getPageSize!() - 5)}
                            kind={paging.getPageSize() < 10 ? "fullTransparentButton" : "fullTransparentButton"}
                            disabled={paging.getPageSize() < 10}
                            iconColor={paging.getPageSize() < 10 ? "@middleLightGrey" : "@darkGrey"}
                            tooltip={paging.getPageSize() < 10 ? undefined : { tooltipText: t("show less entries"), position: "top" }}
                        />
                    }
                    {paging.getPageCount() > 1 &&
                        <Pagination
                            count={paging.getPageCount()}
                            initialIndex={paging.getPageIndex()}
                            paginate={paging.setPageIndex}
                            float="left"
                            onClick={clickPaginate}
                        />
                    }
                    {showSelectionLost && rowSelectionLength > 0 &&
                        <T.SelectionLost>
                            <Icon name="info" style={{ float: "left", marginRight: 10 }} />
                            {t("selection will be lost!")}
                        </T.SelectionLost>
                    }
                    {paging.getPageSize !== undefined && paging.setPageSize !== undefined && !hideMoreLessEnties &&
                        <Button
                            floated='left'
                            icon="table add row"
                            onClick={() => paging.setPageSize!(paging.getPageSize!() + 5)}
                            kind={!paging.getCanNextPage() ? "fullTransparentButton" : "fullTransparentButton"}
                            disabled={!paging.getCanNextPage()}
                            iconColor={!paging.getCanNextPage() ? "@middleLightGrey" : "inherit"}
                            tooltip={!paging.getCanNextPage() ? undefined : { tooltipText: t("show more entries"), position: "top" }}
                        />
                    }
                </T.GridLeft>
                <T.GridRight>
                    {rightBottomLine}
                    {_.map(bottomLineButtons, (btn, i) => <Button {...btn} floated="right" kind={btn.kind ?? "transparentButton"} key={i} />)}
                    {!noFilter && !noFilterClose &&
                        <Button
                            kind={showFilters ? "cancelButton" : "transparentButton"}
                            icon={showFilters ? "filter times" : "filter"}
                            // onClick={() => setshowFilters(!showFilters)}
                            onClick={toggleFilter}

                            tooltip={{ tooltipText: showFilters ? t("hide filter") : t("show filter"), position: expandedTable ? "bottom" : "right", offsetX: expandedTable ? -15 : 0 }}
                            floated="right"
                        />
                    }
                </T.GridRight>
            </T.Grid>

        </T.Outer>
    ), [onEdit, t, bottomLineButtons, renderSubComponent, onHoverRow, columnSizing, table, data, theme,
        noFilter, noSorting, columns, expandedTable, onDoubleClick, showFilters, toggleFilter, rowSelectionLength,
        onSelectChanged, paging, leftBottomLine, rightBottomLine, disabled, clickPaginate, showSelectionLost, hideMoreLessEnties, noFilterClose
    ]);

    return (
        <>
            <LoaderInline active={loading} />
            {loaderData !== undefined && canLoad !== undefined && !canLoad(loaderData) ? (renderForEmpty ? renderForEmpty() : <div />) :
                (renderTable ? renderTable(Table) : <Table />)
            }
        </>
    );
}
export default LoadingDataTable;

