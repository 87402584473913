import { PostInteraction, TubePostDownloadDto } from 'collaboration-service';
import CommentsRatingsView from 'components/General/CommentsRatingsView';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { formatDistanceToNow } from 'date-fns';
import { Button, ButtonGroup, getUriFromLinkByName, Image, RatingView, Theme } from 'imaginarity-react-ui';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { PostProps } from 'services/ApplicationState/ApplicationComponentsFactory';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { gotoNamedRoute } from 'services/Helpers/RoutingHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { ImgI18N, useImgI18N } from 'services/ImgI18N';
import { ThemeContext } from 'styled-components';
import QuizButton from 'views/Quiz/QuizButton';
import { ButtonsContainer, Card, Comments, CommentsRatingsContent, Created, GridContainer, Indicator, PostRibbon, SPContent, SPHeader, StartButtonPost, Title, TPDesc } from '../PostClasses';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage, user: state.user });
export interface TubePostProps extends PostProps {
}
export interface TubePostState {
    commentCount: number;
    ratingCount: number;
    rating?: number;
    showComments: boolean;
    show: string;
}

const TubePost = (p: TubePostProps) => {
    const [commentCount, setCommentCount] = React.useState<number>(0);
    const [ratingCount, setRatingCount] = React.useState<number>(0);
    const [rating, setRating] = React.useState<number>();
    const [showComments, setShowComments] = React.useState(false);
    const [show, setShow] = React.useState("comments");

    const post = p.post as TubePostDownloadDto;
    const id = post.id;
    const { t } = useImgI18N("tube");
    const { contentLanguage } = useSelector(mapper, shallowCompare);
    const theme = React.useContext<Theme>(ThemeContext);



    const thumb = (getTranslated(post?.content, contentLanguage)?.thumbnails ?? [])[0] ?? (getTranslated(post?.content, "en-GB")?.thumbnails ?? [])[0];

    const media = thumb && thumb.reference ? thumb : post.media
    const imageUri = getUriFromLinkByName(media, "self") ?? theme.emptyImageUrl;
    const headline = getTranslated(post.headlines, contentLanguage).text ?? "";
    const desc = getTranslated(post.descriptions, contentLanguage).text ?? "";
    const created = formatDistanceToNow(
        new Date(post.created),
        { addSuffix: true, locale: ImgI18N.getInstance().languageLocal(contentLanguage) }
    )

    const onShow = (value: string) => () => setShow(value);
    const ratingCountChanged = (ratingCount: number, rating?: number) => {
        let value = rating;
        if (value)
            value = post.rating + value / ratingCount;
        setRatingCount(ratingCount);
        setRating(value);
    }
    const showCommentsMethod = (showComments: boolean, show: string = "comments") => () => {
        setShowComments(showComments);
        setShow(show);
    };

    return (
        <Card>
            <PostRibbon gradientColor1={theme.colors.darkGrey} gradientColor2="#1c1e1f"><span>{t("Tube")}</span> </PostRibbon>
            <GridContainer>
                <div style={{ margin: "10px" }}>
                    <Image src={imageUri} mediaType={media?.mediaType} />
                </div>
                <SPContent>
                    <Title>
                        <SPHeader>
                            <SafeHTML html={headline} />
                        </SPHeader>
                    </Title>
                    <Created>
                        {created}
                    </Created>
                    <TPDesc>
                        <SafeHTML html={desc ?? ""} />
                    </TPDesc>
                    <StartButtonPost>
                        <QuizButton
                            text1={t("play")}
                            text2={t("video")}
                            diameter={70}
                            onClick={() => gotoNamedRoute("tube_video_details", { id })}
                            backgroundColor="#FFF"
                            borderColor={theme.colors.darkGrey}
                            lightMode
                        />
                    </StartButtonPost>
                </SPContent>
            </GridContainer>
            <Comments>
                <Button
                    kind="transparentButton"
                    icon="comment"
                    floated="left"
                    active={showComments}
                    onClick={showCommentsMethod(!showComments)}
                />
                {commentCount > 0 &&
                    <Indicator>
                        {commentCount}
                    </Indicator>
                }
                <div
                    onClick={showCommentsMethod(!showComments, "ratings")}
                    style={{ float: "right", marginTop: 10, cursor: "pointer" }}
                >
                    {ratingCount > 0 &&
                        <Indicator float="right" isType="rating">
                            {ratingCount}
                        </Indicator>
                    }
                    <RatingView
                        rating={rating ?? post.rating}
                        maxStars={5}
                        size="18px"
                    />
                </div>
            </Comments>
            {
                showComments &&
                <>
                    <ButtonsContainer>
                        <ButtonGroup floated="left" >
                            <Button
                                icon="comment"
                                //size={this.getButtonSize(theme)}
                                content={commentCount + " " + (post.commentCount === 1 ? t("comment") : t("comments"))}
                                kind={show === "comments" ? "secondary" : "transparentButton"}
                                onClick={onShow("comments")} />

                            <Button
                                icon="star half"
                                //size={this.getButtonSize(theme)}
                                content={ratingCount + " " + (post.ratingCount === 1 ? t("rating") : t("ratings"))}
                                kind={show === "ratings" ? "secondary" : "transparentButton"}
                                onClick={onShow("ratings")} />
                        </ButtonGroup>
                    </ButtonsContainer>
                    <CommentsRatingsContent>
                        {show === "comments" &&
                            <CommentsRatingsView
                                post={post}
                                onCountChanged={setCommentCount}
                                noLeftBorder
                                getIncoming={PostInteraction.GetPostComments}
                                addCommentRating={PostInteraction.AddPostComment}
                                onDeleteComment={PostInteraction.DeletePostComment}
                                onSubmitComment={PostInteraction.UpdatePostComment}
                            />
                        }
                        {show === "ratings" &&
                            <CommentsRatingsView
                                post={post}
                                onCountChanged={ratingCountChanged}
                                noLeftBorder
                                asRating
                                getIncoming={PostInteraction.GetPostRatings}
                                addCommentRating={PostInteraction.AddPostRating}
                            />
                        }
                    </CommentsRatingsContent>
                </>
            }
        </Card >
    );

}

export default TubePost;