import * as React from "react";
import { getIconForType } from "services/Helpers/FileTypeHelpers";
import { useThemePart } from "services/useAppTheme";
import { CMSSC } from "./CMSSC";
import { CMSTheme } from "./CMSTheme";

const CMSAppIcon = (p: { type?: string, margin?: string }) => {
    const { type, margin } = p;
    const icons = useThemePart(t => (t.applicationTheming.cmsTheme as CMSTheme).icons);
    const iconToShow = React.useMemo(() => icons[type ? getIconForType(type) : "unknown"], [type, icons]);

    return <CMSSC.CmsAppIcon url={iconToShow} margin={margin} />
}

export default CMSAppIcon;