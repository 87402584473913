import { ControllerHelper, EventController, EventUploadDto, FileEntryDownloadDto, PostController } from 'collaboration-service';
import { getLinkByName, Loader, LoaderInline, styled } from 'imaginarity-react-ui';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { getCMSContent } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import PodcastContent from 'views/Podcast/PodcastContent';
import FileEntryVideoContent from 'views/Tube/FileEntryVideoContent';


const TubeVideoContainer = styled.div`
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        video, embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
    }
`;



const mapper = (state: ApplicationState) => ({
    contentLanguage: state.contentLanguage,
});
export interface PreviewFileEntryVideoPodcastProps {
    playlistStateId?: string;
    id: string;
    isPodcast?: boolean;
}


const PreviewFileEntryVideoPodcast = (p: PreviewFileEntryVideoPodcastProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currentPost, setCurrentPost] = React.useState<FileEntryDownloadDto>();
    const { t, currentLanguage } = useImgI18N("tube");
    const { contentLanguage } = useSelector(mapper, shallowCompare);
    const language = contentLanguage ?? currentLanguage;

    const context = p.playlistStateId;

    React.useEffect(() => {
        const id = p.id;
        const loadPost = async () => {
            if (!id) {
                setCurrentPost(undefined);
                return;
            }
            setLoading(true);
            const post = context ?
                await ControllerHelper.singleCall({ id, context }, PostController.GetSinglePostWithContext) as FileEntryDownloadDto :
                await ControllerHelper.singleCall({ id }, PostController.GetSinglePost) as FileEntryDownloadDto;
            setCurrentPost(post);
            setLoading(false);
        }
        if (id !== currentPost?.id)
            loadPost();

    }, [currentPost, context, p.id]);

    const translatedContent = React.useMemo(() => {
        const translatedContent = getCMSContent({ fileEntry: currentPost, filePosts: {}, language });
        return translatedContent;
    }, [currentPost, language])

    const finishedVideo = React.useMemo(() => () => {
        if (currentPost) {
            const ev: EventUploadDto = {
                eventType: "Finished",
                group_id: currentPost.group_id,
                payload: {
                    "PostId": currentPost.id,
                }
            };
            ControllerHelper.singleCall({ ev, createRestriction: { checkGroupId: true, checkPayload: ["PostId"], checkUserId: true, maxAgeInSeconds: 10 } }, EventController.PostEventWithRestriction);
        }
    }, [currentPost])

    const update = React.useMemo(() => ({ percentage: 90, callback: finishedVideo }), [finishedVideo]);

    if (!currentPost)
        return <Loader active size="small" infoText={t("loading")} light />;

    return (
        <>
            {loading ?
                <LoaderInline
                    infoText="loading"
                    animationColor={"@accent"}
                    backgroundColor={"rgba(0,0,0,0.1)"}
                    textColor={"@lightGrey"}
                    active
                />
                :
                <TubeVideoContainer>
                    <div style={{ height: 0, marginTop: -10 }} />
                    {p.isPodcast ?
                        <PodcastContent
                            src={getLinkByName(translatedContent?.media, "self")?.uri ?? ""}
                            namespace="podcast"
                            onTimeUpdate={update}
                            currentPost={currentPost}
                            mobileView={false}
                        />
                        :
                        <FileEntryVideoContent
                            media={translatedContent?.media!}
                            namespace="tube"
                            onTimeUpdate={update}
                            noAutoplay
                        />
                    }
                </TubeVideoContainer>
            }
        </>
    );



}

export default PreviewFileEntryVideoPodcast; 