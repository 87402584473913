import { TransferProgressEvent } from "@azure/core-http";
import { AnonymousCredential, BlockBlobClient, newPipeline } from "@azure/storage-blob";
import Axios, { AxiosRequestConfig } from "axios";
import { ContentMediaUploadDto, ContentPostContentDownloadDto, ControllerHelper, ICallErrorResponse, MediaController, MediaDownloadDto, MediaUploadDto, MediaWithoutThumbnailsDownloadDto } from "collaboration-service";
import { LinkDto, LinkType } from "imaginarity-azure";
import { UploadMediaParametersDto } from "imaginarity-azure/dist/UploadMediaParametersDto";
import * as _ from "lodash";
import { getLinkFromContent } from "./LinkHelpers";
import { getTranslated } from "./TranslationHelpers";

export function getMediaLink<T>(data: T, func: (data: T) => MediaDownloadDto | MediaWithoutThumbnailsDownloadDto | undefined, refName?: string) {
    const m = func(data);
    if (m) {
        const idx = _.findIndex(m.links, l => l.ref === (refName ? refName : "self"));
        if (idx >= 0)
            return m.links[idx].uri;
    }
    return undefined;
}


export function getThumbnailOrMediaLink(media: MediaDownloadDto | undefined, refName?: LinkType): LinkDto | undefined {
    if (media) {
        if (media.thumbnails && media.thumbnails.length > 0) {
            const link = getLinkFromContent(media.thumbnails[0], refName);
            if (link)
                return link;
        }
        return getLinkFromContent(media, refName);
    }
    return undefined;
}


export function getThumbnailOrMediaLinkForContent(contents: ContentPostContentDownloadDto[], lng: string | undefined, refName?: LinkType): string | undefined {
    const c = getTranslated(contents, lng);
    if (c) {
        const link = c.thumbnails && c.thumbnails.length > 0 ? getLinkFromContent(c.thumbnails[0], refName) : undefined;
        if (link)
            return link.uri;
        const mLink = c.content ? getLinkFromContent(c.content, refName) : undefined;
        if (mLink)
            return mLink.uri;

    }
    return undefined;
}

export const mapMedia = (m: MediaDownloadDto) => ({ media: m?.thumbnails[0] ?? m, refName: "self" });

export interface GetUriMethod {
    (data: { mediaType?: string; fileName?: string; container?: string }, onSuccess?: (result: UploadMediaParametersDto) => void, onError?: (reason: ICallErrorResponse) => void, onProgress?: (currentBytes: number, totalBytes: number) => void, mutex?: string): void;
};

export const putToBlobStorage = async (getUri: GetUriMethod, file: File, onProgress?: (progress: number) => void, abortSignal?: AbortSignal) => {
    const uploadParameters = await ControllerHelper.promise({ mediaType: (file as any).mimeType ?? file.type, fileName: file.name }, getUri);
    if (uploadParameters) {
        const f = ((window as any).imgUploadToBlobStorage) as (uploadParameters: UploadMediaParametersDto, file: File, onProgress?: (progress: number) => void, abortSignal?: AbortSignal) => Promise<string>;
        if (f) {
            const result = await f(uploadParameters, file, onProgress, abortSignal);
            const mediaUpload: MediaUploadDto = {
                changed: true,
                reference: uploadParameters.reference,
                container: uploadParameters.container,
                copyByReference: true,
                mediaType: (file as any).mimeType ?? file.type,
                fileName: file.name,
                thumbnails: [],
            };
            return !result ? mediaUpload : undefined;
        }
        const promise = new Promise<MediaUploadDto>((res, rej) => {
            MediaController.UploadFile(
                {
                    container: uploadParameters.container,
                    bytes: file,
                    fileName: file.name,
                    mediaType: (file as any).mimeType ?? file.type,
                },
                res,
                rej,
                (c, t) => {
                    if (onProgress)
                        onProgress(c / t);
                }
            );
        });
        const up = await promise;
        //console.log(up);
        return up;
    }
}

export async function uploadMediaToAzureStorage(uploadParameters: UploadMediaParametersDto, file: File, onProgress?: (progress: number) => void, abortSignal?: AbortSignal) {
    const progressCallback = onProgress ? (progress: TransferProgressEvent) => {
        onProgress(progress.loadedBytes / file.size)
    } : undefined;
    if (uploadParameters) {
        const client = new BlockBlobClient(uploadParameters.uri, newPipeline(new AnonymousCredential()));
        const result = await client.uploadData(file, {
            abortSignal: abortSignal,
            onProgress: progressCallback,
            blobHTTPHeaders: uploadParameters.blobHttpHeaders
        });
        await client.setMetadata(uploadParameters.metadata);
        const mediaUpload: ContentMediaUploadDto = {
            changed: true,
            reference: uploadParameters.reference,
            container: uploadParameters.container,
            copyByReference: true,
            mediaType: (file as any).mimeType ?? file.type,
            fileName: file.name,
            thumbnails: [],
        };
        return !result.errorCode ? mediaUpload : undefined;
    }
}

interface PutToBlobStorageByteProgressProps {
    getUri: GetUriMethod;
    file: File;
    onProgress?: (progress: number) => void;
    abortSignal?: AbortSignal;
    containerOverride?: string;
    attachment?: boolean;
}

export const putToBlobStorageByteProgress = async (p: PutToBlobStorageByteProgressProps) => {
    const { getUri, file, onProgress, abortSignal, containerOverride, attachment } = p;
    const uploadParameters = await ControllerHelper.promise({ mediaType: (file as any).mimeType ?? file.type, fileName: file.name, container: containerOverride }, getUri);
    if (uploadParameters) {
        if (attachment && uploadParameters.blobHttpHeaders) {
            const old = uploadParameters.blobHttpHeaders?.blobContentDisposition ?? `inline; filename="${file.name}"`;
            uploadParameters.blobHttpHeaders.blobContentDisposition = old.replace("inline;", "attachment;");;
        }
        //console.log('uploadParameters => ', uploadParameters);
        try {
            const result = await uploadMediaToAzureStorage(uploadParameters, file, p => {
                if (onProgress) {
                    onProgress(p * file.size);
                }
            }, abortSignal);
            //console.log("uploadMediaToAzureStorage  ok");
            return result;

        } catch (error) {
            console.log("error", error);
            return undefined;
        }
    }
}


export async function uploadMediaToStorage(file: File, onProgress?: (progress: number) => void, abortSignal?: AbortSignal) {
    try {
        var result = await putToBlobStorage(MediaController.GetUploadUri, file, onProgress, abortSignal);
        return result;
    }
    catch (e) {
        return undefined;
    }
}

export const cleanMediaBeforeUpload = (media: MediaUploadDto) => {
    (media as any).links = undefined;
    media.reference = "";
    if (media.thumbnails) {
        _.forEach(media.thumbnails, tt => cleanMediaBeforeUpload(tt as MediaUploadDto));
    }
}

export const createCleanMediaBeforeUpload = (media: MediaUploadDto) => {
    const m = _.clone(media);
    cleanMediaBeforeUpload(m);
    if (m.thumbnails) {
        const t = _.map(m.thumbnails, tt => createCleanMediaBeforeUpload(tt as MediaUploadDto));
        m.thumbnails = t;
    }
    return m;
}

/*
Take care! this function always returns successfully running promises! But each promise returns "ok=true" on success and
"ok=false" on failure.
*/

export interface ProgressData {
    loaded?: number;
    lengthComputable: boolean;
    total?: number;
}

export function downloadMedia<T>(media: MediaDownloadDto | undefined, userData: T, onProgress?: (progress: ProgressData) => void) {
    return new Promise<{ url: string, userData: T, ok: boolean }>(async (resolve) => {
        if (media) {
            const link = _.find(media.links, l => l.ref === "self");
            if (!link)
                resolve({ url: "", userData, ok: false });
            const headers =
            {
                "X-ZUMO-AUTH": undefined,
                "X-Forwarded-Ssl": "on"
            };
            const aConfig: AxiosRequestConfig =
            {
                url: link ? link.uri : "",
                method: "GET",
                headers,
                responseType: "blob",
                onDownloadProgress: onProgress
            };
            try {
                const response = await Axios(aConfig);
                if (response.status !== 200)
                    resolve({ url: "", userData, ok: false });
                resolve({ url: URL.createObjectURL(response.data), userData, ok: true });
            }
            catch (error) {
                resolve({ url: "", userData, ok: false });
            }
        }
        else
            resolve({ url: "", userData, ok: false });
    });
}


export const dowbloadMedias = (medias: MediaDownloadDto[], onStart: () => void, onProgress: (progress: number) => void): Promise<Array<string | undefined>> => {
    return new Promise<Array<string | undefined>>(async res => {
        const state = _.map(medias, m => ({ media: m, loaded: 0, size: 0, ok: true, done: false, url: "" }));
        const update = (idx: number) => (p: ProgressData) => {
            state[idx].size = (p.lengthComputable ? p.total : 1) ?? 1;
            state[idx].loaded = (p.lengthComputable ? p.loaded : 0) ?? 0;

            let loaded = 0;
            let size = 0;
            _.forEach(state, s => {
                loaded += s.loaded;
                size += s.size;
            });
            onProgress(100 * loaded / size);
        }
        onStart();
        const ps = _.map(medias, (m, i) => downloadMedia(m, i, update(i)));
        const result = await Promise.all(ps);
        _.forEach(result, r => {
            state[r.userData].done = true;
            state[r.userData].loaded = state[r.userData].size;
            state[r.userData].ok = r.ok;
            state[r.userData].url = r.url;
        });
        res(_.map(state, s => s.ok ? s.url : undefined));
    });
}

export function isMediaSVG(o?: MediaDownloadDto | MediaUploadDto): boolean | undefined {
    if (o !== undefined) {
        return o.mediaType?.endsWith("svg+xml") ?? false;
    }
    return undefined;
}