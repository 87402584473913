import { CMSThemeIcons } from "views/CMS/CMSTheme";

interface IconMap {
    word: JSX.Element;
    excel: JSX.Element;
    powerPoint: JSX.Element;
    pdf: JSX.Element;
}

export const mapFileTypeToRenderer = (map: IconMap) => [
    { type: "application/msword", render: map.word },
    { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", render: map.word },
    { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.template", render: map.word },
    { type: "application/vnd.ms-word.document.macroEnabled.12", render: map.word },
    { type: "application/vnd.ms-word.template.macroEnabled.12", render: map.word },
    { type: "application/vnd.ms-excel", render: map.excel },
    { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", render: map.excel },
    { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.template", render: map.excel },
    { type: "application/vnd.ms-excel.sheet.macroEnabled.12", render: map.excel },
    { type: "application/vnd.ms-excel.template.macroEnabled.12", render: map.excel },
    { type: "application/vnd.ms-excel.addin.macroEnabled.12", render: map.excel },
    { type: "application/vnd.ms-excel.sheet.binary.macroEnabled.12", render: map.excel },
    { type: "application/vnd.ms-powerpoint", render: map.powerPoint },
    { type: "application/vnd.openxmlformats-officedocument.presentationml.presentation", render: map.powerPoint },
    { type: "application/vnd.openxmlformats-officedocument.presentationml.template", render: map.powerPoint },
    { type: "application/vnd.openxmlformats-officedocument.presentationml.slideshow", render: map.powerPoint },
    { type: "application/vnd.ms-powerpoint.addin.macroEnabled.12", render: map.powerPoint },
    { type: "application/vnd.ms-powerpoint.presentation.macroEnabled.12", render: map.powerPoint },
    { type: "application/vnd.ms-powerpoint.template.macroEnabled.12", render: map.powerPoint },
    { type: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12", render: map.powerPoint },
    { type: "application/pdf", render: map.pdf },
    { type: "application/x-pdf", render: map.pdf },
];

export const fileTypeIconMap: Array<{ regex: RegExp, icon: keyof CMSThemeIcons }> = [
    { regex: /application\/msword/ig, icon: "word" },
    { regex: /application\/vnd.openxmlformats-officedocument.wordprocessingml.document/ig, icon: "word" },
    { regex: /application\/vnd.openxmlformats-officedocument.wordprocessingml.template/ig, icon: "word" },
    { regex: /application\/vnd.ms-word.document.macroEnabled.12/ig, icon: "word" },
    { regex: /application\/vnd.ms-word.template.macroEnabled.12/ig, icon: "word" },

    { regex: /application\/vnd.ms-excel/ig, icon: "excel" },
    { regex: /application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet/ig, icon: "excel" },
    { regex: /application\/vnd.openxmlformats-officedocument.spreadsheetml.template/ig, icon: "excel" },
    { regex: /application\/vnd.ms-excel.sheet.macroEnabled.12/ig, icon: "excel" },
    { regex: /application\/vnd.ms-excel.template.macroEnabled.12/ig, icon: "excel" },
    { regex: /application\/vnd.ms-excel.addin.macroEnabled.12/ig, icon: "excel" },
    { regex: /application\/vnd.ms-excel.sheet.binary.macroEnabled.12/ig, icon: "excel" },

    { regex: /application\/vnd.ms-powerpoint/ig, icon: "powerPoint" },
    { regex: /application\/vnd.openxmlformats-officedocument.presentationml.presentation/ig, icon: "powerPoint" },
    { regex: /application\/vnd.openxmlformats-officedocument.presentationml.template/ig, icon: "powerPoint" },
    { regex: /application\/vnd.openxmlformats-officedocument.presentationml.slideshow/ig, icon: "powerPoint" },
    { regex: /application\/vnd.ms-powerpoint.addin.macroEnabled.12/ig, icon: "powerPoint" },
    { regex: /application\/vnd.ms-powerpoint.presentation.macroEnabled.12/ig, icon: "powerPoint" },
    { regex: /application\/vnd.ms-powerpoint.template.macroEnabled.12/ig, icon: "powerPoint" },
    { regex: /application\/vnd.ms-powerpoint.slideshow.macroEnabled.12/ig, icon: "powerPoint" },

    { regex: /application\/pdf/ig, icon: "pdf" },
    { regex: /application\/x-pdf/ig, icon: "pdf" },

    { regex: /application\/zip/ig, icon: "zip" },
    { regex: /application\/octet-stream/ig, icon: "zip" },
    { regex: /application\/x-zip-compressed/ig, icon: "zip" },
    { regex: /multipart\/x-zip/ig, icon: "zip" },
    { regex: /application\/x-rar-compressed/ig, icon: "zip" },
    // { regex: /application\/octet-stream/ig, icon: "zip" },
    { regex: /application\/x-arj/ig, icon: "zip" },

    { regex: /application\/json/ig, icon: "xml" },
    { regex: /text\/javascript/ig, icon: "xml" },
    { regex: /application\/javascript/ig, icon: "xml" },


    { regex: /image\/png/ig, icon: "png" },
    { regex: /image\/jp(eg|g)/ig, icon: "jpeg" },
    { regex: /"image\/svg\+xml/ig, icon: "svg" },
    { regex: /image\/(.*)/ig, icon: "image" },

    { regex: /video\/(.*)/ig, icon: "video" },
    { regex: /audio\/(.*)/ig, icon: "audio" },

    { regex: /text\/xml/ig, icon: "xml" },
    { regex: /application\/xml/ig, icon: "xml" },
    { regex: /text\/(.*)/ig, icon: "text" },

    // Posts =>

    { regex: /ContentPost/ig, icon: "contentPost" },
    { regex: /Quiz/ig, icon: "quiz" },
    { regex: /Community|PorscheMomentPost/ig, icon: "feedPost" },
    { regex: /TubePost/ig, icon: "tubePost" },
    { regex: /WikiArticle/ig, icon: "wikiArticle" },
    { regex: /(.*)Question/ig, icon: "question" },
    { regex: /SlideShow/ig, icon: "slideShow" },
    { regex: /PodcastPost/ig, icon: "podcastPost" },

];

export const getIconForType = (type: string): keyof CMSThemeIcons => {
    for (let i = 0; i < fileTypeIconMap.length; i++) {
        const x = fileTypeIconMap[i];
        x.regex.lastIndex = 0;
        if (x.regex.test(type)) {
            return x.icon;
        }
    }
    return "unknown";
}