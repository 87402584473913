import { appendPx, belowBreakpointOrEqual, css, getColor, getPaddingOrAlt, keyframes, lightenColor, styled } from 'imaginarity-react-ui';
import { MasterHeader } from 'layout/MasterLayoutComponents';
import _ from 'lodash';

const attentionBlink = keyframes`
    0%, 50%,100% {opacity: 1;}
    25%,75% {opacity: 0;}
`;
const overflowShow = keyframes`
    0% {overflow: hidden;}
    99%{overflow: hidden;}
    100%{overflow: auto;}
`;
const overflowHide = keyframes`
    0%{overflow: hidden;}
    99%{overflow: hidden;}
    100%{overflow: hidden;}
`;
export const overflowShowAnimation = css`
    animation: ${overflowShow};
    animation-delay: 0s;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
`;
export const overflowHideAnimation = css`
    animation: ${overflowHide};
    animation-delay: 0s;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
`;
export const jello = keyframes`
    0% {transform: scale3d(1, 1, 1);}
    30% {transform: scale3d(1.25, 0.75, 1);}
    40% {transform: scale3d(0.75, 1.25, 1);}
    50% {transform: scale3d(1.15, 0.85, 1);}
    65% {transform: scale3d(0.95, 1.05, 1);}
    75% {transform: scale3d(1.05, 0.95, 1);}
    100% {transform: scale3d(1, 1, 1);}
`;
export const MainSearchContainer = styled.div<{ disabled?: boolean }>`
    position: relative;
    width:100%;
    transition: all 0.2s ease;
    opacity: ${p => p.disabled ? 0.4 : 1};
    *{
        transition: all 0.2s ease-out;
        border: none;
    }
`;
export class PartsSC {
    public static Menu = styled.div<{ itemCount: number }>`
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
    margin-top:12px;
    padding: 5px;
    padding-bottom: 0px;
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(${props => props.itemCount}, fit-content(100px)) ${props => props.theme.header && props.theme.header.rightLogoURL ? "auto" : ""};
    -ms-grid-columns: ${props => _.times(props.itemCount, x => "fit-content(100px)")} ${props => props.theme.header && props.theme.header.rightLogoURL ? "1fr" : ""};
    -webkit-touch-callout: none;
      -webkit-user-select: none; 
       -khtml-user-select: none; 
         -moz-user-select: none; 
          -ms-user-select: none; 
              user-select: none; 
`;
    public static MenuItem = styled.div`
    text-decoration: none;
    padding: 5px 7px 0px 7px;
    display: inline-block;
    white-space: nowrap;
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => belowBreakpointOrEqual(props, "tablet") ? appendPx(props.theme.fontSizeMenuMobile) : appendPx(props.theme.fontSizeMenu)};
    -webkit-touch-callout: none;
      -webkit-user-select: none; 
       -khtml-user-select: none; 
         -moz-user-select: none; 
          -ms-user-select: none; 
              user-select: none; 
    color: ${props => getColor(props, props.theme.headerArea.color)};
    transition: 0.1s all ease-out;
    &:hover {
        color: ${props => getColor(props, props.theme.headerArea.color)};
    }
    &:focus {
        color: ${props => getColor(props, props.theme.headerArea.color)};
    }
    &:visited {
        color: ${props => getColor(props, props.theme.headerArea.color)};
    }
`;
    public static ActiveItem = styled(PartsSC.MenuItem) <{ column: number }>`
    text-align: center;
    border-bottom: 2px solid ${props => getColor(props, props.theme.colors.accent)};
    font-weight:  ${props => props.theme.fontWeights.heavy};
    -ms-grid-column: ${props => props.column};
    grid-column: ${props => props.column};
`;
    public static InActiveItem = styled(PartsSC.MenuItem) <{ column: number }>`
    text-align: center;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    -ms-grid-column: ${props => props.column};
    grid-column: ${props => props.column};
    &:hover{
        border-bottom: 2px solid ${props => getColor(props, props.theme.colors.lightGrey)};
    }
`;
    public static FillItem = styled(PartsSC.MenuItem) <{ column: number }>`
    right: 0;
    border-bottom: 2px solid transparent;
    margin-bottom: 1px;
    -ms-grid-column: ${props => props.column};
    grid-column: ${props => props.column};
    background-image: url(${props => props.theme.header?.rightLogoURL});
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
`;
    public static TextContainer = styled.div`
    text-transform: capitalize;
`;
    public static HeaderContainer = styled(MasterHeader)`
    display: grid;
    display: -ms-grid;
    //grid-template-columns: fit-content(100px) auto;
    //-ms-grid-columns: fit-content(100px) auto;
    grid-template-columns: max-content 1fr;
    -ms-grid-columns: max-content 1fr;
    align-items: center;
`;
    public static HeaderRight = styled(MasterHeader)`
    grid-row-start: unset;
    grid-row-end: unset;
    grid-column-start:2;
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: unset;
    grid-row: unset;
`;
    public static HeaderLogo = styled.div`
    background-color: ${props => getColor(props, props.theme.headerArea.backgroundColor)};
    color: ${props => getColor(props, props.theme.headerArea.color)};
    grid-row-start: 1;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-row: 1;
    grid-column: 1;
    height: ${props => props.theme.headerHeight}px;
    width: 100%;
    align-items: center;
    //padding: ${props => getPaddingOrAlt(props, p => p.header.padding, 4)};
    cursor: pointer;    
`;
    public static Logo = styled.img`
    //height: 40px;
    //width: 150px;
    height: ${p => p.theme.headerHeight}px;
    margin-top:  ${props => belowBreakpointOrEqual(props, "tablet") ? -7 : 0}px;
    //width: ${props => belowBreakpointOrEqual(props, "tablet") ? 100 : 100}px;

`;
    public static Header = styled.div<{ noAccordion?: boolean }>`
    margin: 0px;
    position: relative;
    width: 100%;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 25px 1fr 40px 40px;
    grid-template-rows: ${props => props.noAccordion ? "1fr" : "42px 1fr"};
    -ms-grid-columns: 25px 1fr 40px 40px;
    -ms-grid-rows:  ${props => props.noAccordion ? "1fr" : "42px 1fr"};
`;

    public static Content = styled.div`
    grid-row: 1;
    -ms-grid-row: 1;
    grid-column: 2;
    -ms-grid-column: 2;
    line-height:40px;
    font-weight: ${props => props.theme.fontWeights.heavy};
    font-size: ${props => appendPx(props.theme.fontSize)};
`;
    public static Badge = styled.div`
    position: absolute;
    top: 10px;
    right: 50px;
    border-radius: 50%;
    background-color: ${props => getColor(props, props.theme.badge.backgroundColor)};
    font-size:10px;
    font-weight:${props => props.theme.fontWeights.default};
    color: ${props => getColor(props, props.theme.badge.color)};
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    box-shadow: 0 5px 3px -4px ${props => getColor(props, props.theme.colors.darkGrey)};
    animation: ${attentionBlink} 0.6s both;
`;
    public static IconDef = styled.div<{ open?: boolean }>`
    grid-row: 1;
    -ms-grid-row: 1;
    grid-column: 4;
    -ms-grid-column: 4;
    float: right;
    margin: auto;
    margin-right: 0px;
    position: relative;
    background: ${p => lightenColor(p, getColor(p, p.theme.backgroundColor), 99)};
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    svg{
        transition: all 0.5s ease-out;
        transform-origin: center center;
        transform: scaleY(${p => p.open ? -1 : 1});
        stroke: ${p => lightenColor(p, getColor(p, p.theme.color), 70)};
        stroke-width: 3;
    }
    &:hover{
        background: ${p => lightenColor(p, getColor(p, p.theme.backgroundColor), 95)};
        svg{
            stroke: ${p => getColor(p, p.theme.colors.accent)};
        }
    }
`;
    public static IconTitle = styled.div<{ open?: boolean }>`
    grid-row: 1;
    -ms-grid-row: 1;
    grid-column: 1;
    -ms-grid-column: 1;
    float: left;
`;
    public static Children = styled.div<{ open?: boolean, maxChildrenHeight: boolean }>`
    grid-row: 2;
    -ms-grid-row: 2;
    grid-column: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    grid-column-start: 1;
    grid-column-end: 5;
    position: relative;
    padding: 0px;
    margin: 0px;
    /* transform-origin: 50% 0%;
    transform: ${props => props.open ? "scaleY(100%)" : "scaleY(0%)"}; 
    visibility: ${props => props.open ? "visible" : "hidden"}; */
    transition: all 0.3s ease-out;
    max-height: ${props => props.open ? (props.maxChildrenHeight ? "100%" : "unset") : "0"};
    //border-bottom: ${props => props.open ? "1px solid rgba(0,0,0,0.2)" : "1px solid transparent"};
    overflow: ${props => props.maxChildrenHeight && props.open ? "auto" : "hidden"};
    ${props => props.open && props.maxChildrenHeight ? overflowShowAnimation : ""}
    //padding-bottom: 15px;
    overflow-x: hidden !important;
`;

    public static ChildrenNA = styled.div<{ maxChildrenHeight: boolean }>`
    grid-row: 1;
    -ms-grid-row: 1;
    grid-column: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    grid-column-start: 1;
    grid-column-end: 5;
    position: relative;
    padding: 0px;
    margin: 0px;
    transform-origin: 50% 0%;
    //overflow: ${props => props.maxChildrenHeight ? "auto" : "hidden"};
    //overflow-x: hidden !important;
`;
    public static SearchDelete = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40px;
    height: 40px;
    z-index: 500;
    cursor: pointer;
    button>div{margin: 0;}
`;
}