import { Icon } from "imaginarity-react-ui";
import * as React from "react";
import { getTranslatedStandardDate } from "services/Helpers/TranslationHelpers";
import { DateTimeFnsSC as T } from "./DateTimeFnsSC";

interface DateTimeFnsProps {
    date: Date;
    fluid?: boolean;
    dateFormat?: string;
    timeFormat?: string;
    iconColor?: string;
    textColor?: string;
    separator?: string;
    hideIcons?: boolean;
    noTime?: boolean;
    iconSize?: string | number | undefined;
    marginTop?: number;
}

const DateTimeFns = (p: DateTimeFnsProps) => {
    const {
        iconSize,
        date,
        fluid,
        dateFormat,
        timeFormat,
        iconColor,
        textColor,
        hideIcons,
        separator,
        noTime,
        marginTop
    } = p;

    return (
        <T.Grid
            title={getTranslatedStandardDate(date, "zzzz")}
            fluid={fluid ?? false}
            separator={separator ? true : false}
            noTime={noTime ?? false}
        >
            <T.GridDate textColor={textColor ?? "color"}>
                {!hideIcons && (
                    <Icon
                        color={iconColor ?? "color"}
                        name="calendar"
                        marginTop={marginTop}
                        style={{ float: "left", marginRight: 3 }}
                        size={iconSize}
                    />
                )}
                {getTranslatedStandardDate(date, dateFormat ?? "PPP")}
                {separator && <T.Separator>{separator}</T.Separator>}
            </T.GridDate>
            {noTime ? (
                <div />
            ) : (
                <T.GridTime textColor={textColor ?? "color"}>
                    {!hideIcons && (
                        <Icon
                            color={iconColor ?? "color"}
                            name="clock"
                            marginTop={marginTop}
                            style={{ float: "left", marginRight: 2, marginLeft: 5 }}
                            size={iconSize}
                        />
                    )}
                    {getTranslatedStandardDate(date, timeFormat ?? "pp")}
                </T.GridTime>
            )}
        </T.Grid>
    );
};
export default DateTimeFns;
