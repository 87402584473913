import { PodcastPostDownloadDto } from 'collaboration-service';
import { Button, ButtonGroup, Flag, getColor, Icon, Image, Modal, styled } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { createFullNamedRoute, getQrImageUriForNamedRoute } from 'services/Helpers/RoutingHelper';
import { getLanguageFlag, getTranslatedForTube as getTranslatedForPodcast } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { isAdminInGroup } from 'services/StoreDependantHelpers';
import { PodcastSC as T } from './PodcastSC';

interface PodcastAudioInfoProps {
    mobileView: boolean;
    post: PodcastPostDownloadDto;
}

const Norm = styled.div`
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
    &:hover{
        filter: grayscale(1);
    }
`;

const Red = styled(Norm)`
    position: relative;
    color: ${p => getColor(p, "@accent")};
    cursor: default;
    &:hover{
        filter: grayscale(0);
    }
`;

const PodcastAudioInfo = (p: PodcastAudioInfoProps) => {
    const { mobileView, post } = p;
    const { contentLanguage, quality } = useSelector((s: ApplicationState) => ({ contentLanguage: s.contentLanguage, quality: s.podcastState.quality }), shallowCompare);
    const { t, currentLanguage } = useImgI18N("podcast");
    const lng = contentLanguage ?? currentLanguage;
    const [showQr, setShowQr] = React.useState<boolean>(false);
    const [linkIsCopied, setLinkIsCopied] = React.useState<boolean>(false);
    const isAdmin = isAdminInGroup(post.group_id);

    const { content, lngs } = React.useMemo(() => {
        const content = getTranslatedForPodcast(post?.content, lng, [quality, "SD", "HD", "4K", "", undefined]);
        const subLngs = _.uniq(_.map(content.content?.subtitles, s => s.lng));
        return {
            content,
            lngs: _.uniq(_.map(post?.content, c => c.lng)),
            subLngs
        }
    }, [post, lng, quality])

    const copyLink = React.useMemo(() => () => {
        const textarea = document.createElement("textarea");
        textarea.textContent = createFullNamedRoute("open_post", { id: post.id });
        if (textarea.textContent != null)
            setLinkIsCopied(true);
        else
            setLinkIsCopied(false);
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        } finally {
            document.body.removeChild(textarea);
        }
    }, [post]);

    const toggleQr = React.useMemo(() => () => {
        setShowQr(!showQr);
    }, [showQr]);

    const dispatch = useAppDispatch();

    const setLang = React.useMemo(() => (lng: string) => {
        dispatch(Actions.setContentLanguage(lng));
    }, [dispatch]);

    return (
        <T.PCGridLeftMoreInfo mobileView={mobileView}>
            <T.PCGridLeftMoreInfoTitle>{t("fileformat:")}</T.PCGridLeftMoreInfoTitle>
            <T.PCGridLeftMoreInfoContent>{content.useContent ? content.content.mediaType : "link"}</T.PCGridLeftMoreInfoContent>
            {!mobileView && <div />}
            <T.PCGridLeftMoreInfoTitle>{t("media language:")}</T.PCGridLeftMoreInfoTitle>
            <T.PCGridLeftMoreInfoContent>{_.map(lngs, l => {
                const f = getLanguageFlag(l)
                if (l === content.lng)
                    return (
                        <Red key={l}>
                            <Flag name={f} size={18} />
                            <Icon
                                color="@veryLightGrey"
                                name="eye open"
                                size={14}
                                style={{ position: "absolute", top: -8, left: -6, }}
                            />
                        </Red>
                    );
                return (
                    <Norm key={l} onClick={() => setLang(l)}>
                        <Flag name={f} size={18} hoverable hoverFactor={1} />
                    </Norm>
                )
            })}
            </T.PCGridLeftMoreInfoContent>

            {!mobileView && <div />}
            {isAdmin &&
                <>
                    <T.PCGridLeftMoreInfoTitle>{t("generic link:")}</T.PCGridLeftMoreInfoTitle>
                    <T.PCGridLeftMoreInfoContent>
                        <ButtonGroup fluid>
                            <Button
                                kind={mobileView ? "secondary_menu" : "transparentButton"}
                                icon="link"
                                content={linkIsCopied ? t("link copied") : t("copy link")}
                                onClick={copyLink}
                                active={linkIsCopied}
                                tooltip={{
                                    tooltipText: linkIsCopied ? t("link is copied to clipboard") : t("copy link to clipboard")
                                }} />
                            <Button
                                kind={mobileView ? "secondary_menu" : "transparentButton"}
                                icon={showQr ? "qr code times" : "qr code"}
                                content="qr code"
                                onClick={toggleQr}
                                tooltip={{ tooltipText: t("generate qr code") }}
                            />
                        </ButtonGroup>
                    </T.PCGridLeftMoreInfoContent>
                    <Modal
                        active={showQr}
                        header={t("qr code")}
                        icon="qr code"
                        fixed={false}
                        width="small"
                        height="large"
                        shadow
                        footer={<>
                            <Button active={linkIsCopied} kind={"secondary"} floated="left" content={linkIsCopied ? t("link is copied to clipboard") : t("copy link to clipboard")} onClick={copyLink} icon={linkIsCopied ? "check" : "link"} />
                            <Button kind="primary" floated="right" content={t("close")} onClick={toggleQr} icon="times" />
                        </>
                        }
                    >
                        <Image src={getQrImageUriForNamedRoute("open_post", { id: post.id })} thumbnail width='100%' />
                        <T.PCQrContainerLink onClick={copyLink}>
                            {createFullNamedRoute("open_post", { id: post.id })}
                        </T.PCQrContainerLink>
                    </Modal>
                </>
            }
        </T.PCGridLeftMoreInfo>);
}
export default PodcastAudioInfo;