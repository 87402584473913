import { ControllerHelper, GameController, GameResultDto, QuizDownloadDto } from 'collaboration-service';
import DonutChart from 'components/DonutChart/DonutChart';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { belowBreakpointOrEqual, Icon, Image, ImgThemeConsumer } from 'imaginarity-react-ui';
import { DiagramData, PieDiagramData } from 'imaginarity-statistics';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { getLink } from 'services/Helpers';
import { gotoNamedRoute } from 'services/Helpers/RoutingHelper';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import QuizButton from './QuizButton';
import { QuizSC } from './QuizSC';



const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage, user: state.user });

export interface QuizResultProps extends ComponentWithMappedApplicationState<typeof mapper>, ImgI18NTranslatedComponentProps {
    quiz: QuizDownloadDto;
    gameId: string;
    onClose: () => void;
    isPpeQuiz?: boolean;
    closeModal?: () => void;
    isFromPlaylist?: boolean;
}

export interface QuizResultState {
    gameResult?: GameResultDto[];
    diagram?: DiagramData[];
}

class QuizResult extends React.Component<QuizResultProps, QuizResultState> {
    constructor(props: QuizResultProps) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        GameController.GetGameResult({ gameId: this.props.gameId },
            (data) => {
                this.setState({ gameResult: data, diagram: _.map(data, d => this.generateDiagramData(d)) });
            }
        );
    }

    public render() {
        const { t, user } = this.props;
        const { gameResult } = this.state;

        const wrong: number[] = [];
        const percentage: number[] = [];
        const seconds: string[] = [];

        // const singleResult = gameResult && gameResult.length <= 1;
        const multiResult = gameResult && gameResult.length > 1;

        if (gameResult) {
            _.forEach(gameResult,
                e => {
                    seconds.push(t("{{seconds}} sec.", { seconds: (Math.round(e.timeTaken / 10) / 100) }));

                    if (e.questionsCount !== undefined && e.rightAnswersCount !== undefined) {
                        wrong.push(e.questionsCount - e.rightAnswersCount);
                        percentage.push(Math.round(e.percentPoints));
                    }
                });
        }

        let message = "";
        if (gameResult) {
            let idx = _.findIndex(gameResult, g => g.user.id === user?.id ?? "");
            idx = idx < 0 ? 0 : idx;
            const procent = gameResult[idx].rightAnswersCount === 0 ? "0%" : percentage[idx] + "%";
            //message = procent + t(" in ") + seconds[idx] + ", " + gameResult[idx].points + t(" of ") + gameResult[idx].pointsPossible + t(" points");
            message = procent + " " + seconds[idx] + " " + gameResult[idx].points + "/" + gameResult[idx].pointsPossible + t("pt");
        }


        //const src = get

        return (
            <ImgThemeConsumer>
                {(theme) => {
                    const isMobile = belowBreakpointOrEqual({ theme }, "mobile");
                    return (
                        <>
                            {isMobile ?
                                <QuizSC.QuizBackgroundMobile>
                                    <QuizSC.ResultContainerMobile>
                                        <QuizSC.ResultHeader>
                                            <div className="box">
                                                <div className="inner innerSmall">
                                                    <span>{message}</span>
                                                </div>
                                                <div className="inner innerSmall">
                                                    <span>{message}</span>
                                                </div>
                                            </div>
                                        </QuizSC.ResultHeader>
                                        {gameResult && gameResult.length === 1 &&
                                            <>
                                                {gameResult && gameResult[0].rightAnswersCount !== undefined && gameResult[0].questionsCount !== undefined &&
                                                    <QuizSC.ResultPercentage>
                                                        <DonutChart
                                                            width={220}
                                                            donePercentage={gameResult[0].rightAnswersCount === 0 ? 0 : percentage[0]}
                                                            activeColor="rgba(0, 149, 20, 0.9)"
                                                            inactiveColor="rgba(149, 0, 20, 0.9)"
                                                        />
                                                    </QuizSC.ResultPercentage>
                                                }

                                                <QuizSC.Stats>
                                                    <QuizSC.ResultHeader>
                                                        <div className="box">
                                                            <div className="bottom">
                                                                {t("points")}
                                                            </div>
                                                            <div className="bottom">
                                                                {gameResult[0].points}/{gameResult[0].pointsPossible}
                                                            </div>
                                                        </div>
                                                        <div className="box">
                                                            <div className="bottom">
                                                                {t("questions")}
                                                            </div>
                                                            <div className="bottom">
                                                                {gameResult[0].questionsCount}
                                                            </div>
                                                        </div>
                                                        <div className="box">
                                                            <div className="bottom">
                                                                {t("correct")}
                                                            </div>
                                                            <div className="bottom">
                                                                {gameResult[0].rightAnswersCount}
                                                            </div>
                                                        </div>
                                                        <div className="box">
                                                            <div className="bottom">
                                                                {t("wrong")}
                                                            </div>
                                                            <div className="bottom">
                                                                {wrong[0]}
                                                            </div>
                                                        </div>
                                                    </QuizSC.ResultHeader>
                                                </QuizSC.Stats>

                                            </>
                                        }
                                        {multiResult &&
                                            <div className="MULTIPLAYER">
                                                {_.map(gameResult, (e, i, c) => {
                                                    const position = i % 2 === 0 ? "right" : "left";
                                                    const pos = i === 0 ? "one" : i === 1 ? "two" : i === 2 ? "three" : undefined;
                                                    return (
                                                        <QuizSC.ResultVersusContainer key={i} pos={pos}>
                                                            <QuizSC.InviteInfo1 position={position}>
                                                                <b>{e.rightAnswersCount === 0 ? 0 : percentage[i]}</b>% {this.props.t('in')} <b>{seconds[i]}</b>sec.
                                                            </QuizSC.InviteInfo1>
                                                            <QuizSC.InviteInfo2 position={position}>
                                                                <b>{e.points}</b> {this.props.t('of')} <b>{e.pointsPossible}</b> {this.props.t('points')}
                                                            </QuizSC.InviteInfo2>
                                                            <QuizSC.QuizVersusPlayerPlaceContainer pos={pos} first={true} position={position}>
                                                                <Icon name='hashtag' color="#000" style={{ display: "inline-block" }} />
                                                                <QuizSC.QuizVersusPlayerPlace >
                                                                    {i + 1}
                                                                </QuizSC.QuizVersusPlayerPlace>
                                                            </QuizSC.QuizVersusPlayerPlaceContainer>
                                                            <QuizSC.PlayerNameContainer>
                                                                <QuizSC.InviteUserAvatar position={position === "right" ? "left" : "right"}>
                                                                    <Image squared rounded src={getLink(e.user?.avatar?.links, "self")?.uri ?? theme.emptyAvatarImageUrl} width="100%" />
                                                                </QuizSC.InviteUserAvatar>
                                                                <QuizSC.InviteFirstName position={position === "right" ? "left" : "right"}>
                                                                    {e.user.firstName}
                                                                </QuizSC.InviteFirstName>
                                                                <QuizSC.InviteLastName position={position === "right" ? "left" : "right"}>
                                                                    {e.user.secondName}
                                                                </QuizSC.InviteLastName>
                                                            </QuizSC.PlayerNameContainer>
                                                        </QuizSC.ResultVersusContainer>
                                                    );
                                                }
                                                )}
                                            </div>
                                        }
                                        {this.props.isPpeQuiz ?
                                            <div style={{ width: "100%", marginTop: 20, textAlign: "center" }}>
                                                <span style={{ margin: 10 }}>
                                                    <QuizButton text1={this.props.t("back")} text2={this.props.isFromPlaylist ? undefined : this.props.t("to PPE")} onClick={this.props.closeModal} diameter={50} />
                                                </span>
                                            </div>
                                            :
                                            <div style={{ width: "100%", marginTop: 20, textAlign: "center" }}>
                                                {this.props.quiz && this.props.quiz.highScoreMode === "Optional" &&
                                                    <span style={{ margin: 10 }}>
                                                        <QuizButton text1={this.props.t("hide")} text2={this.props.t("score")} onClick={this.hideAndBackToFeed} diameter={50} />
                                                    </span>

                                                }
                                                <span style={{ margin: 10 }}>
                                                    <QuizButton text1={this.props.t("finish")} onClick={this.backToFeed} diameter={50} />
                                                </span>
                                            </div>
                                        }
                                    </QuizSC.ResultContainerMobile>
                                </QuizSC.QuizBackgroundMobile>
                                :
                                <QuizSC.QuizBackground style={{
                                    position: this.props.isPpeQuiz ? "unset" : "relative",
                                    backgroundColor: this.props.isPpeQuiz ? "transparent" : "inherit",
                                    backgroundImage: this.props.isPpeQuiz ? "none" : "",
                                }}>

                                    <div style={{ position: "absolute", width: "100%", height: "100%", left: "50%", transform: "translateX(-50%)" }}>
                                        <QuizSC.ResultContainer style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%,-50%)",
                                        }}>

                                            <QuizSC.ResultHeader>
                                                <div className="box">
                                                    <div className="inner">
                                                        <span>{message}</span>
                                                    </div>
                                                    <div className="inner">
                                                        <span>{message}</span>
                                                    </div>
                                                </div>
                                            </QuizSC.ResultHeader>

                                            {gameResult && gameResult.length === 1 &&
                                                <>
                                                    {gameResult && gameResult[0].rightAnswersCount !== undefined && gameResult[0].questionsCount !== undefined &&
                                                        <QuizSC.ResultPercentage>
                                                            <DonutChart
                                                                width={220}
                                                                donePercentage={gameResult[0].rightAnswersCount === 0 ? 0 : percentage[0]}
                                                                //activeColor="rgba(149, 0, 20, 0.7)"
                                                                activeColor="rgba(0, 149, 20, 0.9)"
                                                                //inactiveColor="rgba(49, 54, 57, 0.2)"
                                                                inactiveColor="rgba(149, 0, 20, 0.9)"
                                                            />
                                                        </QuizSC.ResultPercentage>
                                                    }

                                                    <QuizSC.Stats>
                                                        <QuizSC.ResultHeader>
                                                            <div className="box">
                                                                <div className="bottom">
                                                                    {t("points")}
                                                                </div>
                                                                <div className="bottom">
                                                                    {gameResult[0].points}/{gameResult[0].pointsPossible}
                                                                </div>
                                                            </div>
                                                            <div className="box">
                                                                <div className="bottom">
                                                                    {t("questions")}
                                                                </div>
                                                                <div className="bottom">
                                                                    {gameResult[0].questionsCount}
                                                                </div>
                                                            </div>
                                                            <div className="box">
                                                                <div className="bottom">
                                                                    {t("correct")}
                                                                </div>
                                                                <div className="bottom">
                                                                    {gameResult[0].rightAnswersCount}
                                                                </div>
                                                            </div>
                                                            <div className="box">
                                                                <div className="bottom">
                                                                    {t("wrong")}
                                                                </div>
                                                                <div className="bottom">
                                                                    {wrong[0]}
                                                                </div>
                                                            </div>
                                                        </QuizSC.ResultHeader>
                                                    </QuizSC.Stats>

                                                </>
                                            }
                                            {multiResult &&
                                                <div className="MULTIPLAYER">
                                                    {_.map(gameResult, (e, i, c) => {
                                                        const position = i % 2 === 0 ? "right" : "left";
                                                        const pos = i === 0 ? "one" : i === 1 ? "two" : i === 2 ? "three" : undefined;
                                                        return (
                                                            <QuizSC.ResultVersusContainer key={i} pos={pos}>
                                                                <QuizSC.InviteInfo1 position={position}>
                                                                    <b>{e.rightAnswersCount === 0 ? 0 : percentage[i]}</b>% {this.props.t('in')} <b>{seconds[i]}</b>sec.
                                                                </QuizSC.InviteInfo1>
                                                                <QuizSC.InviteInfo2 position={position}>
                                                                    <b>{e.points}</b> {this.props.t('of')} <b>{e.pointsPossible}</b> {this.props.t('points')}
                                                                </QuizSC.InviteInfo2>
                                                                <QuizSC.QuizVersusPlayerPlaceContainer pos={pos} first={true} position={position}>
                                                                    <Icon name='hashtag' color="#000" style={{ display: "inline-block" }} />
                                                                    <QuizSC.QuizVersusPlayerPlace >
                                                                        {i + 1}
                                                                    </QuizSC.QuizVersusPlayerPlace>
                                                                </QuizSC.QuizVersusPlayerPlaceContainer>
                                                                <QuizSC.PlayerNameContainer>
                                                                    <QuizSC.InviteUserAvatar position={position === "right" ? "left" : "right"}>
                                                                        <Image squared rounded src={getLink(e.user?.avatar?.links, "self")?.uri ?? theme.emptyAvatarImageUrl} width="100%" />
                                                                    </QuizSC.InviteUserAvatar>
                                                                    <QuizSC.InviteFirstName position={position === "right" ? "left" : "right"}>
                                                                        {e.user.firstName}
                                                                    </QuizSC.InviteFirstName>
                                                                    <QuizSC.InviteLastName position={position === "right" ? "left" : "right"}>
                                                                        {e.user.secondName}
                                                                    </QuizSC.InviteLastName>
                                                                </QuizSC.PlayerNameContainer>
                                                            </QuizSC.ResultVersusContainer>
                                                        );
                                                    }
                                                    )}
                                                </div>
                                            }


                                            {/* {gameResult === undefined &&
                                            <div className="FullHeightContainer">
                                                <Loader active={true}>{this.props.t("loading results")}</Loader>
                                            </div>
                                        } */}
                                            {!this.props.isPpeQuiz &&
                                                <div style={{ width: "100%", marginTop: multiResult ? "150px" : "90px", textAlign: "center" }}>
                                                    {this.props.quiz && this.props.quiz.highScoreMode === "Optional" &&
                                                        <span style={{ margin: 10 }}>
                                                            <QuizButton text1={this.props.t("hide")} text2={this.props.t("score")} onClick={this.hideAndBackToFeed} diameter={100} />
                                                        </span>

                                                    }
                                                    <span style={{ margin: 10 }}>
                                                        <QuizButton text1={this.props.t("finish")} onClick={this.backToFeed} diameter={100} />
                                                    </span>
                                                </div>
                                            }
                                            {this.props.isPpeQuiz &&
                                                <div style={{ width: "100%", marginTop: multiResult ? "150px" : "90px", textAlign: "center" }}>
                                                    <span style={{ margin: 10 }}>
                                                        <QuizButton text1={this.props.t("back")} text2={this.props.isFromPlaylist ? undefined : this.props.t("to PPE")} onClick={this.props.closeModal} diameter={100} />
                                                    </span>
                                                </div>
                                            }

                                        </QuizSC.ResultContainer>
                                    </div>
                                </QuizSC.QuizBackground>
                            }
                        </>
                    );
                }}
            </ImgThemeConsumer>
        );
    }
    private backToFeed = () => {
        // if (this.state.gameResult)
        //     this.props.onClose();
        gotoNamedRoute("quiz");
    };

    private hideAndBackToFeed = async () => {
        await ControllerHelper.singleCall({ gameId: this.props.gameId, hidden: true }, GameController.SetGameVisibility);

        // this.props.onClose();
        gotoNamedRoute("quiz");
    };
    private generateDiagramData = (gameResult: GameResultDto): DiagramData => {
        const answerDistribution: Array<{ correct: string }> = [];
        if (gameResult.questionsCount !== undefined && gameResult.rightAnswersCount !== undefined)
            for (let i = 0; i < gameResult.questionsCount; i++) {
                if (gameResult.rightAnswersCount - i > 0) {
                    answerDistribution.push({ correct: this.props.t("right") });
                } else {
                    answerDistribution.push({ correct: this.props.t("wrong") });
                }
            }
        //        console.log(answerDistribution);
        const dia: PieDiagramData = {
            url: () => new Promise<any[]>(r => r(answerDistribution)),
            innerRadius: 30,
            outerRadius: 75,
            title: this.props.t('Result'),
            groupBy: ["correct"],
            label: {
                show: false,
                position: "inside",
                fontSize: 16,
            },
            aggregators: [
                {
                    label: "Correct",
                    type: "count"
                },
            ],
            nodeManipulation: [
                {
                    path: {
                        correct: this.props.t("wrong")
                    },
                    data: {
                        itemStyle: {
                            color: "#950013",
                        },
                    },
                },
                {
                    path: {
                        correct: this.props.t("right")
                    },
                    data: {
                        itemStyle: {
                            color: "#009514",
                        },
                    }
                }
            ],
            enableSpecificStyling: true,
            type: "Pie",
            padding: {
                top: 0,
                bottom: 0,
            },
            layout: { x: 0, y: 0, w: 0, h: 0 },
        };
        return dia;
    }
}

export default translate("quiz")(connect(mapper)(QuizResult));
